import { Visualization } from '@/common/utils/reports/visualization/visualization';
import * as Constants from '@/common/constants/visualizations.constants';
import { ScoreCircleConfig, ScoreBarConfig, ScoreTextConfig } from '@/common/types/reports/score';
import { Position2d } from '@/common/types/reports/position';

export function generateScoreCircle(visualization: Visualization, config: ScoreCircleConfig) {
    // Score Circle
    visualization.arc({
        position: config.position,
        innerRadius: config.radius - config.thickness,
        outerRadius: config.radius,
        startRadians: 0,
        endRadians: Math.PI * 2,
        aspectRatio: config.aspectRatio,
        attributes: {
            fill: Constants.GREY,
        },
    });

    // Score Circle Fill
    visualization.arc({
        position: config.position,
        innerRadius: config.radius - config.thickness,
        outerRadius: config.radius,
        startRadians: 0,
        endRadians: calculateScorePercentageInRadians(config.fillPercent),
        aspectRatio: config.aspectRatio,
        attributes: {
            fill: config.color,
        },
    });

    // Labels
    for (const label of config.labels) {
        generateScoreText(visualization, label, config.position, config.aspectRatio);
    }

    return visualization;
}

export function generateScoreBar(visualization: Visualization, config: ScoreBarConfig, reportVersion: number) {
    const GRADIANT_RECT_WIDTH = config.length / 4;
    const FUNCTIONAL_RANGE_LOWERBOUND = config.length * (config.functionalRange[0] / 100);
    const FUNCTIONAL_RANGE_UPPERBOUND = config.length * (config.functionalRange[1] / 100);
    const FUNCTIONAL_WINDOW_POSITION = new Position2d(FUNCTIONAL_RANGE_LOWERBOUND, config.position.y - 0.001);
    const FUNCTIONAL_WINDOW_WIDTH = FUNCTIONAL_RANGE_UPPERBOUND - FUNCTIONAL_RANGE_LOWERBOUND;
    const FUNCTIONAL_WINDOW_HEIGHT = config.height + 0.001;

    // Gradiant Red
    visualization.rect({
        position: config.position,
        width: GRADIANT_RECT_WIDTH,
        height: config.height,
        aspectRatio: config.aspectRatio,
        attributes: {
            fill: Constants.RED,
        },
    });

    // Gradiant Orange
    visualization.rect({
        position: new Position2d(config.position.x + GRADIANT_RECT_WIDTH + config.padding, config.position.y),
        width: GRADIANT_RECT_WIDTH,
        height: config.height,
        aspectRatio: config.aspectRatio,
        attributes: {
            fill: Constants.ORANGE,
        },
    });

    // Gradiant Yellow
    visualization.rect({
        position: new Position2d(config.position.x + GRADIANT_RECT_WIDTH * 2 + config.padding * 2, config.position.y),
        width: GRADIANT_RECT_WIDTH,
        height: config.height,
        aspectRatio: config.aspectRatio,
        attributes: {
            fill: Constants.YELLOW,
        },
    });

    // Gradiant Green
    visualization.rect({
        position: new Position2d(config.position.x + GRADIANT_RECT_WIDTH * 3 + config.padding * 3, config.position.y),
        width: GRADIANT_RECT_WIDTH,
        height: config.height,
        aspectRatio: config.aspectRatio,
        attributes: {
            fill: Constants.GREEN,
        },
    });

    if (reportVersion === 1) {
        // Functional Window
        visualization.rect({
            position: new Position2d(
                config.position.x + FUNCTIONAL_WINDOW_POSITION.x + config.padding * 3,
                FUNCTIONAL_WINDOW_POSITION.y,
            ),
            width: FUNCTIONAL_WINDOW_WIDTH,
            height: FUNCTIONAL_WINDOW_HEIGHT,
            aspectRatio: config.aspectRatio,
            attributes: {},
            styles: {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': '.003',
                'stroke-dasharray': '.02, .005',
            },
        });
    }

    // labels
    for (const label of config.labels) {
        generateScoreText(visualization, label, config.position, config.aspectRatio);
    }
}

function generateScoreText(
    visualization: Visualization,
    config: ScoreTextConfig,
    position: Position2d,
    aspectRatio: number,
) {
    if (config.draw === undefined || config.draw) {
        visualization.text({
            content: config.text.toString(),
            position: [position.x + config.positionOffset.x, position.y + config.positionOffset.y],
            aspectRatio,
            attributes: {
                'font-size': config.fontSize,
                'font-family': config.fontFamily,
                'font-weight': 'bold',
            },
            styles: {
                'fill': config.color,
                'text-anchor': config.anchor !== undefined ? config.anchor : 'middle',
                'dominant-baseline': config.baseline !== undefined ? config.baseline : 'central',
                'letter-spacing': '0',
            },
        });
    }
}

export function calculateScorePercentageInRadians(score: number): number {
    return (Math.PI * 2 * score) / 100;
}
