















import Vue, { PropType } from 'vue';

import OverallScoreVisualization, {
    OverallScoreConfig,
} from '@/views/reports/visualizations/scores/OverallScoreVisualization.vue';

import { ReportData } from '@/common/types/reports/reportData';

import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        OverallScoreVisualization,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        config: {
            type: Object as PropType<OverallScoreConfig>,
            required: false,
            default: undefined,
        },
    },
    computed: {
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            if (this.config.reportVersion === 1) {
                infoText.push(this.$t('reports.info.myScore'));
                infoText.push(this.$t('reports.info.dynamicVisionScale'));
                infoText.push(this.$t('reports.info.eyeMovementScores'));
                infoText.push(this.$t('reports.info.percentiles'));
                infoText.push(this.$t('reports.info.referenceRange'));
            } else {
                infoText.push(this.$t('reports.info.dynamicVisionV2.dynamicVisionScore'));
                infoText.push(this.$t('reports.info.dynamicVisionV2.eyeMovementScores'));
                infoText.push(this.$t('reports.info.dynamicVisionV2.eyeMovementScorePursuit'));
                infoText.push(this.$t('reports.info.dynamicVisionV2.eyeMovementScoreSaccade'));
                infoText.push(this.$t('reports.info.dynamicVisionV2.eyeMovementScoreFixation'));
                infoText.push(this.$t('reports.info.dynamicVisionV2.eyeMovementScoreFooterOne'));
                infoText.push(this.$t('reports.info.dynamicVisionV2.eyeMovementScoreFooterTwo'));
            }

            return infoText;
        },
        isParticipantAssessment(): boolean {
            if (this.reportData.assessment?.id) {
                return true;
            } else {
                return false;
            }
        },
    },
});
