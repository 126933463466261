





























































import Vue from 'vue';

import UserApi from '@/api/user.api';
import { User } from '@/common/types/user/user';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import IconStatic from '@/views/common/static/IconStatic.vue';
import ProgressBar from '@/views/common/static/ProgressBar.vue';
import SearchBar from '@/views/common/controls/SearchBar.vue';
import CreateEditUser from '@/views/user/CreateEditUser.vue';

import * as DataTableUtils from '@/common/utils/datatables';

const DATATABLE_OPTIONS_STORE_KEY = 'USERS';

export default Vue.extend({
    components: {
        CreateEditUser,
        GreenButton,
        IconStatic,
        ProgressBar,
        SearchBar,
    },
    data() {
        return {
            loading: true,
            users: [] as User[],
            totalUsers: 0 as number,
            itemsPerPage: 10 as number,
            search: '' as string,
            defaultSortBy: 'firstName' as string,
            defaultSortOrder: 'DESC' as string,
            options: {} as any,
            showCreateEditUser: false,
            selectedUser: undefined as User | undefined,
        };
    },
    computed: {
        headers(): any[] {
            const headers = [
                {
                    text: '',
                    value: 'locked',
                },
                {
                    text: this.$t('participantInfo.fields.firstName').toString(),
                    align: 'start',
                    sortable: true,
                    value: 'firstName',
                },
                {
                    text: this.$t('participantInfo.fields.lastName').toString(),
                    align: 'start',
                    sortable: true,
                    value: 'lastName',
                },
                {
                    text: this.$t('participantInfo.fields.email').toString(),
                    align: 'start',
                    sortable: true,
                    value: 'email',
                },
                {
                    text: this.$t('participantInfo.fields.company').toString(),
                    sortable: false,
                    value: 'company.name',
                },
                {
                    text: this.$t('participantInfo.fields.role').toString(),
                    sortable: false,
                    value: 'role.name',
                },
            ];
            return headers;
        },
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi();
                DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
            },
            deep: true,
        },
    },
    async mounted() {
        const cache = DataTableUtils.getDataTableOptionsCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
        this.options = cache.options;
        this.search = cache.search;
        await this.getDataFromApi();
    },
    methods: {
        async getDataFromApi() {
            this.loading = true;
            const { sortBy, sortOrder } = DataTableUtils.parseSortOptions(
                this.options.sortBy,
                this.options.sortDesc,
                this.defaultSortBy,
                this.defaultSortOrder,
            );

            const usersPage = await UserApi.getUsersPaginated(
                this.options.page,
                this.itemsPerPage,
                this.search,
                sortBy,
                sortOrder,
            );
            this.users = usersPage.data;
            this.totalUsers = usersPage.totalRecords;
            this.loading = false;
        },
        setShowCreateEditUser(show: boolean) {
            this.showCreateEditUser = show;
        },
        setSelectedUser(user: User | undefined) {
            this.selectedUser = user;
        },
        rowClass(user: User) {
            return user.active ? 'user-active' : 'user-inactive';
        },
        executeSearch(search: string) {
            this.search = search;
            this.getDataFromApi();
            DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
        },
        clearFiltersAndSearch() {
            this.options = DataTableUtils.getDefaultOptions(this.defaultSortBy, this.defaultSortOrder);
            this.search = '';
            DataTableUtils.clearDataTableCache(DATATABLE_OPTIONS_STORE_KEY);
        },
    },
});
