


































import Vue from 'vue';
export default Vue.extend({
    props: {
        tooltip: {
            type: String,
            required: false,
            default: '',
        },
        disabledTooltip: {
            type: String,
            required: false,
            default: '',
        },
        icon: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        show: {
            type: Boolean,
            required: false,
        },
        size: {
            type: [Number, String],
            required: false,
        },
        tooltipDirection: {
            type: String,
            required: false,
            default: 'bottom',
        },
        color: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        isAsset(): boolean {
            if (this.icon && (this.icon.includes('png') || this.icon.includes('svg'))) {
                return true;
            } else {
                return false;
            }
        },
        tooltipMessage(): string {
            return !this.disabled ? this.tooltip : this.disabledTooltip;
        },
        useRightEyeGreen(): boolean {
            return this.color;
        },
    },
});
