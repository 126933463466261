// General
import Help from '@/views/trainer/exercises/program/Help.vue';

// New user
import Welcome from '@/views/trainer/exercises/newUser/Welcome.vue';
import Overview from '@/views/trainer/exercises/newUser/Overview.vue';
import Basics from '@/views/trainer/exercises/newUser/Basics.vue';
import ChooseWorkout from '@/views/trainer/exercises/newUser/ChooseWorkout.vue';

// General user flow
import Landing from '@/views/trainer/exercises/program/Landing.vue';
import Progress from '@/views/trainer/exercises/program/Progress.vue';
import Congratulations from '@/views/trainer/exercises/program/Congratulations.vue';

// Exercises
import Tutorial from '@/views/trainer/exercises/program/workout/Tutorial.vue';
import Instructions from '@/views/trainer/exercises/program/workout/Instructions.vue';
import WorkoutPlan from '@/views/trainer/exercises/program/workout/WorkoutPlan.vue';
import ExerciseView from '@/views/trainer/exercises/program/workout/ExerciseView.vue';

const routes = [
    {
        path: '/landing',
        alias: ['eqtTrainer', 'trainer'],
        name: 'landing',
        component: Landing,
    },
    {
        path: '/welcome',
        component: Welcome,
    },
    {
        path: '/overview',
        name: 'overview',
        component: Overview,
    },
    {
        path: '/basics',
        name: 'basics',
        component: Basics,
    },
    {
        path: '/chooseWorkout',
        name: 'chooseWorkout',
        component: ChooseWorkout,
    },
    {
        path: '/help',
        name: 'help',
        component: Help,
        meta: {
            allowedRoles: ['Client Admin', 'RE superadmin', 'RE admin'],
        },
    },
    {
        path: '/congratulations',
        name: 'congratulations',
        component: Congratulations,
    },
    {
        path: '/progress',
        name: 'progress',
        component: Progress,
        props: true,
    },
    {
        path: '/instructions',
        name: 'instructions',
        component: Instructions,
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: Tutorial,
        props: true,
    },
    {
        path: '/exercise',
        name: 'exercise',
        component: ExerciseView,
        props: true,
    },
    {
        path: '/workoutPlan',
        name: 'workoutPlan',
        component: WorkoutPlan,
    },
];

export default routes;
