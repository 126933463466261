
















import Vue from 'vue';

export default Vue.extend({
    props: {
        error: Error,
        component: String,
        individualScoreType: {
            type: String,
            required: false,
        },
    },
    computed: {
        testName(): string {
            return this.component === 'IndividualScoreDynamicVision'
                ? this.component + this.individualScoreType
                : this.component;
        },
    },
});
