import { Position2d } from '@/common/types/reports/position';
import { AnimationParameters } from '@/common/types/trainer/animation';
import { PursuitAnimation } from '@/common/utils/trainer/pursuitAnimation';
import { SaccadeAnimation } from '@/common/utils/trainer/saccadeAnimation';
import { FixationAnimation } from '@/common/utils/trainer/fixationAnimation';
import { IdleAnimation } from '@/common/utils/trainer/idleAnimation';

import {
    PursuitAnimationParameters,
    SaccadeAnimationParameters,
    FixationAnimationParameters,
    IdleAnimationParameters,
} from '@/common/types/trainer/animation';
import { centimetersToPixels, cmPosToPixelPos } from './math';

export interface StimulusState {
    position: Position2d;
    radius: number;
    isVisible: boolean;
    timerState?: StimulusTimerState;
}

export interface StimulusTimerState {
    duration: number;
}

export interface EqtAnimation {
    stimulusState(time: number): StimulusState | undefined;
}

export function createAnimation(parameters: AnimationParameters): EqtAnimation {
    const pixelParameters = animationToPixels(parameters, 'exercise');

    switch (pixelParameters.type) {
        case 'Pursuit':
            return new PursuitAnimation(pixelParameters);
        case 'Saccade':
            return new SaccadeAnimation(pixelParameters);
        case 'Fixation':
            return new FixationAnimation(pixelParameters);
        case 'Idle':
        default:
            return new IdleAnimation(pixelParameters);
    }
}

export function animationToPixels(animation: AnimationParameters, canvasId: string): AnimationParameters {
    switch (animation.type) {
        case 'Pursuit':
            return pursuitParametersToPixels(animation, canvasId);
        case 'Saccade':
            return saccadeParametersToPixels(animation, canvasId);
        case 'Fixation':
            return fixationParametersToPixels(animation);
        case 'Idle':
        default:
            return idleParametersToPixels(animation, canvasId);
    }
}

export function pursuitParametersToPixels(
    parameters: PursuitAnimationParameters,
    canvasId: string,
): PursuitAnimationParameters {
    return {
        type: 'Pursuit',
        radius: centimetersToPixels(parameters.radius),
        startPosition: cmPosToPixelPos(parameters.startPosition, canvasId),
        endPosition: cmPosToPixelPos(parameters.endPosition, canvasId),
        distancePerSecond: centimetersToPixels(parameters.distancePerSecond),
    };
}

export function saccadeParametersToPixels(
    parameters: SaccadeAnimationParameters,
    canvasId: string,
): SaccadeAnimationParameters {
    return {
        type: 'Saccade',
        radius: centimetersToPixels(parameters.radius),
        startPosition: cmPosToPixelPos(parameters.startPosition, canvasId),
        endPosition: cmPosToPixelPos(parameters.endPosition, canvasId),
        numberOfSteps: parameters.numberOfSteps,
        pauseDelay: parameters.pauseDelay,
        gapDelay: parameters.gapDelay,
    };
}

export function fixationParametersToPixels(parameters: FixationAnimationParameters): FixationAnimationParameters {
    return {
        type: 'Fixation',
        radius: centimetersToPixels(parameters.radius),
        distance: centimetersToPixels(parameters.distance),
        pauseDelay: parameters.pauseDelay,
        gapDelay: parameters.gapDelay,
    };
}

export function idleParametersToPixels(parameters: IdleAnimationParameters, canvasId: string): IdleAnimationParameters {
    return {
        type: 'Idle',
        radius: centimetersToPixels(parameters.radius),
        position: cmPosToPixelPos(parameters.position, canvasId),
        duration: parameters.duration,
    };
}
