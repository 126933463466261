import { WorkoutResponse, ExerciseResponse } from '@/common/types/api/responses/trainerResponses';

export default class Workout {
    private readonly workoutNumber: number;
    private readonly exercises: ExerciseResponse[];

    private currentExerciseIndex: number;

    public constructor(workoutResponse: WorkoutResponse) {
        this.workoutNumber = workoutResponse.number;
        this.exercises = workoutResponse.exercises;
        if (workoutResponse.complete) {
            this.currentExerciseIndex = this.exercises.length;
        } else {
            this.currentExerciseIndex = 0;
            while (this.exercises[this.currentExerciseIndex] && this.exercises[this.currentExerciseIndex].finished) {
                this.currentExerciseIndex += 1;
            }
        }
    }

    public setNextExercise() {
        this.currentExerciseIndex += 1;
    }

    public get currentExercise(): ExerciseResponse | undefined {
        const currentExercise = this.exercises[this.currentExerciseIndex];
        return currentExercise ?? null;
    }

    public get nextExercise(): ExerciseResponse | undefined {
        this.setNextExercise();
        const nextExercise = this.currentExercise;
        return nextExercise;
    }

    public get number(): number {
        return this.workoutNumber;
    }

    public get currentExerciseNumber(): number {
        return this.currentExerciseIndex + 1;
    }

    public get allExercises(): ExerciseResponse[] {
        return this.exercises;
    }
}
