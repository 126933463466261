






import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';
import { TestData } from '@/common/types/reports/reportData';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import ScreenData from '@/common/types/reports/screenData';

export interface FourDotFusionConfig {
    testData: TestData;
    systemType: string;
}

const ZOOM_LEVEL = 0.8;

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<FourDotFusionConfig>,
        },
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        aspectRatio(): number {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            return screenData.width / screenData.height;
        },
        redDotAttributes(): Record<string, any> {
            return {
                'fill': '#FF0000',
                'stroke': '#FF0000',
                'stroke-width': 0.003 / ZOOM_LEVEL,
            };
        },
        greenDotAttributes(): Record<string, any> {
            return {
                'fill': '#00FF00',
                'stroke': '#00FF00',
                'stroke-width': 0.003 / ZOOM_LEVEL,
            };
        },
        whiteDotAttributes(): Record<string, any> {
            return {
                'fill': '#FFFFFF',
                'stroke': '#FFFFFF',
                'stroke-width': 0.003 / ZOOM_LEVEL,
            };
        },
        redTransparentDotAttributes(): Record<string, any> {
            return {
                'fill': '#FF0000',
                'stroke': '#FF0000',
                'stroke-width': 0.003 / ZOOM_LEVEL,
                'opacity': 0.5,
            };
        },
        fontAttributes(): Record<string, any> {
            const fontSize = 0.001;

            return {
                'font-size': `${fontSize / ZOOM_LEVEL}em`,
                'font-family': 'sans-serif',
                'font-weight': 'bold',
            };
        },
        fontStyleAttributes(): Record<string, any> {
            return {
                'fill': '#000000',
                'text-anchor': 'middle',
                'letter-spacing': '0',
            };
        },
    },
    mounted() {
        const screenData = Constants.SCREEN_DATA[this.config.systemType];

        if (!this.config.testData) {
            throw new Error('This assessment is missing Four Dot Fusion data.');
        }

        const fourDotFusionResult = this.config.testData.metrics.fourDotFusionResult;

        const topLeftCoordinates = new Position2d(0.1, 0.1);
        const topMiddleCoordinates = new Position2d(0.5, 0.1);
        const topRightCoordinates = new Position2d(0.9, 0.1);
        const leftMiddleCoordinates = new Position2d(0.1, 0.45);
        const centerCoordinates = new Position2d(0.5, 0.45);
        const rightMiddleCoordinates = new Position2d(0.9, 0.45);
        const bottomLeftCoordinates = new Position2d(0.1, 0.82);
        const bottomMiddleCoordinates = new Position2d(0.5, 0.82);
        const bottomRightCoordinates = new Position2d(0.9, 0.82);

        let resultLocation;
        let fourDotResultText;
        switch (fourDotFusionResult) {
            case 'BINOCULAR_SINGLE_VISION':
                resultLocation = topLeftCoordinates;
                fourDotResultText = 'Binocular Single Vision';
                break;
            case 'RIGHT_EYE_SUPPRESSION':
                resultLocation = topMiddleCoordinates;
                fourDotResultText = 'Right Eye Suppression';
                break;
            case 'LEFT_EYE_SUPPRESSION':
                resultLocation = centerCoordinates;
                fourDotResultText = 'Left Eye Suppression';
                break;
            case 'ALTERNATE_SUPPRESSION':
                resultLocation = leftMiddleCoordinates;
                fourDotResultText = 'Alternate Suppression';
                break;
            case 'CROSSED_DIPLOPIA':
                resultLocation = topRightCoordinates;
                fourDotResultText = 'Crossed Diplopia';
                break;
            case 'UNCROSSED_DIPLOPIA':
                resultLocation = rightMiddleCoordinates;
                fourDotResultText = 'Uncrossed Diplopia';
                break;
            case 'ABNORMAL_RETINAL_CORRESPONDENCE':
                resultLocation = bottomLeftCoordinates;
                fourDotResultText = 'Abnormal Retinal Correspondence';
                break;
            case 'VERTICAL_DIPLOPIA_WITH_RIGHT_HYPO_TROPIA':
                resultLocation = bottomMiddleCoordinates;
                fourDotResultText = 'Vertical Diplopia with Right Hypo Tropia';
                break;
            case 'VERTICAL_DIPLOPIA_WITH_LEFT_HYPO_TROPIA':
                resultLocation = bottomRightCoordinates;
                fourDotResultText = 'Vertical Diplopia with Left Hypo Tropia';
                break;
            default:
                resultLocation = topLeftCoordinates;
                fourDotResultText = 'Binocular Single Vision';
                break;
        }

        const defaultAttributes = {
            'fill': 'none',
            'stroke': 'black',
            'stroke-width': 0.001 / ZOOM_LEVEL,
        };

        const binocularSingleVision = this.buildBinocularVisionVisualization(topLeftCoordinates, screenData);
        const rightEyeSuppression = this.buildRightEyeSuppressionVisualization(topMiddleCoordinates, screenData);
        const crossedDiplopia = this.buildCrossedDiplopiaVisualization(topRightCoordinates, screenData);
        const alternateSuppression = this.buildAlternateSuppressionVisualization(leftMiddleCoordinates, screenData);
        const leftEyeSuppression = this.buildLeftEyeSuppressionVisualization(centerCoordinates, screenData);
        const uncrossedDiplopia = this.buildUncrossedDiplopiaVisualization(rightMiddleCoordinates, screenData);
        const abnormalRetinalCorrespondence = this.buildAbnormalRetinalCorrespondenceVisualization(
            bottomLeftCoordinates,
            screenData,
        );
        const verticalDiplopiaRightTropia = this.buildVerticalDiplopiaRightTropiaVisualization(
            bottomMiddleCoordinates,
            screenData,
        );
        const verticalDiplopiaLeftTropia = this.buildVerticalDiplopiaLeftTropiaVisualization(
            bottomRightCoordinates,
            screenData,
        );

        Visualization.build()
            .with(binocularSingleVision)
            .with(rightEyeSuppression)
            .with(uncrossedDiplopia)
            .with(alternateSuppression)
            .with(leftEyeSuppression)
            .with(crossedDiplopia)
            .with(abnormalRetinalCorrespondence)
            .with(verticalDiplopiaRightTropia)
            .with(verticalDiplopiaLeftTropia)
            .line({
                start: [0.3, 0],
                end: [0.3, 1],
                aspectRatio: this.aspectRatio,
                attributes: {
                    ...defaultAttributes,
                    stroke: 'black',
                },
            })
            .line({
                start: [0.7, 0],
                end: [0.7, 1],
                aspectRatio: this.aspectRatio,
                attributes: {
                    ...defaultAttributes,
                    stroke: 'black',
                },
            })
            .line({
                start: [-0.1, 0.3],
                end: [1.1, 0.3],
                aspectRatio: this.aspectRatio,
                attributes: {
                    ...defaultAttributes,
                    stroke: 'black',
                },
            })
            .line({
                start: [-0.1, 0.67],
                end: [1.1, 0.67],
                aspectRatio: this.aspectRatio,
                attributes: {
                    ...defaultAttributes,
                    stroke: 'black',
                },
            })
            .text({
                content: `Four Dot Fusion Result: ${fourDotResultText}`,
                position: [0.5, -0.05],
                aspectRatio: this.aspectRatio,
                attributes: this.fontAttributes,
                styles: this.fontStyleAttributes,
            })
            .rect({
                position: new Position2d(resultLocation.x - 0.2, resultLocation.y - 0.1),
                width: 0.398,
                height: 0.223,
                aspectRatio: this.aspectRatio,
                attributes: {
                    fill: 'gray',
                    opacity: 0.2,
                },
            })
            .zoom(ZOOM_LEVEL)
            .drawNormalized(this.svg, 6 / 4);
    },
    methods: {
        buildBinocularVisionVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            return Visualization.build()
                .text({
                    content: `Binocular Single Vision`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.03, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.03, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.003, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.003, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.whiteDotAttributes,
                    ),
                );
        },
        buildRightEyeSuppressionVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            return Visualization.build()
                .text({
                    content: `Right Eye Suppression`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.03, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.03, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                );
        },
        buildCrossedDiplopiaVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            return Visualization.build()
                .text({
                    content: `Crossed Diplopia`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.1, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.07, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.13, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                );
        },
        buildAlternateSuppressionVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            return Visualization.build()
                .text({
                    content: `Alternate Suppression`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .text({
                    content: `Or`,
                    position: [coordinates.x, coordinates.y + 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.13, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.07, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                );
        },
        buildLeftEyeSuppressionVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            return Visualization.build()
                .text({
                    content: `Left Eye Suppression`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                );
        },
        buildUncrossedDiplopiaVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            return Visualization.build()
                .text({
                    content: `Uncrossed Diplopia`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.13, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.07, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                );
        },
        buildAbnormalRetinalCorrespondenceVisualization(
            coordinates: Position2d,
            screenData: ScreenData,
        ): Visualization {
            return Visualization.build()
                .text({
                    content: `Abnormal Retinal Correspondence`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .text({
                    content: `Or`,
                    position: [coordinates.x, coordinates.y + 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.1, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.13, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.07, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.07, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.13, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.greenDotAttributes,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.1, coordinates.y + 0.1),
                        this.aspectRatio,
                        this.redDotAttributes,
                    ),
                );
        },
        buildVerticalDiplopiaRightTropiaVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            const radius = 0.005;

            return Visualization.build()
                .text({
                    content: `Vertical Diplopia with Right Hypo Tropia`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y),
                        this.aspectRatio,
                        this.redDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.05),
                        this.aspectRatio,
                        this.redDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.02, coordinates.y + 0.08),
                        this.aspectRatio,
                        this.greenDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.02, coordinates.y + 0.08),
                        this.aspectRatio,
                        this.greenDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.12),
                        this.aspectRatio,
                        this.greenDotAttributes,
                        radius,
                    ),
                );
        },
        buildVerticalDiplopiaLeftTropiaVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            const radius = 0.005;

            return Visualization.build()
                .text({
                    content: `Vertical Diplopia with Left Hypo Tropia`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: this.aspectRatio,
                    attributes: this.fontAttributes,
                    styles: this.fontStyleAttributes,
                })
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.08),
                        this.aspectRatio,
                        this.redDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.13),
                        this.aspectRatio,
                        this.redDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x - 0.02, coordinates.y),
                        this.aspectRatio,
                        this.greenDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x + 0.02, coordinates.y),
                        this.aspectRatio,
                        this.greenDotAttributes,
                        radius,
                    ),
                )
                .with(
                    this.buildFourDotCircle(
                        new Position2d(coordinates.x, coordinates.y + 0.04),
                        this.aspectRatio,
                        this.greenDotAttributes,
                        radius,
                    ),
                );
        },
        buildFourDotCircle(
            coordinates: Position2d,
            aspectRatio: number,
            attributes: Record<string, any>,
            radius: number = 0.01,
        ): Visualization {
            return Visualization.build().circle({
                center: [coordinates.x, coordinates.y],
                radius,
                aspectRatio,
                attributes,
            });
        },
    },
});
