










































































































































import Vue, { PropType } from 'vue';

import { SET_ERROR_BANNER } from '@/store/general/mutations';
import Logger from '@/common/utils/logger';
import { CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';

import CompanyLogo from '@/views/common/components/CompanyLogo.vue';
import EditableTextField from '@/views/common/controls/EditableTextField.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';

import CompanyApi from '@/api/company.api';
import { Company } from '@/common/types/company/company';
import { SET_VIEWING_COMPANY } from '@/store/company/mutations';

export default Vue.extend({
    components: {
        CompanyLogo,
        EditableTextField,
        GreenButton,
        SaveConfirmation,
    },
    props: {
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        company: {
            type: Object as PropType<Company>,
            required: true,
        },
    },
    data() {
        return {
            loading: true as boolean,
            showConfirmation: false as boolean,
        };
    },
    computed: {
        name(): string {
            return this.company.name;
        },
        accountNumber(): string {
            return this.company.accountNumber;
        },
        address1(): string {
            return this.company.address1;
        },
        address2(): string {
            return this.company.address2;
        },
        city(): string {
            return this.company.city;
        },
        state(): string {
            return this.company.state ? this.company.state.code : '';
        },
        country(): string {
            return this.company.country ? this.company.country.code : '';
        },
        zip(): string {
            return this.company.zipCode;
        },
        active(): boolean {
            return this.company.active;
        },
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
    },
    methods: {
        async saveCompanyDetails() {
            try {
                const company = await CompanyApi.updateCompany(this.company);
                this.$store.commit(SET_VIEWING_COMPANY, company);
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async saveCompanyActiveState(activeState: boolean) {
            try {
                const company = await CompanyApi.updateCompanyActiveState(this.company.id, activeState);
                this.$store.commit(SET_VIEWING_COMPANY, company);
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        showSaveConfirmation() {
            this.showConfirmation = true;
        },
        saveConfirmation() {
            this.company.active = !this.company.active;
            this.saveCompanyActiveState(this.company.active);
            this.showConfirmation = false;
        },
        cancelConfirmation() {
            this.showConfirmation = false;
        },
    },
});
