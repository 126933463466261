







import Vue from 'vue';

export default Vue.extend({
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        unclickable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isChecked: false,
        };
    },
    methods: {
        onClick() {
            if (this.unclickable) {
                return;
            }
            this.isChecked = !this.isChecked;

            this.$emit('selectionChanged', this.isChecked);
        },
    },
    mounted() {
        this.isChecked = this.checked;
    },
});
