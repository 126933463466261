export default {
    providerSummary: 'EyeQ Trainer Summary',
    providerSettings: 'Provider Settings',
    exercisesDemo: 'EyeQ Trainer Exercises',
    logout: 'Logout',
    viewAssessments: 'View Assessments',
    companyManagement: 'Company Management',
    viewProgress: 'My Progress',
    viewWorkoutPlan: 'My Workout Plan',
    selectReport: 'Select a Report',
    trainer: 'Go to EyeQ Trainer',
    companyModules: 'Company Modules',
    pageTitle: {
        companyModules: 'Company Modules',
        title: 'EyeQ Trainer&#153;',
        participants: 'Participants',
        assessments: 'Assessments',
        exercises: 'Exercises',
        settings: 'Settings',
    },
    assessments: 'Assessments',
    participants: 'Patient Management',
    eqtParticipants: 'Participants',
    exercises: 'Exercises',
    eyeQTrainer: 'EyeQ Trainer&#153;',
    successCenter: 'Success Center',
    users: 'User Management',
    appVersions: 'App Version Management',
    protocols: 'Custom Protocols',
    sportsVisionTrainer: 'Sports Vision Trainer',
    settingsBlocks: 'Settings',
    settings: {
        EqtSettings: 'EyeQ Trainer',
        CompanyModules: 'Company Modules',
    },
    admin: 'Administration',
};
