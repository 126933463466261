import ApiService from '@/api/common/apiService';
import {
    ParticipantSummaryResponse,
    ParticipantSettingsResponse,
    CompanyEqtSettings,
    EqtPrescriptionResponse,
    EqtParticipantResponse,
} from '../common/types/api/responses/trainerResponses';
import {
    ProgramResponse,
    CycleResponse,
    WorkoutResponse,
    WorkoutPlanResponse,
    ExerciseResponse,
} from '@/common/types/api/responses/trainerResponses';
import { ExerciseRequest, UpdateProgramRequest } from '@/common/types/api/requests/trainerRequests';
import { getTimeZone } from '@/common/utils/date';
import { PaginatedResponse } from '@/common/types/api/responses/paginatedResponses';
import { HttpMethod } from '@/common/types/api/requests/customRequest';

class TrainerApi {
    public async getEqtParticipantsByCompany(companyId: string): Promise<EqtParticipantResponse[]> {
        const uri = `/righteye.api/eqt/participants/${companyId}`;
        const response = await ApiService.get<EqtParticipantResponse[]>(uri);
        return response;
    }

    public async getEqtParticipantsByCompanyPaginated(
        companyId: string,
        page: number,
        size: number,
        searchValue: string,
        sortBy: string,
        sortOrder: string,
    ): Promise<PaginatedResponse<EqtParticipantResponse>> {
        let uri: string = `/righteye.api/eqt/participantsPaginated/${companyId}?page=${page}&per_page=${size}&sort_order=${sortOrder}`;

        if (sortBy) {
            uri = `${uri}&sort_by=${sortBy}`;
        }
        if (searchValue) {
            uri = `${uri}&search_value=${searchValue}`;
        }

        const request = {
            url: uri,
            method: 'GET' as HttpMethod,
        };
        const response = await ApiService.requestWithDataField<any>(request);
        return response;
    }

    public async getEqtParticipantByUserId(userId: string): Promise<EqtParticipantResponse> {
        const uri: string = `/righteye.api/eqt/userParticipant/${userId}`;
        const response = await ApiService.get<EqtParticipantResponse>(uri);
        return response;
    }

    public async getAllEqtParticipants(): Promise<EqtParticipantResponse[]> {
        const uri = `/righteye.api/eqt/participants`;
        const response = await ApiService.get<EqtParticipantResponse[]>(uri);
        return response;
    }

    public async getParticipantSummary(participantId: string): Promise<ParticipantSummaryResponse> {
        const uri = `/righteye.api/eqt/providers/participantSummary/${participantId}`;
        const response = await ApiService.get<ParticipantSummaryResponse>(uri);
        return response;
    }

    public async getCompanyEqtSettings(companyId: string): Promise<CompanyEqtSettings> {
        const uri = `/righteye.api/companies/${companyId}/eqtCompanySettings`;
        const response = await ApiService.get<CompanyEqtSettings>(uri);
        return response;
    }

    public async setSelectedPrescription(prescriptionId: string): Promise<void> {
        const uri = `/righteye.api/participants/eqtParticipantSettings`;
        const response = await ApiService.patch<void>(uri, {
            nextPrescriptionId: prescriptionId,
        });
        return response;
    }

    public async assignGeneralExercises(participantId: string): Promise<EqtPrescriptionResponse[]> {
        const uri = `/righteye.api/eqt/generalExercises/` + participantId;
        const response = await ApiService.post<EqtPrescriptionResponse[]>(uri);
        return response;
    }

    public async setEqtEnabled(participantId: string, eqtEnabled: boolean): Promise<ParticipantSettingsResponse> {
        const uri = `/righteye.api/participants/eqtParticipantSettings`;
        const response = await ApiService.patch<ParticipantSettingsResponse>(uri, {
            participantId,
            eqtEnabled,
        });
        return response;
    }

    public async setParticipantsEnabledByDefault(companyId: string, isEnabled: boolean): Promise<CompanyEqtSettings> {
        const uri = `/righteye.api/companies/${companyId}/eqtCompanySettings`;
        const response = await ApiService.put<CompanyEqtSettings>(uri, { participantsEnabledByDefault: isEnabled });
        return response;
    }

    public async getPrograms(): Promise<ProgramResponse[]> {
        const response = await ApiService.get<ProgramResponse[]>('/righteye.api/programs');
        return response;
    }

    public async updateProgram(programId: string, request: UpdateProgramRequest): Promise<ProgramResponse> {
        const uri = `/righteye.api/eqt/programs/${programId}`;
        const response = await ApiService.put<ProgramResponse>(uri, request);
        return response;
    }

    public async getWorkoutPlans(): Promise<WorkoutPlanResponse[]> {
        const response = await ApiService.get<WorkoutPlanResponse[]>('/righteye.api/eqt/workout-plans');
        return response;
    }

    public async getProgram(): Promise<ProgramResponse> {
        const response = await ApiService.get<ProgramResponse>('/righteye.api/eqt/programs/current');
        return response;
    }

    public async getCycle(): Promise<CycleResponse> {
        const response = await ApiService.get<CycleResponse>('/righteye.api/eqt/programs/cycles/current');
        return response;
    }

    public async getWorkout(): Promise<WorkoutResponse> {
        const response = await ApiService.get<WorkoutResponse>('/righteye.api/eqt/programs/cycles/workouts/current');
        return response;
    }

    public async getAllProgramCycles(): Promise<CycleResponse[]> {
        const program = await this.getProgram();
        const response = await ApiService.get<CycleResponse[]>(`/righteye.api/eqt/programs/${program.id}/cycles`);
        return response;
    }

    public async getAllWorkoutsForCycle(): Promise<WorkoutResponse[]> {
        const program = await this.getProgram();
        const cycle = await this.getCycle();
        const uri = `/righteye.api/eqt/programs/${program.id}/cycles/${cycle.id}/workouts`;
        const response = await ApiService.get<WorkoutResponse[]>(uri);
        return response;
    }

    public async getExercise(exerciseId: string): Promise<ExerciseResponse> {
        const response = await ApiService.get<ExerciseResponse>(`/righteye.api/eqt/exercises/${exerciseId}`);
        return response;
    }

    public async createProgram(): Promise<ProgramResponse> {
        const response = await ApiService.post<ProgramResponse>('/righteye.api/eqt/programs', {
            timezone: getTimeZone(),
        });
        return response;
    }

    public async createExercise(exerciseRequest: ExerciseRequest): Promise<ExerciseResponse> {
        const program = await this.getProgram();
        const uri = `/righteye.api/eqt/programs/${program.id}/exercises`;
        const response = await ApiService.post<ExerciseResponse>(uri, exerciseRequest);
        return response;
    }

    public async postSelectedWorkout(workoutPlanId: string): Promise<CycleResponse> {
        const program = await this.getProgram();
        const cycleResponse = await ApiService.post<CycleResponse>(`/righteye.api/eqt/programs/${program.id}/cycles`, {
            workoutPlanId,
        });
        return cycleResponse;
    }
}

export default new TrainerApi();
