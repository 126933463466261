import { STATS } from './common/queryFragments';
import { SACCADIC_LATENCY_NORMS } from './common/queryFragments';

export const CHOICE_REACTION_TIME_NORMS_QUERY = `
choiceReactionTime {
    saccadicLatency ${SACCADIC_LATENCY_NORMS}
    visualReactionSpeed ${STATS}
    processingSpeed ${STATS}
    reactionTime ${STATS}
    responseAccuracy ${STATS}
    distractibility ${STATS}
    impulsivity ${STATS}
}
`;

export const CHOICE_REACTION_TIME_METRIC_QUERY = `
choiceReactionTime {
    metrics {
        saccadicLatency1 { time direction }
        saccadicLatency2 { time direction }
        saccadicLatency3 { time direction }
        saccadicLatency4 { time direction }
        saccadicLatency5 { time direction }
        saccadicLatency6 { time direction }
        saccadicLatency7 { time direction }
        saccadicLatency8 { time direction }
        saccadicLatency { min max average }
        visualReactionSpeed
        processingSpeed
        reactionTime
        responseAccuracy
        distractibility
        impulsivity
        distanceFromScreen
        distanceFromScreenMin
        distanceFromScreenMax
    }
}
`;
