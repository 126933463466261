



























































import Vue, { PropType } from 'vue';

import { DynamicCssClass } from '@/common/types/general';
import * as DateUtils from '@/common/utils/date';

import ExercisesDisplay from '@/views/trainer/admin/components/ExercisesDisplay.vue';
import TextLink from '@/views/common/controls/TextLink.vue';
import EqtAssignmentModel from '@/common/types/trainer/eqtAssignmentModel';
import { ExerciseModel } from '@/common/types/trainer/program';

export default Vue.extend({
    components: {
        ExercisesDisplay,
        TextLink,
    },
    props: {
        participantId: String,
        assignments: Array as PropType<EqtAssignmentModel[]>,
        generalExercises: Array as PropType<ExerciseModel[]>,
    },
    data() {
        return {
            selectedAssignmentIndex: 0 as number,
        };
    },
    computed: {
        hasGeneralAssignment(): boolean {
            return this.assignments.some((a: EqtAssignmentModel) => a.IsGeneralAssignment);
        },
    },
    watch: {
        assignments(newValue) {
            this.selectedAssignmentIndex = newValue.findIndex((a: EqtAssignmentModel) => a.IsSelected);
        },
    },
    methods: {
        alternatingRowColor(index: number): DynamicCssClass | undefined {
            if (index % 2) {
                return { 'even-row-color': true };
            }
        },
        hasAssessment(assignment: EqtAssignmentModel): boolean {
            return assignment?.Assessment?.Id !== undefined ? true : false;
        },
        onAssessmentClick(assignment: EqtAssignmentModel) {
            if (this.hasAssessment(assignment) && assignment.Assessment) {
                this.$router.push({
                    path: `/report/${this.participantId}/standard/${assignment.Assessment.Id}`,
                });
            }
        },
        onSelectedAssignmentChange(newValue: boolean) {
            this.$emit('assignmentChanged', newValue);
        },
        formatDate(date: Date) {
            return DateUtils.getTimeStampFormat(date);
        },
    },
    mounted() {
        this.selectedAssignmentIndex = this.assignments.findIndex((a: EqtAssignmentModel) => a.IsSelected);
    },
});
