import { STATS } from './common/queryFragments';

export const DYNAMIC_VISUAL_ACUITY_1_NORMS_QUERY = `
dynamicVisualAcuity1 {
    dvaScore ${STATS}
}
`;

export const DYNAMIC_VISUAL_ACUITY_1_METRIC_QUERY = `
dynamicVisualAcuity1 {
    metrics {
        dvaScore
        distanceFromScreen
        distanceFromScreenMin
        distanceFromScreenMax
    }
}
`;
