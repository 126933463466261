






import Vue from 'vue';

export default Vue.extend({
    props: {
        exerciseName: {
            type: String,
            required: true,
        },
    },
});
