import { AssessmentType } from '@/common/constants/reports.constants';
import { GraphQlQuery } from '../../common/types/api/requests/graphqlRequests';

export class ParticipantAssessmentsQuery implements GraphQlQuery {
    private readonly participantId: string;
    private readonly assessmentTypes: Set<AssessmentType>;

    public constructor(participantId: string, assessmentTypes: AssessmentType[] = []) {
        this.participantId = participantId;
        this.assessmentTypes = new Set(assessmentTypes);
    }

    public requestBody(): string {
        const query = `
        query ParticipantAssessments($participantId: String!, $assessmentTypes: String!) {
            assessments(assessmentQuery: {
              participantIds: $participantId,
              containsAssessmentTypes: $assessmentTypes
            }) {
               info {
                id,
                dateCreated,
              }
            }
          }`;

        const variables = {
            participantId: this.participantId,
            assessmentTypes: [...this.assessmentTypes],
        };

        const body: any = {
            query,
            variables,
        };

        return JSON.stringify(body);
    }
}
