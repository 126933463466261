export default class Iterator<T> {
    private readonly items: T[];
    private currentIndex: number = 0;

    constructor(items: T[]) {
        this.items = items;
    }

    public next(): T | undefined {
        const nextItem = this.items[this.currentIndex];
        if (nextItem) {
            this.currentIndex += 1;
            return nextItem;
        } else {
            return undefined;
        }
    }
}
