






































































import Vue from 'vue';

import SupportModal from '@/views/common/components/SupportModal.vue';
import ComponentModal from '@/views/common/containers/ComponentModal.vue';

import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import * as CompanyModule from '@/common/types/auth/companyModules';
import { UserRole } from '@/common/types/auth/auth';

import { ENGINEERING_ROLE, SUPER_ADMIN_ROLE, PARTICIPANT_ROLE } from '@/common/constants/userRoles.constants';

import { UPDATE_PROGRAM, UPDATE_CYCLE } from '@/store/trainer/actions';

import ApiService from '@/api/common/apiService';

export default Vue.extend({
    components: {
        ComponentModal,
        SupportModal,
    },
    data() {
        return {
            showSupport: false,
            showNeedHelp: false,
        };
    },
    methods: {
        goHome() {
            /* tslint:disable:no-empty Allow empty block to swallow redundant navigation errors */
            this.$router.push({ name: 'home' }).catch(() => {});
        },
        goToTrainerProgress() {
            /* tslint:disable:no-empty Allow empty block to swallow redundant navigation errors */
            this.$router.push({ name: 'progress' }).catch(() => {});
        },
        goToTrainerWorkoutPlan() {
            /* tslint:disable:no-empty Allow empty block to swallow redundant navigation errors */
            this.$router.push({ name: 'workoutPlan' }).catch(() => {});
        },
        goToHelpPage() {
            /* tslint:disable:no-empty Allow empty block to swallow redundant navigation errors */
            this.$router.push({ name: 'needHelp' }).catch(() => {});
        },
        logout() {
            ApiService.logout();
        },
        setSupportModal(value: boolean) {
            this.showSupport = value;
        },
        setShowNeedHelp(value: boolean) {
            this.showNeedHelp = value;
        },
    },
    computed: {
        currentUser(): AuthenticatedUser | undefined {
            return this.$store.getters.currentUser;
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        userRole(): UserRole | undefined {
            return this.currentUser?.role as UserRole | undefined;
        },
        isTrainerEnabled(): boolean {
            return this.$store.getters.hasModule(CompanyModule.EYEQ_TRAINER) && this.$store.getters.hasProgram;
        },
        hasProgramAndCycle(): boolean {
            const program = this.$store.getters.hasProgram;
            const cycle = this.$store.getters.hasCycle;
            return program && cycle;
        },
    },
    mounted() {
        if (this.hasProgramAndCycle) {
            this.$store.dispatch(UPDATE_PROGRAM);
            this.$store.dispatch(UPDATE_CYCLE);
        }
    },
});
