




















import Vue from 'vue';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import { getDatePickerFormat } from '@/common/utils/date';

export default Vue.extend({
    components: {
        GreenButton,
    },
    props: {
        startingDate: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            selectedDate: '' as string,
            menu: false as boolean,
            minDate: '' as string,
        };
    },
    methods: {
        dateChanged(newDate: string) {
            this.selectedDate = newDate;
            this.menu = false;
            this.$emit('onSelection', getDatePickerFormat(this.selectedDate));
        },
    },
    watch: {
        startingDate(newValue) {
            this.selectedDate = newValue;
        },
    },
    mounted() {
        this.selectedDate = getDatePickerFormat(this.startingDate);
        this.minDate = getDatePickerFormat(new Date());
    },
});
