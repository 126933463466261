































































































































import Vue from 'vue';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import { Country, State } from '@/common/types/company/company';
import { CompanyRequest } from '@/common/types/api/requests/companyRequest';
import companyApi from '@/api/company.api';

export default Vue.extend({
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    components: {
        GreenButton,
    },
    data() {
        return {
            companyName: '',
            accountNumber: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
            rules: {
                required: (value: any) => !!value || 'Required.',
            },
            showConfirmation: false,
            states: [] as State[],
            countries: [] as Country[],
        };
    },
    computed: {
        validCompany(): boolean {
            return (
                this.companyName !== '' &&
                this.accountNumber !== '' &&
                this.address1 !== '' &&
                this.city !== '' &&
                this.country !== '' &&
                this.zipCode !== ''
            );
        },
    },
    methods: {
        async createNewCompany() {
            try {
                if (this.validCompany) {
                    const company = {
                        name: this.companyName,
                        accountNumber: this.accountNumber,
                        address1: this.address1,
                        address2: this.address2,
                        city: this.city,
                        stateCode: this.state === '' ? null : this.state,
                        countryCode: this.country,
                        zipCode: this.zipCode,
                        eyeQTrainerActive: false,
                        populationNormsActive: true,
                        modules: [],
                    } as CompanyRequest;
                    await companyApi.createNewCompany(company);
                    this.showConfirmation = true;
                }
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.hide();
            }
        },
        hide() {
            this.$emit('hide');
        },
        async loadCountries() {
            try {
                this.countries = await companyApi.getListOfCountries();
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async loadStatesForCountry(countryCode: string) {
            try {
                this.states = await companyApi.getListOfStatesByCountry(countryCode);
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
    },
    async mounted() {
        this.loadCountries();
        this.loadStatesForCountry('US');
    },
    watch: {
        show() {
            this.companyName = '';
            this.accountNumber = '';
            this.address1 = '';
            this.address2 = '';
            this.city = '';
            this.state = '';
            this.country = '';
            this.zipCode = '';
        },
        country() {
            this.loadStatesForCountry(this.country);
        },
    },
});
