import { Position2d } from '@/common/types/reports/position';
import { StimulusState } from '@/common/utils/trainer/animation';
import { PursuitAnimationParameters } from '@/common/types/trainer/animation';

export class PursuitAnimation {
    private readonly startPosition: Position2d;
    private readonly radius: number;
    private readonly duration: number;
    private readonly distanceToMove: Position2d;

    public constructor(parameters: PursuitAnimationParameters) {
        this.startPosition = parameters.startPosition;
        this.radius = parameters.radius;

        this.distanceToMove = new Position2d(
            parameters.endPosition.x - parameters.startPosition.x,
            parameters.endPosition.y - parameters.startPosition.y,
        );

        const absoluteDistance = Math.sqrt(Math.pow(this.distanceToMove.x, 2) + Math.pow(this.distanceToMove.y, 2));
        this.duration = (absoluteDistance / parameters.distancePerSecond) * 1000;
    }

    public stimulusState(time: number): StimulusState | undefined {
        if (time > this.duration || time < 0) {
            return undefined;
        } else {
            const normalizedTime = time / this.duration;
            return {
                position: new Position2d(
                    this.startPosition.x + normalizedTime * this.distanceToMove.x,
                    this.startPosition.y + normalizedTime * this.distanceToMove.y,
                ),
                radius: this.radius,
                isVisible: true,
                timerState: undefined,
            };
        }
    }
}
