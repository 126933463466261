










import Vue, { PropType } from 'vue';

import ScoreVisualization, {
    ScoreConfig,
} from '@/views/reports/visualizations/scores/IndividualScoreVisualization.vue';

import { ReportData } from '@/common/types/reports/reportData';

import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        ScoreVisualization,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        individualScoreType: String,
        config: Object as PropType<ScoreConfig>,
    },
    data() {
        return {
            reportType: 'Assessment',
        };
    },
    computed: {
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.myScore'));
            infoText.push(this.$t('reports.info.dynamicVisionScale'));
            infoText.push(this.$t('reports.info.eyeMovementScores'));
            infoText.push(this.$t('reports.info.percentiles'));
            infoText.push(this.$t('reports.info.referenceRange'));

            return infoText;
        },
    },
});
