import ApiService from '@/api/common/apiService';
import { PasswordRequirement, UserRole } from '@/common/types/auth/auth';
import { ForgotPasswordResponse, ResetPasswordResponse } from '@/common/types/api/responses/authResponses';

class AuthApi {
    public async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
        const uri = `/righteye.api/users/resetpassword/token`;
        const requestBody = { email };
        const response = await ApiService.post<ForgotPasswordResponse>(uri, requestBody);
        return response;
    }

    public async resetPassword(token: string, password: string): Promise<ResetPasswordResponse> {
        const uri = `/righteye.api/users/password/${token}`;
        const response = await ApiService.post<ResetPasswordResponse>(uri, password);
        return response;
    }

    public async getPasswordPolicyByToken(token: string): Promise<PasswordRequirement[]> {
        const uri = `/righteye.api/passwordpolicy/${token}`;
        const response = await ApiService.get<PasswordRequirement[]>(uri);
        return response;
    }

    public async getUserRoles(): Promise<UserRole[]> {
        const uri = `/righteye.api/roles`;
        const response = await ApiService.get<UserRole[]>(uri);
        return response;
    }
}

export default new AuthApi();
