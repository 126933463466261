




























import Vue, { PropType } from 'vue';

import { ReportData } from '@/common/types/reports/reportData';
import { ReportNorms } from '@/common/types/reports/norms';
import GamesTable from '../../metrics/GamesTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        GamesTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            gameType: 'bubbleBlast',
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.bubbleBlast?.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.bubbleBlast?.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.bubbleBlast?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.bubbleBlast.score'));
            infoText.push(this.$t('reports.info.bubbleBlast.smoothPursuit'));

            return infoText;
        },
    },
});
