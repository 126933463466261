






import Vue from 'vue';

import Users from '@/views/common/datatables/Users.vue';
import * as Nav from '@/store/navigation/mutations';

export default Vue.extend({
    components: {
        Users,
    },
    data() {
        return {};
    },
    mounted() {
        this.$store.commit(Nav.SHOW_LEFT_NAV);
    },
});
