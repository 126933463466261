export default {
    navigation: {
        title: 'EyeQ Trainer™',
        participants: 'Participants',
        assessments: 'Assessments',
        exercises: 'Exercises',
        settings: 'Settings',
    },
    participants: {
        eyeQTrainerEnabled: {
            true: 'Yes',
            false: 'No',
        },
        noAssociatedUser: 'Add an email address to this participant',
        tableLoading: 'Loading... Please wait',
        dateFallback: 'N/A',
        fields: {
            patientId: 'User Id',
            firstName: 'First Name',
            lastName: 'Last Name',
            company: 'Company',
            status: 'Status',
            startDate: 'Start Date',
            endDate: 'End Date',
            eyeQTrainerEnabled: 'Enabled',
        },
        errors: {
            accessDenied:
                'Access denied, please log out and log back in. If issues persist, ' +
                'please contact customer support at 301-979-7970 or email us at ' +
                'support@righteye.com.',
        },
        assignmentDetails: {
            headers: {
                assessmentFrom: 'Assessment From',
                exercises: 'Exercises',
                workoutPlan: 'Workout Plan',
                exercisesCompleted: 'Workouts Completed',
                endDate: 'End Date',
            },
            changeAssignment: 'Change Assignment',
            workoutPlans: {
                Mix_It_Up: 'Mix It Up',
                Switch_Hit: 'Switch Hit',
                Go_Steady: 'Go Steady',
                undefined: 'N/A',
            },
        },
        updateAssignment: {
            eyeQTrainerEnabled: 'EyeQ Trainer Enabled',
            participantInformation: 'Participant Information',
            currentAssignment: 'Current Assignment',
            chooseAssignment: 'Choose Assignment',
            assignmentSelection: {
                selectAssignment: 'Select Assignment',
                assessmentFrom: 'Assessment From',
                exercises: 'Exercises',
                endDate: 'End Date',
                assignGeneralExercises: 'Assign General Exercises',
            },
        },
        updateParticipantEmail: {
            title: 'Add an Email Address',
            emailAddress: 'Email Address',
            confirmEmailAddress: 'Confirm Email Address',
            buttons: {
                save: 'Save',
                cancel: 'Cancel',
            },
        },
        userDetails: {
            headers: {
                userId: 'User Id',
                name: 'Name',
                dateOfBirth: 'Date of Birth',
                status: 'Status',
            },
        },
    },
    exercises: {
        description: 'Select an exercise or workout plan below to preview',
        undefined: 'N/A',
        labels: {
            fixation: 'Fixation Exercises',
            pursuit: 'Pursuit Exercises',
            saccade: 'Saccade Exercises',
            workoutPlans: 'Workout Plans',
        },
        buttons: {
            'fixation': 'Fixation',
            'pursuit-left': 'Left Pursuit',
            'pursuit-right': 'Right Pursuit',
            'pursuit-vertical': 'Vertical Pursuit',
            'left-v-pattern': 'Left V Pattern',
            'right-v-pattern': 'Right V Pattern',
            'bilateral-v-pattern': 'Bilateral V Pattern',
            'left-a-pattern': 'Left A Pattern',
            'right-a-pattern': 'Right A Pattern',
            'bilateral-a-pattern': 'Bilateral A Pattern',
            'saccade-left': 'Left Saccade',
            'saccade-right': 'Right Saccade',
            'saccade-up': 'Up Saccade',
            'saccade-down': 'Down Saccade',
        },
        workoutPlans: {
            Mix_It_Up: {
                name: 'Mix it Up',
                description: 'One of each exercise during each workout',
                example: 'Example: Fixations, Pursuits then Saccades',
                exercises: ['Fixation', 'Pursuit', 'Saccade'],
            },
            Switch_Hit: {
                name: 'Switch Hit',
                description: 'Do one type of exercise today and a different exercise tomorrow',
                example: 'Example: Fixations today, Pursuits tomorrow',
                exercises: ['Pursuit', 'Pursuit', 'Pursuit'],
            },
            Go_Steady: {
                name: 'Go Steady',
                description: 'One of each exercise during each workout',
                example: 'Example: Fixations for 10 days, Pursuits for 10 days',
                exercises: ['Fixation', 'Fixation', 'Fixation'],
            },
            undefined: {
                name: '',
                description: '',
                example: '',
                exercises: [''],
            },
        },
    },
    tooltips: {
        eqtAssigned: 'Go to EyeQTrainer™',
        eqtNotAssigned: 'This assessment does not qualify for EyeQTrainer™',
    },
};
