

































































import Vue from 'vue';
import Logger from '@/common/utils/logger';
import TrainerApi from '@/api/trainer.api';
import { WORKOUT_IDS } from '@/common/constants/exercises.constants';
import MessageBar from '@/views/common/static/MessageBar.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import WorkoutPlanEntry from '@/views/trainer/exercises/components/workouts/WorkoutPlanEntry.vue';
import ExistingProgram from '@/views/trainer/exercises/components/program/ExistingProgram.vue';
import { TranslateResult } from 'vue-i18n';
import { UPDATE_PROGRAM, UPDATE_CYCLE } from '@/store/trainer/actions';

export default Vue.extend({
    components: { GreenButton, MessageBar, WorkoutPlanEntry, ExistingProgram },
    data() {
        return {
            errorMessage: undefined as TranslateResult | undefined,
            selectedWorkout: 1,
            page: '',
            msg: '',
        };
    },
    methods: {
        async handleSubmit() {
            this.errorMessage = undefined;
            try {
                const workoutId = WORKOUT_IDS[`workout${this.selectedWorkout}`];
                await TrainerApi.postSelectedWorkout(workoutId);
                this.$router.push('landing');
            } catch (error) {
                Logger.error('Failed to set selected workout', error);
                const networkError = this.$t('trainer.forms.connectivityError').toString();
                if (error.message === networkError) {
                    this.errorMessage = this.$t('trainer.forms.chooseWorkoutConnectivityError').toString();
                } else {
                    this.errorMessage = error.message;
                }
            }
        },
        onRadioButtonClicked(workoutNumber: number) {
            this.selectedWorkout = workoutNumber;
        },
    },
    computed: {
        hasProgram(): boolean {
            return this.$store.getters.hasProgram;
        },
        hasCycle(): boolean {
            return this.$store.getters.hasCycle;
        },
    },
    async mounted() {
        await this.$store.dispatch(UPDATE_PROGRAM);
        await this.$store.dispatch(UPDATE_CYCLE);

        if (!this.hasProgram) {
            this.page = 'basics';
            this.msg = 'basics';
            const chooseWorkoutButton = document.getElementById('chooseWorkoutButton') as HTMLButtonElement;
            chooseWorkoutButton.disabled = true;
        }

        if (this.hasCycle) {
            this.page = 'landing';
            this.msg = 'landing';
            const chooseWorkoutButton = document.getElementById('chooseWorkoutButton') as HTMLButtonElement;
            chooseWorkoutButton.disabled = true;
        }
    },
});
