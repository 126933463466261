




import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';

import * as FormatUtils from '@/common/utils/reports/format';
import * as ScoreUtils from '@/common/utils/reports/visualization/score';

export interface OverallScoreConfig {
    overall: number;
    saccades: number;
    pursuits: number;
    fixations: number;
    functionalWindowLowerBound: number;
    functionalWindowUpperBound: number;
    accuracy: number;
    reportVersion: number;
}

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<OverallScoreConfig>,
        },
    },
    methods: {
        calculateScoreColor(score: number): string {
            if (score >= 75) {
                return Constants.GREEN;
            } else if (score >= 50) {
                return Constants.YELLOW;
            } else if (score >= 25) {
                return Constants.ORANGE;
            } else {
                return Constants.RED;
            }
        },
        calculateScorePercentageInRadians(score: number): number {
            return (Math.PI * 2 * score) / 100;
        },
    },
    mounted() {
        const visualization = Visualization.build();

        const overallScore = this.config.reportVersion === 1 ? this.config.overall : this.config.accuracy;

        // Gradiant
        const GRADIANT_LENGTH = 0.66;
        const GRADIANT_PADDING = 0.004;
        const GRADIANT_RECT_HEIGHT = 0.034;

        const SCORE_MARKER_POSITION = GRADIANT_LENGTH * (overallScore / 100) + 0.01;

        // Positions
        const GRADIANT_POSITION = new Position2d(0.025, 0.35);
        const OVERALL_POSITION = new Position2d(0.88, 0.53);
        const ACCURACY_OVERALL_POSITION = new Position2d(0.95, 0.53);
        const OVERALL_TEXT_POSITION = new Position2d(-0.1, 0);
        const ACCURACY_OVERALL_TEXT_POSITION = new Position2d(-0.145, 0);

        // Text
        const TEXT_FONT_FAMILY = 'ProximaNova';
        const MARKER_FONT_FAMILY = 'serif';
        const NUMBER_FONT_SIZE_LARGE = '.0023em';
        const TEXT_FONT_SIZE_LARGE = '.0014em';
        const GRADIANT_MARKER_FONT_SIZE = '.0034em';
        const GRADIANT_SCORE_FONT_SIZE = '.0016em';
        const GRADIANT_WINDOW_FONT_SIZE = '.0012em';
        const FUNCTIONAL_WINDOW_TEXT_OFFSET = GRADIANT_POSITION.y + GRADIANT_RECT_HEIGHT + 0.17;

        const LARGE_CIRCLE_INNER_RADIUS = 0.027;
        const LARGE_CIRCLE_OUTER_RADIUS = 0.039;

        // Functional -> Exceptional Rect
        // const FUNCTIONAL_RECT_WIDTH = GRADIANT_LENGTH - FUNCTIONAL_RANGE_LOWERBOUND;

        // Dysfunctional Rects
        const GRADIANT_RECT_WIDTH = GRADIANT_LENGTH / 4;

        const ASPECT_RATIO = 10 / 1;

        // Overall score
        ScoreUtils.generateScoreCircle(visualization, {
            position: this.config.reportVersion === 1 ? OVERALL_POSITION : ACCURACY_OVERALL_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: LARGE_CIRCLE_OUTER_RADIUS,
            thickness: LARGE_CIRCLE_OUTER_RADIUS - LARGE_CIRCLE_INNER_RADIUS,
            labels: [
                {
                    text:
                        this.config.reportVersion === 1
                            ? this.$t('reports.cards.titles.ScoreDynamicVision').toString()
                            : this.$t('reports.cards.titles.AccuracyScoreDynamicVision').toString(),
                    positionOffset:
                        this.config.reportVersion === 1 ? OVERALL_TEXT_POSITION : ACCURACY_OVERALL_TEXT_POSITION,
                    fontSize: TEXT_FONT_SIZE_LARGE,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
                {
                    text: overallScore.toString(),
                    positionOffset: new Position2d(0, 0),
                    fontSize: NUMBER_FONT_SIZE_LARGE,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
            ],
            color: this.calculateScoreColor(overallScore),
            fillPercent: overallScore,
        });

        // Gradiant
        ScoreUtils.generateScoreBar(
            visualization,
            {
                position: GRADIANT_POSITION,
                height: GRADIANT_RECT_HEIGHT,
                length: GRADIANT_LENGTH,
                aspectRatio: ASPECT_RATIO,
                padding: GRADIANT_PADDING,
                functionalRange: [this.config.functionalWindowLowerBound, this.config.functionalWindowUpperBound],
                labels: [
                    {
                        text: 0,
                        positionOffset: new Position2d(0, -0.001 + FUNCTIONAL_WINDOW_TEXT_OFFSET),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(25, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH + GRADIANT_PADDING,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(50, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 2 + GRADIANT_PADDING * 2,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(75, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 3 + GRADIANT_PADDING * 3,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(100, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 4 + GRADIANT_PADDING * 4,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'end',
                        baseline: 'top',
                    },
                    {
                        text: 'I',
                        positionOffset: new Position2d(SCORE_MARKER_POSITION, -0.04),
                        fontSize: GRADIANT_MARKER_FONT_SIZE,
                        fontFamily: MARKER_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'hanging',
                        draw: overallScore !== undefined,
                    },
                    {
                        text: overallScore.toString(),
                        positionOffset: new Position2d(SCORE_MARKER_POSITION, -0.06),
                        fontSize: GRADIANT_SCORE_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'bottom',
                        draw: overallScore !== undefined,
                    },
                ],
            },
            this.config.reportVersion,
        );

        // Actually build the visualization according to the space avaliable
        visualization.drawNormalized(this.svg, ASPECT_RATIO);
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
});
