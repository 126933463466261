import { Route, NavigationGuardNext } from 'vue-router';

declare const portalUrl: string;

const routes = [
    {
        path: 'portal',
        name: 'portal',
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            window.location.href = portalUrl;
        },
    },
    {
        path: '/brainHealth/:assessmentId',
        name: 'brainHealth',
        props: (route: Route) => ({
            assessmentId: route.params.assessmentId,
        }),
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const brainHealthURL = `/#/viewBrainEyeQ?assessmentId=${to.params.assessmentId}&report=braineyeq`;
            window.location.href = portalUrl + brainHealthURL;
        },
    },
    {
        path: 'functionalVision/:assessmentId',
        name: 'functionalVision',
        props: (route: Route) => ({
            assessmentId: route.params.assessmentId,
        }),
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const functionalVisionURL = `/#/viewFunctionalEyeQ?assessmentId=${to.params.assessmentId}&report=functionaleyeq`;
            window.location.href = portalUrl + functionalVisionURL;
        },
    },
    {
        path: 'reading/:assessmentId',
        name: 'reading',
        props: (route: Route) => ({
            assessmentId: route.params.assessmentId,
        }),
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const readingURL = `/#/viewReadingEyeQ?assessmentId=${to.params.assessmentId}&report=readingeyeq`;
            window.location.href = portalUrl + readingURL;
        },
    },
    {
        path: 'readingStandard/:assessmentId',
        name: 'readingStandard',
        props: (route: Route) => ({
            assessmentId: route.params.assessmentId,
        }),
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const readingStandardURL = `/#/viewReport?assessmentId=${to.params.assessmentId}&report=standard`;
            window.location.href = portalUrl + readingStandardURL;
        },
    },
    {
        path: 'sportsVision/:assessmentId',
        name: 'sportsVision',
        props: (route: Route) => ({
            assessmentId: route.params.assessmentId,
        }),
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const sportsVisionURL = `/#/viewSportsEyeQ?assessmentId=${to.params.assessmentId}&report=sportseyeq`;
            window.location.href = portalUrl + sportsVisionURL;
        },
    },
    {
        path: 'saccadometry/:assessmentId',
        name: 'saccadometry',
        props: (route: Route) => ({
            assessmentId: route.params.assessmentId,
        }),
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const saccadometryURL = `/#/viewSaccadometryTest?assessmentId=${to.params.assessmentId}&report=saccadometrytest`;
            window.location.href = portalUrl + saccadometryURL;
        },
    },
    {
        path: 'users',
        name: 'users',
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const usersURL = '/#/user';
            window.location.href = portalUrl + usersURL;
        },
    },
    {
        path: 'participants',
        name: 'participants',
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const participantsURL = '/#/patientlist';
            window.location.href = portalUrl + participantsURL;
        },
    },
    {
        path: 'protocols',
        name: 'protocols',
        beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            const protocolsURL = '/#/protocol';
            window.location.href = portalUrl + protocolsURL;
        },
    },
];

export default routes;
