import { Position2d } from '@/common/types/reports/position';

export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

export function centimetersToPixels(centimeters: number): number {
    return centimeters * getDevicePpcm();
}

export function cmToPos(xCm: number, yCm: number, canvasId: string): Position2d {
    const canvasElement = document.getElementById(canvasId)!;
    const canvasSize = {
        width: canvasElement.clientWidth,
        height: canvasElement.clientHeight,
    };

    const centerX = canvasSize.width / 2;
    const centerY = canvasSize.height / 2;
    return new Position2d(centerX + centimetersToPixels(xCm), centerY + centimetersToPixels(yCm));
}

export function cmPosToPixelPos(position: Position2d, canvasId: string): Position2d {
    return cmToPos(position.x, position.y, canvasId);
}

export function getDevicePpi(): number {
    const elem = document.createElement('div');
    elem.style.width = '1in';
    document.body.appendChild(elem);
    const ppi = elem.offsetWidth;
    document.body.removeChild(elem);
    return ppi;
}

export function getDevicePpcm(): number {
    return getDevicePpi() / 2.54;
}

export function distance(from: Position2d, to: Position2d): number {
    const xDistance = from.x - to.x;
    const yDistance = from.y - to.y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
}
