import { Route } from 'vue-router';

import {
    CLIENT_USER_ROLE,
    CLIENT_ADMIN_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

import Assessments from '@/views/common/datatables/Assessments.vue';
import CompanyPage from '@/views/company/CompanyPage.vue';
import SportsVisionTrainer from '@/views/company/SportsVisionTrainer.vue';
import SettingsBlocks from '@/views/company/settings/SettingsBlocks.vue';

import TrainerAdminRoutes from '@/router/trainerAdmin';
import BAA from '@/views/static/BAA.vue';

const routes = [
    {
        path: '/company/:companyId',
        name: 'company',
        component: CompanyPage,
        meta: {
            allowedRoles: [CLIENT_USER_ROLE, CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE],
        },
        props: (route: Route) => ({
            companyId: route.params.companyId,
        }),
        children: [
            {
                path: 'assessments',
                name: 'assessments',
                component: Assessments,
                meta: {
                    allowedRoles: [CLIENT_USER_ROLE, SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE, ENGINEERING_ROLE],
                },
            },
            {
                path: 'settings',
                name: 'settings',
                component: SettingsBlocks,
                meta: {
                    allowedRoles: [ENGINEERING_ROLE, SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE],
                },
            },
            {
                path: 'sportsVisionTrainer',
                name: 'sportsVisionTrainer',
                component: SportsVisionTrainer,
                meta: {
                    allowdRoles: [CLIENT_USER_ROLE, SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE, ENGINEERING_ROLE],
                },
            },
            {
                path: 'baa',
                name: 'baaSignedIn',
                component: BAA,
                meta: {
                    allowdRoles: [CLIENT_USER_ROLE, SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE, ENGINEERING_ROLE],
                },
                props: (route: Route) => ({
                    companyId: route.params.companyId,
                }),
            },
            ...TrainerAdminRoutes,
        ],
    },
];

export default routes;
