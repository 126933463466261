import { render, staticRenderFns } from "./ExerciseEntry.vue?vue&type=template&id=2553d82c&scoped=true&"
import script from "./ExerciseEntry.vue?vue&type=script&lang=ts&"
export * from "./ExerciseEntry.vue?vue&type=script&lang=ts&"
import style0 from "./ExerciseEntry.vue?vue&type=style&index=0&id=2553d82c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2553d82c",
  null
  
)

export default component.exports