import { Module } from 'vuex';
import { RootState } from '@/store/index';
import * as Mutations from '@/store/participant/mutations';
import * as Actions from '@/store/participant/actions';
import { Participant } from '@/common/types/user/participant';

import UserApi from '@/api/user.api';

import Logger from '@/common/utils/logger';

interface ParticipantState {
    viewingParticipant?: Participant | undefined;
}

const companyModule: Module<ParticipantState, RootState> = {
    state: {
        viewingParticipant: undefined,
    },
    mutations: {
        [Mutations.SET_VIEWING_PARTICIPANT](state: ParticipantState, participant: Participant) {
            state.viewingParticipant = participant;
        },
        [Mutations.CLEAR_PARTICIPANT_STATE](state: ParticipantState) {
            state.viewingParticipant = undefined;
        },
    },
    actions: {
        async [Actions.SET_VIEWING_PARTICIPANT](context, participantId: string) {
            if (participantId !== context.state.viewingParticipant?.id) {
                try {
                    const participant = await UserApi.getParticipant(participantId);
                    context.commit(Mutations.SET_VIEWING_PARTICIPANT, participant);
                } catch (error) {
                    Logger.error(`There was an error getting company with id ${participantId}: ${error}`);
                }
            }
        },
    },
    getters: {
        viewingParticipant(state: ParticipantState): Participant | undefined {
            return state.viewingParticipant;
        },
    },
};

export default companyModule;
