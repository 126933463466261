import { ExerciseModel } from '@/common/types/trainer/program';

export class ExerciseModels {
    public fixation: ExerciseModel = {
        id: '1e329e4c-821b-11e9-ae38-0a9eef70a27a',
        name: 'fixation',
        displayName: 'Fixation',
    };

    public leftPursuit: ExerciseModel = {
        id: '9dc6cce2-7e4d-11e9-a175-0a2072e50896',
        name: 'pursuit-left',
        displayName: 'Left Pursuit',
    };

    public rightPursuit: ExerciseModel = {
        id: '9dc6d0fe-7e4d-11e9-a175-0a2072e50896',
        name: 'pursuit-right',
        displayName: 'Right Pursuit',
    };

    public verticalPursuit: ExerciseModel = {
        id: '9dc6d2b6-7e4d-11e9-a175-0a2072e50896',
        name: 'pursuit-vertical',
        displayName: 'Vertical Pursuit',
    };

    public leftVPattern: ExerciseModel = {
        id: '9dc6dc5d-7e4d-11e9-a175-0a2072e50896',
        name: 'left-v-pattern',
        displayName: 'Left V Pattern',
    };

    public rightVPattern: ExerciseModel = {
        id: '9dc6daf3-7e4d-11e9-a175-0a2072e50896',
        name: 'right-v-pattern',
        displayName: 'Right V Pattern',
    };

    public bilateralVPattern: ExerciseModel = {
        id: '9dc6d99a-7e4d-11e9-a175-0a2072e50896',
        name: 'bilateral-v-pattern',
        displayName: 'Bilateral V Pattern',
    };

    public leftAPattern: ExerciseModel = {
        id: '9dc6e0a8-7e4d-11e9-a175-0a2072e50896',
        name: 'left-a-pattern',
        displayName: 'Left A Pattern',
    };

    public rightAPattern: ExerciseModel = {
        id: '9dc6df42-7e4d-11e9-a175-0a2072e50896',
        name: 'right-a-pattern',
        displayName: 'Right A Pattern',
    };

    public bilateralAPattern: ExerciseModel = {
        id: '9dc6ddd8-7e4d-11e9-a175-0a2072e50896',
        name: 'bilateral-a-pattern',
        displayName: 'Bilateral A Pattern',
    };

    public rightSaccade: ExerciseModel = {
        id: '9dc6d5c8-7e4d-11e9-a175-0a2072e50896',
        name: 'saccade-right',
        displayName: 'Right Saccade',
    };

    public leftSaccade: ExerciseModel = {
        id: '9dc6d456-7e4d-11e9-a175-0a2072e50896',
        name: 'saccade-left',
        displayName: 'Left Saccade',
    };

    public upSaccade: ExerciseModel = {
        id: '9dc6d70d-7e4d-11e9-a175-0a2072e50896',
        name: 'saccade-up',
        displayName: 'Up Saccade',
    };

    public downSaccade: ExerciseModel = {
        id: '9dc6d846-7e4d-11e9-a175-0a2072e50896',
        name: 'saccade-down',
        displayName: 'Down Saccade',
    };
}

const models = new ExerciseModels();
export default models;

export const EXERCISES: Map<string, ExerciseModel> = new Map();

function addExercise(exercise: ExerciseModel) {
    EXERCISES.set(exercise.id, exercise);
}

addExercise(models.fixation);
addExercise(models.leftPursuit);
addExercise(models.rightPursuit);
addExercise(models.verticalPursuit);
addExercise(models.leftVPattern);
addExercise(models.rightVPattern);
addExercise(models.bilateralVPattern);
addExercise(models.leftAPattern);
addExercise(models.rightAPattern);
addExercise(models.bilateralAPattern);
addExercise(models.leftSaccade);
addExercise(models.rightSaccade);
addExercise(models.upSaccade);
addExercise(models.downSaccade);
