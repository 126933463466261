






































import Vue from 'vue';
import IconButton from '@/views/common/controls/IconButton.vue';
import TextLink from '@/views/common/controls/TextLink.vue';

export default Vue.extend({
    props: {
        testType: {
            type: String,
            required: true,
        },
        info: {
            type: Array,
            required: true,
        },
        showPaper: {
            type: Boolean,
            required: false,
            default: false,
        },
        paperLink: {
            type: String,
            required: false,
        },
        minDistance: {
            type: Number,
            required: false,
            default: undefined,
        },
        maxDistance: {
            type: Number,
            required: false,
            default: undefined,
        },
        averageDistance: {
            type: Number,
            required: false,
            default: undefined,
        },
    },
    components: {
        IconButton,
        TextLink,
    },
    data() {
        return {
            show: false,
        };
    },
});
