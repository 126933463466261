export type AssessmentType = 'dynamic-vision' | 'brain-eyeq' | 'functional-eyeq' | 'sports-eyeq' | 'reading-eyeq';

export type TestType =
    | 'calibration'
    | 'circularSmoothPursuit'
    | 'horizontalSmoothPursuit'
    | 'verticalSmoothPursuit'
    | 'fixationStability'
    | 'choiceReactionTime'
    | 'discriminateReactionTime'
    | 'simpleReactionTime'
    | 'horizontalSaccades'
    | 'verticalSaccades'
    | 'staticVisualAcuity'
    | 'dynamicVisualAcuity1'
    | 'dynamicVisualAcuity2'
    | 'dynamicVisualAcuity3'
    | 'cardinalGazePosition'
    | 'fixationStability'
    | 'contrastSensitivity'
    | 'horizontalGapSaccades'
    | 'randomHorizontalSaccades'
    | 'reading'
    | 'cosmosCombat'
    | 'spaceStorm'
    | 'bubbleBlast'
    | 'mazeMaster'
    | 'nearPointConvergence'
    | 'fourDotFusion'
    | 'antisaccades'
    | 'prosaccades';

export const ALL_TEST_TYPES = [
    'calibration',
    'circularSmoothPursuit',
    'horizontalSmoothPursuit',
    'verticalSmoothPursuit',
    'fixationStability',
    'choiceReactionTime',
    'discriminateReactionTime',
    'simpleReactionTime',
    'horizontalSaccades',
    'verticalSaccades',
    'staticVisualAcuity',
    'dynamicVisualAcuity1',
    'dynamicVisualAcuity2',
    'dynamicVisualAcuity3',
    'contrastSensitivity',
    'horizontalGapSaccades',
    'cardinalGazePosition',
    // 'randomHorizontalSaccades', // Not implemented
    // 'reading', // Not implemented
    'bubbleBlast',
    'cosmosCombat',
    'mazeMaster',
    'spaceStorm',
    'nearPointConvergence',
    'fourDotFusion',
] as TestType[];

export const FDA_STANDARD_TEST_TYPES = [
    'circularSmoothPursuit',
    'horizontalSmoothPursuit',
    'verticalSmoothPursuit',
    'horizontalSaccades',
    'verticalSaccades',
    'horizontalGapSaccades',
] as TestType[];

export const STANDARD_TEST_TYPES = [
    'calibration',
    'fixationStability',
    'choiceReactionTime',
    'discriminateReactionTime',
    'simpleReactionTime',
    'staticVisualAcuity',
    'dynamicVisualAcuity1',
    'dynamicVisualAcuity2',
    'dynamicVisualAcuity3',
    'contrastSensitivity',
    'cardinalGazePosition',
    'bubbleBlast',
    'cosmosCombat',
    'mazeMaster',
    'spaceStorm',
] as TestType[];

export const SENSORIMOTOR_SCREENER_TEST_TYPES = ['calibration', 'nearPointConvergence'] as TestType[];

export const SENSORIMOTOR_EXAM_TEST_TYPES = [
    'calibration',
    'fourDotFusion',
    'cardinalGazePosition',
    'circularSmoothPursuit',
    'horizontalSmoothPursuit',
    'verticalSmoothPursuit',
    'horizontalSaccades',
    'verticalSaccades',
    'fixationStability',
    'horizontalGapSaccades',
    'simpleReactionTime',
] as TestType[];

export type ReportCardName =
    // Standard
    | 'Calibration'
    | 'CircularSmoothPursuit'
    | 'FixationStability'
    | 'HorizontalSaccades'
    | 'HorizontalSmoothPursuit'
    | 'VerticalSaccades'
    | 'VerticalSmoothPursuit'
    | 'ChoiceReactionTime'
    | 'DiscriminateReactionTime'
    | 'SimpleReactionTime'
    | 'StaticVisualAcuity'
    | 'DynamicVisualAcuity1'
    | 'DynamicVisualAcuity2'
    | 'DynamicVisualAcuity3'
    | 'CardinalGazePosition'
    | 'ContrastSensitivity'
    | 'FixationStability'
    | 'HorizontalGapSaccades'
    | 'BubbleBlast'
    | 'CosmosCombat'
    | 'MazeMaster'
    | 'SpaceStorm'
    | 'SaccadicLatency'
    | 'Antisaccades'
    | 'Prosaccades'
    // Dynamic Vision
    | 'CalibrationDynamicVision'
    | 'CircularSmoothPursuitDynamicVision'
    | 'FixationStabilityDynamicVision'
    | 'HorizontalSaccadesDynamicVision'
    | 'HorizontalSmoothPursuitDynamicVision'
    | 'VerticalSaccadesDynamicVision'
    | 'VerticalSmoothPursuitDynamicVision'
    | 'SaccadicLatencyDynamicVision'
    | 'ScoreDynamicVision'
    | 'ChoiceReactionTimeDynamicVision'
    | 'DiscriminateReactionTimeDynamicVision'
    | 'EyeHandReactionTimeDynamicVision'
    | 'IndividualScoreDynamicVision'
    | 'OverallScoreDynamicVision'
    | 'NinePointMotorFunction'
    | 'NearPointConvergence'
    | 'FourDotFusion'
    | 'SensorimotorScreenerResult'
    | 'ConditionsRecommendations'
    | 'SurveyCard'
    | 'SymptomSurvey';

export const REPORT_NAVIGATION_ICONS = {
    Calibration: 'Callibration_Black_Icon_50x50.png',
    CircularSmoothPursuit: 'Circular_Smooth_Pursuits_Black_Icon_50x50.png',
    FixationStability: 'Fixation_Icon_Black_50x50_v1.png',
    HorizontalSaccades: 'HS_Icon_Black_Icon_50x50.png',
    HorizontalSmoothPursuit: 'HSP_Icon_Black_Icon_50x50.png',
    VerticalSaccades: 'VS_Icon_Black_Icon_50x50.png',
    VerticalSmoothPursuit: 'VSP_Icon_Black_Icon_50x50.png',
    ChoiceReactionTime: 'Choice_Reaction_Time_Black_Icon_50x50.png',
    DiscriminateReactionTime: 'Discriminate_Reaction_Time_Icon_Black_50x50_v1.png',
    SimpleReactionTime: 'timer',
    StaticVisualAcuity: 'photo_size_select_small',
    DynamicVisualAcuity1: 'Dynamic_Visual_Acuity_Icon_Black_50x50_v1.png',
    DynamicVisualAcuity2: 'Dynamic_Visual_Acuity_Icon_Black_50x50_v1.png',
    DynamicVisualAcuity3: 'Dynamic_Visual_Acuity_Icon_Black_50x50_v1.png',
    CardinalGazePosition: 'CardinalGaze_Icon_200x200_Black.png',
    ContrastSensitivity: 'adjust',
    HorizontalGapSaccades: 'fas fa-ellipsis-h',
    SaccadicLatency: 'far fa-clock',
    CalibrationDynamicVision: 'Callibration_Black_Icon_50x50.png',
    CircularSmoothPursuitDynamicVision: 'Circular_Smooth_Pursuits_Black_Icon_50x50.png',
    FixationStabilityDynamicVision: 'Fixation_Icon_Black_50x50_v1.png',
    HorizontalSaccadesDynamicVision: 'HS_Icon_Black_Icon_50x50.png',
    HorizontalSmoothPursuitDynamicVision: 'HSP_Icon_Black_Icon_50x50.png',
    VerticalSaccadesDynamicVision: 'VS_Icon_Black_Icon_50x50.png',
    VerticalSmoothPursuitDynamicVision: 'VSP_Icon_Black_Icon_50x50.png',
    SaccadicLatencyDynamicVision: 'far fa-clock',
    EyeHandReactionTimeDynamicVision: 'Choice_Reaction_Time_Black_Icon_50x50.png',
    ChoiceReactionTimeDynamicVision: 'Choice_Reaction_Time_Black_Icon_50x50.png',
    DiscriminateReactionTimeDynamicVision: 'Discriminate_Reaction_Time_Icon_Black_50x50_v1.png',
    ScoreDynamicVision: 'far fa-eye',
    OverallScoreDynamicVision: 'far fa-eye',
    BubbleBlast: 'far fa-dot-circle',
    CosmosCombat: 'fas fa-meteor',
    MazeMaster: 'fab fa-magento',
    SpaceStorm: 'fas fa-space-shuttle',
    NinePointMotorFunction: '9PointMotorFunction_Icon_200x200_Black.png',
    SensorimotorScreenerResult: 'Callibration_Black_Icon_50x50.png',
    NearPointConvergence: 'NearPointofConvergence_Icon_200x200_Black.png',
    FourDotFusion: '4DotFusionTest_Icon_200x200_Black.png',
    ConditionsRecommendations: 'fas fa-stream',
    SurveyCard: 'CISymptomSurvey_Icon_200x200.png',
    SymptomSurvey: 'CISymptomSurvey_Icon_200x200.png',
    Antisaccades: 'HS_Icon_Black_Icon_50x50.png',
    Prosaccades: 'HS_Icon_Black_Icon_50x50.png',
} as Record<ReportCardName, string>;
