













































































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';
import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ButtonState } from '@/common/types/general';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import VergenceVisualization, { VergenceConfig } from '@/views/reports/visualizations/VergenceVisualization.vue';
import VerticalSmoothPursuitVisualization, {
    VerticalSmoothPursuitConfig,
} from '@/views/reports/visualizations/VerticalSmoothPursuitVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';
import { formatNumber } from '@/common/utils/reports/format';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        VergenceVisualization,
        VerticalSmoothPursuitVisualization,
        GreenButton,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        const visualData = VisualDataUtils.extractVisualData(this.reportData, 'verticalSmoothPursuit');

        return {
            config: {
                testData: visualData,
                systemType: this.reportData.assessment.systemType,
            } as VerticalSmoothPursuitConfig,
            vergenceConfig: {
                testData: visualData,
                systemType: this.reportData.assessment.systemType,
                metrics: this.reportData.tests.verticalSmoothPursuit.metrics,
                radius: 0.2,
            } as VergenceConfig,
            playButtonState: 'active' as ButtonState,
            playAnimation: false,
            headers: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: 'Right Eye',
                    value: 'right',
                },
                {
                    text: 'Left Eye',
                    value: 'left',
                },
                {
                    text: 'Both Eyes',
                    value: 'both',
                },
            ],
        };
    },
    methods: {
        playReplayAnimation() {
            this.playButtonState = 'inactive';
            this.playAnimation = true;
        },
        onAnimationFinished() {
            this.playButtonState = 'active';
            this.playAnimation = false;
        },
    },
    computed: {
        tableData(): any {
            const metrics = this.reportData.tests.verticalSmoothPursuit.metrics;

            return [
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.spPercent'),
                    right: formatNumber(metrics.right.spPercent),
                    left: formatNumber(metrics.left.spPercent),
                    both: formatNumber(metrics.both.spPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.spEfficiency'),
                    right: formatNumber(metrics.right.spEfficiency),
                    left: formatNumber(metrics.left.spEfficiency),
                    both: formatNumber(metrics.both.spEfficiency),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.saccadePercent'),
                    right: formatNumber(metrics.right.saccadePercent),
                    left: formatNumber(metrics.left.saccadePercent),
                    both: formatNumber(metrics.both.saccadePercent),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.fixationPercent'),
                    right: formatNumber(metrics.right.fixationPercent),
                    left: formatNumber(metrics.left.fixationPercent),
                    both: formatNumber(metrics.both.fixationPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.eyeTargetVelErr'),
                    right: formatNumber(metrics.right.eyeTargetVelErr),
                    left: formatNumber(metrics.left.eyeTargetVelErr),
                    both: formatNumber(metrics.both.eyeTargetVelErr),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.verticalSynchronizationSp'),
                    right: formatNumber(metrics.right.verticalSynchronizationSp),
                    left: formatNumber(metrics.left.verticalSynchronizationSp),
                    both: formatNumber(metrics.both.verticalSynchronizationSp),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.pathwayLengthDiffTopSide'),
                    right: 'NA',
                    left: 'NA',
                    both: formatNumber(metrics.pathwayLengthDiffTopSide),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.pathwayLengthDiffBottomSide'),
                    right: 'NA',
                    left: 'NA',
                    both: formatNumber(metrics.pathwayLengthDiffBottomSide),
                },
            ];
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.verticalSmoothPursuit?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.verticalSmoothPursuit?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.verticalSmoothPursuit?.metrics,
                'distanceFromScreen',
            );
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sp.smoothPursuit'));
            infoText.push(this.$t('reports.info.pursuits'));
            infoText.push(this.$t('reports.info.sp.saccadeFixation'));
            infoText.push(this.$t('reports.info.sp.eyeTargetVel'));
            infoText.push(this.$t('reports.info.sp.horVertSync'));
            infoText.push(this.$t('reports.info.sp.pathwayLengthDiff'));

            return infoText;
        },
        paperLink(): string {
            if (this.reportData.assessment.systemType === 'I15') {
                return 'reports.info.normsV3.I15.verticalSmoothPursuit';
            }

            return 'reports.info.normsV3.SMI.verticalSmoothPursuit';
        },
    },
});
