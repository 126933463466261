var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"participants"},[_c('div',{staticClass:"page-content"},[_c('v-card',{staticStyle:{"overflow":"auto"},attrs:{"height":"100%"}},[_c('UpdateAssignment',{attrs:{"show":_vm.showUpdateAssignment,"participant":_vm.selectedParticipant},on:{"hide":_vm.hideUpdateAssignment,"save":_vm.savedUpdateAssignment}}),_c('SaveConfirmation',{attrs:{"show":_vm.showConfirmation,"save":_vm.saveEyeQTrainerEnabled,"cancel":_vm.cancelConfirmation}}),_c('RegisterUser',{attrs:{"show":_vm.showRegisterUser,"participant":_vm.userRegistrationParticipant},on:{"onUserRegistration":_vm.onUserRegistration,"hide":_vm.hideRegisterUser}}),_c('v-card-title',[_c('v-row',[_c('v-col',[_c('SearchBar',{attrs:{"search":_vm.search,"clearable":true},on:{"clear":_vm.clearFiltersAndSearch,"input":_vm.executeSearch}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.participants,"search":_vm.search,"server-items-length":_vm.totalParticipants,"sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortByDesc,"loading":"","loading-text":_vm.$t('trainerAdmin.participants.tableLoading'),"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"options":_vm.options,"show-expand":"","item-key":"participantId","items-per-page":10,"footer-props":{
                    'items-per-page-options': [10, 25, 50, 100],
                    'showFirstLastPage': true,
                }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.expandParticipantRow},scopedSlots:_vm._u([{key:"item.patientId",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"text-start truncate",style:(_vm.getWidth())},[_vm._v(_vm._s(item.patientId))])]}},{key:"item.firstName",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"text-start truncate",style:(_vm.getWidth())},[_vm._v(_vm._s(item.firstName))])]}},{key:"item.lastName",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"text-start truncate",style:(_vm.getWidth())},[_vm._v(_vm._s(item.lastName))])]}},{key:"item.eyeQTrainerEnabled",fn:function(ref){
                var item = ref.item;
return [_c('div',{on:{"click":[function($event){return _vm.showRegisterUserPopup(item)},function($event){$event.stopPropagation();}]}},[_c('GreenToggle',{attrs:{"disabled":!item.userId ? true : false,"tooltip":_vm.$t('trainerAdmin.participants.noAssociatedUser')},on:{"input":function($event){return _vm.showConfirmationPopup(item)}},model:{value:(item.eyeQTrainerEnabled),callback:function ($$v) {_vm.$set(item, "eyeQTrainerEnabled", $$v)},expression:"item.eyeQTrainerEnabled"}})],1)]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('AssignmentDetails',{key:_vm.selectedParticipant.isDirty,staticClass:"assignment-details",attrs:{"editable":false,"loading":_vm.participantDetailsLoading},model:{value:(_vm.selectedParticipant),callback:function ($$v) {_vm.selectedParticipant=$$v},expression:"selectedParticipant"}}),_c('GreenButton',{staticClass:"edit-button",nativeOn:{"click":function($event){return _vm.handleEditButton()}}},[_vm._v(" "+_vm._s(_vm.$t('trainerAdmin.participants.assignmentDetails.changeAssignment'))+" ")])],1)]}}],null,true)},[_c('ProgressBar',{attrs:{"slot":"progress","loading":_vm.loading},slot:"progress"})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }