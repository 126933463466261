export default {
    fields: {
        name: 'Name',
        accountNumber: 'Account Number',
        address1: 'Address 1',
        address2: 'Address 2',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip Code',
    },
    deactivate: {
        active: 'Deactivate',
        inactive: 'Activate',
        confirmation: 'Are You Sure?',
        confirm: 'Yes',
        cancel: 'No',
    },
};
