import { Position2d } from '@/common/types/reports/position';
import { StimulusState } from '@/common/utils/trainer/animation';
import { IdleAnimationParameters } from '@/common/types/trainer/animation';

export class IdleAnimation {
    private readonly position: Position2d;
    private readonly radius: number;
    private readonly duration: number;

    public constructor(parameters: IdleAnimationParameters) {
        this.position = parameters.position;
        this.radius = parameters.radius;
        this.duration = parameters.duration;
    }

    public stimulusState(time: number): StimulusState | undefined {
        if (time > this.duration || time < 0) {
            return undefined;
        } else {
            return {
                position: this.position,
                radius: this.radius,
                isVisible: true,
                timerState: undefined,
            };
        }
    }
}
