import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=48a6f291&scoped=true&"
import script from "./Auth.vue?vue&type=script&lang=ts&"
export * from "./Auth.vue?vue&type=script&lang=ts&"
import style0 from "./Auth.vue?vue&type=style&index=0&id=48a6f291&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a6f291",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCol,VIcon,VImg,VRow})
