










import Vue from 'vue';

import * as Nav from '@/store/navigation/mutations';
import * as Companies from '@/store/company/actions';

import * as Settings from '@/common/utils/settings';
import { CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';

import CompanyInfo from '@/views/company/components/CompanyInfo.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';

import { Company } from '@/common/types/company/company';
import { LeftNavLink } from '@/common/types/general';
import * as CompanyModule from '@/common/types/auth/companyModules';

export default Vue.extend({
    components: {
        CompanyInfo,
        LoadingCircle,
    },
    props: {
        companyId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true as boolean,
            userId: '',
        };
    },
    computed: {
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        company(): Company {
            return this.$store.getters.viewingCompany;
        },
    },
    methods: {
        setNavigationLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);

            const titleNavText = this.company.name;
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, titleNavText);

            const links = [
                {
                    text: this.$t('routes.assessments'),
                    to: { path: 'assessments' },
                    icon: 'assignment_ind',
                    enabled: true,
                },
                {
                    text: this.$t('routes.eyeQTrainer'),
                    to: { path: 'eqtparticipants' },
                    icon: 'preview',
                    enabled: this.$store.getters.hasModule(CompanyModule.EYEQ_TRAINER),
                    sublinks: [
                        {
                            text: this.$t('routes.eqtParticipants'),
                            to: { path: 'eqtparticipants' },
                            enabled: true,
                        },
                        {
                            text: this.$t('routes.exercises'),
                            to: { name: 'exercises' },
                            enabled: true,
                        },
                    ],
                },
                {
                    text: this.$t('routes.users'),
                    to: { name: 'users' },
                    icon: 'contacts',
                    enabled: this.isClientAdmin || this.isSuperAdmin || this.isEngineer,
                },
                {
                    text: this.$t('routes.participants'),
                    to: { name: 'participants' },
                    icon: 'people',
                    enabled: this.isClientAdmin,
                },
                {
                    text: this.$t('routes.protocols'),
                    to: { name: 'protocols' },
                    icon: 'fas fa-cubes',
                    enabled: this.isClientAdmin || this.isSuperAdmin || this.isEngineer,
                },
                {
                    text: this.$t('routes.settingsBlocks'),
                    to: { name: 'settings' },
                    icon: 'settings',
                    enabled: Settings.areSettingsEnabled(),
                    sublinks: [
                        {
                            text: this.$t('routes.settings.EqtSettings'),
                            to: { path: 'settings', hash: 'EqtSettings' },
                            enabled: Settings.isSettingsEnabled(Settings.EQT_SETTINGS),
                        },
                        {
                            text: this.$t('routes.settings.CompanyModules'),
                            to: { path: 'settings', hash: 'CompanyModules' },
                            enabled: Settings.isSettingsEnabled(Settings.COMPANY_MODULES),
                        },
                    ],
                },
                {
                    text: this.$t('routes.sportsVisionTrainer'),
                    to: { name: 'sportsVisionTrainer' },
                    icon: 'fas fa-running',
                    enabled: this.isClientAdmin || this.isSuperAdmin || this.isEngineer,
                },
                {
                    text: this.$t('routes.successCenter'),
                    to: { name: 'successCenter' },
                    icon: 'far fa-question-circle',
                    enabled: this.isClientAdmin || this.isSuperAdmin || this.isEngineer,
                },
            ] as LeftNavLink[];
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
    },
    watch: {
        async companyId(newValue) {
            await this.$store.dispatch(Companies.SET_VIEWING_COMPANY, newValue);
            this.setNavigationLinks();
        },
    },
    async mounted() {
        this.loading = true;
        await this.$store.dispatch(Companies.SET_VIEWING_COMPANY, this.companyId);
        this.$store.commit(Nav.SET_LEFT_NAV_LINKS, []);
        this.setNavigationLinks();
        this.loading = false;
    },
});
