

































import Vue, { PropType } from 'vue';

import { AppVersionManifest, AppVersions, VersionOverride } from '@/common/types/appVersions/appVersions';

import IconStatic from '@/views/common/static/IconStatic.vue';
import Overrides from '@/views/superAdmin/components/datatables/Overrides.vue';

export default Vue.extend({
    components: {
        IconStatic,
        Overrides,
    },
    props: {
        appVersions: {
            type: Object as PropType<AppVersions>,
            required: true,
        },
        manifest: {
            type: Object as PropType<AppVersionManifest>,
            required: true,
        },
        patform: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            fields: ['Company', 'System', 'EyeTrackerType'] as string[],
            icons: {
                int: 'fas fa-flask',
                qa: 'fas fa-clipboard-check',
                staging: 'fas fa-hard-hat',
                production: 'fas fa-exclamation-circle',
            },
            overridesByEnvironment: {} as any,
        };
    },
    methods: {
        getOverridesForVersionEnvironmentAndField(
            version: string,
            environment: string,
            field: string,
        ): VersionOverride[] {
            let allOverrides = [] as VersionOverride[];
            // @ts-ignore typescript won't let you index objects by their key names
            const appVersionEnvironment = this.appVersions[environment];

            if (appVersionEnvironment.appOverrides?.[field]) {
                allOverrides = allOverrides.concat(appVersionEnvironment.appOverrides[field]);
            }
            if (appVersionEnvironment.launcherOverrides?.[field]) {
                allOverrides = allOverrides.concat(appVersionEnvironment.launcherOverrides[field]);
            }
            if (appVersionEnvironment.deltavOverrides?.[field]) {
                allOverrides = allOverrides.concat(appVersionEnvironment.deltavOverrides[field]);
            }

            return allOverrides.filter((o: VersionOverride) => o.Version === version);
        },
    },
});
