import { STATS } from './common/queryFragments';

const HS_DATA = `{
    value
    leftSide
    rightSide
}
`;

const HS_METRICS_LRB = `{
    saccadeNum ${HS_DATA}
    fixationNum ${HS_DATA}
    onTarget ${HS_DATA}
    band2Over ${HS_DATA}
    band2Under ${HS_DATA}
    band3Over ${HS_DATA}
    band3Under ${HS_DATA}
    saccadicEfficiency ${HS_DATA}
    saccadicTargeting ${HS_DATA}
    missed
    speedAccuracyTradeoff
    saccadicRecovery
    saccadicVariation
    saccadicVelocity
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const HS_DATA_NORMS = `{
    value ${STATS}
    leftSide ${STATS}
    rightSide ${STATS}
}
`;

const HS_NORMS_LRB = `{
    saccadeNum ${HS_DATA_NORMS}
    fixationNum ${HS_DATA_NORMS}
    onTarget ${HS_DATA_NORMS}
    band2Over ${HS_DATA_NORMS}
    band2Under ${HS_DATA_NORMS}
    band3Over ${HS_DATA_NORMS}
    band3Under ${HS_DATA_NORMS}
    saccadicEfficiency ${HS_DATA_NORMS}
    saccadicTargeting ${HS_DATA_NORMS}
    missed ${STATS}
    speedAccuracyTradeoff ${STATS}
    saccadicRecovery ${STATS}
    saccadicVariation ${STATS}
    saccadicVelocity ${STATS}
}
`;

export const HORIZONTAL_SACCADES_NORMS_QUERY = `
horizontalSaccades {
    left ${HS_NORMS_LRB}
    right ${HS_NORMS_LRB}
}
`;

export const HORIZONTAL_SACCADES_METRIC_QUERY = `
horizontalSaccades {
    metrics {
        left ${HS_METRICS_LRB}
        right ${HS_METRICS_LRB}
    }
    visualData {
        gazeData {
            timeStamp
            left {
                x
                y
            }
            right {
                x
                y
            }
        }
    }
}
`;
