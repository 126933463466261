









































import Vue from 'vue';
import IconButton from '@/views/common/controls/IconButton.vue';
import TextLink from '@/views/common/controls/TextLink.vue';

export default Vue.extend({
    props: {
        modalTitle: {
            type: String,
            required: true,
        },
        info: {
            type: Object,
            required: true,
        },
    },
    components: {
        IconButton,
        TextLink,
    },
    data() {
        return {
            show: false,
        };
    },
});
