export const BUBBLE_BLAST_NORMS_QUERY = `
bubbleBlast {
    eyeType
    level
    bubbleSizePercent
    bubbleDirection
    score {
      mean
      stdDev
    }
    smoothPursuitPercent {
      mean
      stdDev
    }
}
`;

export const BUBBLE_BLAST_METRIC_QUERY = `
bubbleBlast {
    info {
        attemptNumber
    }
    metrics {
        eyeType
        level
        score
        smoothPursuitPercent
        bubbleSizePercent
        bubbleDirection
        distanceFromScreenMin
        distanceFromScreenMax
        left {
          distanceFromScreen
        }
        right {
          distanceFromScreen
        }
        both {
          distanceFromScreen
        }
    }
}
`;
