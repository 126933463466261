
























































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';

import { ButtonState } from '@/common/types/general';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import VerticalSmoothPursuitVisualization, {
    VerticalSmoothPursuitConfig,
} from '@/views/reports/visualizations/VerticalSmoothPursuitVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';
import { formatNumber } from '@/common/utils/reports/format';

export default Vue.extend({
    components: {
        GreenButton,
        VerticalSmoothPursuitVisualization,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            type: Boolean,
            required: false,
            default: false,
        },
        reportVersion: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            metrics: undefined as any,
            config: undefined as VerticalSmoothPursuitConfig | undefined,
            playButtonState: 'active' as ButtonState,
            playAnimation: false,
            headers: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',

                    width: '80%',
                },
                {
                    text: 'Right',
                    value: 'right',
                },
                {
                    text: 'Left',
                    value: 'left',
                },
            ],
        };
    },
    methods: {
        playReplayAnimation() {
            this.playButtonState = 'inactive';
            this.playAnimation = true;
        },
        onAnimationFinished() {
            this.playButtonState = 'active';
            this.playAnimation = false;
        },
        loadReportData(reportData: ReportData) {
            if (reportData.tests.verticalSmoothPursuit) {
                this.metrics = reportData.tests.verticalSmoothPursuit.metrics;
                const visualData = VisualDataUtils.extractVisualData(this.reportData, 'verticalSmoothPursuit');
                const systemType = this.reportData.assessment?.systemType
                    ? this.reportData.assessment.systemType
                    : 'I15';
                this.config = {
                    testData: visualData,
                    systemType,
                } as VerticalSmoothPursuitConfig;
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
    computed: {
        tableData(): any {
            return [
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.dynamicVision.spPercent'),
                    right: formatNumber(this.metrics?.right.spPercent),
                    left: formatNumber(this.metrics?.left.spPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.verticalSmoothPursuit.dynamicVision.spEfficiency'),
                    right: formatNumber(this.metrics?.right.spEfficiency),
                    left: formatNumber(this.metrics?.left.spEfficiency),
                },
            ];
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sp.smoothPursuit'));
            infoText.push(this.$t('reports.info.pursuits'));
            infoText.push(this.$t('reports.info.sp.saccadeFixation'));
            infoText.push(this.$t('reports.info.sp.eyeTargetVel'));
            infoText.push(this.$t('reports.info.sp.horVertSync'));

            return infoText;
        },
        paperLink(): string {
            if (this.config?.systemType === 'I15') {
                return 'reports.info.normsV3.I15.verticalSmoothPursuit';
            } else {
                return 'reports.info.normsV3.SMI.verticalSmoothPursuit';
            }
        },
    },
});
