














import Vue from 'vue';
export default Vue.extend({
    props: {
        search: {
            type: String,
            required: false,
            default: '',
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            searchTerms: '',
            awaitingSearch: false,
        };
    },
    computed: {
        clearIcon(): string {
            return this.clearable ? 'clear' : '';
        },
    },
    methods: {
        clear() {
            this.$emit('clear');
        },
    },
    watch: {
        search(newValue) {
            this.searchTerms = newValue;
        },
        searchTerms(newValue, oldValue) {
            if (!this.awaitingSearch && newValue !== oldValue) {
                setTimeout(() => {
                    this.$emit('input', this.searchTerms);
                    this.awaitingSearch = false;
                }, 1500); // 1.5 sec delay
            }
            this.awaitingSearch = true;
        },
    },
});
