




































































import Vue from 'vue';
import { LeftNavLink } from '@/common/types/general';
import IconStatic from '@/views/common/static/IconStatic.vue';

declare const dashboardVersion: string;

export default Vue.extend({
    components: {
        IconStatic,
    },
    data() {
        return {
            iconSize: 18 as number,
            dashboardVersion,
            drawer: true,
            mini: false,
            toggleNavBarIcon: 'keyboard_arrow_left',
        };
    },
    computed: {
        titleLink(): string {
            return this.$store.getters.leftNavTitleText;
        },
        links(): LeftNavLink[] {
            return this.$store.getters.leftNavLinks;
        },
        navWidth(): number {
            if (screen.width <= 1024) {
                return 200;
            } else {
                return 300;
            }
        },
    },
    methods: {
        back() {
            this.$router.back();
        },
        toggleMini() {
            this.mini = !this.mini;
            if (this.toggleNavBarIcon === 'keyboard_arrow_left') {
                this.toggleNavBarIcon = 'keyboard_arrow_right';
            } else {
                this.toggleNavBarIcon = 'keyboard_arrow_left';
            }
        },
    },
});
