export default {
    fields: {
        patientId: 'User Id',
        name: 'Name',
        dateOfBirth: 'Date of Birth',
        assessmentDate: 'Assessment Date',
        gender: 'Gender',
        ethnicity: 'Ethnicity',
        notes: 'Notes',
        vision: 'Vision Conditions',
        medical: 'Medical Conditions',
        firstName: 'First Name',
        middleName: 'Middle Name (Optional)',
        lastName: 'Last Name',
        address1: 'Address 1 (Optional)',
        address2: 'Address 2 (Optional)',
        city: 'City (Optional)',
        state: 'State (Optional)',
        country: 'Country (Optional)',
        company: 'Company',
        role: 'Role',
        phone: 'Phone (Optional)',
        mobile: 'Mobile (Optional)',
        email: 'Email',
    },
    tooltips: {
        expand: 'View More Information',
        locked: 'This user needs to change their password.',
    },
    buttons: {
        save: 'Save',
        createUser: 'Create User',
    },
    forms: {
        createUser: 'Create User',
        editUser: 'Edit User',
        search: 'Search',
    },
};
