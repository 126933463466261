var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.manifests,"hide-default-footer":_vm.manifests.length < 10,"item-key":"Version","show-select":"","single-select":false,"show-expand":"","single-expand":""},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.Environments",fn:function(ref){
var item = ref.item;
return _vm._l((item.Environments),function(tag){return _c('TagChip',{key:tag,attrs:{"label":tag,"color":_vm.ColorConstants.GREEN}})})}},{key:"item.Tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.Tags),function(tag){return _c('TagChip',{key:tag,attrs:{"label":tag}})})}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('OverridesByEnvironment',{attrs:{"appVersions":_vm.appVersions,"manifest":item,"platform":item.platform}})],1)]}}],null,true),model:{value:(_vm.selectedManifests),callback:function ($$v) {_vm.selectedManifests=$$v},expression:"selectedManifests"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }