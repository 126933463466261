















































































import Vue, { PropType } from 'vue';

import { Assessment, AssessmentReport } from '@/common/types/reports/assessment';

import Logger from '@/common/utils/logger';
import * as DataTableUtils from '@/common/utils/datatables';

import { SET_ERROR_BANNER } from '@/store/general/mutations';

import {
    CLIENT_ADMIN_ROLE,
    CLIENT_USER_ROLE,
    PARTICIPANT_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';
import AssessmentApi from '@/api/assessment.api';

import IconButton from '@/views/common/controls/IconButton.vue';
import ProgressBar from '@/views/common/static/ProgressBar.vue';
import SearchBar from '@/views/common/controls/SearchBar.vue';
import EditableTextField from '@/views/common/controls/EditableTextField.vue';
import SelectReportMenu from '@/views/company/components/SelectReportMenu.vue';
import SettingsDropdown from '@/views/company/components/SettingsDropdown.vue';
import ReportActionsMenu from '@/views/company/components/ReportActionsMenu.vue';

import { getLocalTimeStampFormat } from '@/common/utils/date';
import { Company } from '@/common/types/company/company';
import { PaginatedResponse } from '@/common/types/api/responses/paginatedResponses';

const DATATABLE_OPTIONS_STORE_KEY = 'ASSESSMENTS';

export default Vue.extend({
    components: {
        EditableTextField,
        IconButton,
        ProgressBar,
        ReportActionsMenu,
        SelectReportMenu,
        SettingsDropdown,
        SearchBar,
    },
    props: {
        company: {
            type: Object as PropType<Company>,
            required: false,
        },
        userId: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            search: '',
            expanded: [] as Assessment[],
            defaultSortBy: 'dateCreated',
            defaultSortByDesc: true,
            defaultSortOrder: 'DESC' as string,
            options: {} as any,
            totalAssessments: 0 as number,
            items: [] as Assessment[],
            cellWidth: window.innerWidth / 8,
            showHiddenAssessments: false,
        };
    },
    computed: {
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isClientUser(): boolean {
            return this.$store.getters.userRole === CLIENT_USER_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
        headers(): any[] {
            const headers = [
                {
                    text: '',
                    value: 'data-table-expand',
                    align: 'start',
                },
                {
                    text: 'Date',
                    align: 'start',
                    sortable: true,
                    value: 'dateCreated',
                    sort(rhs: string, lhs: string) {
                        return rhs && lhs && new Date(rhs) < new Date(lhs) ? -1 : 1;
                    },
                },
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'participant.name',
                },
                {
                    text: 'User Id',
                    align: 'start',
                    sortable: false,
                    value: 'participant.patientId',
                },
                {
                    text: 'Assessment Type',
                    align: 'start',
                    sortable: true,
                    value: 'assessmentType.name',
                },
            ];

            if (!this.isParticipant) {
                headers.push({
                    text: 'Notes',
                    align: 'start',
                    sortable: true,
                    value: 'notes',
                });
            }

            return headers;
        },
        activeAssessmentsOnly(): boolean | undefined {
            if (this.showHiddenAssessments) {
                return undefined;
            } else {
                return true;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        const cache = DataTableUtils.getDataTableOptionsCache(
            DATATABLE_OPTIONS_STORE_KEY,
            this.options,
            this.search,
            this.expanded,
        );
        this.options = cache.options;
        this.search = cache.search;
        this.expanded = cache.selected;
    },
    methods: {
        async saveAssessmentNotes(item: Assessment) {
            try {
                const response = await AssessmentApi.updateAssessmentNotes(item.id, item.notes);
                item.notes = response.notes;
            } catch (error) {
                Logger.error(`Failed to load company: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error);
            }
        },
        async getDataFromApi() {
            this.loading = true;

            let assessmentsPage = {} as PaginatedResponse<AssessmentReport>;
            const { sortBy, sortOrder } = DataTableUtils.parseSortOptions(
                this.options.sortBy,
                this.options.sortDesc,
                this.defaultSortBy,
                this.defaultSortOrder,
            );

            if (this.userId) {
                assessmentsPage = await AssessmentApi.getAssessmentsByUserPaginated(
                    this.userId,
                    this.options.page,
                    this.options.itemsPerPage,
                    this.search,
                    sortBy,
                    sortOrder,
                );
            } else if (this.company) {
                assessmentsPage = await AssessmentApi.getAssessmentsByCompanyPaginated(
                    this.company.id,
                    this.options.page,
                    this.options.itemsPerPage,
                    this.search,
                    sortBy,
                    sortOrder,
                    this.activeAssessmentsOnly,
                );
            }

            this.items = assessmentsPage.data.map((item) => item.assessment);
            this.totalAssessments = assessmentsPage.totalRecords;
            this.loading = false;
        },
        formatDate(date: string) {
            // Assessment Date from api is guaranteed to be UTC
            date = getLocalTimeStampFormat(date);
            return date;
        },
        getWidth() {
            return {
                'max-width': this.cellWidth + 'px',
            };
        },
        onResize() {
            this.cellWidth = window.innerWidth / 8;
        },
        expandRow(row: Assessment) {
            if (!this.expanded.includes(row)) {
                this.expanded = [row];
            } else {
                this.expanded = [];
            }
            DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search, this.expanded);
        },
        setShowHiddenAssessments(showHiddenAssessments: boolean) {
            this.showHiddenAssessments = showHiddenAssessments;
            this.getDataFromApi();
        },
        rowClass(assessment: Assessment) {
            return assessment.active ? 'assessment-active' : 'assessment-inactive';
        },
        updateAssessmentItem(updatedAssessment: Assessment) {
            for (let item of this.items) {
                if (item.id === updatedAssessment.id) {
                    item = updatedAssessment;
                    break;
                }
            }
        },
        executeSearch(search: string) {
            this.search = search;
            this.getDataFromApi();
            DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search, this.expanded);
        },
        clearFiltersAndSearch() {
            this.options = DataTableUtils.getDefaultOptions(this.defaultSortBy, this.defaultSortOrder);
            this.search = '';
            DataTableUtils.clearDataTableCache(DATATABLE_OPTIONS_STORE_KEY);
        },
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi();
                DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search, this.expanded);
            },
            deep: true,
        },
        itemsPerPage() {
            this.getDataFromApi();
        },
    },
});
