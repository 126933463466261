




























import Vue from 'vue';
export default Vue.extend({
    props: {
        icon: {
            type: String,
            required: false,
            default: undefined,
        },
        size: {
            type: [Number, String],
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        tooltip: {
            type: String,
            required: false,
            default: '',
        },
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabledTooltip: {
            type: String,
            required: false,
            default: '',
        },
        tooltipDirection: {
            type: String,
            required: false,
            default: 'bottom',
        },
    },
    computed: {
        isAsset(): boolean {
            if ((this.icon && this.icon.includes('png')) || this.icon.includes('svg')) {
                return true;
            } else {
                return false;
            }
        },
        tooltipMessage(): string {
            return !this.disabled ? this.tooltip : this.disabledTooltip;
        },
    },
});
