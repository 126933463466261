import { EqtParticipantResponse, ParticipantSummaryResponse } from '@/common/types/api/responses/trainerResponses';
import EqtParticipantDetails from '@/common/types/trainer/eqtParticipantDetails';
import EqtAssignmentModel from '@/common/types/trainer/eqtAssignmentModel';
import ProgramDetails from '@/common/types/trainer/programDetails';
import { ExerciseModel } from '@/common/types/trainer/program';
import { getDOBFormat } from '@/common/utils/date';

export interface StringKeyDictionary {
    [key: string]: any;
}

export class EqtParticipant {
    // Searchable/Sortable Fields
    public patientId: string;
    public firstName: string;
    public lastName: string;
    public company: string;
    public status: string;
    public startDate: string;
    public endDate: string;
    public eyeQTrainerEnabled: boolean;

    // Other fields
    public participantId: string;
    public userId: string;
    public companyId: string;
    public dateOfBirth: string;
    public isDirty: boolean;
    public program: ProgramDetails | undefined;
    public assignments: EqtAssignmentModel[];
    public currentAssignment: EqtAssignmentModel | undefined;
    public generalExercises: ExerciseModel[][];

    public constructor(response: EqtParticipantResponse) {
        this.participantId = response.participantId;
        this.patientId = response.patientId;
        this.userId = response.userId;
        this.firstName = response.firstName;
        this.lastName = response.lastName;
        this.company = response.companyName;
        this.companyId = response.companyId;
        switch (response.status) {
            case 'ASSESSED':
                this.status = 'Assessed';
                break;
            case 'ACTIVE':
                this.status = 'Active';
                break;
            case 'CYCLE_ENDED':
                this.status = 'Cycle Ended';
                break;
            case 'NOT_STARTED':
                this.status = 'Not Started';
                break;
            case 'COMPLETE':
                this.status = 'Complete';
                break;
            default:
                this.status = '';
                break;
        }
        this.startDate = this.status !== 'NOT_STARTED' && response.startDate ? response.startDate : 'N/A';
        this.endDate = this.status !== 'NOT_STARTED' && response.startDate ? response.endDate : 'N/A';
        this.eyeQTrainerEnabled = response.eyeQTrainerEnabled;
        this.dateOfBirth = response.dateOfBirth ? getDOBFormat(response.dateOfBirth) : response.dateOfBirth;
        this.isDirty = true;
    }

    public loadDetails(detailsResponse: ParticipantSummaryResponse) {
        const eqtParticipantDetails = new EqtParticipantDetails(detailsResponse);
        this.currentAssignment = eqtParticipantDetails.assignments.find((a) => a.IsSelected);
        this.program = eqtParticipantDetails.program;
        this.assignments = eqtParticipantDetails.assignments;
        this.generalExercises = eqtParticipantDetails.generalExercises;
    }
}
