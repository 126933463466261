import Auth from '@/views/auth/Auth.vue';
import Login from '@/views/auth/components/Login.vue';
import ForgotPassword from '@/views/auth/components/ForgotPassword.vue';
import ResetPassword from '@/views/auth/components/ResetPassword.vue';
import EmailUnsubscribe from '@/views/static/EmailUnsubscribe.vue';

const routes = [
    {
        path: '/auth',
        component: Auth,
        children: [
            {
                path: '/login',
                name: 'login',
                component: Login,
                props: true,
            },
            {
                path: '/forgotPassword',
                name: 'forgotPassword',
                component: ForgotPassword,
            },
            {
                name: 'resetPassword',
                path: '/resetPassword/:token',
                component: ResetPassword,
            },
            {
                path: '/emailUnsubscribe',
                name: 'emailUnsubscribe',
                component: EmailUnsubscribe,
            },
        ],
    },
];

export default routes;
