





















import { EqtParticipant } from '@/common/types/trainer/eqtParticipant';
import Vue, { PropType } from 'vue';
export default Vue.extend({
    props: {
        userDetails: Object as PropType<EqtParticipant>,
    },
});
