import { GraphQlQuery } from '../../common/types/api/requests/graphqlRequests';

export class ComparativeAssessmentListQuery implements GraphQlQuery {
    private readonly normCategory: string;

    public constructor(normCategory: string) {
        this.normCategory = normCategory;
    }

    public requestBody(): string {
        const query = `query ComparativeAssessment($normCategory: String!){
          comparativeAssessments(comparativeAssessmentQuery: {normsCategories : $normCategory}) {
            info {
              label
              id
              normsCategory
            }
          }
        }`;

        const variables = {
            normCategory: this.normCategory,
        };

        const body: any = {
            query,
            variables,
        };

        return JSON.stringify(body);
    }
}
