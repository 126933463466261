export class Position2d {
    public x: number;
    public y: number;

    public constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public get() {
        return { x: this.x, y: this.y };
    }

    public get coordinates(): [number, number] {
        return [this.x, this.y];
    }
}
