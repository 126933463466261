import axios, { AxiosError, AxiosInstance } from 'axios';
import * as AuthenticationService from '@/api/common/authenticationService';
import store from '@/store/index';
import router from '@/router';
import { i18n } from '@/main';

import { CustomRequest, HttpMethod } from '@/common/types/api/requests/customRequest';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import { User } from '@/common/types/user/user';
import { SET_AUTHENTICATED_USER, DELETE_AUTHENTICATED_USER } from '@/store/auth/mutations';
import Logger from '@/common/utils/logger';

declare const apiUrl: string;
declare const dataApiUrl: string;

export default class AuthenticatedHttpClient {
    private readonly client: AxiosInstance;

    constructor() {
        this.client = this.createStandardClient();
    }

    public async request<T>(request: CustomRequest, authorization?: string, authorize = true): Promise<T> {
        try {
            if (!request.headers) {
                request.headers = {};
            }

            if (authorize && !authorization) {
                const authHeader = await this.getAuthHeader();
                request.headers.Authorization = authHeader;
            } else if (authorize && authorization) {
                request.headers.Authorization = authorization;
            }

            const response = await this.client.request<T>(request);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async authenticateWithCredentials(username: string, password: string): Promise<AuthenticatedUser> {
        try {
            const authenticationToken = await AuthenticationService.authenticate(username, password);
            const userProfile = await this.getUserProfile(authenticationToken.userId, authenticationToken.idToken);
            const authenticatedUser = await AuthenticatedUser.fromAuthenticationResponse(
                authenticationToken,
                userProfile,
            );
            await store.commit(SET_AUTHENTICATED_USER, authenticatedUser);
            return authenticatedUser;
        } catch (error) {
            throw error;
        }
    }

    public async logout(error?: Error) {
        store.commit(DELETE_AUTHENTICATED_USER);
        this.redirectToLoginScreen(error);
    }

    private redirectToLoginScreen(error?: Error, redirectUrl?: string) {
        router
            .push({
                name: 'login',
                params: {
                    redirect: redirectUrl as string,
                    errorMessage: error?.message as string,
                },
            })
            .catch((err: Error) => {
                // Swallow redundant navigation errors when coming from login, meaningless block to satisfy tslint
                err = err;
            });
    }

    private async authenticateWithRefreshToken(refreshToken: string): Promise<AuthenticatedUser> {
        try {
            const refreshAuthenticationToken = await AuthenticationService.authenticateWithRefreshToken(refreshToken);
            const authenticatedUser = store.getters.currentUser as AuthenticatedUser;
            authenticatedUser.idToken = refreshAuthenticationToken.idToken;
            authenticatedUser.refreshToken = refreshAuthenticationToken.refreshToken
                ? refreshAuthenticationToken.refreshToken
                : authenticatedUser.refreshToken;
            await store.commit(SET_AUTHENTICATED_USER, authenticatedUser);
            return authenticatedUser;
        } catch (error) {
            throw error;
        }
    }

    private async getUserProfile(userId: string, authorization: string): Promise<User> {
        const userProfileRequest = {
            url: `righteye.api/users/${userId}`,
            method: 'GET' as HttpMethod,
        };
        const response = await this.request<any>(userProfileRequest, authorization);
        return response.data;
    }

    private async getAuthHeader(): Promise<string> {
        const user = store.getters.currentUser as AuthenticatedUser | undefined;
        if (user) {
            return this.getAuthHeaderForUser(user);
        }
        return '';
    }

    private async getAuthHeaderForUser(user: AuthenticatedUser): Promise<string> {
        if (user.isIdTokenValid()) {
            return user.authenticationHeader();
        } else {
            try {
                const newUser = await this.authenticateWithRefreshToken(user.refreshToken);
                return newUser.authenticationHeader();
            } catch (error) {
                store.commit(DELETE_AUTHENTICATED_USER);
                this.redirectToLoginScreen(error as Error, router.currentRoute.fullPath);
                throw error;
            }
        }
    }

    private createStandardClient(): AxiosInstance {
        const client = axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        client.interceptors.request.use(
            (request) => {
                if (request.url?.includes('/graphql')) {
                    request.baseURL = dataApiUrl;
                }
                return request;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        client.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const statusCode = error.response?.status;
                if ([401, 403].includes(statusCode)) {
                    error = this.translateErrorMessage(error);
                    this.logout(error);
                } else if ([500].includes(statusCode)) {
                    error = new Error(i18n.t('login.errors.unknownError').toString());
                } else {
                    error = this.translateErrorMessage(error);
                }
                Logger.error(error.message);
                return Promise.reject(error);
            },
        );

        return client;
    }

    private translateErrorMessage(error: AxiosError) {
        if (error.response?.data.message) {
            // Api error
            const message = this.getTranslationString(error.response?.data.message);
            return new Error(message);
        } else if (error.response?.status === 403 && !error.response.data?.includes('Your account has been locked')) {
            // Access denied due to permissions
            return new Error(i18n.t('errors.auth.accessDenied').toString());
        } else if (error.isAxiosError === true && error.message === 'Network Error') {
            // Network issues
            return new Error(i18n.t('forms.connectivityError').toString());
        } else if (error.response?.data?.errors) {
            // Api Business Logic errors
            const message = error.response.data.errors.map((err: Error) => err.message).join('; ');
            return new Error(message);
        } else if (error.message) {
            // Cognito error message
            const message = this.getTranslationString(error.message);
            return new Error(message);
        } else {
            // Anything else
            return new Error(i18n.t('forms.connectivityError').toString());
        }
    }

    private getTranslationString(error: string) {
        try {
            const key = error.replace('.', '');
            if (i18n.te(`errors.auth.${key}`)) {
                return i18n.t(`errors.auth.${key}`).toString();
            } else {
                return error.toString();
            }
        } catch (exception) {
            return error.toString();
        }
    }
}
