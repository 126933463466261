


































import Vue, { PropType } from 'vue';

import { Versions } from '@/common/types/appVersions/appVersions';

import IconStatic from '@/views/common/static/IconStatic.vue';
import Overrides from '@/views/superAdmin/components/datatables/Overrides.vue';

export default Vue.extend({
    components: {
        IconStatic,
        Overrides,
    },
    props: {
        versions: {
            type: Object as PropType<Versions>,
            required: true,
        },
        environment: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            icons: {
                app: 'fas fa-satellite',
                launcher: 'fas fa-rocket',
                deltav: 'fas fa-plane-departure',
            } as Record<string, string>,
            platforms: ['app', 'launcher', 'deltav'] as string[],
            overrides: {} as any,
        };
    },
    watch: {
        versions(newValue: Versions) {
            this.loading = true;
            this.overrides = this.extractOverrides(newValue);
            this.loading = false;
        },
    },
    methods: {
        extractOverrides(versions: Versions): any {
            const overrides = {
                app: {
                    Company: versions.appOverrides?.Company ? versions.appOverrides?.Company : [],
                    System: versions.appOverrides?.System ? versions.appOverrides?.System : [],
                    EyeTrackerType: versions.appOverrides?.EyeTrackerType ? versions.appOverrides?.EyeTrackerType : [],
                },
                launcher: {
                    Company: versions.launcherOverrides?.Company ? versions.launcherOverrides?.Company : [],
                    System: versions.launcherOverrides?.System ? versions.launcherOverrides?.System : [],
                    EyeTrackerType: versions.launcherOverrides?.EyeTrackerType
                        ? versions.launcherOverrides?.EyeTrackerType
                        : [],
                },
                deltav: {
                    Company: versions.deltavOverrides?.Company ? versions.deltavOverrides?.Company : [],
                    System: versions.deltavOverrides?.System ? versions.deltavOverrides?.System : [],
                    EyeTrackerType: versions.deltavOverrides?.EyeTrackerType
                        ? versions.deltavOverrides?.EyeTrackerType
                        : [],
                },
            };
            return overrides;
        },
    },
    mounted() {
        this.loading = true;
        this.overrides = this.extractOverrides(this.versions);
        this.loading = false;
    },
});
