




































import Vue from 'vue';

import AuthApi from '@/api/auth.api';
import { ButtonState } from '@/common/types/general';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import TextLink from '@/views/common/controls/TextLink.vue';
import ErrorBanner from '@/views/common/static/ErrorBanner.vue';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

export default Vue.extend({
    components: {
        GreenButton,
        TextLink,
        LoadingCircle,
        ErrorBanner,
    },
    props: {
        emailInput: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            email: '' as string,
            submitSuccess: false as boolean,
            loading: false as boolean,
        };
    },
    computed: {
        forgotPasswordButtonState(): ButtonState {
            return this.email && this.email.length > 0 ? 'active' : 'inactive';
        },
    },
    methods: {
        async handleForgotPassword(email: string) {
            if (this.email && this.email.length > 0) {
                try {
                    this.loading = true;
                    AuthApi.forgotPassword(email);
                    this.submitSuccess = true;
                } catch (error) {
                    this.submitSuccess = false;
                    this.$store.commit(SET_ERROR_BANNER, error.message);
                } finally {
                    this.loading = false;
                }
            }
        },
        async handleBacktoLogin() {
            this.$router.push({ name: 'login', params: { email: this.email } });
        },
        clearMessageText() {
            this.submitSuccess = false;
        },
    },
    watch: {
        email() {
            this.clearMessageText();
        },
    },
    mounted() {
        this.email = this.$route.params.emailInput;
    },
});
