import { Module } from 'vuex';
import { RootState } from '@/store/index';
import TrainerApi from '@/api/trainer.api';
import {
    SET_WORKOUT,
    CLEAR_WORKOUT,
    SET_PROGRAM,
    CLEAR_PROGRAM,
    SET_CYCLE,
    CLEAR_CYCLE,
} from '@/store/trainer/mutations';
import { WorkoutResponse, ProgramResponse, CycleResponse } from '@/common/types/api/responses/trainerResponses';
import Workout from '@/common/types/trainer/workout';

interface ProgramState {
    workout?: Workout;
    program?: ProgramResponse;
    cycle?: CycleResponse;
}

const programModule: Module<ProgramState, RootState> = {
    state: {
        workout: undefined,
        program: undefined,
        cycle: undefined,
    },
    mutations: {
        setWorkout(state: ProgramState, workoutResponse: WorkoutResponse) {
            state.workout = new Workout(workoutResponse);
        },
        clearWorkout(state: ProgramState) {
            state.workout = undefined;
        },
        setProgram(state: ProgramState, programResponse: ProgramResponse) {
            state.program = programResponse;
        },
        clearProgram(state: ProgramState) {
            state.program = undefined;
        },
        setCycle(state: ProgramState, cycle: CycleResponse) {
            state.cycle = cycle;
        },
        clearCycle(state: ProgramState) {
            state.cycle = undefined;
        },
        clearEqtState(state: ProgramState) {
            state.workout = undefined;
            state.cycle = undefined;
            state.program = undefined;
        },
    },
    actions: {
        async updateWorkout({ commit }) {
            try {
                const workout = await TrainerApi.getWorkout();
                commit(SET_WORKOUT, workout);
            } catch (error) {
                commit(CLEAR_WORKOUT);
            }
        },
        async updateProgram({ commit }) {
            try {
                const program = await TrainerApi.getProgram();
                commit(SET_PROGRAM, program);
            } catch (error) {
                commit(CLEAR_PROGRAM);
            }
        },
        async updateCycle({ commit }) {
            try {
                const cycle = await TrainerApi.getCycle();
                commit(SET_CYCLE, cycle);
            } catch (error) {
                commit(CLEAR_CYCLE);
            }
        },
    },
    getters: {
        currentProgram(state: ProgramState): ProgramResponse | undefined {
            return state.program;
        },
        currentCycle(state: ProgramState): CycleResponse | undefined {
            return state.cycle;
        },
        currentWorkout(state: ProgramState): Workout | undefined {
            return state.workout;
        },
        hasProgram(state: ProgramState): boolean {
            return state.program !== undefined;
        },
        hasCycle(state: ProgramState): boolean {
            return state.cycle !== undefined;
        },
    },
};

export default programModule;
