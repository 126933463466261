































































































import Vue, { PropType } from 'vue';

import { SET_ERROR_BANNER } from '@/store/general/mutations';
import * as Nav from '@/store/navigation/mutations';

import TrainerApi from '@/api/trainer.api';

import { ParticipantSummaryResponse } from '@/common/types/api/responses/trainerResponses';
import { UpdateParticipantResponse } from '@/common/types/api/responses/userResponses';
import { EqtParticipant } from '@/common/types/trainer/eqtParticipant';
import { Company } from '@/common/types/company/company';

import Logger from '@/common/utils/logger';
import * as DataTableUtils from '@/common/utils/datatables';

import AssignmentDetails from '@/views/trainer/admin/components/AssignmentDetails.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import GreenToggle from '@/views/common/controls/GreenToggle.vue';
import ProgressBar from '@/views/common/static/ProgressBar.vue';
import SearchBar from '@/views/common/controls/SearchBar.vue';
import UpdateAssignment from '@/views/trainer/admin/components/UpdateAssignment.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';
import RegisterUser from '@/views/trainer/admin/components/RegisterUser.vue';

const DATATABLE_OPTIONS_STORE_KEY = 'EQTPARTICIPANTS';

export default Vue.extend({
    props: {
        company: {
            type: Object as PropType<Company>,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: '',
                    value: 'data-table-expand',
                    align: 'start',
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.patientId'),
                    sortable: true,
                    value: 'patientId',
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.firstName'),
                    sortable: true,
                    value: 'firstName',
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.lastName'),
                    sortable: true,
                    value: 'lastName',
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.status'),
                    sortable: true,
                    value: 'status',
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.startDate'),
                    sortable: true,
                    value: 'startDate',
                    sort(rhs: string, lhs: string) {
                        if (rhs === 'N/A') {
                            return -1;
                        } else if (lhs === 'N/A') {
                            return 1;
                        } else {
                            return new Date(rhs) < new Date(lhs) ? -1 : 1;
                        }
                    },
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.endDate'),
                    sortable: true,
                    value: 'endDate',
                    sort(rhs: string, lhs: string) {
                        if (rhs === 'N/A') {
                            return -1;
                        } else if (lhs === 'N/A') {
                            return 1;
                        } else {
                            return new Date(rhs) < new Date(lhs) ? -1 : 1;
                        }
                    },
                },
                {
                    text: this.$t('trainerAdmin.participants.fields.eyeQTrainerEnabled'),
                    sortable: true,
                    value: 'eyeQTrainerEnabled',
                },
            ],
            expanded: [] as EqtParticipant[],
            singleExpand: true,
            search: '',
            loading: false as boolean,
            participants: [] as EqtParticipant[],
            selectedParticipant: {} as EqtParticipant,
            showUpdateAssignment: false as boolean,
            showConfirmation: false as boolean,
            showRegisterUser: false as boolean,
            userRegistrationParticipant: undefined as EqtParticipant | undefined,
            participantDetailsLoading: false as boolean,
            cellWidth: window.innerWidth / 12.5,
            options: {} as any,
            defaultSortBy: 'startDate',
            defaultSortByDesc: true,
            defaultSortOrder: 'DESC' as string,
            totalParticipants: 0,
        };
    },
    methods: {
        async loadParticipants() {
            try {
                this.loading = true;
                const { sortBy, sortOrder } = DataTableUtils.parseSortOptions(
                    this.options.sortBy,
                    this.options.sortDesc,
                    this.defaultSortBy,
                    this.defaultSortOrder,
                );
                const eqtParticipantsResponse = await TrainerApi.getEqtParticipantsByCompanyPaginated(
                    this.company.id,
                    this.options.page,
                    this.options.itemsPerPage,
                    this.search,
                    sortBy,
                    sortOrder,
                );
                this.totalParticipants = eqtParticipantsResponse.totalRecords;
                this.participants = eqtParticipantsResponse.data.map((response) => new EqtParticipant(response));
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.loading = false;
            }
        },
        async loadParticipantDetails(participant: EqtParticipant) {
            try {
                if (participant.isDirty) {
                    this.participantDetailsLoading = true;
                    const participantDetailsResponse: ParticipantSummaryResponse = await TrainerApi.getParticipantSummary(
                        participant.participantId,
                    );
                    participant.loadDetails(participantDetailsResponse);
                    participant.isDirty = false;
                }
                this.selectedParticipant = participant;
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.participantDetailsLoading = false;
            }
        },
        hideUpdateAssignment() {
            this.showUpdateAssignment = false;
        },
        async savedUpdateAssignment() {
            this.selectedParticipant.isDirty = true;
            await this.loadParticipantDetails(this.selectedParticipant);
        },
        handleEditButton() {
            this.showUpdateAssignment = true;
        },
        showConfirmationPopup(participant: EqtParticipant) {
            this.selectedParticipant = participant;
            this.showConfirmation = true;
        },
        saveEyeQTrainerEnabled() {
            TrainerApi.setEqtEnabled(
                this.selectedParticipant.participantId,
                this.selectedParticipant.eyeQTrainerEnabled,
            );
            this.showConfirmation = false;
        },
        cancelConfirmation() {
            this.selectedParticipant.eyeQTrainerEnabled = !this.selectedParticipant.eyeQTrainerEnabled;
            this.showConfirmation = false;
        },
        showRegisterUserPopup(participant: EqtParticipant) {
            if (!participant.userId) {
                this.showRegisterUser = true;
                this.userRegistrationParticipant = participant;
            }
        },
        hideRegisterUser() {
            this.showRegisterUser = false;
        },
        onUserRegistration(response: UpdateParticipantResponse) {
            if (this.userRegistrationParticipant) {
                this.userRegistrationParticipant.userId = response.data.userId;
            }
        },
        expandParticipantRow(participant: EqtParticipant) {
            if (!this.expanded.includes(participant)) {
                this.expanded = [];
                this.expanded.push(participant);
            } else {
                this.expanded = [];
            }
        },
        getWidth() {
            return {
                'max-width': this.cellWidth + 'px',
            };
        },
        onResize() {
            this.cellWidth = window.innerWidth / 12.5;
        },
        executeSearch(search: string) {
            this.search = search;
            this.loadParticipants();
            DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search, this.expanded);
        },
        clearFiltersAndSearch() {
            this.options = DataTableUtils.getDefaultOptions(this.defaultSortBy, this.defaultSortOrder);
            this.search = '';
            DataTableUtils.clearDataTableCache(DATATABLE_OPTIONS_STORE_KEY);
        },
    },
    async mounted() {
        this.$store.commit(Nav.SHOW_LEFT_NAV);
        window.addEventListener('resize', this.onResize);
        const cache = DataTableUtils.getDataTableOptionsCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
        this.options = cache.options;
        this.search = cache.search;
        this.expanded = cache.selected ? cache.selected : [];
    },
    components: {
        AssignmentDetails,
        GreenButton,
        GreenToggle,
        ProgressBar,
        RegisterUser,
        SaveConfirmation,
        UpdateAssignment,
        SearchBar,
    },
    watch: {
        expanded(items: EqtParticipant[]) {
            if (items.length > 0) {
                this.selectedParticipant = items[0];
                this.loadParticipantDetails(items[0]);
                DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search, this.expanded);
            }
        },
        options: {
            handler() {
                this.loadParticipants();
                DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search, this.expanded);
            },
            deep: true,
        },
        itemsPerPage() {
            this.loadParticipants();
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
});
