














import Vue from 'vue';

import LoadingCircle from '@/views/common/static/LoadingCircle.vue';

export default Vue.extend({
    components: {
        LoadingCircle,
    },
    props: {
        on: {
            required: false,
        },
        state: {
            type: String,
            required: false,
            default: 'active',
        },
    },
});
