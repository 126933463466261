





































import Vue from 'vue';
import TrainerApi from '@/api/trainer.api';
import { UPDATE_WORKOUT } from '@/store/trainer/actions';
import ExerciseTable from '@/views/trainer/exercises/components/workouts/ExerciseTable.vue';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import { CycleResponse } from '@/common/types/api/responses/trainerResponses';
import Workout from '@/common/types/trainer/workout';
import ExistingProgram from '@/views/trainer/exercises/components/program/ExistingProgram.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import { CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';
import * as LeftNavUtils from '@/common/utils/leftNav';
import { UPDATE_PROGRAM, UPDATE_CYCLE } from '@/store/trainer/actions';
import { isBeforeNow } from '@/common/utils/date';

export default Vue.extend({
    components: { ExerciseTable, ExistingProgram, GreenButton, LoadingCircle },
    data() {
        return {
            imageNum: 1,
            planName: '' as string,
            cycle: undefined as CycleResponse | undefined,
            page: '',
            message: '',
            participant: {},
            loading: false,
        };
    },
    methods: {
        async setCycle() {
            try {
                this.cycle = await TrainerApi.getCycle();
            } catch (error) {
                throw error;
            }
            const planKey = 'trainer.workoutPlan.' + this.cycle.workoutPlan.name + '.name';
            this.planName = this.$t(planKey).toString();
        },
        async setWorkout() {
            await this.$store.dispatch(UPDATE_WORKOUT);
        },
        async getEqtParticipant() {
            this.participant = await TrainerApi.getEqtParticipantByUserId(this.$store.getters.currentUser.userId);
        },
        onClick() {
            this.$router.push({ name: 'instructions' });
        },
    },
    computed: {
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        currentUser(): AuthenticatedUser | undefined {
            return this.$store.getters.currentUser;
        },
        currentWorkout(): Workout | undefined {
            return this.$store.getters.currentWorkout;
        },
        hasProgram(): boolean {
            return this.$store.getters.hasProgram;
        },
        hasCycle(): boolean {
            return this.$store.getters.hasCycle;
        },
        exerciseNumber(): number {
            return this.currentWorkout?.currentExerciseNumber ?? 0;
        },
        companyActive(): boolean {
            return this.currentUser?.company.active as boolean;
        },
        cycleNotEnded(): boolean {
            return isBeforeNow(this.$store.getters.currentCycle?.endDate);
        },
    },
    async created() {
        await this.setCycle();
        await this.setWorkout();

        if (this.cycle && !this.cycle.active) {
            this.$router.push({ name: 'congratulations' });
        }
    },
    async mounted() {
        this.loading = true;
        LeftNavUtils.setEyeQTrainerLeftNav();
        await this.$store.dispatch(UPDATE_PROGRAM);
        await this.$store.dispatch(UPDATE_CYCLE);

        await this.getEqtParticipant();
        if (!this.hasProgram) {
            this.page = 'basics';
            this.message = 'basics';
        } else if (!this.hasCycle) {
            this.page = 'chooseWorkout';
            this.message = 'chooseWorkout';
        }
        this.loading = false;
    },
});
