import ApiService from '@/api/common/apiService';
import { HttpMethod } from '@/common/types/api/requests/customRequest';

import { CreateUserRequest, UpdateParticipantRequest } from '@/common/types/api/requests/userRequests';
import { PaginatedResponse } from '@/common/types/api/responses/paginatedResponses';
import { UpdateParticipantResponse, SupportEmailResponse } from '@/common/types/api/responses/userResponses';
import { Participant } from '@/common/types/user/participant';
import { User } from '@/common/types/user/user';

class UserApi {
    public async getUsersPaginated(
        page: number,
        size: number,
        searchValue: string,
        sortBy: string,
        sortOrder: string,
    ): Promise<PaginatedResponse<User>> {
        const uri: string = `/righteye.api/pagingusers?page=${page}&per_page=${size}&sort_order=${sortOrder}&sort_by=${sortBy}&search_value=${searchValue}`;

        const request = {
            url: uri,
            method: 'GET' as HttpMethod,
        };
        const response = await ApiService.requestWithDataField<any>(request);
        return response;
    }

    public async updateUser(updateUserRequest: User): Promise<string> {
        const uri: string = `/righteye.api/users`;
        const response = await ApiService.put<string>(uri, updateUserRequest);
        return response;
    }

    public async createUser(createUserRequest: CreateUserRequest): Promise<User> {
        const uri: string = `/righteye.api/users`;
        const response = await ApiService.post<User>(uri, createUserRequest);
        return response;
    }

    public async getParticipant(participantId: string): Promise<Participant> {
        const uri: string = `/righteye.api/participants/${participantId}`;
        const response = await ApiService.get<Participant>(uri);
        return response;
    }

    public async updateParticipant(
        updateParticipantRequest: UpdateParticipantRequest,
    ): Promise<UpdateParticipantResponse> {
        const uri: string = `/righteye.api/participants`;
        const response = await ApiService.put<UpdateParticipantResponse>(uri, updateParticipantRequest);
        return response;
    }

    public async sendSupportEmail(phoneNumber: string, message: string): Promise<SupportEmailResponse> {
        const uri = '/righteye.api/support-message';
        const requestBody = { phoneNumber, message };
        const response = await ApiService.post<SupportEmailResponse>(uri, requestBody);
        return response;
    }

    public async getUserById(userId: string): Promise<User> {
        const uri = `righteye.api/users/${userId}`;
        const response = await ApiService.get<User>(uri);
        return response;
    }
}

export default new UserApi();
