import { render, staticRenderFns } from "./VergenceVisualization.vue?vue&type=template&id=812b0908&scoped=true&"
import script from "./VergenceVisualization.vue?vue&type=script&lang=ts&"
export * from "./VergenceVisualization.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "812b0908",
  null
  
)

export default component.exports