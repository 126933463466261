























import Vue, { PropType } from 'vue';

import Logger from '@/common/utils/logger';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

import ScoreVisualizationCompact, {
    ScoreConfig,
} from '@/views/reports/visualizations/scores/ScoreVisualizationCompact.vue';
import ReferenceScoreVisualizationCompact from '@/views/reports/visualizations/scores/ReferenceScoreVisualizationCompact.vue';

import { ReportData } from '@/common/types/reports/reportData';

import AssessmentsApi from '@/api/assessment.api';
import { ComparativeReportScore } from '@/common/types/reports/assessment';

export default Vue.extend({
    components: {
        ScoreVisualizationCompact,
        ReferenceScoreVisualizationCompact,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        message: String,
        updateViz: Boolean,
        reportVersion: Number,
    },
    data() {
        return {
            config: undefined as ScoreConfig | undefined,
            comparativeReportScore: undefined as ComparativeReportScore | undefined,
        };
    },
    computed: {
        isParticipantAssessment(): boolean {
            if (this.reportData.assessment?.id) {
                return true;
            } else {
                return false;
            }
        },
    },
    async mounted() {
        try {
            if (this.isParticipantAssessment) {
                const score = await AssessmentsApi.getReportScore(
                    this.reportData.assessment.id,
                    this.reportData.assessmentType.name,
                );
                const cspMetrics = this.reportData.tests.circularSmoothPursuit.metrics;
                const onTargetSp = cspMetrics.both.onTargetSpPercent;
                const spPercent = cspMetrics.both.spPercent;

                const cspAccuracy = Math.floor((onTargetSp / spPercent) * 100.0);

                const fixationMetrics = this.reportData.tests.fixationStability.metrics;
                const fixationBand1 = fixationMetrics.both.gazePositionsBand1Aggregate;
                const fixationBand2 = fixationMetrics.both.gazePositionsBand2Aggregate;
                const fixationAccuracy = Math.floor(fixationBand1 + fixationBand2);

                const hsMetrics = this.reportData.tests.horizontalSaccades.metrics;
                const hsLeftMissed = hsMetrics.left.missed;
                const hsRightMissed = hsMetrics.right.missed;

                const hsLeftSaccadeNum = hsMetrics.left.saccadeNum.value;
                const hsRightSaccadeNum = hsMetrics.right.saccadeNum.value;

                const leftAccuracy = (hsLeftSaccadeNum - hsLeftMissed) / hsLeftSaccadeNum;
                const rightAccuracy = (hsRightSaccadeNum - hsRightMissed) / hsRightSaccadeNum;
                const saccadeAccuracy = Math.floor(((leftAccuracy + rightAccuracy) / 2.0) * 100.0);

                const config = {} as ScoreConfig;
                config.overall = score.overallScore;
                config.saccades = score.saccadesScore;
                config.pursuits = score.pursuitsScore;
                config.fixations = score.fixationsScore;
                config.functionalWindowLowerBound = score.functionalWindowLowerBound;
                config.functionalWindowUpperBound = score.functionalWindowUpperBound;
                config.overallAccuracy = Math.floor((cspAccuracy + saccadeAccuracy + fixationAccuracy) / 3.0);
                config.saccadeAccuracy = saccadeAccuracy;
                config.pursuitAccuracy = cspAccuracy;
                config.fixationAcurracy = fixationAccuracy;
                config.reportVersion = this.reportVersion;

                this.config = config;
            } else {
                if (this.reportData.info?.id) {
                    this.comparativeReportScore = await AssessmentsApi.getComparativeScoreData(this.reportData.info.id);
                }
            }
        } catch (error) {
            Logger.error(`Failed to load score: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            throw error;
        }
    },
});
