import {
    AgeBasedNormsRange,
    NormsSystemType,
    ReportNorms,
    NormsCollection,
    LabeledNorms,
} from '@/common/types/reports/norms';
import { TestType } from '@/common/constants/reports.constants';
import { NormsData, NormsRow, SystemType } from '@/common/types/reports/reportData';
import { LeftRightBoth } from '@/common/types/reports/metricData';
import { extractLeftRightBoth } from '@/common/utils/reports/reports';
import { getAgeFromDateOfBirth } from '@/common/utils/date';

export function ageBasedRangeFromDateOfBirth(dateOfBirth: string | undefined): AgeBasedNormsRange | undefined {
    if (dateOfBirth) {
        const age = getAgeFromDateOfBirth(dateOfBirth);
        return ageBasedRangeFromAge(age);
    } else {
        return undefined;
    }
}

export function ageBasedRangeFromAge(age: number): AgeBasedNormsRange | undefined {
    if (age >= 1 && age <= 3) {
        return '1-3';
    } else if (age >= 4 && age <= 6) {
        return '4-6';
    } else if (age >= 7 && age <= 10) {
        return '7-10';
    } else if (age >= 11 && age <= 16) {
        return '11-16';
    } else if (age >= 17 && age <= 28) {
        return '17-28';
    } else if (age >= 29 && age <= 41) {
        return '29-41';
    } else if (age >= 42 && age <= 55) {
        return '42-55';
    } else if (age >= 56 && age <= 64) {
        return '56-64';
    } else if (age >= 65 && age <= 70) {
        return '65-70';
    } else if (age >= 71 && age <= 100) {
        return '71-100';
    }

    return undefined;
}

export function extractReportNorms(normsData: NormsData, systemType: SystemType): ReportNorms {
    const normsSystemType = systemType === 'I15' ? 'i15' : 'smi';

    const ageBased = normsRowsToReportNorms(normsData.ageBased, normsSystemType);
    const mlb = normsRowsToReportNorms(normsData.mlb, normsSystemType);
    const games = normsRowsToReportNorms(normsData.games, normsSystemType);

    const result: Record<string, NormsCollection> = {};

    for (const testType of Object.keys(ageBased)) {
        if (!result[testType]) {
            result[testType] = {};
        }
        result[testType].ageBased = ageBased[testType];
    }

    for (const testType of Object.keys(mlb)) {
        if (!result[testType]) {
            result[testType] = {};
        }
        result[testType].mlb = mlb[testType];
    }

    for (const testType of Object.keys(games)) {
        if (!result[testType]) {
            result[testType] = {};
        }
        result[testType].games = games[testType];
    }

    return result as Record<TestType, NormsCollection>;
}

function normsRowsToReportNorms(
    rows: NormsRow[] | undefined,
    systemType: NormsSystemType,
): Record<string, LabeledNorms[]> {
    if (!rows) {
        return {} as Record<TestType, LabeledNorms[]>;
    }

    const result: Record<string, LabeledNorms[]> = {};

    for (const row of rows) {
        if (row.data) {
            const data = row.data[systemType];
            for (const test of Object.keys(data)) {
                if (!result[test]) {
                    result[test] = [];
                }
                result[test].push({
                    label: row.label,
                    data: data[test],
                });
            }
        }
    }

    return result;
}

export function extractLeftRightBothNorms(norms: NormsCollection): LeftRightBoth<NormsCollection> {
    const ageBased = normsRowsToReportNormsLrb(norms, 'ageBased');
    const mlb = normsRowsToReportNormsLrb(norms, 'mlb');
    const games = normsRowsToReportNormsLrb(norms, 'games');

    const result: LeftRightBoth<NormsCollection> = {
        left: {
            ageBased: ageBased?.left,
            mlb: mlb?.left,
            games: games?.left,
        },
        right: {
            ageBased: ageBased?.right,
            mlb: mlb?.right,
            games: games?.right,
        },
        both: {
            ageBased: ageBased?.both,
            mlb: mlb?.both,
            games: games?.both,
        },
        bothLeft: {
            ageBased: ageBased?.bothLeft,
            mlb: mlb?.bothLeft,
            games: games?.bothLeft,
        },
        bothRight: {
            ageBased: ageBased?.bothRight,
            mlb: mlb?.bothRight,
            games: games?.bothRight,
        },
    };

    return result;
}

function normsRowsToReportNormsLrb(
    normsCollection: NormsCollection,
    normsCategory: 'ageBased' | 'mlb' | 'games',
): LeftRightBoth<LabeledNorms[]> | undefined {
    if (!normsCollection || !normsCollection[normsCategory]) {
        return undefined;
    }

    const normsData = normsCollection[normsCategory]!;

    const result: LeftRightBoth<LabeledNorms[]> = {
        left: [],
        right: [],
        both: [],
        bothLeft: [],
        bothRight: [],
    };

    for (const labeledData of normsData) {
        const label = labeledData.label;
        const data = labeledData.data;

        const lrbData = extractLeftRightBoth(data);

        result.left!.push({
            label,
            data: lrbData.left,
        });

        result.right!.push({
            label,
            data: lrbData.right,
        });

        result.both!.push({
            label,
            data: lrbData.both,
        });

        result.bothLeft!.push({
            label,
            data: lrbData.bothLeft,
        });

        result.bothRight!.push({
            label,
            data: lrbData.bothRight,
        });
    }

    return result;
}
