import { TestType } from '@/common/constants/reports.constants';

export type NormsSystemType = 'i15' | 'smi';

export type NormsType = 'AGE_BASED' | 'MLB' | 'READING' | 'GAMES';

export type AgeBasedNormsRange =
    | '1-3'
    | '4-6'
    | '7-10'
    | '11-16'
    | '17-28'
    | '29-41'
    | '42-55'
    | '56-64'
    | '65-70'
    | '71-100';

export type MlbNormsLabel = 'A' | 'AA' | 'AAA' | 'Amateur' | 'MLB' | 'MLB99' | 'PDP' | 'RL';

export type ReadingNormsLevel =
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | 'A1'
    | 'A2'
    | 'A3'
    | 'A4'
    | 'A5'
    | 'College';

export const AGE_BASED_NORMS_RANGES: AgeBasedNormsRange[] = [
    '1-3',
    '4-6',
    '7-10',
    '11-16',
    '17-28',
    '29-41',
    '42-55',
    '56-64',
    '65-70',
    '71-100',
];
export const MLB_NORMS_LABELS: MlbNormsLabel[] = ['A', 'AA', 'AAA', 'Amateur', 'MLB', 'MLB99', 'PDP', 'RL'];
export const READING_NORMS_LEVELS: ReadingNormsLevel[] = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    'A1',
    'A2',
    'A3',
    'A4',
    'A5',
    'College',
];

export interface NormsTable {
    label: string;
    data: {
        i15: Norms;
        smi: Norms;
    };
}

export type Norms = any;

export interface NormsSelection {
    ageBased?: AgeBasedNormsRange[];
    mlb?: MlbNormsLabel[];
    reading?: string[];
    games?: AgeBasedNormsRange[];
}

export interface LabeledNorms {
    label: string;
    data: Norms[];
}

export interface NormsCollection {
    ageBased?: LabeledNorms[];
    mlb?: LabeledNorms[];
    games?: LabeledNorms[];
}

export type ReportNorms = Record<TestType, NormsCollection>;
