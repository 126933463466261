

























import Vue, { PropType } from 'vue';

import Logger from '@/common/utils/logger';

import { SET_ERROR_BANNER } from '@/store/general/mutations';

import TrainerApi from '@/api/trainer.api';

import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';

import { Company } from '@/common/types/company/company';

export default Vue.extend({
    components: {
        SaveConfirmation,
    },
    props: {
        company: {
            type: Object as PropType<Company>,
            required: true,
        },
    },
    data() {
        return {
            participantsEnabledByDefault: undefined as boolean | undefined,
            showConfirmation: false as boolean,
        };
    },
    methods: {
        async getParticipantsEnabledByDefault() {
            try {
                const companySettings = await TrainerApi.getCompanyEqtSettings(this.company.id);
                this.participantsEnabledByDefault = companySettings.participantsEnabledByDefault;
            } catch (error) {
                this.participantsEnabledByDefault = undefined;
                Logger.error('Failed to get EQT enabled status', error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.$emit('loading', false);
            }
        },
        async setParticipantsEnabledByDefault() {
            if (this.participantsEnabledByDefault !== undefined) {
                try {
                    await TrainerApi.setParticipantsEnabledByDefault(
                        this.company.id,
                        this.participantsEnabledByDefault,
                    );
                } catch (error) {
                    Logger.error(`Failed to set EQT enabled status to ${this.participantsEnabledByDefault}`, error);
                    this.$store.commit(SET_ERROR_BANNER, error.message);
                }
            }
        },
        confirmationCancel() {
            this.participantsEnabledByDefault = !this.participantsEnabledByDefault;
        },
    },
    watch: {
        company() {
            this.getParticipantsEnabledByDefault();
        },
    },
    async mounted() {
        this.getParticipantsEnabledByDefault();
    },
});
