export const SPORTS_VISION = 'Sports Vision EyeQ';
export const FUNCTIONAL_VISION = 'Functional Vision EyeQ';
export const BRAIN_HEALTH = 'Brain Health EyeQ';
export const READING = 'RightEye Reading';
export const SACCADOMETRY = 'Saccadometry Test';
export const INDIVIDUAL_TEST = 'Individual Tests';
export const DYNAMIC_VISION = 'Dynamic Vision';
export const SENSORIMOTOR_SCREENER = 'Sensorimotor Screener';
export const SENSORIMOTOR_EXAM = 'Sensorimotor Exam';

export const REPORT_URLS = {
    'e8ad94d9-5151-4425-1900-53c3df98f999': '#/viewReadingEyeQ?report=readingeyeq',
    '771d9834-9f38-8774-b32c-eb9296350a34': '#/viewSportsEyeQ?report=sportseyeq',
    '2c003158-ceba-aa3e-0d08-96df2c0cae19': '#/viewBrainEyeQ?report=braineyeq',
    '92a82b4f-36ee-11e9-8674-0ae5684866fc': '#/viewSaccadometryTest?report=saccadometrytest',
    '755d7f0a-03b2-11e9-bbce-0ad607517572': '#/viewFunctionalEyeQ?report=functionaleyeq',
} as Record<string, string>;
export const STANDARD_REPORT_URL = '#/viewReport?report=standard';

export const EYEQ_TYPES = {
    'Dynamic Vision': 'Dynamic',
    'Sports Vision EyeQ': 'Dynamic',
    'Brain Health EyeQ': 'Brain',
    'Functional Vision EyeQ': 'Functional',
} as Record<string, string>;
