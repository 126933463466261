export const SPORTS_VISION_TRAINER = {
    warmupDrills: [
        {
            code: 'I101GET',
            category: '101 - White',
            name: 'Object (pursuit) Tracking',
            eyeMovement: 'Pursuits',
            link: 'https://player.vimeo.com/video/472655656',
        },
        {
            code: 'I101GES',
            category: '101 - White',
            name: 'Scanning & Decision Making',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472655645',
        },
        {
            code: 'I101GEA',
            category: '101 - White',
            name: 'Targeting',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472655545',
        },
        {
            code: 'I101GEB',
            category: '101 - White',
            name: 'Balance & Vision',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472655547',
        },
        {
            code: 'I101GER',
            category: '101 - White',
            name: 'Pro-act (not react)',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472655552',
        },
        {
            code: 'I201GET',
            category: '201 - Yellow	',
            name: 'Object (pursuit) Tracking	',
            eyeMovement: 'Pursuits',
            link: 'https://player.vimeo.com/video/472664927',
        },
        {
            code: 'I201GES',
            category: '201 - Yellow	',
            name: 'Scanning & Decision Making	',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472664868',
        },
        {
            code: 'I201GEA',
            category: '201 - Yellow',
            name: 'Targeting',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472664794',
        },
        {
            code: 'I201GEB',
            category: '201 - Yellow',
            name: 'Balance & Vision',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472664884',
        },
        {
            code: 'I201GER',
            category: '201 - Yellow',
            name: 'Pro-act (not react)',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472664880',
        },
        {
            code: 'I301GET',
            category: '301 - Orange',
            name: 'Object (pursuit) Tracking',
            eyeMovement: 'Pursuits',
            link: 'https://player.vimeo.com/video/472668301',
        },
        {
            code: 'I301GES',
            category: '301 - Orange',
            name: 'Scanning & Decision Making',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472668267',
        },
        {
            code: 'I301GEA',
            category: '301 - Orange',
            name: 'Targeting',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472668209',
        },
        {
            code: 'I301GEB',
            category: '301 - Orange',
            name: 'Balance & Vision',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472668211',
        },
        {
            code: 'I301GER',
            category: '301 - Orange',
            name: 'Pro-act (not react)',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472668309',
        },
        {
            code: 'I401GET',
            category: '401 - Blue',
            name: 'Object (pursuit) Tracking',
            eyeMovement: 'Pursuits',
            link: 'https://player.vimeo.com/video/472744883',
        },
        {
            code: 'I401GEA',
            category: '401 - Blue',
            name: 'Targeting',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472744899',
        },
        {
            code: 'I401GEB',
            category: '401 - Blue',
            name: 'Balance & Vision',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472744897',
        },
        {
            code: 'I401GER',
            category: '401 - Blue',
            name: 'Pro-act (not react)',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472744878',
        },
    ],
    weightRoomDrills: [
        {
            code: 'AWU1',
            category: 'Balance & Vision, Targeting',
            name: 'Assisted Squat',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472653220',
        },
        {
            code: 'AWU2',
            category: 'Balance & Vision',
            name: 'Valgus Lunge',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472653345',
        },
        {
            code: 'AWU3',
            category: 'Balance & Vision, Targeting',
            name: 'Bosu Push Up',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472653406',
        },
        {
            code: 'AWU4',
            category: 'Targeting',
            name: '½ kneeling lift',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472653272',
        },
        {
            code: 'AWU5',
            category: 'Targeting',
            name: 'Overhead Squat',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472653276',
        },
        {
            code: 'AWU6',
            category: 'Balance & Vision, Decision making',
            name: 'Mini-bands Lateral',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472653360',
        },
        {
            code: 'AWU7',
            category: 'Targeting',
            name: 'Standing Chop',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472653401',
        },
        {
            code: 'AWU8',
            category: 'Balance & Vision, Tracking',
            name: 'Lateral Lunge',
            eyeMovement: 'Fixations & Pursuits',
            link: 'https://player.vimeo.com/video/472653364',
        },
        {
            code: 'AWU9',
            category: 'Scanning',
            name: 'Single Leg Core Activation',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472653365',
        },
        {
            code: 'AWU10',
            category: 'Scanning, Targeting',
            name: 'Side to side',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472653478',
        },
        {
            code: 'AWU11',
            category: 'Balance & Vision, Decision making',
            name: 'Valgus Squat',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472654335',
        },
        {
            code: 'AWU12',
            category: 'Scanning, Targeting',
            name: 'Cross Over Step Up',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472654308',
        },
        {
            code: 'AWU13',
            category: 'Balance & Vision, Tracking',
            name: 'Step Up',
            eyeMovement: 'Fixations & Pursuits',
            link: 'https://player.vimeo.com/video/472654363',
        },
        {
            code: 'AWU14',
            category: 'Targeting',
            name: 'Glute Bridge w/ March',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472654372',
        },
        {
            code: 'AWU15',
            category: 'Balance & Vision, Tracking',
            name: 'Mini-band Squat',
            eyeMovement: 'Fixations & Pursuits',
            link: 'https://player.vimeo.com/video/472654375',
        },
        {
            code: 'AWU16',
            category: 'Targeting',
            name: 'Leg Swings',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472654397',
        },
        {
            code: 'AWU17',
            category: 'Targeting',
            name: 'Physio ball Roll out',
            eyeMovement: 'Fixations',
            link: 'https://player.vimeo.com/video/472654470',
        },
        {
            code: 'AWU18',
            category: 'Balance & Vision, Tracking, Decision making',
            name: 'Bosu Squat',
            eyeMovement: 'Fixations; Saccades & Pursuits',
            link: 'https://player.vimeo.com/video/472654496',
        },
        {
            code: 'AWU19',
            category: 'Balance & Vision, Tracking',
            name: 'Drop Back Lunge',
            eyeMovement: 'Fixations & Pursuits',
            link: 'https://player.vimeo.com/video/472654462',
        },
        {
            code: 'AWU20',
            category: 'Tracking',
            name: 'Hip Series-High Knees',
            eyeMovement: 'Pursuits',
            link: 'https://player.vimeo.com/video/472654463',
        },
        {
            code: 'AWU21',
            category: 'Scanning, Tracking',
            name: '½ Kneeing chop',
            eyeMovement: 'Saccades & Pursuits',
            link: 'https://player.vimeo.com/video/472655465',
        },
        {
            code: 'AWU22',
            category: 'Scanning',
            name: 'Bench Mountain Climber',
            eyeMovement: 'Saccades',
            link: 'https://player.vimeo.com/video/472655462',
        },
        {
            code: 'AWU23',
            category: 'Balance & Decision Making',
            name: 'Mini bands, lateral',
            eyeMovement: 'Fixations & Saccades',
            link: 'https://player.vimeo.com/video/472655551',
        },
    ],
    baseballHittingDrills: [
        {
            code: 'I101BA1',
            category: 'Set Phase',
            name: 'Color Patch Drill (large)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472655566',
        },
        {
            code: 'I106BA1',
            category: 'Set Phase',
            name: 'Symbol Drill (medium)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658485',
        },
        {
            code: 'I417BA1',
            category: 'Set Phase',
            name: 'Visual Lock Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472745391',
        },
        {
            code: 'I115BA1',
            category: 'Lift & Balance Phase',
            name: 'Color Line Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658614',
        },
        {
            code: 'I120BA1',
            category: 'Lift & Balance Phase',
            name: 'Color Dot Drill (large)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658626',
        },
        {
            code: 'I125BA1',
            category: 'Lift & Balance Phase',
            name: 'Color Dot Drill (Small)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658567',
        },
        {
            code: 'I131BA1',
            category: 'Separation & Power Phase',
            name: 'Count It: Shoulder-Hand Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661157',
        },
        {
            code: 'I135BA1',
            category: 'Separation & Power Phase',
            name: 'Through the Picture Frame',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661247',
        },
        {
            code: 'I132BA1',
            category: 'Separation & Power Phase',
            name: 'See It Coming',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661267',
        },
        {
            code: 'I235BA1',
            category: 'Release Phase',
            name: 'Ball Color Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472666042',
        },
        {
            code: 'I150BA1',
            category: 'Release Phase',
            name: 'Call Type of Pitch',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662816',
        },
        {
            code: 'I155BA1',
            category: 'Release Phase',
            name: 'Call "Yes" or "No"',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662840',
        },
        {
            code: 'I161BA1',
            category: 'Finish Phase',
            name: 'Count It Out',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662927',
        },
        {
            code: 'I165BA1',
            category: 'Finish Phase',
            name: 'Bat Handle Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662907',
        },
        {
            code: 'I170BA1',
            category: 'Finish Phase',
            name: 'Bat Knob Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662888',
        },
        {
            code: 'I226BA1',
            category: 'Overall Phase',
            name: 'Anchor Cue',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665932',
        },
        {
            code: 'I281BA1',
            category: 'Overall Phase',
            name: 'KISS Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472668237',
        },
        {
            code: 'I271BA1',
            category: 'Overall Phase',
            name: 'Hit Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472667124',
        },
    ],
    basketballFreeThrows: [
        {
            code: 'I102BB1',
            category: 'Hoop',
            name: 'Call the Color Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658553',
        },
        {
            code: 'I107BB1',
            category: 'Hoop',
            name: 'Cue 1, 2 Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658491',
        },
        {
            code: 'I133BB1',
            category: 'Hoop',
            name: 'Dot 1, 2 Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661231',
        },
        {
            code: 'I330BB1',
            category: 'Hoop',
            name: 'Call Middle Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472668284',
        },
        {
            code: 'I335BB1',
            category: 'Hoop',
            name: 'Call "M" Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472668406',
        },
        {
            code: 'I200BB1',
            category: 'Hoop',
            name: 'Call M-Dot Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664676',
        },
        {
            code: 'I205BB1',
            category: 'Hoop (blink body move)',
            name: 'Matching Arm-to-Hoop Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664942',
        },
        {
            code: 'I210BB1',
            category: 'Hoop (blink body move)',
            name: 'Matching Hand-to-Hoop Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665015',
        },
        {
            code: 'I215BB1',
            category: 'Hoop (blink body move)',
            name: 'Matching Finger-to-Hoop Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665287',
        },
        {
            code: 'I220BB1',
            category: 'Hoop-blink-ball',
            name: 'Call "Ball" Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665931',
        },
        {
            code: 'I225BB1',
            category: 'Hoop-blink-ball',
            name: '',
            eyeMovement: 'Call Ball-Defense Drill',
            link: 'https://player.vimeo.com/video/472665935',
        },
        {
            code: 'I230BB1',
            category: 'Hoop-blink-ball',
            name: 'Call Defense-Target Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665924',
        },
    ],
    golfPutting: [
        {
            code: 'I400GO1',
            category: 'Preparation',
            name: 'Draw the Path Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472744835',
        },
        {
            code: 'I410GO1',
            category: 'Preparation',
            name: 'Visual Calibration Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472744904',
        },
        {
            code: 'I415GO1',
            category: 'Preparation',
            name: 'Visual Lock Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472745404',
        },
        {
            code: 'I420GO1',
            category: 'Action',
            name: 'Club Handle Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472745388',
        },
        {
            code: 'I425GO1',
            category: 'Action',
            name: 'Velcro Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472745400',
        },
        {
            code: 'I430GO1',
            category: 'Action',
            name: 'Hit the Strip Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472745446',
        },
        {
            code: 'I435GO1',
            category: 'Finish',
            name: 'Hit the Divot Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472745454',
        },
        {
            code: 'I440GO1',
            category: 'Finish',
            name: 'Count It One Second',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778926',
        },
        {
            code: 'I465GO1',
            category: 'Finish',
            name: 'Surprise Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778984',
        },
        {
            code: 'I450GO1',
            category: 'Overall',
            name: 'Anchor Cue',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778930',
        },
        {
            code: 'I455GO1',
            category: 'Overall',
            name: 'KISS Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778979',
        },
        {
            code: 'I460GO1',
            category: 'Overall',
            name: 'Hit Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778974',
        },
    ],
    soccerPenaltyKicks: [
        {
            code: 'I101SO1',
            category: 'Preparation/Run Up',
            name: 'Color Patch Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472655621',
        },
        {
            code: 'I107SO1',
            category: 'Preparation/Run Up',
            name: 'Symbol Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658493',
        },
        {
            code: 'I126SO1',
            category: 'Preparation/Run Up',
            name: 'Color Dot Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658656',
        },
        {
            code: 'I190SO1',
            category: 'Stabilization',
            name: 'Leg Cue Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664611',
        },
        {
            code: 'I365SO1',
            category: 'Stabilization',
            name: 'Laces or Inside Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472668340',
        },
        {
            code: 'I366SO1',
            category: 'Stabilization',
            name: 'Call it Left or Right Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472668372',
        },
        {
            code: 'I195SO1',
            category: 'Stabilization',
            name: 'Call It High or Low Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664638',
        },
        {
            code: 'I200SO1',
            category: 'Contact',
            name: 'Call Kick Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664691',
        },
        {
            code: 'I205SO1',
            category: 'Contact',
            name: 'Boot Color Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664912',
        },
        {
            code: 'I210SO1',
            category: 'Contact',
            name: 'Boot Symbol Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664980',
        },
        {
            code: 'I186SO1',
            category: 'Finish',
            name: 'Count it Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664537',
        },
        {
            code: 'I127SO1',
            category: 'Finish',
            name: 'Call Patch Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658698',
        },
        {
            code: 'I128SO1',
            category: 'Finish',
            name: 'Call Symbol Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658717',
        },
        {
            code: 'I452SO1',
            category: 'Overview',
            name: 'Anchor Cue',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778945',
        },
        {
            code: 'I462SO1',
            category: 'Overview',
            name: 'Ball Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472778986',
        },
        {
            code: 'I457SO1',
            category: 'Overview',
            name: 'KISS Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472779003',
        },
    ],
    tennisGroundStrokes: [
        {
            code: 'I216TE1',
            category: 'At the split step',
            name: 'Color Patch Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665355',
        },
        {
            code: 'I206TE1',
            category: 'At the split step',
            name: 'Call Right or Left Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664916',
        },
        {
            code: 'I211TE1',
            category: 'At the split step',
            name: 'Call Offense or Defense Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665209',
        },
        {
            code: 'I125TE1',
            category: 'Movement Prep',
            name: 'Call Closed or Open Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472658582',
        },
        {
            code: 'I147TE1',
            category: 'Movement Prep',
            name: 'Stop It Direction Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661323',
        },
        {
            code: 'I136TE1',
            category: 'Movement Prep',
            name: 'Direction mats',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661227',
        },
        {
            code: 'I140TE1',
            category: 'Forward Swing',
            name: 'Call Top or Under',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661337',
        },
        {
            code: 'I146TE1',
            category: 'Forward Swing',
            name: 'Stop It Spin Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661310',
        },
        {
            code: 'I148TE1',
            category: 'Forward Swing',
            name: 'Stop It Depth Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472661391',
        },
        {
            code: 'I150TE1',
            category: 'Contact',
            name: 'Say "Hit" Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662928',
        },
        {
            code: 'I155TE1',
            category: 'Contact',
            name: 'Call Angle or Deep',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662818',
        },
        {
            code: 'I160TE1',
            category: 'Contact',
            name: 'Rip It, Dip It, Flip It, Chip It Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662817',
        },
        {
            code: 'I165TE1',
            category: 'Finish',
            name: 'Bounce Hit Drill (Level 1)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662909',
        },
        {
            code: 'I170TE1',
            category: 'Finish',
            name: 'Bounce Hit Drill (Level 2)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472662971',
        },
        {
            code: 'I177TE1',
            category: 'Finish',
            name: 'Bounce Hit (Level 3)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472663004',
        },
        {
            code: 'I252TE1',
            category: 'Overall',
            name: 'Anchor Cue',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472666974',
        },
        {
            code: 'I257TE1',
            category: 'Overall',
            name: 'K.I.S.S. Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472667067',
        },
        {
            code: 'I262TE1',
            category: 'Overall',
            name: 'Hit Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472667050',
        },
    ],
    tennisReturningServe: [
        {
            code: 'I215TE1',
            category: 'Preparation',
            name: 'Color Patch Drill (Large)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665275',
        },
        {
            code: 'Color Patch Drill (Large)',
            category: 'Preparation',
            name: 'Symbol Drill (Medium)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665965',
        },
        {
            code: 'I240TE1',
            category: 'Preparation',
            name: 'Color Dot Drill (Small)',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472666093',
        },
        {
            code: 'I230TE1',
            category: 'Pre-toss to Apex',
            name: 'Ball Color Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665986',
        },
        {
            code: 'I175TE1',
            category: 'Pre-toss to Apex',
            name: 'Call the Spin Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472663025',
        },
        {
            code: 'I185TE1',
            category: 'Pre-toss to Apex',
            name: 'Call "Ball" and "Hit"',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664575',
        },
        {
            code: 'I180TE1',
            category: 'Contact',
            name: 'Say "Hit" Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664601',
        },
        {
            code: 'I186TE1',
            category: 'Contact',
            name: 'Wide or "T" Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664533',
        },
        {
            code: 'Wide or "T" Drill',
            category: 'Contact',
            name: 'Wide, "T" or Middle Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664634',
        },
        {
            code: 'I210TE1',
            category: 'Finish',
            name: 'Count It Out',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472665173',
        },
        {
            code: 'I195TE1',
            category: 'Finish',
            name: 'Racquet Hole Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664614',
        },
        {
            code: 'I200TE1',
            category: 'Finish',
            name: 'Racquet Handle Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472664688',
        },
        {
            code: 'I250TE1',
            category: 'Overview',
            name: 'Anchor Cue',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472666995',
        },
        {
            code: 'I255TE1',
            category: 'Overview',
            name: 'K.I.S.S. Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472666969',
        },
        {
            code: 'I260TE1',
            category: 'Overview',
            name: 'Hit Drill',
            eyeMovement: '',
            link: 'https://player.vimeo.com/video/472667039',
        },
    ],
};
