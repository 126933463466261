import * as d3 from 'd3';

import { VisualizationElement, D3Selection, Attributes, Styles, Properties, Classes } from '../elements';
import { calculateAspectRatio } from '@/common/utils/reports/visualData';
import * as MathUtils from '@/common/utils/math';

export interface LineProperties {
    start: [number, number];
    end?: [number, number];
    length?: number;
    degrees?: number;
    aspectRatio?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
    id?: string;
    rotate?: number;
}

export class Line extends VisualizationElement {
    public constructor(private readonly lineProperties: LineProperties) {
        super({
            attributes: lineProperties.attributes,
            styles: lineProperties.styles,
            properties: lineProperties.properties,
            classes: lineProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        let aspectRatio = this.lineProperties.aspectRatio;
        if (!aspectRatio) {
            aspectRatio = calculateAspectRatio(svg);
        }

        if (
            !this.lineProperties.end &&
            this.lineProperties.length !== undefined &&
            this.lineProperties.degrees !== undefined
        ) {
            this.lineProperties.end = [0, 0];
            this.lineProperties.end[0] =
                this.lineProperties.length * Math.cos(MathUtils.degreesToRadians(this.lineProperties.degrees)) +
                this.lineProperties.start[0];
            this.lineProperties.end[1] =
                this.lineProperties.length * Math.sin(MathUtils.degreesToRadians(this.lineProperties.degrees)) +
                this.lineProperties.start[1];
        } else if (!this.lineProperties.end) {
            this.lineProperties.end = [0, 0];
        }

        const selection = d3
            .select(svg)
            .append('line')
            .attr('x1', this.lineProperties.start[0])
            .attr('y1', this.lineProperties.start[1] / aspectRatio)
            .attr('x2', this.lineProperties.end[0])
            .attr('y2', this.lineProperties.end[1] / aspectRatio);

        if (this.lineProperties.id) {
            selection.attr('id', this.lineProperties.id);
        }

        const rotate = this.lineProperties.rotate;
        if (rotate) {
            const midX = (this.lineProperties.start[0] + this.lineProperties.end[0]) / 2.0;
            const midY = (this.lineProperties.start[1] + this.lineProperties.end[1]) / 2.0 / aspectRatio;
            selection.attr('transform', `rotate(${rotate} ${midX},${midY})`);
        }

        return selection;
    }
}
