export default {
    unauthenticatedSubject: 'Trouble Logging In?',
    passwordNotWorking: "If your password isn't working, use this link to reset it:",
    newPatient: 'If you’re a patient and not sure if you have a login, please contact your RightEye provider.',
    forgotPassword: 'Forgot Password',
    forgotPasswordSuccess:
        'If the provided information matches our records, then an email will be sent to the address provided.',
    needTechnicalAssistance: 'Need Technical Assistance?',
    phone: 'Phone',
    phoneNumber: '301-979-7970',
    email: 'Email',
    emailAddress: 'support@righteye.com',
    medicalQuestions: 'Have Medical Questions?',
    medicalAnswers:
        'For questions about your report results, or if EyeQ Trainer was assigned for you, please contact your RightEye provider',
    backToLogin: 'BACK TO LOGIN',
    greeting: 'Hi {user},',
    support: 'Contact Support',
    supportPrompt: 'How can we help?',
    supportConfirmation:
        'Thank you for your message. Our support team has been notified and will get back to you as soon as possible.',
};
