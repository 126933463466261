import { Position2d } from '../types/reports/position';
import ScreenData from '../types/reports/screenData';

export const INCHES_PER_CM = 1 / 2.54;

export function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
}

export function degreesToPixels(visualAngleDegrees: number, centimetersFromScreen: number, pixelsPerInch: number) {
    const radians = degreesToRadians(visualAngleDegrees);
    const centimeters = centimetersFromScreen * Math.tan(radians);
    const pixelsPerCentimeter = pixelsPerInch * INCHES_PER_CM;
    return centimeters * pixelsPerCentimeter;
}

export function degreesToNormalized(visualAngleDegrees: number, screenData: ScreenData) {
    const pixels = degreesToPixels(visualAngleDegrees, screenData.dpi, screenData.centimetersFromScreen);
    return pixels / screenData.width;
}

export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

export function getOrigin(containerWidth: number, containerHeight: number): Position2d {
    return new Position2d(containerWidth / 2, containerHeight / 2);
}

export function getHeightForAspectRatio(width: number, aspectRatio: number) {
    return width / aspectRatio;
}

export function getWidthForAspectRatio(height: number, aspectRatio: number) {
    return height * aspectRatio;
}

export function cmToInches(valueInCm: number): number {
    return valueInCm * INCHES_PER_CM;
}

export function roundToNearestTenth(value: number): number {
    return Math.round(value * 10.0) / 10.0;
}

export function mmToPixels(value: number, pixelsPerInch: number) {
    const valueInCm = value / 10.0;
    const valueInInches = cmToInches(valueInCm);

    return pixelsPerInch * valueInInches;
}
