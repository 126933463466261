import { Position2d } from '@/common/types/reports/position';
import { SCREEN_DATA } from '@/common/constants/visualizations.constants';
import * as Constants from '@/common/constants/reports.constants';
import { ReportData } from '@/common/types/reports/reportData';
import {
    GazeData,
    ProcessedGazePoint,
    ProcessedVisualData,
    TimeStampedEyeData,
} from '@/common/types/reports/visualData';
import ScreenData from '@/common/types/reports/screenData';

// Processes raw report data coming in from the api into a format that's useful for our reports
export function extractVisualData(report: ReportData, testType: Constants.TestType): ProcessedVisualData {
    const gazeData = report.tests[testType].visualData.gazeData;
    const systemType = report.assessment?.systemType ? report.assessment.systemType : 'I15';
    const screenData = SCREEN_DATA[systemType];

    const aspectRatio = screenData.width / screenData.height;
    const data = processData(gazeData, screenData);

    const leftPoints = [];
    const rightPoints = [];
    for (let i = 0; i < data.length - 1; i++) {
        const [startLeft, startRight] = data[i];
        const [stopLeft, stopRight] = data[i + 1];

        leftPoints.push(createGazePoint(startLeft, stopLeft));
        rightPoints.push(createGazePoint(startRight, stopRight));
    }

    const left = {
        points: leftPoints,
        aspectRatio,
    };

    const right = {
        points: rightPoints,
        aspectRatio,
    };

    return {
        left,
        right,
    };
}

// This is the main data processing pipline.
// Any sort of preprocessing that converts raw data to report data should go here.
// All points should be normalized (0.0 <= x <= 1.0) when they leave this function.
function processData(gazeData: GazeData[], screenData: ScreenData): Array<[TimeStampedEyeData, TimeStampedEyeData]> {
    return gazeData
        .filter(filterNull)
        .filter(filterBlinks)
        .map((p) => normalizePoint(p, screenData))
        .map(separateEyeData);
}

// Returns false if this point is a null or undefined value
export function filterNull(point: GazeData): boolean {
    const isNotNull =
        point.left.x !== undefined &&
        point.left.x !== null &&
        point.left.y !== undefined &&
        point.left.y !== null &&
        point.right.x !== undefined &&
        point.right.x !== null &&
        point.right.y !== undefined &&
        point.right.y !== null;
    return isNotNull;
}

// Returns false if we think this point represents a blink
export function filterBlinks(point: GazeData): boolean {
    const isNotBlink = point.left.x !== 0 && point.left.y !== 0 && point.right.x !== 0 && point.right.y !== 0;
    return isNotBlink;
}

// Normalizes a single gaze point based on the given screen data
export function normalizePoint(point: GazeData, screenData: ScreenData): GazeData {
    const left = {
        ...point.left,
        ...normalizePosition(new Position2d(point.left.x, point.left.y), screenData),
    };

    const right = {
        ...point.right,
        ...normalizePosition(new Position2d(point.right.x, point.right.y), screenData),
    };

    const processedPoint = {
        ...point,
        left,
        right,
    };

    return processedPoint;
}

// Normalize a single x/y coordinate point based on the given screendata
export function normalizePosition(point: Position2d, screenData: ScreenData): Position2d {
    return new Position2d(point.x / screenData.width, point.y / screenData.height);
}

// Splits up a gaze point into separate pieces of eye data, each with its own copy of the
// shared part of the data.
export function separateEyeData(point: GazeData): [TimeStampedEyeData, TimeStampedEyeData] {
    const timeStamp = typeof point.timeStamp === 'string' ? parseInt(point.timeStamp, 10) : point.timeStamp;

    const left = {
        timeStamp,
        phoria: point.phoria,
        x: point.left.x,
        y: point.left.y,
        zDistance: point.left.zDistance,
        eyeX: point.left.eyeX,
        eyeY: point.left.eyeY,
    };

    const right = {
        timeStamp,
        phoria: point.phoria,
        x: point.right.x,
        y: point.right.y,
        zDistance: point.right.zDistance,
        eyeX: point.right.eyeX,
        eyeY: point.right.eyeY,
    };

    return [left, right];
}

// Takes a raw gaze point returned from the data API and processes it into a more useful format for reports
function createGazePoint(start: TimeStampedEyeData, stop: TimeStampedEyeData): ProcessedGazePoint {
    const mirroredStartX = 1.0 - start.x; // Mirror X coordinate for viewer's POV
    const normalizedStartPoint = new Position2d(mirroredStartX, start.y);

    const mirroredStopX = 1.0 - stop.x;
    const normalizedStopPoint = new Position2d(mirroredStopX, stop.y);

    const gazePoint = {
        startPosition: normalizedStartPoint,
        stopPosition: normalizedStopPoint,
        duration: (stop.timeStamp - start.timeStamp) / 1000,
        delay: start.timeStamp / 1000,
        phoria: start.phoria,
        zDistance: start.zDistance,
        eyeX: start.eyeX,
        eyeY: start.eyeY,
    };

    return gazePoint;
}

export function calculateAspectRatio(svg: SVGSVGElement): number {
    let aspectRatio = svg.clientWidth / svg.clientHeight;

    if (!aspectRatio || aspectRatio === 0 || Number.isNaN(aspectRatio) || !Number.isFinite(aspectRatio)) {
        aspectRatio = 1;
    }

    return aspectRatio;
}
