



















































import Vue, { PropType } from 'vue';

import { WORKOUT_PLANS } from '@/common/types/trainer/program';
import { ExerciseModel } from '@/common/types/trainer/program';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import DatePickerEditIcon from '@/views/common/controls/DatePickerEditIcon.vue';
import DropdownEditIcon from '@/views/common/controls/DropdownEditIcon.vue';
import ExercisesDisplay from '@/views/trainer/admin/components/ExercisesDisplay.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import TextLink from '@/views/common/controls/TextLink.vue';
import { EqtParticipant } from '@/common/types/trainer/eqtParticipant';
import { getTimeStampFormat, getDateFormat } from '@/common/utils/date';

export default Vue.extend({
    components: {
        DatePickerEditIcon,
        DropdownEditIcon,
        ExercisesDisplay,
        LoadingCircle,
        TextLink,
        GreenButton,
    },
    props: {
        value: Object as PropType<EqtParticipant>,
        editable: Boolean,
        loading: Boolean,
    },
    data() {
        return {
            participantId: undefined as string | undefined,
            dateCreated: '' as string | undefined,
            exercises: [] as ExerciseModel[] | undefined,
            workoutPlan: undefined as string | undefined,
            exercisesCompleted: 0 as number,
            endDate: '' as string | undefined,
        };
    },
    computed: {
        workoutPlans(): string[] {
            return WORKOUT_PLANS.map((wp) =>
                this.$t('trainerAdmin.participants.assignmentDetails.workoutPlans.' + wp).toString(),
            );
        },
        hasAssessment(): boolean {
            return this.value.currentAssignment?.Assessment?.Id !== undefined;
        },
        displayDate(): string {
            return this.dateCreated === '' || !this.dateCreated ? 'N/A' : this.dateCreated;
        },
    },
    watch: {
        value() {
            this.loadAssignmentDetails();
        },
    },
    methods: {
        loadAssignmentDetails() {
            this.participantId = this.value.participantId;
            this.dateCreated = getTimeStampFormat(this.value.currentAssignment?.DateCreated as Date);
            this.exercises = this.value.currentAssignment?.Exercises;
            if (this.value.program) {
                this.exercisesCompleted = this.value.program.ExercisesCompleted;
                this.workoutPlan = this.value.program.WorkoutPlan?.name;
            } else {
                this.exercisesCompleted = 0;
                this.workoutPlan = undefined;
            }
            this.endDate = this.value.endDate;
        },
        onAssessmentClick() {
            if (this.hasAssessment && this.value.currentAssignment?.Assessment) {
                this.$router.push({
                    path: `/report/${this.participantId}/standard/${this.value.currentAssignment.Assessment.Id}`,
                });
            }
        },
        onWorkoutPlanSelection(workoutPlan: string) {
            const actualWorkoutPlanValueIndex = this.workoutPlans.findIndex((wp) => wp === workoutPlan);
            this.workoutPlan = WORKOUT_PLANS[actualWorkoutPlanValueIndex];
            this.$emit('workoutPlanChange', this.workoutPlan);
        },
        onEndDateSelection(date: Date) {
            this.endDate = getDateFormat(date);
            this.$emit('endDateChange', this.endDate);
        },
    },
    mounted() {
        this.loadAssignmentDetails();
    },
});
