export function isNinePointMotorFunctionPassed(result: string) {
    if (result === 'Pass') {
        return true;
    } else {
        return false;
    }
}

export function isNearPointConvergencePassed(breakPoint: number, recoveryPoint: number): boolean {
    const breakPointThresholdCm = 10.16; // 4 inches
    const recoveryPointThresholdCm = 12.7; // 5 inches
    if (breakPoint > breakPointThresholdCm || recoveryPoint > recoveryPointThresholdCm) {
        return false;
    } else {
        return true;
    }
}

export function isScreenerPassed(
    ninePointMotorFunctionResult: boolean,
    nearPointConvergenceResult: boolean,
    surveyResult: boolean,
): boolean {
    if (ninePointMotorFunctionResult === false || nearPointConvergenceResult === false || surveyResult === false) {
        return false;
    } else {
        return true;
    }
}
