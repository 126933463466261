













import Vue from 'vue';

import TopNavigation from '@/views/common/navigation/TopNavigation.vue';
import LeftNav from '@/views/common/navigation/LeftNav.vue';
import ErrorBanner from '@/views/common/static/ErrorBanner.vue';

export default Vue.extend({
    components: {
        LeftNav,
        TopNavigation,
        ErrorBanner,
    },
});
