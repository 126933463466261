
































import Vue from 'vue';

import ReportsApi from '@/api/reports.api';

import * as Nav from '@/store/navigation/mutations';

import Logger from '@/common/utils/logger';

import ReportToolbar from '@/views/common/controls/ReportToolbar.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import NormsSelector from '@/views/reports/components/NormsSelector.vue';
import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import Report from '@/views/reports/Report.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { ReportData } from '@/common/types/reports/reportData';
import { NormsSelection, ReportNorms, AgeBasedNormsRange } from '@/common/types/reports/norms';
import { ageBasedRangeFromDateOfBirth } from '@/common/utils/reports/norms';
import { reportCardsFromReportData } from '@/common/utils/reports/reports';
import * as ParticipantUtils from '@/common/utils/participant';

import {
    ReportCardName,
    SENSORIMOTOR_EXAM_TEST_TYPES,
    REPORT_NAVIGATION_ICONS,
} from '@/common/constants/reports.constants';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';
import { Participant } from '@/common/types/user/participant';
import ParticipantInfo from '@/views/common/components/ParticipantInfo.vue';
import PrintableSensorimotorExamReport from '@/views/reports/PrintableSensorimotorExamReport.vue';
// import SurveyApi from '@/api/survey.api';

const CARDS_TO_ALWAYS_SHOW = [
    'Calibration',
    'CardinalGazePosition',
    'FourDotFusion',
    'ConditionsRecommendations',
] as ReportCardName[];

const REPORT_CARD_DISPLAY_ORDER = [
    'Calibration',
    'FixationStability',
    'HorizontalSmoothPursuit',
    'FourDotFusion',
    'CardinalGazePosition',
    'CircularSmoothPursuit',
    'VerticalSmoothPursuit',
    'HorizontalSaccades',
    'VerticalSaccades',
    'SimpleReactionTime',
    'HorizontalGapSaccades',
    'ConditionsRecommendations',
    'SurveyCard',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: false,
        },
        updateNavLink: Boolean,
    },
    components: {
        LoadingCircle,
        NormsSelector,
        ParticipantInfo,
        PrintableSensorimotorExamReport,
        Report,
        ReportFooter,
        ScreenDistanceDisplay,
        ReportToolbar,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            norms: {} as ReportNorms,
            cards: [] as ReportCardName[],
            loading: true,
        };
    },
    computed: {
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
    },
    async mounted() {
        try {
            this.loading = true;

            this.reportData = await ReportsApi.getReportDataByAssessmentId(
                this.assessmentId,
                SENSORIMOTOR_EXAM_TEST_TYPES,
            );
            await this.$store.dispatch(SET_VIEWING_PARTICIPANT, this.reportData.participant.id);

            this.cards = reportCardsFromReportData(this.reportData);
            if (this.cards.length === 0) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }
            CARDS_TO_ALWAYS_SHOW.map((card: ReportCardName) => {
                if (!this.cards.includes(card)) {
                    this.cards.push(card);
                }
            });

            /*
            const survey = await SurveyApi.getSurvey(this.participant.id);

            if (survey.responses) {
                this.cards.push('SurveyCard');
            }
            */

            this.cards = this.cards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                return REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) > REPORT_CARD_DISPLAY_ORDER.indexOf(lhs) ? 1 : -1;
            });

            await this.updateNorms();

            this.generateLeftNavLinks();
            this.loading = false;
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        } finally {
            this.loading = false;
        }
    },
    watch: {
        updateNavLink(shouldUpdateNavLinks: boolean) {
            if (shouldUpdateNavLinks) {
                this.generateLeftNavLinks();
            }
        },
    },
    methods: {
        async updateNorms(selection?: NormsSelection) {
            if (!this.reportData) {
                return;
            }
            if (!selection) {
                const ageRange = this.ageRange();
                selection = {
                    ageBased: ageRange ? [ageRange] : [],
                    games: ageRange ? [ageRange] : [],
                };
            }

            const norms = await ReportsApi.getNormsDataForReport(this.reportData, selection, undefined);
            this.norms = norms;
        },
        async onNormsSelectionChanged(selection: NormsSelection) {
            this.updateNorms(selection);
        },
        ageRange(): AgeBasedNormsRange | undefined {
            const dob = ParticipantUtils.getDateOfBirth(this.participant);
            return dob ? ageBasedRangeFromDateOfBirth(dob) : undefined;
        },
        generateLeftNavLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, '❮ ' + this.$t('reports.reports.sensorimotorExamReport'));

            const links = this.cards.map((c) => ({
                text: this.$t('reports.cards.titles.' + c.toString()).toString(),
                to: { hash: c },
                icon: c in REPORT_NAVIGATION_ICONS ? REPORT_NAVIGATION_ICONS[c] : 'analytics',
                enabled: true,
            }));
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
    },
});
