























import Vue, { PropType } from 'vue';

import { TestType } from '@/common/constants/reports.constants';
import { LabeledNorms, NormsCollection } from '@/common/types/reports/norms';
import { formatValue } from '@/common/utils/reports/format';
import * as MetricsUtils from '@/common/utils/reports/metrics';
import { MetricData } from '@/common/types/reports/metricData';

export type MetricTableField = string | MetricTableFieldConfig;

export interface MetricTableFieldConfig {
    path?: string;
    metricPath?: string;
    normsPath?: string;
    translationPath?: string;
}

export default Vue.extend({
    props: {
        testType: String as PropType<TestType>,
        metrics: Object,
        norms: Object as PropType<NormsCollection>,
        fields: Array as PropType<MetricTableField[]>,
        title: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            headers: [] as any[],
            items: [] as any[],
        };
    },
    methods: {
        metricValue(metrics: MetricData, field: MetricTableField) {
            let path;

            if (!metrics) {
                return formatValue(undefined);
            }

            if (typeof field === 'string') {
                path = field;
            } else if (field.metricPath) {
                path = field.metricPath;
            } else if (field.path) {
                path = field.path;
            } else {
                throw new Error('Metric field config must have a metricPath or path');
            }

            return formatValue(MetricsUtils.getNestedMetricValue(metrics, path));
        },
        translatedLabel(field: MetricTableField) {
            const translation = (path: string) => {
                return this.$t(`reports.metrics.tests.${this.testType}.${path}`);
            };

            if (typeof field === 'string') {
                return translation(field);
            }

            if (field.translationPath) {
                return translation(field.translationPath);
            }

            if (field.path) {
                return translation(field.path);
            }

            throw new Error('Metric field config must have a translationPath or path');
        },
        generateHeaders() {
            this.headers = [
                {
                    text: 'Metrics',
                    align: 'start',
                    value: 'metricField',
                    sortable: true,
                    width: '18vw',
                },
                {
                    text: 'My Eyes',
                    align: 'start',
                    value: 'metricValue',
                    sortable: false,
                    width: '8vw',
                },
            ];
            this.sortedAgeNorms.map((a: LabeledNorms) => {
                this.headers.push({
                    text: `${this.$t('reports.metrics.normsLabelPrefixes.ages')} ${a.label}`,
                    align: 'start',
                    value: a.label,
                    sortable: false,
                    width: '12vw',
                });
            });
            this.sortedMLBNorms.map((a: LabeledNorms) => {
                this.headers.push({
                    text: `${this.$t('reports.metrics.normsLabelPrefixes.mlb')} ${a.label}`,
                    align: 'start',
                    value: a.label,
                    sortable: false,
                    width: '12vw',
                });
            });
        },
        generateItems() {
            this.items = [] as any[];
            this.fields.map((field) => {
                const item = {
                    metricField: this.translatedLabel(field),
                    metricValue: this.metricValue(this.metrics, field),
                } as any;
                this.sortedAgeNorms.map((a: LabeledNorms) => {
                    item[a.label] = this.metricValue(a.data, field);
                });
                this.sortedMLBNorms.map((a: LabeledNorms) => {
                    item[a.label] = this.metricValue(a.data, field);
                });
                this.items.push(item);
            });
        },
    },
    computed: {
        sortedAgeNorms() {
            if (this.norms?.ageBased) {
                return this.norms.ageBased.sort((a, b) =>
                    Number(a.label.split('-')[0]) > Number(b.label.split('-')[0]) ? 1 : -1,
                );
            } else {
                return [];
            }
        },
        sortedMLBNorms() {
            if (this.norms?.mlb) {
                return this.norms.mlb.sort((a, b) => (a.label > b.label ? 1 : -1));
            } else {
                return [];
            }
        },
    },

    async mounted() {
        this.generateHeaders();
        this.generateItems();
    },
    watch: {
        norms() {
            this.generateHeaders();
            this.generateItems();
        },
        fields() {
            this.generateHeaders();
            this.generateItems();
        },
    },
});
