



































import Vue from 'vue';

import { ButtonState } from '@/common/types/general';

import ComponentModal from '@/views/common/containers/ComponentModal.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';
import { VersionOverride } from '@/common/types/appVersions/appVersions';

export default Vue.extend({
    components: {
        ComponentModal,
        GreenButton,
        SaveConfirmation,
    },
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            rules: {
                required: (value: any) => !!value || 'Required.',
            },
            id: undefined as undefined | string,
            version: undefined as undefined | string,
            showConfirmation: false as boolean,
        };
    },
    computed: {
        versionOverride(): VersionOverride {
            return {
                Id: this.id,
                Version: this.version,
            } as VersionOverride;
        },
        isValid(): boolean {
            return this.id !== undefined && this.id !== '' && this.version !== undefined && this.version !== '';
        },
        saveButtonState(): ButtonState {
            return this.isValid ? 'active' : 'inactive';
        },
    },
    methods: {
        async save() {
            this.$emit('save', this.versionOverride);
            this.$emit('hide');
            this.id = undefined;
            this.version = undefined;
        },
        showSaveConfirmation() {
            this.showConfirmation = true;
        },
        saveConfirmation() {
            this.save();
            this.showConfirmation = false;
        },
        cancelConfirmation() {
            this.showConfirmation = false;
            this.$emit('hide');
        },
    },
});
