

































































import Vue from 'vue';

export default Vue.extend({
    components: {},

    data() {
        return {
            fixationImageSources: [
                { src: require('@/assets/dynamicVision/brain/fixation_gray.png') },
                { src: require('@/assets/dynamicVision/brain/fixation_red.png') },
            ],
            fixationIndex: 0,
        };
    },
    computed: {},
    methods: {
        handleFixationClick() {
            this.fixationIndex = (this.fixationIndex + 1) % this.fixationImageSources.length;
        },
    },
});
