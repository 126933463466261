

























































































import Vue, { PropType } from 'vue';

import { ReportData } from '@/common/types/reports/reportData';
import InfoModal from '@/views/common/containers/InfoModal.vue';

import CardinalGazePosition, {
    CardinalGazePositionConfig,
} from '@/views/reports/visualizations/CardinalGazePosition.vue';

import MultiMetricTable from '@/views/reports/metrics/MultiMetricTable.vue';
import FlatMultiMetricTable from '@/views/reports/metrics/FlatMultiMetricTable.vue';

import { ReportNorms } from '@/common/types/reports/norms';
import { extractLeftRightBothNorms } from '@/common/utils/reports/norms';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        CardinalGazePosition,
        MultiMetricTable,
        FlatMultiMetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        forPrint: {
            default: false,
            required: false,
            type: Boolean,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            metrics: undefined as any,
            fieldsBinocular: [] as string[],
            fieldsMonocular: [] as string[],
            leftConfig: {} as CardinalGazePositionConfig,
            rightConfig: {} as CardinalGazePositionConfig,
            bothConfig: {} as CardinalGazePositionConfig,
        };
    },
    computed: {
        hasLeft(): boolean {
            return this.metrics && this.metrics.eyeType.includes('LEFT');
        },
        hasRight(): boolean {
            return this.metrics && this.metrics.eyeType.includes('RIGHT');
        },
        hasBinocular(): boolean {
            return this.metrics && this.metrics.eyeType.includes('BINOCULAR');
        },
        norms(): any {
            return extractLeftRightBothNorms(this.normsData.cardinalGazePosition);
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.cardinalGazePosition.cgp'));
            infoText.push(this.$t('reports.info.cardinalGazePosition.disparity'));

            return infoText;
        },
    },
    mounted() {
        if (!this.reportData.tests.cardinalGazePosition) {
            this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
            return;
        }

        this.metrics = this.reportData.tests.cardinalGazePosition.metrics;
        this.fieldsBinocular = ['saccadicLatencyStats', 'vrt', 'cgpRank', 'disparity', 'targetingDisplacement'];
        this.fieldsMonocular = ['saccadicLatencyStats', 'vrt', 'cgpRank', 'targetingDisplacement'];

        this.leftConfig = {
            eye: 'left',
            metrics: this.metrics.left,
            norms: this.normsData?.cardinalGazePosition,
            systemType: this.reportData.assessment.systemType,
        };
        this.rightConfig = {
            eye: 'right',
            metrics: this.metrics.right,
            norms: this.normsData?.cardinalGazePosition,
            systemType: this.reportData.assessment.systemType,
        };
        this.bothConfig = {
            eye: 'both',
            metrics: this.metrics.both,
            norms: this.normsData?.cardinalGazePosition,
            systemType: this.reportData.assessment.systemType,
        };
    },
});
