













































import Vue, { PropType } from 'vue';

import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ReportData } from '@/common/types/reports/reportData';
import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';
import { translateSvaScore } from '@/common/utils/reports/reports';
import { ReportNorms } from '@/common/types/reports/norms';
import { ageBasedRangeFromAge, extractLeftRightBothNorms } from '@/common/utils/reports/norms';
import { getAgeFromDateOfBirth } from '@/common/utils/date';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        const age = getAgeFromDateOfBirth(this.reportData.participant.dateOfBirth!);

        const ageRange = ageBasedRangeFromAge(age)!;
        const metrics = translateSvaScore(this.reportData.tests.staticVisualAcuity.metrics);

        const fields = ['svaScore'];

        return {
            ageRange,
            fields,
            metrics,
        };
    },
    computed: {
        hasLeft(): boolean {
            return this.metrics.left && this.metrics.left.svaScore;
        },
        hasRight(): boolean {
            return this.metrics.right && this.metrics.right.svaScore;
        },
        hasBoth(): boolean {
            return this.metrics.both && this.metrics.both.svaScore;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.staticVisualAcuity?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.staticVisualAcuity?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.staticVisualAcuity?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.staticVisualAcuity.sva'));

            return infoText;
        },
        norms(): any {
            return extractLeftRightBothNorms(this.normsData.staticVisualAcuity);
        },
    },
});
