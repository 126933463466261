import ApiService from '@/api/common/apiService';
import { Survey, SurveyResponse } from '@/common/types/survey/survey';

class SurveyApi {
    public async getSurvey(assessmentId: string): Promise<Survey> {
        const url: string = `/righteye.api/survey/${assessmentId}`;
        return await ApiService.get<Survey>(url);
    }

    public async updateSurveyResponse(assessmentId: string, response: SurveyResponse): Promise<boolean> {
        const url: string = `/righteye.api/survey/${assessmentId}`;
        return await ApiService.put<boolean>(url, response);
    }
}

export default new SurveyApi();
