import { Module } from 'vuex';
import { RootState } from '@/store/index';
import * as Mutations from '@/store/user/mutations';
import * as Actions from '@/store/user/actions';
import store from '@/store/index';
import { User } from '@/common/types/user/user';

import UserApi from '@/api/user.api';
import Logger from '@/common/utils/logger';

interface UserState {
    viewingUser?: User | undefined;
}

const userModule: Module<UserState, RootState> = {
    state: {
        viewingUser: undefined,
    },
    mutations: {
        [Mutations.SET_VIEWING_USER](state: UserState, user: User) {
            state.viewingUser = user;
        },
        [Mutations.CLEAR_USER_STATE](state: UserState) {
            state.viewingUser = undefined;
        },
    },
    actions: {
        async [Actions.SET_VIEWING_USER](context, userid: string) {
            if (context.state.viewingUser?.id !== userid) {
                try {
                    const user = await UserApi.getUserById(userid);
                    context.commit(Mutations.SET_VIEWING_USER, user);
                } catch (error) {
                    Logger.error(`There was an error getting company with id ${userid}: ${error}`);
                }
            }
        },
    },
    getters: {
        viewingUser(state: UserState): User {
            if (state.viewingUser) {
                return state.viewingUser;
            } else {
                return store.getters.currentUser;
            }
        },
    },
};

export default userModule;
