













import Vue from 'vue';

import ReportsApi from '@/api/reports.api';
import * as Nav from '@/store/navigation/mutations';

import Logger from '@/common/utils/logger';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import Report from '@/views/reports/Report.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { ReportData } from '@/common/types/reports/reportData';
import { ReportCardName, FDA_STANDARD_TEST_TYPES, REPORT_NAVIGATION_ICONS } from '@/common/constants/reports.constants';
import * as ReportUtils from '@/common/utils/reports/reports';

const REPORT_CARD_DISPLAY_ORDER = [
    'CircularSmoothPursuit',
    'HorizontalSmoothPursuit',
    'VerticalSmoothPursuit',
    'HorizontalSaccades',
    'VerticalSaccades',
    'HorizontalGapSaccades',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: false,
        },
        updateNavLink: Boolean,
    },
    components: {
        Report,
        LoadingCircle,
        ReportFooter,
        ScreenDistanceDisplay,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            cards: [] as ReportCardName[],
            loading: true,
        };
    },
    async mounted() {
        try {
            this.loading = true;

            this.reportData = await ReportsApi.getReportDataByAssessmentId(this.assessmentId, FDA_STANDARD_TEST_TYPES);
            this.cards = ReportUtils.reportCardsFromReportData(this.reportData);
            if (this.cards.length === 0) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }
            this.cards = this.cards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                return REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) > REPORT_CARD_DISPLAY_ORDER.indexOf(lhs) ? 1 : -1;
            });

            this.generateLeftNavLinks();
            this.loading = false;
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        } finally {
            this.loading = false;
        }
    },
    watch: {
        updateNavLink(shouldUpdateNavLinks: boolean) {
            if (shouldUpdateNavLinks) {
                this.generateLeftNavLinks();
            }
        },
    },
    methods: {
        generateLeftNavLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, '❮ ' + this.$t('reports.reports.fdaStandardReport'));

            const links = this.cards.map((c) => ({
                text: this.$t('reports.cards.titles.' + c.toString()).toString(),
                to: { hash: c },
                icon: c in REPORT_NAVIGATION_ICONS ? REPORT_NAVIGATION_ICONS[c] : 'analytics',
                enabled: true,
            }));
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
    },
});
