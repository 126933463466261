






import Vue from 'vue';

export default Vue.extend({
    props: {
        href: {
            type: String,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
});
