
















import Vue, { PropType } from 'vue';

import { Company } from '@/common/types/company/company';
import * as Settings from '@/common/utils/settings';

import EqtSettings from '@/views/company/settings/EqtSettings.vue';
import CompanyModules from '@/views/company/settings/CompanyModules.vue';

export default Vue.extend({
    components: {
        CompanyModules,
        EqtSettings,
    },
    props: {
        company: {
            type: Object as PropType<Company>,
            required: true,
        },
    },
    data() {
        return {
            Settings,
        };
    },
});
