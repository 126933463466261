













import Vue from 'vue';
import MessageBar from '@/views/common/static/MessageBar.vue';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: { MessageBar },
    data() {
        return {
            msg: '' as TranslateResult,
        };
    },
    computed: {
        currentUser(): AuthenticatedUser | undefined {
            return this.$store.getters.currentUser;
        },
    },
    mounted() {
        this.msg = this.$t('trainer.congratulations.message');
    },
});
