































import Vue, { PropType } from 'vue';

import { LabeledNorms, NormsCollection } from '@/common/types/reports/norms';
import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import { MetricData } from '@/common/types/reports/metricData';

export default Vue.extend({
    components: {
        MetricTable,
    },
    props: {
        metrics: Object as PropType<MetricData>,
        norms: Object as PropType<NormsCollection>,
        fields: Array as PropType<string[]>,
        title: {
            type: String,
            required: false,
            default: '',
        },
        testType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedMetric: this.fields[0] as string,
        };
    },
    computed: {
        processedNorms(): NormsCollection {
            const processedNorms = {} as Record<string, LabeledNorms[]>;
            Object.entries(this.norms).map(([normCategory, labeledNormsArray]) => {
                if (labeledNormsArray) {
                    processedNorms[normCategory] = [];
                    for (const labeledNorm of labeledNormsArray) {
                        if (labeledNorm.data) {
                            processedNorms[normCategory].push({
                                label: labeledNorm.label,
                                data: labeledNorm.data[this.selectedMetric],
                            });
                        }
                    }
                }
            });
            return processedNorms;
        },
    },
});
