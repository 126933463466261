import DataApi from '@/api/data.api';
import { TestType, ALL_TEST_TYPES, AssessmentType } from '@/common/constants/reports.constants';
import { ReportQuery } from '@/api/queries/reportQuery';
import { ParticipantAssessmentsQuery } from '@/api/queries/participantAssessmentsQuery';
import { ReportData, AssessmentResult } from '@/common/types/reports/reportData';
import { Assessment } from '@/common/types/reports/assessment';

import { NormsSelection, ReportNorms } from '@/common/types/reports/norms';
import { extractReportNorms } from '@/common/utils/reports/norms';
import { NormsQuery } from './queries/normsQuery';
import { ParticipantIdQuery } from '@/api/queries/participantIdQuery';
import { Participant } from '../common/types/user/participant';
import { ComparativeAssessment } from '../common/types/reports/comparativeAssessment';
import { ComparativeAssessmentQuery } from './queries/comparativeAssessmentQuery';
import { ComparativeAssessmentListQuery } from './queries/comparativeAssessmentListQuery';

import * as ReportUtils from '@/common/utils/reports/reports';

class ReportsApi {
    public async getReportDataByAssessmentId(assessmentId: string, testTypes: TestType[]): Promise<ReportData> {
        const query = new ReportQuery(assessmentId, testTypes);
        const response = await DataApi.query(query);
        return response.report;
    }

    public async getAssessmentsByParticipantIdAndAssessmentTypes(
        participantId: string,
        assessmentTypes: AssessmentType[],
    ): Promise<Assessment[]> {
        const query = new ParticipantAssessmentsQuery(participantId, assessmentTypes);
        const response = await DataApi.query(query);
        const assessments = response.assessments.map((a: AssessmentResult) => a.info) as Assessment[];
        return assessments;
    }

    public async getParticipantByAssessmeentId(assessmentId: string): Promise<Participant> {
        const query = new ParticipantIdQuery(assessmentId);
        const response = await DataApi.query(query);
        return response.report.participant;
    }

    public async getComparativeAssessmentIdsByNormsCategory(normsCategory: string): Promise<ComparativeAssessment[]> {
        const query = new ComparativeAssessmentListQuery(normsCategory);
        const response = await DataApi.query(query);

        let result = response.comparativeAssessments.map((r: any) => r.info);

        // Sort comparative assessments with Within Reference Data first and then the rest alphabetically
        result = result.sort((rhs: ComparativeAssessment, lhs: ComparativeAssessment) => {
            if (rhs.label === 'Within Reference Data') {
                return -1;
            } else if (lhs.label === 'Within Reference Data') {
                return 1;
            } else {
                return rhs.label > lhs.label ? 1 : -1;
            }
        });

        return result;
    }

    public async getReportDataByComparativeAssessmentId(
        comparativeAssessmentId: string,
        testTypes: TestType[] = ALL_TEST_TYPES,
    ): Promise<ReportData> {
        const query = new ComparativeAssessmentQuery(comparativeAssessmentId, testTypes);
        const response = await DataApi.query(query);
        const result = response.comparativeAssessments.pop();
        return result;
    }

    public async getNormsDataForReport(
        report: ReportData,
        selection: NormsSelection,
        testTypes: TestType[] | undefined,
    ): Promise<ReportNorms> {
        if (ReportUtils.isSelectionEmpty(selection)) {
            return {} as ReportNorms;
        }

        const assessmentSystemType = report.assessment?.systemType ? report.assessment.systemType : 'I15';
        const systemType = assessmentSystemType === 'I15' ? 'i15' : 'smi';
        if (!testTypes) {
            testTypes = ReportUtils.testTypesForReport(report);
        }

        const query = new NormsQuery({
            selection,
            systemType,
            testTypes,
        });

        const response = await DataApi.query(query);
        const result = extractReportNorms(response.norms, assessmentSystemType);
        return result;
    }
}

export default new ReportsApi();
