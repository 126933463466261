






















import Vue, { PropType } from 'vue';

import * as d3 from 'd3';

import * as Constants from '@/common/constants/visualizations.constants';
import * as MathUtils from '@/common/utils/math';
import { ProcessedGazePoint, ProcessedVisualData } from '@/common/types/reports/visualData';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import PerspectiveImageVisualization from '@/views/reports/visualizations/PerspectiveImageVisualization.vue';
import EyeBoxVisualization from '@/views/reports/visualizations/EyeBoxVisualization.vue';

export interface FixationStabilityConfig {
    testData: ProcessedVisualData;
    systemType: string;
}

export default Vue.extend({
    components: {
        PerspectiveImageVisualization,
        EyeBoxVisualization,
    },
    props: {
        config: {
            type: Object as PropType<FixationStabilityConfig>,
        },
        playAnimation: Boolean,
        reportType: {
            required: false,
            type: String,
        },
        showGuidelines: {
            required: false,
            type: Boolean,
        },
        showEyeDominance: {
            required: false,
            type: Boolean,
        },
        showAvgHeadTilt: {
            required: false,
            type: Boolean,
        },
        eyeDominance: {
            required: false,
            type: String,
        },
        hasEyeCoordinates: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            visualization: new Visualization(),
            leftPoints: [] as ProcessedGazePoint[],
            rightPoints: [] as ProcessedGazePoint[],
        };
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        perspectiveImageSize(): number {
            return 0.032;
        },
        perspectiveImageLocation(): Position2d {
            return new Position2d(0.75, 0.23);
        },
        borderThickness(): number {
            return 0.0008;
        },
        labelFontSize(): number {
            return 0.0006;
        },
        perspectiveLabelLocation(): Position2d {
            return new Position2d(0.71, 0.272);
        },
        showAdditionalViz(): boolean {
            return this.reportType === 'standard' || this.reportType === 'sensorimotorExam';
        },
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const ASPECT_RATIO = screenData.width / screenData.height;
            const DEFAULT_ZOOM_LEVEL = 1.5;
            const ZOOM_LEVEL = this.config.systemType === 'I15' ? DEFAULT_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL + 1;

            this.leftPoints = this.config.testData.left.points;
            this.rightPoints = this.config.testData.right.points;

            if (this.isPatientPerspective) {
                this.leftPoints = this.invertPoints(this.leftPoints);
                this.rightPoints = this.invertPoints(this.rightPoints);
            }

            let labelOffset = -0.6;
            let displayAspectRatio = 6 / 2;

            if (this.config.systemType === 'Generic60Cm') {
                labelOffset = -0.85;
                displayAspectRatio = 7 / 2;
            }

            const EYES = [
                {
                    DATA: this.rightPoints,
                    OFFSET: this.isPatientPerspective ? 0.2 : -0.2,
                    Y_OFFSET: -0.25,
                    COLOR: Constants.RIGHT_COLOR,
                    ID: Constants.RIGHT_GAZE_TRAIL_ID,
                    LABEL: this.$t('reports.metrics.eye.right').toString(),
                    LABEL_OFFSET: labelOffset,
                    ESO_OFFSET_X: this.isPatientPerspective ? 0.41 : 0.59,
                    EXO_OFFSET_X: this.isPatientPerspective ? 0.59 : 0.41,
                    EYE_DOMINANCE_OFFSET_X: this.isPatientPerspective ? 0.633 : 0.366,
                },
                {
                    DATA: this.leftPoints,
                    OFFSET: this.isPatientPerspective ? -0.2 : 0.2,
                    Y_OFFSET: -0.25,
                    COLOR: Constants.LEFT_COLOR,
                    ID: Constants.LEFT_GAZE_TRAIL_ID,
                    LABEL: this.$t('reports.metrics.eye.left').toString(),
                    LABEL_OFFSET: labelOffset,
                    ESO_OFFSET_X: this.isPatientPerspective ? 0.59 : 0.41,
                    EXO_OFFSET_X: this.isPatientPerspective ? 0.41 : 0.59,
                    EYE_DOMINANCE_OFFSET_X: this.isPatientPerspective ? 0.366 : 0.633,
                },
            ];

            for (const eye of EYES) {
                this.drawErrorBands(ASPECT_RATIO, ZOOM_LEVEL, eye.OFFSET, eye.Y_OFFSET);
                this.drawEyeLabels(eye.LABEL, eye.COLOR, ASPECT_RATIO, ZOOM_LEVEL, eye.OFFSET, eye.LABEL_OFFSET);
                if (this.showGuidelines) {
                    this.drawGuidelines(
                        ASPECT_RATIO,
                        ZOOM_LEVEL,
                        eye.OFFSET,
                        eye.Y_OFFSET,
                        eye.ESO_OFFSET_X,
                        eye.EXO_OFFSET_X,
                    );
                }

                this.drawGazepoints(ASPECT_RATIO, ZOOM_LEVEL, eye.DATA, eye.COLOR, eye.OFFSET, eye.Y_OFFSET);

                if (this.showAvgHeadTilt) {
                    this.drawAvgHeadTilt(ASPECT_RATIO, ZOOM_LEVEL, eye.OFFSET, eye.Y_OFFSET);
                }
            }

            if (this.showEyeDominance && this.eyeDominance) {
                let eyeDominanceXOffset = EYES[0].EYE_DOMINANCE_OFFSET_X;
                if (this.eyeDominance === 'left') {
                    eyeDominanceXOffset = EYES[1].EYE_DOMINANCE_OFFSET_X;
                }

                this.visualization.text({
                    content: '(Likely Dominant)',
                    position: [eyeDominanceXOffset, 0.12],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${0.0008 / ZOOM_LEVEL}em`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                    },
                    styles: {
                        'fill': Constants.BLACK,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                });
            }

            this.visualization.zoom(ZOOM_LEVEL);
            this.visualization.drawNormalized(this.svg, displayAspectRatio);
        },
        drawErrorBands(aspectRatio: number, zoomLevel: number, xOffset: number, yOffset: number) {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            const AOI_RADIUS_1 =
                MathUtils.degreesToPixels(
                    Constants.AREA_OF_INTEREST_RADIUS_1,
                    screenData.centimetersFromScreen,
                    screenData.dpi,
                ) / screenData.width;
            const AOI_RADIUS_2 =
                MathUtils.degreesToPixels(
                    Constants.AREA_OF_INTEREST_RADIUS_2,
                    screenData.centimetersFromScreen,
                    screenData.dpi,
                ) / screenData.width;
            const AOI_RADIUS_3 =
                MathUtils.degreesToPixels(
                    Constants.AREA_OF_INTEREST_RADIUS_3,
                    screenData.centimetersFromScreen,
                    screenData.dpi,
                ) / screenData.width;

            const TEXT_FONT_SIZE_SMALL = 0.0012;
            const ONE_DEGREE_TEXT_POSITION = new Position2d(0.5 - AOI_RADIUS_1 * 1.1, 0.48);
            const TWO_DEGREE_TEXT_POSITION = new Position2d(0.5 - AOI_RADIUS_2 * 1.1, 0.46);
            const FOUR_DEGREE_TEXT_POSITION = new Position2d(0.5 - AOI_RADIUS_3 * 0.9, 0.43);

            const REFERENCE_DEFAULT_ATTRIBUTES = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.003 / zoomLevel,
                'stroke-opacity': 0.8,
            };

            const TEXT_ATTRIBUTES = {
                'font-size': `${TEXT_FONT_SIZE_SMALL / zoomLevel}em`,
                'font-family': Constants.TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            };

            const TEXT_STYLES = {
                'fill': Constants.BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            };

            this.visualization.circle({
                center: [Constants.ORIGIN.x + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                radius: AOI_RADIUS_1,
                aspectRatio,
                attributes: {
                    ...REFERENCE_DEFAULT_ATTRIBUTES,
                },
            });
            this.visualization.circle({
                center: [Constants.ORIGIN.x + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                radius: AOI_RADIUS_2,
                aspectRatio,
                attributes: {
                    ...REFERENCE_DEFAULT_ATTRIBUTES,
                },
            });
            this.visualization.circle({
                center: [Constants.ORIGIN.x + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                radius: AOI_RADIUS_3,
                aspectRatio,
                attributes: {
                    ...REFERENCE_DEFAULT_ATTRIBUTES,
                },
            });

            this.visualization.text({
                content: '1º',
                position: [ONE_DEGREE_TEXT_POSITION.x + xOffset / zoomLevel, ONE_DEGREE_TEXT_POSITION.y + yOffset],
                aspectRatio,
                attributes: {
                    ...TEXT_ATTRIBUTES,
                },
                styles: {
                    ...TEXT_STYLES,
                },
            });
            this.visualization.text({
                content: '2º',
                position: [TWO_DEGREE_TEXT_POSITION.x + xOffset / zoomLevel, TWO_DEGREE_TEXT_POSITION.y + yOffset],
                aspectRatio,
                attributes: {
                    ...TEXT_ATTRIBUTES,
                },
                styles: {
                    ...TEXT_STYLES,
                },
            });
            this.visualization.text({
                content: '4º',
                position: [FOUR_DEGREE_TEXT_POSITION.x + xOffset / zoomLevel, FOUR_DEGREE_TEXT_POSITION.y + yOffset],
                aspectRatio,
                attributes: {
                    ...TEXT_ATTRIBUTES,
                },
                styles: {
                    ...TEXT_STYLES,
                },
            });
        },
        drawEyeLabels(
            label: string,
            color: string,
            aspectRatio: number,
            zoomLevel: number,
            xOffset: number,
            yOffset: number,
        ) {
            this.visualization.text({
                content: label,
                position: [Constants.ORIGIN.x + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset / zoomLevel],
                aspectRatio,
                attributes: {
                    'font-size': `${0.0015 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                    'font-weight': 'bold',
                },
                styles: {
                    'fill': color,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
            });
        },
        drawGazepoints(
            aspectRatio: number,
            zoomLevel: number,
            points: ProcessedGazePoint[],
            color: string,
            xOffset: number,
            yOffset: number,
        ) {
            const attributes = {
                'fill': color,
                'stroke-width': 0.002 / zoomLevel,
                'stroke-opacity': 0.8,
            };

            this.visualization.map(points, (point) => {
                return Visualization.build().circle({
                    id: 'fixation-circle',
                    point: [point],
                    center: [point.startPosition.x + xOffset / zoomLevel, point.startPosition.y + yOffset],
                    radius: 0.0008,
                    aspectRatio,
                    attributes,
                });
            });
        },
        drawGuidelines(
            aspectRatio: number,
            zoomLevel: number,
            xOffset: number,
            yOffset: number,
            esoOffsetX: number,
            exoOffsetX: number,
        ) {
            const attributes = {
                'fill': 'none',
                'stroke': Constants.ORANGE,
                'stroke-width': 0.002 / zoomLevel,
            };

            this.visualization.text({
                content: 'Eso',
                position: [esoOffsetX + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                aspectRatio,
                attributes: {
                    'font-size': `${0.0008 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
                tooltip: {
                    id: 'eso-tooltip',
                    content: 'Inward Deviation',
                },
            });

            this.visualization.text({
                content: 'Exo',
                position: [exoOffsetX + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                aspectRatio,
                attributes: {
                    'font-size': `${0.0008 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
                tooltip: {
                    id: 'exo-tooltip',
                    content: 'Outward Deviation',
                },
            });

            this.visualization.text({
                content: 'Hyper',
                position: [Constants.ORIGIN.x + xOffset / zoomLevel, 0.385 + yOffset],
                aspectRatio,
                attributes: {
                    'font-size': `${0.0008 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
                tooltip: {
                    id: 'hyper-tooltip',
                    content: 'Upward Deviation',
                },
            });

            this.visualization.text({
                content: 'Hypo',
                position: [Constants.ORIGIN.x + xOffset / zoomLevel, 0.615 + yOffset],
                aspectRatio,
                attributes: {
                    'font-size': `${0.0008 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
                tooltip: {
                    id: 'hypo-tooltip',
                    content: 'Downward Deviation',
                },
            });

            this.visualization.line({
                start: [Constants.ORIGIN.x + xOffset / zoomLevel, 0.395 + yOffset],
                end: [Constants.ORIGIN.x + xOffset / zoomLevel, 0.605 + yOffset],
                aspectRatio,
                attributes,
            });

            this.visualization.line({
                start: [0.42 + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                end: [0.58 + xOffset / zoomLevel, Constants.ORIGIN.y + yOffset],
                aspectRatio,
                attributes,
            });
        },
        drawAvgHeadTilt(aspectRatio: number, zoomLevel: number, xOffset: number, yOffset: number) {
            const attributes = {
                'fill': 'none',
                'stroke': Constants.GREEN,
                'stroke-width': 0.003 / zoomLevel,
            };

            const { absoluteAngle } = this.avgAngle(this.leftPoints, this.rightPoints);

            this.visualization.text({
                content: 'Tilt',
                position: [0.5 + xOffset / zoomLevel, 0.5 + yOffset],
                aspectRatio,
                length: 0.088,
                degrees: absoluteAngle,
                attributes: {
                    'font-size': `${0.0008 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
            });

            this.visualization.line({
                start: [0.42 + xOffset / zoomLevel, 0.5 + yOffset],
                end: [0.58 + xOffset / zoomLevel, 0.5 + yOffset],
                aspectRatio,
                attributes,
                rotate: absoluteAngle,
            });
        },
        redraw() {
            this.visualization.clear();
            this.generateSVG();
        },
        changePerspective(isPatientPerspective: boolean) {
            this.isPatientPerspective = isPatientPerspective;
            d3.select('body').selectAll('#eso-tooltip').remove();
            d3.select('body').selectAll('#exo-tooltip').remove();
            d3.select('body').selectAll('#hyper-tooltip').remove();
            d3.select('body').selectAll('#hypo-tooltip').remove();
            this.redraw();
        },
        invertPoints(points: ProcessedGazePoint[]): ProcessedGazePoint[] {
            const invertedPoints = [];
            for (const point of points) {
                let eyeX;
                let eyeY;
                if (point.eyeX && point.eyeY) {
                    eyeX = point.eyeX * -1.0;
                    eyeY = point.eyeY;
                }
                const gazePoint = {
                    startPosition: new Position2d(1 - point.startPosition.x, point.startPosition.y),
                    stopPosition: new Position2d(1 - point.stopPosition.x, point.stopPosition.y),
                    duration: point.duration,
                    delay: point.delay,
                    phoria: point.phoria,
                    zDistance: point.zDistance,
                    eyeX,
                    eyeY,
                };

                invertedPoints.push(gazePoint);
            }

            return invertedPoints;
        },
        playGazeAnimation() {
            const pathSelection = d3.select(this.svg).selectAll('#fixation-circle');

            pathSelection
                .attr('r', 0)
                .transition()
                .duration((d: any, i: number) => d.duration * 1000)
                .delay((d: any, i: number) => d.delay * 1000)
                .ease(d3.easeLinear)
                .attr('r', 0.0008)
                .end()
                .then(() => this.$emit('animationFinished'));
        },
        avgAngle(
            leftEye: ProcessedGazePoint[],
            rightEye: ProcessedGazePoint[],
        ): { avgAngle: number; absoluteAngle: number; headTiltDirection: string } {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            if (leftEye.length === 0) {
                return { avgAngle: 0, absoluteAngle: 0, headTiltDirection: 'No' };
            }

            let avgAngle = 0;
            let absoluteAngle = 0;
            for (let i = 0; i < leftEye.length; i++) {
                const leftPoint = leftEye[i];
                const rightPoint = rightEye[i];
                const x1 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(leftPoint.eyeX!, screenData.dpi) / screenData.width;
                const y1 =
                    (0.5175 + MathUtils.mmToPixels(leftPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                const x2 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(rightPoint.eyeX!, screenData.dpi) / screenData.width;
                const y2 =
                    (0.5175 + MathUtils.mmToPixels(rightPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                let angle = Math.atan2(y2 - y1, x2 - x1);
                angle = angle * (180.0 / Math.PI);
                avgAngle += angle;
            }

            avgAngle /= leftEye.length;

            let headTiltDirection = 'Right';
            if (avgAngle < 0) {
                headTiltDirection = 'Left';
            }

            absoluteAngle = avgAngle;
            avgAngle = Math.abs(avgAngle);
            absoluteAngle = (180.0 - absoluteAngle) * -1.0;

            if (!this.isPatientPerspective) {
                avgAngle = 180.0 - avgAngle;
                if (absoluteAngle < 0) {
                    absoluteAngle = (180.0 - absoluteAngle) * -1.0;
                } else {
                    absoluteAngle = 180.0 - absoluteAngle;
                }
            }

            avgAngle = Math.round(avgAngle * 10) / 10;

            return { avgAngle, absoluteAngle, headTiltDirection };
        },
    },
    watch: {
        playAnimation(shouldPlayAnimation: boolean) {
            if (shouldPlayAnimation) {
                this.playGazeAnimation();
            }
        },
        showGuidelines(val: boolean) {
            this.redraw();
        },
        showEyeDominance(val: boolean) {
            this.redraw();
        },
        showAvgHeadTilt(val: boolean) {
            this.redraw();
        },
    },
});
