import { CIRCULAR_SMOOTH_PURSUIT_METRIC_QUERY } from './tests/circularSmoothPursuit';
import { HORIZONTAL_SMOOTH_PURSUIT_METRIC_QUERY } from './tests/horizontalSmoothPursuit';
import { VERTICAL_SMOOTH_PURSUIT_METRIC_QUERY } from './tests/verticalSmoothPursuit';
import { HORIZONTAL_SACCADES_METRIC_QUERY } from './tests/horizontalSaccades';
import { VERTICAL_SACCADES_METRIC_QUERY } from './tests/verticalSaccades';
import { CALIBRATION_METRIC_QUERY } from './tests/calibration';
import { FIXATION_STABILITY_METRIC_QUERY } from './tests/fixationStability';
import { TestType } from '../../common/constants/reports.constants';
import { CHOICE_REACTION_TIME_METRIC_QUERY } from './tests/choiceReactionTime';
import { DISCRIMINATE_REACTION_TIME_METRIC_QUERY } from './tests/discriminateReactionTime';
import { STATIC_VISUAL_ACUITY_METRIC_QUERY } from './tests/staticVisualAcuity';
import { DYNAMIC_VISUAL_ACUITY_1_METRIC_QUERY } from './tests/dynamicVisualAcuity1';
import { DYNAMIC_VISUAL_ACUITY_2_METRIC_QUERY } from './tests/dynamicVisualAcuity2';
import { DYNAMIC_VISUAL_ACUITY_3_METRIC_QUERY } from './tests/dynamicVisualAcuity3';
import { CARDINAL_GAZE_POSITION_METRIC_QUERY } from './tests/cardinalGazePosition';
import { CONTRAST_SENSITIVITY_METRIC_QUERY } from './tests/contrastSensitivity';
import { HORIZONTAL_GAP_SACCADES_METRIC_QUERY } from './tests/horizontalGapSaccades';
import { BUBBLE_BLAST_METRIC_QUERY } from './tests/bubbleBlast';
import { COSMOS_COMBAT_METRIC_QUERY } from './tests/cosmosCombat';
import { MAZE_MASTER_METRIC_QUERY } from './tests/mazeMaster';
import { SPACE_STORM_METRIC_QUERY } from './tests/spaceStorm';
import { SIMPLE_REACTION_TIME_METRIC_QUERY } from './tests/simpleReactionTime';
import { NEAR_POINT_CONVERGENCE_METRIC_QUERY } from './tests/nearPointConvergence';
import { FOUR_DOT_FUSION_METRIC_QUERY } from './tests/fourDotFusion';

export class TestQuery {
    public static fromTestType(testType: TestType): TestQuery {
        switch (testType) {
            case 'circularSmoothPursuit':
                return TestQuery.body(CIRCULAR_SMOOTH_PURSUIT_METRIC_QUERY);
            case 'horizontalSmoothPursuit':
                return TestQuery.body(HORIZONTAL_SMOOTH_PURSUIT_METRIC_QUERY);
            case 'verticalSmoothPursuit':
                return TestQuery.body(VERTICAL_SMOOTH_PURSUIT_METRIC_QUERY);
            case 'fixationStability':
                return TestQuery.body(FIXATION_STABILITY_METRIC_QUERY);
            case 'calibration':
                return TestQuery.body(CALIBRATION_METRIC_QUERY);
            case 'horizontalSaccades':
                return TestQuery.body(HORIZONTAL_SACCADES_METRIC_QUERY);
            case 'verticalSaccades':
                return TestQuery.body(VERTICAL_SACCADES_METRIC_QUERY);
            case 'choiceReactionTime':
                return TestQuery.body(CHOICE_REACTION_TIME_METRIC_QUERY);
            case 'discriminateReactionTime':
                return TestQuery.body(DISCRIMINATE_REACTION_TIME_METRIC_QUERY);
            case 'simpleReactionTime':
                return TestQuery.body(SIMPLE_REACTION_TIME_METRIC_QUERY);
            case 'staticVisualAcuity':
                return TestQuery.body(STATIC_VISUAL_ACUITY_METRIC_QUERY);
            case 'dynamicVisualAcuity1':
                return TestQuery.body(DYNAMIC_VISUAL_ACUITY_1_METRIC_QUERY);
            case 'dynamicVisualAcuity2':
                return TestQuery.body(DYNAMIC_VISUAL_ACUITY_2_METRIC_QUERY);
            case 'dynamicVisualAcuity3':
                return TestQuery.body(DYNAMIC_VISUAL_ACUITY_3_METRIC_QUERY);
            case 'cardinalGazePosition':
                return TestQuery.body(CARDINAL_GAZE_POSITION_METRIC_QUERY);
            case 'contrastSensitivity':
                return TestQuery.body(CONTRAST_SENSITIVITY_METRIC_QUERY);
            case 'horizontalGapSaccades':
                return TestQuery.body(HORIZONTAL_GAP_SACCADES_METRIC_QUERY);
            case 'bubbleBlast':
                return TestQuery.body(BUBBLE_BLAST_METRIC_QUERY);
            case 'cosmosCombat':
                return TestQuery.body(COSMOS_COMBAT_METRIC_QUERY);
            case 'mazeMaster':
                return TestQuery.body(MAZE_MASTER_METRIC_QUERY);
            case 'spaceStorm':
                return TestQuery.body(SPACE_STORM_METRIC_QUERY);
            case 'nearPointConvergence':
                return TestQuery.body(NEAR_POINT_CONVERGENCE_METRIC_QUERY);
            case 'fourDotFusion':
                return TestQuery.body(FOUR_DOT_FUSION_METRIC_QUERY);
            default:
                return TestQuery.body('');
        }
    }

    public static body(queryBody: string): TestQuery {
        return new TestQuery(queryBody);
    }

    public readonly body: string;
    public returnAllTests: boolean;

    private constructor(queryBody: string) {
        this.body = queryBody;
    }

    public allTests(): TestQuery {
        this.returnAllTests = true;
        return this;
    }
}
