import * as d3 from 'd3';

import { VisualizationElement, D3Selection, Attributes, Styles, Properties, Classes } from '../elements';
import { ProcessedGazePoint } from '@/common/types/reports/visualData';
import { Position2d } from '@/common/types/reports/position';

export interface PathProperties {
    points: ProcessedGazePoint[];
    id?: string;
    translate?: Position2d;
    aspectRatio?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
}

export class Path extends VisualizationElement {
    public constructor(private readonly pathProperties: PathProperties) {
        super({
            attributes: pathProperties.attributes,
            styles: pathProperties.styles,
            properties: pathProperties.properties,
            classes: pathProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        const aspectRatio = this.pathProperties.aspectRatio ?? 1;
        const id = this.pathProperties.id ? this.pathProperties.id : 'path';
        const translation = this.pathProperties.translate ? this.pathProperties.translate : new Position2d(0, 0);

        if (this.pathProperties.attributes) {
            this.pathProperties.attributes.id = id;
        }

        const line = d3
            .line()
            .x((d: [number, number]) => d[0] + translation.x)
            .y((d: [number, number]) => (d[1] + translation.y) / aspectRatio);

        const selection = d3
            .select(svg)
            .selectAll(`#${id}`)
            .data(this.pathProperties.points)
            .enter()
            .append('path')
            .attr('d', (d: ProcessedGazePoint) => line([d.startPosition.coordinates, d.stopPosition.coordinates]));

        return selection;
    }
}
