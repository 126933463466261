























import Vue from 'vue';
import IconButton from '@/views/common/controls/IconButton.vue';

export default Vue.extend({
    props: {
        showShare: {
            type: Boolean,
            required: false,
            default: false,
        },
        showPrint: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        IconButton,
    },
    data() {
        return {
            showClipboardConfirmation: false,
        };
    },
    methods: {
        getShareableLink() {
            const origin = window.location.origin;
            const shareableLink = `${origin}/#${this.$route.fullPath}`;
            this.showClipboardConfirmation = true;
            this.writeToUserClipboard(shareableLink);
            setTimeout(() => {
                this.showClipboardConfirmation = false;
            }, 10000);
            this.$emit('share');
        },
        writeToUserClipboard(text: string) {
            navigator.clipboard.writeText(text);
        },
        handlePrint() {
            window.print();
            this.$emit('print');
        },
    },
});
