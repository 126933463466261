import Vue from 'vue';
import VueI18n from 'vue-i18n';
import vuetify from '@/plugins/vuetify';
import App from '@/views/App.vue';
import router from '@/router';
import store from '@/store/index';
import messages from '@/translations';

declare const portalUrl: string;

Vue.config.productionTip = false;

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');

window.addEventListener('message', (e) => {
    if (e.origin !== portalUrl) {
        return;
    }

    if (e.data === 'logout') {
        window.localStorage.removeItem('authenticatedUser');
    }

    const payload = JSON.parse(e.data);
    window.localStorage.setItem(payload.key, JSON.stringify(payload.data));
});
