














import Vue from 'vue';
import ProgressStar from '@/views/trainer/exercises/components/progress/ProgressStar.vue';

export default Vue.extend({
    components: { ProgressStar },
    props: {
        workoutRow: {
            type: Array,
            required: true,
        },
        displayCoach: {
            type: String,
            default: '',
        },
        coachPhrase: {
            type: String,
            default: '',
        },
    },
});
