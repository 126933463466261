import {
    ENGINEERING_ROLE,
    SUPER_ADMIN_ROLE,
    CLIENT_ADMIN_ROLE,
    PARTICIPANT_ROLE,
    CLIENT_USER_ROLE,
} from '../constants/userRoles.constants';
import store from '@/store/index';
import { i18n } from '@/main';
import * as CompanyModule from '@/common/types/auth/companyModules';
import * as Nav from '@/store/navigation/mutations';
import { LeftNavLink } from '@/common/types/general';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import * as Settings from '@/common/utils/settings';

export async function setEyeQTrainerLeftNav() {
    const user = store.getters.currentUser as AuthenticatedUser;
    const isEngineer = user.role === ENGINEERING_ROLE;
    const isSuperAdmin = user.role === SUPER_ADMIN_ROLE;
    const isClientAdmin = user.role === CLIENT_ADMIN_ROLE;
    const isClientUser = user.role === CLIENT_USER_ROLE;
    const isParticipant = user.role === PARTICIPANT_ROLE;

    store.commit(Nav.SHOW_LEFT_NAV);
    if (isParticipant) {
        const titleNavText = store.getters.currentUser.name;
        store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, titleNavText);
    } else if (isEngineer || isSuperAdmin || isClientAdmin || isClientUser) {
        const titleNavText = await store.getters.viewingCompany.name;
        store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, titleNavText);
    }

    if (isParticipant) {
        const links = [
            {
                text: i18n.t('routes.assessments'),
                to: { name: 'user', params: { userId: store.getters.currentUser.userId } },
                icon: 'assignment_ind',
                enabled: true,
            },
            {
                text: i18n.t('routes.eyeQTrainer'),
                to: { name: 'landing' },
                icon: 'preview',
                enabled: store.getters.hasModule(CompanyModule.EYEQ_TRAINER),
            },
        ] as LeftNavLink[];
        store.commit(Nav.SET_LEFT_NAV_LINKS, links);
    } else if (isEngineer || isSuperAdmin || isClientAdmin || isClientUser) {
        const links = [
            {
                text: i18n.t('routes.assessments'),
                to: { path: 'assessments' },
                icon: 'assignment_ind',
                enabled: true,
            },
            {
                text: i18n.t('routes.eyeQTrainer'),
                to: { path: 'eqtparticipants' },
                icon: 'preview',
                enabled: store.getters.hasModule(CompanyModule.EYEQ_TRAINER),
                sublinks: [
                    {
                        text: i18n.t('routes.eqtParticipants'),
                        to: { path: 'eqtparticipants' },
                        enabled: true,
                    },
                    {
                        text: i18n.t('routes.exercises'),
                        to: { name: 'exercises' },
                        enabled: true,
                    },
                ],
            },
            {
                text: i18n.t('routes.users'),
                to: { name: 'users' },
                icon: 'contacts',
                enabled: isClientAdmin || isSuperAdmin || isEngineer,
            },
            {
                text: i18n.t('routes.participants'),
                to: { name: 'participants' },
                icon: 'people',
                enabled: isClientAdmin,
            },
            {
                text: i18n.t('routes.protocols'),
                to: { name: 'protocols' },
                icon: 'fas fa-cubes',
                enabled: isClientAdmin || isSuperAdmin || isEngineer,
            },
            {
                text: i18n.t('routes.settingsBlocks'),
                to: { name: 'settings', params: { companyId: user.companyId } },
                icon: 'settings',
                enabled: Settings.areSettingsEnabled(),
                sublinks: [
                    {
                        text: i18n.t('routes.settings.EqtSettings'),
                        to: { path: 'settings', hash: 'EqtSettings' },
                        enabled: Settings.isSettingsEnabled(Settings.EQT_SETTINGS),
                    },
                    {
                        text: i18n.t('routes.settings.CompanyModules'),
                        to: { path: 'settings', hash: 'CompanyModules' },
                        enabled: Settings.isSettingsEnabled(Settings.COMPANY_MODULES),
                    },
                ],
            },
            {
                text: i18n.t('routes.sportsVisionTrainer'),
                to: { name: 'sportsVisionTrainer' },
                icon: 'fas fa-running',
                enabled: isClientAdmin || isSuperAdmin || isEngineer,
            },
            {
                text: i18n.t('routes.successCenter'),
                to: { name: 'successCenter' },
                icon: 'far fa-question-circle',
                enabled: isClientAdmin || isSuperAdmin || isEngineer,
            },
        ] as LeftNavLink[];
        store.commit(Nav.SET_LEFT_NAV_LINKS, links);
    }
}
