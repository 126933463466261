



























































import Vue from 'vue';
import TrainerApi from '@/api/trainer.api';
import MessageBar from '@/views/common/static/MessageBar.vue';
import ExistingProgram from '@/views/trainer/exercises/components/program/ExistingProgram.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';

interface WorkoutPlanInfo {
    name: string;
    definition: string;
    headers: [string, string, string];
}

export default Vue.extend({
    components: {
        MessageBar,
        ExistingProgram,
        GreenButton,
    },
    data() {
        return {
            workoutPlanInfo: undefined as WorkoutPlanInfo | undefined,
            page: '',
            msg: '',
        };
    },
    computed: {
        hasProgram(): boolean {
            return this.$store.getters.hasProgram;
        },
        hasCycle(): boolean {
            return this.$store.getters.hasCycle;
        },
    },
    async created() {
        if (!this.hasProgram) {
            this.page = 'basics';
            this.msg = 'basics';
        } else if (!this.hasCycle) {
            this.page = 'chooseWorkout';
            this.msg = 'chooseWorkout';
        }
    },
    async mounted() {
        const cycle = await TrainerApi.getCycle();
        this.workoutPlanInfo = {
            name: this.$t(`trainer.workoutPlan.${cycle.workoutPlan.name}.name`).toString(),
            definition: this.$t(`trainer.workoutPlan.${cycle.workoutPlan.name}.definition`).toString(),
            // @ts-ignore
            headers: this.$t(`trainer.workoutPlan.${cycle.workoutPlan.name}.descriptionHeaders`),
        };
    },
    methods: {
        goToLanding() {
            this.$router.push({ name: 'landing' });
        },
    },
});
