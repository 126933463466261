


























































import Vue from 'vue';
import Logger from '@/common/utils/logger';
import TrainerApi from '@/api/trainer.api';
import MessageBar from '@/views/common/static/MessageBar.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import ExistingProgram from '@/views/trainer/exercises/components/program/ExistingProgram.vue';
import { TranslateResult } from 'vue-i18n';
import { UPDATE_PROGRAM, UPDATE_CYCLE } from '@/store/trainer/actions';
import * as LeftNavUtils from '@/common/utils/leftNav';

export default Vue.extend({
    components: {
        GreenButton,
        MessageBar,
        ExistingProgram,
    },
    data() {
        return {
            msg: '' as TranslateResult,
            page: '',
            message: '',
        };
    },
    methods: {
        async onClick() {
            try {
                if (!this.hasProgram) {
                    await TrainerApi.createProgram();
                }
                this.$router.push('chooseWorkout');
            } catch (error) {
                Logger.error('Failed to create program', error);
            }
        },
    },
    computed: {
        hasProgram(): boolean {
            return this.$store.getters.hasProgram;
        },
        hasCycle(): boolean {
            return this.$store.getters.hasCycle;
        },
    },
    async mounted() {
        LeftNavUtils.setEyeQTrainerLeftNav();
        await this.$store.dispatch(UPDATE_PROGRAM);
        await this.$store.dispatch(UPDATE_CYCLE);

        if (this.hasProgram) {
            this.page = 'landing';
            this.message = 'landing';
            const programButton = document.getElementById('programButton') as HTMLButtonElement;
            programButton.disabled = true;
        }

        if (!this.hasCycle) {
            this.page = 'chooseWorkout';
            this.message = 'chooseWorkout';
        }

        this.msg = this.$t('trainer.exercises.message');
    },
});
