import { MIN_MAX_AVG_NORMS, STATS } from './common/queryFragments';

const CGP = `{
    up
    right
    down
    left
    superiorLeft
    superiorRight
    inferiorLeft
    inferiorRight
}`;

const CGP_METRICS_LRB = `{
    saccadicLatencyStats { min max average }
    vrt ${CGP}
    cgpRank ${CGP}
    disparity ${CGP}
    targetingDisplacement ${CGP}
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const CGP_NORMS = `{
    up ${STATS}
    right ${STATS}
    down ${STATS}
    left ${STATS}
    superiorLeft ${STATS}
    superiorRight ${STATS}
    inferiorLeft ${STATS}
    inferiorRight ${STATS}
}`;

const CGP_LRB_NORMS = `{
    saccadicLatencyStats ${MIN_MAX_AVG_NORMS}
    vrt ${CGP_NORMS}
    cgpRank ${CGP_NORMS}
    disparity ${CGP_NORMS}
    targetingDisplacement ${CGP_NORMS}
}
`;

export const CARDINAL_GAZE_POSITION_NORMS_QUERY = `
cardinalGazePosition {
    left ${CGP_LRB_NORMS}
    right ${CGP_LRB_NORMS}
    both ${CGP_LRB_NORMS}
}
`;

export const CARDINAL_GAZE_POSITION_METRIC_QUERY = `
cardinalGazePosition {
    metrics {
        eyeType
        left ${CGP_METRICS_LRB}
        right ${CGP_METRICS_LRB}
        both ${CGP_METRICS_LRB}
    }
}
`;
