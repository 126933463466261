




import Vue, { PropType } from 'vue';

import * as d3 from 'd3';
import * as Constants from '@/common/constants/visualizations.constants';
import * as FormatUtils from '@/common/utils/reports/format';
import * as MathUtils from '@/common/utils/math';
import { ProcessedVisualData, ProcessedGazePoint } from '@/common/types/reports/visualData';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { MetricData } from '@/common/types/reports/metricData';

const EYE_RADIUS = [0.05, 0.025];
const LEFT_GAZE_LINE_ID = 'leftGazeLine';
const RIGHT_GAZE_LINE_ID = 'rightGazeLine';

export interface VergenceConfig {
    testData: ProcessedVisualData;
    systemType: string;
    metrics: MetricData;
    radius: number;
}

export default Vue.extend({
    props: {
        playAnimation: Boolean,
        config: {
            type: Object as PropType<VergenceConfig>,
        },
        isPatientPerspective: Boolean,
    },
    data() {
        return {
            eyeTextXLoc: 0,
            eyeTextYLoc: 0,
            eyeText: '' as string,
            avgText: '' as string,
            avgTextXLoc: 0,
            avgTextYLoc: 0,
            avgPhoria: '' as string,
            visualization: new Visualization(),
        };
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
    mounted() {
        this.generateSVG();
    },
    watch: {
        playAnimation(val: boolean) {
            if (val) {
                this.playGazeAnimation();
            }
        },
        isPatientPerspective(val: boolean) {
            d3.select(this.svg).selectAll('*').remove();
            this.visualization.clear();
            this.generateSVG();
        },
    },
    methods: {
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const radius =
                MathUtils.degreesToPixels(this.config.radius, screenData.centimetersFromScreen, screenData.dpi) /
                screenData.width;

            const eyeOffset = 0.08;

            const leftEyeStartPosition = this.config.testData.left.points[0].startPosition.x;
            const rightEyeStartPosition = this.config.testData.right.points[0].startPosition.x;

            const defaultAttributes = {
                'stroke-width': 0.005,
                'stroke-opacity': 0.7,
            };

            const leftEyeXLoc = 0.5 + eyeOffset;
            const leftEyeYLoc = 0.3;

            const rightEyeXLoc = 0.5 - eyeOffset;
            const rightEyeYLoc = 0.3;

            const ASPECT_RATIO = screenData.width / screenData.height;
            const eyeLabelFontSize = 0.002;
            this.eyeTextXLoc = 0.4;
            this.eyeTextYLoc = 0.15;

            this.eyeText =
                this.config.testData.left.points[0].phoria.value +
                ' ' +
                this.config.testData.left.points[0].phoria.label;

            this.calculateAvgPhoria();

            this.visualization
                .text({
                    content: `${this.eyeText}`,
                    id: 'eyeText',
                    position: [this.eyeTextXLoc + 0.08, this.eyeTextYLoc - 0.05],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `.04px`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                        'font-weight': 'regular',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: `${this.avgText}`,
                    position: [this.avgTextXLoc + 0.18, this.avgTextYLoc + 0.2],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `.04px`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                        'font-weight': 'regular',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                })
                // Eyes
                .text({
                    content: 'Left Eye',
                    position: [this.isPatientPerspective ? 1.0 - leftEyeXLoc : leftEyeXLoc, leftEyeYLoc - 0.07],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${eyeLabelFontSize}em`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': Constants.LEFT_COLOR,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: 'Right Eye',
                    position: [this.isPatientPerspective ? 1.0 - rightEyeXLoc : rightEyeXLoc, rightEyeYLoc - 0.07],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${eyeLabelFontSize}em`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': Constants.RIGHT_COLOR,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                })
                .ellipse({
                    center: [leftEyeXLoc, leftEyeYLoc],
                    radiusX: EYE_RADIUS[0],
                    radiusY: EYE_RADIUS[1],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        fill: 'black',
                        stroke: 'black',
                    },
                })
                .ellipse({
                    center: [rightEyeXLoc, rightEyeYLoc],
                    radiusX: EYE_RADIUS[0],
                    radiusY: EYE_RADIUS[1],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        fill: 'black',
                        stroke: 'black',
                    },
                })
                // Gaze lines
                .line({
                    start: [this.isPatientPerspective ? 1.0 - leftEyeXLoc : leftEyeXLoc, leftEyeYLoc],
                    end: [this.isPatientPerspective ? 1.0 - leftEyeStartPosition : leftEyeStartPosition, 0.8],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        'id': LEFT_GAZE_LINE_ID,
                        'stroke': Constants.LEFT_COLOR,
                        'stroke-width': 0.005,
                    },
                })
                .line({
                    start: [this.isPatientPerspective ? 1.0 - rightEyeXLoc : rightEyeXLoc, rightEyeYLoc],
                    end: [this.isPatientPerspective ? 1.0 - rightEyeStartPosition : rightEyeStartPosition, 0.8],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        'id': RIGHT_GAZE_LINE_ID,
                        'stroke': Constants.RIGHT_COLOR,
                        'stroke-width': 0.005,
                    },
                })
                .line({
                    start: [0.5 - radius, 0.8],
                    end: [0.5 + radius, 0.8],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        'stroke': 'black',
                        'stroke-dasharray': `${0.0004}em`,
                    },
                })
                .drawNormalized(this.svg, ASPECT_RATIO);
        },
        playGazeAnimation() {
            this.drawVergenceGaze(this.config.testData.left.points, LEFT_GAZE_LINE_ID);
            this.drawVergenceGaze(this.config.testData.right.points, RIGHT_GAZE_LINE_ID);
            this.displayPhoriaPoints(this.config.testData.left.points);
        },
        drawVergenceGaze(data: ProcessedGazePoint[], lineId: string) {
            const line = d3.select(this.svg).select(`#${lineId}`);
            for (const point of data) {
                line.transition()
                    .delay(point.delay)
                    .duration(point.duration)
                    .attr('x2', this.isPatientPerspective ? 1.0 - point.startPosition.x : point.startPosition.x)
                    .end()
                    .then();
            }
            return data[data.length - 1].delay;
        },
        displayPhoriaPoints(data: ProcessedGazePoint[]) {
            const text = d3.select(this.svg).select(`#eyeText`);
            for (const point of data) {
                let innerText = '';

                if (point.phoria) {
                    innerText = point.phoria.value + ' ' + point.phoria.label;
                }

                text.transition()
                    .delay(point.delay)
                    .duration(point.duration)
                    .text(innerText)
                    .end()
                    .then(() => this.resetPhoriaEyeText());
            }
            return data[data.length - 1].delay;
        },
        calculateAvgPhoria() {
            let totalLeft = 0;

            for (const point of this.config.testData.left.points) {
                const pointValue = point.phoria.label === 'EXO' ? point.phoria.value * -1 : point.phoria.value;
                totalLeft += pointValue;
            }

            const avgLeft = totalLeft / this.config.testData.left.points.length;

            let totalRight = 0;

            for (const point of this.config.testData.right.points) {
                const pointValue = point.phoria.label === 'EXO' ? point.phoria.value * -1 : point.phoria.value;
                totalRight += pointValue;
            }

            const avgRight = totalRight / this.config.testData.right.points.length;

            const totalAvg = avgLeft + avgRight / 2;
            const avgLabel = totalAvg > 0 ? ' ESO' : ' EXO';

            this.avgPhoria = FormatUtils.formatValue(Math.abs(totalAvg), 2) + avgLabel;

            this.avgText = 'Average: ' + this.avgPhoria;

            this.avgTextXLoc = 0.3;
            this.avgTextYLoc = 0.675;
        },
        resetPhoriaEyeText() {
            this.eyeText =
                this.config.testData.left.points[0].phoria.value +
                ' ' +
                this.config.testData.left.points[0].phoria.label;
        },
    },
});
