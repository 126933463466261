import store from '@/store';
import * as Mutations from '@/store/datatables/mutations';
import { DataOptions } from 'vuetify';

export function parseSortOptions(
    sortBy: string[],
    sortDesc: string[],
    defaultSortBy: string,
    defaultSortOrder: string,
) {
    let actualSortBy;
    if (!sortBy || sortBy.length === 0) {
        actualSortBy = defaultSortBy;
    } else {
        actualSortBy = sortBy[0];
    }

    let actualSortOrder;
    if (!sortBy || sortBy.length === 0) {
        actualSortOrder = defaultSortOrder;
    } else {
        actualSortOrder = sortDesc[0] ? 'DESC' : 'ASC';
    }

    return {
        sortBy: actualSortBy,
        sortOrder: actualSortOrder,
    };
}

export function getDefaultOptions(defaultSortBy = '', defaultSortOrder = ''): DataOptions {
    return {
        page: 1,
        itemsPerPage: 10,
        sortBy: [defaultSortBy],
        sortDesc: [convertSortOrderToBoolean(defaultSortOrder)],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
}

export function convertSortOrderToBoolean(sortOrder: string) {
    return sortOrder === 'DESC' ? true : false;
}

export function setDataTableCache(dataTable: string, options?: DataOptions, search?: string, selected?: any) {
    const payload = {
        dataTable,
        options,
        search,
        selected,
    };
    store.commit(Mutations.SAVE_DATATABLE_CACHE, payload);
}

export function getDataTableOptionsCache(dataTable: string, options?: DataOptions, search?: string, selected?: any) {
    const cache = store.getters.dataTableCache(dataTable);
    if (cache) {
        return { options: cache.options, search: cache.search, selected: cache.selected };
    }
    return { options, search, selected };
}

export function clearDataTableCache(dataTable: string) {
    store.commit(Mutations.CLEAR_DATATABLE_CACHE, { dataTable });
}
