






import Vue from 'vue';

export default Vue.extend({
    props: {
        progress: {
            type: Number,
            required: true,
        },
        x: {
            type: Number,
            required: true,
        },
        y: {
            type: Number,
            required: true,
        },
        r: {
            type: Number,
            required: true,
        },
        fillColor: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            pathData: '',
        };
    },
    methods: {
        updateProgress() {
            const startAngle = Math.PI / 2;
            const stopAngle = startAngle - 2 * Math.PI * this.progress;

            const startX = this.x + this.r * Math.cos(startAngle);
            const startY = this.y - this.r * Math.sin(startAngle);
            const endX = this.x + this.r * Math.cos(stopAngle);
            const endY = this.y - this.r * Math.sin(stopAngle);

            const xAxisRotation = 0;
            const sweepFlag = 1;
            const largeArc = this.progress < 0.5 ? 0 : 1;

            this.pathData = `
            M ${startX} ${startY}
            A ${this.r} ${this.r}, ${xAxisRotation}, ${largeArc}, ${sweepFlag}, ${endX} ${endY}
            L ${this.x} ${this.y}
            Z`;
        },
    },
    watch: {
        x(value) {
            this.updateProgress();
        },
        y(value) {
            this.updateProgress();
        },
        r(value) {
            this.updateProgress();
        },
        progress(value) {
            this.updateProgress();
        },
    },
    mounted() {
        this.updateProgress();
    },
});
