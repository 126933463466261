







































import Vue from 'vue';

import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_COMPANY } from '@/store/company/actions';

import Logger from '@/common/utils/logger';
import { reportDataToCSV } from '@/common/utils/reports/reports';

import ReportToolbar from '@/views/common/controls/ReportToolbar.vue';
import IconButton from '@/views/common/controls/IconButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import ParticipantInfo from '@/views/common/components/ParticipantInfo.vue';
import StandardReport from '@/views/reports/StandardReport.vue';
import FDAStandardReport from '@/views/reports/FDAStandardReport.vue';
import PrinterFriendlyHeader from '@/views/common/headers/PrinterFriendlyHeader.vue';

import { ReportData, TestData } from '@/common/types/reports/reportData';
import { Participant } from '@/common/types/user/participant';

import { STANDARD_TEST_TYPES, FDA_STANDARD_TEST_TYPES, TestType } from '@/common/constants/reports.constants';
import { PARTICIPANT_ROLE } from '@/common/constants/userRoles.constants';

import ReportsApi from '@/api/reports.api';

export default Vue.extend({
    props: {
        participantId: {
            type: String,
            required: true,
        },
        assessmentId: {
            type: String,
            required: false,
        },
    },
    components: {
        IconButton,
        LoadingCircle,
        ParticipantInfo,
        StandardReport,
        FDAStandardReport,
        PrinterFriendlyHeader,
        ReportToolbar,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            loading: true,
            reportTabs: [] as any[],
            updateStandardNavLink: false,
            updateFDAStandardNavLink: false,
            currentTab: '' as string,
        };
    },
    computed: {
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
    },
    async mounted() {
        try {
            this.loading = true;

            this.reportData = await ReportsApi.getReportDataByAssessmentId(this.assessmentId, STANDARD_TEST_TYPES);

            await Promise.all([
                this.$store.dispatch(SET_VIEWING_PARTICIPANT, this.reportData.participant.id),
                this.$store.dispatch(SET_VIEWING_COMPANY, this.reportData.participant.companyId),
            ]);

            if (
                this.reportData?.allTests.list.filter((t: TestType | TestData) =>
                    FDA_STANDARD_TEST_TYPES.includes(t as TestType),
                ).length > 0
            ) {
                this.reportTabs.push({ id: 'fdaStandard', name: 'FDA Standard Report' });
            }

            if (
                this.reportData?.allTests.list.filter((t: TestType | TestData) =>
                    STANDARD_TEST_TYPES.includes(t as TestType),
                ).length > 0
            ) {
                this.reportTabs.push({ id: 'standard', name: 'Standard Report' });
            }

            if (this.reportTabs.length > 0) {
                this.currentTab = this.reportTabs[0].id;
            } else {
                this.$store.commit(SET_ERROR_BANNER, 'This report does not contain any test data.');
            }

            this.loading = false;
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        handleExport() {
            if (this.reportData) {
                const url = reportDataToCSV(this.reportData);
                const link = document.createElement('a');
                link.download = 'AssessmentExport.csv';
                link.href = url;
                link.click();
            }
        },
        updateLeftNavLinks(tab: string) {
            this.currentTab = tab;
            if (tab === 'fdaStandard') {
                this.updateFDAStandardNavLink = true;
                this.updateStandardNavLink = false;
            } else {
                this.updateStandardNavLink = true;
                this.updateFDAStandardNavLink = false;
            }
        },
    },
});
