import { EqtPrescriptionResponse } from '@/common/types/api/responses/trainerResponses';
import { EXERCISES } from '@/common/types/trainer/exerciseModels';
import AssessmentModel from './assessmentModel';

export default class PrescriptionModel {
    private readonly id: string;
    private readonly name: string;
    private readonly exerciseId: string;
    private readonly assessment: AssessmentModel | undefined;
    private readonly prescriptionGroupId: string | undefined;
    private readonly ordering: number;
    private isNext: boolean;
    private readonly dateCreated: string;

    public constructor(prescription: EqtPrescriptionResponse) {
        this.name = EXERCISES.get(prescription.eqtExerciseId)!.displayName;
        this.exerciseId = prescription.eqtExerciseId;
        this.id = prescription.id;
        this.assessment = prescription.assessment ? new AssessmentModel(prescription.assessment) : undefined;
        this.prescriptionGroupId = prescription.prescriptionGroupId ? prescription.prescriptionGroupId : undefined;
        this.isNext = false;
        this.ordering = prescription.ordering;
        this.dateCreated = prescription.createdDate;
    }

    public get Id(): string {
        return this.id;
    }

    public get Name(): string {
        return this.name;
    }

    public get ExerciseId(): string {
        return this.exerciseId;
    }

    public get Assessment(): AssessmentModel | undefined {
        return this.assessment;
    }

    public get Ordering(): number {
        return this.ordering;
    }

    public get IsNext(): boolean {
        return this.isNext;
    }

    public set IsNext(isNext) {
        this.isNext = isNext;
    }

    public get PrescriptionGroupId(): string | undefined {
        return this.prescriptionGroupId;
    }

    public get DateCreated(): string {
        return this.dateCreated;
    }
}
