




















import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            showHiddenAssessments: false,
        };
    },
    methods: {
        setShowHiddenAssessments(showHiddenAssessments: boolean) {
            this.showHiddenAssessments = showHiddenAssessments;
            this.$emit('showHiddenAssessments', this.showHiddenAssessments);
        },
    },
});
