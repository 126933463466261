

















import Vue from 'vue';

import ReportsApi from '@/api/reports.api';

import * as Nav from '@/store/navigation/mutations';

import Logger from '@/common/utils/logger';

import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import NormsSelector from '@/views/reports/components/NormsSelector.vue';
import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import Report from '@/views/reports/Report.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';
import AssessmentApi from '@/api/assessment.api';

import { ReportData } from '@/common/types/reports/reportData';
import { NormsSelection, ReportNorms, AgeBasedNormsRange } from '@/common/types/reports/norms';
import { ageBasedRangeFromDateOfBirth } from '@/common/utils/reports/norms';
import { reportCardsFromReportData } from '@/common/utils/reports/reports';
import * as ParticipantUtils from '@/common/utils/participant';

import { ReportCardName, STANDARD_TEST_TYPES, REPORT_NAVIGATION_ICONS } from '@/common/constants/reports.constants';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { Participant } from '@/common/types/user/participant';

const REPORT_CARD_DISPLAY_ORDER = [
    'FixationStability',
    'ChoiceReactionTime',
    'DiscriminateReactionTime',
    'SaccadicLatency',
    'ContrastSensitivity',
    'StaticVisualAcuity',
    'DynamicVisualAcuity2',
    'DynamicVisualAcuity3',
    'SimpleReactionTime',
    'CardinalGazePosition',
    'DynamicVisualAcuity1',
    'Calibration',
    'Antisaccades',
    'Prosaccades',
] as ReportCardName[];

const ANTISACCADE_TEST_NAME = 'antisaccades';
const PROSACCADE_TEST_NAME = 'prosaccades';

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: false,
        },
        updateNavLink: Boolean,
    },
    components: {
        Report,
        LoadingCircle,
        ReportFooter,
        ScreenDistanceDisplay,
        NormsSelector,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            norms: {} as ReportNorms,
            cards: [] as ReportCardName[],
            loading: true,
        };
    },
    computed: {
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
    },
    async mounted() {
        try {
            this.loading = true;

            this.reportData = await ReportsApi.getReportDataByAssessmentId(this.assessmentId, STANDARD_TEST_TYPES);
            const isAntisaccadeTest = await AssessmentApi.getIsAntisaccadeTest(this.assessmentId);
            const isProsaccadeTest = await AssessmentApi.getIsProsaccadeTest(this.assessmentId);

            if (isAntisaccadeTest) {
                const result = await AssessmentApi.getAntisaccadeTestData(this.assessmentId);
                const antisaccadeTestData = {
                    info: undefined,
                    metrics: result,
                    norms: undefined,
                    visualData: { gazeData: [] },
                    eyeTrackingData: {},
                };
                this.reportData.tests[ANTISACCADE_TEST_NAME] = antisaccadeTestData;
            }

            if (isProsaccadeTest) {
                const result = await AssessmentApi.getProsaccadeTestData(this.assessmentId);
                const prosaccadeTestData = {
                    info: undefined,
                    metrics: result,
                    norms: undefined,
                    visualData: { gazeData: [] },
                    eyeTrackingData: {},
                };
                this.reportData.tests[PROSACCADE_TEST_NAME] = prosaccadeTestData;
            }

            this.cards = reportCardsFromReportData(this.reportData);

            if (this.cards.length === 0) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }
            this.cards = this.cards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                return REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) > REPORT_CARD_DISPLAY_ORDER.indexOf(lhs) ? 1 : -1;
            });

            await this.updateNorms();

            this.generateLeftNavLinks();
            this.loading = false;
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        } finally {
            this.loading = false;
        }
    },
    watch: {
        updateNavLink(shouldUpdateNavLinks: boolean) {
            if (shouldUpdateNavLinks) {
                this.generateLeftNavLinks();
            }
        },
    },
    methods: {
        async updateNorms(selection?: NormsSelection) {
            if (!this.reportData) {
                return;
            }
            if (!selection) {
                const ageRange = this.ageRange();
                selection = {
                    ageBased: ageRange ? [ageRange] : [],
                    games: ageRange ? [ageRange] : [],
                };
            }

            const norms = await ReportsApi.getNormsDataForReport(this.reportData, selection, undefined);
            this.norms = norms;
        },
        async onNormsSelectionChanged(selection: NormsSelection) {
            this.updateNorms(selection);
        },
        ageRange(): AgeBasedNormsRange | undefined {
            const dob = ParticipantUtils.getDateOfBirth(this.participant);
            return dob ? ageBasedRangeFromDateOfBirth(dob) : undefined;
        },
        generateLeftNavLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, '❮ ' + this.$t('reports.reports.standardReport'));

            const links = this.cards.map((c) => ({
                text: this.$t('reports.cards.titles.' + c.toString()).toString(),
                to: { hash: c },
                icon: c in REPORT_NAVIGATION_ICONS ? REPORT_NAVIGATION_ICONS[c] : 'analytics',
                enabled: true,
            }));
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
    },
});
