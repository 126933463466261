





























import { AGE_BASED_NORMS_RANGES, MLB_NORMS_LABELS, NormsSelection } from '@/common/types/reports/norms';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    props: {
        initialSelection: {
            type: Object as PropType<NormsSelection>,
            default: {
                ageBased: [],
                mlb: [],
                games: [],
            },
        },
        showAgeBased: Boolean,
        showMlb: Boolean,
    },
    data() {
        return {
            selection: this.initialSelection,
            ageBasedCategories: AGE_BASED_NORMS_RANGES,
            mlbCategories: MLB_NORMS_LABELS,
        };
    },
    methods: {
        onSelectionChanged() {
            const selection = {
                ageBased: this.selection.ageBased,
                mlb: this.selection.mlb,
                games: this.selection.ageBased,
            };
            this.$emit('selectionChanged', selection);
        },
    },
});
