import { Route } from 'vue-router';

import {
    CLIENT_ADMIN_ROLE,
    SUPER_ADMIN_ROLE,
    PARTICIPANT_ROLE,
    CLIENT_USER_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

import DynamicVisionReport from '@/views/reports/DynamicVisionReport.vue';
import StandardReportTabs from '@/views/reports/StandardReportTabs.vue';
import SensorimotorScreenerReport from '@/views/reports/SensorimotorScreenerReport.vue';
import SensorimotorExamReport from '@/views/reports/SensorimotorExamReport.vue';
import SensorimotorInterpretationReport from '@/views/reports/SensorimotorInterpretationReport.vue';
import { DYNAMIC_VISION } from '@/common/types/auth/companyModules';

const routes = [
    {
        path: '/report',
        component: {
            // Inline declaration of a component that renders our <router-view>
            render: (c: any) => c('router-view'),
        },
        meta: {
            allowedRoles: [PARTICIPANT_ROLE, SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE, ENGINEERING_ROLE],
        },
        children: [
            {
                path: ':participantId/dynamicVision/:assessmentId',
                name: 'DynamicVisionReport',
                component: DynamicVisionReport,
                props: (route: Route) => ({
                    participantId: route.params.participantId,
                    assessmentId: route.params.assessmentId,
                    comparisonId: route.query.comparisonId,
                }),
                meta: {
                    allowedRoles: [
                        PARTICIPANT_ROLE,
                        SUPER_ADMIN_ROLE,
                        ENGINEERING_ROLE,
                        CLIENT_ADMIN_ROLE,
                        CLIENT_USER_ROLE,
                    ],
                    requiredModules: [DYNAMIC_VISION],
                },
            },
            {
                path: ':participantId/standard/:assessmentId',
                name: 'StandardReport',
                component: StandardReportTabs,
                props: (route: Route) => ({
                    participantId: route.params.participantId,
                    assessmentId: route.params.assessmentId,
                }),
                meta: {
                    allowedRoles: [
                        PARTICIPANT_ROLE,
                        SUPER_ADMIN_ROLE,
                        ENGINEERING_ROLE,
                        CLIENT_ADMIN_ROLE,
                        CLIENT_USER_ROLE,
                    ],
                },
            },
            {
                path: ':participantId/sensorimotorScreener/:assessmentId',
                name: 'SensorimotorScreenerReport',
                component: SensorimotorScreenerReport,
                props: (route: Route) => ({
                    participantId: route.params.participantId,
                    assessmentId: route.params.assessmentId,
                }),
                meta: {
                    allowedRoles: [
                        PARTICIPANT_ROLE,
                        SUPER_ADMIN_ROLE,
                        ENGINEERING_ROLE,
                        CLIENT_ADMIN_ROLE,
                        CLIENT_USER_ROLE,
                    ],
                },
            },
            {
                path: ':participantId/sensorimotorExam/:assessmentId',
                name: 'SensorimotorExamReport',
                component: SensorimotorExamReport,
                props: (route: Route) => ({
                    participantId: route.params.participantId,
                    assessmentId: route.params.assessmentId,
                }),
                meta: {
                    allowedRoles: [
                        PARTICIPANT_ROLE,
                        SUPER_ADMIN_ROLE,
                        ENGINEERING_ROLE,
                        CLIENT_ADMIN_ROLE,
                        CLIENT_USER_ROLE,
                    ],
                },
            },
            {
                path: ':participantId/sensorimotorInterpretation/:assessmentId',
                name: 'SensorimotorInterpretationReport',
                component: SensorimotorInterpretationReport,
                props: (route: Route) => ({
                    participantId: route.params.participantId,
                    assessmentId: route.params.assessmentId,
                }),
                meta: {
                    allowedRoles: [
                        PARTICIPANT_ROLE,
                        SUPER_ADMIN_ROLE,
                        ENGINEERING_ROLE,
                        CLIENT_ADMIN_ROLE,
                        CLIENT_USER_ROLE,
                    ],
                },
            },
        ],
    },
];

export default routes;
