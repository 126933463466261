


















































































































































































import Vue, { PropType } from 'vue';

import PrinterFriendlyHeader from '@/views/common/headers/PrinterFriendlyHeader.vue';
import ReportCard from '@/views/reports/cards/ReportCard.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { ReportData } from '@/common/types/reports/reportData';

import { ReportCardName } from '@/common/constants/reports.constants';

import { getTimeStampFormat, getDOBFormat } from '@/common/utils/date';

const DV_PRINT_ORDER = [
    'ScoreDynamicVisionPrintable',
    'CircularSmoothPursuitDynamicVision',
    'HorizontalSaccadesDynamicVision',
    'FixationStabilityDynamicVision',
    'HorizontalSmoothPursuitDynamicVision',
    'VerticalSaccadesDynamicVision',
    'ChoiceReactionTimeDynamicVision',
    'VerticalSmoothPursuitDynamicVision',
    'CalibrationDynamicVision',
    'DiscriminateReactionTimeDynamicVision',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: true,
        },
        comparisonId: {
            type: Array as PropType<string[]>,
            required: false,
        },
        reportDataCache: {
            type: Object as PropType<Record<string, ReportData>>,
            required: true,
        },
        reportVersion: {
            type: Number,
            required: true,
        },
    },
    components: {
        PrinterFriendlyHeader,
        ReportCard,
        ScreenDistanceDisplay,
    },
    methods: {
        formatAssessmentDate(date: string): string {
            return `${getTimeStampFormat(date)}`;
        },
        formatDateOfBirth(date: string): string {
            return `${getDOBFormat(date)}`;
        },
    },
    data() {
        return {
            DV_PRINT_ORDER,
        };
    },
    computed: {
        assessmentIds(): string[] {
            let assessmentIds = [this.assessmentId] as string[];
            if (this.comparisonId && !Array.isArray(this.comparisonId)) {
                assessmentIds = assessmentIds.concat([this.comparisonId]);
            } else if (this.comparisonId) {
                assessmentIds = assessmentIds.concat(this.comparisonId as string[]);
            }
            return assessmentIds;
        },
    },
});
