








import Vue from 'vue';

import * as Nav from '@/store/navigation/mutations';

import Assessments from '@/views/common/datatables/Assessments.vue';
import CompanyInfo from '@/views/company/components/CompanyInfo.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';

import { Company } from '@/common/types/company/company';
import { CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';
import * as CompanyModule from '@/common/types/auth/companyModules';
import { LeftNavLink } from '@/common/types/general';

export default Vue.extend({
    components: {
        Assessments,
        CompanyInfo,
        LoadingCircle,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true as boolean,
        };
    },
    computed: {
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        company(): Company {
            return this.$store.getters.viewingCompany;
        },
    },
    methods: {
        setNavigationLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);

            const titleNavText = this.$store.getters.currentUser.name;
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, titleNavText);

            const links = [
                {
                    text: this.$t('routes.assessments'),
                    to: { name: 'user', params: { userId: this.userId } },
                    icon: 'assignment_ind',
                    enabled: true,
                },
                {
                    text: this.$t('routes.eyeQTrainer'),
                    to: { name: 'landing' },
                    icon: 'preview',
                    enabled: this.$store.getters.hasModule(CompanyModule.EYEQ_TRAINER),
                },
                {
                    text: this.$t('routes.sportsVisionTrainer'),
                    to: { name: 'sportsVisionTrainer' },
                    icon: 'fas fa-running',
                    enabled: this.isClientAdmin || this.isSuperAdmin || this.isEngineer,
                },
                {
                    text: this.$t('routes.successCenter'),
                    to: { name: 'successCenter' },
                    icon: 'far fa-question-circle',
                    enabled: this.isClientAdmin || this.isSuperAdmin || this.isEngineer,
                },
            ] as LeftNavLink[];
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
    },
    async mounted() {
        this.loading = true;
        this.$store.commit(Nav.SET_LEFT_NAV_LINKS, []);
        this.setNavigationLinks();
        this.loading = false;
    },
});
