import { TestType } from '@/common/constants/reports.constants';
import { GraphQlQuery } from '../../common/types/api/requests/graphqlRequests';
import { NormsSelection, NormsSystemType } from '../../common/types/reports/norms';
import { BUBBLE_BLAST_NORMS_QUERY } from './tests/bubbleBlast';
import { CALIBRATION_NORMS_QUERY } from './tests/calibration';
import { CARDINAL_GAZE_POSITION_NORMS_QUERY } from './tests/cardinalGazePosition';
import { CHOICE_REACTION_TIME_NORMS_QUERY } from './tests/choiceReactionTime';
import { CIRCULAR_SMOOTH_PURSUIT_NORMS_QUERY } from './tests/circularSmoothPursuit';
import { CONTRAST_SENSITIVITY_NORMS_QUERY } from './tests/contrastSensitivity';
import { COSMOS_COMBAT_NORMS_QUERY } from './tests/cosmosCombat';
import { DISCRIMINATE_REACTION_TIME_NORMS_QUERY } from './tests/discriminateReactionTime';
import { DYNAMIC_VISUAL_ACUITY_1_NORMS_QUERY } from './tests/dynamicVisualAcuity1';
import { DYNAMIC_VISUAL_ACUITY_2_NORMS_QUERY } from './tests/dynamicVisualAcuity2';
import { DYNAMIC_VISUAL_ACUITY_3_NORMS_QUERY } from './tests/dynamicVisualAcuity3';
import { FIXATION_STABILITY_NORMS_QUERY } from './tests/fixationStability';
import { HORIZONTAL_GAP_SACCADES_NORMS_QUERY } from './tests/horizontalGapSaccades';
import { HORIZONTAL_SACCADES_NORMS_QUERY } from './tests/horizontalSaccades';
import { HORIZONTAL_SMOOTH_PURSUIT_NORMS_QUERY } from './tests/horizontalSmoothPursuit';
import { MAZE_MASTER_NORMS_QUERY } from './tests/mazeMaster';
import { SIMPLE_REACTION_TIME_NORMS_QUERY } from './tests/simpleReactionTime';
import { SPACE_STORM_NORMS_QUERY } from './tests/spaceStorm';
import { STATIC_VISUAL_ACUITY_NORMS_QUERY } from './tests/staticVisualAcuity';
import { VERTICAL_SACCADES_NORMS_QUERY } from './tests/verticalSaccades';
import { VERTICAL_SMOOTH_PURSUIT_NORMS_QUERY } from './tests/verticalSmoothPursuit';

export class NormsQuery implements GraphQlQuery {
    private readonly selection: NormsSelection;
    private readonly systemType: NormsSystemType;
    private readonly testTypes: Set<TestType>;

    public constructor(params: { selection: NormsSelection; systemType: NormsSystemType; testTypes: TestType[] }) {
        this.selection = params.selection;
        this.systemType = params.systemType;

        this.testTypes = new Set();
        params.testTypes.forEach((testType) => this.testTypes.add(testType));
    }

    public requestBody(): string {
        const systemType = this.systemType;
        const testQueryBodies = Array.from(this.testTypes).map((testType) => queryBodyByTestType(testType));

        const normsQueries = [];

        if (
            testQueryBodies &&
            testQueryBodies.length > 0 &&
            testQueryBodies.filter((t) => t && t.length > 0).length > 0
        ) {
            if (this.selection.ageBased && this.selection.ageBased.length > 0) {
                normsQueries.push(`
                    ageBased(labels: ${JSON.stringify(this.selection.ageBased)}) {
                        label
                        data {
                            ${systemType} {
                                ${testQueryBodies}
                            }
                        }
                    }
                `);
            }

            if (this.selection.mlb && this.selection.mlb.length > 0) {
                normsQueries.push(`
                    mlb(labels: ${JSON.stringify(this.selection.mlb)}) {
                        label
                        data {
                            ${systemType} {
                                ${testQueryBodies}
                            }
                        }
                    }
                `);
            }

            if (this.selection.games && this.selection.games.length > 0) {
                normsQueries.push(`
                    games(labels: ${JSON.stringify(this.selection.games)}) {
                        label
                        data {
                            ${systemType} {
                                ${testQueryBodies}
                            }
                        }
                    }
                `);
            }
        } else {
            // If there are no norms queries, generate a body that will return successfully
            normsQueries.push(`
                    ageBased(labels: ${JSON.stringify(this.selection.ageBased)}) {
                        label
                    }
                `);
        }

        const query = `query NormsData {
            norms {
                ${normsQueries}
            }
        }`;

        const body: any = {
            query,
        };

        return JSON.stringify(body);
    }
}

function queryBodyByTestType(testType: TestType): string {
    switch (testType) {
        case 'circularSmoothPursuit':
            return CIRCULAR_SMOOTH_PURSUIT_NORMS_QUERY;
        case 'horizontalSmoothPursuit':
            return HORIZONTAL_SMOOTH_PURSUIT_NORMS_QUERY;
        case 'verticalSmoothPursuit':
            return VERTICAL_SMOOTH_PURSUIT_NORMS_QUERY;
        case 'fixationStability':
            return FIXATION_STABILITY_NORMS_QUERY;
        case 'calibration':
            return CALIBRATION_NORMS_QUERY;
        case 'horizontalSaccades':
            return HORIZONTAL_SACCADES_NORMS_QUERY;
        case 'verticalSaccades':
            return VERTICAL_SACCADES_NORMS_QUERY;
        case 'choiceReactionTime':
            return CHOICE_REACTION_TIME_NORMS_QUERY;
        case 'discriminateReactionTime':
            return DISCRIMINATE_REACTION_TIME_NORMS_QUERY;
        case 'simpleReactionTime':
            return SIMPLE_REACTION_TIME_NORMS_QUERY;
        case 'staticVisualAcuity':
            return STATIC_VISUAL_ACUITY_NORMS_QUERY;
        case 'dynamicVisualAcuity1':
            return DYNAMIC_VISUAL_ACUITY_1_NORMS_QUERY;
        case 'dynamicVisualAcuity2':
            return DYNAMIC_VISUAL_ACUITY_2_NORMS_QUERY;
        case 'dynamicVisualAcuity3':
            return DYNAMIC_VISUAL_ACUITY_3_NORMS_QUERY;
        case 'cardinalGazePosition':
            return CARDINAL_GAZE_POSITION_NORMS_QUERY;
        case 'contrastSensitivity':
            return CONTRAST_SENSITIVITY_NORMS_QUERY;
        case 'horizontalGapSaccades':
            return HORIZONTAL_GAP_SACCADES_NORMS_QUERY;
        case 'bubbleBlast':
            return BUBBLE_BLAST_NORMS_QUERY;
        case 'cosmosCombat':
            return COSMOS_COMBAT_NORMS_QUERY;
        case 'mazeMaster':
            return MAZE_MASTER_NORMS_QUERY;
        case 'spaceStorm':
            return SPACE_STORM_NORMS_QUERY;
        default:
            return '';
    }
}
