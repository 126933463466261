import { STATS } from './common/queryFragments';

const VS_DATA = `{
    value
    topSide
    bottomSide
}
`;

const VS_METRICS_LRB = `{
    saccadeNum ${VS_DATA}
    fixationNum ${VS_DATA}
    onTarget ${VS_DATA}
    band2Over ${VS_DATA}
    band2Under ${VS_DATA}
    band3Over ${VS_DATA}
    band3Under ${VS_DATA}
    saccadicEfficiency ${VS_DATA}
    saccadicTargeting ${VS_DATA}
    missed
    speedAccuracyTradeoff
    saccadicRecovery
    saccadicVariation
    saccadicVelocity
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}`;
const VS_DATA_NORMS = `{
    value ${STATS}
    topSide ${STATS}
    bottomSide ${STATS}
}
`;

const VS_NORMS_LRB = `{
    saccadeNum ${VS_DATA_NORMS}
    fixationNum ${VS_DATA_NORMS}
    onTarget ${VS_DATA_NORMS}
    band2Over ${VS_DATA_NORMS}
    band2Under ${VS_DATA_NORMS}
    band3Over ${VS_DATA_NORMS}
    band3Under ${VS_DATA_NORMS}
    saccadicEfficiency ${VS_DATA_NORMS}
    saccadicTargeting ${VS_DATA_NORMS}
    missed ${STATS}
    speedAccuracyTradeoff ${STATS}
    saccadicRecovery ${STATS}
    saccadicVariation ${STATS}
    saccadicVelocity ${STATS}
}
`;

export const VERTICAL_SACCADES_NORMS_QUERY = `
verticalSaccades {
    left ${VS_NORMS_LRB}
    right ${VS_NORMS_LRB}
}
`;

export const VERTICAL_SACCADES_METRIC_QUERY = `
verticalSaccades {
    metrics {
        left ${VS_METRICS_LRB}
        right ${VS_METRICS_LRB}
    }
    visualData {
        gazeData {
            timeStamp
            left {
                x
                y
            }
            right {
                x
                y
            }
        }
    }
}
`;
