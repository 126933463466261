




























import Vue, { PropType } from 'vue';

import ReportCardError from '@/views/reports/cards/ReportCardError.vue';

// Standard Report cards
import Antisaccades from '@/views/reports/cards/standard/Antisaccades.vue';
import Prosaccades from '@/views/reports/cards/standard/Prosaccades.vue';
import Calibration from '@/views/reports/cards/standard/Calibration.vue';
import CircularSmoothPursuit from '@/views/reports/cards/standard/CircularSmoothPursuit.vue';
import HorizontalSmoothPursuit from '@/views/reports/cards/standard/HorizontalSmoothPursuit.vue';
import VerticalSmoothPursuit from '@/views/reports/cards/standard/VerticalSmoothPursuit.vue';
import HorizontalSaccades from '@/views/reports/cards/standard/HorizontalSaccades.vue';
import VerticalSaccades from '@/views/reports/cards/standard/VerticalSaccades.vue';
import ChoiceReactionTime from '@/views/reports/cards/standard/ChoiceReactionTime.vue';
import DiscriminateReactionTime from '@/views/reports/cards/standard/DiscriminateReactionTime.vue';
import SimpleReactionTime from '@/views/reports/cards/standard/SimpleReactionTime.vue';
import StaticVisualAcuity from '@/views/reports/cards/standard/StaticVisualAcuity.vue';
import DynamicVisualAcuity1 from '@/views/reports/cards/standard/DynamicVisualAcuity1.vue';
import DynamicVisualAcuity2 from '@/views/reports/cards/standard/DynamicVisualAcuity2.vue';
import DynamicVisualAcuity3 from '@/views/reports/cards/standard/DynamicVisualAcuity3.vue';
import CardinalGazePosition from '@/views/reports/cards/standard/CardinalGazePosition.vue';
import ContrastSensitivity from '@/views/reports/cards/standard/ContrastSensitivity.vue';
import FixationStability from '@/views/reports/cards/standard/FixationStability.vue';
import HorizontalGapSaccades from '@/views/reports/cards/standard/HorizontalGapSaccades.vue';
import BubbleBlast from '@/views/reports/cards/standard/BubbleBlast.vue';
import CosmosCombat from '@/views/reports/cards/standard/CosmosCombat.vue';
import MazeMaster from '@/views/reports/cards/standard/MazeMaster.vue';
import SpaceStorm from '@/views/reports/cards/standard/SpaceStorm.vue';
import SaccadicLatency from '@/views/reports/cards/standard/SaccadicLatency.vue';

// Dynamic Vision cards
import CalibrationDynamicVision from '@/views/reports/cards/dynamicVision/CalibrationDynamicVision.vue';
import CircularSmoothPursuitDynamicVision from '@/views/reports/cards/dynamicVision/CircularSmoothPursuitDynamicVision.vue';
import FixationStabilityDynamicVision from '@/views/reports/cards/dynamicVision/FixationStabilityDynamicVision.vue';
import HorizontalSaccadesDynamicVision from '@/views/reports/cards/dynamicVision/HorizontalSaccadesDynamicVision.vue';
import HorizontalSmoothPursuitDynamicVision from '@/views/reports/cards/dynamicVision/HorizontalSmoothPursuitDynamicVision.vue';
import VerticalSaccadesDynamicVision from '@/views/reports/cards/dynamicVision/VerticalSaccadesDynamicVision.vue';
import VerticalSmoothPursuitDynamicVision from '@/views/reports/cards/dynamicVision/VerticalSmoothPursuitDynamicVision.vue';
import SaccadicLatencyDynamicVision from '@/views/reports/cards/dynamicVision/SaccadicLatencyDynamicVision.vue';
import ScoreDynamicVision from '@/views/reports/cards/dynamicVision/ScoreDynamicVision.vue';
import ChoiceReactionTimeDynamicVision from '@/views/reports/cards/dynamicVision/ChoiceReactionTimeDynamicVision.vue';
import DiscriminateReactionTimeDynamicVision from '@/views/reports/cards/dynamicVision/DiscriminateReactionTimeDynamicVision.vue';
import EyeHandReactionTimeDynamicVision from '@/views/reports/cards/dynamicVision/EyeHandReactionTimeDynamicVision.vue';
import BrainReferenceDynamicVision from '@/views/reports/cards/dynamicVision/BrainReferenceDynamicVision.vue';
import NerveMuscleReferenceDynamicVision from '@/views/reports/cards/dynamicVision/NerveMuscleReferenceDynamicVision.vue';
import IndividualScoreDynamicVision from '@/views/reports/cards/dynamicVision/IndividualScoreDynamicVision.vue';
import OverallScoreDynamicVision from '@/views/reports/cards/dynamicVision/OverallScoreDynamicVision.vue';

// Sensorimotor Cards
import NinePointMotorFunction from '@/views/reports/cards/sensorimotor/NinePointMotorFunction.vue';
import NearPointConvergence from '@/views/reports/cards/sensorimotor/NearPointConvergence.vue';
import FourDotFusion from '@/views/reports/cards/sensorimotor/FourDotFusion.vue';
import SensorimotorScreenerResult from '@/views/reports/cards/sensorimotor/SensorimotorScreenerResult.vue';
import SymptomSurvey from '@/views/reports/cards/sensorimotor/SymptomSurvey.vue';

// Printable Cards
import ScoreDynamicVisionPrintable from '@/views/reports/cards/printable/ScoreDynamicVisionPrintable.vue';
import BrainReferenceDynamicVisionPrintable from '@/views/reports/cards/printable/BrainReferenceDynamicVisionPrintable.vue';
import NerveMuscleReferenceDynamicVisionPrintable from '@/views/reports/cards/printable/NerveMuscleReferenceDynamicVisionPrintable.vue';

// General Cards
import ConditionsRecommendations from '@/views/reports/cards/general/ConditionsRecommendations.vue';
import SurveyCard from '@/views/reports/cards/general/SurveyCard.vue';

import { ReportData } from '@/common/types/reports/reportData';
import { ReportCardName } from '@/common/constants/reports.constants';
import { ReportNorms } from '@/common/types/reports/norms';
import { ScoreConfig } from '@/views/reports/visualizations/scores/IndividualScoreVisualization.vue';

// Any time you add a new card that can be used in reports, add it to this component.
export default Vue.extend({
    props: {
        component: String as PropType<ReportCardName>,
        reportData: Object as PropType<ReportData>,
        norms: Object as PropType<ReportNorms>,
        loadMetricTable: {
            type: Boolean,
            required: false,
            default: true,
        },
        individualScoreType: {
            type: String,
            required: false,
            default: 'Pursuits',
        },
        config: {
            type: Object as PropType<ScoreConfig>,
            required: false,
        },
        showInterpretationText: {
            type: Boolean,
            required: false,
            default: false,
        },
        forPrint: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasSensorimotorExam: {
            type: Boolean,
            required: false,
            default: false,
        },
        reportType: {
            required: false,
            type: String,
        },
        reportVersion: {
            required: false,
            type: Number,
        },
    },
    components: {
        ReportCardError,

        // Standard Report cards
        Antisaccades,
        Prosaccades,
        Calibration,
        CircularSmoothPursuit,
        HorizontalSmoothPursuit,
        VerticalSmoothPursuit,
        HorizontalSaccades,
        VerticalSaccades,
        ChoiceReactionTime,
        DiscriminateReactionTime,
        SimpleReactionTime,
        StaticVisualAcuity,
        DynamicVisualAcuity1,
        DynamicVisualAcuity2,
        DynamicVisualAcuity3,
        CardinalGazePosition,
        ContrastSensitivity,
        FixationStability,
        HorizontalGapSaccades,
        BubbleBlast,
        CosmosCombat,
        MazeMaster,
        SpaceStorm,
        SaccadicLatency,

        // Dynamic Vision cards
        CalibrationDynamicVision,
        CircularSmoothPursuitDynamicVision,
        FixationStabilityDynamicVision,
        HorizontalSaccadesDynamicVision,
        HorizontalSmoothPursuitDynamicVision,
        VerticalSaccadesDynamicVision,
        VerticalSmoothPursuitDynamicVision,
        SaccadicLatencyDynamicVision,
        ScoreDynamicVision,
        ChoiceReactionTimeDynamicVision,
        DiscriminateReactionTimeDynamicVision,
        EyeHandReactionTimeDynamicVision,
        BrainReferenceDynamicVision,
        NerveMuscleReferenceDynamicVision,
        IndividualScoreDynamicVision,
        OverallScoreDynamicVision,

        // Sensorimotor
        NinePointMotorFunction,
        NearPointConvergence,
        FourDotFusion,
        SensorimotorScreenerResult,
        SymptomSurvey,

        // Printable cards
        ScoreDynamicVisionPrintable,
        BrainReferenceDynamicVisionPrintable,
        NerveMuscleReferenceDynamicVisionPrintable,

        // General cards
        ConditionsRecommendations,
        SurveyCard,
    },
    data() {
        return {
            error: undefined as Error | undefined,
        };
    },
    errorCaptured(error) {
        this.error = error;
    },
    watch: {
        reportData() {
            this.error = undefined;
        },
    },
    methods: {
        setError(error: Error) {
            if (error) {
                this.error = error;
            } else {
                this.error = new Error(this.$t('reports.cards.error').toString());
            }
        },
    },
});
