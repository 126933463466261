
































































import Vue from 'vue';
import AuthApi from '@/api/auth.api';

import { PasswordRequirement } from '@/common/types/auth/auth';
import { ButtonState } from '@/common/types/general';

import GreenButton from '@/views/common/controls/GreenButton.vue';

import ErrorBanner from '@/views/common/static/ErrorBanner.vue';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { DELETE_AUTHENTICATED_USER } from '@/store/auth/mutations';

export default Vue.extend({
    components: {
        GreenButton,
        ErrorBanner,
    },
    data() {
        return {
            password: '',
            rePassword: '',
            passwordPolicy: [] as PasswordRequirement[],
            resetSuccessful: false,
            errorMessage: false,
        };
    },
    methods: {
        async getPasswordPolicy() {
            try {
                this.passwordPolicy = await AuthApi.getPasswordPolicyByToken(this.token);
            } catch (error) {
                this.errorMessage = true;
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async handleSubmit(token: string, password: string) {
            if (this.doesPasswordMeetRequirements && this.doPasswordsMatch) {
                try {
                    await AuthApi.resetPassword(token, password);
                    this.resetSuccessful = true;
                } catch (error) {
                    this.$store.commit(SET_ERROR_BANNER, error.message);
                }
            }
        },
        handleBackToLogin() {
            this.$store.commit(DELETE_AUTHENTICATED_USER);
            this.$router.push({ name: 'login' });
        },
        meetsPasswordRequirement(requirementRegexpStr: string): boolean {
            const regex = new RegExp(requirementRegexpStr);
            return regex.test(this.password);
        },
    },
    computed: {
        token(): string {
            return this.$route.params.token;
        },
        submitButtonState(): ButtonState {
            return this.doesPasswordMeetRequirements && this.doPasswordsMatch ? 'active' : 'inactive';
        },
        doesPasswordMeetRequirements(): boolean {
            const results = this.passwordPolicy.map((requirement: PasswordRequirement) =>
                new RegExp(requirement.regexPattern).test(this.password),
            );
            return results.filter((r) => !r).length === 0;
        },
        shouldShowMatchMessage(): boolean {
            return this.rePasswordNotEmpty && !this.doPasswordsMatch;
        },
        doPasswordsMatch(): boolean {
            return this.password === this.rePassword;
        },
        rePasswordNotEmpty(): boolean {
            return this.rePassword !== '';
        },
    },
    mounted() {
        this.getPasswordPolicy();
    },
});
