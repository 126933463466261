


























































import Vue from 'vue';

import ReportsApi from '@/api/reports.api';

import Logger from '@/common/utils/logger';

import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import Report from '@/views/reports/Report.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { ReportData } from '@/common/types/reports/reportData';
import { reportCardsFromReportData } from '@/common/utils/reports/reports';
import PrinterFriendlySensorimotorHeader from '@/views/common/headers/PrinterFriendlySensorimotorHeader.vue';
import * as ParticipantUtils from '@/common/utils/participant';

import {
    ReportCardName,
    SENSORIMOTOR_EXAM_TEST_TYPES,
    SENSORIMOTOR_SCREENER_TEST_TYPES,
} from '@/common/constants/reports.constants';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';
import { Participant } from '@/common/types/user/participant';
import { NormsSelection, ReportNorms, AgeBasedNormsRange } from '@/common/types/reports/norms';
import { ageBasedRangeFromDateOfBirth } from '@/common/utils/reports/norms';
import SensorimotorApi from '@/api/sensorimotor.api';
import { ScreenerResult } from '@/common/types/sensorimotor/screener';

const CARDS_TO_ALWAYS_SHOW = [
    'Calibration',
    'CardinalGazePosition',
    'FourDotFusion',
    'ConditionsRecommendations',
] as ReportCardName[];

const REPORT_CARD_DISPLAY_ORDER = [
    'Calibration',
    'CardinalGazePosition',
    'FourDotFusion',
    'CircularSmoothPursuit',
    'HorizontalSmoothPursuit',
    'VerticalSmoothPursuit',
    'HorizontalSaccades',
    'VerticalSaccades',
    'FixationStability',
    'HorizontalGapSaccades',
    'SimpleReactionTime',
    'ConditionsRecommendations',
] as ReportCardName[];

const SCREENER_REPORT_CARD_DISPLAY_ORDER = [
    'SensorimotorScreenerResult',
    'NinePointMotorFunction',
    'NearPointConvergence',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: false,
        },
    },
    components: {
        Report,
        LoadingCircle,
        ReportFooter,
        ScreenDistanceDisplay,
        PrinterFriendlySensorimotorHeader,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            norms: {} as ReportNorms,
            cards: [] as ReportCardName[],
            loading: true,
            screenerReportData: undefined as ReportData | undefined,
            screenerCards: [] as ReportCardName[],
        };
    },
    computed: {
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
        reportTitle(): string {
            return 'Complete Sensorimotor Report';
        },
    },
    async mounted() {
        try {
            this.loading = true;

            this.reportData = await ReportsApi.getReportDataByAssessmentId(
                this.assessmentId,
                SENSORIMOTOR_EXAM_TEST_TYPES,
            );
            await this.$store.dispatch(SET_VIEWING_PARTICIPANT, this.reportData.participant.id);

            const latestScreenerResult = (await SensorimotorApi.getLatestScreenerResultByParticipant(
                this.reportData.participant.id,
            )) as ScreenerResult;

            if (latestScreenerResult.assessmentId) {
                this.screenerReportData = await ReportsApi.getReportDataByAssessmentId(
                    latestScreenerResult.assessmentId,
                    SENSORIMOTOR_SCREENER_TEST_TYPES,
                );

                this.screenerCards = reportCardsFromReportData(this.screenerReportData);

                this.screenerCards = this.screenerCards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                    return SCREENER_REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) >
                        SCREENER_REPORT_CARD_DISPLAY_ORDER.indexOf(lhs)
                        ? 1
                        : -1;
                });
            }

            this.cards = reportCardsFromReportData(this.reportData);

            if (this.cards.length === 0) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }

            CARDS_TO_ALWAYS_SHOW.map((card: ReportCardName) => {
                if (!this.cards.includes(card)) {
                    this.cards.push(card);
                }
            });
            this.cards = this.cards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                return REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) > REPORT_CARD_DISPLAY_ORDER.indexOf(lhs) ? 1 : -1;
            });

            await this.updateNorms();

            this.loading = false;
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        async updateNorms(selection?: NormsSelection) {
            if (!this.reportData) {
                return;
            }
            if (!selection) {
                const ageRange = this.ageRange();
                selection = {
                    ageBased: ageRange ? [ageRange] : [],
                    games: ageRange ? [ageRange] : [],
                };
            }

            const norms = await ReportsApi.getNormsDataForReport(this.reportData, selection, undefined);
            this.norms = norms;
        },
        async onNormsSelectionChanged(selection: NormsSelection) {
            this.updateNorms(selection);
        },
        ageRange(): AgeBasedNormsRange | undefined {
            const dob = ParticipantUtils.getDateOfBirth(this.participant);
            return dob ? ageBasedRangeFromDateOfBirth(dob) : undefined;
        },
    },
});
