

























import Vue from 'vue';
import IconButton from '@/views/common/controls/IconButton.vue';

export default Vue.extend({
    components: {
        IconButton,
    },
    data() {
        return {
            showCalibrationLabels: false,
        };
    },
    methods: {
        setShowCalibrationLabels(showCalibrationLabels: boolean) {
            this.showCalibrationLabels = showCalibrationLabels;
            this.$emit('showCalibrationLabels', this.showCalibrationLabels);
        },
    },
});
