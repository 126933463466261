




import Vue from 'vue';

export default Vue.extend({
    props: {
        size: {
            type: Number,
            required: false,
            default: 40,
        },
    },
    computed: {
        width(): number {
            return this.size / 10;
        },
    },
});
