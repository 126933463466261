


























































import Vue from 'vue';

import ReportsApi from '@/api/reports.api';

import * as Nav from '@/store/navigation/mutations';

import Logger from '@/common/utils/logger';

import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import NormsSelector from '@/views/reports/components/NormsSelector.vue';
import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import Report from '@/views/reports/Report.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { ReportData } from '@/common/types/reports/reportData';
import { NormsSelection, ReportNorms, AgeBasedNormsRange } from '@/common/types/reports/norms';
import { ageBasedRangeFromDateOfBirth } from '@/common/utils/reports/norms';
import { reportCardsFromReportData } from '@/common/utils/reports/reports';
import * as ParticipantUtils from '@/common/utils/participant';
import TextLink from '@/views/common/controls/TextLink.vue';

import {
    ReportCardName,
    SENSORIMOTOR_EXAM_TEST_TYPES,
    SENSORIMOTOR_SCREENER_TEST_TYPES,
    REPORT_NAVIGATION_ICONS,
} from '@/common/constants/reports.constants';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';
import { Participant } from '@/common/types/user/participant';
import ParticipantInfo from '@/views/common/components/ParticipantInfo.vue';
import PrintableSensorimotorExamReport from '@/views/reports/PrintableSensorimotorExamReport.vue';
import ConditionsRecommendationsPanel from '@/views/reports/ConditionsRecommendationsPanel.vue';
import ReportToolbar from '@/views/common/controls/ReportToolbar.vue';
import IconButton from '@/views/common/controls/IconButton.vue';
import FixedToolbar from '@/views/common/controls/FixedToolbar.vue';
import SensorimotorApi from '@/api/sensorimotor.api';
import { ScreenerResult } from '@/common/types/sensorimotor/screener';
import { PARTICIPANT_ROLE } from '@/common/constants/userRoles.constants';
// import SurveyApi from '@/api/survey.api';

const CARDS_TO_ALWAYS_SHOW = ['Calibration', 'FourDotFusion', 'ConditionsRecommendations'] as ReportCardName[];

const REPORT_CARD_DISPLAY_ORDER = [
    'Calibration',
    'NinePointMotorFunction',
    'FixationStability',
    'HorizontalSmoothPursuit',
    'FourDotFusion',
    'CardinalGazePosition',
    'CircularSmoothPursuit',
    'VerticalSmoothPursuit',
    'HorizontalSaccades',
    'VerticalSaccades',
    'ConditionsRecommendations',
    'SurveyCard',
] as ReportCardName[];

const SCREENER_REPORT_CARD_DISPLAY_ORDER = [
    'SensorimotorScreenerResult',
    'NinePointMotorFunction',
    'NearPointConvergence',
    'SymptomSurvey',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: false,
        },
        updateNavLink: Boolean,
    },
    components: {
        Report,
        LoadingCircle,
        ReportFooter,
        ScreenDistanceDisplay,
        NormsSelector,
        ParticipantInfo,
        IconButton,
        TextLink,
        PrintableSensorimotorExamReport,
        ConditionsRecommendationsPanel,
        FixedToolbar,
        ReportToolbar,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            screenerReportData: undefined as ReportData | undefined,
            norms: {} as ReportNorms,
            cards: [] as ReportCardName[],
            screenerCards: [] as ReportCardName[],
            loading: true,
            showConditionsRecommendationsPanel: true,
            buttonHover: false,
        };
    },
    computed: {
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
    },
    async mounted() {
        try {
            this.loading = true;

            this.reportData = await ReportsApi.getReportDataByAssessmentId(
                this.assessmentId,
                SENSORIMOTOR_EXAM_TEST_TYPES,
            );

            await this.$store.dispatch(SET_VIEWING_PARTICIPANT, this.reportData.participant.id);

            const latestScreenerResult = (await SensorimotorApi.getLatestScreenerResultByParticipant(
                this.reportData.participant.id,
            )) as ScreenerResult;

            if (latestScreenerResult.assessmentId) {
                this.screenerReportData = await ReportsApi.getReportDataByAssessmentId(
                    latestScreenerResult.assessmentId,
                    SENSORIMOTOR_SCREENER_TEST_TYPES,
                );

                this.screenerCards = reportCardsFromReportData(this.screenerReportData);

                this.screenerCards = this.screenerCards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                    return SCREENER_REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) >
                        SCREENER_REPORT_CARD_DISPLAY_ORDER.indexOf(lhs)
                        ? 1
                        : -1;
                });
            }

            this.cards = reportCardsFromReportData(this.reportData);
            if (this.cards.length === 0) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }
            CARDS_TO_ALWAYS_SHOW.map((card: ReportCardName) => {
                if (!this.cards.includes(card)) {
                    this.cards.push(card);
                }
            });
            /*
            const survey = await SurveyApi.getSurvey(this.participant.id);

            if (survey.responses) {
                this.cards.push('SurveyCard');
            }
            */

            this.cards = this.cards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                return REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) > REPORT_CARD_DISPLAY_ORDER.indexOf(lhs) ? 1 : -1;
            });

            await this.updateNorms();

            this.generateLeftNavLinks();
            this.loading = false;
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        } finally {
            this.loading = false;
        }
    },
    watch: {
        updateNavLink(shouldUpdateNavLinks: boolean) {
            if (shouldUpdateNavLinks) {
                this.generateLeftNavLinks();
            }
        },
    },
    methods: {
        async updateNorms(selection?: NormsSelection) {
            if (!this.reportData) {
                return;
            }
            if (!selection) {
                const ageRange = this.ageRange();
                selection = {
                    ageBased: ageRange ? [ageRange] : [],
                    games: ageRange ? [ageRange] : [],
                };
            }

            const norms = await ReportsApi.getNormsDataForReport(this.reportData, selection, undefined);
            this.norms = norms;
        },
        async onNormsSelectionChanged(selection: NormsSelection) {
            this.updateNorms(selection);
        },
        ageRange(): AgeBasedNormsRange | undefined {
            const dob = ParticipantUtils.getDateOfBirth(this.participant);
            return dob ? ageBasedRangeFromDateOfBirth(dob) : undefined;
        },
        generateLeftNavLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);
            this.$store.commit(
                Nav.SET_LEFT_NAV_TITLE_TEXT,
                '❮ ' + this.$t('reports.reports.sensorimotorInterpretationReport'),
            );

            let links = this.screenerCards.map((c) => ({
                text: this.$t('reports.cards.titles.' + c.toString()).toString(),
                to: { hash: c },
                icon: c in REPORT_NAVIGATION_ICONS ? REPORT_NAVIGATION_ICONS[c] : 'analytics',
                enabled: true,
            }));

            const examLinks = this.cards.map((c) => ({
                text: this.$t('reports.cards.titles.' + c.toString()).toString(),
                to: { hash: c },
                icon: c in REPORT_NAVIGATION_ICONS ? REPORT_NAVIGATION_ICONS[c] : 'analytics',
                enabled: true,
            }));

            links = links.concat(examLinks);
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
        setShowConditionsRecommendationsPanel(value: boolean) {
            this.showConditionsRecommendationsPanel = value;
        },
    },
});
