




import Vue from 'vue';
export default Vue.extend({
    props: {
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            inputValue: '' as string,
        };
    },
    watch: {
        value() {
            this.inputValue = this.value;
        },
        inputValue() {
            this.$emit('input', this.inputValue);
        },
    },
});
