// Vuetify configuration

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { disable: true },
    icons: {
        iconfont: 'md',
    },
});
