import Vue from 'vue';
import Router, { Route, NavigationGuardNext } from 'vue-router';
import store from '@/store/index';
import { REFRESH_AUTHENTICATED_USER } from '@/store/auth/actions';
import { DELETE_AUTHENTICATED_USER } from '@/store/auth/mutations';

import AuthRoutes from '@/router/auth';
import CompanyRoutes from '@/router/company';
import ReportRoutes from '@/router/reports';
import UserRoutes from '@/router/user';
import PortalRoutes from '@/router/portal';
import TrainerRoutes from '@/router/trainer';
import SuperAdminRoutes from '@/router/superAdmin';

import {
    PARTICIPANT_ROLE,
    CLIENT_ADMIN_ROLE,
    CLIENT_USER_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

import HomePage from '@/views/auth/HomePage.vue';
import LoggedIn from '@/views/auth/LoggedIn.vue';
import PageError from '@/views/errors/PageError.vue';

import ReportsApi from '@/api/reports.api';

import { CLEAR_ERROR_BANNER } from '@/store/general/mutations';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import CompanyModule from '@/common/types/auth/companyModules';
import { Position2d } from '@/common/types/reports/position';
import BAA from '@/views/static/BAA.vue';

Vue.use(Router);

declare const successCenterUrl: string;

const router = new Router({
    routes: [
        {
            path: '/',
            component: LoggedIn,
            meta: {
                allowedRoles: [
                    PARTICIPANT_ROLE,
                    CLIENT_USER_ROLE,
                    CLIENT_ADMIN_ROLE,
                    SUPER_ADMIN_ROLE,
                    ENGINEERING_ROLE,
                ],
            },
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: HomePage,
                },
                ...CompanyRoutes,
                ...SuperAdminRoutes,
                ...UserRoutes,
                ...ReportRoutes,
                ...PortalRoutes,
                ...TrainerRoutes,
                {
                    path: '/baa',
                    name: 'baa',
                    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
                        const user = store.getters.currentUser;
                        if (user) {
                            router.replace({ path: `/company/${user.companyId}/baa` });
                        } else {
                            const params = {
                                name: 'login',
                                params: {
                                    redirect: to.path,
                                },
                            };
                            next(params);
                        }
                    },
                },
            ],
        },
        ...AuthRoutes,
        {
            path: '/successCenter',
            name: 'successCenter',
            beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
                window.location.href = successCenterUrl;
            },
        },
        {
            name: 'pageNotFound',
            path: '/pageNotFound',
            alias: '*',
            component: PageError,
            props: {
                errorType: 'PageNotFound',
            },
        },
        {
            name: 'missingSubscription',
            path: '/missingSubscription',
            component: PageError,
            props: {
                errorType: 'MissingSubscription',
            },
        },
        {
            name: 'roleNotAllowed',
            path: '/roleNotAllowed',
            component: PageError,
            props: {
                errorType: 'RoleNotAllowed',
            },
        },
        {
            name: 'baaApp',
            path: '/company/:companyId/baa/:baaKey',
            component: BAA,
            props: (route: Route) => ({
                companyId: route.params.companyId,
                baaKey: route.params.baaKey,
            }),
        },
    ],
    scrollBehavior(to: Route, from: Route, savedPosition: any) {
        if (to.hash) {
            // If there's a #anchor in the URL scroll to there
            return {
                selector: to.hash,
                offset: new Position2d(0, 100),
            };
        } else if (savedPosition) {
            // If the back button was used go to the last position
            return savedPosition;
        } else {
            // Otherwise start at the top of the page
            return new Position2d(0, 0);
        }
    },
});

// Clear error banner message whenever user navigates away
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    store.commit(CLEAR_ERROR_BANNER);
    next();
});

// Validate authentication for authenticated routes
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    store.dispatch(REFRESH_AUTHENTICATED_USER);
    const isUserLoggedIn = store.getters.currentUser !== undefined;
    const allowedRoles = getAllowedRoles(to);
    const requiresAuth = allowedRoles.length > 0;
    const roleAllowed = !requiresAuth || isRoleAllowed(allowedRoles);

    if (!requiresAuth) {
        // If page does not require a role, then proceed
        return next();
    } else if (isUserLoggedIn && roleAllowed) {
        // If user is logged in and allowed, then proceed
        return next();
    } else if (isUserLoggedIn && !roleAllowed) {
        // If user is logged in but not allowed, then send to roleNotAllowedPage
        const params = {
            name: 'roleNotAllowed',
        };
        return next(params);
    } else if (!isUserLoggedIn) {
        // If user is not logged in, send them to login screen with redirect
        const params = {
            name: 'login',
            params: {
                redirect: to.path,
            },
        };
        return next(params);
    } else {
        // If anything else, clean up and redirect to login screen
        store.commit(DELETE_AUTHENTICATED_USER);
        const params = {
            name: 'login',
        };
        return next(params);
    }
});

// Validate required modules are present on user
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    const requiredModules = getRequiredModules(to);
    requiredModules.forEach((requiredModule) => {
        if (!store.getters.hasModule(requiredModule)) {
            return next({ name: 'missingSubscription' });
        }
    });
    next();
});

// Validate user has access to this assessment
router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.params?.assessmentId && to.params?.participantId) {
        const user = store.getters.currentUser;
        const assessmentParticipant = await ReportsApi.getParticipantByAssessmeentId(to.params?.assessmentId);
        if (to.params.participantId !== assessmentParticipant.id) {
            next({ name: 'pageNotFound' });
        } else if (user.role === PARTICIPANT_ROLE && !user.participantIds.includes(assessmentParticipant.id)) {
            next({ name: 'pageNotFound' });
        } else if (user.role === CLIENT_ADMIN_ROLE && user.companyId !== assessmentParticipant.companyId) {
            next({ name: 'pageNotFound' });
        }
    }
    next();
});

function getAllowedRoles(to: Route): string[] {
    if (to.meta.allowedRoles) {
        return to.meta.allowedRoles;
    } else {
        return [];
    }
}

function getRequiredModules(to: Route): CompanyModule[] {
    let requiredModules = [] as CompanyModule[];
    to.matched.forEach((m) => {
        if (m.meta.requiredModules) {
            requiredModules = requiredModules.concat(m.meta.requiredModules);
        }
    });
    return requiredModules;
}

function isRoleAllowed(allowedRoles: string[]): boolean {
    const user = store.getters.currentUser as AuthenticatedUser | undefined;
    return user !== undefined && allowedRoles.includes(user.role);
}

export default router;
