





















import Vue from 'vue';
import { WorkoutPlanModel } from '@/common/types/trainer/program';
import EyeView from '@/views/trainer/exercises/components/workouts/EyeView.vue';
import FancyRadioButton from '@/views/common/controls/FancyRadioButton.vue';

export default Vue.extend({
    components: { EyeView, FancyRadioButton },
    props: {
        plan: {
            type: Object as () => WorkoutPlanModel,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        recommended: {
            type: Boolean,
            default: false,
        },
    },
});
