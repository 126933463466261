





























































import Vue, { PropType } from 'vue';

import { PARTICIPANT_ROLE } from '@/common/constants/userRoles.constants';
import { TestType } from '@/common/constants/reports.constants';

import { Participant } from '@/common/types/user/participant';
import { ReportData } from '@/common/types/reports/reportData';

import Logger from '@/common/utils/logger';
import { getTimeStampFormat } from '@/common/utils/date';

import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';
import { SET_VIEWING_COMPANY } from '@/store/company/actions';

import ReportsApi from '@/api/reports.api';

import * as ReportUtils from '@/common/utils/reports/reports';
import CustomizeReportPanel from '@/views/reports/components/CustomizeReportPanel.vue';
import DynamicVisionListView from '@/views/reports/DynamicVisionListView.vue';
import DynamicVisionGridView from '@/views/reports/DynamicVisionGridView.vue';
import EyeQTrainerAssigned from '@/views/reports/components/EyeQTrainerAssigned.vue';
import ReportToolbar from '@/views/common/controls/ReportToolbar.vue';
import IconButton from '@/views/common/controls/IconButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import ParticipantInfo from '@/views/common/components/ParticipantInfo.vue';
import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import ScoreDynamicVisionCompact from '@/views/reports/cards/dynamicVision/ScoreDynamicVisionCompact.vue';
import TextLink from '@/views/common/controls/TextLink.vue';

const DV_TEST_TYPES = [
    'calibration',
    'circularSmoothPursuit',
    'horizontalSmoothPursuit',
    'verticalSmoothPursuit',
    'fixationStability',
    'choiceReactionTime',
    'discriminateReactionTime',
    'horizontalSaccades',
    'verticalSaccades',
] as TestType[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: true,
        },
        comparisonId: {
            type: Object as PropType<string | string[]>,
            required: false,
        },
    },
    components: {
        CustomizeReportPanel,
        EyeQTrainerAssigned,
        IconButton,
        LoadingCircle,
        ParticipantInfo,
        ReportFooter,
        TextLink,
        ScoreDynamicVisionCompact,
        DynamicVisionListView,
        DynamicVisionGridView,
        ReportToolbar,
    },
    data() {
        return {
            loading: true,
            showCustomizePanel: false,
            activeView: 'DynamicVisionGridView',
            reportDataCache: {} as Record<string, ReportData>,
            selectedComparisonIds: [] as string[],
        };
    },
    computed: {
        dynamicVisionActiveView(): string {
            return this.activeView;
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
        reportVersion(): number {
            const dashboardV2Date = new Date('4/25/2024 5:00 EST'); // Dashboard comparison to self release date
            const assessmentDate = new Date(this.reportDataCache[this.assessmentId].assessment.dateCreated);

            if (assessmentDate < dashboardV2Date) {
                return 1;
            }

            return 2;
        },
    },
    methods: {
        setDynamicVisionGridActive() {
            this.activeView = 'DynamicVisionGridView';
        },
        setDynamicVisionListActive() {
            this.activeView = 'DynamicVisionListView';
        },
        formatAssessmentDate(date: string): string {
            return `${this.$t('reports.controls.assessment')} ${getTimeStampFormat(date)}`;
        },
        setShowCustomizePanel(value: boolean) {
            this.showCustomizePanel = value;
        },
        onComparisonIdsChanged(event: any) {
            this.selectedComparisonIds = event;
        },
        handleExport() {
            const url = ReportUtils.reportDataToCSV(this.reportDataCache[this.assessmentId]);
            const link = document.createElement('a');
            link.download = 'AssessmentExport.csv';
            link.href = url;
            link.click();
        },
    },
    async mounted() {
        this.loading = true;
        try {
            this.reportDataCache[this.assessmentId] = await ReportsApi.getReportDataByAssessmentId(
                this.assessmentId,
                DV_TEST_TYPES,
            );
            await Promise.all([
                this.$store.dispatch(SET_VIEWING_PARTICIPANT, this.reportDataCache[this.assessmentId].participant.id),
                this.$store.dispatch(
                    SET_VIEWING_COMPANY,
                    this.reportDataCache[this.assessmentId].participant.companyId,
                ),
            ]);
        } catch (error) {
            Logger.error(`Failed to load assessments: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
        }

        if (this.comparisonId) {
            this.setDynamicVisionListActive();
        }

        this.loading = false;
    },
});
