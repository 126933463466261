




import Vue, { PropType } from 'vue';

import ReportsApi from '@/api/reports.api';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { MetricData } from '@/common/types/reports/metricData';
import { ReportData } from '@/common/types/reports/reportData';
import { Norms, NormsSelection } from '@/common/types/reports/norms';
import { ageBasedRangeFromDateOfBirth } from '@/common/utils/reports/norms';

import Logger from '@/common/utils/logger';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { TestType } from '@/common/constants/reports.constants';

const GREEN = '#94D500';
const YELLOW = '#E2D627';
const ORANGE = '#F4B220';
const RED = '#C93D4F';
const BLACK = '#00000';

export interface ReactionBarConfig {
    label: string;
    reportData: ReportData;
    metricData: MetricData;
    testType: TestType;
}

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<ReactionBarConfig>,
        },
    },
    data() {
        return {
            norms: undefined as Norms | undefined,
        };
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        saccadicLatency(): number {
            return this.config.metricData.saccadicLatency.average;
        },
        visualSpeed(): number {
            return this.config.metricData.visualReactionSpeed;
        },
        brainProcessingSpeed(): number {
            return this.config.metricData.processingSpeed;
        },
        targetAccuracy(): number {
            return this.config.metricData.responseAccuracy;
        },
        totalReactionTime(): number {
            return this.config.metricData.reactionTime;
        },
        normsSelection(): NormsSelection {
            if (this.config.reportData.info?.normsCategory) {
                return {
                    ageBased: [this.config.reportData.info.normsCategory],
                };
            } else {
                const ageBasedNormsCategory = ageBasedRangeFromDateOfBirth(
                    this.config.reportData.participant.dateOfBirth,
                );
                if (ageBasedNormsCategory) {
                    return {
                        ageBased: [ageBasedNormsCategory],
                    };
                } else {
                    return {};
                }
            }
        },
    },
    methods: {
        // Lower is better
        calculateScoreColorInverse(user: number, norms: number, stdDev: number | undefined): string {
            if (user && norms && stdDev) {
                const referenceUpperBound = norms + stdDev;
                if (user < referenceUpperBound) {
                    return GREEN;
                } else if (user < referenceUpperBound + stdDev) {
                    return YELLOW;
                } else if (user < referenceUpperBound + stdDev * 2) {
                    return ORANGE;
                } else {
                    return RED;
                }
            } else {
                return GREEN;
            }
        },
        // Higher is better
        calculateScoreColor(user: number, norms: number, stdDev: number | undefined): string {
            if (user && norms && stdDev) {
                const referenceLowerBound = norms - stdDev;
                if (user > referenceLowerBound) {
                    return GREEN;
                } else if (user < referenceLowerBound - stdDev) {
                    return YELLOW;
                } else if (user < referenceLowerBound - stdDev * 2) {
                    return ORANGE;
                } else {
                    return RED;
                }
            } else {
                return GREEN;
            }
        },
    },
    async mounted() {
        // Text Attributes
        const TEXT_FONT_FAMILY = 'ProximaNova';
        const TEXT_FONT_SIZE_LARGE = '.0025em';
        const NUMBER_FONT_SIZE_LARGE = '.0033em';
        const TEXT_FONT_SIZE_SMALL = '.002em';

        // Positions
        const LABEL_POSITION = new Position2d(0.5, 0.18);
        const BAR_POSITION = new Position2d(0.04, 0.28);
        const TOTAL_REACTION_TIME_POSITION = new Position2d(0.5, 0.82);
        const TOTAL_REACTION_TIME_OFFSET = 0.27;
        const TARGET_ACCURACY_POSITION = new Position2d(0.5, 0.94);
        const TARGET_ACCURACY_OFFSET = 0.21;

        // Bar Rects
        const BAR_HEIGHT = 0.09;
        const BAR_PADDING = 0.005;
        const LABEL_OFFSET = BAR_HEIGHT + 0.22;
        const NUMBER_OFFSET = 0.12;
        const LINE_HEIGHT = 0.1;

        const availableSpace = 1 - BAR_POSITION.x * 2 - BAR_PADDING * 3;
        const SL_BAR_WIDTH = availableSpace * (this.saccadicLatency / this.totalReactionTime);
        const VS_BAR_WIDTH = availableSpace * (this.visualSpeed / this.totalReactionTime);
        const BPS_BAR_WIDTH = availableSpace * (this.brainProcessingSpeed / this.totalReactionTime);

        const SL_BAR_START = BAR_POSITION;
        const VS_BAR_START = new Position2d(SL_BAR_START.x + SL_BAR_WIDTH + BAR_PADDING, BAR_POSITION.y);
        const BPS_BAR_START = new Position2d(VS_BAR_START.x + VS_BAR_WIDTH + BAR_PADDING, BAR_POSITION.y);

        const ASPECT_RATIO = 8 / 3;

        // Get Norm Data
        try {
            const normsData = await ReportsApi.getNormsDataForReport(this.config.reportData, this.normsSelection, [
                this.config.testType,
            ]);
            if (normsData[this.config.testType]?.ageBased) {
                const testNorms = normsData[this.config.testType];
                this.norms = testNorms.ageBased?.pop()?.data;
            }
        } catch (error) {
            Logger.error(`Failed to load norms data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            throw error;
        }

        const SL_COLOR = this.calculateScoreColorInverse(
            this.saccadicLatency,
            this.norms?.saccadicLatency.average.mean,
            this.norms?.saccadicLatency.average.stdDev,
        );
        const VS_COLOR = this.calculateScoreColorInverse(
            this.visualSpeed,
            this.norms?.visualReactionSpeed.mean,
            this.norms?.visualReactionSpeed.stdDev,
        );
        const BPS_COLOR = this.calculateScoreColorInverse(
            this.brainProcessingSpeed,
            this.norms?.processingSpeed.mean,
            this.norms?.processingSpeed.stdDev,
        );
        const TRT_COLOR = this.calculateScoreColorInverse(
            this.totalReactionTime,
            this.norms?.reactionTime.mean,
            this.norms?.reactionTime.stdDev,
        );
        const RA_COLOR = this.calculateScoreColor(
            this.targetAccuracy,
            this.norms?.responseAccuracy.mean,
            this.norms?.responseAccuracy.stdDev,
        );

        const visualization = Visualization.build();

        // Label Text
        visualization.text({
            content: this.config.label,
            position: [LABEL_POSITION.x, LABEL_POSITION.y],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Total Reaction Time Text
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.totalReactionTime')}:`,
            position: [TOTAL_REACTION_TIME_POSITION.x, TOTAL_REACTION_TIME_POSITION.y],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });
        visualization.text({
            content: `${this.totalReactionTime} ms`,
            position: [TOTAL_REACTION_TIME_POSITION.x + TOTAL_REACTION_TIME_OFFSET, TOTAL_REACTION_TIME_POSITION.y],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': TRT_COLOR,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Target Accuracy text
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.targetAccuracy')}:`,
            position: [TARGET_ACCURACY_POSITION.x, TARGET_ACCURACY_POSITION.y],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
            },
        });
        visualization.text({
            content: `${this.targetAccuracy}%`,
            position: [TARGET_ACCURACY_POSITION.x + TARGET_ACCURACY_OFFSET, TARGET_ACCURACY_POSITION.y],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': RA_COLOR,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Saccadic Latency Label
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.saccadicLatency.saccadic')}`,
            position: [SL_BAR_START.x + SL_BAR_WIDTH / 2, SL_BAR_START.y + LABEL_OFFSET],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_SMALL,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.saccadicLatency.latency')}`,
            position: [SL_BAR_START.x + SL_BAR_WIDTH / 2, SL_BAR_START.y + LABEL_OFFSET + LINE_HEIGHT],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_SMALL,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Saccadic Latency Bar
        visualization.rect({
            position: SL_BAR_START,
            width: SL_BAR_WIDTH,
            height: BAR_HEIGHT,
            aspectRatio: ASPECT_RATIO,
            attributes: {
                fill: SL_COLOR,
            },
        });

        // Saccadic Latency Number
        visualization.text({
            content: `${this.saccadicLatency} ms`,
            position: [SL_BAR_START.x + SL_BAR_WIDTH / 2, SL_BAR_START.y + NUMBER_OFFSET],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': NUMBER_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Visual Speed Label
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.visualSpeed.visual')}`,
            position: [VS_BAR_START.x + VS_BAR_WIDTH / 2, VS_BAR_START.y + LABEL_OFFSET],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_SMALL,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.visualSpeed.speed')}`,
            position: [VS_BAR_START.x + VS_BAR_WIDTH / 2, VS_BAR_START.y + LABEL_OFFSET + LINE_HEIGHT],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_SMALL,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Visual Speed Bar
        visualization.rect({
            position: VS_BAR_START,
            width: VS_BAR_WIDTH,
            height: BAR_HEIGHT,
            aspectRatio: ASPECT_RATIO,
            attributes: {
                fill: VS_COLOR,
            },
        });

        // Visual Speed Number
        visualization.text({
            content: `${this.visualSpeed} ms`,
            position: [VS_BAR_START.x + VS_BAR_WIDTH / 2, VS_BAR_START.y + NUMBER_OFFSET],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': NUMBER_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Brain Processing Speed Label
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.brainProcessingSpeed.brainProcessing')}`,
            position: [BPS_BAR_START.x + BPS_BAR_WIDTH / 2, BPS_BAR_START.y + LABEL_OFFSET],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_SMALL,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });
        visualization.text({
            content: `${this.$t('reports.reports.reactionTimeBar.brainProcessingSpeed.speed')}`,
            position: [BPS_BAR_START.x + BPS_BAR_WIDTH / 2, BPS_BAR_START.y + LABEL_OFFSET + LINE_HEIGHT],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': TEXT_FONT_SIZE_SMALL,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Brain Processing Speed bar
        visualization.rect({
            position: BPS_BAR_START,
            width: BPS_BAR_WIDTH,
            height: BAR_HEIGHT,
            aspectRatio: ASPECT_RATIO,
            attributes: {
                fill: BPS_COLOR,
            },
        });

        // Brain Processing Speed Number
        visualization.text({
            content: `${this.brainProcessingSpeed} ms`,
            position: [BPS_BAR_START.x + BPS_BAR_WIDTH / 2, BPS_BAR_START.y + NUMBER_OFFSET],
            aspectRatio: ASPECT_RATIO,
            attributes: {
                'font-size': NUMBER_FONT_SIZE_LARGE,
                'font-family': TEXT_FONT_FAMILY,
                'font-weight': 'bold',
            },
            styles: {
                'fill': BLACK,
                'text-anchor': 'middle',
                'dominant-baseline': 'central',
                'letter-spacing': '0',
            },
        });

        // Actually build the visualization according to the space avaliable
        visualization.drawNormalized(this.svg, 8 / 3);
    },
});
