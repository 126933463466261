import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { SET_ERROR_BANNER, CLEAR_ERROR_BANNER } from './mutations';

interface GeneralState {
    errorBanner: string | undefined;
}

const generalModule: Module<GeneralState, RootState> = {
    state: {
        errorBanner: undefined as string | undefined,
    },
    mutations: {
        [SET_ERROR_BANNER](state: GeneralState, error: Error | string) {
            if (typeof error === 'string') {
                state.errorBanner = error;
            } else {
                state.errorBanner = error.message;
            }
        },
        [CLEAR_ERROR_BANNER](state: GeneralState) {
            state.errorBanner = undefined;
        },
    },
    getters: {
        errorBanner(state: GeneralState): string | undefined {
            return state.errorBanner;
        },
    },
};

export default generalModule;
