import { STATS } from './common/queryFragments';

const FIXATION_METRICS_LRB = `{
    fixationLocationNotMeasurable
    fixationStabilityNotMeasurable
    fixationLocationAggregate
    fixationLocationByStimulus { shape value }
    fixationStabilityAggregate
    fixationStabilityByStimulus { shape value }
    gazePositionsBand1Aggregate
    gazePositionsBand1ByStimulus{ shape value }
    gazePositionsBand2Aggregate
    gazePositionsBand2ByStimulus{ shape value }
    gazePositionsBand3Aggregate
    gazePositionsBand3ByStimulus{ shape value }
    gazePositionsBand4Aggregate
    gazePositionsBand4ByStimulus{ shape value }
    targetingDisplacementHorizontal
    targetingDisplacementVertical
    fixationDispersion
    samplesLostPercent
    microsaccadePercent
    microsaccadeFreq
    headboxPercent
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const FIXATION_LOCATION_NORMS = `{
    shape
    value ${STATS}
}`;

const FIXATION_LABEL = `{ value }`;

const FIXATION_NORMS_LRB = `{
    fixationLocationNotMeasurable ${STATS}
    fixationStabilityNotMeasurable ${STATS}
    fixationLocationAggregate ${FIXATION_LABEL}
    fixationLocationByStimulus ${FIXATION_LOCATION_NORMS}
    fixationStabilityAggregate ${FIXATION_LABEL}
    fixationStabilityByStimulus ${FIXATION_LOCATION_NORMS}
    gazePositionsBand1Aggregate ${STATS}
    gazePositionsBand1ByStimulus ${FIXATION_LOCATION_NORMS}
    gazePositionsBand2Aggregate ${STATS}
    gazePositionsBand2ByStimulus ${FIXATION_LOCATION_NORMS}
    gazePositionsBand3Aggregate ${STATS}
    gazePositionsBand3ByStimulus ${FIXATION_LOCATION_NORMS}
    gazePositionsBand4Aggregate ${STATS}
    gazePositionsBand4ByStimulus ${FIXATION_LOCATION_NORMS}
    bceaByStimulus ${FIXATION_LOCATION_NORMS}
    targetingDisplacementHorizontal ${STATS}
    targetingDisplacementVertical ${STATS}
    fixationDispersion ${STATS}
    samplesLostPercent ${STATS}
    microsaccadePercent ${STATS}
    microsaccadeFreq ${STATS}
    headboxPercent ${STATS}
    distanceFromScreen ${STATS}
    distanceFromScreenMin ${STATS}
    distanceFromScreenMax ${STATS}
    disassociatedPhoria ${STATS}
    disassociatedTropia ${STATS}
}
`;

export const FIXATION_STABILITY_NORMS_QUERY = `
fixationStability {
    bceaAggregate ${STATS}
    depth ${STATS}
    convergencePoint ${STATS}
    headMovementPercent ${STATS}
    left ${FIXATION_NORMS_LRB}
    right ${FIXATION_NORMS_LRB}
    both ${FIXATION_NORMS_LRB}
    bothLeft ${FIXATION_NORMS_LRB}
    bothRight ${FIXATION_NORMS_LRB}
}
`;

export const FIXATION_STABILITY_METRIC_QUERY = `
fixationStability {
    metrics {
        eyeType
        headMovementPercent
        bceaAggregate
        bceaByStimulus { shape value }
        disassociatedPhoria { value label min max stdDev }
        disassociatedTropia { value label }
        depth
        convergencePoint
        left ${FIXATION_METRICS_LRB}
        right ${FIXATION_METRICS_LRB}
        both ${FIXATION_METRICS_LRB}
        bothLeft ${FIXATION_METRICS_LRB}
        bothRight ${FIXATION_METRICS_LRB}
    }
    visualData {
        gazeData {
            timeStamp
            phoria { value label min max stdDev }
            left {
                x
                y
                eyeX
                eyeY
                microsaccade
                zDistance
            }
            right {
                x
                y
                eyeX
                eyeY
                microsaccade
                zDistance
            }
        }
    }
}
`;
