



































































import Vue, { PropType } from 'vue';

import IconStatic from '@/views/common/static/IconStatic.vue';
import Manifests from '@/views/superAdmin/components/datatables/Manifests.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';

import { AppVersionManifest, AppVersions } from '@/common/types/appVersions/appVersions';

import * as Nav from '@/store/navigation/mutations';

import AppVersionApi from '@/api/appVersions.api';

export default Vue.extend({
    props: {
        appVersions: {
            type: Object as PropType<AppVersions>,
            required: true,
        },
    },
    components: {
        IconStatic,
        Manifests,
        SaveConfirmation,
    },
    data() {
        return {
            loading: false as boolean,
            appManifests: [] as AppVersionManifest[],
            launcherManifests: [] as AppVersionManifest[],
            deltavManifests: [] as AppVersionManifest[],
            selectedAppManifests: [] as AppVersionManifest[],
            selectedLauncherManifests: [] as AppVersionManifest[],
            selectedDeltavManifests: [] as AppVersionManifest[],
            showConfirmation: false as boolean,
        };
    },
    computed: {
        isCleanEnabled(): boolean {
            // Prevent cleaning deployed versions
            if (
                this.selectedAppManifests.length +
                    this.selectedLauncherManifests.length +
                    this.selectedDeltavManifests.length ===
                0
            ) {
                return false;
            }
            if (
                this.selectedAppManifests.filter((m: AppVersionManifest) => m.Environments && m.Environments.length > 0)
                    .length > 0
            ) {
                return false;
            }
            if (
                this.selectedLauncherManifests.filter(
                    (m: AppVersionManifest) => m.Environments && m.Environments.length > 0,
                ).length > 0
            ) {
                return false;
            }
            if (
                this.selectedDeltavManifests.filter(
                    (m: AppVersionManifest) => m.Environments && m.Environments.length > 0,
                ).length > 0
            ) {
                return false;
            }
            return true;
        },
    },
    methods: {
        async loadManifests() {
            const promises = [this.loadAppManifests(), this.loadLauncherManifests(), this.loadDeltavManifests()];
            await Promise.all(promises);
        },
        async loadAppManifests() {
            this.appManifests = await AppVersionApi.getAppManifests();
        },
        async loadLauncherManifests() {
            this.launcherManifests = await AppVersionApi.getLauncherManifests();
        },
        async loadDeltavManifests() {
            this.deltavManifests = await AppVersionApi.getDeltavManifests();
        },
        async cleanSelectedManifests() {
            const promises = [] as Array<Promise<AppVersionManifest[]>>;
            // eta-upgrade-api doesn't support multiple version cleaning at once yet, even though the interface allows it
            this.selectedAppManifests.forEach((m: AppVersionManifest) =>
                promises.push(AppVersionApi.cleanAppBuilds([m.Version])),
            );
            this.selectedLauncherManifests.forEach((m: AppVersionManifest) =>
                promises.push(AppVersionApi.cleanLauncherBuilds([m.Version])),
            );
            await Promise.all(promises);
        },
        showCleanConfirmation() {
            this.showConfirmation = true;
        },
        async saveConfirmation() {
            await this.cleanSelectedManifests();
            await this.loadManifests();
            this.showConfirmation = false;
        },
        cancelConfirmation() {
            this.showConfirmation = false;
        },
    },
    async mounted() {
        this.loading = true;

        this.$store.commit(Nav.SHOW_LEFT_NAV);
        await this.loadManifests();

        this.loading = false;
    },
});
