




























































import Vue, { PropType } from 'vue';

import Logger from '@/common/utils/logger';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

import CompanyLogo from '@/views/common/components/CompanyLogo.vue';
import EditableTextField from '@/views/common/controls/EditableTextField.vue';
import IconButton from '@/views/common/controls/IconButton.vue';

import { AssessmentData } from '@/common/types/reports/reportData';
import { Participant } from '@/common/types/user/participant';

import AssessmentApi from '@/api/assessment.api';
import {
    CLIENT_ADMIN_ROLE,
    PARTICIPANT_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

import { getTimeStampFormat, getDOBFormat } from '@/common/utils/date';
import * as ParticipantUtils from '@/common/utils/participant';

import * as FormatUtils from '@/common/utils/reports/format';
import { Company } from '@/common/types/company/company';

export default Vue.extend({
    components: {
        CompanyLogo,
        EditableTextField,
        IconButton,
    },
    props: {
        participant: {
            type: Object as PropType<Participant>,
            required: false,
        },
        assessment: {
            type: Object as PropType<AssessmentData>,
            required: true,
        },
        showNotes: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            cachedAssessment: this.assessment as AssessmentData,
        };
    },
    computed: {
        name(): string {
            const firstName = this.participantInfo.firstName;
            const lastName = this.participantInfo.lastName;
            return `${firstName} ${lastName}`;
        },
        dateOfBirth(): string {
            return getDOBFormat(ParticipantUtils.getDateOfBirth(this.participantInfo));
        },
        notes(): string {
            return this.cachedAssessment.notes.length > 0 ? this.cachedAssessment.notes : '';
        },
        assessmentDate(): string {
            return `${getTimeStampFormat(this.cachedAssessment.dateCreated)}`;
        },
        medicalConditions(): string {
            const medicalConditions = this.participantInfo.medicalConditions?.map((mc) => mc.condition.name);
            return FormatUtils.formatArrayAsCommaSeperatedString(medicalConditions);
        },
        visionConditions(): string {
            const visionConditions = this.participantInfo.visionConditions?.map((vc) => vc.condition.name);
            return FormatUtils.formatArrayAsCommaSeperatedString(visionConditions);
        },
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
        participantInfo(): Participant {
            return this.participant || this.$store.getters.viewingParticipant;
        },
        company(): Company {
            return this.$store.getters.viewingCompany;
        },
    },
    methods: {
        async saveAssessmentNotes() {
            try {
                const response = await AssessmentApi.updateAssessmentNotes(
                    this.cachedAssessment.id,
                    this.cachedAssessment.notes,
                );
                this.cachedAssessment.notes = response.notes;
            } catch (error) {
                Logger.error(`Failed to save assessment notes: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
    },
});
