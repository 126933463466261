


































import Vue from 'vue';
import TrainerApi from '@/api/trainer.api';
import MessageBar from '@/views/common/static/MessageBar.vue';
import ProgressRow from '@/views/trainer/exercises/components/progress/ProgressRow.vue';
import ProgressStar from '@/views/trainer/exercises/components/progress/ProgressStar.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import { WorkoutResponse } from '@/common/types/api/responses/trainerResponses';
import ExistingProgram from '@/views/trainer/exercises/components/program/ExistingProgram.vue';
import TextLink from '@/views/common/controls/TextLink.vue';
import { TranslateResult } from 'vue-i18n';
import { UPDATE_PROGRAM, UPDATE_CYCLE } from '@/store/trainer/actions';
import * as LeftNavUtils from '@/common/utils/leftNav';
import * as DateUtils from '@/common/utils/date';
import * as DateConstants from '@/common/constants/date.constants';

const DEFAULT_ROW_LENGTH = 10;
const COACH_EYEQ_FILE_SUFFIXES = ['good', 'great', 'awesome'];

export default Vue.extend({
    components: { GreenButton, MessageBar, ProgressStar, ProgressRow, ExistingProgram, TextLink },
    props: {
        showLogoutLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            msg: '' as TranslateResult,
            workoutRows: [] as any,
            rowLength: DEFAULT_ROW_LENGTH,
            completedNum: 0,
            startDate: '',
            daysRemaining: 0,
            workouts: undefined as WorkoutResponse[] | undefined,
            page: '',
            message: '',
        };
    },
    methods: {
        goHome() {
            this.$router.push({ name: 'home' });
        },
        doMore() {
            this.$router.push({ name: 'landing' });
        },
        displayCoach(min: number, message: string) {
            return this.completedNum >= min ? message : null;
        },
    },
    computed: {
        currentUser(): AuthenticatedUser | undefined {
            return this.$store.getters.currentUser;
        },
        hasProgram(): boolean {
            return this.$store.getters.hasProgram;
        },
        hasCycle(): boolean {
            return this.$store.getters.hasCycle;
        },
    },
    created() {
        if (!this.hasProgram) {
            this.page = 'basics';
            this.message = 'basics';
        } else if (!this.hasCycle) {
            this.page = 'chooseWorkout';
            this.message = 'chooseWorkout';
        }
    },
    async mounted() {
        LeftNavUtils.setEyeQTrainerLeftNav();

        this.msg = this.$t('trainer.progress.message', {
            name: this.currentUser?.name ?? 'User',
        });

        await this.$store.dispatch(UPDATE_PROGRAM);
        await this.$store.dispatch(UPDATE_CYCLE);

        const cycle = this.$store.getters.currentCycle;
        this.startDate = DateUtils.getTrainerProgressFormat(cycle.start);
        this.daysRemaining = DateUtils.getTimeDifference(new Date(), cycle.end, DateConstants.DAYS);

        try {
            this.workouts = await TrainerApi.getAllWorkoutsForCycle();
        } catch (error) {
            this.workouts = [];
        }

        this.completedNum = this.workouts.filter((workout: WorkoutResponse) => {
            return workout.complete;
        }).length;

        const numberOfWorkouts = this.workouts.length;
        const numberOfRowsToDisplay = Math.max(3, Math.ceil(numberOfWorkouts / this.rowLength));

        for (let i: number = numberOfWorkouts; i < numberOfRowsToDisplay * this.rowLength; i++) {
            this.workouts.push({
                started: '',
                complete: false,
                number: i + 1,
                exercises: [],
            });
        }

        for (let i: number = 0; i < numberOfRowsToDisplay; i++) {
            const rowStart = i * this.rowLength;
            const rowCutoff = rowStart + this.rowLength;
            this.workoutRows.push({
                workouts: this.workouts.slice(rowStart, rowCutoff),
                displayCoach: this.displayCoach(0, COACH_EYEQ_FILE_SUFFIXES[i % 3]),
            });
        }
    },
});
