



















import Vue, { PropType } from 'vue';

import ReportCard from '@/views/reports/cards/ReportCard.vue';
import { ReportData } from '@/common/types/reports/reportData';
import { ReportCardName } from '@/common/constants/reports.constants';
import { ReportNorms } from '@/common/types/reports/norms';

export default Vue.extend({
    props: {
        reportData: {
            type: Object as PropType<ReportData>,
            required: true,
        },
        norms: {
            type: Object as PropType<ReportNorms>,
            required: false,
        },
        cards: {
            type: Array as PropType<ReportCardName[]>,
            required: true,
        },
        loadMetricTable: {
            type: Boolean,
            required: false,
            default: true,
        },
        showInterpretationText: {
            type: Boolean,
            required: false,
            default: false,
        },
        forPrint: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasSensorimotorExam: {
            type: Boolean,
            required: false,
            default: false,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    components: {
        ReportCard,
    },
});
