import { Position2d } from '@/common/types/reports/position';
import { cmToPos } from '@/common/utils/trainer/math';
import { shuffleArray } from '@/common/utils/trainer/random';
import { StimulusState } from '@/common/utils/trainer/animation';
import { FixationAnimationParameters } from '@/common/types/trainer/animation';

export class FixationAnimation {
    private readonly animationSteps: FixationAnimationStep[] = [];

    public constructor(parameters: FixationAnimationParameters) {
        const pauseDuration = parameters.pauseDelay;
        const gapDuration = parameters.gapDelay;

        const directions: Position2d[] = [];

        const center = cmToPos(0, 0, 'exercise');

        directions.push(new Position2d(center.x + parameters.distance, center.y));
        directions.push(new Position2d(center.x - parameters.distance, center.y));
        directions.push(new Position2d(center.x, center.y + parameters.distance));
        directions.push(new Position2d(center.x, center.y - parameters.distance));

        shuffleArray(directions);

        let currentTime = 0;

        for (const position of directions) {
            // Add center position
            currentTime += pauseDuration;
            const centerStep = {
                endTime: currentTime,
                state: {
                    position: center,
                    radius: parameters.radius,
                    isVisible: true,
                    timerState: { duration: pauseDuration },
                },
            };
            this.animationSteps.push(centerStep);

            currentTime += gapDuration;
            const centerGap = {
                endTime: currentTime,
                state: {
                    position: center,
                    radius: parameters.radius,
                    isVisible: false,
                    timerState: undefined,
                },
            };
            this.animationSteps.push(centerGap);

            // Add exercise position
            currentTime += pauseDuration;
            const fixationStep = {
                endTime: currentTime,
                state: {
                    position,
                    radius: parameters.radius,
                    isVisible: true,
                    timerState: { duration: pauseDuration },
                },
            };
            this.animationSteps.push(fixationStep);

            currentTime += gapDuration;
            const fixationGap = {
                endTime: currentTime,
                state: {
                    position,
                    radius: parameters.radius,
                    isVisible: false,
                    timerState: undefined,
                },
            };
            this.animationSteps.push(fixationGap);
        }

        // End with center position
        currentTime += pauseDuration;
        const finalCenterStep = {
            endTime: currentTime,
            state: {
                position: center,
                radius: parameters.radius,
                isVisible: true,
                timerState: { duration: pauseDuration },
            },
        };
        this.animationSteps.push(finalCenterStep);
    }

    public stimulusState(time: number): StimulusState | undefined {
        if (time > this.animationSteps[this.animationSteps.length - 1].endTime || time < 0) {
            return undefined;
        } else {
            for (const step of this.animationSteps) {
                if (time < step.endTime) {
                    return step.state;
                }
            }
            return undefined;
        }
    }
}

interface FixationAnimationStep {
    endTime: number;
    state: StimulusState;
}
