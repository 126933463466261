import ApiService from '@/api/common/apiService';
import { HttpMethod } from '@/common/types/api/requests/customRequest';
import { AuthenticationToken } from '@/common/types/auth/auth';
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import { CognitoPublicKeyResponse } from '@/common/types/api/responses/authResponses';

declare const AwsRegion: string;
declare const AwsCognitoUserPoolId: string;

export async function authenticate(username: string, password: string): Promise<AuthenticationToken> {
    const authenticateRequest = {
        url: `/righteye.api/authenticate`,
        method: 'POST' as HttpMethod,
        data: { username, password },
    };
    return await ApiService.requestWithNoAuthorization<AuthenticationToken>(authenticateRequest);
}

export async function authenticateWithRefreshToken(refreshToken: string): Promise<AuthenticationToken> {
    const authenticateRequest = {
        url: `/righteye.api/authenticate/refresh`,
        method: 'POST' as HttpMethod,
        data: refreshToken,
    };
    return await ApiService.requestWithNoAuthorization<AuthenticationToken>(authenticateRequest);
}

export async function logout(): Promise<void> {
    const url: string = `/righteye.api/logout`;
    return await ApiService.post<void>(url);
}

export async function getPublicKey(idToken: string): Promise<string> {
    const publicKeyRequest = {
        url: `https://cognito-idp.${AwsRegion}.amazonaws.com/${AwsCognitoUserPoolId}/.well-known/jwks.json`,
        method: 'GET' as HttpMethod,
    };
    const response = await ApiService.requestWithNoAuthorization<CognitoPublicKeyResponse>(publicKeyRequest);

    const decodedToken = jwt.decode(idToken, { json: true, complete: true });

    const keyResponse = response.keys.filter((key) => key.kid === decodedToken?.header.kid);
    return jwkToPem(keyResponse[0] as any);
}
