

























import Vue from 'vue';

import { CLEAR_ERROR_BANNER } from '@/store/general/mutations';

export default Vue.extend({
    data() {
        return {
            alertIcon: 'mdi-alert-circle',
            show: false,
        };
    },
    props: {
        login: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        message(): string {
            return this.$store.getters.errorBanner;
        },
    },
    watch: {
        message() {
            this.show = true;
        },
        show(newValue: boolean) {
            if (!newValue) {
                this.$store.commit(CLEAR_ERROR_BANNER);
            }
        },
    },
});
