



























import Vue, { PropType } from 'vue';

import NearPointConvergenceVisualization, {
    NearPointConvergenceConfig,
} from '@/views/reports/visualizations/NearPointConvergenceVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';

import MultiMetricTable from '@/views/reports/metrics/MultiMetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        NearPointConvergenceVisualization,
        MultiMetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        forPrint: {
            default: false,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            config: {
                testData: this.reportData.tests.nearPointConvergence,
                systemType: this.reportData.assessment.systemType,
            } as NearPointConvergenceConfig,
            directions: [
                'midlinePrimary',
                'superiorLeft',
                'superiorRight',
                'inferiorLeft',
                'inferiorRight',
                'midlineLeft',
                'superiorMidline',
                'midlineRight',
                'inferiorMidline',
            ],
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.nearPointConvergence'));

            return infoText;
        },
    },
});
