





































import Vue from 'vue';
import BAAContent from './BAAContent.vue';
import CompanyApi from '@/api/company.api';
import { CompanyBAARequest } from '@/common/types/api/requests/companyRequest';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import { ButtonState } from '@/common/types/general';

// ignoring this because html2pdf.js does not have a type declaration
// @ts-ignore
import * as html2pdf from 'html2pdf.js';

export default Vue.extend({
    props: {
        companyId: {
            type: String,
            required: false,
        },
        baaKey: {
            type: String,
            required: false,
        },
    },
    components: {
        BAAContent,
        GreenButton,
    },
    data() {
        return {
            x: 0,
            y: 0,
            isDrawing: false,
            context: {} as CanvasRenderingContext2D,
            canvasImage: '',
            saving: true,
            showConfirmation: false as boolean,
            exportCompleted: false as boolean,
            companyName: '' as string,
            rect: {} as DOMRect,
            startDate: '' as string,
            isSignatureDrawn: false as boolean,
            name: '' as string,
        };
    },
    computed: {
        saveButtonState(): ButtonState {
            if (this.isSignatureDrawn && this.name !== '') {
                return 'active';
            }

            return 'inactive';
        },
        coveredEntity(): string {
            return this.companyName;
        },
        companyStartDate(): string {
            return this.startDate;
        },
    },
    methods: {
        async exportToPDF() {
            this.saving = false;
            this.canvasImage = (document.getElementById('myCanvas') as HTMLCanvasElement).toDataURL('image/png');

            const pdf = await html2pdf()
                .set({
                    margin: 0.5,
                    filename: 'document.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { scale: 1.2, dpi: 192, letterRendering: true, useCORS: true },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                })
                .from(this.$refs.document)
                .outputPdf();

            this.saving = true;
            this.exportCompleted = true;

            const base64EncodedBAA = window.btoa(pdf);
            const baaRequest = {
                body: base64EncodedBAA,
            } as CompanyBAARequest;

            if (this.baaKey) {
                await CompanyApi.uploadBAAForCompany(this.companyId, this.baaKey, baaRequest);
            } else {
                await CompanyApi.uploadBAAForCompanyAuthenticated(this.companyId, baaRequest);
                this.$router.push({ name: `home` });
            }
        },
        hasGenerated() {
            alert('PDF generated successfully!');
        },
        drawLine(x1: number, y1: number, x2: number, y2: number) {
            const ctx = this.context;
            ctx.beginPath();
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 1;
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.stroke();
            ctx.closePath();
            this.isSignatureDrawn = true;
        },
        beginDrawing(e: any) {
            this.x = e.offsetX;
            this.y = e.offsetY;
            this.isDrawing = true;
        },
        beginDrawingTouch(e: any) {
            e.preventDefault();
            this.rect = this.getCanvasElementById('myCanvas').getBoundingClientRect();

            const touch = e.touches[0];
            this.x = touch.clientX - this.rect.left;
            this.y = touch.clientY - this.rect.top;
            this.isDrawing = true;
        },
        keepDrawing(e: any) {
            if (this.isDrawing === true) {
                this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
                this.x = e.offsetX;
                this.y = e.offsetY;
            }
        },
        keepDrawingTouch(e: any) {
            e.preventDefault();
            if (this.isDrawing === true) {
                const touch = e.touches[0];
                this.rect = this.getCanvasElementById('myCanvas').getBoundingClientRect();

                this.drawLine(this.x, this.y, touch.clientX - this.rect.left, touch.clientY - this.rect.top);
                this.x = touch.clientX - this.rect.left;
                this.y = touch.clientY - this.rect.top;
            }
        },
        stopDrawing(e: any) {
            e.preventDefault();
            if (this.isDrawing === true) {
                this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
                this.x = 0;
                this.y = 0;
                this.isDrawing = false;
            }
        },
        showSaveConfirmation() {
            this.showConfirmation = true;
        },
        scrollToBottom() {
            window.scrollTo(0, document.body.scrollHeight);
        },
        getCanvasElementById(id: string): HTMLCanvasElement {
            // https://stackoverflow.com/questions/13669404/typescript-problems-with-type-system
            const canvas = document.getElementById(id);

            if (!(canvas instanceof HTMLCanvasElement)) {
                throw new Error(
                    `The element of id "${id}" is not a HTMLCanvasElement. Make sure a <canvas id="${id}""> element is present in the document.`,
                );
            }

            return canvas;
        },
    },
    async mounted() {
        if (this.baaKey) {
            const companyBAA = await CompanyApi.getBAAForCompany(this.companyId, this.baaKey);
            this.companyName = companyBAA.companyName;
            this.startDate = companyBAA.startDate;
        } else {
            const companyBAA = await CompanyApi.getBAAForCompanyAuthenticated(this.companyId);
            this.companyName = companyBAA.companyName;
            this.startDate = companyBAA.startDate;
        }

        // https://stackoverflow.com/questions/13669404/typescript-problems-with-type-system
        const getCanvasRenderingContext2D = (canvas: HTMLCanvasElement): CanvasRenderingContext2D => {
            const context = canvas.getContext('2d');

            if (context === null) {
                throw new Error('This browser does not support 2-dimensional canvas rendering contexts.');
            }

            return context;
        };

        this.context = getCanvasRenderingContext2D(this.getCanvasElementById('myCanvas'));
    },
});
