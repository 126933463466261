



































































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';

import { ButtonState } from '@/common/types/general';
import CircularSmoothPursuitVisualization, {
    CircularSmoothPursuitConfig,
} from '@/views/reports/visualizations/CircularSmoothPursuitVisualization.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import VergenceVisualization, { VergenceConfig } from '@/views/reports/visualizations/VergenceVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';
import { formatNumber } from '@/common/utils/reports/format';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        VergenceVisualization,
        CircularSmoothPursuitVisualization,
        GreenButton,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        const visualData = VisualDataUtils.extractVisualData(this.reportData, 'circularSmoothPursuit');

        return {
            config: {
                testData: visualData,
                systemType: this.reportData.assessment.systemType,
            } as CircularSmoothPursuitConfig,
            vergenceConfig: {
                testData: visualData,
                systemType: this.reportData.assessment.systemType,
                metrics: this.reportData.tests.circularSmoothPursuit.metrics,
                radius: 5,
            } as VergenceConfig,
            playButtonState: 'active' as ButtonState,
            playAnimation: false,
            headers: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: 'Right Eye',
                    value: 'right',
                },
                {
                    text: 'Left Eye',
                    value: 'left',
                },
                {
                    text: 'Both Eyes',
                    value: 'both',
                },
            ],
        };
    },
    methods: {
        playReplayAnimation() {
            this.playButtonState = 'inactive';
            this.playAnimation = true;
        },
        onAnimationFinished() {
            this.playButtonState = 'active';
            this.playAnimation = false;
        },
    },
    computed: {
        tableData(): any {
            const metrics = this.reportData.tests.circularSmoothPursuit.metrics;

            return [
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.spPercent'),
                    right: formatNumber(metrics.right.spPercent),
                    left: formatNumber(metrics.left.spPercent),
                    both: formatNumber(metrics.both.spPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.spEfficiency'),
                    right: formatNumber(metrics.right.spEfficiency),
                    left: formatNumber(metrics.left.spEfficiency),
                    both: formatNumber(metrics.both.spEfficiency),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.saccadePercent'),
                    right: formatNumber(metrics.right.saccadePercent),
                    left: formatNumber(metrics.left.saccadePercent),
                    both: formatNumber(metrics.both.saccadePercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.fixationPercent'),
                    right: formatNumber(metrics.right.fixationPercent),
                    left: formatNumber(metrics.left.fixationPercent),
                    both: formatNumber(metrics.both.fixationPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.eyeTargetVelErr'),
                    right: formatNumber(metrics.right.eyeTargetVelErr),
                    left: formatNumber(metrics.left.eyeTargetVelErr),
                    both: formatNumber(metrics.both.eyeTargetVelErr),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.horizontalSynchronizationSp'),
                    right: formatNumber(metrics.right.horizontalSynchronizationSp),
                    left: formatNumber(metrics.left.horizontalSynchronizationSp),
                    both: formatNumber(metrics.both.horizontalSynchronizationSp),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.verticalSynchronizationSp'),
                    right: formatNumber(metrics.right.verticalSynchronizationSp),
                    left: formatNumber(metrics.left.verticalSynchronizationSp),
                    both: formatNumber(metrics.both.verticalSynchronizationSp),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.onTargetSpPercent'),
                    right: formatNumber(metrics.right.onTargetSpPercent),
                    left: formatNumber(metrics.left.onTargetSpPercent),
                    both: formatNumber(metrics.both.onTargetSpPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.predictiveSpPercent'),
                    right: formatNumber(metrics.right.predictiveSpPercent),
                    left: formatNumber(metrics.left.predictiveSpPercent),
                    both: formatNumber(metrics.both.predictiveSpPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.latentSpPercent'),
                    right: formatNumber(metrics.right.latentSpPercent),
                    left: formatNumber(metrics.left.latentSpPercent),
                    both: formatNumber(metrics.both.latentSpPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.backupSaccadePercent'),
                    right: formatNumber(metrics.right.backupSaccadePercent),
                    left: formatNumber(metrics.left.backupSaccadePercent),
                    both: formatNumber(metrics.both.backupSaccadePercent),
                },
                {
                    name: this.$t('reports.metrics.tests.circularSmoothPursuit.catchupSaccadePercent'),
                    right: formatNumber(metrics.right.catchupSaccadePercent),
                    left: formatNumber(metrics.left.catchupSaccadePercent),
                    both: formatNumber(metrics.both.catchupSaccadePercent),
                },
            ];
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.circularSmoothPursuit?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.circularSmoothPursuit?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.circularSmoothPursuit?.metrics,
                'distanceFromScreen',
            );
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sp.smoothPursuit'));
            infoText.push(this.$t('reports.info.pursuits'));
            infoText.push(this.$t('reports.info.sp.efficiency'));
            infoText.push(this.$t('reports.info.sp.saccadeFixation'));
            infoText.push(this.$t('reports.info.sp.eyeTargetVel'));
            infoText.push(this.$t('reports.info.sp.horVertSync'));
            infoText.push(this.$t('reports.info.sp.predictiveLatentSP'));
            infoText.push(this.$t('reports.info.sp.targetSP'));

            return infoText;
        },
        paperLink(): string {
            if (this.reportData.assessment.systemType === 'I15') {
                return 'reports.info.normsV3.I15.circularSmoothPursuit';
            }

            return 'reports.info.normsV3.SMI.circularSmoothPursuit';
        },
    },
});
