import {
    CLIENT_USER_ROLE,
    CLIENT_ADMIN_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

import EqtParticipants from '@/views/trainer/admin/EqtParticipants.vue';
import Exercises from '@/views/trainer/admin/Exercises.vue';
import Tutorial from '@/views/trainer/exercises/program/workout/Tutorial.vue';

import { EYEQ_TRAINER } from '@/common/types/auth/companyModules';

const routes = [
    {
        path: 'eqtparticipants',
        name: 'eqtparticipants',
        alias: '/provider/summary',
        component: EqtParticipants,
        meta: {
            allowedRoles: [CLIENT_USER_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE, CLIENT_ADMIN_ROLE],
            requiredModules: [EYEQ_TRAINER],
        },
    },
    {
        path: 'exercises',
        name: 'exercises',
        component: Exercises,
        meta: {
            allowedRoles: [CLIENT_USER_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE, CLIENT_ADMIN_ROLE],
            requiredModules: [EYEQ_TRAINER],
        },
    },
    {
        path: 'exercises/:exerciseId',
        name: 'exerciseTutorial',
        component: Tutorial,
        props: true,
        meta: {
            allowedRoles: [CLIENT_USER_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE, CLIENT_ADMIN_ROLE],
            requiredModules: [EYEQ_TRAINER],
        },
    },
];

export default routes;
