











import { TestData } from '@/common/types/reports/reportData';
import Vue, { PropType } from 'vue';

import * as FormatUtils from '@/common/utils/reports/format';
import * as MetricsUtils from '@/common/utils/reports/metrics';

interface MinMaxAvg {
    min: number;
    max: number;
    avg: number;
}

export default Vue.extend({
    props: {
        tests: {
            type: Object as PropType<TestData[]>,
            required: false,
            default: undefined,
        },
    },
    computed: {
        minMaxAvg(): MinMaxAvg {
            const minMaxAvg = {
                min: 0,
                max: 0,
                avg: 0,
            } as MinMaxAvg;
            let total = 0;
            let count = 0;

            for (const test of Object.values(this.tests)) {
                const metrics = test?.metrics;
                if (metrics !== undefined) {
                    const min = MetricsUtils.searchForField(metrics, 'distanceFromScreenMin');
                    const max = MetricsUtils.searchForField(metrics, 'distanceFromScreenMax');
                    const avg = MetricsUtils.searchForField(metrics, 'distanceFromScreen');
                    if (min !== undefined && max !== undefined && avg !== undefined) {
                        if (FormatUtils.isValidValue(min)) {
                            if (min < minMaxAvg.min || !minMaxAvg.min) {
                                minMaxAvg.min = min;
                            }
                        }
                        if (FormatUtils.isValidValue(max)) {
                            if (max > minMaxAvg.max || !minMaxAvg.max) {
                                minMaxAvg.max = max;
                            }
                        }
                        if (FormatUtils.isValidValue(avg)) {
                            total += avg;
                            count++;
                        }
                    }
                    minMaxAvg.avg = total / count;
                }
            }
            return minMaxAvg;
        },
        minDistance(): string {
            return FormatUtils.formatNumber(this.minMaxAvg.min, 0);
        },
        maxDistance(): string {
            return FormatUtils.formatNumber(this.minMaxAvg.max, 0);
        },
        averageDistance(): string {
            return FormatUtils.formatNumber(this.minMaxAvg.avg, 0);
        },
    },
});
