import { Route } from 'vue-router';

import UserPage from '@/views/user/UserPage.vue';

import {
    PARTICIPANT_ROLE,
    CLIENT_ADMIN_ROLE,
    CLIENT_USER_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

const routes = [
    {
        path: '/user/:userId',
        name: 'user',
        component: UserPage,
        meta: {
            allowedRoles: [PARTICIPANT_ROLE, CLIENT_USER_ROLE, CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE],
        },
        props: (route: Route) => ({
            userId: route.params.userId,
        }),
    },
];

export default routes;
