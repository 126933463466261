import PrescriptionModel from './prescriptionModel';
import { convertUTCToLocal } from '@/common/utils/date';

export default class GeneralAssignmentModel {
    private readonly id: string;
    private prescriptions: PrescriptionModel[];
    private readonly dateCreated: Date;
    private readonly isAssessment: boolean = false;

    public constructor(id: string, dateCreated: string) {
        this.id = id;
        this.prescriptions = [];
        this.dateCreated = convertUTCToLocal(dateCreated); // response's date string is guaranteed UTC
    }

    public get Id(): string {
        return this.id;
    }

    public get DateCreated(): Date {
        return this.dateCreated;
    }

    public get Prescriptions(): PrescriptionModel[] {
        return this.prescriptions;
    }

    public get IsAssessment() {
        return this.isAssessment;
    }

    public AddPrescription(prescription: PrescriptionModel) {
        this.prescriptions.push(prescription);
        this.sort();
    }

    public sort() {
        this.prescriptions.sort((a, b) => (a.Ordering > b.Ordering ? 1 : -1));
    }
}
