

























































import Vue from 'vue';

import * as LeftNavUtils from '@/common/utils/leftNav';
import models from '@/common/types/trainer/exerciseModels';
import { ExerciseModel } from '@/common/types/trainer/program';
import { WORKOUT_PLANS } from '@/common/types/trainer/program';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import PreviewWorkout from '@/views/trainer/admin/components/PreviewWorkout.vue';

export default Vue.extend({
    components: {
        GreenButton,
        PreviewWorkout,
    },
    data() {
        return {
            exercises: {},
            workoutPlans: [] as string[],
            showPreviewWorkout: false as boolean,
            selectedWorkoutPlan: undefined as string | undefined,
        };
    },
    methods: {
        async handleSubmit(exercise: ExerciseModel) {
            this.$router.push({
                name: 'exerciseTutorial',
                params: { exerciseId: exercise.id },
            });
        },
        handlePreviewWorkout(workoutPlan: string | undefined) {
            this.showPreviewWorkout = !this.showPreviewWorkout;
            this.selectedWorkoutPlan = workoutPlan;
        },
    },
    mounted() {
        LeftNavUtils.setEyeQTrainerLeftNav();
        this.exercises = {
            fixation: [models.fixation],
            pursuit: [
                models.leftPursuit,
                models.rightPursuit,
                models.verticalPursuit,
                models.leftVPattern,
                models.rightVPattern,
                models.bilateralVPattern,
                models.leftAPattern,
                models.rightAPattern,
                models.bilateralAPattern,
            ],
            saccade: [models.leftSaccade, models.rightSaccade, models.upSaccade, models.downSaccade],
        };
        this.workoutPlans = WORKOUT_PLANS;
    },
});
