

































































































































import Vue, { PropType } from 'vue';
import ReportsApi from '@/api/reports.api';
import Logger from '@/common/utils/logger';

import * as Nav from '@/store/navigation/mutations';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

import FixedToolbar from '@/views/common/controls/FixedToolbar.vue';
import IconButton from '@/views/common/controls/IconButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import ReportCard from '@/views/reports/cards/ReportCard.vue';
import ScoreDynamicVisionCompact from '@/views/reports/cards/dynamicVision/ScoreDynamicVisionCompact.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { Assessment } from '@/common/types/reports/assessment';
import { ComparativeAssessment } from '@/common/types/reports/comparativeAssessment';
import { ReportData } from '@/common/types/reports/reportData';
import { AgeBasedNormsRange } from '@/common/types/reports/norms';
import * as ReportUtils from '@/common/utils/reports/reports';
import { ageBasedRangeFromDateOfBirth } from '@/common/utils/reports/norms';
import PrintableDynamicVisionReport from '@/views/reports/PrintableDynamicVisionReport.vue';

import {
    ReportCardName,
    TestType,
    AssessmentType,
    REPORT_NAVIGATION_ICONS,
} from '@/common/constants/reports.constants';
import { PARTICIPANT_ROLE } from '@/common/constants/userRoles.constants';
import { getTimeStampFormat } from '@/common/utils/date';

interface VSelectObject {
    text?: string;
    value?: string;
    divider?: boolean;
    header?: string;
}
const DV_TEST_TYPES = [
    'calibration',
    'circularSmoothPursuit',
    'horizontalSmoothPursuit',
    'verticalSmoothPursuit',
    'fixationStability',
    'choiceReactionTime',
    'discriminateReactionTime',
    'horizontalSaccades',
    'verticalSaccades',
] as TestType[];

// Reports that should generate Dynamic Vision
const DV_ASSESSMENT_TYPES = ['dynamic-vision', 'brain-eyeq', 'functional-eyeq', 'sports-eyeq'] as AssessmentType[];

const DV_CARDS = [
    'ScoreDynamicVision',
    'CircularSmoothPursuitDynamicVision',
    'HorizontalSmoothPursuitDynamicVision',
    'VerticalSmoothPursuitDynamicVision',
    'HorizontalSaccadesDynamicVision',
    'VerticalSaccadesDynamicVision',
    'FixationStabilityDynamicVision',
    'ChoiceReactionTimeDynamicVision',
    'DiscriminateReactionTimeDynamicVision',
    'CalibrationDynamicVision',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: true,
        },
        comparisonId: {
            type: Object as PropType<string | string[]>,
            required: false,
        },
        reportDataCache: {
            type: Object as PropType<Record<string, ReportData>>,
            required: true,
        },
        reportVersion: {
            type: Number,
            required: true,
        },
    },
    components: {
        IconButton,
        LoadingCircle,
        ReportCard,
        ScreenDistanceDisplay,
        ScoreDynamicVisionCompact,
        PrintableDynamicVisionReport,
        FixedToolbar,
    },
    data() {
        return {
            loading: true,
            DV_CARDS,
            showClipboardConfirmation: false,
            showCustomizePanel: false,
            comparisonColumns: 0 as number,
            minComparisonColumns: 0 as number,
            dropdownListValues: [] as VSelectObject[],
            participantAssessments: [] as Assessment[],
            comparativeAssessments: [] as ComparativeAssessment[],
            selectedComparisonIds: [] as string[],
            selectedComparisonIdsCache: [] as string[],
            showCompactCards: false,
        };
    },
    computed: {
        maxComparisonColumns(): number {
            const routerView = document.getElementsByClassName('view-content');
            const contentWidth = routerView[0]?.clientWidth ? routerView[0].clientWidth : window.innerWidth;

            let maxColumnsByScreenSize = Math.trunc(contentWidth / 620); // Roughly a column's width by trial and error
            maxColumnsByScreenSize = Math.min(maxColumnsByScreenSize, 2);

            return Math.min(
                maxColumnsByScreenSize,
                this.participantAssessments.length + this.comparativeAssessments.length,
            );
        },
        normsCategory(): AgeBasedNormsRange | undefined {
            return ageBasedRangeFromDateOfBirth(this.reportDataCache[this.assessmentId]?.participant?.dateOfBirth);
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
    },
    watch: {
        selectedComparisonIds(newArray) {
            // If one column's assessment is selected by another column, then they should swap instead of show duplicates
            // Vue's watcher newValue, oldValue params do not work if you mutate an object or array, so we'll keep our own cache of the previous value
            for (const [index, value] of newArray.entries()) {
                // If the selection has changed
                if (value !== this.selectedComparisonIdsCache[index]) {
                    // And the cache contains the new value
                    if (this.selectedComparisonIdsCache.includes(value)) {
                        const oldIndex = this.selectedComparisonIdsCache.indexOf(value); // Then find the new value in the cache
                        newArray[oldIndex] = this.selectedComparisonIdsCache[index]; // And swap the two values
                        break; // break so the swap is not undone later in the loop, we can reasonably assume only one column changes at once
                    }
                }
            }
            this.selectedComparisonIdsCache = JSON.parse(JSON.stringify(newArray)); // update the cache with a deep copy
        },
        comparisonColumns(newValue) {
            this.$emit('comparisonIdsChanged', this.selectedComparisonIds.slice(0, newValue));
        },
    },
    methods: {
        formatAssessmentDate(date: string): string {
            return `${this.$t('reports.controls.assessment')} ${getTimeStampFormat(date)}`;
        },
        async loadReportData(id: string) {
            if (!this.reportDataCache[id]) {
                try {
                    // Figure out if the provided id is a participant assessment id or a comparative assessment id
                    if (
                        id === this.assessmentId ||
                        this.participantAssessments.filter((pa) => pa.id === id).length > 0
                    ) {
                        const reportData = await ReportsApi.getReportDataByAssessmentId(id, DV_TEST_TYPES);
                        Vue.set(this.reportDataCache, id, reportData);
                    } else {
                        const reportData = await ReportsApi.getReportDataByComparativeAssessmentId(id, DV_TEST_TYPES);
                        Vue.set(this.reportDataCache, id, reportData);
                    }
                } catch (error) {
                    Logger.error(`Failed to load visual data: ${error}`);
                    this.$store.commit(SET_ERROR_BANNER, error.message.message);
                }
            }
        },
        formatCompactMessage(reportDataItem: any): string {
            if (reportDataItem.info) {
                return `${reportDataItem.info.label} - Ages ${reportDataItem.info.normsCategory}`;
            }

            return `${getTimeStampFormat(reportDataItem.assessment.dateCreated)}`;
        },
        generateLeftNavLinks() {
            this.$store.commit(Nav.SHOW_LEFT_NAV);
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, '❮ ' + this.$t('reports.reports.dynamicVisionReport'));

            const links = DV_CARDS.map((c) => ({
                text: this.$t('reports.cards.titles.' + c).toString(),
                to: { hash: c },
                icon: REPORT_NAVIGATION_ICONS[c],
                enabled: true,
            }));
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
        generateComparisonDropdownList() {
            this.dropdownListValues.push({ header: this.$t('reports.reports.selectAssessment').toString() });
            this.dropdownListValues.push({ divider: true });
            this.dropdownListValues = this.dropdownListValues.concat(
                this.participantAssessments.map((a: Assessment) => {
                    return {
                        text: this.formatAssessmentDate(a.dateCreated),
                        value: a.id,
                    };
                }),
            );

            if (this.comparativeAssessments.length > 0) {
                this.dropdownListValues.push({ header: this.$t('reports.reports.selectComparison').toString() });
                this.dropdownListValues.push({ divider: true });
                this.dropdownListValues = this.dropdownListValues.concat(
                    this.comparativeAssessments.map((ca: ComparativeAssessment) => {
                        return {
                            text: `${ca.label} - Ages ${ca.normsCategory}`,
                            value: ca.id,
                        };
                    }),
                );
            }
        },
        generateSelectedComparisons() {
            // Cast the comparisonId prop(s) to an array
            if (this.comparisonId && !Array.isArray(this.comparisonId)) {
                this.selectedComparisonIds = [this.comparisonId];
            } else if (this.comparisonId) {
                this.selectedComparisonIds = this.comparisonId as string[];
            } else {
                this.selectedComparisonIds = [];
            }

            // Seed the drop downs with the query param assessmentIds then add the first unused assessment for each of the remaining empty columns
            if (this.participantAssessments.length && this.selectedComparisonIds.length < this.comparisonColumns) {
                for (let column = this.selectedComparisonIds.length; column < this.comparisonColumns; column++) {
                    for (const a of this.participantAssessments.concat(this.comparativeAssessments as any[])) {
                        if (!this.selectedComparisonIds.includes(a.id)) {
                            this.selectedComparisonIds[column] = a.id;
                            break;
                        }
                    }
                }
            } else {
                // Instead set the number of columns to the number of selected assessments or the max columns
                this.comparisonColumns = Math.min(this.selectedComparisonIds.length, this.maxComparisonColumns);
            }
        },
        addColumn() {
            if (this.comparisonColumns < this.maxComparisonColumns) {
                this.comparisonColumns++;
                if (!this.selectedComparisonIds[this.comparisonColumns - 1]) {
                    // If this column has not been used before, seed it with the first assessment not already selected
                    for (const a of this.participantAssessments.concat(this.comparativeAssessments as any[])) {
                        if (!this.selectedComparisonIds.includes(a.id)) {
                            this.selectedComparisonIds.splice(this.comparisonColumns - 1, 1, a.id);
                            this.loadReportData(a.id);
                            break; // We only need one;
                        }
                    }
                }
            }
        },
        removeColumn() {
            if (this.comparisonColumns > this.minComparisonColumns) {
                this.comparisonColumns--;
            }
        },
        setShowCustomizePanel(value: boolean) {
            this.showCustomizePanel = value;
        },
        writeToUserClipboard(text: string) {
            navigator.clipboard.writeText(text);
        },
        handlePrint() {
            window.print();
        },
        handleExport() {
            const url = ReportUtils.reportDataToCSV(this.reportDataCache[this.assessmentId]);
            const link = document.createElement('a');
            link.download = 'AssessmentExport.csv';
            link.href = url;
            link.click();
        },
        onScroll() {
            if (this.getScrollPercent() > 10) {
                this.showCompactCards = true;
            } else {
                this.showCompactCards = false;
            }
        },
        getScrollPercent() {
            const scrollTop = document.documentElement.scrollTop;
            const docHeight = document.documentElement.scrollHeight;
            const winHeight = window.innerHeight;
            const scrollPercent = scrollTop / (docHeight - winHeight);
            return Math.round(scrollPercent * 100);
        },
    },
    async mounted() {
        this.loading = true;
        try {
            let participantAssessments = await ReportsApi.getAssessmentsByParticipantIdAndAssessmentTypes(
                this.$store.getters.viewingParticipant.id,
                DV_ASSESSMENT_TYPES,
            );
            participantAssessments = participantAssessments.filter((a: Assessment) => a.id !== this.assessmentId);
            this.participantAssessments = participantAssessments.sort((rhs: Assessment, lhs: Assessment) => {
                return rhs && lhs && new Date(rhs.dateCreated) < new Date(lhs.dateCreated) ? 1 : -1;
            });

            await this.loadReportData(this.assessmentId);
            const csp = this.reportDataCache[this.assessmentId].tests.circularSmoothPursuit;
            const fixation = this.reportDataCache[this.assessmentId].tests.fixationStability;
            const hs = this.reportDataCache[this.assessmentId].tests.horizontalSaccades;

            if (csp === null || fixation === null || hs === null) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }
        } catch (error) {
            Logger.error(`Failed to load assessments: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
        }

        this.generateComparisonDropdownList();
        this.generateSelectedComparisons();
        this.generateLeftNavLinks();

        const promises = [] as Array<Promise<void>>;
        this.selectedComparisonIds.map((id: string) => promises.push(this.loadReportData(id)));
        await Promise.all(promises);

        window.addEventListener('scroll', this.onScroll);

        this.loading = false;
    },
    async beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
});
