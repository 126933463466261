




























































import Vue, { PropType } from 'vue';

import IconStatic from '@/views/common/static/IconStatic.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';
import CreateOverride from '@/views/superAdmin/components/modals/CreateOverride.vue';

import { VersionOverride } from '@/common/types/appVersions/appVersions';
import { DataTableHeader } from 'vuetify';
import GreenButton from '@/views/common/controls/GreenButton.vue';

import AppVersionApi from '@/api/appVersions.api';
import { CreateOverrideRequest, DeleteOverrideRequest } from '@/common/types/api/requests/appVersionRequests';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

export default Vue.extend({
    components: {
        CreateOverride,
        GreenButton,
        IconStatic,
        SaveConfirmation,
    },
    props: {
        overrides: {
            type: Array as PropType<VersionOverride[]>,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        environment: {
            type: String,
            required: true,
        },
        platform: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            headers: [] as DataTableHeader[],
            selectedOverrides: [] as VersionOverride[],
            showDeleteConfirmation: false as boolean,
            showCreateOverrideModal: false as boolean,
        };
    },
    computed: {
        isDeleteEnabled(): boolean {
            return this.selectedOverrides && this.selectedOverrides.length > 0;
        },
    },
    methods: {
        generateHeaders(): DataTableHeader[] {
            const headers = [] as DataTableHeader[];
            headers.push(
                {
                    text: this.$t('appVersionManagement.overrides.id').toString(),
                    align: 'start',
                    value: 'Id',
                },
                {
                    text: this.$t('appVersionManagement.overrides.version').toString(),
                    align: 'start',
                    value: 'Version',
                },
            );
            return headers;
        },
        setShowDeleteConfirmation(show: boolean) {
            this.showDeleteConfirmation = show;
        },
        hideDeleteConfirmation() {
            this.setShowDeleteConfirmation(false);
        },
        deleteOverrides() {
            for (const override of this.selectedOverrides) {
                const deleteOverrideRequest = {
                    environment: this.environment,
                    platform: this.platform,
                    id: override.Id,
                    field: this.field,
                } as DeleteOverrideRequest;
                AppVersionApi.deleteOverride(deleteOverrideRequest);
            }
            this.setShowDeleteConfirmation(false);
        },
        async createOverride(override: VersionOverride) {
            try {
                const createOverrideRequest = {
                    environment: this.environment,
                    platform: this.platform,
                    id: override.Id,
                    version: override.Version,
                    field: this.field,
                } as CreateOverrideRequest;
                await AppVersionApi.createOverride(createOverrideRequest);
                this.overrides.push(override);
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        hideCreateOverrideModal() {
            this.setShowCreateOverrideModal(false);
        },
        setShowCreateOverrideModal(show: boolean) {
            this.showCreateOverrideModal = show;
        },
    },
    async mounted() {
        this.loading = true;
        this.headers = this.generateHeaders();
        this.loading = false;
    },
});
