

















import Vue from 'vue';

import { Position2d } from '@/common/types/reports/position';
import Iterator from '@/common/types/trainer/iterator';

import { EqtAnimation, StimulusState } from '@/common/utils/trainer/animation';

import TimerStimulus from '@/views/trainer/exercises/components/stimuli/TimerStimulus.vue';

export default Vue.extend({
    components: { TimerStimulus },
    props: {
        animations: {
            type: Array as () => EqtAnimation[],
            required: true,
        },
    },
    data() {
        return {
            stimulusState: {
                position: new Position2d(0, 0),
                radius: 0,
                isVisible: false,
                timerState: undefined,
            } as StimulusState,
            timerActivated: false,
            animationIterator: undefined as Iterator<EqtAnimation> | undefined,
            currentAnimation: undefined as EqtAnimation | undefined,
            startTime: undefined as number | undefined,
        };
    },
    methods: {
        playAnimations() {
            window.requestAnimationFrame(this.step.bind(this));
        },
        step(timestamp: number) {
            if (this.currentAnimation) {
                if (!this.startTime) {
                    this.startTime = timestamp;
                }

                const progress = timestamp - this.startTime;
                const state = this.currentAnimation.stimulusState(progress);

                if (state) {
                    this.stimulusState = state;
                    this.timerActivated = this.stimulusState.timerState !== null;
                    window.requestAnimationFrame(this.step.bind(this));
                } else {
                    this.timerActivated = false;
                    this.startTime = undefined;
                    this.currentAnimation = this.animationIterator!.next();
                    window.requestAnimationFrame(this.step.bind(this));
                }
            } else {
                this.finished();
            }
        },
        finished() {
            this.$emit('finished');
        },
    },
    mounted() {
        this.animationIterator = new Iterator(this.animations);
        this.currentAnimation = this.animationIterator.next();
        this.playAnimations();
    },
});
