const SVA_SCORE = `{ mean }`;

export const STATIC_VISUAL_ACUITY_NORMS_QUERY = `
staticVisualAcuity {
    left { svaScore ${SVA_SCORE} }
    right { svaScore ${SVA_SCORE} }
    both { svaScore ${SVA_SCORE} }
}
`;

export const STATIC_VISUAL_ACUITY_METRIC_QUERY = `
staticVisualAcuity {
    metrics {
        left {
            svaScore
            distanceFromScreenMin
            distanceFromScreenMax
        }
        right {
            svaScore
            distanceFromScreenMin
            distanceFromScreenMax
        }
        both {
            svaScore
            distanceFromScreen
            distanceFromScreenMin
            distanceFromScreenMax
        }
    }
}
`;
