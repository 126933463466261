




























import Vue, { PropType } from 'vue';

import ReactionBarVisualization, {
    ReactionBarConfig,
} from '@/views/reports/visualizations/ReactionBarVisualization.vue';

import { ReportData } from '@/common/types/reports/reportData';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        ReactionBarVisualization,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
    },
    data() {
        return {
            metrics: {} as any,
            config: undefined as ReactionBarConfig | undefined,
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.reactionTime.definition'));
            infoText.push(this.$t('reports.info.reactionTime.saccadicLatency'));
            infoText.push(this.$t('reports.info.reactionTime.visualReactionSpeed'));
            infoText.push(this.$t('reports.info.reactionTime.processingSpeed'));
            infoText.push(this.$t('reports.info.reactionTime.distractibility'));
            infoText.push(this.$t('reports.info.reactionTime.impulsivity'));

            return infoText;
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (reportData.tests.discriminateReactionTime) {
                this.metrics = reportData.tests.discriminateReactionTime.metrics;
                this.config = {
                    label: this.$t('reports.tests.discriminateReactionTime').toString(),
                    reportData,
                    metricData: reportData.tests.discriminateReactionTime.metrics,
                    testType: 'discriminateReactionTime',
                };
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
