













import Vue, { PropType } from 'vue';
export default Vue.extend({
    props: {
        options: Array as PropType<string[]>,
    },
    methods: {
        onChange(item: string) {
            this.$emit('onSelection', item);
        },
    },
});
