import companyManagement from './companyManagement';
import companyInfo from './companyInfo';
import companyModules from './companyModules';
import components from './components';
import conditions from './conditions';
import email from './email';
import trainer from './trainer';
import trainerAdmin from './trainerAdmin';
import errors from './errors';
import forms from './forms';
import login from './login';
import needHelp from './needHelp';
import participantInfo from './participantInfo';
import provider from './provider';
import reports from './reports';
import routes from './routes';
import appVersionManagement from './appVersionManagement';

export default {
    appVersionManagement,
    companyManagement,
    companyInfo,
    companyModules,
    components,
    conditions,
    email,
    trainer,
    trainerAdmin,
    errors,
    forms,
    login,
    needHelp,
    participantInfo,
    provider,
    reports,
    routes,
};
