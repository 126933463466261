


























import Vue from 'vue';
import MissingSubscription from '@/views/errors/MissingSubscription.vue';
import PageNotFound from '@/views/errors/PageNotFound.vue';
import RoleNotAllowed from '@/views/errors/RoleNotAllowed.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    props: {
        errorType: {
            type: String,
            required: true,
        },
    },
    components: {
        MissingSubscription,
        PageNotFound,
        RoleNotAllowed,
        GreenButton,
    },
    methods: {
        goHome() {
            if (this.$route.name !== 'login') {
                this.$router.push({ name: 'login' });
            }
        },
    },
});
