






import Vue from 'vue';

import Logger from '@/common/utils/logger';

import TrainerApi from '@/api/trainer.api';

import { ExerciseResponse, ExerciseType } from '@/common/types/api/responses/trainerResponses';
import { ExerciseRequest } from '@/common/types/api/requests/trainerRequests';
import { createAnimation, EqtAnimation } from '@/common/utils/trainer/animation';
import ExerciseAnimation from '@/views/trainer/exercises/components/exercise/ExerciseAnimation.vue';

import {
    CLIENT_ADMIN_ROLE,
    CLIENT_USER_ROLE,
    PARTICIPANT_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';

export default Vue.extend({
    components: { ExerciseAnimation },
    props: {
        exercise: {
            type: Object as () => ExerciseResponse,
            required: false,
        },
    },
    data() {
        return {
            started: new Date(),
            animations: [] as EqtAnimation[],
            exerciseType: undefined as ExerciseType | undefined,
        };
    },
    methods: {
        async onFinished() {
            if (this.exercise) {
                const user = this.$store.getters.currentUser as AuthenticatedUser;
                if (
                    user.role === CLIENT_ADMIN_ROLE ||
                    user.role === SUPER_ADMIN_ROLE ||
                    user.role === ENGINEERING_ROLE ||
                    user.role === CLIENT_USER_ROLE
                ) {
                    this.$router.push({ path: `/company/${user.companyId}/exercises/${this.exercise.exerciseId}` });
                } else if (user.role === PARTICIPANT_ROLE) {
                    this.$router.push({ path: `/user/${user.userId}/exercises` });
                }
            } else {
                const request: ExerciseRequest = {
                    started: this.started.toISOString(),
                    completed: new Date().toISOString(),
                    exerciseType: this.exerciseType!,
                };

                await TrainerApi.createExercise(request);
                this.$store.getters.currentWorkout.setNextExercise();
                this.$router.push('/tutorial');
            }
        },
    },
    async mounted() {
        const currentExercise = this.exercise ?? this.$store.getters.currentWorkout?.currentExercise;

        if (currentExercise?.parameters) {
            const animations = [];
            for (const parameters of currentExercise.parameters.animations) {
                animations.push(createAnimation(parameters));
            }
            this.started = new Date();
            this.exerciseType = currentExercise.exerciseType;
            this.animations = animations;
        } else {
            Logger.error('Error loading exercise');
            this.onFinished();
        }
    },
});
