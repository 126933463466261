




import Vue, { PropType } from 'vue';
import * as d3 from 'd3';
import { Position2d } from '@/common/types/reports/position';
import * as Constants from '@/common/constants/visualizations.constants';

export default Vue.extend({
    props: {
        size: Number,
        coordinates: Object as PropType<Position2d>,
        borderThickness: {
            default: 0.004,
            required: false,
            type: Number,
        },
        labelFontSize: {
            default: 0.001,
            required: false,
            type: Number,
        },
        perspectiveLabelLocation: {
            default: new Position2d(0.0, 0.0),
            required: false,
            type: Position2d,
        },
    },
    computed: {
        selection(): SVGGElement {
            return this.$refs.selection as SVGGElement;
        },
        perspectiveImageLabel(): string {
            if (this.isPatientPerspective) {
                return 'Click to show provider perspective';
            } else {
                return 'Click to show patient perspective';
            }
        },
        frontHeadImageX(): number {
            return this.coordinates.x;
        },
        backHeadImageX(): number {
            return this.coordinates.x - this.size;
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            selectionLocationX: this.coordinates.x,
            frontHeadImage: require('@/assets/head.png'),
            backHeadImage: require('@/assets/back_head.png'),
            perspectiveImage: require('@/assets/head.png'),
        };
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        generateSVG() {
            d3.select(this.selection)
                .append('image')
                .attr('href', (d) => {
                    const imagePath = this.frontHeadImage;
                    return imagePath;
                })
                .attr('x', this.coordinates.x)
                .attr('y', this.coordinates.y)
                .attr('width', this.size)
                .attr('height', this.size);

            d3.select(this.selection)
                .append('image')
                .attr('href', (d) => {
                    const imagePath = this.backHeadImage;
                    return imagePath;
                })
                .attr('x', this.coordinates.x - this.size)
                .attr('y', this.coordinates.y)
                .attr('width', this.size)
                .attr('height', this.size);

            d3.select(this.selection)
                .append('rect')
                .attr('x', this.coordinates.x - this.size)
                .attr('y', this.coordinates.y)
                .attr('width', this.size * 2.0)
                .attr('height', this.size)
                .style('fill', 'none')
                .style('stroke-width', this.borderThickness)
                .style('stroke', 'gray');

            const selectionRect = d3
                .select(this.selection)
                .append('rect')
                .attr('x', this.coordinates.x - this.size)
                .attr('y', this.coordinates.y)
                .attr('width', this.size * 2.0)
                .attr('height', this.size)
                .style('opacity', '0')
                .style('stroke-width', this.borderThickness)
                .style('stroke', 'gray');

            const perspectiveSelection = d3
                .select(this.selection)
                .append('rect')
                .attr('x', this.selectionLocationX)
                .attr('y', this.coordinates.y)
                .attr('width', this.size)
                .attr('height', this.size)
                .style('fill', 'none')
                .style('stroke-width', this.borderThickness)
                .style('stroke', Constants.GREEN);

            d3.select(this.selection)
                .append('text')
                .attr('x', this.perspectiveLabelLocation.x)
                .attr('y', this.perspectiveLabelLocation.y)
                .style('font-size', `${this.labelFontSize}em`)
                .style('font-family', 'sans-serif')
                .text('Change Perspective');

            selectionRect.on('click', () => {
                if (this.isPatientPerspective) {
                    this.selectionLocationX = this.frontHeadImageX;
                    perspectiveSelection.transition().duration(800).attr('x', this.frontHeadImageX);
                } else {
                    this.selectionLocationX = this.backHeadImageX;
                    perspectiveSelection.transition().duration(800).attr('x', this.backHeadImageX);
                }

                this.isPatientPerspective = !this.isPatientPerspective;
                this.$emit('perspectiveChanged', this.isPatientPerspective);
            });

            d3.select('body')
                .append('div')
                .attr('id', 'perspective-hover')
                .style('opacity', '0')
                .style('position', 'absolute')
                .style('background-color', 'white')
                .style('border', 'solid')
                .style('border-width', '1px')
                .style('border-radius', '5px')
                .style('padding', '10px')
                .style('z-index', '20')
                .style('font-family', 'sans-serif')
                .style('cursor', 'pointer')
                .html(`<span>${this.perspectiveImageLabel}</span>`);

            selectionRect.on('mouseover', (d: MouseEvent) => {
                d3.select('#perspective-hover')
                    .style('opacity', 1)
                    .style('left', d.pageX + 5 + 'px')
                    .style('top', d.pageY + 'px')
                    .html(`<span>${this.perspectiveImageLabel}</span>`);
            });

            selectionRect.on('mouseout', (d: MouseEvent) => {
                d3.select('#perspective-hover').style('opacity', 0);
            });
        },
    },
});
