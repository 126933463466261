var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('SearchBar',{attrs:{"search":_vm.search,"clearable":true},on:{"input":_vm.executeSearch,"clear":_vm.clearFiltersAndSearch}})],1),_c('v-col',{attrs:{"md":"auto"}},[(_vm.isEngineer || _vm.isSuperAdmin || _vm.isClientAdmin || _vm.isClientUser)?_c('SettingsDropdown',{staticClass:"dropdown-settings",on:{"showHiddenAssessments":function($event){return _vm.setShowHiddenAssessments($event)}}}):_vm._e()],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalAssessments,"loading":_vm.loading,"sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortByDesc,"options":_vm.options,"loading-text":"Loading... Please wait","item-key":"id","single-expand":"","show-expand":"","expanded":_vm.expanded,"footer-props":{
            'items-per-page-options': [10, 25, 50, 100, 500],
            'showFirstLastPage': true,
        },"item-class":_vm.rowClass},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('SelectReportMenu',{attrs:{"assessment":item}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ReportActionsMenu',{attrs:{"assessment":item},on:{"updatedAssessment":function($event){return _vm.updateAssessmentItem($event)}}})],1)],1)],1)]}},{key:"item.dateCreated",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-start add-padding",style:(_vm.getWidth())},[_vm._v(_vm._s(_vm.formatDate(item.dateCreated)))])]}},{key:"item.participant.name",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-start truncate",style:(_vm.getWidth())},[_vm._v(_vm._s(item.participant.name))])]}},{key:"item.participant.patientId",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-start truncate",style:(_vm.getWidth())},[_vm._v(_vm._s(item.participant.patientId))])]}},{key:"item.notes",fn:function(ref){
        var item = ref.item;
return [_c('EditableTextField',{style:(_vm.getWidth()),attrs:{"editable":_vm.isEngineer || _vm.isSuperAdmin || _vm.isClientAdmin,"content":item.notes,"characterLimit":249},on:{"save":function($event){item.notes = $event;
                    _vm.saveAssessmentNotes(item);}}})]}}],null,true)},[_c('ProgressBar',{attrs:{"slot":"progress","loading":_vm.loading},slot:"progress"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }