























import Vue from 'vue';

import NeedHelp from '@/views/static/NeedHelp.vue';
import TermsAndConditions from '@/views/common/static/TermsAndConditions.vue';
import PrivacyPolicy from '@/views/common/static/PrivacyPolicy.vue';

export default Vue.extend({
    components: {
        NeedHelp,
        TermsAndConditions,
        PrivacyPolicy,
    },
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        contentComponent: {
            type: String,
            required: false,
            default: '',
        },
        width: {
            type: String,
            required: false,
            default: '66%',
        },
    },
    watch: {
        show(value) {
            // Reset scrolling on close and open https://github.com/vuetifyjs/vuetify/issues/1687
            const vDialogActive: any = document.getElementsByClassName('v-dialog--active');
            if (vDialogActive && vDialogActive[0]?.scrollTop) {
                vDialogActive[0].scrollTop = 0;
            }
        },
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
    },
});
