


































import Vue from 'vue';
import ExerciseEntry from '@/views/trainer/exercises/components/workouts/ExerciseEntry.vue';
import Workout from '@/common/types/trainer/workout';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';

export default Vue.extend({
    components: { ExerciseEntry },
    computed: {
        currentUser(): AuthenticatedUser | undefined {
            return this.$store.getters.currentUser;
        },
        currentWorkout(): Workout | undefined {
            return this.$store.getters.currentWorkout;
        },
    },
});
