export default {
    environment: 'Environment',
    version: 'Version',
    labels: {
        appVersionsByEnvironment: 'Current App Versions By Environment',
        appVersions: 'All App Versions',
        manifests: 'Manifests',
        app: 'App',
        launcher: 'Launcher',
        deltav: 'DeltaV',
        cleanManifests: 'Clean Selected Manifests',
        createOverride: 'Add New Override',
        deleteOverrides: 'Delete Selected Overrides',
    },
    versions: {
        version: 'Version',
        app: 'App',
        launcher: 'Launcher',
        deltav: 'DeltaV',
        overrides: 'Overrides',
    },
    manifests: {
        environments: 'Environments',
        version: 'Version',
        name: 'Name',
        tags: 'Tags',
        dateCreated: 'Date Created',
        overrides: 'Overrides',
    },
    overrides: {
        Company: 'Company Overrides',
        System: 'System Overrides',
        EyeTrackerType: 'Eye Tracker Type Overrides',
        id: 'Id',
        version: 'Version',
        actions: 'Actions',
        delete: 'Delete',
        noData: 'No overrides configured.',
    },
    environments: {
        int: 'Int',
        qa: 'QA',
        staging: 'Staging',
        production: 'Prod',
    },
};
