




















import Vue, { PropType } from 'vue';

import * as FormatUtils from '@/common/utils/reports/format';
import * as MetricUtils from '@/common/utils/reports/metrics';

import { TestData } from '@/common/types/reports/reportData';
import { Norms, ReportNorms } from '@/common/types/reports/norms';
import { TestType } from '@/common/constants/reports.constants';
import { LabeledNorms } from '@/common/types/reports/norms';
import { MetricData } from '@/common/types/reports/metricData';
import { MetricTableField } from './MetricTable.vue';

const EXCLUDED_METRICS = ['distanceFromScreenMin', 'distanceFromScreenMax', 'left', 'right', 'both'] as string[];

export default Vue.extend({
    props: {
        gameType: String,
        normsData: Object as PropType<ReportNorms>,
        testData: Object as PropType<TestData>,
    },
    data() {
        return {
            metrics: {} as any,
            norms: [] as LabeledNorms[],
            headers: [] as any[],
            items: [] as any[],
        };
    },
    mounted() {
        this.metrics = this.testData.metrics;
        this.loadNormData();
        this.generateItems();
        this.generateHeaders();
    },
    methods: {
        metricValue(metrics: MetricData, field: string) {
            let path;

            if (!metrics) {
                return FormatUtils.formatValue(undefined);
            }

            if (typeof field === 'string') {
                path = field;
            } else {
                throw new Error('Metric field config must have a metricPath or path');
            }

            return FormatUtils.formatValue(MetricUtils.getNestedMetricValue(metrics, path));
        },
        translatedLabel(field: MetricTableField) {
            if (field === 'score' || field === 'level' || field === 'eyeType') {
                return this.$t(`reports.metrics.tests.games.${field}`);
            } else {
                return this.$t(`reports.metrics.tests.games.${this.gameType}.${field}`);
            }
        },
        loadNormData() {
            const gameTypeNorms = [] as LabeledNorms[];
            for (const [label, norms] of Object.entries(this.sortedNorms)) {
                let gameTypeNorm = undefined as LabeledNorms | undefined;
                for (const data of norms) {
                    if (this.gameType === 'bubbleBlast') {
                        if (
                            data.eyeType === this.metrics.eyeType &&
                            data.bubbleDirection === this.metrics.bubbleDirection &&
                            data.bubbleSizePercent === this.metrics.bubbleSizePercent &&
                            data.level === this.metrics.level
                        ) {
                            gameTypeNorm = { label, data };
                            break;
                        }
                    } else if (this.gameType === 'cosmosCombat') {
                        if (
                            data.eyeType === this.metrics.eyeType &&
                            data.distanceFromCenter.toString() === this.metrics.distanceFromCenter &&
                            data.level === this.metrics.level
                        ) {
                            gameTypeNorm = { label, data };
                            break;
                        }
                    } else if (this.gameType === 'mazeMaster') {
                        if (
                            data.eyeType === this.metrics.eyeType &&
                            data.gaze.toString() === this.metrics.gaze &&
                            data.level === this.metrics.level
                        ) {
                            gameTypeNorm = { label, data };
                            break;
                        }
                    } else if (this.gameType === 'spaceStorm') {
                        if (
                            data.eyeType === this.metrics.eyeType &&
                            data.distractors.toString() === this.metrics.distractors &&
                            data.ships === this.metrics.ships &&
                            data.level === this.metrics.level
                        ) {
                            gameTypeNorm = { label, data };
                            break;
                        }
                    }
                }
                if (!gameTypeNorm) {
                    gameTypeNorm = { label, data: {} as any };
                }
                gameTypeNorms.push(gameTypeNorm as LabeledNorms);
            }
            this.norms = gameTypeNorms;
        },
        generateHeaders() {
            this.headers = [
                {
                    text: 'Metrics',
                    align: 'start',
                    value: 'metricField',
                    sortable: true,
                    width: '18vw',
                },
                {
                    text: 'My Eyes',
                    align: 'start',
                    value: 'metricValue',
                    sortable: false,
                    width: '8vw',
                },
            ];
            Object.keys(this.sortedNorms).map((label) => {
                this.headers.push({
                    text: `${this.$t('reports.metrics.normsLabelPrefixes.ages')} ${label}`,
                    align: 'start',
                    value: label,
                    sortable: false,
                    width: '12vw',
                });
            });
        },
        generateItems() {
            this.items = [] as any[];
            Object.entries(this.testData.metrics).map(([field, metric]) => {
                if (!EXCLUDED_METRICS.includes(field as string)) {
                    const item = {
                        metricField: this.translatedLabel(field),
                        metricValue: FormatUtils.formatValue(metric),
                    } as any;
                    this.norms.map((a: LabeledNorms) => {
                        item[a.label] = this.metricValue(a.data, field);
                    });
                    this.items.push(item);
                }
            });
        },
    },
    watch: {
        normsData() {
            this.loadNormData();
            this.generateHeaders();
            this.generateItems();
        },
    },
    computed: {
        sortedNorms(): Record<string, Norms[]> {
            if (this.normsData[this.gameType as TestType]) {
                const sorted = this.normsData[this.gameType as TestType].games!.sort((a, b) =>
                    Number(a.label.split('-')[0]) > Number(b.label.split('-')[0]) ? 1 : -1,
                );

                const sortedNorms = {} as Record<string, Norms[]>;
                sorted.map((labeledNorm: LabeledNorms) => {
                    sortedNorms[labeledNorm.label] = labeledNorm.data;
                });
                return sortedNorms;
            } else {
                return {} as Record<string, Norms[]>;
            }
        },
    },
});
