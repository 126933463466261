












































































import Vue from 'vue';

import ApiService from '@/api/common/apiService';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import TextLink from '@/views/common/controls/TextLink.vue';
import ErrorBanner from '@/views/common/static/ErrorBanner.vue';

import { ButtonState } from '@/common/types/general';

export default Vue.extend({
    props: {
        redirect: {
            type: String,
            required: false,
        },
        email: {
            type: String,
            required: false,
        },
        errorMessage: {
            type: String,
            required: false,
        },
    },
    components: {
        GreenButton,
        LoadingCircle,
        TextLink,
        ErrorBanner,
    },
    data() {
        return {
            emailInput: '' as string,
            passwordInput: '' as string,
            passwordFieldType: 'password' as 'password' | 'text',
            showTooltip: false,
            tooltipMessage: '',
            tooltipWidth: 0,
            loading: false,
        };
    },
    computed: {
        loginButtonState(): ButtonState {
            return this.loading ? 'inactive' : 'active';
        },
    },
    methods: {
        async handleSubmit(username: string, password: string) {
            this.showTooltip = false;
            this.loading = true;
            try {
                await ApiService.login(username, password);
                if (this.redirect) {
                    this.$router.push({ path: this.redirect });
                } else {
                    this.$router.push({ name: 'home' });
                }
            } catch (error) {
                if (error && error.message) {
                    this.tooltipMessage = error.message;
                } else if (error) {
                    this.tooltipMessage = error;
                } else {
                    this.tooltipMessage = this.$t('login.errors.unknownError').toString();
                }
                this.tooltipWidth = document.getElementById('password-field')!.offsetWidth;
                this.showTooltip = true;
            } finally {
                this.loading = false;
            }
        },
        handleForgotPassword() {
            this.$router.push({ name: 'forgotPassword', params: { emailInput: this.emailInput } });
        },
        handleNeedHelp() {
            this.$router.push({ name: 'needHelp' });
        },
        flipPasswordFieldType() {
            if (this.passwordFieldType === 'text') {
                this.passwordFieldType = 'password';
            } else {
                this.passwordFieldType = 'text';
            }
        },
    },
    mounted() {
        if (this.$store.getters.currentUser) {
            // This user is already logged in
            this.$router.replace({ name: 'home' });
        }
        this.emailInput = this.email;
        if (this.errorMessage) {
            this.tooltipMessage = this.errorMessage;
            this.showTooltip = true;
        }
    },
});
