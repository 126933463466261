

























import Vue from 'vue';

import ReportsApi from '@/api/reports.api';

import Logger from '@/common/utils/logger';

import ReportFooter from '@/views/reports/components/ReportFooter.vue';
import Report from '@/views/reports/Report.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';

import { ReportData } from '@/common/types/reports/reportData';
import { reportCardsFromReportData } from '@/common/utils/reports/reports';
import PrinterFriendlySensorimotorHeader from '@/views/common/headers/PrinterFriendlySensorimotorHeader.vue';

import { ReportCardName, SENSORIMOTOR_SCREENER_TEST_TYPES } from '@/common/constants/reports.constants';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';
import { Participant } from '@/common/types/user/participant';

const REPORT_CARD_DISPLAY_ORDER = [
    'SensorimotorScreenerResult',
    'NinePointMotorFunction',
    'NearPointConvergence',
    'SymptomSurvey',
] as ReportCardName[];

export default Vue.extend({
    props: {
        assessmentId: {
            type: String,
            required: false,
        },
    },
    components: {
        Report,
        ReportFooter,
        ScreenDistanceDisplay,
        PrinterFriendlySensorimotorHeader,
    },
    data() {
        return {
            reportData: undefined as ReportData | undefined,
            cards: [] as ReportCardName[],
            REPORT_CARD_DISPLAY_ORDER,
        };
    },
    computed: {
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
        reportTitle(): string {
            return 'Sensorimotor Screener Report';
        },
        reportSubtitle(): string {
            return '9-Point Motor Function & Near Point of Convergence';
        },
    },
    async mounted() {
        try {
            this.reportData = await ReportsApi.getReportDataByAssessmentId(
                this.assessmentId,
                SENSORIMOTOR_SCREENER_TEST_TYPES,
            );
            await this.$store.dispatch(SET_VIEWING_PARTICIPANT, this.reportData.participant.id);

            this.cards = reportCardsFromReportData(this.reportData, true);
            if (this.cards.length === 0) {
                throw new Error(
                    'We could not generate a report because you did not successfully complete all required tests. Please retest.',
                );
            }

            this.cards = this.cards.sort((rhs: ReportCardName, lhs: ReportCardName) => {
                return REPORT_CARD_DISPLAY_ORDER.indexOf(rhs) > REPORT_CARD_DISPLAY_ORDER.indexOf(lhs) ? 1 : -1;
            });
        } catch (error) {
            Logger.error(`Failed to load visual data: ${error}`);
            this.$store.commit(SET_ERROR_BANNER, error.message);
            return;
        }
    },
});
