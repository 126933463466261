import { AssessmentResponse } from '@/common/types/api/responses/trainerResponses';
import PrescriptionModel from './prescriptionModel';
import * as DateUtils from '@/common/utils/date';

export default class AssessmentModel {
    private readonly id: string;
    private readonly isActive: boolean;
    private readonly dateCreated: Date;
    private prescriptions: PrescriptionModel[];
    private readonly isAssessment: boolean = true;
    private readonly assessmentTypeId: string;

    private isSelected: boolean;

    public constructor(assessmentResponse: AssessmentResponse) {
        this.id = assessmentResponse.id;
        this.isActive = assessmentResponse.active;
        this.isSelected = false;
        // response's date string is guaranteed UTC
        this.dateCreated = DateUtils.convertUTCToLocal(assessmentResponse.dateCreated);
        this.prescriptions = [];
        this.assessmentTypeId = assessmentResponse.type;
    }

    public get Id(): string {
        return this.id;
    }

    public get IsActive(): boolean {
        return this.isActive;
    }

    public get DateCreated(): Date {
        return this.dateCreated;
    }

    public get IsSelected(): boolean {
        return this.isSelected;
    }

    public set IsSelected(value: boolean) {
        this.isSelected = value;
    }

    public get Prescriptions(): PrescriptionModel[] {
        return this.prescriptions;
    }

    public get IsAssessment(): boolean {
        return this.isAssessment;
    }

    public get AssessmentTypeId(): string {
        return this.assessmentTypeId;
    }

    public AddPrescription(prescription: PrescriptionModel) {
        this.prescriptions.push(prescription);
    }
}
