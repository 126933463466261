import { TimeType } from '@/common/constants/date.constants';
import {
    isBefore,
    format,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    differenceInMonths,
    differenceInYears,
} from 'date-fns';

export function getTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function parseDate(date: string | Date): Date | undefined {
    if (!date) {
        return undefined;
    } else if (date instanceof Date) {
        return date;
    } else {
        return new Date(date);
    }
}

function formatDate(date: Date | undefined, pattern: string) {
    if (date) {
        return format(date, pattern);
    } else {
        return '';
    }
}

export function getTimeStampFormat(date: string | Date): string {
    const pattern = 'M/dd/yyyy h:mm a';
    const dateObj = parseDate(date);
    return formatDate(dateObj, pattern);
}

export function getDateFormat(date: Date | string): string {
    const pattern = 'MM/dd/yyyy';
    const dateObj = parseDate(date);
    return formatDate(convertWithTimezoneOffset(dateObj), pattern);
}

export function getDatePickerFormat(date: Date | string): string {
    const pattern = 'yyyy-MM-dd';
    const dateObj = parseDate(date);
    return formatDate(convertWithTimezoneOffset(dateObj), pattern);
}

export function getDOBFormat(dateOfBirth: Date | string): string {
    const pattern = 'MM/yyyy';
    const dateObj = parseDate(dateOfBirth);
    return formatDate(convertWithTimezoneOffset(dateObj), pattern);
}

export function getTrainerProgressStarFormat(date: Date | string): string {
    const pattern = 'MMM d';
    const dateObj = convertLocalToUTC(parseDate(date) as Date);
    return formatDate(dateObj, pattern);
}

export function getTrainerProgressFormat(date: Date | string): string {
    const pattern = 'MMMM do';
    const dateObj = convertLocalToUTC(parseDate(date) as Date);
    return formatDate(dateObj, pattern);
}

export function getLocalTimeStampFormat(date: string | Date): string {
    return getTimeStampFormat(convertUTCToLocal(date));
}

export function getAgeFromDateOfBirth(dateOfBirth: string | undefined): number {
    if (!dateOfBirth) {
        return 0;
    }

    const dob = Date.parse(dateOfBirth);
    const ageDiff = Date.now() - dob;
    const ageDate = new Date(ageDiff);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
}

export function convertUTCToLocal(date: Date | string): Date {
    const dateObj = parseDate(date);

    if (dateObj) {
        const offset = new Date().getTimezoneOffset();
        dateObj.setMinutes(dateObj.getMinutes() - offset);
        return dateObj;
    } else {
        return new Date();
    }
}

export function convertLocalToUTC(date: Date | string): Date {
    const dateObj = parseDate(date);

    if (dateObj) {
        const offset = new Date().getTimezoneOffset();
        dateObj.setMinutes(dateObj.getMinutes() + offset);
        return dateObj;
    } else {
        return new Date();
    }
}

export function convertWithTimezoneOffset(date: Date | undefined): Date | undefined {
    if (!date) {
        return undefined;
    }

    // https://www.sunzala.com/why-the-javascript-date-is-one-day-off/
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getTimeDifference(startDate: Date | string, endDate: Date | string, timeType: TimeType) {
    const date1 = parseDate(startDate);
    const date2 = parseDate(endDate);

    if (date1 && date2) {
        switch (timeType) {
            case 'seconds':
                return differenceInSeconds(date1, date2);
            case 'minutes':
                return differenceInMinutes(date1, date2);
            case 'hours':
                return differenceInHours(date1, date2);
            case 'days':
                return differenceInDays(date1, date2);
            case 'months':
                return differenceInMonths(date1, date2);
            case 'years':
                return differenceInYears(date1, date2);
        }
    }

    return 0;
}

export function addTimeToDate(dateIn: Date | string, time: number, timeType: TimeType): Date {
    const date = parseDate(dateIn);

    if (date) {
        switch (timeType) {
            case 'seconds':
                date.setSeconds(date.getSeconds() + time);
                break;
            case 'minutes':
                date.setMinutes(date.getMinutes() + time);
                break;
            case 'hours':
                date.setHours(date.getHours() + time);
                break;
            case 'days':
                date.setDate(date.getDate() + time);
                break;
            case 'months':
                date.setMonth(date.getMonth() + time);
                break;
            case 'years':
                date.setFullYear(date.getFullYear() + time);
                break;
        }

        return date;
    } else {
        return new Date();
    }
}

export function isBeforeNow(dateIn: Date | string) {
    const date = parseDate(dateIn);

    if (date) {
        return isBefore(date, new Date());
    } else {
        return false;
    }
}
