










import Vue, { PropType } from 'vue';

import { ReportData } from '@/common/types/reports/reportData';

import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';
import SensorimotorApi from '@/api/sensorimotor.api';

export default Vue.extend({
    components: {
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        forPrint: {
            default: false,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            metrics: {} as any,
            hasSymptoms: false as boolean,
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sensorimotor'));
            infoText.push(this.$t('reports.info.calibration.pupilaryDistance'));
            infoText.push(this.$t('reports.info.calibration.disparity'));
            infoText.push(this.$t('reports.info.calibration.pupilaryDiameter'));

            return infoText;
        },
        symptomText(): string {
            if (this.hasSymptoms) {
                return 'Yes';
            } else {
                return 'No';
            }
        },
    },
    methods: {
        async loadReportData(reportData: ReportData) {
            try {
                if (!reportData.tests.calibration) {
                    this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                    return;
                }

                const screenerResults = await SensorimotorApi.getScreenerResult(reportData.assessment.id);

                this.hasSymptoms = screenerResults.hasSymptoms;
            } catch (error) {
                this.$emit('error', error);
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    async mounted() {
        this.loadReportData(this.reportData);
    },
});
