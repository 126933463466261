























import Vue, { PropType } from 'vue';

import OverridesByPlatform from '@/views/superAdmin/components/tabs/OverridesByPlatform.vue';

import { AppVersions, Versions } from '@/common/types/appVersions/appVersions';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
    components: {
        OverridesByPlatform,
    },
    props: {
        appVersions: {
            type: Object as PropType<AppVersions>,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            headers: [] as DataTableHeader[],
        };
    },
    computed: {
        items(): any[] {
            return this.generateItems(this.appVersions);
        },
    },
    methods: {
        generateHeaders(): DataTableHeader[] {
            const headers = [] as DataTableHeader[];
            headers.push(
                {
                    text: this.$t('appVersionManagement.environment').toString(),
                    align: 'start',
                    value: 'environment',
                },
                {
                    text: `${this.$t('appVersionManagement.versions.app')} ${this.$t('appVersionManagement.version')}`,
                    value: 'app',
                },
                {
                    text: `${this.$t('appVersionManagement.versions.launcher')} ${this.$t(
                        'appVersionManagement.version',
                    )}`,
                    value: 'launcher',
                },
                {
                    text: `${this.$t('appVersionManagement.versions.deltav')} ${this.$t(
                        'appVersionManagement.version',
                    )}`,
                    value: 'deltav',
                },
                {
                    text: this.$t('appVersionManagement.versions.overrides').toString(),
                    value: 'data-table-expand',
                },
            );

            return headers;
        },
        generateItems(appVersions: AppVersions): any[] {
            const items = [] as any[];
            Object.entries(appVersions).forEach(([environment, versions]: [string, Versions]) => {
                const item = {
                    environment,
                    app: versions.app,
                    launcher: versions.launcher,
                    deltav: versions.deltav,
                    appOverrides: versions.appOverrides,
                    launcherOverrides: versions.launcherOverrides,
                    deltavOverrides: versions.deltavOverrides,
                };
                items.push(item);
            });
            return items;
        },
    },
    async mounted() {
        this.loading = true;
        this.headers = this.generateHeaders();
        this.loading = false;
        this.$emit('loaded');
    },
});
