import ScreenData from '@/common/types/reports/screenData';
import { Position2d } from '@/common/types/reports/position';

export const RIGHT = 'right';
export const LEFT = 'left';
export const RIGHT_COLOR = '#166888';
export const LEFT_COLOR = '#2FAECC';

export const GREEN = '#94D500';
export const YELLOW = '#E2D627';
export const ORANGE = '#F4B220';
export const RED = '#C93D4F';
export const GREY = '#C7C7C7';
export const DARK_GREY = '#a3aaad';
export const BLUE = '#00a1de';
export const BLACK = '#00000';

export const SCREEN_DATA = {
    I15: {
        centimetersFromScreen: 56 as number,
        dpi: 85.33 as number,
        height: 768 as number,
        width: 1024 as number,
    } as ScreenData,
    Generic60Cm: {
        centimetersFromScreen: 60 as number,
        dpi: 91.79 as number,
        height: 1080 as number,
        width: 1920 as number,
    } as ScreenData,
} as Record<string, ScreenData>;

export const screenData = SCREEN_DATA.I15;

export const LEFT_GAZE_TRAIL_ID = 'leftGazeLine';
export const RIGHT_GAZE_TRAIL_ID = 'rightGazeLine';

export const AREA_OF_INTEREST_RADIUS_1 = 0.7;
export const AREA_OF_INTEREST_RADIUS_2 = 1.2;
export const AREA_OF_INTEREST_RADIUS_3 = 2.2;

export const TEXT_FONT_FAMILY = 'ProximaNova';
export const EYE_LABEL_FONT_SIZE = 0.003;

export const ORIGIN = new Position2d(0.5, 0.5);

export const STROKE_DASH_LENGTH = 0.0004;
