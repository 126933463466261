import { ExerciseName } from '@/common/types/api/responses/trainerResponses';

export interface WorkoutPlan {
    id: string;
    name: string;
}

export interface WorkoutPlanModel {
    id: string;
    name: string;
    description: string;
    example: string;
    exercises: [ExerciseName, ExerciseName, ExerciseName];
}

export interface ExerciseModel {
    id: string;
    name: string;
    displayName: string;
}

export type ProgramStatus = 'ASSESSED' | 'ACTIVE' | 'CYCLE_ENDED' | 'NOT_STARTED' | 'COMPLETE';

export const WORKOUT_PLANS = ['Switch_Hit', 'Mix_It_Up', 'Go_Steady'];
