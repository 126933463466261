

























import Vue, { PropType } from 'vue';

import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ReportData } from '@/common/types/reports/reportData';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import { ReportNorms } from '@/common/types/reports/norms';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            metrics: undefined as any,
            fields: ['srt'] as string[],
        };
    },
    computed: {
        norms(): any {
            return this.normsData.simpleReactionTime;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.reactionTime.definition'));

            return infoText;
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (reportData.tests.simpleReactionTime) {
                this.metrics = reportData.tests.simpleReactionTime.metrics;
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
