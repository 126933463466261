export default {
    createCompany: 'Create New Company',
    itemsPerPage: 'Items per page: ',
    buttons: {
        newCompany: 'New Company',
        createCompany: 'Create Company',
    },
    companyCreated: 'New Company Created',
    companyConfirmation: 'New company has been created. You can access this company via the company management page.',
};
