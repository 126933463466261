


































import Vue, { PropType } from 'vue';

import TagChip from '@/views/common/static/TagChip.vue';
import OverridesByEnvironment from '@/views/superAdmin/components/tabs/OverridesByEnvironment.vue';

import { AppVersionManifest, AppVersions } from '@/common/types/appVersions/appVersions';
import { DataTableHeader } from 'vuetify';

import * as ColorConstants from '@/common/constants/visualizations.constants';

import * as DateUtils from '@/common/utils/date';

export default Vue.extend({
    props: {
        manifests: {
            type: Array as PropType<AppVersionManifest[]>,
            required: true,
        },
        appVersions: {
            type: Object as PropType<AppVersions>,
            required: true,
        },
    },
    components: {
        OverridesByEnvironment,
        TagChip,
    },
    data() {
        return {
            loading: false,
            headers: [] as DataTableHeader[],
            selectedManifests: [] as AppVersionManifest[],
            ColorConstants,
            expanded: [] as AppVersionManifest[],
        };
    },
    watch: {
        manifests(newValue: AppVersionManifest[]) {
            this.loading = true;
            this.setDeployedEnvironmentsByVersion(newValue, this.appVersions);
            this.$emit('loaded');
            this.loading = false;
        },
        selectedManifests(newValue: AppVersionManifest[]) {
            this.$emit('manifestSelected', newValue);
        },
    },
    methods: {
        generateHeaders(): DataTableHeader[] {
            const headers = [] as DataTableHeader[];
            headers.push(
                {
                    text: this.$t('appVersionManagement.manifests.environments').toString(),
                    align: 'start',
                    value: 'Environments',
                },
                {
                    text: this.$t('appVersionManagement.manifests.name').toString(),
                    align: 'start',
                    value: 'Name',
                },
                {
                    text: this.$t('appVersionManagement.manifests.version').toString(),
                    align: 'start',
                    value: 'Version',
                },
                {
                    text: this.$t('appVersionManagement.manifests.dateCreated').toString(),
                    align: 'start',
                    value: 'DateCreated',
                },
                {
                    text: this.$t('appVersionManagement.manifests.tags').toString(),
                    align: 'start',
                    value: 'Tags',
                },
                {
                    text: this.$t('appVersionManagement.manifests.overrides').toString(),
                    value: 'data-table-expand',
                    sortable: true,
                    width: 2,
                },
            );
            return headers;
        },
        formatDate(date: string) {
            date = DateUtils.getLocalTimeStampFormat(date);
            return date;
        },
        setDeployedEnvironmentsByVersion(manifests: AppVersionManifest[], appVersions: AppVersions) {
            for (const manifest of manifests) {
                const version = manifest.Version;
                manifest.Environments = [];
                for (const [environment, versions] of Object.entries(appVersions)) {
                    for (const appVersion of Object.values(versions)) {
                        if (appVersion === version) {
                            manifest.Environments.push(environment);
                        }
                    }
                }
            }
        },
    },
    async mounted() {
        this.loading = true;
        this.setDeployedEnvironmentsByVersion(this.manifests, this.appVersions);
        this.headers = this.generateHeaders();
    },
});
