













import Vue, { PropType } from 'vue';

import * as d3 from 'd3';
import * as Constants from '@/common/constants/visualizations.constants';
import * as MathUtils from '@/common/utils/math';
import { ProcessedGazePoint, ProcessedVisualData } from '@/common/types/reports/visualData';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { Position2d } from '@/common/types/reports/position';
import PerspectiveImageVisualization from '@/views/reports/visualizations/PerspectiveImageVisualization.vue';

const DEGREES_FROM_CENTER = 10.85;

export interface HorizontalSaccadesConfig {
    testData: ProcessedVisualData;
    systemType: string;
}

export default Vue.extend({
    components: {
        PerspectiveImageVisualization,
    },
    props: {
        playAnimation: Boolean,
        config: {
            type: Object as PropType<HorizontalSaccadesConfig>,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            visualization: new Visualization(),
        };
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        perspectiveImageSize(): number {
            return 0.1;
        },
        perspectiveImageLocation(): Position2d {
            return new Position2d(0.87, 0.61);
        },
        labelFontSize(): number {
            return 0.0018;
        },
        perspectiveLabelLocation(): Position2d {
            return new Position2d(0.74, 0.74);
        },
        showAdditionalViz(): boolean {
            return this.reportType === 'standard' || this.reportType === 'sensorimotorExam';
        },
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const ASPECT_RATIO = screenData.width / screenData.height;
            const ZOOM_LEVEL = this.config.systemType === 'I15' ? 1 : ASPECT_RATIO;

            // 'Area of Interest'
            const AOI_RADIUS_1 = MathUtils.degreesToNormalized(Constants.AREA_OF_INTEREST_RADIUS_1, screenData);
            const AOI_RADIUS_2 = MathUtils.degreesToNormalized(Constants.AREA_OF_INTEREST_RADIUS_2, screenData);
            const AOI_RADIUS_3 = MathUtils.degreesToNormalized(Constants.AREA_OF_INTEREST_RADIUS_3, screenData);

            const LEFT_AOI_OFFEST = MathUtils.degreesToNormalized(DEGREES_FROM_CENTER, screenData);
            const RIGHT_AOI_OFFEST = -MathUtils.degreesToNormalized(DEGREES_FROM_CENTER, screenData);

            const REFERENCE_DEFAULT_ATTRIBUTES = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.003 / ZOOM_LEVEL,
            };

            const DATA_DEFAULT_ATTRIBUTES = {
                'fill': 'none',
                'stroke-width': 0.003 / ZOOM_LEVEL,
                'stroke-opacity': 0.7,
            };

            const leftPoints = this.isPatientPerspective
                ? this.invertPoints(this.config.testData.left.points)
                : this.config.testData.left.points;
            const rightPoints = this.isPatientPerspective
                ? this.invertPoints(this.config.testData.right.points)
                : this.config.testData.right.points;

            const EYES = [
                {
                    DATA: rightPoints,
                    OFFSET: -0.15,
                    COLOR: Constants.RIGHT_COLOR,
                    ID: Constants.RIGHT_GAZE_TRAIL_ID,
                    LABEL: this.$t('reports.metrics.eye.right').toString(),
                    LABEL_OFFSET: -0.4,
                },
                {
                    DATA: leftPoints,
                    OFFSET: 0.15,
                    COLOR: Constants.LEFT_COLOR,
                    ID: Constants.LEFT_GAZE_TRAIL_ID,
                    LABEL: this.$t('reports.metrics.eye.left').toString(),
                    LABEL_OFFSET: 0.4,
                },
            ];

            const visualization = this.visualization;
            for (const eye of EYES) {
                visualization.circle({
                    center: [Constants.ORIGIN.x + LEFT_AOI_OFFEST, Constants.ORIGIN.y + eye.OFFSET / ZOOM_LEVEL],
                    radius: AOI_RADIUS_1,
                    aspectRatio: ASPECT_RATIO,
                    attributes: REFERENCE_DEFAULT_ATTRIBUTES,
                });
                visualization.circle({
                    center: [Constants.ORIGIN.x + LEFT_AOI_OFFEST, Constants.ORIGIN.y + eye.OFFSET / ZOOM_LEVEL],
                    radius: AOI_RADIUS_2,
                    aspectRatio: ASPECT_RATIO,
                    attributes: REFERENCE_DEFAULT_ATTRIBUTES,
                });
                visualization.circle({
                    center: [Constants.ORIGIN.x + LEFT_AOI_OFFEST, Constants.ORIGIN.y + eye.OFFSET / ZOOM_LEVEL],
                    radius: AOI_RADIUS_3,
                    aspectRatio: ASPECT_RATIO,
                    attributes: REFERENCE_DEFAULT_ATTRIBUTES,
                });
                visualization.circle({
                    center: [Constants.ORIGIN.x + RIGHT_AOI_OFFEST, Constants.ORIGIN.y + eye.OFFSET / ZOOM_LEVEL],
                    radius: AOI_RADIUS_1,
                    aspectRatio: ASPECT_RATIO,
                    attributes: REFERENCE_DEFAULT_ATTRIBUTES,
                });
                visualization.circle({
                    center: [Constants.ORIGIN.x + RIGHT_AOI_OFFEST, Constants.ORIGIN.y + eye.OFFSET / ZOOM_LEVEL],
                    radius: AOI_RADIUS_2,
                    aspectRatio: ASPECT_RATIO,
                    attributes: REFERENCE_DEFAULT_ATTRIBUTES,
                });
                visualization.circle({
                    center: [Constants.ORIGIN.x + RIGHT_AOI_OFFEST, Constants.ORIGIN.y + eye.OFFSET / ZOOM_LEVEL],
                    radius: AOI_RADIUS_3,
                    aspectRatio: ASPECT_RATIO,
                    attributes: REFERENCE_DEFAULT_ATTRIBUTES,
                });
                visualization.path({
                    points: eye.DATA,
                    aspectRatio: ASPECT_RATIO,
                    id: eye.ID,
                    translate: new Position2d(0, eye.OFFSET / ZOOM_LEVEL),
                    attributes: {
                        ...DATA_DEFAULT_ATTRIBUTES,
                        stroke: eye.COLOR,
                    },
                });
                visualization.text({
                    content: eye.LABEL,
                    position: [Constants.ORIGIN.x, Constants.ORIGIN.y + eye.LABEL_OFFSET / ZOOM_LEVEL],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${Constants.EYE_LABEL_FONT_SIZE / ZOOM_LEVEL}em`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': eye.COLOR,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                });
            }
            visualization.zoom(ZOOM_LEVEL);
            visualization.drawNormalized(this.svg, ASPECT_RATIO);
        },
        playGazeAnimation() {
            const pathSelection = d3.select(this.svg).selectAll('path');
            const totalLength = [] as any[];
            pathSelection.nodes().forEach((n: any) => {
                totalLength.push(n.getTotalLength());
            });

            pathSelection
                .attr('stroke-dasharray', (d: any, i: any) => totalLength[i])
                .attr('stroke-dashoffset', (d: any, i: any) => totalLength[i])
                .transition()
                .duration((d: any) => d.duration)
                .delay((d: any) => d.delay)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0)
                .end()
                .then(() => this.$emit('animationFinished'));
        },
        redraw() {
            this.visualization.clear();
            this.generateSVG();
        },
        changePerspective(isPatientPerspective: boolean) {
            this.isPatientPerspective = isPatientPerspective;
            this.redraw();
        },
        invertPoints(points: ProcessedGazePoint[]): ProcessedGazePoint[] {
            const invertedPoints = [];
            for (const point of points) {
                const gazePoint = {
                    startPosition: new Position2d(1 - point.startPosition.x, point.startPosition.y),
                    stopPosition: new Position2d(1 - point.stopPosition.x, point.stopPosition.y),
                    duration: point.duration,
                    delay: point.delay,
                    phoria: point.phoria,
                    zDistance: point.zDistance,
                };

                invertedPoints.push(gazePoint);
            }

            return invertedPoints;
        },
    },
    watch: {
        playAnimation(val: boolean) {
            if (val) {
                this.playGazeAnimation();
            }
        },
    },
});
