export default {
    cards: {
        titles: {
            Antisaccades: 'Antisaccades',
            Prosaccades: 'Prosaccades',
            Calibration: 'Nine Point Motor Function',
            CalibrationPrint: 'Nine Point Motor Function (Exam)',
            CircularSmoothPursuit: 'Circular Smooth Pursuit',
            HorizontalSmoothPursuit: 'Horizontal Smooth Pursuit',
            VerticalSmoothPursuit: 'Vertical Smooth Pursuit',
            HorizontalSaccades: 'Horizontal Saccades',
            VerticalSaccades: 'Vertical Saccades',
            ChoiceReactionTime: 'Choice Reaction Time',
            DiscriminateReactionTime: 'Discriminate Reaction Time',
            SimpleReactionTime: 'Simple Reaction Time',
            StaticVisualAcuity: 'Static Visual Acuity',
            DynamicVisualAcuity1: 'Dynamic Visual Acuity 1',
            DynamicVisualAcuity2: 'Dynamic Visual Acuity 2',
            DynamicVisualAcuity3: 'Dynamic Visual Acuity 3',
            CardinalGazePosition: 'Cardinal Gaze Position',
            ContrastSensitivity: 'Contrast Sensitivity',
            FixationStability: 'Fixation Stability',
            HorizontalGapSaccades: 'Horizontal Gap Saccades',
            BubbleBlast: 'Bubble Blast',
            CosmosCombat: 'Cosmos Combat',
            MazeMaster: 'Maze Master',
            SpaceStorm: 'Space Storm',
            SaccadicLatency: 'Saccadic Latency',
            CalibrationDynamicVision: 'Sensorimotor',
            CircularSmoothPursuitDynamicVision: 'Circular Smooth Pursuit',
            FixationStabilityDynamicVision: 'Fixation Stability',
            HorizontalSaccadesDynamicVision: 'Horizontal Saccades',
            HorizontalSmoothPursuitDynamicVision: 'Horizontal Smooth Pursuit',
            VerticalSaccadesDynamicVision: 'Vertical Saccades',
            VerticalSmoothPursuitDynamicVision: 'Vertical Smooth Pursuit',
            SaccadicLatencyDynamicVision: 'Saccadic Latency',
            ScoreDynamicVision: 'My Score',
            AccuracyScoreDynamicVision: 'My Accuracy Score',
            OverallScoreDynamicVision: 'My Score',
            ReferenceDataScore: 'Reference Data Score',
            EyeHandReactionTimeDynamicVision: 'Eye-Hand Reaction Time',
            ChoiceReactionTimeDynamicVision: 'Choice Reaction Time',
            DiscriminateReactionTimeDynamicVision: 'Discriminate Reaction Time',
            BrainReferenceDynamicVision: 'Reference Information',
            IndividualScoreDynamicVisionPursuits: 'Pursuits Score',
            IndividualScoreDynamicVisionFixations: 'Fixations Score',
            IndividualScoreDynamicVisionSaccades: 'Saccades Score',
            NinePointMotorFunction: 'Nine Point Motor Function',
            NinePointMotorFunctionPrint: 'Nine Point Motor Function (Screener)',
            NearPointConvergence: 'Near Point Convergence',
            FourDotFusion: 'Four Dot Fusion',
            SensorimotorScreenerResult: 'Screener Results',
            ConditionsRecommendations: 'Interpretations & Recommendations',
            SurveyCard: 'CISS',
            SymptomSurvey: 'Binocular Vision Disorder Symptom Question',
        },
        error: 'This assessment does not have any data for this test.',
    },
    controls: {
        play: 'Play',
        assessment: 'Assessment:',
    },
    disclaimer: {
        header: 'DISCLAIMER',
        body:
            'The RightEye Vision System is designed to provide information based on involuntary eye movements for general health and wellness. The RightEye Vision System cannot replace your evaluation. Nor can the RightEye Vision be used to provide an uninterpreted diagnosis or direct treatment recommendations.',
    },
    metrics: {
        metrics: 'Metrics',
        myEyes: 'My Eyes',
        subMetrics: 'Sub-Metrics',
        eye: {
            left: 'Left Eye',
            right: 'Right Eye',
            both: 'Both Eyes',
        },
        normsLabelPrefixes: {
            ages: 'Ages',
            mlb: 'MLB Category',
        },
        noData: 'Data is not available for this metric',
        actual: 'Actual',
        tests: {
            calibration: {
                pupilaryDistance: 'Pupillary Distance (mm)',
                disparity: 'Disparity Between Eyes (D)',
                midlinePrimary: 'Midline Primary',
                midlineLeft: 'Midline Left',
                midlineRight: 'Midline Right',
                superiorLeft: 'Superior Left',
                superiorRight: 'Superior Right',
                superiorMidline: 'Superior Midline',
                inferiorLeft: 'Inferior Left',
                inferiorRight: 'Inferior Right',
                inferiorMidline: 'Inferior Midline',
                horizontalDisplacementLeft: 'Horizontal Displacement Left Eye (D)',
                horizontalDisplacementRight: 'Horizontal Displacement Right Eye (D)',
                verticalDisplacementLeft: 'Vertical Displacement Left Eye (D)',
                verticalDisplacementRight: 'Vertical Displacement Right Eye (D)',
                meanPupilDiameter: 'Mean Pupil Diameter (mm)',
                right: 'Right Eye',
                left: 'Left Eye',
                both: 'Both Eyes',
                myEyes: 'My Eyes',
                pupilaryMetrics: 'Pupilary Metrics',
                disparityMetrics: 'Disparity Metrics (D)',
                horizontalDisplacementMetrics: 'Horizontal Displacement Metrics',
                verticalDisplacementMetrics: 'Vertical Displacement Metrics',
                pupilDiameterMetrics: 'Pupil Diameter Metrics',
            },
            circularSmoothPursuit: {
                spPercent: 'Smooth Pursuit (%)',
                spEfficiency: 'Efficiency (mm)',
                saccadePercent: 'Saccade (%)',
                fixationPercent: 'Fixation (%)',
                eyeTargetVelErr: 'Eye Target Velocity Error (dps)',
                horizontalSynchronizationSp: 'Horizontal Synchronization SP (0-1)',
                verticalSynchronizationSp: 'Vertical Synchronization SP (0-1)',
                onTargetSpPercent: 'On Target Smooth Pursuit (%)',
                predictiveSpPercent: 'Predictive Smooth Pursuit (%)',
                latentSpPercent: 'Latent Smooth Pursuit (%)',
                backupSaccadePercent: 'Backup Saccade (%)',
                catchupSaccadePercent: 'Catchup Saccade (%)',
                intrusions: 'Intrusion (#)',
                dynamicVision: {
                    spPercent: 'SP (%)',
                    spEfficiency: 'Efficiency (mm)',
                },
            },
            horizontalSmoothPursuit: {
                spPercent: 'Smooth Pursuit (%)',
                spEfficiency: 'Efficiency (mm)',
                saccadePercent: 'Saccade (%)',
                fixationPercent: 'Fixation (%)',
                eyeTargetVelErr: 'Eye Target Velocity Error (dps)',
                horizontalSynchronizationSp: 'Horizontal Synchronization SP (0-1)',
                verticalSynchronizationSp: 'Vertical Synchronization SP (0-1)',
                onTargetSpPercent: 'On Target Smooth Pursuit (%)',
                predictiveSpPercent: 'Predictive Smooth Pursuit (%)',
                latentSpPercent: 'Latent Smooth Pursuit (%)',
                backupSaccadePercent: 'Backup Saccade (%)',
                catchupSaccadePercent: 'Catchup Saccade (%)',
                intrusions: 'Intrusion (#)',
                pathwayLengthDiffLeftSide: 'Pathway Length Difference - Left (mm)',
                pathwayLengthDiffRightSide: 'Pathway Length Difference - Right (mm)',
                dynamicVision: {
                    spPercent: 'SP (%)',
                    spEfficiency: 'Efficiency (mm)',
                },
            },
            verticalSmoothPursuit: {
                spPercent: 'Smooth Pursuit (%)',
                spEfficiency: 'Efficiency (mm)',
                saccadePercent: 'Saccade (%)',
                fixationPercent: 'Fixation (%)',
                eyeTargetVelErr: 'Eye Target Velocity Error (dps)',
                horizontalSynchronizationSp: 'Horizontal Synchronization SP (0-1)',
                verticalSynchronizationSp: 'Vertical Synchronization SP (0-1)',
                onTargetSpPercent: 'On Target Smooth Pursuit (%)',
                predictiveSpPercent: 'Predictive Smooth Pursuit (%)',
                latentSpPercent: 'Latent Smooth Pursuit (%)',
                backupSaccadePercent: 'Backup Saccade (%)',
                catchupSaccadePercent: 'Catchup Saccade (%)',
                intrusions: 'Intrusion (#)',
                pathwayLengthDiffTopSide: 'Pathway Length Difference - Top (mm)',
                pathwayLengthDiffBottomSide: 'Pathway Length Difference - Bottom (mm)',
                dynamicVision: {
                    spPercent: 'SP (%)',
                    spEfficiency: 'Efficiency (mm)',
                },
            },
            horizontalSaccades: {
                saccadeNum: 'Saccade (#)',
                fixationNum: 'Fixation (#)',
                onTarget: 'On Target (#) (9mm)',
                band2Over: 'Overshot Target (#) (9-18mm)',
                band2Under: 'Undershot Target (#) (9-18mm)',
                band3Over: 'Overshot Target (#) (18-36mm)',
                band3Under: 'Undershot Target (#) (18-36mm)',
                missed: 'Missed (#) (> 36m)',
                saccadicEfficiency: 'Saccadic Efficiency (mm)',
                saccadicTargeting: 'Saccadic Targeting (mm)',
                speedAccuracyTradeoff: 'Speed/Accuracy Trade-off (dps/mm)',
                saccadicRecovery: 'Saccadic Recovery (mm)',
                saccadicVariation: 'Saccadic Variance (mm)',
                saccadicVelocity: 'Saccadic Velocity (d/s)',
                dynamicVision: {
                    saccadicTargeting: 'TA (mm)',
                    saccadicVelocity: 'SPEED (d/s)',
                },
            },
            verticalSaccades: {
                saccadeNum: 'Saccade (#)',
                fixationNum: 'Fixation (#)',
                onTarget: 'On Target (#) (9mm)',
                band2Over: 'Overshot Target (#) (9-18mm)',
                band2Under: 'Undershot Target (#) (9-18mm)',
                band3Over: 'Overshot Target (#) (18-36mm)',
                band3Under: 'Undershot Target (#) (18-36mm)',
                missed: 'Missed (#) (> 36m)',
                saccadicEfficiency: 'Saccadic Efficiency (mm)',
                saccadicTargeting: 'Saccadic Targeting (mm)',
                speedAccuracyTradeoff: 'Speed/Accuracy Trade-off (dps/mm)',
                saccadicRecovery: 'Saccadic Recovery (mm)',
                saccadicVariation: 'Saccadic Variance (mm)',
                saccadicVelocity: 'Saccadic Velocity (d/s)',
                dynamicVision: {
                    saccadicTargeting: 'TA (mm)',
                    saccadicVelocity: 'SPEED (d/s)',
                },
            },
            simpleReactionTime: {
                srt: 'Simple Reaction Time (ms)',
            },
            choiceReactionTime: {
                saccadicLatency: 'Saccadic Latency (ms)',
                visualReactionSpeed: 'Visual Reaction Speed (ms)',
                processingSpeed: 'Processing Speed (ms)',
                reactionTime: 'Reaction Time (ms)',
                responseAccuracy: 'Response Accuracy (%)',
                distractibility: 'Distractibility (#)',
                impulsivity: 'Impulsivity (#)',
            },
            discriminateReactionTime: {
                saccadicLatency: 'Saccadic Latency (ms)',
                visualReactionSpeed: 'Visual Reaction Speed (ms)',
                processingSpeed: 'Processing Speed (ms)',
                reactionTime: 'Reaction Time (ms)',
                responseAccuracy: 'Response Accuracy (%)',
                distractibility: 'Distractibility (#)',
                impulsivity: 'Impulsivity (#)',
            },
            staticVisualAcuity: {
                svaScore: 'Static Visual Acuity',
            },
            dynamicVisualAcuity1: {
                dvaScore: 'Dynamic Visual Acuity (20-400)',
            },
            dynamicVisualAcuity2: {
                speed: 'Speed (mph)',
                reactionTime: 'Reaction Time (ms)',
            },
            dynamicVisualAcuity3: {
                speed: 'Speed (mph)',
                reactionTime: 'Reaction Time (ms)',
            },
            cardinalGazePosition: {
                saccadicLatency: 'Saccadic Latency (ms)',
                saccadicLatencyStats: 'Saccadic Latency (ms)',
                up: 'Up',
                right: 'Right',
                down: 'Down',
                left: 'Left',
                superiorLeft: 'Superior Left',
                superiorRight: 'Superior Right',
                inferiorLeft: 'Inferior Left',
                inferiorRight: 'Inferior Right',
                visualReactionSpeed: 'Visual Reaction Speed (ms)',
                vrt: 'Visual Reaction Speed (ms)',
                cgpRank: 'Cardinal Gaze Position',
                disparity: 'Disparity (D)',
                targetingDisplacement: 'Accuracy (D)',
                average: 'Average',
                min: 'Min',
                max: 'Max',
            },
            fixationStability: {
                fixationLocationAggregate: 'Fixation Location (%)',
                fixationStabilityAggregate: 'Fixation Stability (%)',
                bceaAggregate: 'Bivariate Contour Ellipse Area - BCEA (pixels squared)',
                gazePositionsBand1Aggregate: 'Gaze Position Band 1 (≤ 1°)',
                gazePositionsBand2Aggregate: 'Gaze Position Band 2 (> 1° and ≤ 2°)',
                gazePositionsBand3Aggregate: 'Gaze Position Band 3 (> 2° and ≤ 4°)',
                gazePositionsBand4Aggregate: 'Gaze Position Band 4 (> 4°)',
                depth: 'Depth (+/- mm)',
                fixationDispersion: 'Fixation Dispersion (mm)',
                dynamicVision: {
                    gazePositionsBand1Aggregate: '≤ 1°',
                    gazePositionsBand2Aggregate: '> 1° and ≤ 2°',
                    gazePositionsBand3Aggregate: '> 2° and ≤ 4°',
                    gazePositionsBand4Aggregate: '> 4°',
                    fixationDispersion: 'Dispersion (mm)',
                },
                left: {
                    fixationLocationAggregate: 'Fixation Location - Left Eye (%)',
                    fixationStabilityAggregate: 'Fixation Stability - Left Eye (%)',
                    fixationDispersion: 'Fixation Dispersion - Left Eye (mm)',
                    gazePositionsBand1Aggregate: 'Gaze Position Band 1 (≤ 1°) - Left Eye',
                    gazePositionsBand2Aggregate: 'Gaze Position Band 2 (> 1° and ≤ 2°) - Left Eye',
                    gazePositionsBand3Aggregate: 'Gaze Position Band 3 (> 2° and ≤ 4°) - Left Eye',
                    gazePositionsBand4Aggregate: 'Gaze Position Band 4 (> 4°) - Left Eye',
                },
                right: {
                    fixationLocationAggregate: 'Fixation Location - Right Eye (%)',
                    fixationStabilityAggregate: 'Fixation Stability - Right Eye (%)',
                    fixationDispersion: 'Fixation Dispersion - Right Eye (mm)',
                    gazePositionsBand1Aggregate: 'Gaze Position Band 1 (≤ 1°) - Right Eye',
                    gazePositionsBand2Aggregate: 'Gaze Position Band 2 (> 1° and ≤ 2°) - Right Eye',
                    gazePositionsBand3Aggregate: 'Gaze Position Band 3 (> 2° and ≤ 4°) - Right Eye',
                    gazePositionsBand4Aggregate: 'Gaze Position Band 4 (> 4°) - Right Eye',
                },
            },
            contrastSensitivity: {
                cyclesPerDegree3: '3 Cycles Per Degree (1-8)',
                cyclesPerDegree6: '6 Cycles Per Degree (1-8)',
                cyclesPerDegree12: '12 Cycles Per Degree (1-8)',
                cyclesPerDegree18: '18 Cycles Per Degree (1-8)',
            },
            randomHorizontalSaccades: {},
            horizontalGapSaccades: {
                nystagmusDuration0Degrees: {
                    fast: {
                        mean: '0 degrees center duration fast phase (Mean)',
                        stdDev: '0 degrees center duration fast phase (SD)',
                    },
                    slow: {
                        mean: '0 degrees center duration slow phase (Mean)',
                        stdDev: '0 degrees center duration slow phase (SD)',
                    },
                },
                nystagmusDuration20DegreesLeft: {
                    fast: {
                        mean: '20 degrees left duration fast phase (Mean)',
                        stdDev: '20 degrees left duration fast phase (SD)',
                    },
                    slow: {
                        mean: '20 degrees left duration slow phase (Mean)',
                        stdDev: '20 degrees left duration slow phase (SD)',
                    },
                },
                nystagmusDuration20DegreesRight: {
                    fast: {
                        mean: '20 degrees right duration fast phase (Mean)',
                        stdDev: '20 degrees right duration fast phase (SD)',
                    },
                    slow: {
                        mean: '20 degrees right duration slow phase (Mean)',
                        stdDev: '20 degrees right duration slow phase (SD)',
                    },
                },
                nystagmusDuration23DegreesLeft: {
                    fast: {
                        mean: '23 degrees left duration fast phase (Mean)',
                        stdDev: '23 degrees left duration fast phase (SD)',
                    },
                    slow: {
                        mean: '23 degrees left duration slow phase (Mean)',
                        stdDev: '23 degrees left duration slow phase (SD)',
                    },
                },
                nystagmusVelocity0Degrees: {
                    fast: {
                        mean: '0 degrees center velocity fast phase (Mean)',
                        stdDev: '0 degrees center velocity fast phase (SD)',
                    },
                    slow: {
                        mean: '0 degrees center velocity slow phase (Mean)',
                        stdDev: '0 degrees center velocity slow phase (SD)',
                    },
                },
                nystagmusVelocity20DegreesLeft: {
                    fast: {
                        mean: '20 degrees left velocity fast phase (Mean)',
                        stdDev: '20 degrees left velocity fast phase (SD)',
                    },
                    slow: {
                        mean: '20 degrees left velocity slow phase (Mean)',
                        stdDev: '20 degrees left velocity slow phase (SD)',
                    },
                },
                nystagmusVelocity20DegreesRight: {
                    fast: {
                        mean: '20 degrees right velocity fast phase (Mean)',
                        stdDev: '20 degrees right velocity fast phase (SD)',
                    },
                    slow: {
                        mean: '20 degrees right velocity slow phase (Mean)',
                        stdDev: '20 degrees right velocity slow phase (SD)',
                    },
                },
                nystagmusVelocity23DegreesLeft: {
                    fast: {
                        mean: '23 degrees left velocity fast phase (Mean)',
                        stdDev: '23 degrees left velocity fast phase (SD)',
                    },
                    slow: {
                        mean: '23 degrees left velocity slow phase (Mean)',
                        stdDev: '23 degrees left velocity slow phase (SD)',
                    },
                },
            },
            games: {
                trial: 'Trial',
                score: 'Score (#)',
                level: 'Level (#)',
                eyeType: 'Eye Type',
                distanceFromScreen: 'Distance From Screen',
                bubbleBlast: {
                    bubbleSizePercent: 'Bubble Size (%)',
                    bubbleDirection: 'Bubble Direction',
                    smoothPursuitPercent: 'Smooth Pursuit (%)',
                },
                cosmosCombat: {
                    distanceFromCenter: 'Distance From Center',
                    accuracyPercent: 'Accuracy (%)',
                    speed: 'Speed (ms)',
                },
                mazeMaster: {
                    gaze: 'Active Gaze',
                    totalGameTime: 'Total Game Time (sec)',
                },
                spaceStorm: {
                    ships: 'Number of Ships',
                    distractors: 'Distractors',
                },
            },
            nearPointConvergence: {
                breakPoint: 'Break Point',
                recoveryPoint: 'Recovery Point',
            },
            fourDotFusion: {
                fourDotFusionResult: 'Four Dot Fusion Result',
            },
        },
    },
    printable: {
        dynamicVision: {
            dynamicVisionReport: 'DYNAMIC VISION REPORT&#153',
            overall: 'DYNAMIC VISION SCORE - COMPARISON TO SELF',
            individual: 'INDIVIDUAL TEST RESULTS',
            pursuits: 'Pursuits',
            saccades: 'Saccades',
            fixationAndReaction: 'Fixation & Reaction',
            name: 'Name:',
            dateOfBirth: 'Date of Birth:',
            assessmentDate: 'Assessment Date:',
            guidelinesTitle: 'GUIDELINES',
            guidelines: {
                1: 'This RightEye Dynamic Vision Report is designed to provide an assessment of your vision as reflected by oculomotor behavior.',
                2: 'DYNAMIC VISION SCORE: calculated as an equal input (33%) of all eye movement scores.',
                3: 'Fixations: refers to all metrics related to the stopping point (fixation) of the eye.',
                4: 'Pursuits: refers to all metrics related to the movement of the eye in relation to an object (smooth pursuit).',
                5: 'Saccades: refers to all metrics related to the quick movement of the eye to relocate foveal vision (saccade).',
                6: 'Saccadic Latency (ms): refers to the time between when the stimuli appear, and the eye first leaves the center of Solar System. Lower is better.',
                7: 'Visual Speed (ms): the average time difference between when the arrow begins shooting from the solar system to when the eye hits the target (e.g.: alien). Lower is better.',
                8: 'Processing Speed (ms): the average difference between when the eye hits the target (e.g.: alien) and the button is pressed. Lower is better.',
                9: 'Reaction Time (ms): the average time difference between when the arrow begins shooting from the solar system and the button is pressed. Lower is better.',
                10: 'Sensorimotor: Each point in this section denotes the average fixation point of each eye in relation to the corresponding calibration point, measured during the 9-point calibration performed at the beginning of the test protocol.',
            },
            guidelinesV1: {
                1: 'This RightEye Dynamic Vision Report is designed to provide an assessment of your vision as reflected by oculomotor behavior.',
                2: '“DYNAMIC VISION SCORE” Scale: this shows your results on a scale from below to within to above the RightEye reference data. “My Score” refers to your score on a metric.',
                3: 'Fixations: refers to all metrics related to the stopping point (fixation) of the eye.',
                4: 'Pursuits: refers to all metrics related to the movement of the eye in relation to an object (smooth pursuit).',
                5: 'Saccades: refers to all metrics related to the quick movement of the eye to relocate foveal vision (saccade).',
                6: 'Saccadic Latency (ms): refers to the time between when the stimuli appear, and the eye first leaves the center of Solar System. Lower is better.',
                7: 'Visual Speed (ms): the average time difference between when the arrow begins shooting from the solar system to when the eye hits the target (e.g.: alien). Lower is better.',
                8: 'Processing Speed (ms): the average difference between when the eye hits the target (e.g.: alien) and the button is pressed. Lower is better.',
                9: 'Reaction Time (ms): the average time difference between when the arrow begins shooting from the solar system and the button is pressed. Lower is better. Color coding for all "Eye-Hand Reaction Time" metrics: Green = Above average for your reference data, Orange = Within the average for your reference data, Red = Below average for your reference data.',
                10: 'Sensorimotor: Each point in this section denotes the average fixation point of each eye in relation to the corresponding calibration point, measured during the 9-point calibration performed at the beginning of the test protocol.',
            },
        },
        sensorimotor: {
            guidelines: {
                calibration: {
                    pupilaryDistance:
                        'Distance Between Eyes (mm) (interpupillary distance): measured from the center of your left and right pupils.',
                    disparity: 'Disparity (D): distance between the left and the right gaze.',
                    pupilaryDiameter:
                        'Pupil Diameter (mm): the size of the pupil during the last phase of the test. It is reported as average, standard deviation and range.',
                },
                cardinalGazePosition: {
                    cardinalGazePosition:
                        'Cardinal Gaze Position: Slow eye movements in relation to the reference range in the cardinal gaze position test in combination with other tests may be suggestive of esophoria, exophoria, hypertropia or convergence excess or insufficiency. ',

                    cgp:
                        'Cardinal Gaze Position: Relative measure of how long it takes the eye to travel in different directions (sensorimotor exam). 1 is the fastest, 8 is the slowest. N/A = eye data missing.',
                },
                ninePointMotorFunction:
                    'Nine Point Motor Function: Inaccurate targeting in the 9-Point motor function in combination with other tests may be suggestive of esophoria, exophoria, hypertropia or convergence excess or insufficiency.',
                nearPointConvergence:
                    'Near Point Convergence: A break point greater than 4 inches (10cm) and/or a recovery point of greater than 5 inches (12cm) may be suggestive of a convergence insufficiency when viewed in combination with other tests and the CISS survey.',
                circularSmoothPursuit:
                    'Circular Smooth Pursuit: A low score on smooth pursuit percentage, and high scores on predictive or latent smooth pursuits may indicate poor tracking abilities. This may be suggestive of various vision related issues. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
                horizontalSmoothPursuit:
                    'Horizontal Smooth Pursuit: A low score on smooth pursuit percentage, and high scores on predictive or latent smooth pursuits may indicate poor tracking abilities on the horizontal plane. This may be suggestive of various vision related issues. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
                verticalSmoothPursuit:
                    'Vertical Smooth Pursuit: A low score on smooth pursuit percentage, and high scores on predictive or latent smooth pursuits may indicate poor tracking abilities on the vertical plane. This may be suggestive of various vision related issues. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
                horizontalSaccades:
                    'Horizontal Saccades: A low targeting score or fixation number may indicate poor targeting in eccentric gaze positions. A high efficiency, variance or recovery score may indicate a lack of eye movement control. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
                verticalSaccades:
                    'Vertical Saccades: A low targeting score or fixation number may indicate poor targeting in elevated and depressed gaze positions. A high efficiency, variance or recovery score may indicate a lack of eye movement control. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
            },
        },
    },
    reports: {
        dynamicVisionReport: 'Dynamic Vision Report',
        standardReport: 'Standard Report',
        fdaStandardReport: 'FDA Standard Report',
        sensorimotorScreenerReport: 'Sensorimotor Screener Report',
        sensorimotorExamReport: 'Sensorimotor Exam Report',
        sensorimotorInterpretationReport: 'Sensorimotor Result',
        selectAssessment: 'Select an Assessment',
        selectComparison: 'Select a Comparison',
        customize: 'Customize...',
        conditionsRecommendationsPanel: 'Conditions and Recommendations (+/-)',
        selectMetric: 'Select Metric',
        selectReport: 'Select a Report',
        reportActions: 'Report Actions',
        tooltips: {
            addColumn: 'Add an Assessment Column',
            cannotAddColumn: 'Cannot add any more Assessment Columns',
            removeColumn: 'Remove an Assessment Column',
            export: 'Export as CSV',
            share: 'Get a link to share',
            print: 'Print this report',
            copied: 'Copied to Clipboard!',
            standard: 'View Standard Report',
            fdaStandard: 'View FDA Standard Report',
            dynamicVision: 'View Dynamic Vision Report',
            sportsVision: 'View Sports Vision Report',
            brainHealth: 'View Brain Health Report',
            functionalVision: 'View Functional Vision Report',
            reading: 'View Reading Report',
            saccadometry: 'View Saccadometry Report',
            moreInfo: 'Show More Info',
            closePopup: 'Close',
            viewComparisons: 'View Comparison Report',
            gridView: 'View Report as Grid',
            listView: 'View Report as List',
            hideAssessment: 'Hide this assessment',
            showAssessment: 'Show this assessment',
            sensorimotorScreener: 'View Sensorimotor Screener Report',
            sensorimotorExam: 'View Sensorimotor Exam Report',
            sensorimotorInterpretation: 'View Sensorimotor Result',
            showAdditionalOptions: 'Show Additional Options',
        },
        score: {
            overall: 'Overall',
            saccades: 'Saccades',
            pursuits: 'Pursuits',
            fixations: 'Fixations',
        },
        reactionTimeBar: {
            totalReactionTime: 'Total Reaction Time',
            targetAccuracy: 'Target Accuracy',
            saccadicLatency: {
                saccadic: 'Saccadic',
                latency: 'Latency',
            },
            visualSpeed: {
                visual: 'Visual',
                speed: 'Speed',
            },
            brainProcessingSpeed: {
                brainProcessing: 'Brain Processing',
                speed: 'Speed',
            },
        },
        names: {
            standard: 'Standard',
            dynamicVision: 'Dynamic Vision',
            sportsVision: 'Sports Vision',
            brainHealth: 'Brain Health',
            functionalVision: 'Functional Vision',
            reading: 'Reading',
            saccadometry: 'Saccadometry',
        },
        linkTexts: {
            standard: 'View Standard Report(s)',
            dynamicVision: 'View Dynamic Vision Report',
            sportsVision: 'View Sports Vision Report',
            brainHealth: 'View Brain Health Report',
            functionalVision: 'View Functional Vision Report',
            reading: 'View Reading Report',
            saccadometry: 'View Saccadometry Report',
            sensorimotorScreener: 'View Sensorimotor Screener Report',
            sensorimotorExam: 'View Sensorimotor Exam Report',
            sensorimotorInterpretation: 'View Sensorimotor Result',
        },
        actions: {
            hide: 'Hide Assessment',
            show: 'Show Assessment',
        },
        cardinalGazePosition: {
            myEyes: 'My Eyes',
            referenceData: 'Reference Data',
            slowest: 'My Slowest',
            fastest: 'My Fastest',
        },
    },
    tests: {
        calibration: 'Sensorimotor',
        circularSmoothPursuit: 'Circular Smooth Pursuit',
        horizontalSmoothPursuit: 'Horizontal Smooth Pursuit',
        verticalSmoothPursuit: 'Vertical Smooth Pursuit',
        horizontalSaccades: 'Horizontal Saccades',
        verticalSaccades: 'Vertical Saccades',
        choiceReactionTime: 'Choice Reaction Time',
        discriminateReactionTime: 'Discriminate Reaction Time',
        simpleReactionTime: 'Simple Reaction Time',
        staticVisualAcuity: 'Static Visual Acuity',
        dynamicVisualAcuity1: 'Dynamic Visual Acuity 1: Head Moving, Object Still',
        dynamicVisualAcuity2: 'Dynamic Visual Acuity 2: Head Still, Object Moving',
        dynamicVisualAcuity3: 'Dynamic Visual Acuity 3: Head Moving, Object Moving',
        cardinalGazePosition: 'Cardinal Gaze Position',
        fixationStability: 'Fixation Stability',
        contrastSensitivity: 'Contrast Sensitivity',
        randomHorizontalSaccades: 'Random Horizontal Saccades',
        horizontalGapSaccades: 'Horizontal Gap Saccades',
        bubbleBlast: 'Bubble Blast',
        cosmosCombat: 'Cosmos Combat',
        mazeMaster: 'Maze Master',
        spaceStorm: 'Space Storm',
    },
    smoothPursuit: {
        circularSmoothPursuit: 'Circular Smooth Pursuit',
        horizontalSmoothPursuit: 'Horizontal Smooth Pursuit',
        verticalSmoothPursuit: 'Vertical Smooth Pursuit',
        head: 'head',
        smoothPursuitPercentageShort: 'SP %',
        efficiency: 'Efficency',
    },
    fixationStability: {
        fixationStability: 'Fixation Stability',
    },
    saccadicLatency: {
        saccadicLatency: 'Saccadic Latency',
    },
    choiceReactionTime: {
        choiceReactionTime: 'Choice Reaction Time',
    },
    discriminateReactionTime: {
        discriminateReactionTime: 'Discriminate Reaction Time',
    },
    conditionsRecommendations: {
        subtitle: 'Interpretation of Quantitative Sensorimotor Exam',
        recommendations: 'Recommendations',
        clinicalNotes: 'Clinical Notes',
        none: 'None',
    },
    saccades: {
        horizontalSaccades: 'Horizontal Saccades',
        verticalSaccades: 'Vertical Saccades',
        targetingAccuracy: 'TA (mm)',
        speed: 'SPEED (d/s)',
    },
    calibration: {
        calibration: 'Sensorimotor',
    },
    charts: {
        saccadicLatency: {
            saccadicLatency: 'Saccadic Latency',
            choiceReactionTime: 'Choice Reaction Time',
            discriminateReactionTime: 'Discriminate Reaction Time',
            trials: 'Trials',
        },
    },
    info: {
        dynamicVisionV2: {
            dynamicVisionScore: 'DYNAMIC VISION SCORE: calculated as an equal input (33%) of all eye movement scores.',
            eyeMovementScores: 'EYE MOVEMENT SCORES',
            eyeMovementScorePursuit:
                'Pursuit Score: is calculated based on on-target smooth pursuits divided by smooth pursuit (%). Ideal pursuit score would include on-target smooth pursuit (%) and smooth pursuit (%) being the same value.  This would result is a 100% pursuit score.',
            eyeMovementScoreSaccade:
                'Saccade Score: is calculated based on the number of saccades minus missed saccades. Ideal saccade score would include no missed (#) (>36mm) and at least 1 saccade (3). This would result is a 100% saccade score.',
            eyeMovementScoreFixation:
                'Fixation Score: is calculated based on the location of both eye positions within 2-degrees of the target. Ideal fixation is all eye movements within 2 degrees of the target. This would result is a 100% fixation score.',
            eyeMovementScoreFooterOne: 'All scores are represented as a percentage.',
            eyeMovementScoreFooterTwo: 'All scores are based on a comparison to the ideal eye movement.',
        },
        brainHealth:
            'Brain Health EyeQ is designed to provide an assessment of brain health and visual function as reflected by oculomotor behavior. For a more in-depth analysis of Brain Health EyeQ go to www.righteye.com',
        functionalScale:
            'Dysfunctional to Functional to Exceptional Scale: this shows your results on a scale from dysfunctional to exceptional. It reflects where the patient falls compared to the population. Each contributing metric is weighted to result in an overall probability of normality.',
        myScore: '"My Score" refers to your score on a metric.',
        myScoreReferenceDataRange: {
            I15: 'Reference Data Range Score is not available for persons under 3 years of age.',
            smi: 'Reference Data Range is not available for persons under 6 years of age.',
        },
        dynamicVisionScale:
            '"DYNAMIC VISION SCORE" Scale: RightEye uses an advanced algorithm to analyze thousands of eye movement metrics during the Dynamic Vision test. Each metric is analyzed to determine if it meaningfully contributes to the score. If it does contribute, a further analytic process determines its weight of contribution. Then it is used to calculate an overall Dynamic Vision score (“My Score”) for that assessment on a scale of 0-100. The Dynamic Vision score serves as a summary of an individual’s general wellness based on their eye movement performance. Dynamic Vision scores can be used to compare an individual’s results to their age group, measure changes over time, or as a baseline in the event of injury or impairment. Scores are not available for ages 3 and under.',
        eyeMovementScores:
            'Eye Movement Scores: Pursuit, Saccade, and Fixation scores are calculated using metrics from tests specific to those eye movements. Each score is calculated independently from the overall Dynamic Vision Score, utilizing its own unique algorithm.',
        fixations: 'Fixations refers to all metrics related to the stopping point (fixation) of the eye.',
        pursuits:
            'Pursuits refers to all metrics related to the movement of the eye in relation to an object (smooth pursuit).',
        percentiles:
            'Percentiles: Percentiles are shown for each Pursuit, Saccade, and Fixation score. Percentiles demonstrate how an individual’s specific eye movement areas perform compared to a population of clinically verified impaired and non-impaired individuals. Age is not a factor in this comparison.',
        referenceRange:
            'Reference range: A dotted-line box is shown on the scale to indicate the upper and lower range of scores expected for that individual’s age group. Reference ranges are calculated from a sample set of over 61,000 RightEye assessments and can be used to illustrate how an individual’s score compares to that of their age-based peers.',
        speed:
            'Speed (degree/second) refers to the average velocity made by the saccades across the test time. Higher is better.',
        pathway:
            'Pathway Length Differences (PLD, millimeters) refers to the average difference in distance between the right and the left eye gaze pathways. Ideal score is zero. Lower is better.',
        distance:
            'Distance (millimeters) refers to the average distance the gaze pathway is from the ideal pathway. Lower is better.',
        hd:
            'HD (Horizontal Displacement D: diopters): Horizontal deviation between the gaze and the center of the screen. Close to zero is the best.',
        vd:
            'VD (Vertical Displacement D: diopters): Vertical deviation between the gaze and the center of the screen. Close to zero is the best.',
        visualSpeed:
            'Visual Speed (ms): The average time difference between when the arrow begins shooting from the solar system to when the eye hits the target (e.g.: alien). Lower is better.',
        processingSpeed:
            'Processing Speed (ms): The average difference between when the eye hits the target (e.g.:alien) and the button is pressed. Lower is better.',
        accuracy:
            'Accuracy: The tally of the correct responses, divided by the number of trials. Represented as percentage of correct responses. Higher is better.',
        averageDistance: 'Your average distance from the screen was',
        recommendedDistance: 'Recommended distance is 55-60 cm.',
        sensorimotor:
            'Sensorimotor: Each point in this section denotes the average fixation point of each eye in relation to the corresponding calibration point, measured during the 9-point calibration performed at the beginning of the test protocol.',
        sensorimotorGuidance: 'A normal result is both eyes within 8 or more targets',
        sp: {
            smoothPursuit:
                'Smooth Pursuit (SP%) are eye movements that follow the target within a velocity range of the target and are reported as a percentage of the test time. Higher is better.',
            saccadeFixation: 'Saccade % and Fixation % should be low.',
            eyeTargetVel:
                'Eye/Target Velocity Error refers to speed represented in degrees per second off target. A low number is better.',
            horVertSync:
                'Horizontal and Vertical Synchronization SP refer to stay on/off target in horizontal/vertical (x and y) plane. 1.0 is perfect.',
            predictiveLatentSP:
                'Predictive and Latent SP refer to 7 mm or more ahead or behind target at the same speed as the target.',
            targetSP: 'On Target SP refers to % of time within 9mm of the target while in SP.',
            pathwayLengthDiff:
                'Pathway Length Differences (PLD, millimeters): refers to the average difference in distance between the right and left eye gaze pathways. Ideal score is zero. Lower is better.',
            efficiency:
                'Efficiency (millimeters): refers to the error in the users’ gaze is from the ideal pathway. Lower is better.',
        },
        saccades: {
            saccades:
                'Saccades refers to all metrics related to the quick movement of the eye to relocate foveal vision (saccade).',
            saccadeNum:
                'Saccade (#) refers to number of saccades tallied for a single test. 1 saccade is from one black dot to the other.',
            fixationNum:
                'Fixation (#) refers to number of times user stops moving their eye. On-Target, overshot, undershot, misses refers to accuracy of the saccade and proximity of eye gaze point to the dot when fixating.',
            saccadicEfficiency:
                "Saccadic Efficiency (mm) refers to how far the error in the users' gaze is from the ideal pathway. Lower is better.",
            saccadicTargeting:
                'Saccadic Targeting (mm) refers to the distance each "hit" or fixation was compared to the ideal target. Lower is better',
            speedAccuracy:
                'Speed Accuracy Trade-off (dps/mm) refers to the trade-off that occurs between moving your eyes quickly but also being accurate.',
            saccadicRecovery:
                'Saccadic Recovery (mm) refers to the difference in the path taken before and after a fixation. A wide, looping path is inefficient. A narrow path is ideal.',
            saccadicVariation:
                'Saccadic Variance (mm) refers to the variability or dispersion when trying to move eye gaze between targets.',
            saccadicVelocity:
                'Saccadic Velocity (degree/second): refers to the average velocity made by the saccades across the test time. Higher is better.',
        },
        gapSaccades: {
            velocityFastPhaseMean:
                '1. [0, 20, 23] Degrees Velocity Fast Phase (Mean): the mean velocity of eye movements in deg/sec that are within 3 cm of the stimuli and moving in the direction from the center of the screen and passing the stimuli to one edge of the screen.',
            velocityFastPhaseSD:
                '2. [0, 20, 23] Degrees Velocity Fast Phase (SD): the standard deviation of the velocity of eye movements that are within 3 cm of the stimuli and moving in the direction from the center of the screen and passing the stimuli to one edge of the screen.',
            velocitySlowPhaseMean:
                '3. [0, 20, 23] Degrees Velocity Slow Phase (Mean): the mean velocity of eye movements in deg/sec that are within 3 cm of the stimuli and moving in the direction from the edge of the screen and passing the stimuli to the center of the screen.',
            velocitySlowPhaseSD:
                '4. [0, 20, 23] Degrees Velocity Slow Phase (SD): the standard deviation of the velocity of eye movements that are within 3 cm of the stimuli and moving in the direction from the edge of the screen and passing the stimuli to the center of the screen.',
            durationFastPhaseMean:
                '5. [0, 20, 23] Degrees Duration Fast Phase (Mean): the mean duration of the eye movements in milliseconds that are within 3 cm of the stimuli and moving in the direction from the center of the screen and passing the stimuli to one edge of the screen.',
            durationFastPhaseSD:
                '6. [0, 20, 23] Degrees Duration Fast Phase (SD): the standard deviation of the duration of the eye movements that are within 3 cm of the stimuli and moving in the direction from the center of the screen and passing the stimuli to one edge of the screen.',
            durationSlowPhaseMean:
                '7. [0, 20, 23] Degrees Duration Slow Phase (Mean): the mean duration of the eye movements in milliseconds that are within 3 cm of the stimuli and moving in the direction from the edge of the screen and passing the stimuli to the center of the screen.',
            durationSlowPhaseSD:
                '8. [0, 20, 23] Degrees Duration Slow Phase (SD): the standard deviation of the duration of the eye movements that are within 3 cm of the stimuli and moving in the direction from the edge of the screen and passing the stimuli to the center of the screen.',
        },
        reactionTime: {
            definition:
                'Reaction Time refers to how long it took you to see, process and respond to three different targets (the alien, planet and astronaut) each with a different key and how accurate you were.',
            reactionTime:
                'Reaction Time (ms): the average time difference between when the arrow begins shooting from the solar system and the button is pressed. Lower is better. Color coding for all "Eye-Hand Reaction Time" metrics: Green = Above average for your reference data, Orange = Within the average for your reference data, Red = Below average for your reference data.',
            saccadicLatency:
                'Saccadic Latency (ms) refers to the time between when the stimuli appear, and the eye first leaves the center of the Solar System. Lower is better.',
            visualReactionSpeed:
                'Visual Speed (ms): the average time difference between when the arrow begins shooting from the solar system to when the eye hits the target (e.g.: alien). Lower is better.',
            distractibility: 'Distractibility refers to your ability to pay attention to the task at hand.',
            processingSpeed:
                'Processing Speed (ms): the average difference between when the eye hits the target (e.g.: alien) and the button is pressed. Lower is better',
            impulsivity:
                'Impulsivity refers to your ability to be “patient”, waiting for the information to present itself before responding.',
        },
        contrastSensitivity: {
            cpd:
                'Cycles Per Degree (CPD): A level (3CPD) tests optic nerve. B level (6CPD) tests retina. C level (12CPD) tests lens/crystalline or PCIOL.',
        },
        fixationStability: {
            fixationLocation:
                'Fixation Stability and Fixation Location: The abiliity to keep your eyes from shifting over time for right, left, or both eyes. Central (>50% of fixation points on the central 2 degrees), Poor Central (<50% but >25% within 2 degrees), Eccentric (<25% within 2 degrees), Stable (>75% within 2 degrees), Relatively Stable (<75% within 2 degrees BUT >75% within 4 degrees), Unstable (<75% in 4 degrees).',
            bcea:
                'Bivariate Contour Ellipse Area (BCEA): The amount of variation measured around a point of fixation. Microsaccades and drifts of the human eye cause corrections of the eye back to a central point. These slight eye movements form an area of dispersion in the shape of an ellipse that is measured by the BCEA.',
            fixationDispersion:
                'Fixation Dispersion (mm): Distance between each gaze point and the target stimuli, averaged over the entire test for all gaze points.',
        },
        staticVisualAcuity: {
            sva:
                'Static Visual Acuity (SVA) refers to how clearly you can see an object that changes size but does not move.',
        },
        dynamicVisualAcuity: {
            dva1:
                'Dynamic Visual Accuity 1 refers to how accurately you can view a stationary target when your head is moving at a rate of 2 times a second (2Hz).',
            dva2:
                'Dynamic Visual Accuity 2 refers to what speed you can accurately identify a moving target the size of 20/100 when your head remains still.',
            dva3:
                'Dynamic Visual Accuity 3 refers to what speed you can accurately identify a moving target the size of 20/100 when your head is allowed free movement.',
        },
        cardinalGazePosition: {
            cgp:
                'Cardinal Gaze Position: Relative measure of how long it takes the eye to travel in different directions (sensorimotor exam). 1 is the fastest, 8 is the slowest. N/A = eye data missing.',
            disparity: 'Disparity is taken between the left and the right eye (represented in mm).',
        },
        calibration: {
            pupilaryDistance:
                'Distance Between Eyes (mm) (interpupillary distance) is measured from the center of your left and right pupils.',
            disparity: 'Disparity (D): distance between the left and the right gaze.',
            pupilaryDiameter:
                'Pupil diameter is the size of the pupil during the last phase of the test. It is reported as average, standard deviation and range.',
            midlinePrimary:
                'The Midline Primary target boundary is more strict, considering eyes within a 1 degree distance to be "on target" compared to a 4 degree threshold for the surrounding targets.',
        },
        bubbleBlast: {
            score:
                'Score: refers to the level of accuracy, the eye obtains when hitting the bubble. Hitting the center of the bubble gives the highest score of 10 points.',
            smoothPursuit: 'Smooth Pursuit (%): refers to the % of time spent in SP on the downward phase.',
        },
        cosmosCombat: {
            score:
                'Score: refers to a combination of the speed and accuracy of your eye movement towards the target throughout the game.',
            accuracy:
                'Accuracy: refers to the path your eyes traveled to the target. A direct line gives the highest score (reported as a %).',
            speed:
                'Speed: refers to the saccadic latency (time it takes to move off the center target once peripheral targets are displayed) plus visual reaction speed (movement time of the eye from the center target until they locate the matching peripheral target).',
        },
        mazeMaster: {
            totalGameTime: 'Total game time: refers to the amount of time taken to complete the maze.',
        },
        spaceStorm: {
            score:
                'Score: refers to the accuracy of the eye related to the target. Hitting the center of the target equals the highest score.',
        },
        normsV3Info: 'You may find reference population data ',
        normsV3: {
            I15: {
                circularSmoothPursuit: 'https://righteye-norms-v3-links.s3.amazonaws.com/I15/i15-csp-norms-v3.pdf',
                horizontalSmoothPursuit: 'https://righteye-norms-v3-links.s3.amazonaws.com/I15/i15-hsp-norms-v3.pdf',
                verticalSmoothPursuit: 'https://righteye-norms-v3-links.s3.amazonaws.com/I15/i15-vsp-norms-v3.pdf',
                horizontalSaccades: 'https://righteye-norms-v3-links.s3.amazonaws.com/I15/i15-hs-norms-v3.pdf',
                verticalSaccades: 'https://righteye-norms-v3-links.s3.amazonaws.com/I15/i15-vs-norms-v3.pdf',
                horizontalGapSaccades:
                    'https://righteye.com/wp-content/uploads/2020/12/Murray-Kubitz-Roberts-Hunfalvay-Bolte-Tyagi-2019.pdf',
            },
            SMI: {
                circularSmoothPursuit: 'https://righteye-norms-v3-links.s3.amazonaws.com/SMI/smi-csp-norms-v3.pdf',
                horizontalSmoothPursuit: 'https://righteye-norms-v3-links.s3.amazonaws.com/SMI/smi-hsp-norms-v3.pdf',
                verticalSmoothPursuit: 'https://righteye-norms-v3-links.s3.amazonaws.com/SMI/smi-vsp-norms-v3.pdf',
                horizontalSaccades: 'https://righteye-norms-v3-links.s3.amazonaws.com/SMI/smi-hs-norms-v3.pdf',
                verticalSaccades: 'https://righteye-norms-v3-links.s3.amazonaws.com/SMI/smi-vs-norms-v3.pdf',
                horizontalGapSaccades:
                    'https://righteye.com/wp-content/uploads/2020/12/Murray-Kubitz-Roberts-Hunfalvay-Bolte-Tyagi-2019.pdf',
            },
        },
        nearPointConvergence:
            'A break point greater than 4 inches (10cm) and/or a recovery point of greater than 5 inches (12cm) may be suggestive of a convergence insufficiency when viewed in combination with other tests and the CISS survey.',
        fourDotFusion: 'Results may be suggestive of {0} condition.',
        sensorimotorInterpretation: {
            ninePointMotorFunction:
                'Inaccurate targeting in the 9-Point motor function in combination with other tests may be suggestive of esophoria, exophoria, hypertropia or convergence excess or insufficiency.',
            cardinalGazePosition:
                'Slow eye movements in relation to the reference range in the cardinal gaze position test in combination with other tests may be suggestive of esophoria, exophoria, hypertropia or convergence excess or insufficiency. ',
            nearPointConvergence:
                'A break point greater than 4 inches (10cm) and/or a recovery point of greater than 5 inches (12cm) may be suggestive of a convergence insufficiency when viewed in combination with other tests and the CISS survey.',
            fourDotFusion: 'Results may be suggestive of {0} condition.',
            circularSmoothPursuit:
                'A low score on smooth pursuit percentage, and high scores on predictive or latent smooth pursuits may indicate poor tracking abilities. This may be suggestive of various vision related issues. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
            horizontalSmoothPursuit:
                'A low score on smooth pursuit percentage, and high scores on predictive or latent smooth pursuits may indicate poor tracking abilities on the horizontal plane. This may be suggestive of various vision related issues. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
            verticalSmoothPursuit:
                'A low score on smooth pursuit percentage, and high scores on predictive or latent smooth pursuits may indicate poor tracking abilities on the vertical plane. This may be suggestive of various vision related issues. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
            horizontalSaccades:
                'A low targeting score or fixation number may indicate poor targeting in eccentric gaze positions. A high efficiency, variance or recovery score may indicate a lack of eye movement control. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
            verticalSaccades:
                'A low targeting score or fixation number may indicate poor targeting in elevated and depressed gaze positions. A high efficiency, variance or recovery score may indicate a lack of eye movement control. Check results in combination with the CISS survey outcomes. To determine if a value is too high or too low, use the relevant age-based average and add or subtract the standard deviation.',
        },
        antisaccades: {
            definition:
                'Antisaccade testing assesses the brains’ ability to inhibit the reflexive saccade (toward a visual stimuli) and “override” that movement leading to a saccade in the opposite (mirrored) direction.',
            metrics:
                'For each trial, the following metrics are calculated. These metrics are then calculated as a group to form means and standard deviations for the entire trial block. The frontend only reports the means and standard deviations for each trial block.',
            saccadicLatency:
                'Saccadic Latency (ms): is the time difference between when the starting cross disapears and the users eye move.',
            saccadicAmplitude:
                'Saccadic Amplitude (°): is the speed of the eye movements as they travel horizontally across the screen.',
            targetAccuracy:
                'Target Accuracy (mm): is the distance between the users eye location on the screen and the target cross at the time when the target cross disappears.',
            antisaccadeNumber:
                'Anti-saccade Number (#): is the frequency of the antisaccades. An antisaccade is defined as a movement of the users eyes that is not towards the target.',
            prosaccadeNumber:
                'Pro-saccade Number (#): is the frequency of the prosaccades. A prosaccade is defined as a movement of the users eye that is towards the target.',
            saccadicOnset:
                'Saccadic Onset (ms): is measured by when the starting cross disappears and the eye reaches an amplitude of >=2 degrees.',
        },
        prosaccades: {
            definition:
                'Prosaccade testing is designed to assess the reflexive guided eye movement towards a suddenly appearing target with or without the presence of a distractor.',
            metrics:
                'For each trial, the following metrics are calculated. These metrics are then calculated as a group to form means and standard deviations for the entire trial block. The frontend only reports the means and standard deviations for each trial block.',
            saccadicLatency:
                'Saccadic Latency (ms): is the time difference between when the starting cross disapears and the users eye move.',
            saccadicAmplitude:
                'Saccadic Amplitude (°): is the speed of the eye movements as they travel horizontally across the screen.',
            targetAccuracy:
                'Target Accuracy (mm): is the distance between the users eye location on the screen and the target cross at the time when the target cross disappears.',
            antisaccadeNumber:
                'Anti-saccade Number (#): is the frequency of the antisaccades. An antisaccade is defined as a movement of the users eyes that is not towards the target.',
            prosaccadeNumber:
                'Pro-saccade Number (#): is the frequency of the prosaccades. A prosaccade is defined as a movement of the users eye that is towards the target.',
            saccadicOnset:
                'Saccadic Onset (ms): is measured by when the starting cross disappears and the eye reaches an amplitude of >=2 degrees.',
        },
    },
};
