


























import Vue, { PropType } from 'vue';

import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ReportData } from '@/common/types/reports/reportData';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            metrics: undefined as any,
            fields: [
                'nystagmusDuration0Degrees.fast.mean',
                'nystagmusDuration0Degrees.fast.stdDev',
                'nystagmusDuration0Degrees.slow.mean',
                'nystagmusDuration0Degrees.slow.stdDev',
                'nystagmusDuration20DegreesLeft.fast.mean',
                'nystagmusDuration20DegreesLeft.fast.stdDev',
                'nystagmusDuration20DegreesLeft.slow.mean',
                'nystagmusDuration20DegreesLeft.slow.stdDev',
                'nystagmusDuration20DegreesRight.fast.mean',
                'nystagmusDuration20DegreesRight.fast.stdDev',
                'nystagmusDuration20DegreesRight.slow.mean',
                'nystagmusDuration20DegreesRight.slow.stdDev',
                'nystagmusDuration23DegreesLeft.fast.mean',
                'nystagmusDuration23DegreesLeft.fast.stdDev',
                'nystagmusVelocity0Degrees.fast.mean',
                'nystagmusVelocity0Degrees.fast.stdDev',
                'nystagmusVelocity0Degrees.slow.mean',
                'nystagmusVelocity0Degrees.slow.stdDev',
                'nystagmusVelocity20DegreesLeft.fast.mean',
                'nystagmusVelocity20DegreesLeft.fast.stdDev',
                'nystagmusVelocity20DegreesLeft.slow.mean',
                'nystagmusVelocity20DegreesLeft.slow.stdDev',
                'nystagmusVelocity20DegreesRight.fast.mean',
                'nystagmusVelocity20DegreesRight.fast.stdDev',
                'nystagmusVelocity20DegreesRight.slow.mean',
                'nystagmusVelocity20DegreesRight.slow.stdDev',
                'nystagmusVelocity23DegreesLeft.fast.mean',
                'nystagmusVelocity23DegreesLeft.fast.stdDev',
            ] as string[],
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.gapSaccades.velocityFastPhaseMean'));
            infoText.push(this.$t('reports.info.gapSaccades.velocityFastPhaseSD'));
            infoText.push(this.$t('reports.info.gapSaccades.velocitySlowPhaseMean'));
            infoText.push(this.$t('reports.info.gapSaccades.velocitySlowPhaseSD'));
            infoText.push(this.$t('reports.info.gapSaccades.durationFastPhaseMean'));
            infoText.push(this.$t('reports.info.gapSaccades.durationFastPhaseSD'));
            infoText.push(this.$t('reports.info.gapSaccades.durationSlowPhaseMean'));
            infoText.push(this.$t('reports.info.gapSaccades.durationSlowPhaseSD'));

            return infoText;
        },
        paperLink(): string {
            if (this.reportData.assessment.systemType === 'I15') {
                return 'reports.info.normsV3.I15.horizontalGapSaccades';
            }

            return 'reports.info.normsV3.SMI.horizontalGapSaccades';
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (reportData.tests.horizontalGapSaccades) {
                this.metrics = reportData.tests.horizontalGapSaccades.metrics;
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
