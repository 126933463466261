export interface GraphQlQuery {
    requestBody(): string;
}

export class SimpleGraphQlQuery implements GraphQlQuery {
    public readonly query: string;
    public readonly variables: object | undefined;

    public constructor(query: string, variables?: object) {
        this.query = 'query {' + query + '}';
        this.variables = variables;
    }

    public requestBody(): string {
        const body: any = {
            query: this.query,
        };

        if (this.variables) {
            body.variables = this.variables;
        }

        return JSON.stringify(body);
    }
}
