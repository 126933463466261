















import Vue from 'vue';

import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    props: {
        page: {
            type: String,
            required: true,
        },
        msg: {
            type: String,
            required: true,
        },
    },
    components: {
        GreenButton,
    },
    methods: {
        async onClick() {
            this.$router.push(this.page);
        },
    },
    computed: {
        currentPage(): string {
            return this.$t(`trainer.routes.${this.page}`).toString();
        },
        message(): string {
            return this.$t(`trainer.errorMessage.${this.msg}`).toString();
        },
    },
});
