export default {
    buttons: {
        backToLogin: 'BACK TO LOGIN',
        register: 'REGISTER',
    },
    errors: {
        authError: 'Invalid email address or password.',
        badPassword: 'Password does not meet requirements.',
        inactivityLogOut: 'You have been logged out due to inactivity.',
        mismatchedPassword: 'Passwords do not match',
        tokenExpirationLogOut: 'Your authentication has expired. Please log in again.',
        unknownError:
            'An error occurred while sending your login request. Please check your internet connection and contact RightEye if the issue persists.',
    },
    fields: {
        emailAddress: 'Email Address',
        password: 'Password',
        secondPassword: 'Re-enter Password',
    },
    navigation: {
        about: 'About',
        contact: 'Contact',
        needHelp: {
            link: 'Need Help?',
            title: 'Need Help?',
        },
        privacyPolicy: {
            link: 'Privacy Policy',
            title: 'Privacy Policy',
        },
        termsAndConditions: {
            link: 'Terms & Conditions',
            title: 'Terms & Conditions',
        },
    },
    static: {
        createPassword: 'Create Password',
        forgotPassword: 'Forgot Password?',
        passwordRequirementTop: 'Passwords must meet the following requirements:',
        passwordSuccesfullyReset: 'Password successfully reset',
        trademark: '©2017 – {currentYear} RightEye, LLC. All rights reserved',
        welcome: 'Log Into Your RightEye Account',
    },
};
