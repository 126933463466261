






import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';
import { TestData } from '@/common/types/reports/reportData';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import ScreenData from '@/common/types/reports/screenData';
import * as MathUtils from '@/common/utils/math';
import { Text } from '@/common/utils/reports/visualization/elements/text';
import { VisualizationElement } from '@/common/utils/reports/visualization/elements';
import * as d3 from 'd3';

export interface NearPointConvergenceConfig {
    testData: TestData;
    systemType: string;
}

const EYE_RADIUS = [0.05, 0.025];

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<NearPointConvergenceConfig>,
        },
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
    mounted() {
        const screenData = Constants.SCREEN_DATA[this.config.systemType];
        const ASPECT_RATIO = screenData.width / screenData.height;

        if (!this.config.testData) {
            throw new Error('This assessment is missing Calibration data.');
        }

        const nearPointConvergenceMetrics = this.config.testData.metrics;
        const breakPoint = nearPointConvergenceMetrics.breakPoint;
        const recoveryPoint = nearPointConvergenceMetrics.recoveryPoint;

        const fontSize = 0.0008;
        const breakPointLine = new Position2d(0.5, 0.09);

        const breakPointLineVisualization = this.buildLineVisualization(
            breakPointLine,
            breakPoint,
            recoveryPoint,
            screenData,
        );

        let breakAndRecoveryVisual = null;
        if (breakPoint < 0 || recoveryPoint < 0) {
            breakAndRecoveryVisual = Visualization.build()
                .text({
                    content: '(TEST INCOMPLETE)',
                    position: [0.424, 0.02],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'start',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: 'Break Point = N/A',
                    position: [0.3, 0.1],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'start',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: 'Recovery Point = N/A',
                    position: [0.3, 0.15],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'start',
                        'letter-spacing': '0',
                    },
                });
        } else {
            breakAndRecoveryVisual = Visualization.build()
                .text({
                    content: this.getBreakDistanceText(breakPoint),
                    position: [0.3, 0.1],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'start',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: this.getRecoveryDistanceText(recoveryPoint),
                    position: [0.3, 0.15],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'start',
                        'letter-spacing': '0',
                    },
                });
        }

        breakAndRecoveryVisual.with(breakPointLineVisualization).drawNormalized(this.svg, 10 / 2);

        d3.select(this.svg)
            .append('image')
            .attr('href', (d) => {
                const imagePath = require('@/assets/near-point-convergence.png');
                return imagePath;
            })
            .attr('x', '0.01')
            .attr('y', '-0.05')
            .attr('width', '0.28')
            .attr('height', '0.28');
    },
    methods: {
        getBreakDistanceText(breakDistance: number): string {
            const breakDistanceToInches = MathUtils.roundToNearestTenth(MathUtils.cmToInches(breakDistance));
            return `Break Point = ${breakDistance} cm (${breakDistanceToInches} in)`;
        },
        getRecoveryDistanceText(recoveryDistance: number): string {
            const recoveryDistanceToInches = MathUtils.roundToNearestTenth(MathUtils.cmToInches(recoveryDistance));
            return `Recovery Point = ${recoveryDistance} cm (${recoveryDistanceToInches} in)`;
        },
        buildLineVisualization(
            coordinates: Position2d,
            breakPoint: number,
            recoveryPoint: number,
            screenData: ScreenData,
        ): Visualization {
            const ASPECT_RATIO = screenData.width / screenData.height;

            const defaultAttributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.001,
            };

            const lineLength = 0.48;
            const lineDistance = 0.05;
            const textPadding = new Position2d(0.014, 0.03);
            const wordOffset = 0.03;
            const fontSize = 0.0008;
            const smallerFontSize = 0.0007;

            const textAttributes = {
                'font-size': `${smallerFontSize}em`,
                'font-family': 'sans-serif',
                'font-weight': 'regular',
            };

            const marker = lineLength / 16.0;

            const textElements: VisualizationElement[] = [];

            for (let i = 0; i <= 15; i++) {
                textElements.push(
                    new Text({
                        content: i + (i === 0 ? ` cm` : ``),
                        position: [coordinates.x + textPadding.x + wordOffset * i, coordinates.y + textPadding.y],
                        aspectRatio: ASPECT_RATIO,
                        attributes: {
                            'font-size': `${fontSize}em`,
                            'font-family': 'sans-serif',
                            'font-weight': 'regular',
                        },
                        styles: {
                            'fill': 'black',
                            'text-anchor': 'middle',
                            'letter-spacing': '0',
                        },
                    }),
                );
            }

            return Visualization.build()
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x + lineLength, coordinates.y],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y + lineDistance],
                    end: [coordinates.x + lineLength, coordinates.y + lineDistance],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .rect({
                    position: new Position2d(coordinates.x, coordinates.y - 0.005),
                    width: 0.007,
                    height: 0.007,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        fill: Constants.BLACK,
                    },
                    angle: 45,
                })
                .rect({
                    position: new Position2d(coordinates.x + lineLength, coordinates.y - 0.005),
                    width: 0.007,
                    height: 0.007,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        fill: Constants.BLACK,
                    },
                    angle: 45,
                })
                .rect({
                    position: new Position2d(coordinates.x, coordinates.y - 0.005 + lineDistance),
                    width: 0.007,
                    height: 0.007,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        fill: Constants.BLACK,
                    },
                    angle: 45,
                })
                .rect({
                    position: new Position2d(coordinates.x + lineLength, coordinates.y - 0.005 + lineDistance),
                    width: 0.007,
                    height: 0.007,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        fill: Constants.BLACK,
                    },
                    angle: 45,
                })
                .circle({
                    center: [coordinates.x + marker * breakPoint + 0.014, coordinates.y],
                    radius: 0.005,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                        fill: 'black',
                    },
                })
                .circle({
                    center: [coordinates.x + marker * recoveryPoint + 0.014, coordinates.y + lineDistance],
                    radius: 0.005,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                        fill: 'black',
                    },
                })
                .text({
                    content: `Convergence Excess`,
                    position: [0.57, 0.17],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: `Possible`,
                    position: [0.57, 0.19],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: `Normal Convergence`,
                    position: [0.74, 0.17],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .text({
                    content: `Convergence Insufficiency`,
                    position: [0.92, 0.17],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .addAll(textElements);
        },
        buildRightEyeVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            const ASPECT_RATIO = screenData.width / screenData.height;

            const stimulusRadius = 0.06;

            const defaultAttributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.002,
            };

            const fontSize = 0.001;

            return Visualization.build()
                .ellipse({
                    center: [coordinates.x, coordinates.y],
                    radiusX: EYE_RADIUS[0],
                    radiusY: EYE_RADIUS[1],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .text({
                    content: `Right Eye`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .circle({
                    center: [coordinates.x, coordinates.y],
                    radius: 0.02,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .circle({
                    center: [coordinates.x, coordinates.y],
                    radius: 0.01,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                        fill: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x + stimulusRadius * 1.3, coordinates.y + stimulusRadius * ASPECT_RATIO],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x + stimulusRadius * 1.3, coordinates.y + stimulusRadius * ASPECT_RATIO * 1.5],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x + stimulusRadius * 1.3, coordinates.y + stimulusRadius * ASPECT_RATIO * 2.0],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                });
        },
        buildLeftEyeVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            const ASPECT_RATIO = screenData.width / screenData.height;

            const stimulusRadius = 0.06;

            const defaultAttributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.002,
            };

            const fontSize = 0.001;

            return Visualization.build()
                .ellipse({
                    center: [coordinates.x, coordinates.y],
                    radiusX: EYE_RADIUS[0],
                    radiusY: EYE_RADIUS[1],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .text({
                    content: `Left Eye`,
                    position: [coordinates.x, coordinates.y - 0.05],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize}em`,
                        'font-family': 'sans-serif',
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .circle({
                    center: [coordinates.x, coordinates.y],
                    radius: 0.02,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .circle({
                    center: [coordinates.x, coordinates.y],
                    radius: 0.01,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                        fill: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x - stimulusRadius * 1.3, coordinates.y + stimulusRadius * ASPECT_RATIO],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x - stimulusRadius * 1.3, coordinates.y + stimulusRadius * ASPECT_RATIO * 1.5],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                })
                .line({
                    start: [coordinates.x, coordinates.y],
                    end: [coordinates.x - stimulusRadius * 1.3, coordinates.y + stimulusRadius * ASPECT_RATIO * 2.0],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        stroke: 'black',
                    },
                });
        },
    },
});
