


























import Vue, { PropType } from 'vue';

import FourDotFusionVisualization, {
    FourDotFusionConfig,
} from '@/views/reports/visualizations/FourDotFusionVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';

import MultiMetricTable from '@/views/reports/metrics/MultiMetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        FourDotFusionVisualization,
        MultiMetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        forPrint: {
            default: false,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            config: {
                testData: this.reportData.tests.fourDotFusion,
                systemType: this.reportData.assessment.systemType,
            } as FourDotFusionConfig,
            directions: [
                'midlinePrimary',
                'superiorLeft',
                'superiorRight',
                'inferiorLeft',
                'inferiorRight',
                'midlineLeft',
                'superiorMidline',
                'midlineRight',
                'inferiorMidline',
            ],
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.fourDotFusion', [`${this.fourDotFusionResult}`]));

            return infoText;
        },
        fourDotFusionResult(): string {
            switch (this.config.testData.metrics.fourDotFusionResult) {
                case 'BINOCULAR_SINGLE_VISION':
                    return 'Binocular Single Vision';
                case 'RIGHT_EYE_SUPPRESSION':
                    return 'Right Eye Suppression';
                case 'LEFT_EYE_SUPPRESSION':
                    return 'Left Eye Suppression';
                case 'ALTERNATE_SUPPRESSION':
                    return 'Alternate Suppression';
                case 'UNCROSSED_DIPLOPIA':
                    return 'Uncrossed Diplopia';
                case 'CROSSED_DIPLOPIA':
                    return 'Crossed Diplopia';
                case 'ABNORMAL_RETINAL_CORRESPONDENCE':
                    return 'Abnormal Retinal Correspondence';
                case 'VERTICAL_DIPLOPIA_WITH_RIGHT_HYPO_TROPIA':
                    return 'Vertical Diplopia with Right Hypo Tropia';
                case 'VERTICAL_DIPLOPIA_WITH_LEFT_HYPO_TROPIA':
                    return 'Vertical Diplopia with Left Hypo Tropia';
                default:
                    return 'Binocular Single Vision';
            }
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (!reportData.tests.fourDotFusion) {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }

            this.config = {
                testData: this.reportData.tests.fourDotFusion,
                systemType: this.reportData.assessment.systemType,
            };
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
