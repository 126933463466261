var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"all-info pa-0"},[_c('v-col',{staticClass:"company-logo-col pa-0",class:{ 'inactive-company': !_vm.active }},[_c('CompanyLogo',{attrs:{"company":_vm.company,"editable":_vm.isEngineer || _vm.isSuperAdmin || _vm.isClientAdmin}})],1),_c('v-col',{staticClass:"long-info-box ma-0 pa-0",class:{ 'inactive-company': !_vm.active }},[_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.name'))+": ")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":true,"content":_vm.name,"characterLimit":100},on:{"save":function($event){_vm.company.name = $event;
                        _vm.saveCompanyDetails();}}})],1)],1),_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.accountNumber'))+":")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.accountNumber}})],1)],1),_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.address1'))+": ")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.address1,"characterLimit":255},on:{"save":function($event){_vm.company.address1 = $event;
                        _vm.saveCompanyDetails();}}})],1)],1),_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.address2'))+": ")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.address2,"characterLimit":255},on:{"save":function($event){_vm.company.address2 = $event;
                        _vm.saveCompanyDetails();}}})],1)],1)],1),_c('v-col',{staticClass:"short-info-box ma-0 pa-0",class:{ 'inactive-company': !_vm.active }},[_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.city'))+": ")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.city,"characterLimit":255},on:{"save":function($event){_vm.company.city = $event;
                        _vm.saveCompanyDetails();}}})],1)],1),_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.state'))+": ")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.state},on:{"save":function($event){_vm.company.state.code = $event;
                        _vm.saveCompanyDetails();}}})],1)],1),_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.country'))+": ")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.country},on:{"save":function($event){_vm.company.country.code = $event;
                        _vm.saveCompanyDetails();}}})],1)],1),_c('v-row',{staticClass:"company-field ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 field-name",attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.$t('companyInfo.fields.zip'))+":")]),_c('v-col',{staticClass:"pa-0 field-value"},[_c('EditableTextField',{attrs:{"editable":false,"content":_vm.zip,"characterLimit":50},on:{"save":function($event){_vm.company.zipCode = $event;
                        _vm.saveCompanyDetails();}}})],1)],1)],1),(_vm.isEngineer || _vm.isSuperAdmin)?_c('v-col',{staticClass:"dropdown-icon-col pa-0",attrs:{"align-self":"start","align":"end"}},[_c('v-menu',{attrs:{"bottom":"","transition":"slide-y-transition","offset-y":true,"offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{ 'dropdown-company-active': _vm.active, 'dropdown-company-inactive': !_vm.active },attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-ellipsis-v")])]}}],null,false,3073093792)},[_c('v-list',{attrs:{"elevation":"10"}},[_c('v-list-item',{staticClass:"dropdown-item"},[(_vm.active)?_c('div',{on:{"click":function($event){return _vm.showSaveConfirmation()}}},[_vm._v(" "+_vm._s(_vm.$t('companyInfo.deactivate.active'))+" ")]):_vm._e(),(!_vm.active)?_c('div',{on:{"click":function($event){return _vm.showSaveConfirmation()}}},[_vm._v(" "+_vm._s(_vm.$t('companyInfo.deactivate.inactive'))+" ")]):_vm._e()])],1)],1)],1):_vm._e(),_c('SaveConfirmation',{attrs:{"show":_vm.showConfirmation,"save":_vm.saveConfirmation,"cancel":_vm.cancelConfirmation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }