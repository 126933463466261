




import Vue, { PropType } from 'vue';

import * as d3 from 'd3';

import * as Constants from '@/common/constants/visualizations.constants';
import * as MathUtils from '@/common/utils/math';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { FixationStabilityConfig } from './FixationStabilityVisualization.vue';
import { ProcessedGazePoint } from '@/common/types/reports/visualData';

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<FixationStabilityConfig>,
        },
        playAnimation: Boolean,
        isPatientPerspective: Boolean,
    },
    data() {
        return {
            visualization: new Visualization(),
            leftPoints: [] as ProcessedGazePoint[],
            rightPoints: [] as ProcessedGazePoint[],
        };
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const ASPECT_RATIO = screenData.width / screenData.height;
            const DEFAULT_ZOOM_LEVEL = 1.0;
            const ZOOM_LEVEL = this.config.systemType === 'I15' ? DEFAULT_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL + 1;

            this.leftPoints = this.config.testData.left.points;
            this.rightPoints = this.config.testData.right.points;

            if (!this.isPatientPerspective) {
                this.leftPoints = this.invertPoints(this.leftPoints);
                this.rightPoints = this.invertPoints(this.rightPoints);
            }

            this.drawEyeCoordinates(ASPECT_RATIO, ZOOM_LEVEL);
        },
        drawEyeCoordinates(aspectRatio: number, zoomLevel: number) {
            const DATA_DEFAULT_ATTRIBUTES = {
                'fill': 'none',
                'stroke-width': 0.002 / zoomLevel,
                'stroke-opacity': 0.8,
            };

            const defaultAttributes = {
                'fill': 'none',
                'stroke': Constants.ORANGE,
                'stroke-width': 0.004 / zoomLevel,
            };

            const lineAttributes = {
                'fill': 'none',
                'stroke': Constants.GREEN,
                'stroke-width': 0.005 / zoomLevel,
            };

            this.visualization.rect({
                position: new Position2d(Constants.ORIGIN.x + 0.13 / zoomLevel, 0.35),
                width: 0.3,
                height: 0.25,
                aspectRatio,
                attributes: {
                    'fill': 'none',
                    'stroke': Constants.GREY,
                    'stroke-width': 0.003,
                },
            });

            this.visualization.line({
                start: [Constants.ORIGIN.x + 0.28 / zoomLevel, 0.35],
                end: [Constants.ORIGIN.x + 0.28 / zoomLevel, 0.685],
                aspectRatio,
                attributes: defaultAttributes,
            });

            this.visualization.line({
                start: [Constants.ORIGIN.x + 0.13 / zoomLevel, 0.5175],
                end: [Constants.ORIGIN.x + 0.43 / zoomLevel, 0.5175],
                aspectRatio,
                attributes: defaultAttributes,
            });

            const { devX: leftDevX, devY: leftDevY } = this.avgDeviation(this.leftPoints);
            this.visualization.circle({
                id: 'left-eye-circle',
                center: [Constants.ORIGIN.x + 0.28 + leftDevX / zoomLevel, 0.5175 - leftDevY],
                radius: 0.015,
                aspectRatio,
                attributes: {
                    ...DATA_DEFAULT_ATTRIBUTES,
                    fill: Constants.LEFT_COLOR,
                },
            });

            const { devX: rightDevX, devY: rightDevY } = this.avgDeviation(this.rightPoints);
            this.visualization.circle({
                id: 'right-eye-circle',
                center: [Constants.ORIGIN.x + 0.28 + rightDevX / zoomLevel, 0.5175 - rightDevY],
                radius: 0.015,
                aspectRatio,
                attributes: {
                    ...DATA_DEFAULT_ATTRIBUTES,
                    fill: Constants.RIGHT_COLOR,
                },
            });

            const { avgAngle, absoluteAngle, headTiltDirection } = this.avgAngle(this.leftPoints, this.rightPoints);
            const avgY = (leftDevY + rightDevY) / 2.0;

            this.visualization.line({
                id: 'line-between-eyes',
                start: [Constants.ORIGIN.x + 0.28 + leftDevX / zoomLevel, 0.5175 - avgY],
                end: [Constants.ORIGIN.x + 0.28 + rightDevX / zoomLevel, 0.5175 - avgY],
                aspectRatio,
                attributes: lineAttributes,
                rotate: absoluteAngle,
            });

            this.visualization.text({
                id: 'angle-text',
                content: '',
                position: [Constants.ORIGIN.x + 0.29 / zoomLevel, 0.72],
                aspectRatio,
                attributes: {
                    'font-size': `${0.002 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
            });

            this.visualization.text({
                id: 'avg-angle-text',
                content: `Avg Angle: ${avgAngle}º (${headTiltDirection} Tilt)`,
                position: [Constants.ORIGIN.x + 0.28 / zoomLevel, 0.32],
                aspectRatio,
                attributes: {
                    'font-size': `${0.0018 / zoomLevel}em`,
                    'font-family': Constants.TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'text-anchor': 'middle',
                    'dominant-baseline': 'central',
                    'letter-spacing': '0',
                },
            });

            this.visualization.zoom(zoomLevel);
            this.visualization.drawNormalized(this.svg, 4 / 3);
        },
        playGazeAnimation() {
            this.drawEyeMovement(this.leftPoints);
            this.drawEyeMovementRight(this.rightPoints);
            this.drawLineBetweenEyes(this.leftPoints, this.rightPoints);
            this.drawAngleText(this.leftPoints, this.rightPoints);
        },
        drawEyeMovement(data: ProcessedGazePoint[]) {
            const leftEyeSelection = d3.select(this.svg).select('#left-eye-circle');
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            for (const point of data) {
                leftEyeSelection
                    .transition()
                    .delay(point.delay * 1000.0)
                    .duration(point.duration * 1000.0)
                    .attr('cx', () => {
                        const devx = MathUtils.mmToPixels(point.eyeX!, screenData.dpi) / screenData.width;
                        return Constants.ORIGIN.x + 0.29 + devx;
                    })
                    .attr('cy', () => {
                        const devy = MathUtils.mmToPixels(point.eyeY!, screenData.dpi) / screenData.height;
                        return (0.5175 - devy) / (4.0 / 3.0);
                    })
                    .end()
                    .then();
            }
            return data[data.length - 1].delay;
        },
        drawEyeMovementRight(data: ProcessedGazePoint[]) {
            const rightEyeSelection = d3.select(this.svg).select('#right-eye-circle');
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            for (const point of data) {
                rightEyeSelection
                    .transition()
                    .delay(point.delay * 1000.0)
                    .duration(point.duration * 1000.0)
                    .attr('cx', () => {
                        const devx = MathUtils.mmToPixels(point.eyeX!, screenData.dpi) / screenData.width;
                        return Constants.ORIGIN.x + 0.29 + devx;
                    })
                    .attr('cy', () => {
                        const devy = MathUtils.mmToPixels(point.eyeY!, screenData.dpi) / screenData.height;
                        return (0.5175 - devy) / (4.0 / 3.0);
                    })
                    .end()
                    .then();
            }
            return data[data.length - 1].delay;
        },
        drawAngleText(leftEye: ProcessedGazePoint[], rightEye: ProcessedGazePoint[]) {
            const textSelection = d3.select(this.svg).select('#angle-text');
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            for (let i = 0; i < leftEye.length; i++) {
                const leftPoint = leftEye[i];
                const rightPoint = rightEye[i];
                const x1 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(leftPoint.eyeX!, screenData.dpi) / screenData.width;
                const y1 =
                    (0.5175 - MathUtils.mmToPixels(leftPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                const x2 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(rightPoint.eyeX!, screenData.dpi) / screenData.width;
                const y2 =
                    (0.5175 - MathUtils.mmToPixels(rightPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                let angle = Math.atan2(y2 - y1, x2 - x1);
                angle = angle * (180.0 / Math.PI);

                let headTiltDirection = 'Right';
                if (angle < 0) {
                    headTiltDirection = 'Left';
                }

                angle = Math.abs(angle);
                if (!this.isPatientPerspective) {
                    angle = 180.0 - angle;
                }
                angle = Math.round(angle * 10) / 10;

                textSelection
                    .transition()
                    .delay(leftPoint.delay * 1000.0)
                    .duration(leftPoint.duration * 1000.0)
                    .text(`Angle: ${angle}º (${headTiltDirection} Tilt)`)
                    .end()
                    .then();

                if (i + 1 === leftEye.length) {
                    textSelection
                        .transition()
                        .delay(leftPoint.delay * 1000.0)
                        .duration(leftPoint.duration * 1000.0)
                        .text('')
                        .end()
                        .then();
                }
            }
        },
        drawLineBetweenEyes(leftEye: ProcessedGazePoint[], rightEye: ProcessedGazePoint[]) {
            const lineSelection = d3.select(this.svg).select('#line-between-eyes');
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            for (let i = 0; i < leftEye.length; i++) {
                const leftPoint = leftEye[i];
                const rightPoint = rightEye[i];

                const x1 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(leftPoint.eyeX!, screenData.dpi) / screenData.width;
                const y1 =
                    (0.5175 - MathUtils.mmToPixels(leftPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                const x2 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(rightPoint.eyeX!, screenData.dpi) / screenData.width;
                const y2 =
                    (0.5175 - MathUtils.mmToPixels(rightPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                let angle = Math.atan2(y2 - y1, x2 - x1);
                angle = angle * (180.0 / Math.PI);
                angle = Math.round(angle * 10) / 10;

                if (!this.isPatientPerspective) {
                    if (angle < 0) {
                        angle = 180.0 - Math.abs(angle);
                    } else {
                        angle = (180.0 - Math.abs(angle)) * -1.0;
                    }
                }

                const avgY = (y1 + y2) / 2.0;

                const midX = (x1 + x2) / 2.0;
                const midY = (y1 + y2) / 2.0;

                let newX1 = x1 - 0.04;
                let newX2 = x2 + 0.02;

                if (!this.isPatientPerspective) {
                    newX1 = x2 - 0.02;
                    newX2 = x1 + 0.04;
                }

                lineSelection
                    .transition()
                    .delay(leftPoint.delay * 1000.0)
                    .duration(leftPoint.duration * 1000.0)
                    .attr('x1', newX1)
                    .attr('y1', avgY)
                    .attr('x2', newX2)
                    .attr('y2', avgY)
                    .attr('transform', `rotate(${angle} ${midX},${midY})`)
                    .end()
                    .then();
            }
        },
        avgAngle(
            leftEye: ProcessedGazePoint[],
            rightEye: ProcessedGazePoint[],
        ): { avgAngle: number; absoluteAngle: number; headTiltDirection: string } {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            if (leftEye.length === 0) {
                return { avgAngle: 0, absoluteAngle: 0, headTiltDirection: 'No' };
            }

            let avgAngle = 0;
            let absoluteAngle = 0;
            for (let i = 0; i < leftEye.length; i++) {
                const leftPoint = leftEye[i];
                const rightPoint = rightEye[i];
                const x1 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(leftPoint.eyeX!, screenData.dpi) / screenData.width;
                const y1 =
                    (0.5175 - MathUtils.mmToPixels(leftPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                const x2 =
                    Constants.ORIGIN.x +
                    0.29 +
                    MathUtils.mmToPixels(rightPoint.eyeX!, screenData.dpi) / screenData.width;
                const y2 =
                    (0.5175 - MathUtils.mmToPixels(rightPoint.eyeY!, screenData.dpi) / screenData.height) / (4.0 / 3.0);

                let angle = Math.atan2(y2 - y1, x2 - x1);
                angle = angle * (180.0 / Math.PI);
                avgAngle += angle;
            }

            avgAngle /= leftEye.length;

            let headTiltDirection = 'Right';
            if (avgAngle < 0) {
                headTiltDirection = 'Left';
            }

            absoluteAngle = avgAngle;
            avgAngle = Math.abs(avgAngle);
            if (!this.isPatientPerspective) {
                avgAngle = 180.0 - avgAngle;
            }

            avgAngle = Math.round(avgAngle * 10) / 10;

            return { avgAngle, absoluteAngle, headTiltDirection };
        },
        redraw() {
            this.visualization.clear();
            this.generateSVG();
        },
        invertPoints(points: ProcessedGazePoint[]): ProcessedGazePoint[] {
            const invertedPoints = [];
            for (const point of points) {
                let eyeX;
                let eyeY;
                if (point.eyeX && point.eyeY) {
                    eyeX = point.eyeX * -1.0;
                    eyeY = point.eyeY;
                }
                const gazePoint = {
                    startPosition: new Position2d(1 - point.startPosition.x, point.startPosition.y),
                    stopPosition: new Position2d(1 - point.stopPosition.x, point.stopPosition.y),
                    duration: point.duration,
                    delay: point.delay,
                    phoria: point.phoria,
                    zDistance: point.zDistance,
                    eyeX,
                    eyeY,
                };

                invertedPoints.push(gazePoint);
            }

            return invertedPoints;
        },
        avgDeviation(points: ProcessedGazePoint[]): { devX: number; devY: number } {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];

            let avgX = 0.0;
            let avgY = 0.0;

            if (points.length === 0) {
                return { devX: avgX, devY: avgY };
            }
            for (const point of points) {
                avgX += MathUtils.mmToPixels(point.eyeX!, screenData.dpi) / screenData.width;
                avgY += MathUtils.mmToPixels(point.eyeY!, screenData.dpi) / screenData.height;
            }

            avgX /= points.length;
            avgY /= points.length;

            return { devX: avgX, devY: avgY };
        },
    },
    watch: {
        playAnimation(shouldPlayAnimation: boolean) {
            if (shouldPlayAnimation) {
                this.playGazeAnimation();
            }
        },
        isPatientPerspective(isPatientPerspective: boolean) {
            this.redraw();
        },
    },
});
