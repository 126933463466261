





































import Vue, { PropType } from 'vue';

import UserApi from '@/api/user.api';

import { UpdateParticipantRequest } from '@/common/types/api/requests/userRequests';
import { UpdateParticipantResponse } from '@/common/types/api/responses/userResponses';
import { ButtonState } from '@/common/types/general';
import { EqtParticipant } from '@/common/types/trainer/eqtParticipant';

import { SET_ERROR_BANNER } from '@/store/general/mutations';

import Logger from '@/common/utils/logger';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import RoundedInput from '@/views/common/controls/RoundedInput.vue';

export default Vue.extend({
    props: {
        show: Boolean,
        participant: Object as PropType<EqtParticipant>,
    },
    components: {
        GreenButton,
        RoundedInput,
    },
    data() {
        return {
            emailAddress: '' as string,
            confirmEmailAddress: '' as string,
            loading: false as boolean,
        };
    },
    computed: {
        saveButtonState(): ButtonState {
            if (this.loading) {
                return 'loading';
            } else {
                return this.emailAddress && this.emailAddress === this.confirmEmailAddress ? 'active' : 'inactive';
            }
        },
    },
    methods: {
        hide() {
            this.$emit('hide');
            this.emailAddress = '';
            this.confirmEmailAddress = '';
        },
        async save() {
            try {
                this.loading = true;
                const request = {
                    id: this.participant.participantId,
                    patientId: this.participant.patientId,
                    companyId: this.participant.companyId,
                    email: this.emailAddress,
                    firstName: this.participant.firstName,
                    lastName: this.participant.lastName,
                } as UpdateParticipantRequest;
                const response: UpdateParticipantResponse = await UserApi.updateParticipant(request);
                this.$emit('onUserRegistration', response);
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.loading = false;
                this.hide();
            }
        },
    },
});
