
import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';
import * as FormatUtils from '@/common/utils/reports/format';

import { Line } from 'vue-chartjs';
import { MetricData } from '@/common/types/reports/metricData';

export interface ReactionTimeConfig {
    choiceReactionTime: MetricData;
    discriminateReactionTime: MetricData;
}

export default Vue.extend({
    mixins: [Line],
    props: {
        config: {
            required: false,
            type: Object as PropType<ReactionTimeConfig>,
        },
    },
    mounted() {
        const labels = [] as string[];
        const crtvalues = [] as number[];
        const drtvalues = [] as any[];

        // Extract, parse, filter, and sort crt and drt metrics
        let crtMetrics = Object.entries(this.config.choiceReactionTime).map(([metric, value]: any) => {
            if (FormatUtils.isValidValue(value.time)) {
                metric = parseInt(metric.replace('saccadicLatency', ''), 10);
                return [metric, value.time];
            }
        });
        crtMetrics = crtMetrics.filter((m) => m !== undefined);
        crtMetrics.map(([metric, value]: any) => {
            labels.push(metric);
            crtvalues.push(value);
            drtvalues.push(null); // Add null to the second dataset to make it looks like its in the same dataset
        });

        let drtMetrics = Object.entries(this.config.discriminateReactionTime).map(([metric, value]: any) => {
            if (FormatUtils.isValidValue(value.time)) {
                metric = parseInt(metric.replace('saccadicLatency', ''), 10);
                return [metric, value.time];
            }
        });
        drtMetrics = drtMetrics.filter((m) => m !== undefined);
        drtMetrics.sort((rhs: any, lhs: any) => (rhs[0] < lhs[0] ? -1 : 1));
        drtMetrics.map(([metric, value]: any) => {
            labels.push(metric);
            drtvalues.push(value);
        });

        const data = {
            labels,
            datasets: [
                {
                    label: this.$t('reports.charts.saccadicLatency.choiceReactionTime'),
                    data: crtvalues,
                    lineTension: 0,
                    fill: false,
                    borderColor: Constants.RIGHT_COLOR,
                },
                {
                    label: this.$t('reports.charts.saccadicLatency.discriminateReactionTime'),
                    data: drtvalues,
                    lineTension: 0,
                    fill: false,
                    borderColor: Constants.LEFT_COLOR,
                },
            ],
        };

        const maxHeightBuffer = Math.max(...crtvalues, ...drtvalues) * 1.1;
        const minHeightBuffer = Math.min(...crtvalues, ...drtvalues.filter((d) => d)) * 0.9; // Remove null's before math
        const options = {
            responsive: true,
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: this.$t('reports.charts.saccadicLatency.saccadicLatency'),
                        },
                        display: true,
                        ticks: {
                            suggestedMax: maxHeightBuffer,
                            suggestedMin: minHeightBuffer,
                        },
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: this.$t('reports.charts.saccadicLatency.trials'),
                        },
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
            legend: {
                align: 'right',
            },
        };

        // @ts-ignore Typescript does not like mixins
        this.renderChart(data, options); // From vue-chartjs/Line mixin
    },
});
