







import Vue from 'vue';

import AllAppVersions from '@/views/superAdmin/components/tabs/AllAppVersions.vue';
import EnvironmentAppVersions from '@/views/superAdmin/components/datatables/EnvironmentAppVersions.vue';

import { AppVersions } from '@/common/types/appVersions/appVersions';

import * as Nav from '@/store/navigation/mutations';

import AppVersionApi from '@/api/appVersions.api';

export default Vue.extend({
    components: {
        EnvironmentAppVersions,
        AllAppVersions,
    },
    data() {
        return {
            loading: false as boolean,
            appVersions: {} as AppVersions,
        };
    },
    methods: {
        async loadAppVersions() {
            this.appVersions = await AppVersionApi.getAppVersions();
        },
    },
    async mounted() {
        this.loading = true;

        this.$store.commit(Nav.SHOW_LEFT_NAV);
        await this.loadAppVersions();

        this.loading = false;
    },
});
