import CycleModel from './cycleModel';
import { CycleResponse } from '@/common/types/api/responses/trainerResponses';

export default class CycleDetails {
    private readonly cycles: CycleModel[];
    private currentCycle: CycleModel | undefined;

    public constructor(cycles: CycleResponse[]) {
        this.cycles = cycles.map((cycle) => new CycleModel(cycle));
    }

    public get CurrentCycle(): CycleModel | null {
        if (!this.currentCycle) {
            this.currentCycle = this.cycles.find((cycle) => cycle.IsActive);
        }
        return this.currentCycle ?? null;
    }

    public get AllCycles(): CycleModel[] {
        return this.cycles;
    }

    public get CycleEnd(): string | null {
        const currentCycle = this.CurrentCycle;
        return currentCycle?.EndDate ?? null;
    }

    public get NumberOfCycles(): number {
        return this.cycles.length;
    }
}
