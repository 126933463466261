


























import Vue, { PropType } from 'vue';

import * as d3 from 'd3';

import * as Constants from '@/common/constants/visualizations.constants';
import { ProcessedGazePoint, ProcessedVisualData } from '@/common/types/reports/visualData';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { Position2d } from '@/common/types/reports/position';
import PerspectiveImageVisualization from '@/views/reports/visualizations/PerspectiveImageVisualization.vue';
import VergenceVisualization, { VergenceConfig } from '@/views/reports/visualizations/VergenceVisualization.vue';

export interface VerticalSmoothPursuitConfig {
    testData: ProcessedVisualData;
    systemType: string;
}

export default Vue.extend({
    components: {
        PerspectiveImageVisualization,
        VergenceVisualization,
    },
    props: {
        playAnimation: Boolean,
        config: {
            type: Object as PropType<VerticalSmoothPursuitConfig>,
        },
        vergenceConfig: {
            type: Object as PropType<VergenceConfig>,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            visualization: new Visualization(),
        };
    },
    mounted() {
        this.generateSVG();
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        perspectiveImageSize(): number {
            return 0.1;
        },
        perspectiveImageLocation(): Position2d {
            return new Position2d(0.83, 0.61);
        },
        labelFontSize(): number {
            return 0.0018;
        },
        perspectiveLabelLocation(): Position2d {
            return new Position2d(0.7, 0.74);
        },
        showAdditionalViz(): boolean {
            return this.reportType === 'standard' || this.reportType === 'sensorimotorExam';
        },
    },
    watch: {
        playAnimation(val: boolean) {
            if (val) {
                this.playGazeAnimation();
            }
        },
    },
    methods: {
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const ASPECT_RATIO = screenData.width / screenData.height;
            const ZOOM_LEVEL = this.config.systemType === 'I15' ? 1 : 1;

            const REFERENCE_DEFAULT_ATTRIBUTES = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.004 / ZOOM_LEVEL,
                'stroke-opacity': 0.6,
            };
            const DATA_DEFAULT_ATTRIBUTES = {
                'fill': 'none',
                'stroke-width': 0.004 / ZOOM_LEVEL,
                'stroke-opacity': 0.8,
            };

            const REFERENCE_LINE_START = new Position2d(0.5, 0);
            const REFERENCE_LINE_END = new Position2d(0.5, 1);

            const leftPoints = this.isPatientPerspective
                ? this.invertPoints(this.config.testData.left.points)
                : this.config.testData.left.points;
            const rightPoints = this.isPatientPerspective
                ? this.invertPoints(this.config.testData.right.points)
                : this.config.testData.right.points;

            const EYES = [
                {
                    DATA: rightPoints,
                    OFFSET: this.isPatientPerspective ? 0.15 : -0.15,
                    COLOR: Constants.RIGHT_COLOR,
                    ID: Constants.RIGHT_GAZE_TRAIL_ID,
                    LABEL: this.$t('reports.metrics.eye.right').toString(),
                    LABEL_OFFSET: this.isPatientPerspective ? 0.35 : -0.35,
                },
                {
                    DATA: leftPoints,
                    OFFSET: this.isPatientPerspective ? -0.15 : 0.15,
                    COLOR: Constants.LEFT_COLOR,
                    ID: Constants.LEFT_GAZE_TRAIL_ID,
                    LABEL: this.$t('reports.metrics.eye.left').toString(),
                    LABEL_OFFSET: this.isPatientPerspective ? -0.35 : 0.35,
                },
            ];

            const visualization = this.visualization;
            for (const eye of EYES) {
                visualization.line({
                    start: [REFERENCE_LINE_START.x + eye.OFFSET / ZOOM_LEVEL, REFERENCE_LINE_START.y],
                    end: [REFERENCE_LINE_END.x + eye.OFFSET / ZOOM_LEVEL, REFERENCE_LINE_END.y],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...REFERENCE_DEFAULT_ATTRIBUTES,
                        'stroke': 'black',
                        'stroke-dasharray': `${Constants.STROKE_DASH_LENGTH / ZOOM_LEVEL}em`,
                    },
                });
                visualization.path({
                    points: eye.DATA,
                    aspectRatio: ASPECT_RATIO,
                    id: eye.ID,
                    translate: new Position2d(eye.OFFSET / ZOOM_LEVEL, 0),
                    attributes: {
                        ...DATA_DEFAULT_ATTRIBUTES,
                        stroke: eye.COLOR,
                    },
                });
                visualization.text({
                    content: eye.LABEL,
                    position: [Constants.ORIGIN.x + eye.LABEL_OFFSET / ZOOM_LEVEL, Constants.ORIGIN.y],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${Constants.EYE_LABEL_FONT_SIZE}em`,
                        'font-family': Constants.TEXT_FONT_FAMILY,
                        'font-weight': 'bold',
                    },
                    styles: {
                        'fill': eye.COLOR,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                        'letter-spacing': '0',
                    },
                });
            }

            visualization.zoom(ZOOM_LEVEL);
            visualization.drawNormalized(this.svg, ASPECT_RATIO);
        },
        playGazeAnimation() {
            const pathSelection = d3.select(this.svg).selectAll('path');
            const totalLength = [] as any[];
            pathSelection.nodes().forEach((n: any) => {
                totalLength.push(n.getTotalLength());
            });

            pathSelection
                .attr('stroke-dasharray', (d: any, i: any) => totalLength[i])
                .attr('stroke-dashoffset', (d: any, i: any) => totalLength[i])
                .transition()
                .duration((d: any) => d.duration)
                .delay((d: any) => d.delay)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0)
                .end()
                .then(() => this.$emit('animationFinished'));
        },
        redraw() {
            this.visualization.clear();
            this.generateSVG();
        },
        changePerspective(isPatientPerspective: boolean) {
            this.isPatientPerspective = isPatientPerspective;
            this.redraw();
        },
        invertPoints(points: ProcessedGazePoint[]): ProcessedGazePoint[] {
            const invertedPoints = [];
            for (const point of points) {
                const gazePoint = {
                    startPosition: new Position2d(1 - point.startPosition.x, point.startPosition.y),
                    stopPosition: new Position2d(1 - point.stopPosition.x, point.stopPosition.y),
                    duration: point.duration,
                    delay: point.delay,
                    phoria: point.phoria,
                    zDistance: point.zDistance,
                };

                invertedPoints.push(gazePoint);
            }

            return invertedPoints;
        },
    },
});
