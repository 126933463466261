// Environment variables
declare const logToConsole: boolean;
declare const datadogEnv: string;
declare const datadogHostname: string;

const datadogApiKey: string = '7a72f88e5cb9dc187b2146f2de586440';
const datadogApiUrl: string = `https://http-intake.logs.datadoghq.com/v1/input/${datadogApiKey}`;
const ddsource: string = 'javascript';
const service: string = 'dashboard';

export class Logger {
    public debug(message: string, details?: any) {
        this.log('debug', message, details);
    }

    public info(message: string, details?: any) {
        this.log('info', message, details);
    }

    public warning(message: string, details?: any) {
        this.log('warn', message, details);
    }

    public error(message: string, details?: any) {
        this.log('error', message, details);
    }

    private log(level: LogLevel, message: string, details?: any) {
        const dateTime = new Date();

        if (logToConsole) {
            console[level](message);
        }

        if (datadogEnv !== 'local') {
            this.postToDatadog(level, message, dateTime, details);
        }
    }

    private async postToDatadog(level: LogLevel, message: string, dateTime: Date, details?: any): Promise<Response> {
        const tags = 'env:' + datadogEnv;
        const logEntry = {
            message,
            ddsource,
            service,
            tags,
            hostname: datadogHostname,
            dateTime,
            status: level,
            details,
        };

        return fetch(datadogApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logEntry),
        });
    }
}

export default new Logger();

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export interface DatadogEntry {
    message: string;
    ddsource: string;
    service: string;
    ddtags: string;
    hostname: string;
    dateTime: Date;
    level: LogLevel;
    details: any;
}
