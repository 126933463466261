


















































































































import Vue from 'vue';

import CompanyLogo from '@/views/common/components/CompanyLogo.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import NewCompanyModal from '@/views/common/components/NewCompanyModal.vue';

import CompanyApi from '@/api/company.api';

import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import { Company } from '@/common/types/company/company';

import * as DataTableUtils from '@/common/utils/datatables';

const DATATABLE_OPTIONS_STORE_KEY = 'COMPANIES';

export default Vue.extend({
    components: {
        LoadingCircle,
        CompanyLogo,
        GreenButton,
        NewCompanyModal,
    },
    data() {
        return {
            itemsPerPageArray: [12, 24, 48],
            totalCompanies: 0 as number,
            options: {} as any,
            awaitingSearch: false,
            defaultSortOrder: 'ASC',
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 12,
            sortBy: 'Name',
            keys: ['Name', 'Account Number'],
            items: [] as Company[],
            user: {} as AuthenticatedUser,
            loading: true,
            showNewCompany: false,
        };
    },
    async mounted() {
        this.loading = true;
        const cache = DataTableUtils.getDataTableOptionsCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
        this.options = cache.options;
        this.search = cache.search;
        this.user = this.$store.getters.currentUser;
        await this.getDataFromApi();
        this.loading = false;
    },
    methods: {
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) {
                this.page += 1;
            }
        },
        formerPage() {
            if (this.page - 1 >= 1) {
                this.page -= 1;
            }
        },
        updateItemsPerPage(number: number) {
            this.itemsPerPage = number;
        },
        goToCompanyPage(company: Company) {
            this.$router.push({ name: 'assessments', params: { companyId: company.id } });
        },
        goToCompanySettingsPage(company: Company) {
            this.$router.push({ name: 'settings', params: { companyId: company.id } });
        },
        setCompanyModal(show: boolean) {
            this.showNewCompany = show;
        },
        async getDataFromApi() {
            this.loading = true;

            const companiesPage = await CompanyApi.getCompaniesPaginated(
                this.page,
                this.itemsPerPage,
                this.search,
                this.sortBy,
                this.defaultSortOrder,
            );
            this.items = companiesPage.data;
            this.totalCompanies = companiesPage.totalRecords;
            this.loading = false;
        },
    },
    computed: {
        numberOfPages(): number {
            return Math.ceil(this.totalCompanies / this.itemsPerPage);
        },
    },
    watch: {
        search() {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.page = 1;
                    this.getDataFromApi();
                    DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
                    this.awaitingSearch = false;
                }, 1500); // 1.5 sec delay
            }
            this.awaitingSearch = true;
        },
        options: {
            handler() {
                this.getDataFromApi();
                DataTableUtils.setDataTableCache(DATATABLE_OPTIONS_STORE_KEY, this.options, this.search);
            },
            deep: true,
        },
        page() {
            if (!this.awaitingSearch) {
                this.getDataFromApi();
            }
        },
        itemsPerPage() {
            this.getDataFromApi();
        },
    },
});
