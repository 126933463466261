















import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';
import * as Constants from '@/common/constants/visualizations.constants';
import { TestData } from '@/common/types/reports/reportData';
import { Position2d } from '@/common/types/reports/position';
import { CalibrationData, CalibrationPoint } from '@/common/types/reports/visualData';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { Text } from '@/common/utils/reports/visualization/elements/text';
import { Line } from '@/common/utils/reports/visualization/elements/line';
import { Circle } from '@/common/utils/reports/visualization/elements/circle';
import { Rect } from '@/common/utils/reports/visualization/elements/rect';
import { VisualizationElement } from '@/common/utils/reports/visualization/elements';
import ScreenData from '@/common/types/reports/screenData';
import PerspectiveImageVisualization from '@/views/reports/visualizations/PerspectiveImageVisualization.vue';
import * as d3 from 'd3';

export interface NinePointMotorFunctionConfig {
    testData: TestData;
    systemType: string;
}

const CALIBRATION_POINT_NUMBER_TO_NAME = {
    1: 'Midline/Primary',
    2: 'Superior Left',
    3: 'Superior Right',
    4: 'Inferior Left',
    5: 'Inferior Right',
    6: 'Midline Left',
    7: 'Superior Midline',
    8: 'Midline Right',
    9: 'Inferior Midline',
} as Record<number, string>;

const DIRECTIONS = [
    'Midline Primary',
    'Superior Left',
    'Superior Right',
    'Inferior Left',
    'Inferior Right',
    'Midline Left',
    'Superior Midline',
    'Midline Right',
    'Inferior Midline',
];

export default Vue.extend({
    components: {
        PerspectiveImageVisualization,
    },
    props: {
        config: {
            type: Object as PropType<NinePointMotorFunctionConfig>,
        },
        showCalibrationLabels: {
            type: Boolean,
        },
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        calibrationLabelOpacity(): number {
            if (this.showCalibrationLabels) {
                return 1;
            } else {
                return 0;
            }
        },
        perspectiveImageSize(): number {
            return 0.12;
        },
        perspectiveImageLocation(): Position2d {
            return new Position2d(0.2, 0.55);
        },
        labelFontSize(): number {
            return 0.0023;
        },
        perspectiveLabelLocation(): Position2d {
            return new Position2d(0.04, 0.71);
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            visualization: new Visualization(),
        };
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        changePerspective(isPatientPerspective: boolean) {
            this.isPatientPerspective = isPatientPerspective;
            this.redraw();
        },
        redraw() {
            this.visualization.clear();
            d3.select('body').selectAll('#circle-tooltip').remove();
            this.generateSVG();
        },
        invertPerspective(point: number): number {
            return 1.0 - point;
        },
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const ASPECT_RATIO = screenData.width / screenData.height;
            const DEFAULT_ZOOM_LEVEL = 0.3;
            const ZOOM_LEVEL =
                this.config.systemType === 'I15' ? DEFAULT_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL * ASPECT_RATIO;

            if (!this.config.testData) {
                throw new Error('This assessment is missing Calibration data.');
            }

            const calibrationData = this.config.testData.eyeTrackingData as CalibrationData;
            const maybePoints = [
                calibrationData.midlinePrimary,
                calibrationData.superiorLeft,
                calibrationData.superiorRight,
                calibrationData.inferiorLeft,
                calibrationData.inferiorRight,
                calibrationData.midlineLeft,
                calibrationData.superiorMidline,
                calibrationData.midlineRight,
                calibrationData.inferiorMidline,
            ];
            const points = maybePoints.filter((point) => point !== undefined).map((point) => point!);

            const stimulusRadius = 0.12;
            const stimulusTextOffset = stimulusRadius * 1.75;
            const fontSize = 0.0007;
            const smallerFontSize = 0.0006;
            const gazeRadius = 0.01;

            const defaultAttributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.001 / ZOOM_LEVEL,
            };

            const textPositioning = new Position2d(0.25, 0.0);
            const textVisualization = this.buildTextVisualization(textPositioning, screenData);

            const linePositioning = new Position2d(0.6, -0.38);
            const lineVisualization = this.buildLineVisualization(linePositioning, points, screenData);

            const leftEyeAttributes = {
                'fill': '#2faecc',
                'stroke': 'none',
                'stroke-width': 0.001 / ZOOM_LEVEL,
            };

            const rightEyeAttributes = {
                'fill': '#166888',
                'stroke': 'none',
                'stroke-width': 0.001 / ZOOM_LEVEL,
            };

            this.visualization
                .map(points, (point) => {
                    const normalizedStimulus = VisualDataUtils.normalizePosition(
                        new Position2d(point.stimulusPosition.x, point.stimulusPosition.y),
                        screenData,
                    );

                    const normalizedLeftEye = VisualDataUtils.normalizePosition(
                        new Position2d(point.leftGazePosition.x, point.leftGazePosition.y),
                        screenData,
                    );

                    const normalizedRightEye = VisualDataUtils.normalizePosition(
                        new Position2d(point.rightGazePosition.x, point.rightGazePosition.y),
                        screenData,
                    );

                    const stimulusAttributes = {
                        'fill': 'none',
                        'stroke': 'black',
                        'stroke-width': 0.001 / ZOOM_LEVEL,
                    };

                    const yOffset = 0.3;

                    const stimuliVisualization = new Visualization();

                    if (point.pointNumber === 1) {
                        stimuliVisualization
                            .circle({
                                center: [normalizedStimulus.x - 1.0, normalizedStimulus.y - yOffset],
                                radius: stimulusRadius,
                                aspectRatio: ASPECT_RATIO,
                                attributes: {
                                    ...stimulusAttributes,
                                    stroke: '#CCCCCC',
                                },
                            })
                            .circle({
                                center: [normalizedStimulus.x - 1.0, normalizedStimulus.y - yOffset],
                                radius: stimulusRadius / 1.5,
                                aspectRatio: ASPECT_RATIO,
                                attributes: {
                                    ...stimulusAttributes,
                                    stroke: '#CCCCCC',
                                },
                            })
                            .circle({
                                center: [normalizedStimulus.x - 1.0, normalizedStimulus.y - yOffset],
                                radius: stimulusRadius / 4.0,
                                aspectRatio: ASPECT_RATIO,
                                attributes: {
                                    ...stimulusAttributes,
                                    'stroke-width': 0.0012 / ZOOM_LEVEL,
                                },
                            })

                            .line({
                                start: [
                                    normalizedStimulus.x - 1.0,
                                    normalizedStimulus.y - stimulusRadius * ASPECT_RATIO - yOffset,
                                ],
                                end: [
                                    normalizedStimulus.x - 1.0,
                                    normalizedStimulus.y + stimulusRadius * ASPECT_RATIO - yOffset,
                                ],
                                aspectRatio: ASPECT_RATIO,
                                attributes: {
                                    ...defaultAttributes,
                                    stroke: '#CCCCCC',
                                },
                            })
                            .line({
                                start: [normalizedStimulus.x - stimulusRadius - 1.0, normalizedStimulus.y - yOffset],
                                end: [normalizedStimulus.x + stimulusRadius - 1.0, normalizedStimulus.y - yOffset],
                                aspectRatio: ASPECT_RATIO,
                                attributes: {
                                    ...defaultAttributes,
                                    stroke: '#CCCCCC',
                                },
                            })
                            .line({
                                start: [
                                    normalizedStimulus.x - 1.0,
                                    normalizedStimulus.y - (stimulusRadius / 4.0) * ASPECT_RATIO - yOffset,
                                ],
                                end: [
                                    normalizedStimulus.x - 1.0,
                                    normalizedStimulus.y + (stimulusRadius / 4.0) * ASPECT_RATIO - yOffset,
                                ],
                                aspectRatio: ASPECT_RATIO,
                                attributes: defaultAttributes,
                            })
                            .line({
                                start: [
                                    normalizedStimulus.x - stimulusRadius / 4.0 - 1.0,
                                    normalizedStimulus.y - yOffset,
                                ],
                                end: [
                                    normalizedStimulus.x + stimulusRadius / 4.0 - 1.0,
                                    normalizedStimulus.y - yOffset,
                                ],
                                aspectRatio: ASPECT_RATIO,
                                attributes: defaultAttributes,
                            });
                    } else {
                        stimuliVisualization
                            .circle({
                                center: [normalizedStimulus.x - 1.0, normalizedStimulus.y - yOffset],
                                radius: stimulusRadius,
                                aspectRatio: ASPECT_RATIO,
                                attributes: stimulusAttributes,
                            })
                            .circle({
                                center: [normalizedStimulus.x - 1.0, normalizedStimulus.y - yOffset],
                                radius: stimulusRadius / 1.5,
                                aspectRatio: ASPECT_RATIO,
                                attributes: stimulusAttributes,
                            })
                            .circle({
                                center: [normalizedStimulus.x - 1.0, normalizedStimulus.y - yOffset],
                                radius: stimulusRadius / 4.0,
                                aspectRatio: ASPECT_RATIO,
                                attributes: stimulusAttributes,
                            })
                            .line({
                                start: [
                                    normalizedStimulus.x - 1.0,
                                    normalizedStimulus.y - stimulusRadius * ASPECT_RATIO - yOffset,
                                ],
                                end: [
                                    normalizedStimulus.x - 1.0,
                                    normalizedStimulus.y + stimulusRadius * ASPECT_RATIO - yOffset,
                                ],
                                aspectRatio: ASPECT_RATIO,
                                attributes: defaultAttributes,
                            })
                            .line({
                                start: [normalizedStimulus.x - stimulusRadius - 1.0, normalizedStimulus.y - yOffset],
                                end: [normalizedStimulus.x + stimulusRadius - 1.0, normalizedStimulus.y - yOffset],
                                aspectRatio: ASPECT_RATIO,
                                attributes: defaultAttributes,
                            });
                    }

                    return stimuliVisualization
                        .circle({
                            center: [
                                this.isPatientPerspective
                                    ? normalizedLeftEye.x - 1.0
                                    : this.invertPerspective(normalizedLeftEye.x) - 1.0,
                                normalizedLeftEye.y - yOffset,
                            ],
                            radius: gazeRadius,
                            aspectRatio: ASPECT_RATIO,
                            attributes: leftEyeAttributes,
                            calibrationPointLabel: `${CALIBRATION_POINT_NUMBER_TO_NAME[point.pointNumber]} (Left Eye)`,
                        })
                        .circle({
                            center: [
                                this.isPatientPerspective
                                    ? normalizedRightEye.x - 1.0
                                    : this.invertPerspective(normalizedRightEye.x) - 1.0,
                                normalizedRightEye.y - yOffset,
                            ],
                            radius: gazeRadius,
                            aspectRatio: ASPECT_RATIO,
                            attributes: rightEyeAttributes,
                            calibrationPointLabel: `${CALIBRATION_POINT_NUMBER_TO_NAME[point.pointNumber]} (Right Eye)`,
                        })
                        .text({
                            id: 'no-calibration-label',
                            content: CALIBRATION_POINT_NUMBER_TO_NAME[point.pointNumber],
                            position: [
                                this.isPatientPerspective
                                    ? normalizedStimulus.x - 1.0
                                    : this.invertPerspective(normalizedStimulus.x) - 1,
                                normalizedStimulus.y - stimulusTextOffset - yOffset,
                            ],
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                'font-size': `${fontSize / ZOOM_LEVEL}em`,
                                'font-family': 'sans-serif',
                            },
                            styles: {
                                'fill': 'black',
                                'text-anchor': 'middle',
                                'letter-spacing': '0',
                                'opacity': 1 - this.calibrationLabelOpacity,
                            },
                        })
                        .text({
                            id: 'calibration-label',
                            content: `${point.pointNumber} - ${CALIBRATION_POINT_NUMBER_TO_NAME[point.pointNumber]}`,
                            position: [
                                this.isPatientPerspective
                                    ? normalizedStimulus.x - 1.0
                                    : this.invertPerspective(normalizedStimulus.x) - 1,
                                normalizedStimulus.y - stimulusTextOffset - yOffset,
                            ],
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                'font-size': `${fontSize / ZOOM_LEVEL}em`,
                                'font-family': 'sans-serif',
                            },
                            styles: {
                                'fill': 'black',
                                'text-anchor': 'middle',
                                'letter-spacing': '0',
                                'opacity': this.calibrationLabelOpacity,
                            },
                        })
                        .text({
                            id: 'calibration-label',
                            content: `${point.pointNumber}`,
                            position: [
                                this.isPatientPerspective
                                    ? normalizedLeftEye.x - 1
                                    : this.invertPerspective(normalizedLeftEye.x) - 1.0,
                                normalizedLeftEye.y - 0.32,
                            ],
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                'font-size': `${smallerFontSize / ZOOM_LEVEL}em`,
                                'font-family': 'sans-serif',
                                'font-weight': 'bold',
                            },
                            styles: {
                                'fill': 'black',
                                'text-anchor': 'middle',
                                'letter-spacing': '0',
                                'opacity': this.calibrationLabelOpacity,
                            },
                        })
                        .text({
                            id: 'calibration-label',
                            content: `${point.pointNumber}`,
                            position: [
                                this.isPatientPerspective
                                    ? normalizedRightEye.x - 1
                                    : this.invertPerspective(normalizedRightEye.x) - 1.0,
                                normalizedRightEye.y - 0.32,
                            ],
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                'font-size': `${smallerFontSize / ZOOM_LEVEL}em`,
                                'font-family': 'sans-serif',
                                'font-weight': 'bold',
                            },
                            styles: {
                                'fill': 'black',
                                'text-anchor': 'middle',
                                'letter-spacing': '0',
                                'opacity': this.calibrationLabelOpacity,
                            },
                        });
                })
                .zoom(ZOOM_LEVEL)
                .with(textVisualization)
                .with(lineVisualization)
                .circle({
                    center: [-0.67, 0.88],
                    radius: gazeRadius,
                    aspectRatio: ASPECT_RATIO,
                    attributes: leftEyeAttributes,
                })
                .text({
                    content: 'Left Eye',
                    position: [-0.58, 0.9],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize / ZOOM_LEVEL}em`,
                        'font-family': 'sans-serif',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .circle({
                    center: [-0.45, 0.88],
                    radius: gazeRadius,
                    aspectRatio: ASPECT_RATIO,
                    attributes: rightEyeAttributes,
                })
                .text({
                    content: 'Right Eye',
                    position: [-0.35, 0.9],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize / ZOOM_LEVEL}em`,
                        'font-family': 'sans-serif',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'letter-spacing': '0',
                    },
                })
                .drawNormalized(this.svg, 6 / 2);
        },
        buildTextVisualization(coordinates: Position2d, screenData: ScreenData): Visualization {
            const ASPECT_RATIO = screenData.width / screenData.height;
            const fontSize = 0.0025;

            const attributes = {
                'font-size': `${fontSize}em`,
                'font-family': 'sans-serif',
                'font-weight': 'regular',
            };

            const styles = {
                'fill': 'black',
                'text-anchor': 'start',
                'letter-spacing': '0',
            };

            const textPadding = new Position2d(0.012, -0.35);
            const wordOffset = 0.13;

            const textElements: VisualizationElement[] = [];

            for (let i = 0; i < DIRECTIONS.length; i++) {
                textElements.push(
                    new Text({
                        content: DIRECTIONS[i],
                        position: [coordinates.x + textPadding.x, coordinates.y + textPadding.y + wordOffset * i],
                        aspectRatio: ASPECT_RATIO,
                        attributes,
                        styles,
                    }),
                );
            }

            return new Visualization().addAll(textElements);
        },
        buildLineVisualization(
            coordinates: Position2d,
            points: CalibrationPoint[],
            screenData: ScreenData,
        ): Visualization {
            const ASPECT_RATIO = screenData.width / screenData.height;
            const sensorimotorResult = this.config.testData.metrics.sensorimotorResult.sensorimotorResult;

            const attributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.003,
            };

            const fontSize = 0.002;
            const largerFontSize = 0.0025;
            const textOffset = 0.1;
            const textAttributes = {
                'font-size': `${fontSize}em`,
                'font-family': 'sans-serif',
                'font-weight': 'regular',
            };

            const guidanceTextAttributes = {
                'font-size': `${largerFontSize}em`,
                'font-family': 'sans-serif',
                'font-weight': 'bold',
            };

            const styles = {
                'fill': 'black',
                'text-anchor': 'start',
                'letter-spacing': '0',
            };

            const resultAttributes = {
                'fill': 'black',
                'stroke': 'none',
                'stroke-width': 0.04,
            };

            const resultRadius = 0.02;
            const resultOffset = 0.25;

            const lineLength = 1.5;
            const lineDistance = 0.132;

            const lineElements: VisualizationElement[] = [];
            const squareElements: VisualizationElement[] = [];
            const sensorimotorResultPoints = [];

            for (let i = 0; i < 9; i++) {
                lineElements.push(
                    new Line({
                        start: [coordinates.x, coordinates.y + lineDistance * i],
                        end: [coordinates.x + lineLength, coordinates.y + lineDistance * i],
                        aspectRatio: ASPECT_RATIO,
                        attributes,
                    }),
                );

                squareElements.push(
                    new Rect({
                        position: new Position2d(coordinates.x, coordinates.y - 0.015 + lineDistance * i),
                        width: 0.03,
                        height: 0.03,
                        aspectRatio: ASPECT_RATIO,
                        attributes: {
                            fill: Constants.BLACK,
                        },
                        angle: 45,
                    }),
                );

                squareElements.push(
                    new Rect({
                        position: new Position2d(coordinates.x + lineLength, coordinates.y - 0.015 + lineDistance * i),
                        width: 0.03,
                        height: 0.03,
                        aspectRatio: ASPECT_RATIO,
                        attributes: {
                            fill: Constants.BLACK,
                        },
                        angle: 45,
                    }),
                );

                sensorimotorResultPoints.push(new Position2d(0.0, coordinates.y + lineDistance * i));
            }

            for (const point of points) {
                const sensorimotorPoint = sensorimotorResult[point.pointNumber - 1].sensorimotorPoint;
                let sensorimotorResultPosition = new Position2d(0.0, coordinates.y);
                if (sensorimotorPoint === 'ONE_EYE') {
                    sensorimotorResultPosition = new Position2d(
                        coordinates.x + lineLength / 3.0,
                        coordinates.y + lineDistance * (point.pointNumber - 1),
                    );
                } else if (sensorimotorPoint === 'BOTH_EYES') {
                    sensorimotorResultPosition = new Position2d(
                        coordinates.x + lineLength / 1.5,
                        coordinates.y + lineDistance * (point.pointNumber - 1),
                    );
                } else if (sensorimotorPoint === 'NONE') {
                    sensorimotorResultPosition = new Position2d(
                        coordinates.x,
                        coordinates.y + lineDistance * (point.pointNumber - 1),
                    );
                }

                sensorimotorResultPoints[point.pointNumber - 1] = sensorimotorResultPosition;
            }

            const circleElements: VisualizationElement[] = [];
            for (const point of sensorimotorResultPoints) {
                circleElements.push(
                    new Circle({
                        center: [point.x + resultOffset, point.y],
                        radius: resultRadius,
                        aspectRatio: ASPECT_RATIO,
                        attributes: resultAttributes,
                    }),
                );
            }

            return new Visualization()
                .line({
                    start: [coordinates.x + lineLength / 3.0, coordinates.y - 0.1],
                    end: [coordinates.x + lineLength / 3.0, coordinates.y + 1.15],
                    aspectRatio: ASPECT_RATIO,
                    attributes,
                })
                .line({
                    start: [coordinates.x + lineLength / 1.5, coordinates.y - 0.1],
                    end: [coordinates.x + lineLength / 1.5, coordinates.y + 1.15],
                    aspectRatio: ASPECT_RATIO,
                    attributes,
                })
                .text({
                    content: `Neither Eye On Target`,
                    position: [coordinates.x + textOffset, coordinates.y - 0.05],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles,
                })
                .text({
                    content: `One Eye On Target`,
                    position: [coordinates.x + lineLength / 3.0 + textOffset, coordinates.y - 0.05],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles,
                })
                .text({
                    content: `Both Eyes On Target`,
                    position: [coordinates.x + lineLength / 1.5 + textOffset, coordinates.y - 0.05],
                    aspectRatio: ASPECT_RATIO,
                    attributes: textAttributes,
                    styles,
                })
                .text({
                    content: `Failure Occurs if both eyes miss 1 or more targets, or if one eye misses 2 or more targets`,
                    position: [coordinates.x - 0.15, coordinates.y + 1.25],
                    aspectRatio: ASPECT_RATIO,
                    attributes: guidanceTextAttributes,
                    styles,
                })
                .addAll(lineElements)
                .addAll(squareElements)
                .addAll(circleElements);
        },
        drawCalibrationLabels() {
            d3.selectAll('#calibration-label').style('opacity', this.calibrationLabelOpacity);
            d3.selectAll('#no-calibration-label').style('opacity', 1 - this.calibrationLabelOpacity);
        },
        hideCalibrationLabels() {
            d3.selectAll('#calibration-label').style('opacity', this.calibrationLabelOpacity);
            d3.selectAll('#no-calibration-label').style('opacity', 1 - this.calibrationLabelOpacity);
        },
    },
    watch: {
        showCalibrationLabels(showCalibrationLabels: boolean) {
            if (showCalibrationLabels) {
                this.drawCalibrationLabels();
            } else {
                this.hideCalibrationLabels();
            }
        },
    },
});
