






























































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
    props: {
        companyName: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
    },
    methods: {},
    computed: {
        year(): number {
            return new Date().getFullYear();
        },
        monthNumber(): number {
            return new Date().getMonth() + 1;
        },
        month(): string {
            return new Date().toLocaleString('default', { month: 'long' });
        },
        day(): number {
            return new Date().getDate();
        },
        nthDay(): string {
            const nthNumber = (number: number) => {
                if (number > 3 && number < 21) {
                    return 'th';
                }
                switch (number % 10) {
                    case 1:
                        return 'st';
                    case 2:
                        return 'nd';
                    case 3:
                        return 'rd';
                    default:
                        return 'th';
                }
            };
            return `${new Date().getDate()}${nthNumber(new Date().getDate())}`;
        },
        companyStartMonth(): string {
            return new Date(this.startDate).toLocaleString('default', { month: 'long' });
        },
        companyStartDay(): number {
            return new Date(this.startDate).getDate();
        },
        companyNthDay(): string {
            const nthNumber = (number: number) => {
                if (number > 3 && number < 21) {
                    return 'th';
                }
                switch (number % 10) {
                    case 1:
                        return 'st';
                    case 2:
                        return 'nd';
                    case 3:
                        return 'rd';
                    default:
                        return 'th';
                }
            };
            return `${new Date(this.startDate).getDate()}${nthNumber(new Date(this.startDate).getDate())}`;
        },
        companyStartYear(): number {
            return new Date(this.startDate).getFullYear();
        },
        companyStartDate(): string {
            if (this.startDate) {
                return `${this.companyStartMonth} ${this.companyNthDay}, ${this.companyStartYear}`;
            } else {
                return 'N/A';
            }
        },
    },
});
