import { STATS } from './common/queryFragments';
import { SACCADIC_LATENCY_NORMS } from './common/queryFragments';

export const DISCRIMINATE_REACTION_TIME_NORMS_QUERY = `
discriminateReactionTime {
    saccadicLatency ${SACCADIC_LATENCY_NORMS}
    visualReactionSpeed ${STATS}
    processingSpeed ${STATS}
    reactionTime ${STATS}
    responseAccuracy ${STATS}
    distractibility ${STATS}
    impulsivity ${STATS}
}
`;

export const DISCRIMINATE_REACTION_TIME_METRIC_QUERY = `
discriminateReactionTime {
    metrics {
        saccadicLatency1  { time direction }
        saccadicLatency2  { time direction }
        saccadicLatency3  { time direction }
        saccadicLatency4  { time direction }
        saccadicLatency5  { time direction }
        saccadicLatency6  { time direction }
        saccadicLatency7  { time direction }
        saccadicLatency8  { time direction }
        saccadicLatency9  { time direction }
        saccadicLatency10 { time direction }
        saccadicLatency11 { time direction }
        saccadicLatency12 { time direction }
        saccadicLatency { min max average }
        visualReactionSpeed
        processingSpeed
        reactionTime
        responseAccuracy
        distractibility
        impulsivity
        distanceFromScreen
        distanceFromScreenMin
        distanceFromScreenMax
    }
}
`;
