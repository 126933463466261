import ApiService from '@/api/common/apiService';
import { SimpleGraphQlQuery, GraphQlQuery } from '@/common/types/api/requests/graphqlRequests';
import { DataApiVersionResponse, DataApiResult } from '@/common/types/api/responses/graphqlResponses';

class DataApi {
    public async getDataApiVersion(): Promise<DataApiVersionResponse> {
        const query = new SimpleGraphQlQuery(`apiVersion`);
        const response = await ApiService.graphql<DataApiVersionResponse>(query);
        return response;
    }

    public async query(query: GraphQlQuery): Promise<DataApiResult> {
        const response = await ApiService.graphql<DataApiResult>(query);
        return response;
    }
}

export default new DataApi();
