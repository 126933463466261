














































































































































































import Vue, { PropType } from 'vue';

import ReportCard from '@/views/reports/cards/ReportCard.vue';
import ScreenDistanceDisplay from '@/views/reports/components/ScreenDistanceDisplay.vue';
import PrinterFriendlyHeader from '@/views/common/headers/PrinterFriendlyHeader.vue';

import { ReportCardName } from '@/common/constants/reports.constants';
import { ReportData } from '@/common/types/reports/reportData';
import { getTimeStampFormat, getDOBFormat } from '@/common/utils/date';
import { ScoreConfig } from '@/views/reports/visualizations/scores/IndividualScoreVisualization.vue';
import { OverallScoreConfig } from '@/views/reports/visualizations/scores/OverallScoreVisualization.vue';

import AssessmentsApi from '@/api/assessment.api';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

const DV_PRINT_ORDER = [
    'ScoreDynamicVisionPrintable',
    'CircularSmoothPursuitDynamicVision',
    'HorizontalSaccadesDynamicVision',
    'FixationStabilityDynamicVision',
    'HorizontalSmoothPursuitDynamicVision',
    'VerticalSaccadesDynamicVision',
    'ChoiceReactionTimeDynamicVision',
    'VerticalSmoothPursuitDynamicVision',
    'CalibrationDynamicVision',
    'DiscriminateReactionTimeDynamicVision',
] as ReportCardName[];

const DV_CARD_OVERALL = 'OverallScoreDynamicVision';

const DV_CARDS_ROW_2 = [
    'CircularSmoothPursuitDynamicVision',
    'HorizontalSaccadesDynamicVision',
    'FixationStabilityDynamicVision',
] as ReportCardName[];

const DV_CARDS_ROW_3 = [
    'HorizontalSmoothPursuitDynamicVision',
    'VerticalSaccadesDynamicVision',
    'BrainReferenceDynamicVisionPrintable',
] as ReportCardName[];

const DV_CARDS_ROW_4 = [
    'VerticalSmoothPursuitDynamicVision',
    'EyeHandReactionTimeDynamicVision',
    'NerveMuscleReferenceDynamicVisionPrintable',
] as ReportCardName[];

export default Vue.extend({
    props: {
        reportData: {
            type: Object as PropType<ReportData>,
            required: true,
        },
        reportVersion: {
            type: Number,
            required: true,
        },
    },
    components: {
        ReportCard,
        PrinterFriendlyHeader,
        ScreenDistanceDisplay,
    },
    methods: {
        formatAssessmentDate(date: string): string {
            return `${getTimeStampFormat(date)}`;
        },
        formatDateOfBirth(date: string): string {
            return `${getDOBFormat(date)}`;
        },
        noBorderClasses(cardComponent: string): string[] {
            const noBorderClass = {
                // Top Score Row
                OverallScoreDynamicVision: ['no-border-bottom'],
                // Mid Score Row
                IndividualScoreDynamicVisionPursuits: ['no-border-right', 'no-border-bottom'],
                IndividualScoreDynamicVisionSaccades: ['no-border-right', 'no-border-bottom'],
                IndividualScoreDynamicVisionFixations: ['no-border-bottom'],
                // Top Card Row
                CircularSmoothPursuitDynamicVision: ['no-border-right', 'no-border-bottom'],
                HorizontalSaccadesDynamicVision: ['no-border-right', 'no-border-bottom'],
                FixationStabilityDynamicVision: ['no-border-bottom'],
                // Middle Card Row
                HorizontalSmoothPursuitDynamicVision: ['no-border-right', 'no-border-bottom'],
                VerticalSaccadesDynamicVision: ['no-border-right', 'no-border-bottom'],
                BrainReferenceDynamicVisionPrintable: ['no-border-bottom'],
                // Bottom Card Row
                VerticalSmoothPursuitDynamicVision: ['no-border-right'],
                EyeHandReactionTimeDynamicVision: ['no-border-right'],
                NerveMuscleReferenceDynamicVisionPrintable: ['no-border-top'],
            } as Record<string, string[]>;

            return noBorderClass[cardComponent];
        },
    },
    data() {
        return {
            DV_PRINT_ORDER,
            DV_CARD_OVERALL,
            DV_CARDS_ROW_1: [
                {
                    component: 'IndividualScoreDynamicVision',
                    type: 'Pursuits',
                    config: undefined as ScoreConfig | undefined,
                },
                {
                    component: 'IndividualScoreDynamicVision',
                    type: 'Saccades',
                    config: undefined as ScoreConfig | undefined,
                },
                {
                    component: 'IndividualScoreDynamicVision',
                    type: 'Fixations',
                    config: undefined as ScoreConfig | undefined,
                },
            ],
            DV_CARDS_ROW_2,
            DV_CARDS_ROW_3,
            DV_CARDS_ROW_4,
            saccadeConfig: {} as ScoreConfig,
            pursuitConfig: {} as ScoreConfig,
            fixationConfig: {} as ScoreConfig,
            overallConfig: undefined as OverallScoreConfig | undefined,
        };
    },
    async mounted() {
        try {
            const score = await AssessmentsApi.getReportScore(
                this.reportData.assessment.id,
                this.reportData.assessmentType.name,
            );

            const cspMetrics = this.reportData.tests.circularSmoothPursuit.metrics;
            const onTargetSp = cspMetrics.both.onTargetSpPercent;
            const spPercent = cspMetrics.both.spPercent;

            const cspAccuracy = Math.floor((onTargetSp / spPercent) * 100.0);

            const fixationMetrics = this.reportData.tests.fixationStability.metrics;
            const fixationBand1 = fixationMetrics.both.gazePositionsBand1Aggregate;
            const fixationBand2 = fixationMetrics.both.gazePositionsBand2Aggregate;
            const fixationAccuracy = Math.floor(fixationBand1 + fixationBand2);

            const hsMetrics = this.reportData.tests.horizontalSaccades.metrics;
            const hsLeftMissed = hsMetrics.left.missed;
            const hsRightMissed = hsMetrics.right.missed;

            const hsLeftSaccadeNum = hsMetrics.left.saccadeNum.value;
            const hsRightSaccadeNum = hsMetrics.right.saccadeNum.value;

            const leftAccuracy = (hsLeftSaccadeNum - hsLeftMissed) / hsLeftSaccadeNum;
            const rightAccuracy = (hsRightSaccadeNum - hsRightMissed) / hsRightSaccadeNum;
            const saccadeAccuracy = Math.floor(((leftAccuracy + rightAccuracy) / 2.0) * 100.0);

            if (score) {
                this.saccadeConfig.scoreLabel = 'Saccades';
                this.saccadeConfig.score = score.saccadesScore;
                this.saccadeConfig.percentile = score.saccadesPercentile;
                this.saccadeConfig.percentileLabel = 'Percentile';
                this.saccadeConfig.functionalWindowLowerBound = score.functionalWindowLowerBound;
                this.saccadeConfig.functionalWindowUpperBound = score.functionalWindowUpperBound;
                this.saccadeConfig.accuracy = saccadeAccuracy;
                this.saccadeConfig.reportVersion = this.reportVersion;

                this.pursuitConfig.scoreLabel = 'Pursuits';
                this.pursuitConfig.score = score.pursuitsScore;
                this.pursuitConfig.percentile = score.pursuitsPercentile;
                this.pursuitConfig.percentileLabel = 'Percentile';
                this.pursuitConfig.functionalWindowLowerBound = score.functionalWindowLowerBound;
                this.pursuitConfig.functionalWindowUpperBound = score.functionalWindowUpperBound;
                this.pursuitConfig.accuracy = cspAccuracy;
                this.pursuitConfig.reportVersion = this.reportVersion;

                this.fixationConfig.scoreLabel = 'Fixations';
                this.fixationConfig.score = score.fixationsScore;
                this.fixationConfig.percentile = score.fixationsPercentile;
                this.fixationConfig.percentileLabel = 'Percentile';
                this.fixationConfig.functionalWindowLowerBound = score.functionalWindowLowerBound;
                this.fixationConfig.functionalWindowUpperBound = score.functionalWindowUpperBound;
                this.fixationConfig.accuracy = fixationAccuracy;
                this.fixationConfig.reportVersion = this.reportVersion;

                this.DV_CARDS_ROW_1[0].config = this.pursuitConfig;
                this.DV_CARDS_ROW_1[1].config = this.saccadeConfig;
                this.DV_CARDS_ROW_1[2].config = this.fixationConfig;

                const overallConfig = {} as OverallScoreConfig;
                overallConfig.overall = score.overallScore;
                overallConfig.saccades = score.saccadesScore;
                overallConfig.pursuits = score.pursuitsScore;
                overallConfig.fixations = score.fixationsScore;
                overallConfig.functionalWindowLowerBound = score.functionalWindowLowerBound;
                overallConfig.functionalWindowUpperBound = score.functionalWindowUpperBound;
                overallConfig.accuracy = Math.floor((cspAccuracy + saccadeAccuracy + fixationAccuracy) / 3.0);
                overallConfig.reportVersion = this.reportVersion;

                this.overallConfig = overallConfig;
            }
        } catch (error) {
            this.$store.commit(SET_ERROR_BANNER, error.message);
        }
    },
});
