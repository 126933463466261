export default {
    titles: {
        'Binocular Single Vision': 'Binocular Single Vision',
        Esophoria: 'Esophoria',
        Exophoria: 'Exophoria',
        Hyperphoria: 'Hyperphoria',
        'Suppression Left': 'Suppression Left',
        'Suppression Right': 'Suppression Right',
        'Suppression Alternating': 'Suppression Alternating',
        'Diplopia, Uncrossed': 'Diplopia, Uncrossed',
        'Diplopia, Crossed': 'Diplopia, Crossed',
        'Right, Diplopia': 'Diplopia Vertical with Right Hypertropia',
        'Left, Diplopia': 'Diplopia Vertical with Left Hypertropia',
        'Convergence Insufficiency': 'Convergence Insufficiency',
        'Convergence Excess': 'Convergence Excess',
        'Nystagmus': 'Nystagmus',
    },
};
