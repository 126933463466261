import * as d3 from 'd3';

import { D3Selection, VisualizationElement, Attributes, Styles, Properties, Classes } from '../elements';
import { calculateAspectRatio } from '@/common/utils/reports/visualData';
import * as MathUtils from '@/common/utils/math';

export interface TextProperties {
    content: string;
    position: [number, number];
    aspectRatio?: number;
    length?: number;
    degrees?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
    id?: string;
    tooltip?: TextTooltip;
    rotate?: number;
}

export interface TextTooltip {
    id: string;
    content: string;
}

export class Text extends VisualizationElement {
    public constructor(private readonly textProperties: TextProperties) {
        super({
            attributes: textProperties.attributes,
            styles: textProperties.styles,
            properties: textProperties.properties,
            classes: textProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        let aspectRatio = this.textProperties.aspectRatio;
        if (!aspectRatio) {
            aspectRatio = calculateAspectRatio(svg);
        }

        this.calculateRadialPosition();

        const selection = d3
            .select(svg)
            .append('text')
            .attr('x', this.textProperties.position[0])
            .attr('y', this.textProperties.position[1] / aspectRatio)
            .text(this.textProperties.content);

        const id = this.textProperties.id;
        if (id) {
            selection.attr('id', id);
        }

        const rotate = this.textProperties.rotate;
        if (rotate) {
            selection.attr(
                'transform',
                `rotate(${rotate} ${this.textProperties.position[0]},${this.textProperties.position[1] / aspectRatio})`,
            );
        }

        const tooltip = this.textProperties.tooltip;
        if (tooltip) {
            const outerCircle = d3
                .select(svg)
                .append('circle')
                .attr('cx', this.textProperties.position[0])
                .attr('cy', this.textProperties.position[1] / aspectRatio)
                .attr('r', 0.01)
                .style('opacity', '0');

            d3.select('body')
                .append('div')
                .attr('id', tooltip.id)
                .style('opacity', '0')
                .style('position', 'absolute')
                .style('background-color', 'white')
                .style('border', 'solid')
                .style('border-width', '1px')
                .style('border-radius', '5px')
                .style('padding', '10px')
                .style('z-index', '20')
                .html(`<span>${tooltip.content}</span>`);

            outerCircle.on('mouseover', (d: MouseEvent) => {
                d3.select(`#${tooltip.id}`)
                    .style('opacity', 1)
                    .style('left', d.pageX + 5 + 'px')
                    .style('top', d.pageY + 'px')
                    .html(`<span>${tooltip.content}</span>`);
            });

            outerCircle.on('mouseout', (d: MouseEvent) => {
                d3.select(`#${tooltip.id}`).style('opacity', 0);
            });
        }

        return selection;
    }

    private calculateRadialPosition() {
        if (this.textProperties.length !== undefined && this.textProperties.degrees !== undefined) {
            this.textProperties.position[0] =
                this.textProperties.length * Math.cos(MathUtils.degreesToRadians(this.textProperties.degrees)) +
                this.textProperties.position[0];
            this.textProperties.position[1] =
                this.textProperties.length * Math.sin(MathUtils.degreesToRadians(this.textProperties.degrees)) +
                this.textProperties.position[1];
        } else if (!this.textProperties.position) {
            this.textProperties.position = [0, 0];
        }
    }
}
