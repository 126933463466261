
























import Vue, { PropType } from 'vue';

import { ExerciseModel } from '@/common/types/trainer/program';

import TextLink from '@/views/common/controls/TextLink.vue';

export default Vue.extend({
    components: {
        TextLink,
    },
    props: {
        exercises: Array as PropType<ExerciseModel[]>,
    },
    computed: {
        slicedExercises(): ExerciseModel[] {
            // If there are more than 3 exercises, then only show the first 3
            return this.exercises ? this.exercises.slice(0, 3) : [];
        },
        hasExercises(): boolean {
            return this.exercises && this.exercises.length > 0;
        },
    },
    methods: {
        handleClick(exercise: ExerciseModel) {
            this.$router.push({
                path: 'exercises/' + exercise.id,
            });
        },
    },
});
