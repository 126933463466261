import * as d3 from 'd3';

import { D3Selection, VisualizationElement, Attributes, Styles, Properties, Classes } from '../elements';
import { Position2d } from '@/common/types/reports/position';
import { calculateAspectRatio } from '@/common/utils/reports/visualData';

export interface ArcProperties {
    position: Position2d;
    innerRadius: number;
    outerRadius: number;
    startRadians: number;
    endRadians: number;
    aspectRatio?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
}

export class Arc extends VisualizationElement {
    public constructor(private readonly arcProperties: ArcProperties) {
        super({
            attributes: arcProperties.attributes,
            styles: arcProperties.styles,
            properties: arcProperties.properties,
            classes: arcProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        let aspectRatio = this.arcProperties.aspectRatio;
        if (!aspectRatio) {
            aspectRatio = calculateAspectRatio(svg);
        }

        const arc = d3
            .arc()
            .innerRadius(this.arcProperties.innerRadius)
            .outerRadius(this.arcProperties.outerRadius)
            .startAngle(this.arcProperties.startRadians)
            .endAngle(this.arcProperties.endRadians);

        const selection = d3
            .select(svg)
            .append('path')
            .attr(
                'transform',
                'translate(' + this.arcProperties.position.x + ', ' + this.arcProperties.position.y / aspectRatio + ')',
            )
            .attr('d', arc as any);

        return selection;
    }
}
