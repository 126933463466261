















































import Vue, { PropType } from 'vue';

import { ReportData } from '@/common/types/reports/reportData';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import { ReportNorms } from '@/common/types/reports/norms';
import { extractLeftRightBothNorms } from '@/common/utils/reports/norms';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';
import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        const metrics = this.reportData.tests.contrastSensitivity.metrics;

        const fields = ['cyclesPerDegree3', 'cyclesPerDegree6', 'cyclesPerDegree12'];

        return {
            metrics,
            fields,
        };
    },
    computed: {
        hasLeft(): boolean {
            const metrics = this.reportData.tests.contrastSensitivity.metrics;
            return metrics.eyeType.includes('LEFT');
        },
        hasRight(): boolean {
            const metrics = this.reportData.tests.contrastSensitivity.metrics;
            return metrics.eyeType.includes('RIGHT');
        },
        hasBinocular(): boolean {
            const metrics = this.reportData.tests.contrastSensitivity.metrics;
            return metrics.eyeType.includes('BINOCULAR');
        },
        norms(): any {
            const norms = extractLeftRightBothNorms(this.normsData.contrastSensitivity);
            return norms;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.contrastSensitivity?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.contrastSensitivity?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.contrastSensitivity?.metrics,
                'distanceFromScreen',
            );
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.contrastSensitivity.cpd'));

            return infoText;
        },
    },
});
