export const COSMOS_COMBAT_NORMS_QUERY = `
cosmosCombat {
    eyeType
    level
    score {
      mean
      stdDev
    }
    accuracyPercent {
      mean
      stdDev
    }
    speed {
      mean
      stdDev
    }
    distanceFromCenter
}
`;

export const COSMOS_COMBAT_METRIC_QUERY = `
cosmosCombat {
    info {
        attemptNumber
    }
    metrics {
        eyeType
        level
        score
        accuracyPercent
        speed
        distanceFromCenter
        distanceFromScreenMin
        distanceFromScreenMax
        left {
          distanceFromScreen
        }
        right {
          distanceFromScreen
        }
        both {
          distanceFromScreen
        }
    }
}
`;
