import { STATS, PHORIA } from './common/queryFragments';

const CSP_METRICS_LRB = `{
    spPercent
    spEfficiency
    saccadePercent
    fixationPercent
    eyeTargetVelErr
    horizontalSynchronizationSp
    verticalSynchronizationSp
    onTargetSpPercent
    predictiveSpPercent
    latentSpPercent
    backupSaccadePercent
    catchupSaccadePercent
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const CSP_NORMS_LRB = `{
    spPercent ${STATS}
    spEfficiency ${STATS}
    saccadePercent ${STATS}
    fixationPercent ${STATS}
    eyeTargetVelErr ${STATS}
    horizontalSynchronizationSp ${STATS}
    verticalSynchronizationSp ${STATS}
    onTargetSpPercent ${STATS}
    predictiveSpPercent ${STATS}
    latentSpPercent ${STATS}
    backupSaccadePercent ${STATS}
    catchupSaccadePercent ${STATS}
}
`;

export const CIRCULAR_SMOOTH_PURSUIT_NORMS_QUERY = `
circularSmoothPursuit {
    left ${CSP_NORMS_LRB}
    right ${CSP_NORMS_LRB}
    both ${CSP_NORMS_LRB}
}
`;

export const CIRCULAR_SMOOTH_PURSUIT_METRIC_QUERY = `
circularSmoothPursuit {
    metrics {
        left ${CSP_METRICS_LRB}
        right ${CSP_METRICS_LRB}
        both ${CSP_METRICS_LRB}
        disassociatedPhoria ${PHORIA}
    }
    visualData {
        gazeData {
            timeStamp
            phoria ${PHORIA}
            left {
                x
                y
                eyeMovement
            }
            right {
                x
                y
                eyeMovement
            }
        }
    }
}
`;
