import * as d3 from 'd3';

import { D3Selection, VisualizationElement, Attributes, Styles, Properties, Classes } from '../elements';
import { Position2d } from '@/common/types/reports/position';
import { calculateAspectRatio } from '@/common/utils/reports/visualData';

export interface RectProperties {
    position: Position2d;
    width: number;
    height: number;
    aspectRatio?: number;
    angle?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
}

export class Rect extends VisualizationElement {
    public constructor(private readonly rectProperties: RectProperties) {
        super({
            attributes: rectProperties.attributes,
            styles: rectProperties.styles,
            properties: rectProperties.properties,
            classes: rectProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        let aspectRatio = this.rectProperties.aspectRatio;
        if (!aspectRatio) {
            aspectRatio = calculateAspectRatio(svg);
        }

        let angle = 0;
        let translateX = 0;
        let translateY = 0;
        if (this.rectProperties.angle) {
            angle = this.rectProperties.angle;
            translateX = this.rectProperties.position.x + this.rectProperties.width / 2.0;
            translateY = (this.rectProperties.position.y + this.rectProperties.height / 2.0) / aspectRatio;
        }

        const selection = d3
            .select(svg)
            .append('rect')
            .attr('x', this.rectProperties.position.x)
            .attr('y', this.rectProperties.position.y / aspectRatio)
            .attr('width', this.rectProperties.width)
            .attr('height', this.rectProperties.height)
            .attr('transform', `rotate(${angle}, ${translateX}, ${translateY})`);

        return selection;
    }
}
