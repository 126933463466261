import { Position2d } from '@/common/types/reports/position';

export const WORKOUT_IDS: Record<string, string> = {
    workout1: '9f03a2ed-7e37-11e9-a175-0a2072e50896',
    workout2: '9f03a5e7-7e37-11e9-a175-0a2072e50896',
    workout3: '9f03a818-7e37-11e9-a175-0a2072e50896',
};

export const OUTPUT_DIRECTORY = 'exerciseDefinitions';

export const START_DELAY = 2000;
export const END_DELAY = 2000;
export const DEFAULT_STIMULUS_RADIUS = 0.65;
export const DEFAULT_DISTANCE_PER_SECOND = 3;
export const DEFAULT_FIXATION_PAUSE_DELAY = 8000;
export const DEFAULT_SACCADE_PAUSE_DELAY = 6000;
export const DEFAULT_GAP_DELAY = 100;

export const CENTER: Position2d = new Position2d(0, 0);
