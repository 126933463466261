import * as d3 from 'd3';

import { D3Selection, VisualizationElement } from './elements';
import { CircleProperties, Circle } from './elements/circle';
import { EllipseProperties, Ellipse } from './elements/ellipse';
import { LineProperties, Line } from './elements/line';
import { PathProperties, Path } from './elements/path';
import { TextProperties, Text } from './elements/text';
import { ArcProperties, Arc } from './elements/arc';
import { RectProperties, Rect } from './elements/rect';

export class Visualization {
    public static build(): Visualization {
        return new Visualization();
    }

    private elements: VisualizationElement[];
    private selections: D3Selection[];
    private zoomMultiplier: number;

    constructor() {
        this.elements = [];
        this.selections = [];
        this.zoomMultiplier = 1;
    }

    public drawNormalized(svg: SVGSVGElement, aspectRatio: number = 4 / 3) {
        const zoomRatio = 1 / this.zoomMultiplier;

        const width = zoomRatio;
        const height = width / aspectRatio;

        const minX = (1 - zoomRatio) / 2;
        const minY = (1 - zoomRatio) / (2 * aspectRatio);

        d3.select(svg)
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('viewBox', `${minX} ${minY} ${width} ${height}`);

        for (const element of this.elements) {
            this.selections.push(element.draw(svg));
        }
    }

    public clear() {
        for (const selection of this.selections) {
            selection.remove();
        }
        this.elements = [];
    }

    // zoom(2) is 2x or 200% zoom
    public zoom(zoomMultiplier: number): Visualization {
        this.zoomMultiplier = zoomMultiplier;
        return this;
    }

    public circle(circleProperties: CircleProperties): Visualization {
        this.elements.push(new Circle(circleProperties));
        return this;
    }

    public ellipse(ellipseProperties: EllipseProperties): Visualization {
        this.elements.push(new Ellipse(ellipseProperties));
        return this;
    }

    public line(lineProperties: LineProperties): Visualization {
        this.elements.push(new Line(lineProperties));
        return this;
    }

    public path(pathProperties: PathProperties): Visualization {
        this.elements.push(new Path(pathProperties));
        return this;
    }

    public text(textProperties: TextProperties): Visualization {
        this.elements.push(new Text(textProperties));
        return this;
    }

    public arc(arcProperties: ArcProperties): Visualization {
        this.elements.push(new Arc(arcProperties));
        return this;
    }

    public rect(rectProperties: RectProperties): Visualization {
        this.elements.push(new Rect(rectProperties));
        return this;
    }

    public map<T>(points: T[], transform: (point: T) => Visualization): Visualization {
        for (const point of points) {
            const visualization = transform(point);
            for (const element of visualization.elements) {
                this.elements.push(element);
            }
        }

        return this;
    }

    public with(visualization: Visualization): Visualization {
        for (const element of visualization.elements) {
            this.elements.push(element);
        }

        return this;
    }

    public add(element: VisualizationElement): Visualization {
        this.elements.push(element);

        return this;
    }

    public addAll(elements: VisualizationElement[]): Visualization {
        for (const element of elements) {
            this.elements.push(element);
        }

        return this;
    }
}
