import { TestType, ReportCardName, ALL_TEST_TYPES } from '@/common/constants/reports.constants';
import { ReportData } from '@/common/types/reports/reportData';

import { Parser } from 'json2csv';
import { LeftRightBoth } from '@/common/types/reports/metricData';
import { NormsSelection } from '@/common/types/reports/norms';
import * as AssessmentTypes from '@/common/constants/assessmentTypes.constants';

export function isSelectionEmpty(selection: NormsSelection): boolean {
    const isAgeBasedEmpty = !selection.ageBased || selection.ageBased.length === 0;
    const isMlbEmpty = !selection.mlb || selection.mlb.length === 0;
    const isGamesEmpty = !selection.games || selection.games.length === 0;
    return isAgeBasedEmpty && isMlbEmpty && isGamesEmpty;
}

export function testTypesForReport(reportData: ReportData): TestType[] {
    return ALL_TEST_TYPES.filter((testType) => reportHasTestData(reportData, testType));
}

export function reportHasTestData(reportData: ReportData, testType: TestType): boolean {
    const inTests = reportData.tests[testType] !== undefined && reportData.tests[testType] !== null;
    const inAllTests = reportData.allTests[testType] !== undefined && reportData.allTests[testType] !== null;

    return inTests || inAllTests;
}

export function isSensorimotorScreenerReport(reportData: ReportData) {
    return [AssessmentTypes.SENSORIMOTOR_SCREENER].includes(reportData.assessmentType.name);
}

export function isSensorimotorExamReport(reportData: ReportData) {
    return [AssessmentTypes.SENSORIMOTOR_EXAM].includes(reportData.assessmentType.name);
}

export function reportCardsFromReportData(reportData: ReportData, printable = false): ReportCardName[] {
    const cards = [] as ReportCardName[];

    if (reportHasTestData(reportData, 'calibration') && !isSensorimotorScreenerReport(reportData)) {
        cards.push('Calibration');
    } else if (reportHasTestData(reportData, 'calibration') && isSensorimotorScreenerReport(reportData)) {
        cards.push('NinePointMotorFunction');
        cards.push('SymptomSurvey');
    }

    if (reportHasTestData(reportData, 'circularSmoothPursuit')) {
        cards.push('CircularSmoothPursuit');
    }

    if (reportHasTestData(reportData, 'horizontalSmoothPursuit')) {
        cards.push('HorizontalSmoothPursuit');
    }

    if (reportHasTestData(reportData, 'verticalSmoothPursuit')) {
        cards.push('VerticalSmoothPursuit');
    }

    if (reportHasTestData(reportData, 'horizontalSaccades')) {
        cards.push('HorizontalSaccades');
    }

    if (reportHasTestData(reportData, 'verticalSaccades')) {
        cards.push('VerticalSaccades');
    }

    if (reportHasTestData(reportData, 'fixationStability')) {
        cards.push('FixationStability');
    }

    if (
        reportHasTestData(reportData, 'choiceReactionTime') &&
        reportHasTestData(reportData, 'discriminateReactionTime')
    ) {
        cards.push('SaccadicLatency');
    }

    if (reportHasTestData(reportData, 'choiceReactionTime')) {
        cards.push('ChoiceReactionTime');
    }

    if (reportHasTestData(reportData, 'discriminateReactionTime')) {
        cards.push('DiscriminateReactionTime');
    }

    if (reportHasTestData(reportData, 'simpleReactionTime')) {
        cards.push('SimpleReactionTime');
    }

    if (reportHasTestData(reportData, 'staticVisualAcuity')) {
        cards.push('StaticVisualAcuity');
    }

    if (reportHasTestData(reportData, 'dynamicVisualAcuity1')) {
        cards.push('DynamicVisualAcuity1');
    }

    if (reportHasTestData(reportData, 'dynamicVisualAcuity2')) {
        cards.push('DynamicVisualAcuity2');
    }

    if (reportHasTestData(reportData, 'dynamicVisualAcuity3')) {
        cards.push('DynamicVisualAcuity3');
    }

    if (reportHasTestData(reportData, 'cardinalGazePosition')) {
        cards.push('CardinalGazePosition');
    }

    if (reportHasTestData(reportData, 'contrastSensitivity')) {
        cards.push('ContrastSensitivity');
    }

    if (reportHasTestData(reportData, 'horizontalGapSaccades')) {
        cards.push('HorizontalGapSaccades');
    }

    if (reportHasTestData(reportData, 'bubbleBlast')) {
        cards.push('BubbleBlast');
    }

    if (reportHasTestData(reportData, 'cosmosCombat')) {
        cards.push('CosmosCombat');
    }

    if (reportHasTestData(reportData, 'mazeMaster')) {
        cards.push('MazeMaster');
    }

    if (reportHasTestData(reportData, 'spaceStorm')) {
        cards.push('SpaceStorm');
    }

    if (reportHasTestData(reportData, 'nearPointConvergence')) {
        cards.push('NearPointConvergence');
    }

    if (reportHasTestData(reportData, 'fourDotFusion')) {
        cards.push('FourDotFusion');
    }

    if (
        reportHasTestData(reportData, 'calibration') &&
        reportHasTestData(reportData, 'nearPointConvergence') &&
        isSensorimotorScreenerReport(reportData)
    ) {
        cards.push('SensorimotorScreenerResult');
    }

    if (reportHasTestData(reportData, 'antisaccades')) {
        cards.push('Antisaccades');
    }

    if (reportHasTestData(reportData, 'prosaccades')) {
        cards.push('Prosaccades');
    }

    return cards;
}

export function reportDataToCSV(reportData: ReportData): string {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(reportData);
    const blob = new Blob(['\ufeff', csv]);
    return URL.createObjectURL(blob);
}

export function extractLeftRightBoth(data: any): LeftRightBoth<any> {
    const result: any = {
        left: {},
        right: {},
        both: {},
        bothLeft: {},
        bothRight: {},
    };

    if (!data) {
        return result;
    }

    for (const key of Object.keys(data)) {
        if (!data[key]) {
            continue;
        }
        switch (key) {
            case 'left':
                result.left = {
                    ...result.left,
                    ...data[key],
                };
                break;
            case 'right':
                result.right = {
                    ...result.right,
                    ...data[key],
                };
                break;
            case 'both':
                result.both = {
                    ...result.both,
                    ...data[key],
                };
                break;
            case 'bothLeft':
                result.bothLeft = {
                    ...result.bothLeft,
                    ...data[key],
                };
                result.both.left = {
                    ...result.both.left,
                    ...data[key],
                };
                break;
            case 'bothRight':
                result.bothRight = {
                    ...result.bothRight,
                    ...data[key],
                };
                result.both.right = {
                    ...result.both.right,
                    ...data[key],
                };
                break;
            default:
                result.left[key] = data[key];
                result.right[key] = data[key];
                result.both[key] = data[key];
                result.bothLeft[key] = data[key];
                result.bothRight[key] = data[key];
                break;
        }
    }

    return result;
}

import { EYEQ_TYPES, REPORT_URLS, STANDARD_REPORT_URL } from '@/common/constants/assessmentTypes.constants';

export function translateSvaScore(metrics: any): any {
    if (metrics.left.svaScore) {
        metrics.left.svaScore = `${metrics.left.svaScore}/20`;
    }

    if (metrics.right.svaScore) {
        metrics.right.svaScore = `${metrics.right.svaScore}/20`;
    }

    if (metrics.both.svaScore) {
        metrics.both.svaScore = `${metrics.both.svaScore}/20`;
    }

    return metrics;
}

export function ReportScoreNameFromTypeId(assessmentTypeName: string): string {
    return EYEQ_TYPES[assessmentTypeName];
}

export function GeneratePortalReportURL(assessmentTypeId: string, assessmentId: string): string {
    let url = REPORT_URLS[assessmentTypeId];
    if (!url) {
        url = STANDARD_REPORT_URL;
    }
    return `${url}&assessmentId=${assessmentId}`;
}
