import { render, staticRenderFns } from "./PerspectiveImageVisualization.vue?vue&type=template&id=3326497a&scoped=true&"
import script from "./PerspectiveImageVisualization.vue?vue&type=script&lang=ts&"
export * from "./PerspectiveImageVisualization.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3326497a",
  null
  
)

export default component.exports