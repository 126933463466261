








































import Vue from 'vue';

import TextLink from '@/views/common/controls/TextLink.vue';

export default Vue.extend({
    components: {
        TextLink,
    },
    methods: {
        toForgotPassword() {
            this.$router.push({ name: 'forgotPassword' });
        },
    },
});
