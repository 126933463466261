




















import Vue, { PropType } from 'vue';

import { SET_VIEWING_PARTICIPANT } from '@/store/participant/mutations';

import { Assessment } from '@/common/types/reports/assessment';
import * as CompanyModule from '@/common/types/auth/companyModules';

import * as AssessmentTypes from '@/common/constants/assessmentTypes.constants';

export default Vue.extend({
    props: {
        assessment: {
            type: Object as PropType<Assessment>,
            required: true,
        },
    },
    watch: {
        assessment(newValue) {
            this.$store.commit(SET_VIEWING_PARTICIPANT, newValue.participant.id);
        },
    },
    computed: {
        reports(): any[] {
            return [
                {
                    path: `/report/${this.assessment.participant.id}/dynamicVision/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.dynamicVision'),
                    toolTip: this.$t('reports.reports.tooltips.dynamicVision'),
                    icon: 'assignment_ind',
                    enabled:
                        [
                            AssessmentTypes.BRAIN_HEALTH,
                            AssessmentTypes.FUNCTIONAL_VISION,
                            AssessmentTypes.DYNAMIC_VISION,
                            AssessmentTypes.SPORTS_VISION,
                        ].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.DYNAMIC_VISION),
                },
                {
                    path: `/report/${this.assessment.participant.id}/standard/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.standard'),
                    toolTip: this.$t('reports.reports.tooltips.standard'),
                    icon: 'assignment',
                    enabled: ![
                        AssessmentTypes.READING,
                        AssessmentTypes.SENSORIMOTOR_SCREENER,
                        AssessmentTypes.SENSORIMOTOR_EXAM,
                    ].includes(this.assessment.assessmentType.name),
                },
                {
                    path: `/sportsVision/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.sportsVision'),
                    toolTip: this.$t('reports.reports.tooltips.sportsVision'),
                    icon: 'assignment_return',
                    enabled:
                        [AssessmentTypes.SPORTS_VISION].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.SPORTS_VISION),
                },
                {
                    path: `/reading/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.reading'),
                    toolTip: this.$t('reports.reports.tooltips.reading'),
                    icon: 'assignment_return',
                    enabled:
                        [AssessmentTypes.READING].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.READING),
                },
                {
                    path: `/readingStandard/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.standard'),
                    toolTip: this.$t('reports.reports.tooltips.standard'),
                    icon: 'assignment',
                    enabled:
                        [AssessmentTypes.READING].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.READING),
                },
                {
                    path: `/saccadometry/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.saccadometry'),
                    toolTip: this.$t('reports.reports.tooltips.saccadometry'),
                    icon: 'assignment_return',
                    enabled:
                        [AssessmentTypes.SACCADOMETRY].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.SACCADOMETRY),
                },
                {
                    path: `/report/${this.assessment.participant.id}/sensorimotorScreener/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.sensorimotorScreener'),
                    toolTip: this.$t('reports.reports.tooltips.sensorimotorScreener'),
                    icon: 'assignment_return',
                    enabled:
                        [AssessmentTypes.SENSORIMOTOR_SCREENER].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.SENSORIMOTOR),
                },
                {
                    path: `/report/${this.assessment.participant.id}/sensorimotorExam/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.sensorimotorExam'),
                    toolTip: this.$t('reports.reports.tooltips.sensorimotorExam'),
                    icon: 'assignment_return',
                    enabled:
                        [AssessmentTypes.SENSORIMOTOR_EXAM].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.SENSORIMOTOR),
                },
                {
                    path: `/report/${this.assessment.participant.id}/sensorimotorInterpretation/${this.assessment.id}`,
                    linkText: this.$t('reports.reports.linkTexts.sensorimotorInterpretation'),
                    toolTip: this.$t('reports.reports.tooltips.sensorimotorInterpretation'),
                    icon: 'assignment_return',
                    enabled:
                        [AssessmentTypes.SENSORIMOTOR_EXAM].includes(this.assessment.assessmentType.name) &&
                        this.$store.getters.hasModule(CompanyModule.SENSORIMOTOR),
                },
            ];
        },
        historicReports(): any[] {
            const brainFunctionalDeactivationDate = new Date('1/16/2021 12:00 EST'); // Dashboard 4.0 release date
            const assessmentDate = new Date(this.assessment.dateCreated);
            if (assessmentDate < brainFunctionalDeactivationDate) {
                return [
                    {
                        path: `/brainHealth/${this.assessment.id}`,
                        linkText: this.$t('reports.reports.linkTexts.brainHealth'),
                        toolTip: this.$t('reports.reports.tooltips.brainHealth'),
                        icon: 'assignment_return',
                        enabled:
                            [
                                AssessmentTypes.BRAIN_HEALTH,
                                AssessmentTypes.FUNCTIONAL_VISION,
                                AssessmentTypes.SPORTS_VISION,
                            ].includes(this.assessment.assessmentType.name) &&
                            this.$store.getters.hasModule(CompanyModule.BRAIN_HEALTH),
                    },
                    {
                        path: `/functionalVision/${this.assessment.id}`,
                        linkText: this.$t('reports.reports.linkTexts.functionalVision'),
                        toolTip: this.$t('reports.reports.tooltips.functionalVision'),
                        icon: 'assignment_return',
                        enabled:
                            [
                                AssessmentTypes.BRAIN_HEALTH,
                                AssessmentTypes.FUNCTIONAL_VISION,
                                AssessmentTypes.SPORTS_VISION,
                            ].includes(this.assessment.assessmentType.name) &&
                            this.$store.getters.hasModule(CompanyModule.FUNCTIONAL_VISION),
                    },
                ];
            } else {
                return [];
            }
        },
    },
});
