









































































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';
import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ButtonState } from '@/common/types/general';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import { ReportData } from '@/common/types/reports/reportData';
import VerticalSaccadesVisualization, {
    VerticalSaccadesConfig,
} from '@/views/reports/visualizations/VerticalSaccadesVisualization.vue';
import { formatNumber } from '@/common/utils/reports/format';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        VerticalSaccadesVisualization,
        GreenButton,
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        const visualData = VisualDataUtils.extractVisualData(this.reportData, 'verticalSaccades');

        const fields = [
            'saccadeNum',
            'fixationNum',
            'onTarget',
            'band2Over',
            'band2Under',
            'band3Over',
            'band3Under',
            'missed',
            'saccadicEfficiency',
            'saccadicTargeting',
            'speedAccuracyTradeoff',
            'saccadicRecovery',
            'saccadicVariation',
            'saccadicVelocity',
        ];

        return {
            fields,
            config: {
                testData: visualData,
                systemType: this.reportData.assessment.systemType,
            } as VerticalSaccadesConfig,
            playButtonState: 'active' as ButtonState,
            playAnimation: false,
            headers: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: 'Right Eye',
                    value: 'right',
                },
                {
                    text: 'Left Eye',
                    value: 'left',
                },
            ],
        };
    },
    computed: {
        tableData() {
            const metrics = this.reportData.tests.verticalSaccades.metrics;
            const tableData = [] as any[];

            for (const field of this.fields) {
                tableData.push({
                    name: this.$t('reports.metrics.tests.verticalSaccades.' + field),
                    right: formatNumber(
                        metrics.right[field].value !== undefined ? metrics.right[field].value : metrics.right[field],
                    ),
                    left: formatNumber(
                        metrics.left[field].value !== undefined ? metrics.left[field].value : metrics.left[field],
                    ),
                });
            }

            return tableData;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.verticalSaccades?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.verticalSaccades?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.verticalSaccades?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.saccades.saccades'));
            infoText.push(this.$t('reports.info.saccades.saccadeNum'));
            infoText.push(this.$t('reports.info.saccades.fixationNum'));
            infoText.push(this.$t('reports.info.saccades.saccadicEfficiency'));
            infoText.push(this.$t('reports.info.saccades.saccadicTargeting'));
            infoText.push(this.$t('reports.info.saccades.speedAccuracy'));
            infoText.push(this.$t('reports.info.saccades.saccadicRecovery'));
            infoText.push(this.$t('reports.info.saccades.saccadicVariation'));
            infoText.push(this.$t('reports.info.saccades.saccadicVelocity'));

            return infoText;
        },
        paperLink(): string {
            if (this.reportData.assessment.systemType === 'I15') {
                return 'reports.info.normsV3.I15.verticalSaccades';
            }

            return 'reports.info.normsV3.SMI.verticalSaccades';
        },
    },
    methods: {
        playReplayAnimation() {
            this.playButtonState = 'inactive';
            this.playAnimation = true;
        },
        onAnimationFinished() {
            this.playButtonState = 'active';
            this.playAnimation = false;
        },
    },
});
