import { Module } from 'vuex';
import { resetVuexStoreState, RootState } from '@/store/index';
import { SET_AUTHENTICATED_USER, DELETE_AUTHENTICATED_USER, LOAD_AUTHENTICATED_USER } from '@/store/auth/mutations';
import { AuthenticatedUser, AUTHENTICATION_LOCAL_STORAGE_KEY } from '@/common/types/auth/authenticatedUser';
import { REFRESH_AUTHENTICATED_USER } from './actions';

interface AuthState {
    user?: AuthenticatedUser;
}

const authModule: Module<AuthState, RootState> = {
    state: {
        user: undefined,
    },
    mutations: {
        [LOAD_AUTHENTICATED_USER](state: AuthState) {
            const authenticatedUser = getAuthenticationLocalStorage();
            // Catch old locally stored tokens and don't load them
            if (authenticatedUser && (!authenticatedUser.role || !authenticatedUser.company)) {
                // If other fields of the token change and the token needs to be invalidated, then add
                // another clause catching those tokens to the above conditional
                removeAuthenticationLocalStorage();
                resetVuexStoreState();
            }
            state.user = authenticatedUser;
        },
        [SET_AUTHENTICATED_USER](state: AuthState, user?: AuthenticatedUser) {
            if (user) {
                state.user = user;
                setAuthenticationLocalStorage(user);
            }
        },
        [DELETE_AUTHENTICATED_USER](state: AuthState) {
            removeAuthenticationLocalStorage();
            resetVuexStoreState();
            state.user = undefined;
        },
    },
    actions: {
        [REFRESH_AUTHENTICATED_USER](context) {
            context.commit(LOAD_AUTHENTICATED_USER);
        },
    },
    getters: {
        currentUser(state: AuthState): AuthenticatedUser | undefined {
            return state.user;
        },
        isAuthenticated(state: AuthState): boolean {
            return state.user !== undefined;
        },
        userRole(state: AuthState): string | undefined {
            return state.user?.role ?? undefined;
        },
        hasModule: (state: AuthState) => (moduleName: string) => {
            return state.user?.company.modules?.some((module) => module.name === moduleName) ?? false;
        },
    },
};

export default authModule;

function setAuthenticationLocalStorage(user: AuthenticatedUser) {
    window.localStorage.setItem(AUTHENTICATION_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

function getAuthenticationLocalStorage(): AuthenticatedUser | undefined {
    return AuthenticatedUser.fromLocalStorage();
}

function removeAuthenticationLocalStorage() {
    window.localStorage.removeItem(AUTHENTICATION_LOCAL_STORAGE_KEY);
}
