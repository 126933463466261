const WRONG_PASSWORD_OR_USERNAME =
    "Your email or password is incorrect. If you have forgotten your password, please click 'Forgot Password?'.";
const ACCOUNT_LOCKED =
    "Your account has been locked due to too many invalid login attempts. Please click 'Forgot Password?' to reset your password.";
const PASSWORD_LIMIT_EXCEEDED =
    'Your account has been temporarily locked due to too many invalid login attempts. Please wait a few minutes and try again.';
const ACCOUNT_DISABLED = 'Your account has been deactivated. Please contact your administrator for help.';
const ACCESS_DENIED =
    'Access denied, please log back in. If issues persist, please contact customer support at 301-979-7970 or email us at support@righteye.com.';

export default {
    pageNotFound1: 'The page you were looking for cannot be found.',
    pageNotFound2:
        'Please head back, visit the home page, or contact RightEye Customer Success Team for further assistance at 301-979-7970 or email us at support@righteye.com.',
    returnHome: 'Return Home',
    reportProblem: 'Report Problem',
    missingSubscription1: 'This is not part of your current subscription.',
    missingSubscription2:
        'Contact RightEye Customer Success Team for more information at 301-979-7970 or email us at support@righteye.com.',
    defaultPageErrorText:
        'RightEye has detected an error with your request. Please reset your browser data and try again:',
    pageErrorBullet1: 'Mac users press Command+Shift+Delete and select "Clear Browser cache"',
    pageErrorBullet2: 'PC users press Control+Shift+Delete and select "Clear Browser cache"',
    contactUsErrorMessage:
        'If the issue persists, contact RightEye Customer Success Team for further assistance at 301-979-7970 or email us at support@righteye.com.',
    auth: {
        'USERNAME_PASSWORD_INCORRECT': WRONG_PASSWORD_OR_USERNAME,
        'USER_DOES_NOT_EXIST': WRONG_PASSWORD_OR_USERNAME,
        'ACCOUNT_LOCKED': ACCOUNT_LOCKED,
        'PASSWORD_ATTEMPTS_EXCEEDED': PASSWORD_LIMIT_EXCEEDED,
        'USER_INACTIVE': ACCOUNT_DISABLED,
        'UNAUTHORIZED': ACCESS_DENIED,
        accessDenied: ACCESS_DENIED,
        'PASSWORD_ALREADY_USED': 'This password has been used before. You must use a new password.',
    },
};
