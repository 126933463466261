import * as d3 from 'd3';

import { VisualizationElement, D3Selection, Attributes, Styles, Properties, Classes } from '../elements';
import { calculateAspectRatio } from '@/common/utils/reports/visualData';
import { ProcessedGazePoint } from '@/common/types/reports/visualData';
export interface CircleProperties {
    radius: number;
    center: [number, number];
    aspectRatio?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
    onMouseOver?: () => void;
    calibrationPointLabel?: string;
    point?: ProcessedGazePoint[];
    id?: string;
}

export class Circle extends VisualizationElement {
    public constructor(private readonly circleProperties: CircleProperties) {
        super({
            attributes: circleProperties.attributes,
            styles: circleProperties.styles,
            properties: circleProperties.properties,
            classes: circleProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        let aspectRatio = this.circleProperties.aspectRatio;
        if (!aspectRatio) {
            aspectRatio = calculateAspectRatio(svg);
        }

        const selection = d3
            .select(svg)
            .append('circle')
            .attr('cx', this.circleProperties.center[0])
            .attr('cy', this.circleProperties.center[1] / aspectRatio)
            .attr('r', this.circleProperties.radius);

        if (this.circleProperties.id) {
            selection.attr('id', this.circleProperties.id);
        }

        if (this.circleProperties.point) {
            selection.data(this.circleProperties.point);
        }

        const calibrationPointLabel = this.circleProperties.calibrationPointLabel;
        if (calibrationPointLabel) {
            const outerCircle = d3
                .select(svg)
                .append('circle')
                .attr('cx', this.circleProperties.center[0])
                .attr('cy', this.circleProperties.center[1] / aspectRatio)
                .attr('r', this.circleProperties.radius + 0.008)
                .style('opacity', '0');

            d3.select('body')
                .append('div')
                .attr('id', 'circle-tooltip')
                .style('opacity', '0')
                .style('position', 'absolute')
                .style('background-color', 'white')
                .style('border', 'solid')
                .style('border-width', '1px')
                .style('border-radius', '5px')
                .style('padding', '10px')
                .style('z-index', '20')
                .html(`<span>${calibrationPointLabel}</span>`);

            outerCircle.on('mouseover', (d: MouseEvent) => {
                d3.select('#circle-tooltip')
                    .style('opacity', 1)
                    .style('left', d.pageX + 5 + 'px')
                    .style('top', d.pageY + 'px')
                    .html(`<span>${calibrationPointLabel}</span>`);
            });

            outerCircle.on('mouseout', (d: MouseEvent) => {
                d3.select('#circle-tooltip').style('opacity', 0);
            });
        }

        return selection;
    }
}
