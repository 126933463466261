




import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import * as ScoreUtils from '@/common/utils/reports/visualization/score';
import { ComparativeReportScore } from '@/common/types/reports/assessment';

const visualization = Visualization.build();

export default Vue.extend({
    props: {
        comparativeReportScore: {
            type: Object as PropType<ComparativeReportScore>,
        },
        updateViz: Boolean,
        message: String,
    },
    methods: {
        calculateScoreColor(score: number): string {
            if (score >= 75) {
                return Constants.GREEN;
            } else if (score >= 50) {
                return Constants.YELLOW;
            } else if (score >= 25) {
                return Constants.ORANGE;
            } else {
                return Constants.RED;
            }
        },
        calculateScorePercentageInRadians(score: number): number {
            return (Math.PI * 2 * score) / 100;
        },
        render() {
            // Positions
            const OVERALL_POSITION = new Position2d(0.56, 0.52);
            const PURSUITS_POSITION = new Position2d(0.68, 0.44);
            const SACCADES_POSITION = new Position2d(0.78, 0.44);
            const FIXATIONS_POSITION = new Position2d(0.88, 0.44);
            const MESSAGE_POSITION = new Position2d(0.25, 0.51);

            const LARGE_CIRCLE_INNER_RADIUS = 0.041;
            const LARGE_CIRCLE_OUTER_RADIUS = 0.058;
            const SMALL_CIRCLE_INNER_RADIUS = 0.032;
            const SMALL_CIRCLE_OUTER_RADIUS = 0.045;

            // Text
            const TEXT_FONT_FAMILY = 'ProximaNova';
            const NUMBER_FONT_SIZE_LARGE = '.0018em';
            const NUMBER_FONT_SIZE_SMALL = '.0014em';

            const ASPECT_RATIO = 16 / 2;

            // Overall score
            ScoreUtils.generateScoreCircle(visualization, {
                position: OVERALL_POSITION,
                aspectRatio: ASPECT_RATIO,
                radius: LARGE_CIRCLE_OUTER_RADIUS,
                thickness: LARGE_CIRCLE_OUTER_RADIUS - LARGE_CIRCLE_INNER_RADIUS,
                labels: [
                    {
                        text: `${this.comparativeReportScore.functionalWindowLowerBound}-${this.comparativeReportScore.functionalWindowUpperBound}`,
                        positionOffset: new Position2d(0, 0),
                        fontSize: NUMBER_FONT_SIZE_LARGE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                    },
                ],
                color: this.calculateScoreColor(this.comparativeReportScore.functionalWindowLowerBound),
                fillPercent: this.comparativeReportScore.functionalWindowLowerBound,
            });

            // Pursuits Score
            ScoreUtils.generateScoreCircle(visualization, {
                position: PURSUITS_POSITION,
                aspectRatio: ASPECT_RATIO,
                radius: SMALL_CIRCLE_OUTER_RADIUS,
                thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
                labels: [
                    {
                        text: `${this.comparativeReportScore.pursuitsScoreLowerBound}-${this.comparativeReportScore.pursuitsScoreUpperBound}`,
                        positionOffset: new Position2d(0, 0),
                        fontSize: NUMBER_FONT_SIZE_SMALL,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                    },
                ],
                color: this.calculateScoreColor(this.comparativeReportScore.pursuitsScoreLowerBound),
                fillPercent: this.comparativeReportScore.pursuitsScoreLowerBound,
            });

            // Saccades Score
            ScoreUtils.generateScoreCircle(visualization, {
                position: SACCADES_POSITION,
                aspectRatio: ASPECT_RATIO,
                radius: SMALL_CIRCLE_OUTER_RADIUS,
                thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
                labels: [
                    {
                        text: `${this.comparativeReportScore.saccadesScoreLowerBound}-${this.comparativeReportScore.saccadesScoreUpperBound}`,
                        positionOffset: new Position2d(0, 0),
                        fontSize: NUMBER_FONT_SIZE_SMALL,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                    },
                ],
                color: this.calculateScoreColor(this.comparativeReportScore.saccadesScoreLowerBound),
                fillPercent: this.comparativeReportScore.saccadesScoreLowerBound,
            });

            // Fixations Score
            ScoreUtils.generateScoreCircle(visualization, {
                position: FIXATIONS_POSITION,
                aspectRatio: ASPECT_RATIO,
                radius: SMALL_CIRCLE_OUTER_RADIUS,
                thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
                labels: [
                    {
                        text: `${this.comparativeReportScore.fixationsScoreLowerBound}-${this.comparativeReportScore.fixationsScoreUpperBound}`,
                        positionOffset: new Position2d(0, 0),
                        fontSize: NUMBER_FONT_SIZE_SMALL,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                    },
                ],
                color: this.calculateScoreColor(this.comparativeReportScore.fixationsScoreLowerBound),
                fillPercent: this.comparativeReportScore.fixationsScoreLowerBound,
            });

            // This white rectangle covers up some issue where old text is still visible when rendering cached data
            visualization.rect({
                position: new Position2d(0, 0),
                width: 0.5,
                height: 0.24,
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    fill: 'white',
                },
            });

            // Assessment Name/Date
            // Line break if there's a ' - ', at time of writing this captures comparisons but not assessments
            const messageLines = this.message.split(' - ');
            // If there's more than one line then position the message a little higher
            const messagePositionY = messageLines.length > 1 ? MESSAGE_POSITION.y - 0.15 : MESSAGE_POSITION.y;
            const lineHeight = 0.35;
            for (const [index, line] of messageLines.entries()) {
                visualization.text({
                    content: line,
                    position: [MESSAGE_POSITION.x, messagePositionY + lineHeight * index],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': '0.0025em',
                        'font-family': TEXT_FONT_FAMILY,
                    },
                    styles: {
                        'fill': Constants.BLACK,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                    },
                });
            }

            // Actually build the visualization according to the space avaliable
            visualization.drawNormalized(this.svg, ASPECT_RATIO);
        },
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
    watch: {
        updateViz(shouldUpdateViz: boolean) {
            if (shouldUpdateViz) {
                visualization.clear();
                this.render();
            }
        },
    },
});
