import { STATS } from './common/queryFragments';

export const SIMPLE_REACTION_TIME_NORMS_QUERY = `
simpleReactionTime {
    srt ${STATS}
}
`;

export const SIMPLE_REACTION_TIME_METRIC_QUERY = `
simpleReactionTime {
    metrics {
        srt
        distanceFromScreen
        distanceFromScreenMin
        distanceFromScreenMax
    }
}
`;
