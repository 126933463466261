export default {
    disabledMessage: 'Please contact your care provider for access to EyeQ Trainer',
    pageNotFound: {
        message: 'Page under development.',
    },
    emailUnsubscribe: {
        invalidLink: 'Invalid unsubscribe link',
        unsubscribeSuccessful: 'You were successfully unsubscribed from email updates',
    },
    welcome: {
        header: 'Hi {user}!',
        message: 'Welcome to EyeQ Trainer!',
        description:
            '<strong>I am Coach EyeQ!</strong> Based on your RightEye EyeQ test results and proven science, we’ve created a vision exercise program that’s customized for you. I will help you with each step of your new workout program.',
    },
    tutorial: {
        header: 'Tutorial for {exerciseName} Exercise',
        link: 'START EXERCISE {exerciseNum} OF 3',
        description: 'EXERCISE {exerciseNum}: Description goes here.....',
        instruction: 'Always keep your eyes on the target',
        demoButtonText: 'START EXERCISE',
        providerDescriptionHeader: 'Exercise Description',
        fixation: {
            name: 'Fixation',
            description: [
                'With your nose centered, focus on the target while moving your head to the right, then left, then back to center, then up, down, and back to center. Repeat this each time the target appears, starting with your nose centered each time.',
            ],
            providerDescription: 'This exercise is designed to train the eyes for stability.',
        },
        'saccade-left': {
            name: 'Left Saccade',
            description: [
                'When you see the target, move your head one time to the right, then to the left, and then back to center. Repeat each time the target jumps, then follow the target back to center with your eyes and nose.',
            ],
            providerDescription:
                'Trains the eyes to move quickly and retarget objects on the fovea. This exercise trains saccadic movement to the left.',
        },
        'saccade-right': {
            name: 'Right Saccade',
            description: [
                'When you see the target, move your head one time to the right, then to the left, and then back to center. Repeat each time the target jumps, then follow the target back to center with your eyes and nose.',
            ],
            providerDescription:
                'Trains the eyes to move quickly and retarget objects on the fovea. This exercise trains saccadic movement to the right.',
        },
        'saccade-up': {
            name: 'Up Saccade',
            description: [
                'When the target stops, tilt your head to the bottom line, to the top line, and then back to center. Repeat each time the target jumps. Finally, follow the target as it moves smoothly back to center.',
            ],
            providerDescription:
                'Trains the eyes to move quickly and retarget objects on the fovea. This exercise trains saccadic movement upwards.',
        },
        'saccade-down': {
            name: 'Down Saccade',
            description: [
                'When the target stops, tilt your head to the bottom line, to the top line, and then back to center. Repeat each time the target jumps. Finally, follow the target as it moves smoothly back to center.',
            ],
            providerDescription:
                'Trains the eyes to move quickly and retarget objects on the fovea. This exercise trains saccadic movement downwards.',
        },
        'pursuit-left': {
            name: 'Left Pursuit',
            description: [
                'When the target stops, move your head one time to the right and then back to center. Repeat each time the target jumps.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains left direction smooth pursuits.',
        },
        'pursuit-right': {
            name: 'Right Pursuit',
            description: [
                'When the target stops, move your head one time to the left and then back to center. Repeat each time the target jumps.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains right direction smooth pursuits.',
        },
        'pursuit-vertical': {
            name: 'Vertical Pursuit',
            description: [
                'When you see the target, move your nose to the bottom line, then to the top line, then back to center  before it disappears. Repeat each time the target jumps.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
        'bilateral-v-pattern': {
            name: 'Bilateral V Pattern',
            description: [
                'Part 1: Right V Pattern - Keep your eyes focused upward on the target, move your head right, then left then back to center. Repeat this Right Left and Center 5 times.',
                'Part 2: Left V Pattern - Always keep your Eyes focused on the target. When the target stops tilt your head to the bottom then back to center. When the target reaches the bottom follow it with your eyes as it moves up. Repeat this for the opposite side.  When the target reaches the bottom follow it with your eyes as it moves up keeping your head centered.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
        'right-v-pattern': {
            name: 'Right V Pattern',
            description: [
                'When you see the target, move your head one time to the bottom line and then back to center. Repeat each time the target jumps. After the last movement, follow the target with your eyes back to center.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
        'left-v-pattern': {
            name: 'Left V Pattern',
            description: [
                'Always keep your Eyes focused on the target. When the target stops tilt your head to the bottom then back to center. When the target reaches the bottom follow it with your eyes as it moves up. Repeat this for the opposite side. When the target reaches the bottom follow it with your eyes as it moves up keeping your head centered.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
        'bilateral-a-pattern': {
            name: 'Bilateral A Pattern',
            description: [
                'Part 1: Right A Pattern - Keep your eyes focused downward on the target, move your head right, then left then back to center. Repeat this Right Left and Center 5 times.',
                'Part 2: Left A Pattern -  When the target stops tilt your head to the top then back to center while always keeping your eyes focused on the target. When the target reaches the top, follow it with your eyes as it moves down. Keep your head centered.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
        'right-a-pattern': {
            name: 'Right A Pattern',
            description: [
                'When you see the target, tilt your head to the top line and then back to center. Repeat each time the target jumps. Finally, follow the target as it moves smoothly back to center.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
        'left-a-pattern': {
            name: 'Left A Pattern',
            description: [
                'When you see the target, tilt your head to the top line and then back to center. Repeat each time the target jumps. Finally, follow the target as it moves smoothly back to center.',
            ],
            providerDescription:
                'Trains the eyes to move smoothly, while keeping the object on the fovea. This exercise trains up and down smooth pursuits.',
        },
    },
    instructions: {
        message: 'Read this before going any further!',
        description: {
            deviceOnTable: 'Set device on a table',
            screenAtArmsLength: "Screen should be perpendicular and at arm's length",
            sitUpright: 'Sit upright and position screen at eye level',
            proTip: '(Tip: Use books to prop device)',
            handsAtSide: 'Keep hands at your side or on lap',
        },
        caution: 'Safety First!',
        warning:
            'If you experience fatigue, eye strain, blurred vision, headache or other unusual symptoms, stop performing the training exercises and consult your physician.',
        buttonText: 'START WORKOUT!',
    },
    needHelp: {
        unauthenticatedSubject: 'Trouble Logging In?',
        passwordNotWorking: "If your password isn't working, use this link to reset it:",
        newPatient: 'If you’re a patient and not sure if you have a login, please contact your RightEye provider.',
        forgotPassword: 'Forgot Password',
        forgotPasswordSuccess:
            'If the provided information matches our records, then an email will be sent to the address provided.',
        needTechnicalAssistance: 'Need Technical Assistance?',
        phone: 'Phone',
        phoneNumber: '301-979-7970',
        email: 'Email',
        emailAddress: 'support@righteye.com',
        medicalQuestions: 'Have Medical Questions?',
        medicalAnswers:
            'For questions about your report results, or if EyeQ Trainer was assigned for you, please contact your RightEye provider',
        backToLogin: 'BACK TO LOGIN',
    },
    landing: {
        header: "Today's Workout",
        message: 'Ready for exercise {num}?',
        workout: 'You chose {name}',
    },
    overview: {
        header: "Here's what to expect:",
        message: "{name}'s 30-day Workout Program",
        description: [
            'Exercise your eyes from any computer',
            'Commit about 15 minutes per day',
            'Start feeling better in 2-4 weeks',
        ],
    },
    congratulations: {
        message: 'You have completed EyeQ Trainer!',
        header: 'Congratulations!',
        description: [
            'Schedule your next RightEye EyeQ test',
            'Please schedule an appointment with your doctor to test and measure your progress.',
        ],
    },
    progress: {
        message: 'Keep up the good work!',
        header: "{name}'s Progress",
        cycleInfo: 'Your 30-day cycle started on {startDate}. You have {daysRemaining} days remaining.',
        workoutComplete: 'You are done for today',
        clickHere: 'Click Here',
        toGoHome: 'to go Home.',
        footer: 'The more workouts you do, the more you can improve!',
        coachPhrase: {
            good: 'Doing good!',
            great: 'Doing great!',
            awesome: 'Doing awesome!',
        },
        finishedForDay: 'FINISHED FOR THE DAY',
    },
    exercises: {
        header: 'Types of Exercises',
        message: "Let's start with the basics!",
        description: ['One daily workout', '3 Exercises a day', 'Done in 30 days'],
        pursuit: {
            name: 'Pursuit Exercises',
            description: 'Improves your ability to follow an object smoothly.',
            footnote: '(around in a circle)',
        },
        saccade: {
            name: 'Saccade Exercises',
            description: 'Improves your ability to quickly change focus.',
            footnote: '(ping pong back and forth)',
        },
        fixation: {
            name: 'Fixation Exercises',
            description: 'Improves your ability to stay focused on an object.',
            footnote: '(stare in one place)',
        },
        pluralizedNames: {
            Pursuit: 'Pursuits',
            Saccade: 'Saccades',
            Fixation: 'Fixations',
        },
    },
    workoutPlan: {
        messageBar: 'My Workout Plan',
        Mix_It_Up: {
            name: 'Workout Plan 1 - Mix It Up',
            definition: 'One of each exercise during each workout session',
            descriptionHeaders: ['First', 'Second', 'Third'],
        },
        Switch_Hit: {
            name: 'Workout Plan 2 - Switch Hit',
            definition: 'Do one type of exercise today and a different exercise tomorrow',
            descriptionHeaders: ['Day 1', 'Day 2', 'Day 3'],
        },
        Go_Steady: {
            name: 'Workout Plan 3 - Go Steady',
            definition: 'Do one type of exercise for 10 days and then change exercises',
            descriptionHeaders: ['First 10', 'Second 10', 'Third 10'],
        },
        sidebar: 'What are the exercises?',
        descriptions: [
            {
                name: 'Fixations',
                description: 'Improves your ability to stay focused on an object (stare in one place)',
            },
            {
                name: 'Pursuits',
                description: 'Improves your ability to follow an object smoothly (around in a circle)',
            },
            {
                name: 'Saccades',
                description: 'Improves your ability to quickly change focus (ping pong back and forth)',
            },
        ],
        continueButton: 'CONTINUE WORKOUT',
    },
    chooseWorkoutPlan: {
        headerText: 'Choose your workout plan',
        subHeaderText: 'Click the round button to the left to choose your plan. Effective until your next assessment.',
        readyToBegin: 'Now you are ready to begin!',
        recommended: 'Recommended',
        Mix_It_Up: {
            name: 'Workout Plan 1 - Mix it Up',
            description: 'One of each exercise during each workout',
            example: '(Example: Fixations, Pursuits then Saccades)',
            exercises: ['Fixation', 'Pursuit', 'Saccade'],
        },
        Switch_Hit: {
            name: 'Workout Plan 2 - Switch Hit',
            description: 'Do one type of exercise today and a different exercise tomorrow',
            example: '(Example: Fixations today, Pursuits tomorrow)',
            exercises: ['Pursuit', 'Pursuit', 'Pursuit'],
        },
        Go_Steady: {
            name: 'Workout Plan 3 - Go Steady',
            description: 'One of each exercise during each workout',
            example: '(Example: Fixations for 10 days, Pursuits for 10 days)',
            exercises: ['Fixation', 'Fixation', 'Fixation'],
        },
    },
    provider: {
        exerciseDemoList: {
            heading: 'Select an exercise below to preview',
            exercises: 'EXERCISES',
            fixationLabel: 'FIXATION',
            pursuitLabel: 'PURSUIT',
            saccadeLabel: 'SACCADE',
            fixation: 'Fixation',
            leftPursuit: 'Left Pursuit',
            rightPursuit: 'Right Pursuit',
            verticalPursuit: 'Vertical Pursuit',
            leftVPattern: 'Left V Pattern',
            rightVPattern: 'Right V Pattern',
            bilateralVPattern: 'Bilateral V Pattern',
            leftAPattern: 'Left A Pattern',
            rightAPattern: 'Right A Pattern',
            bilateralAPattern: 'Bilateral A Pattern',
            leftSaccade: 'Left Saccade',
            rightSaccade: 'Right Saccade',
            upSaccade: 'Up Saccade',
            downSaccade: 'Down Saccade',
        },
    },
    forms: {
        submit: 'SUBMIT',
        register: 'REGISTER',
        email: 'Email Address',
        password: 'Password',
        createPassword: 'Create Password',
        secondPassword: 'Re-enter Password',
        authError: 'Invalid email address or password.',
        passwordRequirementTop: 'Passwords must meet the following requirements:',
        passwordRequirementListLeft: [
            {
                description: '8 characters',
                regexp: '.{8,}',
            },
            {
                description: '1 uppercase letter',
                regexp: '(?=.*[A-Z])',
            },
            {
                description: '1 lowercase letter',
                regexp: '(?=.*[a-z])',
            },
        ],
        passwordRequirementListRight: [
            {
                description: '1 number',
                regexp: '(?=.*[0-9])',
            },
            {
                description: '1 special character (ex. @$!#?&%*)',
                regexp: '(?=.*[$@$!%*#?&])',
            },
        ],
        badPassword: 'Password does not meet requirements.',
        mismatchedPassword: 'Passwords do not match',
        connectivityError:
            'It appears we are having difficulty reaching RightEye services. ' +
            'Please check internet connection and try again. If issues persist, ' +
            'please contact customer support at 301-979-7970 or email us at ' +
            'support@righteye.com.',
        chooseWorkoutConnectivityError:
            'Try clicking the button again to start your workout.' +
            'If the problem persists please contact support at 301-979-7970 or support@righteye.com.',
    },
    components: {
        exerciseTable: {
            instruction: 'Complete these Exercises',
            exercise: 'Exercise {num}',
            tableHeader: '{name} Exercises',
        },
        banner: {
            message: 'Welcome!',
        },
        header: {
            area: 'EyeQ Trainer',
            welcome: 'Hello, {user}!',
            needHelpMenu: 'Need Help?',
        },
        star: {
            text: 'WORKOUT',
        },
        row: {
            text: 'Doing {status}!',
        },
    },
    routes: {
        progress: 'My Progress',
        workoutPlan: 'My Workout Plan',
        exercisesDemo: 'EyeQ Trainer Exercises',
        providerSummary: 'EyeQ Trainer Summary',
        providerSettings: 'Provider Settings',
        logout: 'Logout',
        help: 'Need Help? Click here.',
        createPassword: 'Create your password',
        portal: 'View Reports',
        trainer: 'Go to EyeQ Trainer',
        start: 'START WORKOUT!',
        confirm: 'GOT IT!',
        begin: "LET'S GO!",
        next: 'NEXT',
        now: 'START NOW!',
        more: 'DO MORE',
        landing: "GO TO TODAY'S WORKOUT",
        chooseWorkout: 'CHOOSE YOUR WORKOUT',
        basics: 'START YOUR PROGRAM',
    },
    errorMessage: {
        landing: 'You already have an existing program',
        chooseWorkout: 'You have not chosen your workout plan yet',
        basics: 'You have not started your program yet',
        notEnabled: 'Please contact your care provider for access to EyeQ Trainer',
    },
};
