




























































import Vue from 'vue';

import MessageBar from '@/views/common/static/MessageBar.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    components: { MessageBar, GreenButton },
    methods: {
        onClick() {
            this.$router.push({ name: 'tutorial' });
        },
    },
});
