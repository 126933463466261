import { ExerciseModel } from '@/common/types/trainer/program';

import { EXERCISES } from '@/common/types/trainer/exerciseModels';
import PrescriptionModel from './prescriptionModel';
import AssessmentModel from './assessmentModel';

export default class EqtAssignmentModel {
    private readonly dateCreated: Date;
    private readonly isSelected: boolean;
    private readonly exercises: ExerciseModel[];
    private readonly endDate: string;
    private readonly assessment: AssessmentModel | null;
    private readonly prescriptions: PrescriptionModel[];
    private readonly isGeneralAssignment: boolean;
    private readonly exercisesCompleted: number;

    public constructor(model: any) {
        this.dateCreated = model.DateCreated;
        this.isSelected = model.Prescriptions.find((p: PrescriptionModel) => p.IsNext) ? true : false;
        this.exercises = model.Prescriptions.map((p: PrescriptionModel) => EXERCISES.get(p.ExerciseId));
        this.assessment = model.IsAssessment ? model : null;
        this.isGeneralAssignment = model.IsAssessment ? false : true;
        this.prescriptions = model.Prescriptions;
    }

    public get DateCreated(): Date {
        return this.dateCreated;
    }

    public get IsSelected(): boolean {
        return this.isSelected;
    }

    public get Exercises(): ExerciseModel[] {
        return this.exercises;
    }

    public get EndDate(): string {
        return this.endDate;
    }

    public get Assessment(): AssessmentModel | null {
        return this.assessment;
    }

    public get Prescriptions(): PrescriptionModel[] {
        return this.prescriptions;
    }

    public get IsGeneralAssignment(): boolean {
        return this.isGeneralAssignment;
    }

    public get ExercisesCompleted(): number {
        return this.exercisesCompleted;
    }
}
