












































































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';
import * as FormatUtils from '@/common/utils/reports/format';
import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ButtonState } from '@/common/types/general';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import VergenceVisualization, { VergenceConfig } from '@/views/reports/visualizations/VergenceVisualization.vue';
import HorizontalSmoothPursuitVisualization, {
    HorizontalSmoothPursuitConfig,
} from '@/views/reports/visualizations/HorizontalSmoothPursuitVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        VergenceVisualization,
        HorizontalSmoothPursuitVisualization,
        GreenButton,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            metrics: undefined as any,
            config: undefined as HorizontalSmoothPursuitConfig | undefined,
            vergenceConfig: undefined as VergenceConfig | undefined,
            playButtonState: 'active' as ButtonState,
            playAnimation: false,
            headers: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: 'Right Eye',
                    value: 'right',
                },
                {
                    text: 'Left Eye',
                    value: 'left',
                },
                {
                    text: 'Both Eyes',
                    value: 'both',
                },
            ],
        };
    },
    watch: {
        reportData(newValue: ReportData) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
    methods: {
        playReplayAnimation() {
            this.playButtonState = 'inactive';
            this.playAnimation = true;
        },
        onAnimationFinished() {
            this.playButtonState = 'active';
            this.playAnimation = false;
        },
        loadReportData(reportData: ReportData) {
            if (reportData.tests.horizontalSmoothPursuit) {
                this.metrics = reportData.tests.horizontalSmoothPursuit.metrics;
                const visualData = VisualDataUtils.extractVisualData(reportData, 'horizontalSmoothPursuit');
                const systemType = reportData.assessment?.systemType ? reportData.assessment.systemType : 'I15';
                this.config = {
                    testData: visualData,
                    systemType,
                };
                this.vergenceConfig = {
                    testData: visualData,
                    systemType,
                    metrics: this.metrics,
                    radius: 20,
                };
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    computed: {
        tableData(): any {
            const metrics = this.reportData.tests.horizontalSmoothPursuit.metrics;
            return [
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.spPercent'),
                    right: FormatUtils.formatNumber(metrics?.right.spPercent),
                    left: FormatUtils.formatNumber(metrics?.left.spPercent),
                    both: FormatUtils.formatNumber(metrics?.both.spPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.saccadePercent'),
                    right: FormatUtils.formatNumber(metrics?.right.saccadePercent),
                    left: FormatUtils.formatNumber(metrics?.left.saccadePercent),
                    both: FormatUtils.formatNumber(metrics?.both.saccadePercent),
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.fixationPercent'),
                    right: FormatUtils.formatNumber(metrics?.right.fixationPercent),
                    left: FormatUtils.formatNumber(metrics?.left.fixationPercent),
                    both: FormatUtils.formatNumber(metrics?.both.fixationPercent),
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.eyeTargetVelErr'),
                    right: FormatUtils.formatNumber(metrics?.right.eyeTargetVelErr),
                    left: FormatUtils.formatNumber(metrics?.left.eyeTargetVelErr),
                    both: FormatUtils.formatNumber(metrics?.both.eyeTargetVelErr),
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.horizontalSynchronizationSp'),
                    right: FormatUtils.formatNumber(metrics?.right.horizontalSynchronizationSp),
                    left: FormatUtils.formatNumber(metrics?.left.horizontalSynchronizationSp),
                    both: FormatUtils.formatNumber(metrics?.both.horizontalSynchronizationSp),
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.intrusions'),
                    right: FormatUtils.formatString(metrics?.right.intrusions?.join(' / ')),
                    left: FormatUtils.formatString(metrics?.left.intrusions?.join(' / ')),
                    both: 'NA',
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.pathwayLengthDiffLeftSide'),
                    right: 'NA',
                    left: 'NA',
                    both: FormatUtils.formatNumber(metrics?.pathwayLengthDiffLeftSide),
                },
                {
                    name: this.$t('reports.metrics.tests.horizontalSmoothPursuit.pathwayLengthDiffRightSide'),
                    right: 'NA',
                    left: 'NA',
                    both: FormatUtils.formatNumber(metrics?.pathwayLengthDiffRightSide),
                },
            ];
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.horizontalSmoothPursuit?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.horizontalSmoothPursuit?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.horizontalSmoothPursuit?.metrics,
                'distanceFromScreen',
            );
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sp.smoothPursuit'));
            infoText.push(this.$t('reports.info.pursuits'));
            infoText.push(this.$t('reports.info.sp.saccadeFixation'));
            infoText.push(this.$t('reports.info.sp.eyeTargetVel'));
            infoText.push(this.$t('reports.info.sp.horVertSync'));
            infoText.push(this.$t('reports.info.sp.pathwayLengthDiff'));

            return infoText;
        },
        paperLink(): string {
            if (this.reportData.assessment.systemType === 'I15') {
                return 'reports.info.normsV3.I15.horizontalSmoothPursuit';
            }

            return 'reports.info.normsV3.SMI.horizontalSmoothPursuit';
        },
    },
});
