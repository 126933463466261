import { STATS } from './common/queryFragments';

const CS_METRICS_LRB = `{
    cyclesPerDegree3
    cyclesPerDegree6
    cyclesPerDegree12
    cyclesPerDegree18
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const CS_NORMS_LRB = `{
    cyclesPerDegree3 ${STATS}
    cyclesPerDegree6 ${STATS}
    cyclesPerDegree12 ${STATS}
    cyclesPerDegree18 ${STATS}
}
`;

export const CONTRAST_SENSITIVITY_NORMS_QUERY = `
contrastSensitivity {
    left ${CS_NORMS_LRB}
    right ${CS_NORMS_LRB}
    both ${CS_NORMS_LRB}
}
`;

export const CONTRAST_SENSITIVITY_METRIC_QUERY = `
contrastSensitivity {
    metrics {
        eyeType
        left ${CS_METRICS_LRB}
        right ${CS_METRICS_LRB}
        both ${CS_METRICS_LRB}
    }
}
`;
