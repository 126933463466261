

















































import Vue, { PropType } from 'vue';

import { getTimeStampFormat, getDOBFormat } from '@/common/utils/date';
import * as ParticipantUtils from '@/common/utils/participant';

import CompanyLogo from '@/views/common/components/CompanyLogo.vue';

import { Assessment } from '@/common/types/reports/assessment';
import { Company } from '@/common/types/company/company';
import { ComparativeAssessmentInfo } from '@/common/types/reports/reportData';
import { Participant } from '@/common/types/user/participant';

export default Vue.extend({
    components: {
        CompanyLogo,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        assessment: {
            type: Object as PropType<Assessment>,
            required: false,
        },
        comparativeAssessmentInfo: {
            type: Object as PropType<ComparativeAssessmentInfo>,
            required: false,
        },
    },
    computed: {
        company(): Company {
            return this.$store.getters.viewingCompany;
        },
        participant(): Participant {
            return this.$store.getters.viewingParticipant;
        },
        dateOfBirth(): string {
            const dob = ParticipantUtils.getDateOfBirth(this.participant);
            return this.formatDateOfBirth(dob);
        },
    },
    methods: {
        formatAssessmentDate(date: string): string {
            return `${getTimeStampFormat(date)}`;
        },
        formatDateOfBirth(date: string): string {
            return `${getDOBFormat(date)}`;
        },
    },
});
