



















import Vue from 'vue';
export default Vue.extend({
    props: {
        value: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            model: false as boolean,
        };
    },
    watch: {
        value(newValue) {
            this.model = newValue;
        },
    },
    methods: {
        onChange(value: any) {
            this.$emit('input', this.model);
        },
    },
    mounted() {
        this.model = this.value;
    },
});
