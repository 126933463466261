























import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';

import * as SensorimotorUtils from '@/common/utils/reports/sensorimotor';

import { TestData } from '@/common/types/reports/reportData';
import { Visualization } from '@/common/utils/reports/visualization/visualization';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';
import Logger from '@/common/utils/logger';
import SensorimotorApi from '@/api/sensorimotor.api';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { PARTICIPANT_ROLE } from '@/common/constants/userRoles.constants';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import { ButtonState } from '@/common/types/general';

export interface SensorimotorScreenerResultConfig {
    calibrationTestData: TestData;
    nearPointConvergenceTestData: TestData;
    systemType: string;
    assessmentId: string;
    participantId: string;
    participantDob: string;
    result: string;
    overrideResult: boolean;
    hasSymptoms: boolean;
}

const ZOOM_LEVEL = 0.8;

export default Vue.extend({
    components: {
        LoadingCircle,
        SaveConfirmation,
        GreenButton,
    },
    props: {
        config: {
            type: Object as PropType<SensorimotorScreenerResultConfig>,
        },
        hasSensorimotorExam: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            overrideResult: false as boolean,
            isScreenerPassed: false as boolean,
            examRecommended: '(Full Sensorimotor Exam Recommended)' as string,
            overrideLabel: 'Override Sensorimotor Screener Result' as string,
            showConfirmation: false as boolean,
            dirty: false as boolean,
            saving: false as boolean,
            surveyScore: 0 as number,
            loading: false,
        };
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        screenerResult(): string {
            let overallResult = this.isScreenerPassed;
            if (this.overrideResult) {
                overallResult = !overallResult;
            }

            return overallResult ? 'Next Step: Consult Provider' : 'Next Step: Administer Sensorimotor Exam';
        },
        isParticipant(): boolean {
            return this.$store.getters.userRole === PARTICIPANT_ROLE;
        },
        overrideText(): string {
            if (this.overrideResult) {
                return 'Yes';
            } else {
                return 'No';
            }
        },
        overrideButtonState(): ButtonState {
            return 'active';
        },
    },
    methods: {
        showSaveConfirmation() {
            this.showConfirmation = true;
        },
        saveConfirmation() {
            this.saveScreenerResultOverride(this.config.assessmentId, this.overrideResult);
            this.showConfirmation = false;
        },
        cancelConfirmation() {
            this.showConfirmation = false;
            this.overrideResult = !this.overrideResult;
        },
        async saveScreenerResultOverride(assessmentId: string, overrideResult: boolean) {
            this.saving = true;
            try {
                await SensorimotorApi.updateScreenerResult({ assessmentId, overrideResult });
            } catch (error) {
                Logger.error(`Failed to load assessment conditions: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
            this.saving = false;
            this.dirty = false;
        },
        async handleOverrideButton() {
            this.overrideResult = !this.overrideResult;
            this.showConfirmation = true;
        },
        getSurveyScore(responses: object): number {
            let score = 0;
            for (const response of Object.values(responses)) {
                switch (response) {
                    case 'A0':
                        score += 0;
                        break;
                    case 'A1':
                        score += 1;
                        break;
                    case 'A2':
                        score += 2;
                        break;
                    case 'A3':
                        score += 3;
                        break;
                    case 'A4':
                        score += 4;
                        break;
                    default:
                        score += 0;
                }
            }
            return score;
        },
    },
    async mounted() {
        this.loading = true;
        const screenData = Constants.SCREEN_DATA[this.config.systemType];
        const ASPECT_RATIO = screenData.width / screenData.height;

        if (!this.config.calibrationTestData) {
            throw new Error('This assessment is missing Calibration data.');
        }

        if (!this.config.nearPointConvergenceTestData) {
            throw new Error('This assessment is missing Near Point Convergence data.');
        }

        this.overrideResult = this.config.overrideResult;

        const ninePointMotorFunctionResult = SensorimotorUtils.isNinePointMotorFunctionPassed(this.config.result);
        const nearPointConvergenceResult = SensorimotorUtils.isNearPointConvergencePassed(
            this.config.nearPointConvergenceTestData.metrics.breakPoint,
            this.config.nearPointConvergenceTestData.metrics.recoveryPoint,
        );

        const breakPointResult = this.config.nearPointConvergenceTestData.metrics.breakPoint;
        const recoveryPointResult = this.config.nearPointConvergenceTestData.metrics.recoveryPoint;

        this.isScreenerPassed = SensorimotorUtils.isScreenerPassed(
            ninePointMotorFunctionResult,
            nearPointConvergenceResult,
            !this.config.hasSymptoms,
        );

        let bothEyeCount = 0;
        for (const point of this.config.calibrationTestData.metrics.sensorimotorResult.sensorimotorResult) {
            if (point.sensorimotorPoint === 'BOTH_EYES') {
                bothEyeCount++;
            }
        }

        let symptomQuestionResult = 'N/A';

        if (this.config.hasSymptoms != null) {
            if (this.config.hasSymptoms) {
                symptomQuestionResult = 'Yes';
            } else {
                symptomQuestionResult = 'No';
            }
        }

        const fontSize = 0.0009;

        const defaultAttributes = {
            'fill': 'none',
            'stroke': 'black',
            'stroke-width': 0.001 / ZOOM_LEVEL,
        };

        let isValidBreakAndRecovery = true;
        if (breakPointResult < 0 || recoveryPointResult < 0) {
            isValidBreakAndRecovery = false;
        }

        Visualization.build()
            .line({
                start: [-0.12, 0.02],
                end: [1.12, 0.02],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [-0.12, 0.06],
                end: [1.12, 0.06],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [-0.12, 0.15],
                end: [1.12, 0.15],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [-0.12, 0.21],
                end: [1.12, 0.21],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [-0.12, 0.27],
                end: [1.12, 0.27],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [0.28, 0.02],
                end: [0.28, 0.27],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [-0.12, 0.02],
                end: [-0.12, 0.27],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [0.7, 0.02],
                end: [0.7, 0.27],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .line({
                start: [1.12, 0.02],
                end: [1.12, 0.27],
                aspectRatio: ASPECT_RATIO,
                attributes: defaultAttributes,
            })
            .text({
                content: `Test`,
                position: [0.05, 0.05],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'bold',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: `My Result`,
                position: [0.44, 0.05],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'bold',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: `Expected Result`,
                position: [0.83, 0.05],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'bold',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: `Nine Point Motor Function`,
                position: [-0.1, 0.12],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: `Near Point of Convergence`,
                position: [-0.1, 0.19],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: `Binocular Vision Disorder Symptom Question`,
                position: [-0.1, 0.25],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: `Both eyes are in ${bothEyeCount} or more targets`,
                position: [0.3, 0.12],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: isValidBreakAndRecovery
                    ? `Break Point = ${breakPointResult}cm, Recovery Point = ${recoveryPointResult}cm`
                    : 'TEST INCOMPLETE',
                position: [0.3, 0.19],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'left',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: symptomQuestionResult,
                position: [0.3, 0.25],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'left',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: 'Both eyes within all 9 targets',
                position: [0.71, 0.1],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: 'One eye on at least 8 targets',
                position: [0.71, 0.13],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'start',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: 'Break Point = 10cm, Recovery Point = 12cm',
                position: [0.71, 0.19],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'left',
                    'letter-spacing': '0',
                },
            })
            .text({
                content: 'No',
                position: [0.71, 0.25],
                aspectRatio: ASPECT_RATIO,
                attributes: {
                    'font-size': `${fontSize / ZOOM_LEVEL}em`,
                    'font-family': 'sans-serif',
                    'font-weight': 'regular',
                },
                styles: {
                    'fill': 'black',
                    'text-anchor': 'left',
                    'letter-spacing': '0',
                },
            })
            .zoom(ZOOM_LEVEL)
            .drawNormalized(this.svg, 11 / 2);

        this.loading = false;
    },
});
