

























import Vue, { PropType } from 'vue';

import ReactionTime, { ReactionTimeConfig } from '@/views/reports/visualizations/ReactionTime.vue';

import { ReportData } from '@/common/types/reports/reportData';

import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        ReactionTime,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
    },
    data() {
        return {
            config: {
                choiceReactionTime: this.reportData.tests.choiceReactionTime.metrics,
                discriminateReactionTime: this.reportData.tests.discriminateReactionTime.metrics,
            } as ReactionTimeConfig,
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.choiceReactionTime?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.choiceReactionTime?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.choiceReactionTime?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.reactionTime.saccadicLatency'));
            infoText.push(this.$t('reports.info.reactionTime.definition'));

            return infoText;
        },
    },
});
