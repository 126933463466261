




import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';

import { Position2d } from '@/common/types/reports/position';

import { Visualization } from '@/common/utils/reports/visualization//visualization';
import * as FormatUtils from '@/common/utils/reports/format';
import * as ScoreUtils from '@/common/utils/reports/visualization/score';

export interface ScoreConfig {
    overall: number;
    saccades: number;
    pursuits: number;
    fixations: number;
    saccadesPercentile: number | undefined;
    pursuitsPercentile: number | undefined;
    fixationsPercentile: number | undefined;
    functionalWindowLowerBound: number;
    functionalWindowUpperBound: number;
    overallAccuracy: number;
    saccadeAccuracy: number;
    pursuitAccuracy: number;
    fixationAcurracy: number;
    reportVersion: number;
}

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<ScoreConfig>,
        },
    },
    methods: {
        calculateScoreColor(score: number): string {
            if (score >= 75) {
                return Constants.GREEN;
            } else if (score >= 50) {
                return Constants.YELLOW;
            } else if (score >= 25) {
                return Constants.ORANGE;
            } else {
                return Constants.RED;
            }
        },
        calculateScorePercentageInRadians(score: number): number {
            return (Math.PI * 2 * score) / 100;
        },
    },
    mounted() {
        const visualization = Visualization.build();

        const overallScore = this.config.reportVersion === 1 ? this.config.overall : this.config.overallAccuracy;
        const pursuitScore = this.config.reportVersion === 1 ? this.config.pursuits : this.config.pursuitAccuracy;
        const saccadeScore = this.config.reportVersion === 1 ? this.config.saccades : this.config.saccadeAccuracy;
        const fixationScore = this.config.reportVersion === 1 ? this.config.fixations : this.config.fixationAcurracy;

        // Gradiant
        const GRADIANT_LENGTH = 0.71;
        const GRADIANT_PADDING = 0.005;
        const GRADIANT_RECT_HEIGHT = 0.05;

        const SCORE_MARKER_POSITION = GRADIANT_LENGTH * (overallScore / 100);

        // Positions
        const GRADIANT_POSITION = new Position2d(0.24, 0.11);
        const OVERALL_POSITION = new Position2d(0.14, 0.65);
        const PURSUITS_POSITION = new Position2d(0.41, 0.58);
        const SACCADES_POSITION = new Position2d(0.64, 0.58);
        const FIXATIONS_POSITION = new Position2d(0.87, 0.58);

        // Text
        const TEXT_FONT_FAMILY = 'ProximaNova';
        const MARKER_FONT_FAMILY = 'serif';
        const NUMBER_FONT_SIZE_LARGE = '.0075em';
        const NUMBER_FONT_SIZE_SMALL = '.0045em';
        const TEXT_FONT_SIZE_LARGE = '.0035em';
        const TEXT_FONT_SIZE_SMALL = '.0026em';
        const PERCENTILE_TEXT_FONT_SIZE = '.0015em';
        const GRADIANT_MARKER_FONT_SIZE = '.005em';
        const GRADIANT_SCORE_FONT_SIZE = '.0028em';
        const GRADIANT_WINDOW_FONT_SIZE = '.002em';
        const ABOVE_TEXT_OFFSET = -0.47;
        const BELOW_TEXT_OFFSET = 0.28;
        const FUNCTIONAL_WINDOW_TEXT_OFFSET = GRADIANT_POSITION.y + GRADIANT_RECT_HEIGHT + 0.048;
        const PERCENTILE_TEXT_OFFSET = BELOW_TEXT_OFFSET + 0.09;
        const ACCURACY_TEXT_FONT_SIZE = '0.001em';

        const LARGE_CIRCLE_INNER_RADIUS = 0.085;
        const LARGE_CIRCLE_OUTER_RADIUS = 0.12;
        const SMALL_CIRCLE_INNER_RADIUS = 0.055;
        const SMALL_CIRCLE_OUTER_RADIUS = 0.08;

        // Dysfunctional Rects
        const GRADIANT_RECT_WIDTH = GRADIANT_LENGTH / 4;

        const ASPECT_RATIO = 8 / 3;

        // Overall score
        ScoreUtils.generateScoreCircle(visualization, {
            position: OVERALL_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: LARGE_CIRCLE_OUTER_RADIUS,
            thickness: LARGE_CIRCLE_OUTER_RADIUS - LARGE_CIRCLE_INNER_RADIUS,
            labels: [
                {
                    text: this.$t('reports.reports.score.overall').toString(),
                    positionOffset: new Position2d(0, ABOVE_TEXT_OFFSET),
                    fontSize: TEXT_FONT_SIZE_LARGE,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
                {
                    text: overallScore.toString(),
                    positionOffset: new Position2d(0, 0),
                    fontSize: NUMBER_FONT_SIZE_LARGE,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
            ],
            color: this.calculateScoreColor(overallScore),
            fillPercent: overallScore,
        });

        const pursuitLabels = [
            {
                text: this.$t('reports.reports.score.pursuits').toString(),
                positionOffset: new Position2d(0, BELOW_TEXT_OFFSET),
                fontSize: TEXT_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
            {
                text: pursuitScore.toString(),
                positionOffset: new Position2d(0, 0),
                fontSize: NUMBER_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
        ];

        if (this.config.reportVersion === 1) {
            pursuitLabels.push({
                text: 'PERCENTILE: ' + this.config.pursuitsPercentile?.toString(),
                positionOffset: new Position2d(0, PERCENTILE_TEXT_OFFSET),
                fontSize: PERCENTILE_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        } else {
            pursuitLabels.push({
                text: 'Accuracy Score',
                positionOffset: new Position2d(0.0, 0.35),
                fontSize: ACCURACY_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        }

        // Pursuits Score
        ScoreUtils.generateScoreCircle(visualization, {
            position: PURSUITS_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: pursuitLabels,
            color: this.calculateScoreColor(pursuitScore),
            fillPercent: pursuitScore,
        });

        const saccadeLabels = [
            {
                text: this.$t('reports.reports.score.saccades').toString(),
                positionOffset: new Position2d(0, BELOW_TEXT_OFFSET),
                fontSize: TEXT_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
            {
                text: saccadeScore.toString(),
                positionOffset: new Position2d(0, 0),
                fontSize: NUMBER_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
        ];

        if (this.config.reportVersion === 1) {
            saccadeLabels.push({
                text: 'PERCENTILE: ' + this.config.saccadesPercentile?.toString(),
                positionOffset: new Position2d(0, PERCENTILE_TEXT_OFFSET),
                fontSize: PERCENTILE_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        } else {
            saccadeLabels.push({
                text: 'Accuracy Score',
                positionOffset: new Position2d(0.0, 0.35),
                fontSize: ACCURACY_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        }

        // Saccades Score
        ScoreUtils.generateScoreCircle(visualization, {
            position: SACCADES_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: saccadeLabels,
            color: this.calculateScoreColor(saccadeScore),
            fillPercent: saccadeScore,
        });

        const fixationLabels = [
            {
                text: this.$t('reports.reports.score.fixations').toString(),
                positionOffset: new Position2d(0, BELOW_TEXT_OFFSET),
                fontSize: TEXT_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
            {
                text: fixationScore.toString(),
                positionOffset: new Position2d(0, 0),
                fontSize: NUMBER_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
        ];

        if (this.config.reportVersion === 1) {
            fixationLabels.push({
                text: 'PERCENTILE: ' + this.config.fixationsPercentile?.toString(),
                positionOffset: new Position2d(0, PERCENTILE_TEXT_OFFSET),
                fontSize: PERCENTILE_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        } else {
            fixationLabels.push({
                text: 'Accuracy Score',
                positionOffset: new Position2d(0.0, 0.35),
                fontSize: ACCURACY_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        }

        // Fixations Score
        ScoreUtils.generateScoreCircle(visualization, {
            position: FIXATIONS_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: fixationLabels,
            color: this.calculateScoreColor(fixationScore),
            fillPercent: fixationScore,
        });

        // Gradiant
        ScoreUtils.generateScoreBar(
            visualization,
            {
                position: GRADIANT_POSITION,
                height: GRADIANT_RECT_HEIGHT,
                length: GRADIANT_LENGTH,
                aspectRatio: ASPECT_RATIO,
                padding: GRADIANT_PADDING,
                functionalRange: [this.config.functionalWindowLowerBound, this.config.functionalWindowUpperBound],
                labels: [
                    {
                        text: 0,
                        positionOffset: new Position2d(0, -0.001 + FUNCTIONAL_WINDOW_TEXT_OFFSET),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(25, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH + GRADIANT_PADDING,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(50, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 2 + GRADIANT_PADDING * 2,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(75, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 3 + GRADIANT_PADDING * 3,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(100, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 4 + GRADIANT_PADDING * 4,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'end',
                        baseline: 'top',
                    },
                    {
                        text: 'I',
                        positionOffset: new Position2d(SCORE_MARKER_POSITION, -0.013),
                        fontSize: GRADIANT_MARKER_FONT_SIZE,
                        fontFamily: MARKER_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'hanging',
                        draw: overallScore !== undefined,
                    },
                    {
                        text: overallScore.toString(),
                        positionOffset: new Position2d(SCORE_MARKER_POSITION, -0.03),
                        fontSize: GRADIANT_SCORE_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'bottom',
                        draw: overallScore !== undefined,
                    },
                ],
            },
            this.config.reportVersion,
        );

        // Actually build the visualization according to the space avaliable
        visualization.drawNormalized(this.svg, 8 / 3);
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
});
