export default {
    connectivityError:
        'It appears we are having difficulty reaching RightEye services. ' +
        'Please check internet connection and try again. If issues persist, ' +
        'please contact customer support at 301-979-7970 or email us at ' +
        'support@righteye.com.',
    chooseWorkoutConnectivityError:
        'Try clicking the button again to start your workout.' +
        'If the problem persists please contact support at 301-979-7970 or support@righteye.com.',
};
