export default {
    settings: {
        messageBarText: 'RightEye Provider Settings',
        eyeqTrainer: {
            global: {
                label: 'EyeQ Trainer',
                accessDefault: {
                    enabled: 'Enable EyeQ Trainer by default for all participants assigned exercises',
                    disabled: 'Enable EyeQ Trainer manually per participant',
                },
                confirmation: 'Your settings have been updated successfully.',
            },
        },
        assessments: {
            showHiddenAssessments: 'Show Hidden Assessments',
            hideHiddenAssessments: 'Hide Hidden Assessments',
        },
    },
    summary: {
        messageBarText: 'EyeQ Trainer Summary Report',
        tableHeaders: {
            patientId: 'Patient ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            status: 'Status',
            startDate: 'Start Date',
            endDate: 'End Date',
            eyeQTrainerEnabled: 'EQT Enabled',
        },
        tableSizeOptions: ['10', '25', '50'],
        tableWithFilterIsEmptyError: 'No patients found using given option "{option}" and parameter "{parameter}".',
        tableNoFilterIsEmptyError: 'No patients found.',
        pageSelectionDescription: '{range} of {total}',
        pageSelectionFirst: 'To first page',
        pageSelectionPrevious: 'To previous page',
        pageSelectionNext: 'To next page',
        pageSelectionLast: 'To last page',
        rowSelection: {
            show: 'Display',
            row: 'rows',
            tooltip: 'Select number of rows to display',
        },
        filterSelection: {
            filterBy: 'Filter results by ',
            filterOptions: {
                PATIENT_ID: 'Patient Id',
                FIRST_NAME: 'First Name',
                LAST_NAME: 'Last Name',
                EQT_ENABLED: 'EQT Enabled',
            },
            enabledFilterOptions: {
                NOT_SELECTED: 'N/A',
                ENABLED: 'Yes',
                DISABLED: 'No',
            },
            filterClearButtonContents: 'X',
            filterClearButtonTooltip: 'Clear the current filter',
            inputPlaceholder: 'Type here...',
            inputTooltip: 'Type here, and press ENTER to submit filter',
            dropdownPlaceholder: 'Choose...',
            dropdownTooltip: 'Choose a filter option',
            dropdownEnabledFilter: 'Choose an EQT enabled filter option',
        },
        closeButtonText: 'Close',
        toggleEnabled: 'EyeQ Trainer Enabled',
        toggleDisabled: 'EyeQ Trainer Disabled',
        noAssociatedUser: 'Please create a user for this participant',
        eqtInUse: 'EyeQ Trainer in use',
    },
    cycleDetails: {
        patientInformation: 'Patient Information',
        name: 'Name',
        dateOfBirth: 'Date of Birth',
        patientId: 'Patient ID',
        programStart: 'Start',
        programEnd: 'End',
        totalWorkouts: 'Total Workouts Completed',
        cycleNumber: 'Cycle Number',
        startDate: 'Start Date',
        endDate: 'End Date',
        completedWorkouts: 'Completed Workouts',
        assessmentDate: 'Assessment Date',
    },
    assessmentDetails: {
        header: 'Assessment Date',
    },
    exerciseDemoList: {
        heading: 'Select an exercise below to preview',
        exercises: 'EXERCISES',
        fixationLabel: 'FIXATION',
        pursuitLabel: 'PURSUIT',
        saccadeLabel: 'SACCADE',
        fixation: 'Fixation',
        leftPursuit: 'Left Pursuit',
        rightPursuit: 'Right Pursuit',
        verticalPursuit: 'Vertical Pursuit',
        leftVPattern: 'Left V Pattern',
        rightVPattern: 'Right V Pattern',
        bilateralVPattern: 'Bilateral V Pattern',
        leftAPattern: 'Left A Pattern',
        rightAPattern: 'Right A Pattern',
        bilateralAPattern: 'Bilateral A Pattern',
        leftSaccade: 'Left Saccade',
        rightSaccade: 'Right Saccade',
        upSaccade: 'Up Saccade',
        downSaccade: 'Down Saccade',
    },
    exerciseList: {
        currentExercises: 'Current Exercises',
    },
    assessmentSelection: {
        noAssessmentsAvailable: 'No Assignment Available',
        chooseAssessment: 'Choose an Assignment',
        generalAssignment: 'General Exercises',
        assignGeneralAssignment: 'Assign General Exercises',
        assessmentFrom: 'Assessment from',
        exercisesPrescribed: 'Exercises',
        noSelection: 'Choose an Assignment',
        unsavedSelection: 'Save Selection',
        savedSelection: 'Selection Saved!',
    },
    termsOfService: {
        statement:
            'Starting January 1, 2020, by enabling EyeQ Trainer, you will be charged a $25 fee per user when they start an EyeQ Trainer cycle, or you can select $2,500 annually for unlimited access.',
        strategies: '{clickHere} for strategies on pricing EyeQ Trainer profitably.',
        linkText: 'Click here',
        linkUrl: 'https://success.righteye.com/hc/en-us/articles/360018818293-Pricing-Suggestions',
    },
};
