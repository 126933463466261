






































































































































































import Vue, { PropType } from 'vue';

import { SET_ERROR_BANNER } from '@/store/general/mutations';

import { ButtonState } from '@/common/types/general';
import { Company, Country, State } from '@/common/types/company/company';
import { UserRole } from '@/common/types/auth/auth';
import { CreateUserRequest } from '@/common/types/api/requests/userRequests';
import { User } from '@/common/types/user/user';

import { USER_ROLES } from '@/common/constants/userRoles.constants';

import AuthApi from '@/api/auth.api';
import CompanyApi from '@/api/company.api';
import UserApi from '@/api/user.api';

import ComponentModal from '@/views/common/containers/ComponentModal.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';

export default Vue.extend({
    components: {
        ComponentModal,
        GreenButton,
        SaveConfirmation,
    },
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        user: {
            type: Object as PropType<User>,
            required: false,
        },
    },
    data() {
        return {
            rules: {
                required: (value: any) => !!value || 'Required.',
            },
            email: undefined as undefined | string,
            firstName: undefined as undefined | string,
            middleName: undefined as undefined | string,
            lastName: undefined as undefined | string,
            company: undefined as undefined | Company,
            role: undefined as undefined | UserRole,
            address1: undefined as undefined | string,
            address2: undefined as undefined | string,
            city: undefined as undefined | string,
            state: undefined as undefined | State,
            country: undefined as undefined | Country,
            phone: undefined as undefined | string,
            mobile: undefined as undefined | string,
            active: true as boolean,
            locked: false as boolean,
            countries: [] as Country[],
            states: [] as State[],
            roles: [] as UserRole[],
            companies: [] as Company[],
            showConfirmation: false as boolean,
        };
    },
    computed: {
        title(): string {
            return this.isEdit
                ? this.$t('participantInfo.forms.editUser').toString()
                : this.$t('participantInfo.forms.createUser').toString();
        },
        isCreate() {
            return this.user === undefined;
        },
        isEdit() {
            return this.user !== undefined;
        },
        isDirty(): boolean {
            return (
                this.email !== this.user?.email ||
                this.firstName !== this.user?.firstName ||
                this.middleName !== this.user?.middleName ||
                this.lastName !== this.user?.lastName ||
                this.company?.id !== this.user?.company?.id ||
                this.role !== this.user?.role ||
                this.address1 !== this.user?.address1 ||
                this.address2 !== this.user?.address2 ||
                this.city !== this.user?.city ||
                this.state !== this.user?.state ||
                this.country !== this.user?.country ||
                this.phone !== this.user?.phone ||
                this.mobile !== this.user?.mobile ||
                this.active !== this.user?.active ||
                this.locked !== this.user?.locked
            );
        },
        isValid(): boolean {
            // v-form's built in validate function crashed chrome when used in a computed
            return (
                this.email !== undefined &&
                this.email !== '' &&
                this.firstName !== undefined &&
                this.firstName !== '' &&
                this.lastName !== undefined &&
                this.lastName !== '' &&
                this.company !== undefined &&
                this.role !== undefined
            );
        },
        saveButtonState(): ButtonState {
            return this.isDirty && this.isValid ? 'active' : 'inactive';
        },
        statesList(): Array<{ text: string; value: State }> {
            return this.states.map((state) => {
                return {
                    text: state.name,
                    value: state,
                };
            });
        },
        countriesList(): Array<{ text: string; value: Country }> {
            return this.countries.map((country) => {
                return {
                    text: country.name,
                    value: country,
                };
            });
        },
        rolesList(): Array<{ text: string; value: UserRole }> {
            return this.roles.map((role) => {
                return {
                    text: role.name,
                    value: role,
                };
            });
        },
        companiesList(): Array<{ text: string; value: Company }> {
            return this.companies.map((company) => {
                return {
                    text: company.name,
                    value: company,
                };
            });
        },
    },
    watch: {
        user(newValue: User) {
            this.email = undefined;
            this.firstName = undefined;
            this.middleName = undefined;
            this.lastName = undefined;
            this.company = undefined;
            this.role = undefined;
            this.address1 = undefined;
            this.address2 = undefined;
            this.city = undefined;
            this.state = undefined;
            this.country = undefined;
            this.phone = undefined;
            this.mobile = undefined;
            this.setUserFields(newValue);
        },
    },
    mounted() {
        this.loadUserRoles();
        this.loadCountries();
        this.loadStatesForCountry('US');
        this.loadCompanies();
    },
    methods: {
        setUserFields(user: User) {
            this.email = user?.email;
            this.firstName = user?.firstName;
            this.middleName = user?.middleName;
            this.lastName = user?.lastName;
            this.role = user?.role;
            this.address1 = user?.address1;
            this.address2 = user?.address2;
            this.city = user?.city;
            this.state = user?.state;
            this.country = user?.country;
            this.phone = user?.phone;
            this.mobile = user?.mobile;
            this.active = user?.active;
            this.locked = user?.locked;
            this.company = user?.company;

            // Find the exact company object so === works
            this.company = this.companies
                .filter((company) => {
                    if (company.id === user?.company?.id) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .pop();
        },
        async save() {
            if (this.isEdit) {
                this.editUser();
            } else {
                this.createUser();
            }
        },
        async editUser() {
            try {
                if (this.email) {
                    this.user.email = this.email;
                }
                if (this.firstName) {
                    this.user.firstName = this.firstName;
                }
                if (this.middleName) {
                    this.user.middleName = this.middleName;
                }
                if (this.lastName) {
                    this.user.lastName = this.lastName;
                }
                if (this.company) {
                    this.user.company = this.company;
                }
                if (this.role) {
                    this.user.role = this.role;
                }
                if (this.address1) {
                    this.user.address1 = this.address1;
                }
                if (this.address2) {
                    this.user.address2 = this.address2;
                }
                if (this.city) {
                    this.user.city = this.city;
                }
                if (this.state) {
                    this.user.state = this.state;
                }
                if (this.country) {
                    this.user.country = this.country;
                }
                if (this.phone) {
                    this.user.phone = this.phone;
                }
                if (this.mobile) {
                    this.user.mobile = this.mobile;
                }

                const sendPasswordResetEmail = this.user.locked !== this.locked && this.locked ? true : false;

                // If these are undefined, API will override the current states with false
                this.user.active = this.active;
                this.user.locked = this.locked;

                await UserApi.updateUser(this.user);

                if (sendPasswordResetEmail) {
                    AuthApi.forgotPassword(this.user.email);
                }

                this.$emit('updated', this.user);
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async createUser() {
            try {
                const user = {
                    active: this.active,
                    locked: this.locked,
                    email: this.email,
                    firstName: this.firstName,
                    middleName: this.middleName,
                    lastName: this.lastName,
                    companyId: this.company?.id,
                    roleId: this.role?.id,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    stateCode: this.state?.code,
                    country: this.country?.code,
                    phone: this.phone,
                    mobile: this.mobile,
                } as CreateUserRequest;
                await UserApi.createUser(user);
                this.$emit('created', this.user);
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async loadCountries() {
            try {
                this.countries = await CompanyApi.getListOfCountries();
                this.countries = this.countries.sort((rhs, lhs) => (rhs.name > lhs.name ? 1 : -1));

                // Put US first on list after sorting, leaving the extra copy in
                const usCountry = this.countries.filter((country) => {
                    if (country.code === 'US') {
                        return true;
                    } else {
                        return false;
                    }
                });
                this.countries = usCountry.concat(this.countries);
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async loadStatesForCountry(countryCode: string) {
            try {
                this.states = await CompanyApi.getListOfStatesByCountry(countryCode);
                this.states = this.states.sort((rhs, lhs) => (rhs.name > lhs.name ? 1 : -1));
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async loadUserRoles() {
            try {
                this.roles = await AuthApi.getUserRoles();

                // Only show the roles we currently want to support
                this.roles = this.roles.filter((r: UserRole) => {
                    if (USER_ROLES.includes(r.name)) {
                        return true;
                    } else {
                        return false;
                    }
                });
                this.roles = this.roles.sort((rhs, lhs) => (rhs.id > lhs.id ? 1 : -1));
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async loadCompanies() {
            try {
                this.companies = await CompanyApi.getCompanies();
                this.companies = this.companies.sort((rhs, lhs) => (rhs.name > lhs.name ? 1 : -1));
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        showSaveConfirmation() {
            this.showConfirmation = true;
        },
        saveConfirmation() {
            this.save();
            this.showConfirmation = false;
            this.$emit('hide');
        },
        cancelConfirmation() {
            this.showConfirmation = false;
        },
    },
});
