import { PHORIA, STATS } from './common/queryFragments';

const HSP_METRICS_LRB = `{
    spPercent
    spEfficiency
    saccadePercent
    fixationPercent
    eyeTargetVelErr
    horizontalSynchronizationSp
    intrusions
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const HSP_NORMS_LRB = `{
    spPercent ${STATS}
    saccadePercent ${STATS}
    fixationPercent ${STATS}
    eyeTargetVelErr ${STATS}
    horizontalSynchronizationSp ${STATS}
    intrusions ${STATS}
}
`;

export const HORIZONTAL_SMOOTH_PURSUIT_NORMS_QUERY = `
horizontalSmoothPursuit {
    left ${HSP_NORMS_LRB}
    right ${HSP_NORMS_LRB}
    both ${HSP_NORMS_LRB}
}
`;

export const HORIZONTAL_SMOOTH_PURSUIT_METRIC_QUERY = `
horizontalSmoothPursuit {
    metrics {
        pathwayLengthDiffRightSide
        pathwayLengthDiffLeftSide
        left ${HSP_METRICS_LRB}
        right ${HSP_METRICS_LRB}
        both ${HSP_METRICS_LRB}
        disassociatedPhoria ${PHORIA}
    }
    visualData {
        gazeData {
            timeStamp
            phoria ${PHORIA}
            left {
                x
                y
                eyeMovement
            }
            right {
                x
                y
                eyeMovement
            }
        }
    }
}
`;
