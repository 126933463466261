import { StimulusState } from '@/common/utils/trainer/animation';
import { SaccadeAnimationParameters } from '@/common/types/trainer/animation';
import { Position2d } from '@/common/types/reports/position';

export class SaccadeAnimation {
    private readonly animationSteps: SaccadeAnimationStep[] = [];

    public constructor(parameters: SaccadeAnimationParameters) {
        const pauseDuration = parameters.pauseDelay;
        const gapDuration = parameters.gapDelay;

        const step = new Position2d(
            (parameters.endPosition.x - parameters.startPosition.x) / parameters.numberOfSteps,
            (parameters.endPosition.y - parameters.startPosition.y) / parameters.numberOfSteps,
        );

        let currentTime = 0;
        for (let i = 0; i <= parameters.numberOfSteps; i += 1) {
            // Create Pause Step
            currentTime += pauseDuration;
            const pauseEndTime = currentTime;
            const stimulusPosition = new Position2d(
                parameters.startPosition.x + step.x * i,
                parameters.startPosition.y + step.y * i,
            );
            const pauseStimulusState = {
                position: stimulusPosition,
                radius: parameters.radius,
                isVisible: true,
                timerState: { duration: pauseDuration },
            };

            this.animationSteps.push({ endTime: pauseEndTime, state: pauseStimulusState });

            if (i < parameters.numberOfSteps) {
                // Create Gap Step
                currentTime += gapDuration;
                const gapEndTime = currentTime;
                const gapStimulusState = {
                    position: stimulusPosition,
                    radius: parameters.radius,
                    isVisible: false,
                    timerState: undefined,
                };

                this.animationSteps.push({ endTime: gapEndTime, state: gapStimulusState });
            }
        }
    }

    public stimulusState(time: number): StimulusState | undefined {
        if (time > this.animationSteps[this.animationSteps.length - 1].endTime || time < 0) {
            return undefined;
        } else {
            for (const step of this.animationSteps) {
                if (time < step.endTime) {
                    return step.state;
                }
            }
            return undefined;
        }
    }
}

interface SaccadeAnimationStep {
    endTime: number;
    state: StimulusState;
}
