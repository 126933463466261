import { STATS } from './common/queryFragments';

export const DYNAMIC_VISUAL_ACUITY_2_NORMS_QUERY = `
dynamicVisualAcuity2 {
    speed ${STATS}
    reactionTime ${STATS}
}
`;

export const DYNAMIC_VISUAL_ACUITY_2_METRIC_QUERY = `
dynamicVisualAcuity2 {
    metrics {
        speed
        reactionTime
        distanceFromScreen
        distanceFromScreenMin
        distanceFromScreenMax
    }
}
`;
