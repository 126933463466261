import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { Company } from '@/common/types/company/company';
import * as Mutations from '@/store/company/mutations';
import * as Actions from '@/store/company/mutations';
import store from '@/store/index';

import ComapnyApi from '@/api/company.api';
import Logger from '@/common/utils/logger';

interface CompanyState {
    viewingCompany?: Company | undefined;
}

const companyModule: Module<CompanyState, RootState> = {
    state: {
        viewingCompany: undefined,
    },
    mutations: {
        [Mutations.SET_VIEWING_COMPANY](state: CompanyState, company: Company) {
            state.viewingCompany = company;
        },
        [Mutations.CLEAR_COMPANY_STATE](state: CompanyState) {
            state.viewingCompany = undefined;
        },
    },
    actions: {
        async [Actions.SET_VIEWING_COMPANY](context, companyId: string) {
            if (context.state.viewingCompany?.id !== companyId) {
                try {
                    const company = await ComapnyApi.getCompanyById(companyId);
                    context.commit(Mutations.SET_VIEWING_COMPANY, company);
                } catch (error) {
                    Logger.error(`There was an error getting company with id ${companyId}: ${error}`);
                }
            }
        },
    },
    getters: {
        viewingCompany(state: CompanyState): Company {
            if (state.viewingCompany) {
                return state.viewingCompany;
            } else {
                return store.getters.currentUser.company;
            }
        },
    },
};

export default companyModule;
