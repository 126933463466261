import { STATS } from './common/queryFragments';

const CALIBRATION_EYE_TRACKING_DATA = `{
    stimulusPosition {
        x
        y
    }
    leftGazePosition {
        x
        y
    }
    rightGazePosition {
        x
        y
    }
    pointNumber
}`;

export const CALIBRATION_DIRECTIONAL_NORMS = `{
    midlinePrimary ${STATS}
    midlineLeft ${STATS}
    midlineRight ${STATS}
    superiorLeft ${STATS}
    superiorRight ${STATS}
    superiorMidline ${STATS}
    inferiorLeft ${STATS}
    inferiorRight ${STATS}
    inferiorMidline ${STATS}
}`;

export const CALIBRATION_NORMS_QUERY = `
calibration {
    pupilaryDistance ${STATS}
    disparity ${CALIBRATION_DIRECTIONAL_NORMS}
    left {
        horizontalDisplacement ${CALIBRATION_DIRECTIONAL_NORMS}
        verticalDisplacement ${CALIBRATION_DIRECTIONAL_NORMS}
        meanPupilDiameter ${STATS}
    }
    right {
        horizontalDisplacement ${CALIBRATION_DIRECTIONAL_NORMS}
        verticalDisplacement ${CALIBRATION_DIRECTIONAL_NORMS}
        meanPupilDiameter ${STATS}
    }
    both {
        horizontalDisplacement ${CALIBRATION_DIRECTIONAL_NORMS}
        verticalDisplacement ${CALIBRATION_DIRECTIONAL_NORMS}
        meanPupilDiameter ${STATS}
    }
}
`;

export const CALIBRATION_DIRECTIONAL_METRICS = `{
    midlinePrimary
    midlineLeft
    midlineRight
    superiorLeft
    superiorRight
    superiorMidline
    inferiorLeft
    inferiorRight
    inferiorMidline
}`;

export const CALIBRATION_METRICS_LRB = `{
    horizontalDisplacement ${CALIBRATION_DIRECTIONAL_METRICS}
    verticalDisplacement ${CALIBRATION_DIRECTIONAL_METRICS}
    meanPupilDiameter
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}`;

export const SENSORIMOTOR_METRICS = `{
    sensorimotorResult {
        sensorimotorPoint
    }
}`;

export const CALIBRATION_METRIC_QUERY = `
calibration {
    metrics {
        pupilaryDistance
        sensorimotorResult ${SENSORIMOTOR_METRICS}
        disparity ${CALIBRATION_DIRECTIONAL_METRICS}
        left ${CALIBRATION_METRICS_LRB}
        right ${CALIBRATION_METRICS_LRB}
        both ${CALIBRATION_METRICS_LRB}
    }
    eyeTrackingData {
        midlinePrimary ${CALIBRATION_EYE_TRACKING_DATA}
        superiorLeft ${CALIBRATION_EYE_TRACKING_DATA}
        superiorRight ${CALIBRATION_EYE_TRACKING_DATA}
        inferiorLeft ${CALIBRATION_EYE_TRACKING_DATA}
        inferiorRight ${CALIBRATION_EYE_TRACKING_DATA}
        midlineLeft ${CALIBRATION_EYE_TRACKING_DATA}
        superiorMidline ${CALIBRATION_EYE_TRACKING_DATA}
        midlineRight ${CALIBRATION_EYE_TRACKING_DATA}
        inferiorMidline ${CALIBRATION_EYE_TRACKING_DATA}
    }
}
`;
