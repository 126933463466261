import ApiService from '@/api/common/apiService';

class EmailApi {
    public async unsubscribe(email: string, emailSignature: string): Promise<void> {
        const url: string = '/righteye.api/email/unsubscribe';
        const body = { email, emailSignature };
        await ApiService.put<void>(url, body);
    }
}

export default new EmailApi();
