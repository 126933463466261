






import Vue from 'vue';

import * as ColorConstants from '@/common/constants/visualizations.constants';

export default Vue.extend({
    props: {
        label: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: ColorConstants.BLUE,
        },
    },
});
