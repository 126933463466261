var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":10}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('SearchBar',{attrs:{"search":_vm.search,"clearable":true},on:{"clear":_vm.clearFiltersAndSearch,"input":_vm.executeSearch}})],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":2}},[_c('GreenButton',{nativeOn:{"click":function($event){_vm.setSelectedUser();
                        _vm.setShowCreateEditUser(true);}}},[_vm._v(_vm._s(_vm.$t('participantInfo.buttons.createUser')))])],1)],1),_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.totalUsers,"items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"item-class":_vm.rowClass,"footer-props":{
                'items-per-page-options': [10, 25, 50, 100, 500],
                'showFirstLastPage': true,
            }},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){_vm.setSelectedUser($event);
                _vm.setShowCreateEditUser(true);}},scopedSlots:_vm._u([{key:"item.locked",fn:function(ref){
                var item = ref.item;
return [(item.locked)?_c('IconStatic',{staticClass:"lock-icon",attrs:{"icon":'lock',"size":'18',"tooltip":_vm.$t('participantInfo.tooltips.locked')}}):_vm._e()]}}],null,true)},[_c('ProgressBar',{attrs:{"slot":"progress","loading":_vm.loading},slot:"progress"})],1)],1),_c('CreateEditUser',{attrs:{"user":_vm.selectedUser,"show":_vm.showCreateEditUser},on:{"hide":function($event){return _vm.setShowCreateEditUser(false)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }