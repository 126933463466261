import { PHORIA, STATS } from './common/queryFragments';

const VSP_METRICS_LRB = `{
    spPercent
    spEfficiency
    saccadePercent
    fixationPercent
    eyeTargetVelErr
    verticalSynchronizationSp
    distanceFromScreen
    distanceFromScreenMin
    distanceFromScreenMax
}
`;

const VSP_NORMS_LRB = `{
    spPercent ${STATS}
    saccadePercent ${STATS}
    fixationPercent ${STATS}
    eyeTargetVelErr ${STATS}
    verticalSynchronizationSp ${STATS}
}
`;

export const VERTICAL_SMOOTH_PURSUIT_NORMS_QUERY = `
verticalSmoothPursuit {
    left ${VSP_NORMS_LRB}
    right ${VSP_NORMS_LRB}
    both ${VSP_NORMS_LRB}
}
`;

export const VERTICAL_SMOOTH_PURSUIT_METRIC_QUERY = `
verticalSmoothPursuit {
    metrics {
        pathwayLengthDiffTopSide
        pathwayLengthDiffBottomSide
        left ${VSP_METRICS_LRB}
        right ${VSP_METRICS_LRB}
        both ${VSP_METRICS_LRB}
        disassociatedPhoria ${PHORIA}
    }
    visualData {
        gazeData {
            timeStamp
            phoria ${PHORIA}
            left {
                x
                y
                eyeMovement
            }
            right {
                x
                y
                eyeMovement
            }
        }
    }
}
`;
