import { GraphQlQuery } from '@/common/types/api/requests/graphqlRequests';

import { CustomRequest, HttpMethod } from '@/common/types/api/requests/customRequest';
import AuthenticatedHttpClient from '@/api/common/authenticatedHttpClient';
import { AuthenticatedUser } from '@/common/types/auth/authenticatedUser';
import { GraphQlErrorResponse } from '@/common/types/api/responses/graphqlResponses';
class ApiService {
    private readonly client: AuthenticatedHttpClient;

    constructor() {
        this.client = new AuthenticatedHttpClient();
    }

    public async request<T>(request: CustomRequest): Promise<T> {
        const response = await this.client.request<any>(request);
        return response.data ?? response;
    }

    public async requestWithCustomAuthorization<T>(request: CustomRequest, authorization: string): Promise<T> {
        const response = await this.client.request<any>(request, authorization, true);
        return response.data ?? response;
    }

    public async requestWithNoAuthorization<T>(request: CustomRequest): Promise<T> {
        const response = await this.client.request<any>(request, '', false);
        return response.data ?? response;
    }

    public async requestWithDataField<T>(request: CustomRequest): Promise<T> {
        const response = await this.client.request<any>(request);
        return response;
    }

    public async get<T>(resource: string): Promise<T> {
        const request = {
            url: resource,
            method: 'GET' as HttpMethod,
        };
        const response = await this.request<T>(request);
        return response;
    }

    public async post<T>(resource: string, data: any = {}): Promise<T> {
        const request = {
            url: resource,
            method: 'POST' as HttpMethod,
            data,
        };
        const response = await this.request<T>(request);
        return response;
    }

    public async patch<T>(resource: string, data: any = {}): Promise<T> {
        const request = {
            url: resource,
            method: 'PATCH' as HttpMethod,
            data,
        };
        const response = await this.request<T>(request);
        return response;
    }

    public async put<T>(resource: string, data: any = {}, headers: any = {}): Promise<T> {
        const request = {
            url: resource,
            method: 'PUT' as HttpMethod,
            headers,
            data,
        };
        const response = await this.request<T>(request);
        return response;
    }

    public async graphql<T>(query: GraphQlQuery): Promise<T> {
        const url = '/data/graphql';
        const response = await this.post<any>(url, query.requestBody());

        if (response.errors) {
            const error = new GraphQlErrorResponse(response.errors);
            throw Error(error.toStringPretty());
        }

        return response;
    }

    public async login(username: string, password: string): Promise<AuthenticatedUser> {
        return this.client.authenticateWithCredentials(username, password);
    }

    public async logout(): Promise<void> {
        return this.client.logout();
    }
}

export default new ApiService();
