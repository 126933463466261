export const STATS = `{ mean stdDev }`;

export const SACCADIC_LATENCY_NORMS = `{
    average ${STATS}
}`;

export const MIN_MAX_AVG_NORMS = `{
    min ${STATS}
    max ${STATS}
    average ${STATS}
}`;

export const PHORIA = `{
    value
    label
    min
    max
    stdDev
}`;

export const TROPIA = '{ value label }';
