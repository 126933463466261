import { CycleResponse } from '@/common/types/api/responses/trainerResponses';
import { WorkoutPlan } from '@/common/types/trainer/program';

import { getTimeStampFormat } from '@/common/utils/date';

export default class CycleModel {
    private readonly cycleId: string;
    private readonly startDate: Date;
    private readonly endDate: Date;
    private readonly workoutsCompleted: number;
    private readonly assessmentDate: string;
    private readonly isActive: boolean;
    private readonly workoutPlan: WorkoutPlan | undefined;

    public constructor(cycleResponse: CycleResponse) {
        this.cycleId = cycleResponse.id;
        this.startDate = cycleResponse.start;
        this.endDate = cycleResponse.end;
        this.workoutsCompleted = cycleResponse.workoutsCompleted;
        if (cycleResponse.prescription) {
            this.assessmentDate = cycleResponse.prescription.createdDate.split(' ')[0];
        }
        this.isActive = cycleResponse.active;
        this.workoutPlan = cycleResponse.workoutPlan;
    }

    public get CycleId(): string {
        return this.cycleId;
    }

    public get StartDate(): string {
        return getTimeStampFormat(this.startDate);
    }

    public get EndDate(): string {
        return getTimeStampFormat(this.endDate);
    }

    public get WorkoutsCompleted(): number {
        return this.workoutsCompleted;
    }

    public get AssessmentDate(): string {
        return this.assessmentDate;
    }

    public get IsActive(): boolean {
        return this.isActive;
    }

    public get WorkoutPlan(): WorkoutPlan | undefined {
        return this.workoutPlan;
    }
}
