import * as ChangeCase from 'change-case';

const INVALID_VALUES = [88888, -88888, 99999, -99999, -9999, 9999, 8888, -8888];

export function isValidValue(value: string | number | undefined | null) {
    if (value === undefined || value === null || Number.isNaN(value as number)) {
        return false;
    } else if (typeof value === 'number') {
        // -88888 and -99999 are Magic Numbers indicating bad data, inherited from portal
        return !INVALID_VALUES.includes(value);
    } else if (typeof value === 'string') {
        return !value.includes('8888') && !value.includes('99999') && value !== '';
    } else {
        return true;
    }
}

export function formatValue(value: any, decimalPlaces: number = 2): string {
    if (!isValidValue(value)) {
        return 'NA';
    }

    if (typeof value === 'boolean') {
        return ChangeCase.capitalCase(value.toString());
    }

    if (typeof value === 'number' || !isNaN(value)) {
        return formatNumber(value, decimalPlaces);
    }

    if (typeof value === 'string') {
        const filterOptions = { stripRegexp: /[\_]/gi }; // removes "_" from string
        return ChangeCase.capitalCase(value, filterOptions);
    }

    if (value.mean) {
        const mean = formatValue(value.mean);
        if (value.stdDev) {
            const stdDev = formatValue(value.stdDev);
            return `${mean} (+/-${stdDev})`;
        } else {
            return mean;
        }
    }

    if (value.average) {
        return formatValue(value.average);
    }

    if (value.value) {
        return formatValue(value.value);
    }

    return 'NA';
}

export function formatNumber(value: number, decimalPlaces: number = 2): string {
    if (!isValidValue(value)) {
        return 'NA';
    }

    const floatValue = parseFloat(value.toString());
    return floatValue.toFixed(decimalPlaces);
}

export function esoExoFormatter(value: any): string {
    const valueNumber = parseFloat(value);
    const sign = valueNumber >= 0 ? '+' : '-';
    const text = valueNumber >= 0 ? 'eso' : 'exo';
    return `${sign}${formatNumber(valueNumber)} (${text})`;
}

export function formatString(value: string): string {
    if (isValidValue(value)) {
        return value;
    } else {
        return 'NA';
    }
}

export function formatArrayAsCommaSeperatedString(array: any[]) {
    let result = '';
    if (Array.isArray(array)) {
        array.map((i) => (result += i += ', '));
        result = result.slice(0, -2);
    } else if (typeof array === 'string') {
        return array;
    }
    return result;
}
