export const CONDITION_INFO_MODALS = {
    'Binocular Single Vision': {
        'Typically characterized by': [
            'normal eye alignment (orthophoric)',
            'vergence examination within normal parameters, that is, break point is greater than 4 inches (or 10 centimeters) and recovery point is less than 5 inches or (12 centimeters) and the CISS results below a score of 16',
        ],
        'Additional clinical tests and findings may include': ['none at this time'],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps': ['6-month follow up visit'],
    },
    'Esophoria': {
        'Typically characterized by': [
            '9-Point motor function inaccurate targeting of points ',
            'Cardinal Gaze Position left eye result of slow speed in relation to the reference range',
            'Cardinal gaze position right eye result of slow speed in relation to the reference range ',
            'CISS survey showing abnormal finding',
            '4-Dot resulting in uncrossed diplopia',
        ],
        'Additional clinical tests and findings may include': [
            'An esophoria when conducting a cover test',
            'Phoria using a phoropter',
            'Worth 4-Dot',
        ],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps if Esophoria': [
            'If symptomatic, then Prisms/Glasses',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Exophoria': {
        'Typically characterized by': [
            '9-Point motor function inaccurate targeting of points',
            'Cardinal Gaze Position left eye result of slow speed in relation to the reference range',
            'Cardinal gaze position right eye result of slow speed in relation to the reference range',
            'CISS survey showing abnormal finding',
            '4-Dot resulting in crossed diplopia',
        ],
        'Additional clinical tests and findings may include': [
            'An exophoria when conducting a cover test',
            'Phoria using a phoropter',
            'Worth 4-Dot',
        ],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps if Exophoria': [
            'If symptomatic, then Prisms/Glasses',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Hyperphoria': {
        'Typically characterized by': [
            '9-Point motor function inaccurate targeting of points',
            'Cardinal Gaze Position left eye result of slow speed in relation to the reference range',
            'Cardinal gaze position right eye result of slow speed in relation to the reference range',
            'CISS survey showing abnormal finding',
        ],
        'Additional clinical tests and findings may include': [
            'An hyperphoria when conducting a cover test',
            'Phoria using a phoropter',
            'Worth 4-Dot',
        ],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps if Hyperphoria': [
            'If symptomatic, then Prisms/Glasses',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Suppression Left': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': ['Worth 4-Dot', 'Random dot stereo'],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps if Suppression Left': [
            'Occlusion therapy',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Suppression Right': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': ['Worth 4-Dot', 'Random dot stereo'],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps if Suppression Right': [
            'Occlusion therapy',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Suppression Alternating': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': ['Worth 4-Dot', 'Random dot stereo'],
        'Reference': [
            'Leitman, M.W. (2021). Manual for Eye Examination and Diagnosis, 10th Ed. Wiley & Sons, Hoboken, NJ.',
        ],
        'Recommended next steps if Suppression Alternating': [
            'Occlusion therapy',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Diplopia, Uncrossed': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': ['Cover test', 'Worth 4-Dot', 'Random dot stereo'],
        'Reference': [
            'McGee, S. (2018) Chapter 59 – Nerves of the Eye Muscles (III, IV, and IV): Approach to Diplopia. In Evidence-Based Physical Diagnosis 4th Ed. Pp. 523-540. Elsevier, NY. https://doi.org/10.1016/B978-0-323-39276-1.00059-7',
        ],
        'Recommended next steps if Diplopia, Uncrossed': [
            'If symptomatic, Occlusion therapy',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Diplopia, Crossed': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': ['Cover test', 'Worth 4-Dot', 'Random dot stereo'],
        'Reference': [
            'McGee, S. (2018) Chapter 59 – Nerves of the Eye Muscles (III, IV, and IV): Approach to Diplopia. In Evidence-Based Physical Diagnosis 4th Ed. Pp. 523-540. Elsevier, NY. https://doi.org/10.1016/B978-0-323-39276-1.00059-7',
        ],
        'Recommended next steps if Diplopia, Crossed': [
            'If symptomatic, Occlusion therapy',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Right, Diplopia': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': [
            'Park 3 Step',
            'Cover test',
            'Worth 4-Dot',
            'Random dot stereo',
        ],
        'Reference': [
            'McGee, S. (2018) Chapter 59 – Nerves of the Eye Muscles (III, IV, and IV): Approach to Diplopia. In Evidence-Based Physical Diagnosis 4th Ed. Pp. 523-540. Elsevier, NY. https://doi.org/10.1016/B978-0-323-39276-1.00059-7',
        ],
        'Recommended next steps if Right, Diplopia': [
            'If symptomatic, Occlusion therapy',
            'If symptomatic, prism/glasses',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Left, Diplopia': {
        'Typically characterized by': ['RightEye 4-Dot', 'CISS survey showing abnormal finding'],
        'Additional clinical tests and findings may include': [
            'Park 3 Step',
            'Cover test',
            'Worth 4-Dot',
            'Random dot stereo',
        ],
        'Reference': [
            'McGee, S. (2018) Chapter 59 – Nerves of the Eye Muscles (III, IV, and IV): Approach to Diplopia. In Evidence-Based Physical Diagnosis 4th Ed. Pp. 523-540. Elsevier, NY. https://doi.org/10.1016/B978-0-323-39276-1.00059-7',
        ],
        'Recommended next steps if Left, Diplopia': [
            'If symptomatic, Occlusion therapy',
            'If symptomatic, prism/glasses',
            'If symptomatic, then referral for in-office vision therapy',
            'If symptomatic, then referral for home-based vision therapy',
        ],
    },
    'Convergence Insufficiency': {
        'Typically characterized by': [
            'An exophoria fixation disparity',
            'A remote near point of convergence, that is a break in the convergence ruler test greater than 4 inches (10cm) and/or',
            'A recovery point of less than 5 inches (12cm)',
            'For patient under 21 years of age a total score of 16 or more on the CISS survey',
            'For patients over 21 years of age a total score of 21 or more on the CISS survey',
            'Symptoms such as double vision, eyestrain, headaches, blurred vision, loss of place while reading or performing work at near, or tired eyes.',
            'Not all patients present will all symptoms or results',
        ],
        'Additional clinical tests and findings may include': [
            'An exophoria at near of greater than 6 prism diopters',
            'Exophoria that is greater at near than distance using the phoropter and/or',
            'Decreased positive fusional convergence at near using a prism bar or phoropter to HTS Binocular Vision Assessment (BVA) and/or',
        ],
        'Reference': [
            'Cooper, J. & Jamal, N. (2012). Convergence insufficiency –A major review. Optometry Review. DOI: 10.1016/j.optm.2011.09.013',
        ],
        'Recommended next steps if Convergence Insufficiency': [
            'In-office vision therapy',
            'At home HTS vision therapy',
            'Prism glasses for near',
        ],
    },
    'Convergence Excess': {
        'Typically characterized by': [
            'Esophoria fixation disparity',
            'Symptoms including headaches, blur, diplopia, asthenopia especially when doing sustained near activities like reading',
            'Not all patients present will all symptoms or results',
        ],
        'Additional clinical tests and findings may include': [
            'Any esophoria at near using the phoropter or cover test',
            'An esophoria at near greater than at distance using the phoropter or cover test',
            'And inadequate negative fusional vergence using the phoropter or HTS Binocular Vision Assessment (BVA)',
            'A high AC/A ratio using the phoropter',
            'A low-positive relative accommodation using the phoropter',
        ],
        'Reference': [
            'Galloway, M. & Scheiman, M. (1997). The efficacy of vision therapy for convergence excess. Journal of the American Optometric Association, 68(2), 81-97',
        ],
        'Recommended next steps if Convergence Excess': [
            'Plus lenses at near',
            'At home HTS vision therapy',
            'In-office vision therapy',
        ],
    },
    'Nystagmus': {
        'Typically characterized by': ['Gap test', 'Fixation stability in primary gaze or secondary gaze'],
        'Additional clinical tests and findings may include': [
            'Clinical observation',
            'If new onset, then full medical testing',
        ],
        'Reference': [
            'Leigh, R.J. & Zee, D.S. (2011). The Neurology of Eye Movements 5th Ed. Oxford University Press. New York, NY',
        ],
        'Recommended next steps if Nystagmus': [
            'If impacting performance, then referral for in-office vision therapy',
            'If impacting performance, then referral for home-based vision therapy',
            'Referral to physical therapy',
        ],
    },
};
