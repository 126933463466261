












import Vue from 'vue';

import GreenButton from '@/views/common/controls/GreenButton.vue';

import EmailApi from '@/api/email.api';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';

export default Vue.extend({
    data() {
        return {
            loading: true as boolean,
            message: '' as string,
            email: this.$route.query.email as string,
            signature: this.$route.query.signature as string,
        };
    },
    components: {
        GreenButton,
        LoadingCircle,
    },
    methods: {
        handleBacktoLogin() {
            this.$router.push({ name: 'login', params: { email: this.email } });
        },
    },
    async mounted() {
        try {
            this.loading = true;
            if (this.email && this.signature) {
                await EmailApi.unsubscribe(decodeURI(this.email.toString()), this.signature.toString());
                this.message = this.$t('email.unsubscribe.unsubscribeSuccessful').toString();
            } else {
                this.message = this.$t('email.unsubscribe.invalidLink').toString();
            }
        } catch (error) {
            // The user doesn't have to know there was an error, they can't do anything about it anyway
            this.message = this.$t('email.unsubscribe.unsubscribeSuccessful').toString();
        } finally {
            this.loading = false;
        }
    },
});
