


import Vue from 'vue';

import {
    PARTICIPANT_ROLE,
    CLIENT_ADMIN_ROLE,
    CLIENT_USER_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';

export default Vue.extend({
    mounted() {
        const user = this.$store.getters.currentUser;
        if (user?.role === PARTICIPANT_ROLE) {
            this.$router.replace({ path: `user/${user.userId}` }); // Use replace to prevent breaking the browser back button
        } else if (user?.role === CLIENT_USER_ROLE) {
            this.$router.replace({ path: `company/${user.companyId}/assessments` });
        } else if (user?.role === CLIENT_ADMIN_ROLE) {
            this.$router.replace({ path: `company/${user.companyId}/assessments` });
        } else if (user?.role === SUPER_ADMIN_ROLE) {
            this.$router.replace({ path: `admin/companymanagement` });
        } else if (user?.role === ENGINEERING_ROLE) {
            this.$router.replace({ path: `admin/companymanagement` });
        } else {
            this.$router.replace({ name: 'login' });
        }
    },
});
