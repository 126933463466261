import * as d3 from 'd3';

import { VisualizationElement, D3Selection, Classes, Attributes, Styles, Properties } from '../elements';
import { calculateAspectRatio } from '@/common/utils/reports/visualData';

export interface EllipseProperties {
    radiusX: number;
    radiusY: number;
    center: [number, number];
    aspectRatio?: number;
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
}

export class Ellipse extends VisualizationElement {
    public constructor(private readonly ellipseProperties: EllipseProperties) {
        super({
            attributes: ellipseProperties.attributes,
            styles: ellipseProperties.styles,
            properties: ellipseProperties.properties,
            classes: ellipseProperties.classes,
        });
    }

    public drawSelf(svg: SVGSVGElement): D3Selection {
        let aspectRatio = this.ellipseProperties.aspectRatio;
        if (!aspectRatio) {
            aspectRatio = calculateAspectRatio(svg);
        }

        const selection = d3
            .select(svg)
            .append('ellipse')
            .attr('cx', this.ellipseProperties.center[0])
            .attr('cy', this.ellipseProperties.center[1] / aspectRatio)
            .attr('rx', this.ellipseProperties.radiusX)
            .attr('ry', this.ellipseProperties.radiusY);

        return selection;
    }
}
