import ApiService from '@/api/common/apiService';
import { ScreenerResult, UpdateScreenerOverride } from '@/common/types/sensorimotor/screener';

class SensorimotorApi {
    public async getScreenerResult(assessmentId: string): Promise<ScreenerResult> {
        const url: string = `/righteye.api/sensorimotorScreener/${assessmentId}`;
        return await ApiService.get<ScreenerResult>(url);
    }

    public async getLatestScreenerResultByParticipant(participantId: string): Promise<ScreenerResult> {
        const url: string = `/righteye.api/sensorimotorScreener/${participantId}/latest`;
        return await ApiService.get<ScreenerResult>(url);
    }

    public async updateScreenerResult(screenerResult: UpdateScreenerOverride): Promise<ScreenerResult> {
        const url: string = `/righteye.api/sensorimotorScreener`;
        return await ApiService.put<ScreenerResult>(url, screenerResult);
    }
}

export default new SensorimotorApi();
