
























import Vue, { PropType } from 'vue';

import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ReportData } from '@/common/types/reports/reportData';
import { ReportNorms } from '@/common/types/reports/norms';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        const metrics = this.reportData.tests.discriminateReactionTime.metrics;

        const fields = [
            'saccadicLatency',
            'visualReactionSpeed',
            'processingSpeed',
            'reactionTime',
            'responseAccuracy',
            'distractibility',
            'impulsivity',
        ];

        return {
            metrics,
            fields,
        };
    },
    computed: {
        norms(): any {
            return this.normsData.discriminateReactionTime;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.discriminateReactionTime?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.discriminateReactionTime?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.discriminateReactionTime?.metrics,
                'distanceFromScreen',
            );
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.reactionTime.definition'));
            infoText.push(this.$t('reports.info.reactionTime.saccadicLatency'));
            infoText.push(this.$t('reports.info.reactionTime.visualReactionSpeed'));
            infoText.push(this.$t('reports.info.reactionTime.processingSpeed'));
            infoText.push(this.$t('reports.info.reactionTime.distractibility'));
            infoText.push(this.$t('reports.info.reactionTime.impulsivity'));

            return infoText;
        },
    },
});
