













































































































import Vue, { PropType } from 'vue';

import {
    CLIENT_ADMIN_ROLE,
    CLIENT_USER_ROLE,
    SUPER_ADMIN_ROLE,
    ENGINEERING_ROLE,
} from '@/common/constants/userRoles.constants';
import { BLUE } from '@/common/constants/visualizations.constants';
import * as SensorimotorConstants from '@/common/constants/sensorimotor.constants';

import { SET_ERROR_BANNER } from '@/store/general/mutations';

import Logger from '@/common/utils/logger';

import AssessmentApi from '@/api/assessment.api';

import { ReportCondition } from '@/common/types/reports/reportCondition';
import { ReportRecommendation } from '@/common/types/reports/reportRecommendation';
import { ReportData } from '@/common/types/reports/reportData';
import { ButtonState } from '@/common/types/general';

import ConditionRecommendationInfoModal from '@/views/common/containers/ConditionRecommendationInfoModal.vue';
import EditableTextField from '@/views/common/controls/EditableTextField.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import IconStatic from '@/views/common/static/IconStatic.vue';
import SaveConfirmation from '@/views/common/components/SaveConfirmation.vue';

export default Vue.extend({
    components: {
        ConditionRecommendationInfoModal,
        EditableTextField,
        GreenButton,
        IconStatic,
        SaveConfirmation,
    },
    props: {
        reportData: Object as PropType<ReportData>,
    },
    data() {
        return {
            conditions: [] as ReportCondition[],
            notes: this.reportData.assessment.notes,
            recommendations: [] as ReportRecommendation[],
            BLUE,
            dirty: false as boolean,
            saving: false as boolean,
            loading: false as boolean,
            conditionChecked: false as boolean,
            recommendationChecked: false as boolean,
            followupChecked: false as boolean,
            conditionInfo: SensorimotorConstants.CONDITION_INFO_MODALS,
            showConfirmation: false as boolean,
        };
    },
    computed: {
        isAdmin(): boolean {
            return [SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE].includes(this.$store.getters.userRole);
        },
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        activeRecommendations(): ReportCondition[] {
            return this.recommendations.filter((condition: ReportCondition) => !condition.name.includes('follow-up'));
        },
        followups(): ReportCondition[] {
            return this.recommendations.filter((condition: ReportCondition) => condition.name.includes('follow-up'));
        },
        saveButtonState(): ButtonState {
            if (this.saving) {
                return 'loading';
            } else if (this.conditionChecked && this.recommendationChecked && this.followupChecked && this.dirty) {
                return 'active';
            } else {
                return 'inactive';
            }
        },
    },
    watch: {
        async reportData(newValue: ReportData, oldValue: ReportData) {
            if (newValue && newValue !== oldValue) {
                this.loadConditionsRecommendations(newValue);
            }
        },
    },
    methods: {
        async loadConditionsRecommendations(reportData: ReportData) {
            this.loading = true;
            const assessmentId = reportData.assessment.id;
            const promises = [this.loadConditions(assessmentId), this.loadRecommendations(assessmentId)];
            await Promise.all(promises);
            this.loading = false;
        },
        async saveConditionsRecommendationsNotes(
            reportData: ReportData,
            conditions: ReportCondition[],
            recommendations: ReportRecommendation[],
            notes: string,
        ) {
            this.saving = true;
            const assessmentId = reportData.assessment.id;
            const promises = [
                this.saveConditions(assessmentId, conditions),
                this.saveRecommendations(assessmentId, recommendations),
                this.saveAssessmentNotes(assessmentId, notes),
            ];
            await Promise.all(promises);
            this.saving = false;
            this.dirty = false;
        },
        async loadConditions(assessmentId: string) {
            try {
                this.conditions = await AssessmentApi.getReportConditions(assessmentId);
            } catch (error) {
                Logger.error(`Failed to load assessment conditions: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async loadRecommendations(assessmentId: string) {
            try {
                this.recommendations = await AssessmentApi.getReportRecommendations(assessmentId);
            } catch (error) {
                Logger.error(`Failed to load assessment recommendations: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async saveConditions(assessmentId: string, conditions: ReportCondition[]) {
            try {
                this.conditions = await AssessmentApi.setReportConditions(assessmentId, conditions);
            } catch (error) {
                Logger.error(`Failed to load assessment conditions: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async saveRecommendations(assessmentId: string, recommendations: ReportRecommendation[]) {
            try {
                this.recommendations = await AssessmentApi.setReportRecommendations(assessmentId, recommendations);
            } catch (error) {
                Logger.error(`Failed to load assessment recommendations: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async saveAssessmentNotes(assessmentId: string, notes: string) {
            try {
                const response = await AssessmentApi.updateAssessmentNotes(assessmentId, notes);
                notes = response.notes;
            } catch (error) {
                Logger.error(`Failed to save assessment notes: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        conditionUpdated(newValue: any) {
            this.conditionChecked = this.conditions.some((condition: ReportCondition) => condition.active);
        },
        recommendationUpdated(newValue: any) {
            this.recommendationChecked = this.activeRecommendations.some(
                (recommendation: ReportCondition) => recommendation.active,
            );
        },
        followupUpdated(newValue: any) {
            this.followupChecked = this.followups.some((followup: ReportCondition) => followup.active);
        },
        hide() {
            this.$emit('hide');
        },
        setDirty(dirty: boolean) {
            this.dirty = dirty;
        },
        showSaveConfirmation() {
            this.showConfirmation = true;
        },
        saveConfirmation() {
            this.saveConditionsRecommendationsNotes(this.reportData, this.conditions, this.recommendations, this.notes);
            this.showConfirmation = false;
        },
        cancelConfirmation() {
            this.showConfirmation = false;
        },
    },
    async mounted() {
        if (this.reportData != null) {
            await this.loadConditionsRecommendations(this.reportData);
        }

        this.conditionChecked = this.conditions.some((condition: ReportCondition) => condition.active);
        this.recommendationChecked = this.activeRecommendations.some(
            (recommendation: ReportCondition) => recommendation.active,
        );
        this.followupChecked = this.followups.some((followup: ReportCondition) => followup.active);
    },
});
