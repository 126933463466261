


















import Vue, { PropType } from 'vue';

import { Assessment } from '@/common/types/reports/assessment';

import AssessmentApi from '@/api/assessment.api';

export default Vue.extend({
    props: {
        assessment: {
            type: Object as PropType<Assessment>,
            required: true,
        },
    },
    computed: {
        actions(): any[] {
            return [
                {
                    click: this.hideAssessment,
                    text: this.$t('reports.reports.actions.hide'),
                    toolTip: this.$t('reports.reports.tooltips.hideAssessment'),
                    icon: 'fas fa-eye',
                    enabled: this.assessment.active,
                },
                {
                    click: this.showAssessment,
                    text: this.$t('reports.reports.actions.show'),
                    toolTip: this.$t('reports.reports.tooltips.showAssessment'),
                    icon: 'fas fa-eye-slash',
                    enabled: !this.assessment.active,
                },
            ];
        },
    },
    methods: {
        hideAssessment() {
            AssessmentApi.setAssessmentActiveState(this.assessment.id, false);
            this.assessment.active = false;
            this.$emit('updatedAssessment', this.assessment);
        },
        showAssessment() {
            AssessmentApi.setAssessmentActiveState(this.assessment.id, true);
            this.assessment.active = true;
            this.$emit('updatedAssessment', this.assessment);
        },
    },
});
