




import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';

import { Position2d } from '@/common/types/reports/position';

import { Visualization } from '@/common/utils/reports/visualization/visualization';
import * as FormatUtils from '@/common/utils/reports/format';
import * as ScoreUtils from '@/common/utils/reports/visualization/score';
import { ComparativeReportScore } from '@/common/types/reports/assessment';

export default Vue.extend({
    props: {
        comparativeReportScore: {
            type: Object as PropType<ComparativeReportScore>,
        },
    },
    methods: {
        calculateScoreColor(score: number): string {
            if (score >= 75) {
                return Constants.GREEN;
            } else if (score >= 50) {
                return Constants.YELLOW;
            } else if (score >= 25) {
                return Constants.ORANGE;
            } else {
                return Constants.RED;
            }
        },
        calculateScorePercentageInRadians(score: number): number {
            return (Math.PI * 2 * score) / 100;
        },
    },
    mounted() {
        const visualization = Visualization.build();

        // Gradiant
        const GRADIANT_LENGTH = 0.66;
        const GRADIANT_RECT_HEIGHT = 0.028;
        const GRADIANT_PADDING = 0.002;

        const FUNCTIONAL_RANGE_LOWERBOUND =
            GRADIANT_LENGTH * (this.comparativeReportScore.functionalWindowLowerBound / 100);
        const FUNCTIONAL_RANGE_UPPERBOUND =
            GRADIANT_LENGTH * (this.comparativeReportScore.functionalWindowUpperBound / 100);

        // Positions
        const GRADIANT_POSITION = new Position2d(0.25, 0.13);
        const OVERALL_POSITION = new Position2d(0.15, 0.65);
        const PURSUITS_POSITION = new Position2d(0.37, 0.59);
        const SACCADES_POSITION = new Position2d(0.61, 0.59);
        const FIXATIONS_POSITION = new Position2d(0.84, 0.59);

        // Text
        const TEXT_FONT_FAMILY = 'ProximaNova';
        const NUMBER_FONT_SIZE_LARGE = '.0022em';
        const NUMBER_FONT_SIZE_SMALL = '.0013em';
        const TEXT_FONT_SIZE_LARGE = '.0025em';
        const TEXT_FONT_SIZE_SMALL = '.0017em';
        const GRADIANT_SCORE_FONT_SIZE = '.0017em';
        const GRADIANT_WINDOW_FONT_SIZE = '.0012em';
        const ABOVE_TEXT_OFFSET = -0.45;
        const BELOW_TEXT_OFFSET = 0.27;
        const FUNCTIONAL_WINDOW_TEXT_OFFSET = GRADIANT_POSITION.y + GRADIANT_RECT_HEIGHT + 0.065;

        const LARGE_CIRCLE_INNER_RADIUS = 0.048;
        const LARGE_CIRCLE_OUTER_RADIUS = 0.068;
        const SMALL_CIRCLE_INNER_RADIUS = 0.029;
        const SMALL_CIRCLE_OUTER_RADIUS = 0.043;

        // Dysfunctional Rects
        const GRADIANT_RECT_WIDTH = GRADIANT_LENGTH / 4;

        // Functional Window
        const FUNCTIONAL_WINDOW_WIDTH = FUNCTIONAL_RANGE_UPPERBOUND - FUNCTIONAL_RANGE_LOWERBOUND;
        const SCORE_MARKER_POSITION = GRADIANT_LENGTH * (this.comparativeReportScore.functionalWindowLowerBound / 100);

        const ASPECT_RATIO = 5 / 1;

        // Overall score
        ScoreUtils.generateScoreCircle(visualization, {
            position: OVERALL_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: LARGE_CIRCLE_OUTER_RADIUS,
            thickness: LARGE_CIRCLE_OUTER_RADIUS - LARGE_CIRCLE_INNER_RADIUS,
            labels: [
                {
                    text: this.$t('reports.reports.score.overall').toString(),
                    positionOffset: new Position2d(0, ABOVE_TEXT_OFFSET),
                    fontSize: TEXT_FONT_SIZE_LARGE,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
                {
                    text: `${this.comparativeReportScore.functionalWindowLowerBound}-${this.comparativeReportScore.functionalWindowUpperBound}`,
                    positionOffset: new Position2d(0, 0),
                    fontSize: NUMBER_FONT_SIZE_LARGE,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
            ],
            color: this.calculateScoreColor(this.comparativeReportScore.functionalWindowLowerBound),
            fillPercent: this.comparativeReportScore.functionalWindowLowerBound,
        });

        // Pursuits Score
        ScoreUtils.generateScoreCircle(visualization, {
            position: PURSUITS_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: [
                {
                    text: this.$t('reports.reports.score.pursuits').toString(),
                    positionOffset: new Position2d(0, BELOW_TEXT_OFFSET),
                    fontSize: TEXT_FONT_SIZE_SMALL,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
                {
                    text: `${this.comparativeReportScore.pursuitsScoreLowerBound}-${this.comparativeReportScore.pursuitsScoreUpperBound}`,
                    positionOffset: new Position2d(0, 0),
                    fontSize: NUMBER_FONT_SIZE_SMALL,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
            ],
            color: this.calculateScoreColor(this.comparativeReportScore.pursuitsScoreLowerBound),
            fillPercent: this.comparativeReportScore.pursuitsScoreLowerBound,
        });

        // Saccades Score
        ScoreUtils.generateScoreCircle(visualization, {
            position: SACCADES_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: [
                {
                    text: this.$t('reports.reports.score.saccades').toString(),
                    positionOffset: new Position2d(0, BELOW_TEXT_OFFSET),
                    fontSize: TEXT_FONT_SIZE_SMALL,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
                {
                    text: `${this.comparativeReportScore.saccadesScoreLowerBound}-${this.comparativeReportScore.saccadesScoreUpperBound}`,
                    positionOffset: new Position2d(0, 0),
                    fontSize: NUMBER_FONT_SIZE_SMALL,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
            ],
            color: this.calculateScoreColor(this.comparativeReportScore.saccadesScoreLowerBound),
            fillPercent: this.comparativeReportScore.saccadesScoreLowerBound,
        });

        // Fixations Score
        ScoreUtils.generateScoreCircle(visualization, {
            position: FIXATIONS_POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: [
                {
                    text: this.$t('reports.reports.score.fixations').toString(),
                    positionOffset: new Position2d(0, BELOW_TEXT_OFFSET),
                    fontSize: TEXT_FONT_SIZE_SMALL,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
                {
                    text: `${this.comparativeReportScore.fixationsScoreLowerBound}-${this.comparativeReportScore.fixationsScoreUpperBound}`,
                    positionOffset: new Position2d(0, 0),
                    fontSize: NUMBER_FONT_SIZE_SMALL,
                    fontFamily: TEXT_FONT_FAMILY,
                    color: Constants.BLACK,
                },
            ],
            color: this.calculateScoreColor(this.comparativeReportScore.fixationsScoreLowerBound),
            fillPercent: this.comparativeReportScore.fixationsScoreLowerBound,
        });

        // Gradiant
        ScoreUtils.generateScoreBar(
            visualization,
            {
                position: GRADIANT_POSITION,
                height: GRADIANT_RECT_HEIGHT,
                length: GRADIANT_LENGTH,
                aspectRatio: ASPECT_RATIO,
                padding: GRADIANT_PADDING,
                functionalRange: [
                    this.comparativeReportScore.functionalWindowLowerBound,
                    this.comparativeReportScore.functionalWindowUpperBound,
                ],
                labels: [
                    {
                        text: 0,
                        positionOffset: new Position2d(0, -0.001 + FUNCTIONAL_WINDOW_TEXT_OFFSET),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(25, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH + GRADIANT_PADDING,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(50, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 2 + GRADIANT_PADDING * 2,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'start',
                        baseline: 'top',
                    },
                    {
                        text: FormatUtils.formatNumber(75, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 3 + GRADIANT_PADDING * 3,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'top',
                    },
                    {
                        text: this.comparativeReportScore.functionalWindowUpperBound.toString(),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 3 + GRADIANT_PADDING * 3 + FUNCTIONAL_WINDOW_WIDTH,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'top',
                        draw: this.comparativeReportScore.functionalWindowUpperBound < 94,
                    },
                    {
                        text: FormatUtils.formatNumber(100, 0),
                        positionOffset: new Position2d(
                            GRADIANT_RECT_WIDTH * 4 + GRADIANT_PADDING * 4,
                            FUNCTIONAL_WINDOW_TEXT_OFFSET,
                        ),
                        fontSize: GRADIANT_WINDOW_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        anchor: 'end',
                        baseline: 'top',
                    },
                    {
                        text: `${this.comparativeReportScore.functionalWindowLowerBound}-${this.comparativeReportScore.functionalWindowUpperBound}`,
                        positionOffset: new Position2d(SCORE_MARKER_POSITION, -0.03),
                        fontSize: GRADIANT_SCORE_FONT_SIZE,
                        fontFamily: TEXT_FONT_FAMILY,
                        color: Constants.BLACK,
                        baseline: 'bottom',
                    },
                ],
            },
            1,
        ); // reference reports will only be for older versions so setting to 1

        // Actually build the visualization according to the space avaliable
        visualization.drawNormalized(this.svg, ASPECT_RATIO);
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
});
