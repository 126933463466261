











































import Vue, { PropType } from 'vue';

import TrainerApi from '@/api/trainer.api';

import { SET_ERROR_BANNER } from '@/store/general/mutations';

import Logger from '@/common/utils/logger';

import { WorkoutPlan } from '@/common/types/trainer/program';
import { EqtParticipant } from '@/common/types/trainer/eqtParticipant';
import { UpdateProgramRequest } from '@/common/types/api/requests/trainerRequests';

import AssignmentDetails from '@/views/trainer/admin/components/AssignmentDetails.vue';
import AssignmentSelection from '@/views/trainer/admin/components/AssignmentSelection.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import LabeledContent from '@/views/common/containers/LabeledContent.vue';
import UserDetails from '@/views/trainer/admin/components/UserDetails.vue';

export default Vue.extend({
    components: {
        AssignmentDetails,
        AssignmentSelection,
        GreenButton,
        LabeledContent,
        UserDetails,
    },
    props: {
        show: Boolean,
        participant: Object as PropType<EqtParticipant>,
    },
    data() {
        return {
            selectedAssignmentIndex: 0 as number,
            asssignmentSelectionDirty: false as boolean,
            currentAssignmentDirty: false as boolean,
            resetAssignmentSelection: false as boolean, // Flip this flag to force component rerendering
            saveButtonState: 'inactive' as string,
            workoutPlanCache: {} as { [key: string]: WorkoutPlan },
            newWorkoutPlan: '' as string,
            newEndDate: '' as string,
        };
    },
    methods: {
        hide() {
            this.$emit('hide');
            this.resetState();
        },
        async loadWorkoutPlans() {
            try {
                if (Object.keys(this.workoutPlanCache).length === 0) {
                    const workoutPlansResponse = await TrainerApi.getWorkoutPlans();
                    workoutPlansResponse.map((wp) => (this.workoutPlanCache[wp.name] = wp));
                }
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
        async save() {
            try {
                this.saveButtonState = 'loading';
                await this.saveAssignmentSelection();
                await this.saveCurrentAssignment();
                this.$emit('save');
                this.hide();
                this.saveButtonState = 'inactive';
            } catch (error) {
                Logger.error(error);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.hide();
            }
        },
        async saveAssignmentSelection() {
            if (this.asssignmentSelectionDirty) {
                if (this.participant.assignments.hasOwnProperty(this.selectedAssignmentIndex)) {
                    const selectedPrescription = this.participant.assignments[
                        this.selectedAssignmentIndex
                    ].Prescriptions.shift();
                    if (selectedPrescription) {
                        await TrainerApi.setSelectedPrescription(selectedPrescription.Id);
                    }
                } else {
                    await TrainerApi.assignGeneralExercises(this.participant.participantId);
                }
                this.asssignmentSelectionDirty = false;
            }
        },
        async saveCurrentAssignment() {
            if (this.currentAssignmentDirty && this.participant.program) {
                const programId = this.participant.program.ProgramId;
                const updateProgramRequest = {} as UpdateProgramRequest;
                if (this.newEndDate) {
                    updateProgramRequest.endDate = this.newEndDate;
                    this.participant.endDate = this.newEndDate;
                }
                if (this.newWorkoutPlan) {
                    updateProgramRequest.workoutPlanId = this.workoutPlanCache[this.newWorkoutPlan]?.id;
                    this.participant.program.WorkoutPlan = this.workoutPlanCache[this.newWorkoutPlan];
                }
                await TrainerApi.updateProgram(programId, updateProgramRequest);
                this.currentAssignmentDirty = false;
            }
        },
        onAssignmentChange(selectedAssignmentIndex: number) {
            this.asssignmentSelectionDirty = true;
            this.saveButtonState = 'active';
            this.selectedAssignmentIndex = selectedAssignmentIndex;
        },
        onWorkoutPlanChange(newWorkoutPlan: string) {
            this.newWorkoutPlan = newWorkoutPlan;
            this.currentAssignmentDirty = true;
            this.saveButtonState = 'active';
        },
        onEndDateChange(newEndDate: string) {
            this.newEndDate = newEndDate;
            this.currentAssignmentDirty = true;
            this.saveButtonState = 'active';
        },
        resetState() {
            this.participant.isDirty = true;
            this.resetAssignmentSelection = !this.resetAssignmentSelection;
        },
    },
    mounted() {
        this.loadWorkoutPlans();
    },
    computed: {
        participantEditable() {
            return this.participant.status !== 'Assessed';
        },
    },
});
