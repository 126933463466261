











































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';

import FixationStabilityVisualizationDynamicVision, {
    FixationStabilityConfig,
} from '@/views/reports/visualizations/FixationStabilityVisualizationDynamicVision.vue';
import { ReportData } from '@/common/types/reports/reportData';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';
import { extractLeftRightBoth } from '@/common/utils/reports/reports';
import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import { formatNumber } from '@/common/utils/reports/format';

export default Vue.extend({
    components: {
        FixationStabilityVisualizationDynamicVision,
        InfoModal,
        MetricTable,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            metrics: {} as any,
            binocularFields: [
                'right.gazePositionsBand1Aggregate',
                'left.gazePositionsBand1Aggregate',
                'right.gazePositionsBand2Aggregate',
                'left.gazePositionsBand2Aggregate',
                'right.gazePositionsBand3Aggregate',
                'left.gazePositionsBand3Aggregate',
                'right.gazePositionsBand4Aggregate',
                'left.gazePositionsBand4Aggregate',
                'right.fixationDispersion',
                'left.fixationDispersion',
            ] as string[],
            config: undefined as FixationStabilityConfig | undefined,
            headers: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',

                    width: '80%',
                },
                {
                    text: 'Right',
                    value: 'right',

                    sortable: false,
                    width: '10%',
                },
                {
                    text: 'Left',
                    value: 'left',

                    sortable: false,
                    width: '10%',
                },
            ],
        };
    },
    computed: {
        tableData(): any {
            const metrics = extractLeftRightBoth(this.metrics);
            return [
                {
                    name: this.$t('reports.metrics.tests.fixationStability.dynamicVision.gazePositionsBand1Aggregate'),
                    right: formatNumber(metrics.both.right?.gazePositionsBand1Aggregate),
                    left: formatNumber(metrics.both.left?.gazePositionsBand1Aggregate),
                },
                {
                    name: this.$t('reports.metrics.tests.fixationStability.dynamicVision.gazePositionsBand2Aggregate'),
                    right: formatNumber(metrics.both.right?.gazePositionsBand2Aggregate),
                    left: formatNumber(metrics.both.left?.gazePositionsBand2Aggregate),
                },
                {
                    name: this.$t('reports.metrics.tests.fixationStability.dynamicVision.fixationDispersion'),
                    right: formatNumber(metrics.both.right?.fixationDispersion),
                    left: formatNumber(metrics.both.left?.fixationDispersion),
                },
            ];
        },
        hasBinocular(): boolean {
            return this.metrics.eyeType.includes('BINOCULAR');
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.fixations'));
            infoText.push(this.$t('reports.info.fixationStability.fixationLocation'));
            infoText.push(this.$t('reports.info.fixationStability.bcea'));
            infoText.push(this.$t('reports.info.fixationStability.fixationDispersion'));

            return infoText;
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (reportData.tests.fixationStability) {
                this.metrics = reportData.tests.fixationStability.metrics;
                const visualData = VisualDataUtils.extractVisualData(reportData, 'fixationStability');
                const systemType = reportData.assessment?.systemType ? reportData.assessment.systemType : 'I15';
                this.config = {
                    testData: visualData,
                    systemType,
                };
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
