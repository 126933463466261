






























import Vue from 'vue';
import TimerVisual from '@/views/trainer/exercises/components/stimuli/TimerVisual.vue';

export default Vue.extend({
    components: { TimerVisual },
    props: {
        x: {
            type: Number,
            required: true,
        },
        y: {
            type: Number,
            required: true,
        },
        radius: {
            type: Number,
            required: true,
        },
        duration: {
            type: Number,
            required: true,
        },
        fillColor: {
            type: String,
            default: 'darkslategray',
        },
        activated: {
            type: Boolean,
            default: false,
        },
    },
});
