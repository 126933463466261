














import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';

import { Visualization } from '@/common/utils/reports/visualization//visualization';
import { MetricData, EyeDataFieldName } from '@/common/types/reports/metricData';
import { NormsData } from '@/common/types/reports/reportData';
import { Position2d } from '@/common/types/reports/position';
import * as FormatUtils from '@/common/utils/reports/format';
import PerspectiveImageVisualization from '@/views/reports/visualizations/PerspectiveImageVisualization.vue';

export interface CardinalGazePositionConfig {
    eye: EyeDataFieldName;
    metrics: MetricData;
    systemType: string;
    norms: NormsData;
}

export default Vue.extend({
    components: {
        PerspectiveImageVisualization,
    },
    props: {
        config: {
            type: Object as PropType<CardinalGazePositionConfig>,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            visualization: new Visualization(),
            vrtOrder: {
                0: 'up',
                1: 'down',
                2: 'left',
                3: 'right',
                4: 'superiorLeft',
                5: 'superiorRight',
                6: 'inferiorLeft',
                7: 'inferiorRight',
            },
        };
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        generateSVG() {
            const vrtNorms = this.config.norms.ageBased?.[0].data[this.config.eye].vrt;
            let vrtLengthMetrics = [
                'up',
                'down',
                'left',
                'right',
                'superiorLeft',
                'superiorRight',
                'inferiorLeft',
                'inferiorRight',
            ];

            if (this.isPatientPerspective) {
                vrtLengthMetrics = [
                    'up',
                    'down',
                    'right',
                    'left',
                    'superiorLeft',
                    'superiorRight',
                    'inferiorLeft',
                    'inferiorRight',
                ];
            }

            const defaultAttributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.005,
                'stroke-opacity': 0.7,
            };
            const vrtLowerBound = 30;

            const MAX_MS = 850;
            const MIN_COLOR = Constants.ORANGE;
            const MAX_COLOR = Constants.RED;
            const FONT_SIZE = '0.0015em';
            const TEXT_FONT_FAMILY = 'ProximaNova';

            const LEGEND_POSITION = new Position2d(0.001, 0.86);
            const LEGEND_WIDTH = 0.25;
            const LEGEND_HEIGHT = 0.13;

            const LEGEND_LINE_LENGTH = 0.05;
            const LEGEND_CONTENT_PADDING = 0.01;

            const MY_EYE_POSITION = new Position2d(LEGEND_CONTENT_PADDING, 0.02);
            const MY_EYE_TEXT_POSITION = new Position2d(
                MY_EYE_POSITION.x + LEGEND_CONTENT_PADDING * 2 + LEGEND_LINE_LENGTH,
                0.03,
            );
            const AGE_GROUP_POSITION = new Position2d(LEGEND_CONTENT_PADDING, 0.05);
            const AGE_GROUP_TEXT_POSITION = new Position2d(
                AGE_GROUP_POSITION.x + LEGEND_CONTENT_PADDING * 2 + LEGEND_LINE_LENGTH,
                0.05,
            );
            const FASTEST_POSITION = new Position2d(LEGEND_CONTENT_PADDING, 0.08);
            const FASTEST_TEXT_POSITION = new Position2d(
                FASTEST_POSITION.x + LEGEND_CONTENT_PADDING * 2 + LEGEND_LINE_LENGTH,
                0.08,
            );
            const SLOWEST_POSITION = new Position2d(LEGEND_CONTENT_PADDING, 0.11);
            const SLOWEST_TEXT_POSITION = new Position2d(
                SLOWEST_POSITION.x + LEGEND_CONTENT_PADDING * 2 + LEGEND_LINE_LENGTH,
                0.11,
            );

            let min = MAX_MS;
            let max = 0;
            const metricsLengths = [] as number[];
            const orderedMetrics = this.isPatientPerspective ? this.sortedVrtMetrics : this.invertedVrtMetrics;
            for (const length of orderedMetrics) {
                // don't include any bad values into min/max calculation
                if (FormatUtils.isValidValue(length as number)) {
                    min = Math.min(length as number, min);
                    max = Math.max(length as number, max);
                }

                metricsLengths.push(length as number);
            }

            const normsLengths = [] as number[];
            if (vrtNorms) {
                for (const [field, length] of Object.entries(vrtNorms)) {
                    if (vrtLengthMetrics.includes(field)) {
                        const normLength = length as any; // Giving length a type to make typescript happy
                        normsLengths.push(normLength.mean as number);
                    }
                }
            }

            const vrtLabels = this.isPatientPerspective ? this.sortedVrtLabel : this.invertedVrtLabel;

            const visualization = this.visualization;
            metricsLengths.forEach((length, index) => {
                const normsLength = normsLengths[index] ? normsLengths[index] : length;
                let color = Constants.RIGHT_COLOR;
                if (length === min) {
                    color = MIN_COLOR;
                } else if (length === max) {
                    color = MAX_COLOR;
                }

                visualization.line({
                    start: [0.5, 0.5],
                    degrees: index * 45,
                    length: (normsLength - vrtLowerBound) / MAX_MS,
                    attributes: {
                        ...defaultAttributes,
                        'stroke': color,
                        'stroke-width': 0.02,
                        'stroke-opacity': 0.2,
                    },
                });

                visualization.line({
                    start: [0.5, 0.5],
                    degrees: index * 45,
                    length: (length - vrtLowerBound) / MAX_MS,
                    attributes: {
                        ...defaultAttributes,
                        stroke: color,
                    },
                });

                visualization.text({
                    content: this.formatVrtMetric(length),
                    position: [0.5, 0.49],
                    length: (normsLength - vrtLowerBound) / MAX_MS + 0.05,
                    degrees: index * 45,
                    attributes: {
                        'font-size': FONT_SIZE,
                        'font-family': TEXT_FONT_FAMILY,
                    },
                    styles: {
                        'fill': color,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                    },
                });

                visualization.text({
                    content: vrtLabels[index],
                    position: [0.5, 0.52],
                    length: (normsLength - vrtLowerBound) / MAX_MS + 0.05,
                    degrees: index * 45,
                    attributes: {
                        'font-size': FONT_SIZE,
                        'font-family': TEXT_FONT_FAMILY,
                    },
                    styles: {
                        'fill': color,
                        'text-anchor': 'middle',
                        'dominant-baseline': 'central',
                    },
                });
            });

            // Legend
            visualization.rect({
                position: new Position2d(LEGEND_POSITION.x, LEGEND_POSITION.y),
                width: LEGEND_WIDTH,
                height: LEGEND_HEIGHT,
                styles: {
                    'stroke': Constants.RIGHT_COLOR,
                    'fill': 'none',
                    'stroke-width': '.0002em',
                },
            });

            // My Eyes
            visualization.line({
                start: [LEGEND_POSITION.x + MY_EYE_POSITION.x, LEGEND_POSITION.y + MY_EYE_POSITION.y],
                end: [
                    LEGEND_POSITION.x + MY_EYE_POSITION.x + LEGEND_LINE_LENGTH,
                    LEGEND_POSITION.y + MY_EYE_POSITION.y,
                ],
                attributes: {
                    ...defaultAttributes,
                    stroke: Constants.RIGHT_COLOR,
                },
            });
            visualization.text({
                content: this.$t('reports.reports.cardinalGazePosition.myEyes').toString(),
                position: [LEGEND_POSITION.x + MY_EYE_TEXT_POSITION.x, LEGEND_POSITION.y + MY_EYE_TEXT_POSITION.y],
                attributes: {
                    'font-size': FONT_SIZE,
                    'font-family': TEXT_FONT_FAMILY,
                },
                styles: {
                    fill: Constants.BLACK,
                },
            });

            // Reference data
            visualization.line({
                start: [LEGEND_POSITION.x + AGE_GROUP_POSITION.x, LEGEND_POSITION.y + AGE_GROUP_POSITION.y],
                end: [
                    LEGEND_POSITION.x + AGE_GROUP_POSITION.x + LEGEND_LINE_LENGTH,
                    LEGEND_POSITION.y + AGE_GROUP_POSITION.y,
                ],
                attributes: {
                    ...defaultAttributes,
                    'stroke': Constants.RIGHT_COLOR,
                    'stroke-width': 0.02,
                    'stroke-opacity': 0.2,
                },
            });
            visualization.text({
                content: this.$t('reports.reports.cardinalGazePosition.referenceData').toString(),
                position: [
                    LEGEND_POSITION.x + AGE_GROUP_TEXT_POSITION.x,
                    LEGEND_POSITION.y + AGE_GROUP_TEXT_POSITION.y,
                ],
                attributes: {
                    'font-size': FONT_SIZE,
                    'font-family': TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'dominant-baseline': 'central',
                },
            });

            // Fastest Reaction
            visualization.line({
                start: [LEGEND_POSITION.x + FASTEST_POSITION.x, LEGEND_POSITION.y + FASTEST_POSITION.y],
                end: [
                    LEGEND_POSITION.x + FASTEST_POSITION.x + LEGEND_LINE_LENGTH,
                    LEGEND_POSITION.y + FASTEST_POSITION.y,
                ],
                attributes: {
                    ...defaultAttributes,
                    stroke: Constants.ORANGE,
                },
            });
            visualization.line({
                start: [LEGEND_POSITION.x + FASTEST_POSITION.x, LEGEND_POSITION.y + FASTEST_POSITION.y],
                end: [
                    LEGEND_POSITION.x + FASTEST_POSITION.x + LEGEND_LINE_LENGTH,
                    LEGEND_POSITION.y + FASTEST_POSITION.y,
                ],
                attributes: {
                    ...defaultAttributes,
                    'stroke': Constants.ORANGE,
                    'stroke-width': 0.02,
                    'stroke-opacity': 0.2,
                },
            });
            visualization.text({
                content: this.$t('reports.reports.cardinalGazePosition.fastest').toString(),
                position: [LEGEND_POSITION.x + FASTEST_TEXT_POSITION.x, LEGEND_POSITION.y + FASTEST_TEXT_POSITION.y],
                attributes: {
                    'font-size': FONT_SIZE,
                    'font-family': TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'dominant-baseline': 'central',
                },
            });

            // Slowest Reaction
            visualization.line({
                start: [LEGEND_POSITION.x + SLOWEST_POSITION.x, LEGEND_POSITION.y + SLOWEST_POSITION.y],
                end: [
                    LEGEND_POSITION.x + SLOWEST_POSITION.x + LEGEND_LINE_LENGTH,
                    LEGEND_POSITION.y + SLOWEST_POSITION.y,
                ],
                attributes: {
                    ...defaultAttributes,
                    stroke: Constants.RED,
                },
            });
            visualization.line({
                start: [LEGEND_POSITION.x + SLOWEST_POSITION.x, LEGEND_POSITION.y + SLOWEST_POSITION.y],
                end: [
                    LEGEND_POSITION.x + SLOWEST_POSITION.x + LEGEND_LINE_LENGTH,
                    LEGEND_POSITION.y + SLOWEST_POSITION.y,
                ],
                attributes: {
                    ...defaultAttributes,
                    'stroke': Constants.ORANGE,
                    'stroke-width': 0.02,
                    'stroke-opacity': 0.2,
                },
            });
            visualization.text({
                content: this.$t('reports.reports.cardinalGazePosition.slowest').toString(),
                position: [LEGEND_POSITION.x + SLOWEST_TEXT_POSITION.x, LEGEND_POSITION.y + SLOWEST_TEXT_POSITION.y],
                attributes: {
                    'font-size': FONT_SIZE,
                    'font-family': TEXT_FONT_FAMILY,
                },
                styles: {
                    'fill': Constants.BLACK,
                    'dominant-baseline': 'central',
                },
            });

            visualization.drawNormalized(this.svg, 4 / 4);
        },
        formatVrtMetric(vrt: number): string {
            if (FormatUtils.isValidValue(vrt)) {
                return vrt + ' ms';
            } else {
                return 'N/A';
            }
        },
        changePerspective(isPatientPerspective: boolean) {
            this.isPatientPerspective = isPatientPerspective;
            this.redraw();
        },
        redraw() {
            this.visualization.clear();
            this.generateSVG();
        },
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        showAdditionalViz(): boolean {
            return this.reportType === 'standard' || this.reportType === 'sensorimotorExam';
        },
        perspectiveImageLabel(): string {
            if (this.isPatientPerspective) {
                return 'Click to show doctor perspective';
            } else {
                return 'Click to show patient perspective';
            }
        },
        perspectiveImageSize(): number {
            return 0.1;
        },
        perspectiveImageLocation(): Position2d {
            return new Position2d(0.86, 0.86);
        },
        labelFontSize(): number {
            return 0.0018;
        },
        perspectiveLabelLocation(): Position2d {
            return new Position2d(0.73, 0.99);
        },
        sortedVrtMetrics(): number[] {
            // Order metrics to draw them from 0 degrees (right) going clockwise
            const vrt = this.config.metrics.vrt;
            return [
                vrt.right,
                vrt.inferiorRight,
                vrt.down,
                vrt.inferiorLeft,
                vrt.left,
                vrt.superiorLeft,
                vrt.up,
                vrt.superiorRight,
            ];
        },
        sortedVrtLabel(): string[] {
            // Order metric labels to draw them from 0 degrees (right) going clockwise
            return [
                'Right',
                'Inferior Right',
                'Down',
                'Inferior Left',
                'Left',
                'Superior Left',
                'Up',
                'Superior Right',
            ];
        },
        invertedVrtMetrics(): number[] {
            // Order metrics to draw them from 0 degrees (right) going clockwise
            const vrt = this.config.metrics.vrt;
            return [
                vrt.left,
                vrt.inferiorLeft,
                vrt.down,
                vrt.inferiorRight,
                vrt.right,
                vrt.superiorRight,
                vrt.up,
                vrt.superiorLeft,
            ];
        },
        invertedVrtLabel(): string[] {
            // Order metric labels to draw them from 0 degrees (right) going clockwise
            return [
                'Left',
                'Inferior Left',
                'Down',
                'Inferior Right',
                'Right',
                'Superior Right',
                'Up',
                'Superior Left',
            ];
        },
    },
});
