import { Module } from 'vuex';
import store, { RootState } from '@/store/index';
import { DataOptions } from 'vuetify';
import * as Mutations from './mutations';

interface DataTablesState {
    [dataTable: string]: CompanyDataTableCache;
}

interface CompanyDataTableCache {
    [id: string]: DataTableCache;
}

interface DataTableCache {
    options?: DataOptions;
    search?: string;
    selected?: any;
}

interface DataTableCachePayload {
    dataTable: string;
    options?: DataOptions;
    search?: string;
    selected?: any;
}

const CACHE_BY_VIEWING_COMPANY_ID = ['ASSESSMENTS', 'EQTPARTICIPANTS'];

const dataTablesModule: Module<DataTablesState, RootState> = {
    state: {},
    mutations: {
        [Mutations.SAVE_DATATABLE_CACHE](state: DataTablesState, payload: DataTableCachePayload) {
            const companyId = getIdByDataTable(payload.dataTable);

            if (!state[payload.dataTable]) {
                state[payload.dataTable] = {};
            }

            if (!state[payload.dataTable][companyId]) {
                state[payload.dataTable][companyId] = {};
            }

            state[payload.dataTable][companyId].options = payload.options;
            state[payload.dataTable][companyId].search = payload.search;
            state[payload.dataTable][companyId].selected = payload.selected;
        },
        [Mutations.CLEAR_DATATABLE_CACHE](state: DataTablesState, payload: DataTableCachePayload) {
            const companyId = getIdByDataTable(payload.dataTable);
            if (state[payload.dataTable]?.[companyId]) {
                state[payload.dataTable][companyId] = {};
            }
        },
        [Mutations.CLEAR_DATATABLE_STATE](state: DataTablesState) {
            state = {};
        },
    },
    getters: {
        dataTableCache: (state: DataTablesState) => (dataTable: string) => {
            const companyId = getIdByDataTable(dataTable);
            return state[dataTable]?.[companyId];
        },
    },
};

function getIdByDataTable(dataTable: string) {
    if (CACHE_BY_VIEWING_COMPANY_ID.includes(dataTable)) {
        return store.getters.viewingCompany.id;
    } else {
        return store.getters.currentUser.companyId;
    }
}

export default dataTablesModule;
