







































































import Vue from 'vue';

import IconButton from '@/views/common/controls/IconButton.vue';

export default Vue.extend({
    components: {
        IconButton,
    },
    props: {
        content: {
            type: String,
            required: false,
            default: '',
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        characterLimit: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            showTextField: false as boolean,
            textFieldContent: this.content as string | null,
        };
    },
    watch: {
        content(newValue: string) {
            this.textFieldContent = this.content;
        },
    },
    methods: {
        setShowTextField(value: boolean) {
            this.showTextField = value;
        },
        hideTextField() {
            this.showTextField = false;
        },
        saveTextField() {
            if (!this.textFieldContent) {
                this.textFieldContent = '';
            }
            this.$emit('save', this.textFieldContent);
        },
        change(event: any) {
            this.$emit('change', event);
        },
    },
});
