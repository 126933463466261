import * as d3 from 'd3';

export type D3Selection = d3.Selection<any, any, any, any>;

export type Attributes = Record<string, string | number | boolean>;
export type Styles = Record<string, string | number | boolean>;
export type Properties = Record<string, any>;
export type Classes = string[];

export interface Modifiers {
    attributes?: Attributes;
    styles?: Styles;
    properties?: Properties;
    classes?: Classes;
}

export abstract class VisualizationElement {
    public constructor(public modifiers: Modifiers) {}

    public draw(svg: SVGSVGElement): D3Selection {
        const selection = this.drawSelf(svg);

        this.setAttributes(selection, this.modifiers.attributes);
        this.setStyles(selection, this.modifiers.styles);
        this.setProperties(selection, this.modifiers.properties);
        this.setClasses(selection, this.modifiers.classes);

        return selection;
    }

    protected abstract drawSelf(svg: SVGSVGElement): D3Selection;

    private setAttributes(svgSelection: D3Selection, attributes?: Attributes) {
        if (attributes) {
            for (const attribute in attributes) {
                if (attributes.hasOwnProperty(attribute)) {
                    svgSelection.attr(attribute, attributes[attribute]);
                }
            }
        }
    }

    private setStyles(svgSelection: D3Selection, styles?: Styles) {
        if (styles) {
            for (const style in styles) {
                if (styles.hasOwnProperty(style)) {
                    svgSelection.style(style, styles[style]);
                }
            }
        }
    }

    private setProperties(svgSelection: D3Selection, properties?: Properties) {
        if (properties) {
            for (const property in properties) {
                if (properties.hasOwnProperty(property)) {
                    svgSelection.style(property, properties[property]);
                }
            }
        }
    }

    private setClasses(svgSelection: D3Selection, classes?: Classes) {
        if (classes) {
            for (const className of classes) {
                svgSelection.classed(className, true);
            }
        }
    }
}
