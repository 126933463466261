import jwt from 'jsonwebtoken';

import { User } from '@/common/types/user/user';
import { Company } from '@/common/types/company/company';
import { AuthenticationToken } from '@/common/types/auth/auth';

import * as AuthenticationService from '@/api/common/authenticationService';

export const AUTHENTICATION_LOCAL_STORAGE_KEY = 'cognitoAuthenticationData';

export class AuthenticatedUser {
    public static async fromAuthenticationResponse(
        response: AuthenticationToken,
        userProfile: User,
    ): Promise<AuthenticatedUser> {
        const authPublicKey = await AuthenticationService.getPublicKey(response.idToken);
        const participants = userProfile.participants ? userProfile.participants : [];
        const participantIds = participants.map((p) => p.id);
        return new AuthenticatedUser(
            response.idToken,
            response.refreshToken,
            authPublicKey,
            userProfile.company.id,
            userProfile.company,
            userProfile.email,
            userProfile.role.name,
            `${userProfile.firstName} ${userProfile.lastName}`,
            participantIds,
            userProfile.id,
        );
    }

    public static fromLocalStorage(): AuthenticatedUser | undefined {
        const localStorageData = window.localStorage.getItem(AUTHENTICATION_LOCAL_STORAGE_KEY);

        if (localStorageData) {
            const user = JSON.parse(localStorageData) as LocalStorageUser | undefined;
            if (user) {
                const authuser = new AuthenticatedUser(
                    user.idToken,
                    user.refreshToken,
                    user.authPublicKey,
                    user.companyId,
                    user.company,
                    user.userName,
                    user.role,
                    user.name,
                    user.participantIds,
                    user.userId,
                );
                return authuser;
            }
        }

        return undefined;
    }

    private constructor(
        public idToken: string,
        public refreshToken: string,
        public readonly authPublicKey: string,
        public readonly companyId: string,
        public readonly company: Company,
        public readonly userName: string,
        public readonly role: string,
        public readonly name: string,
        public readonly participantIds: string[],
        public readonly userId: string,
    ) {}

    public authenticationHeader(): string {
        return `Bearer ${this.idToken}`;
    }

    public isIdTokenValid(): boolean {
        return isTokenValid(this.idToken, this.authPublicKey);
    }
}

interface LocalStorageUser {
    idToken: string;
    refreshToken: string;
    authPublicKey: string;
    companyId: string;
    company: Company;
    userName: string;
    role: string;
    name: string;
    participantIds: string[];
    userId: string;
}

function isTokenValid(token: string, authPublicKey: string): boolean {
    try {
        jwt.verify(token, authPublicKey);
        return true;
    } catch (err) {
        return false;
    }
}
