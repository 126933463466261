



























































import Vue from 'vue';

import ComponentModal from '@/views/common/containers/ComponentModal.vue';
import TextLink from '@/views/common/controls/TextLink.vue';

import ErrorBanner from '@/views/common/static/ErrorBanner.vue';

export default Vue.extend({
    components: {
        ComponentModal,
        TextLink,
        ErrorBanner,
    },
    data() {
        return {
            aboutLink: 'https://righteye.com/overview/' as string,
            showTermsAndConditions: false as boolean,
            showPrivacyPolicy: false as boolean,
            showNeedHelp: false as boolean,
        };
    },
    computed: {
        getCurrentYear(): string {
            return new Date().getFullYear().toString();
        },
    },
    methods: {
        handleLogoClick() {
            if (this.$route.name !== 'login') {
                this.$router.push({ name: 'login' });
            }
        },
        setShowTermsAndConditions(value: boolean) {
            this.showTermsAndConditions = value;
        },
        setShowPrivacyPolicy(value: boolean) {
            this.showPrivacyPolicy = value;
        },
        setShowNeedHelp(value: boolean) {
            this.showNeedHelp = value;
        },
    },
});
