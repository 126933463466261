









































import Vue, { PropType } from 'vue';
import Logger from '@/common/utils/logger';

import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { SET_VIEWING_COMPANY } from '@/store/company/mutations';

import { ButtonState } from '@/common/types/general';
import CompanyApi from '@/api/company.api';
import { Company, Module } from '@/common/types/company/company';

import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    components: {
        GreenButton,
    },
    props: {
        company: Object as PropType<Company>,
    },
    data() {
        return {
            saveButtonState: 'inactive' as ButtonState,
            selectedModuleIds: [] as string[],
            allPossibleCompanyModules: [] as Module[],
        };
    },
    methods: {
        dirty() {
            this.saveButtonState = 'active';
        },
        async save(company: Company) {
            if (this.saveButtonState === 'active') {
                this.saveButtonState = 'loading';
                try {
                    const ids = await CompanyApi.updateModulesForCompany(company.id, this.selectedModuleIds);
                    this.company.modules = this.allPossibleCompanyModules.filter((cm: Module) => ids.includes(cm.id));
                    this.selectedModuleIds = ids;
                    this.$store.commit(SET_VIEWING_COMPANY, this.company);
                } catch (error) {
                    Logger.error(error.message);
                    this.$store.commit(SET_ERROR_BANNER, error.message);
                }
                this.saveButtonState = 'completed';
            }
        },
        async loadCompanyModules() {
            try {
                this.allPossibleCompanyModules = await CompanyApi.getModules();
                this.allPossibleCompanyModules.sort((rhs, lhs) => (rhs.name < lhs.name ? -1 : 1));
                this.company.modules = await CompanyApi.getCompanyModules(this.company.id);
                this.selectedModuleIds = this.company.modules.map((m: Module) => m.id);
            } catch (error) {
                this.selectedModuleIds = [];
                Logger.error(error.message);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            }
        },
    },
    watch: {
        company() {
            this.loadCompanyModules();
        },
    },
    async mounted() {
        await this.loadCompanyModules();
    },
});
