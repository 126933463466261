
















import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';
import * as Constants from '@/common/constants/visualizations.constants';
import { TestData } from '@/common/types/reports/reportData';
import { Position2d } from '@/common/types/reports/position';
import { CalibrationData } from '@/common/types/reports/visualData';
import { Visualization } from '@/common/utils/reports/visualization//visualization';
import * as d3 from 'd3';
import PerspectiveImageVisualization from '@/views/reports/visualizations/PerspectiveImageVisualization.vue';

export interface CalibrationConfig {
    testData: TestData;
    systemType: string;
}

const CALIBRATION_POINT_NUMBER_TO_NAME = {
    1: 'Midline/Primary',
    2: 'Superior Left',
    3: 'Superior Right',
    4: 'Inferior Left',
    5: 'Inferior Right',
    6: 'Midline Left',
    7: 'Superior Midline',
    8: 'Midline Right',
    9: 'Inferior Midline',
} as Record<number, string>;

export default Vue.extend({
    components: {
        PerspectiveImageVisualization,
    },
    props: {
        config: {
            type: Object as PropType<CalibrationConfig>,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
        perspectiveImageSize(): number {
            return 0.1;
        },
        perspectiveImageLocation(): Position2d {
            return new Position2d(1.09, 0.77);
        },
        labelFontSize(): number {
            return 0.0018;
        },
        perspectiveLabelLocation(): Position2d {
            return new Position2d(0.96, 0.9);
        },
        showAdditionalViz(): boolean {
            return this.reportType === 'standard' || this.reportType === 'sensorimotorExam';
        },
    },
    data() {
        return {
            isPatientPerspective: false,
            visualization: new Visualization(),
        };
    },
    mounted() {
        this.generateSVG();
    },
    methods: {
        changePerspective(isPatientPerspective: boolean) {
            this.isPatientPerspective = isPatientPerspective;
            this.redraw();
        },
        redraw() {
            this.visualization.clear();
            d3.select('body').selectAll('#circle-tooltip').remove();
            this.generateSVG();
        },
        invertPerspective(point: number): number {
            return 1.0 - point;
        },
        generateSVG() {
            const screenData = Constants.SCREEN_DATA[this.config.systemType];
            const ASPECT_RATIO = screenData.width / screenData.height;
            const ZOOM_LEVEL = 0.7;

            if (!this.config.testData) {
                return;
            }

            const calibrationData = this.config.testData.eyeTrackingData as CalibrationData;
            const maybePoints = [
                calibrationData.midlinePrimary,
                calibrationData.superiorLeft,
                calibrationData.superiorRight,
                calibrationData.inferiorLeft,
                calibrationData.inferiorRight,
                calibrationData.midlineLeft,
                calibrationData.superiorMidline,
                calibrationData.midlineRight,
                calibrationData.inferiorMidline,
            ];
            const points = maybePoints.filter((point) => point !== undefined).map((point) => point!);

            const stimulusRadius = 0.06;
            const stimulusOffsetFactor = this.config.systemType === 'I15' ? 1.5 : 2.0;
            const stimulusTextOffset = stimulusRadius * stimulusOffsetFactor;
            const gazeDotRadius = 0.0085;
            const fontSize = 0.0015;

            const legendOffset = this.config.systemType === 'I15' ? 1 : 1.1;

            const defaultAttributes = {
                'fill': 'none',
                'stroke': 'black',
                'stroke-width': 0.001 / ZOOM_LEVEL,
            };

            this.visualization
                .map(points, (point) => {
                    const normalizedStimulus = VisualDataUtils.normalizePosition(
                        new Position2d(point.stimulusPosition.x, point.stimulusPosition.y),
                        screenData,
                    );
                    const normalizedLeft = VisualDataUtils.normalizePosition(
                        new Position2d(point.leftGazePosition.x, point.leftGazePosition.y),
                        screenData,
                    );
                    const normalizedRight = VisualDataUtils.normalizePosition(
                        new Position2d(point.rightGazePosition.x, point.rightGazePosition.y),
                        screenData,
                    );

                    return Visualization.build()
                        .circle({
                            center: normalizedStimulus.coordinates,
                            radius: stimulusRadius,
                            aspectRatio: ASPECT_RATIO,
                            attributes: defaultAttributes,
                        })
                        .text({
                            content: CALIBRATION_POINT_NUMBER_TO_NAME[point.pointNumber],
                            position: [
                                this.isPatientPerspective
                                    ? normalizedStimulus.x
                                    : this.invertPerspective(normalizedStimulus.x),
                                normalizedStimulus.y - stimulusTextOffset,
                            ],
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                'font-size': `${fontSize / ZOOM_LEVEL}em`,
                                'font-family': 'sans-serif',
                            },
                            styles: {
                                'fill': 'black',
                                'text-anchor': 'middle',
                                'letter-spacing': '0',
                            },
                        })
                        .line({
                            start: [normalizedStimulus.x, normalizedStimulus.y - stimulusRadius * ASPECT_RATIO],
                            end: [normalizedStimulus.x, normalizedStimulus.y + stimulusRadius * ASPECT_RATIO],
                            aspectRatio: ASPECT_RATIO,
                            attributes: defaultAttributes,
                        })
                        .line({
                            start: [normalizedStimulus.x - stimulusRadius, normalizedStimulus.y],
                            end: [normalizedStimulus.x + stimulusRadius, normalizedStimulus.y],
                            aspectRatio: ASPECT_RATIO,
                            attributes: defaultAttributes,
                        })
                        .circle({
                            center: [
                                this.isPatientPerspective ? normalizedLeft.x : this.invertPerspective(normalizedLeft.x),
                                normalizedLeft.y,
                            ],
                            radius: gazeDotRadius,
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                ...defaultAttributes,
                                fill: Constants.LEFT_COLOR,
                                stroke: Constants.LEFT_COLOR,
                            },
                        })
                        .circle({
                            center: [
                                this.isPatientPerspective
                                    ? normalizedRight.x
                                    : this.invertPerspective(normalizedRight.x),
                                normalizedRight.y,
                            ],
                            radius: gazeDotRadius,
                            aspectRatio: ASPECT_RATIO,
                            attributes: {
                                ...defaultAttributes,
                                fill: Constants.RIGHT_COLOR,
                                stroke: Constants.RIGHT_COLOR,
                            },
                        });
                })
                .rect({
                    position: new Position2d(0.31, 1.053 * legendOffset),
                    width: 0.4,
                    height: 0.05,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'fill': 'none',
                        'stroke': '#000000',
                        'stroke-width': 0.0012 / ZOOM_LEVEL,
                    },
                })
                .circle({
                    center: [0.35, 1.085 * legendOffset],
                    radius: gazeDotRadius,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        fill: Constants.LEFT_COLOR,
                        stroke: Constants.LEFT_COLOR,
                    },
                })
                .text({
                    content: 'Left Eye',
                    position: [0.43, 1.07 * legendOffset],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize / ZOOM_LEVEL}em`,
                        'font-family': 'sans-serif',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'dominant-baseline': 'hanging',
                        'letter-spacing': '0',
                    },
                })
                .circle({
                    center: [0.53, 1.085 * legendOffset],
                    radius: gazeDotRadius,
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        ...defaultAttributes,
                        fill: Constants.RIGHT_COLOR,
                        stroke: Constants.RIGHT_COLOR,
                    },
                })
                .text({
                    content: 'Right Eye',
                    position: [0.62, 1.07 * legendOffset],
                    aspectRatio: ASPECT_RATIO,
                    attributes: {
                        'font-size': `${fontSize / ZOOM_LEVEL}em`,
                        'font-family': 'sans-serif',
                    },
                    styles: {
                        'fill': 'black',
                        'text-anchor': 'middle',
                        'dominant-baseline': 'hanging',
                        'letter-spacing': '0',
                    },
                })

                .zoom(ZOOM_LEVEL)
                .drawNormalized(this.svg, 4 / 3);
        },
    },
});
