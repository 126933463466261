export const MAZE_MASTER_NORMS_QUERY = `
mazeMaster {
    eyeType
    level
    totalGameTime {
      mean
      stdDev
    }
    gaze
}
`;

export const MAZE_MASTER_METRIC_QUERY = `
mazeMaster {
    info {
        attemptNumber
    }
    metrics {
        eyeType
        level
        totalGameTime
        gaze
        distanceFromScreenMin
        distanceFromScreenMax
        left {
            distanceFromScreen
        }
        right {
          distanceFromScreen
        }
        both {
          distanceFromScreen
        }
    }
}
`;
