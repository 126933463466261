
































import Vue, { PropType } from 'vue';

import Logger from '@/common/utils/logger';
import { SET_ERROR_BANNER } from '@/store/general/mutations';

import LoadingCircle from '@/views/common/static/LoadingCircle.vue';

import CompanyApi from '@/api/company.api';
import { Company } from '@/common/types/company/company';

export default Vue.extend({
    props: {
        company: {
            type: Object as PropType<Company>,
            required: false,
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        LoadingCircle,
    },
    data() {
        return {
            loading: false as boolean,
            imgLoadError: false as boolean,
            uploadedImage: {} as File,
        };
    },
    computed: {
        logoUrl(): string | undefined {
            return this.company.logoURL;
        },
        name(): string {
            return this.company.name;
        },
    },
    methods: {
        async loadCompanyLogo() {
            if (!this.company.logoURL) {
                try {
                    const company = await CompanyApi.getCompanyById(this.company.id);
                    this.company.logoURL = company.logoURL;
                    this.imgLoadError = false;
                } catch (error) {
                    Logger.error(`Failed to load company: ${error}`);
                    this.$store.commit(SET_ERROR_BANNER, error.message);
                }
            }
        },
        onImageLoadFailure() {
            this.imgLoadError = true;
        },
        async uploadFile() {
            try {
                if (this.uploadedImage) {
                    this.loading = true;
                    this.company.logoURL = await CompanyApi.uploadLogoForCompany(this.company.id, this.uploadedImage);
                }
            } catch (error) {
                Logger.error(`Failed to upload company logo: ${error}`);
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.loading = false;
            }
        },
    },
    async mounted() {
        await this.loadCompanyLogo();
    },
});
