


















































































import Vue, { PropType } from 'vue';

import NinePointMotorFunctionVisualization, {
    NinePointMotorFunctionConfig,
} from '@/views/reports/visualizations/NinePointMotorFunctionVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';

import MultiMetricTable from '@/views/reports/metrics/MultiMetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';
import { formatNumber } from '@/common/utils/reports/format';
import ReportCardDropdown from '@/views/company/components/ReportCardDropdown.vue';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        NinePointMotorFunctionVisualization,
        MultiMetricTable,
        InfoModal,
        ReportCardDropdown,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        showInterpretationText: {
            default: false,
            required: false,
            type: Boolean,
        },
        forPrint: {
            default: false,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            metrics: {} as any,
            config: undefined as NinePointMotorFunctionConfig | undefined,
            pupillaryDistanceHeaders: [
                {
                    text: this.$t('reports.metrics.tests.calibration.pupilaryMetrics'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
            disparityHeaders: [
                {
                    text: this.$t('reports.metrics.tests.calibration.disparityMetrics'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
            horizontalDisplacementHeaders: [
                {
                    text: this.$t('reports.metrics.tests.calibration.horizontalDisplacementMetrics'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.right'),
                    align: 'end',
                    value: 'right',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.left'),
                    align: 'end',
                    value: 'left',
                },
            ],
            verticalDisplacementHeaders: [
                {
                    text: this.$t('reports.metrics.tests.calibration.verticalDisplacementMetrics'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.right'),
                    align: 'end',
                    value: 'right',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.left'),
                    align: 'end',
                    value: 'left',
                },
            ],
            pupillaryDiameterHeaders: [
                {
                    text: this.$t('reports.metrics.tests.calibration.pupilDiameterMetrics'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.right'),
                    align: 'end',
                    value: 'right',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.left'),
                    align: 'end',
                    value: 'left',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
            displacementHeaders: [
                {
                    text: 'Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: 'Horizontal Displacement (D)',
                    align: 'end',
                    value: 'right-horizontal',
                },
                {
                    text: 'Horizontal Displacement (D)',
                    align: 'end',
                    value: 'left-horizontal',
                },
                {
                    text: 'Vertical Displacement (D)',
                    align: 'end',
                    value: 'right-vertical',
                },
                {
                    text: 'Vertical Displacement (D)',
                    align: 'end',
                    value: 'left-vertical',
                },
            ],
            directions: [
                'midlinePrimary',
                'superiorLeft',
                'superiorRight',
                'inferiorLeft',
                'inferiorRight',
                'midlineLeft',
                'superiorMidline',
                'midlineRight',
                'inferiorMidline',
            ] as string[],
            fields: [
                'pupilaryDistance',
                'disparity',
                'horizontalDisplacementLeft',
                'horizontalDisplacementRight',
                'verticalDisplacementRight',
                'verticalDisplacementLeft',
                'meanPupilDiameter',
            ] as string[],
            showCalibrationLabels: false,
        };
    },
    computed: {
        tableData(): any {
            return [
                {
                    name: this.$t('reports.metrics.tests.calibration.pupilaryDistance'),
                    both: formatNumber(this.metrics?.pupilaryDistance?.pupilaryDistance),
                },
            ];
        },
        disparityTableData(): any {
            const displacementTable = [];

            for (const direction of this.directions) {
                displacementTable.push({
                    name: this.$t(`reports.metrics.tests.calibration.${direction}`),
                    both: formatNumber(this.metrics?.disparity?.[direction]),
                });
            }

            return displacementTable;
        },
        horizontalDisplacementTableData(): any {
            const displacementTable = [];

            for (const direction of this.directions) {
                displacementTable.push({
                    name: this.$t(`reports.metrics.tests.calibration.${direction}`),
                    right: formatNumber(this.metrics?.right?.horizontalDisplacement?.[direction]),
                    left: formatNumber(this.metrics?.left?.horizontalDisplacement?.[direction]),
                });
            }

            return displacementTable;
        },
        verticalDisplacementTableData(): any {
            const displacementTable = [];

            for (const direction of this.directions) {
                displacementTable.push({
                    name: this.$t(`reports.metrics.tests.calibration.${direction}`),
                    right: formatNumber(this.metrics?.right?.verticalDisplacement?.[direction]),
                    left: formatNumber(this.metrics?.left?.verticalDisplacement?.[direction]),
                });
            }

            return displacementTable;
        },
        pupilDiameterTableData(): any {
            return [
                {
                    name: this.$t('reports.metrics.tests.calibration.meanPupilDiameter'),
                    left: formatNumber(this.metrics?.left?.meanPupilDiameter),
                    right: formatNumber(this.metrics?.right?.meanPupilDiameter),
                    both: formatNumber(this.metrics?.both?.meanPupilDiameter),
                },
            ];
        },
        displacementTableData(): any {
            const displacementTable = [];

            for (const direction of this.directions) {
                displacementTable.push({
                    'name': this.$t(`reports.metrics.tests.calibration.${direction}`),
                    'right-horizontal': formatNumber(this.metrics?.right?.horizontalDisplacement?.[direction]),
                    'left-horizontal': formatNumber(this.metrics?.left?.horizontalDisplacement?.[direction]),
                    'right-vertical': formatNumber(this.metrics?.right?.verticalDisplacement?.[direction]),
                    'left-vertical': formatNumber(this.metrics?.left?.verticalDisplacement?.[direction]),
                });
            }

            return displacementTable;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sensorimotorGuidance'));
            infoText.push(this.$t('reports.info.calibration.midlinePrimary'));
            infoText.push(this.$t('reports.info.sensorimotor'));
            infoText.push(this.$t('reports.info.calibration.pupilaryDistance'));
            infoText.push(this.$t('reports.info.calibration.disparity'));
            infoText.push(this.$t('reports.info.calibration.pupilaryDiameter'));

            return infoText;
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (!reportData.tests.calibration) {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }

            this.metrics = this.reportData.tests.calibration.metrics;

            this.config = {
                testData: this.reportData.tests.calibration,
                systemType: this.reportData.assessment.systemType,
            };

            // Reorganize metrics and norms for multi table to display properly
            this.metrics.horizontalDisplacementLeft = this.metrics.left.horizontalDisplacement;
            this.metrics.horizontalDisplacementRight = this.metrics.right.horizontalDisplacement;
            this.metrics.verticalDisplacementLeft = this.metrics.left.verticalDisplacement;
            this.metrics.verticalDisplacementRight = this.metrics.right.verticalDisplacement;
            this.metrics.pupilaryDistance = { pupilaryDistance: this.metrics.pupilaryDistance };
            this.metrics.meanPupilDiameter = {
                right: this.metrics.right.meanPupilDiameter,
                left: this.metrics.left.meanPupilDiameter,
                both: this.metrics.both.meanPupilDiameter,
            };
        },
        setShowCalibrationLabels(showCalibrationLabels: boolean) {
            this.showCalibrationLabels = showCalibrationLabels;
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
