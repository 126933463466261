import { SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';

import AppVersionManagement from '@/views/superAdmin/AppVersionManagement.vue';
import CompanyManagement from '@/views/superAdmin/CompanyManagement.vue';
import UserManagement from '@/views/superAdmin/UserManagement.vue';
import SuperAdminPage from '@/views/superAdmin/SuperAdminPage.vue';

const routes = [
    {
        path: '/admin',
        name: 'admin',
        component: SuperAdminPage,
        meta: {
            allowedRoles: [SUPER_ADMIN_ROLE, ENGINEERING_ROLE],
        },
        children: [
            {
                path: 'companymanagement',
                name: 'CompanyManagement',
                component: CompanyManagement,
                meta: {
                    allowedRoles: [SUPER_ADMIN_ROLE, ENGINEERING_ROLE],
                },
            },
            {
                path: 'usermanagement',
                name: 'UserManagement',
                component: UserManagement,
                meta: {
                    allowedRoles: [SUPER_ADMIN_ROLE, ENGINEERING_ROLE],
                },
            },
            {
                path: 'appversionmanagement',
                name: 'AppVersionManagement',
                component: AppVersionManagement,
                meta: {
                    allowedRoles: [ENGINEERING_ROLE],
                },
            },
        ],
    },
];

export default routes;
