






















































import Vue, { PropType } from 'vue';

import { ButtonState } from '@/common/types/general';
import { ReportData } from '@/common/types/reports/reportData';

import GreenButton from '@/views/common/controls/GreenButton.vue';
import LoadingCircle from '@/views/common/static/LoadingCircle.vue';

import { BLUE } from '@/common/constants/visualizations.constants';

import SurveyApi from '@/api/survey.api';

export default Vue.extend({
    props: {
        reportData: Object as PropType<ReportData>,
        readOnly: {
            type: Boolean,
            default: true,
            required: false,
        },
        forPrint: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    components: {
        LoadingCircle,
        GreenButton,
    },
    data() {
        return {
            loading: false as boolean,
            dirty: false as boolean,
            saving: false as boolean,
            title: '' as string,
            subtitle: '' as string,
            questions: {} as Map<string, string>,
            answers: {} as Map<string, string>,
            responses: {} as object,
            surveyScore: 0 as number,
            BLUE,
        };
    },
    computed: {
        saveButtonState(): ButtonState {
            if (this.saving) {
                return 'loading';
            } else if (this.dirty) {
                return 'active';
            } else {
                return 'inactive';
            }
        },
    },
    methods: {
        async loadSurvey(participantId: string) {
            this.loading = true;
            const survey = await SurveyApi.getSurvey(participantId);
            this.title = survey.title;
            this.subtitle = survey.subtitle;
            this.questions = survey.questions;
            this.answers = survey.answers;
            if (survey.responses) {
                this.responses = survey.responses;
            }
            this.loading = false;
        },
        async submitSurvey(reportData: ReportData, responses: Map<string, string>) {
            this.saving = true;
            const participantId = reportData.participant.id;
            const surveyResponse = { responses };
            await SurveyApi.updateSurveyResponse(participantId, surveyResponse);
            this.dirty = false;
            this.saving = false;
        },
        setDirty(dirty: boolean) {
            this.dirty = dirty;
        },
    },
    async mounted() {
        const participantId = this.reportData.participant.id;
        await this.loadSurvey(participantId);

        let score = 0;
        for (const response of Object.values(this.responses)) {
            switch (response) {
                case 'A0':
                    score += 0;
                    break;
                case 'A1':
                    score += 1;
                    break;
                case 'A2':
                    score += 2;
                    break;
                case 'A3':
                    score += 3;
                    break;
                case 'A4':
                    score += 4;
                    break;
                default:
                    score += 0;
            }
        }
        this.surveyScore = score;
    },
});
