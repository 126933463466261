import { TestType } from '@/common/constants/reports.constants';
import { GraphQlQuery } from '../../common/types/api/requests/graphqlRequests';
import { TestQuery } from './testQuery';

export class ReportQuery implements GraphQlQuery {
    private readonly assessmentId: string;
    private readonly testTypes: Set<TestType>;

    public constructor(assessmentId: string, testTypes: TestType[] = []) {
        this.assessmentId = assessmentId;
        this.testTypes = new Set();
        testTypes.forEach((testType: TestType) => this.testTypes.add(testType));
    }

    public requestBody(): string {
        const testQueries = [...this.testTypes].map((testType) => TestQuery.fromTestType(testType));
        const queryBodiesSingleTest = testQueries
            .filter((testQuery) => !testQuery.returnAllTests)
            .map((testQuery) => testQuery.body)
            .join();
        const queryBodiesMultipleTests = testQueries
            .filter((testQuery) => testQuery.returnAllTests)
            .map((testQuery) => testQuery.body)
            .join();

        const query = `query ReportData($assessmentId: String!) {
            report(assessmentId: $assessmentId) {
                assessment {
                    id
                    dateCreated
                    notes
                    systemType
                    eyeQTrainerActive
                }
                assessmentType {
                    name
                    description
                }
                participant {
                    id
                    patientId
                    firstName
                    middleName
                    lastName
                    gender
                    dateOfBirth
                    handedness
                    ethnicity
                    companyId
                    userId
                }
                tests {
                    list
                    ${queryBodiesSingleTest}
                }
                allTests {
                    list
                    ${queryBodiesMultipleTests}
                }
            }
        }`;

        const variables = {
            assessmentId: this.assessmentId,
        };

        const body: any = {
            query,
            variables,
        };

        return JSON.stringify(body);
    }
}
