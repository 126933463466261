import { render, staticRenderFns } from "@/views/static/FaqPage.html?vue&type=template&id=3fbae448&scoped=true&"
import script from "./Help.vue?vue&type=script&lang=ts&"
export * from "./Help.vue?vue&type=script&lang=ts&"
import style0 from "./Help.vue?vue&type=style&index=0&id=3fbae448&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fbae448",
  null
  
)

export default component.exports