










































import Vue from 'vue';

export default Vue.extend({
    props: {
        show: Boolean,
    },
    data() {
        return {
            value: true,
        };
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
    },
});
