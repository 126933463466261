import { Module } from 'vuex';
import { RootState } from '@/store/index';

import * as Mutations from '@/store/navigation/mutations';
import { LeftNavLink } from '@/common/types/general';

interface NavState {
    showLeftNav?: boolean;
    leftNavLinks: LeftNavLink[];
    leftNavTitleText: string;
}

const navModule: Module<NavState, RootState> = {
    state: {
        showLeftNav: false,
        leftNavLinks: [],
        leftNavTitleText: 'RightEye',
    },
    mutations: {
        [Mutations.SHOW_LEFT_NAV](state: NavState) {
            state.showLeftNav = true;
        },
        [Mutations.HIDE_LEFT_NAV](state: NavState) {
            state.showLeftNav = false;
        },
        [Mutations.SET_LEFT_NAV_TITLE_TEXT](state: NavState, titleText: string) {
            state.leftNavTitleText = titleText;
        },
        [Mutations.SET_LEFT_NAV_LINKS](state: NavState, links: any[]) {
            state.leftNavLinks = links;
        },
    },
    getters: {
        showLeftNav: (state: NavState) => {
            return state.showLeftNav;
        },
        leftNavTitleText: (state: NavState) => {
            return state.leftNavTitleText;
        },
        leftNavLinks: (state: NavState) => {
            return state.leftNavLinks;
        },
    },
};

export default navModule;
