

























import Vue, { PropType } from 'vue';

import CalibrationVisualization, {
    CalibrationConfig,
} from '@/views/reports/visualizations/CalibrationVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';

export default Vue.extend({
    components: {
        CalibrationVisualization,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
    },
    data() {
        return {
            metrics: {} as any,
            config: undefined as CalibrationConfig | undefined,
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.sensorimotor'));
            infoText.push(this.$t('reports.info.calibration.pupilaryDistance'));
            infoText.push(this.$t('reports.info.calibration.disparity'));
            infoText.push(this.$t('reports.info.calibration.pupilaryDiameter'));

            return infoText;
        },
    },
    methods: {
        loadReportData(reportData: ReportData) {
            if (reportData.tests.calibration) {
                this.metrics = reportData.tests.calibration.metrics;
                this.config = {
                    testData: this.reportData.tests.calibration,
                    systemType: this.reportData.assessment?.systemType ? this.reportData.assessment.systemType : 'I15',
                };
            } else {
                this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    mounted() {
        this.loadReportData(this.reportData);
    },
});
