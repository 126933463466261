import { MetricData } from '@/common/types/reports/metricData';

// Takes a dot seperated path to a nested field and returns the value
export function getNestedMetricValue(metrics: any, path: string): any {
    const pathComponents = path.split('.');
    let result = metrics;
    for (const pathComponent of pathComponents) {
        result = result[pathComponent];
        if (result === undefined) {
            break;
        }
    }
    return result;
}

export function searchForField(metrics: MetricData, field: string): number | undefined {
    if (metrics?.[field] !== undefined) {
        return metrics[field];
    } else if (metrics?.both?.[field]) {
        return metrics.both[field];
    } else if (metrics?.right?.[field]) {
        return metrics.right[field];
    } else if (metrics?.left?.[field]) {
        return metrics.left[field];
    } else {
        return undefined;
    }
}
