import { TestType } from '@/common/constants/reports.constants';
import { GraphQlQuery } from '../../common/types/api/requests/graphqlRequests';
import { TestQuery } from './testQuery';

export class ComparativeAssessmentQuery implements GraphQlQuery {
    private readonly comparativeAssessmentId: string;
    private readonly testTypes: Set<TestType>;

    public constructor(comparativeAssessmentId: string, testTypes: TestType[] = []) {
        this.comparativeAssessmentId = comparativeAssessmentId;
        this.testTypes = new Set();
        testTypes.forEach((testType: TestType) => this.testTypes.add(testType));
    }

    public requestBody(): string {
        const testQueries = [...this.testTypes].map((testType) => TestQuery.fromTestType(testType));
        const queryBodiesSingleTest = testQueries
            .filter((testQuery) => !testQuery.returnAllTests)
            .map((testQuery) => testQuery.body)
            .join();

        const query = `query ComparativeAssessment($comparativeAssessmentId: String!){
          comparativeAssessments(comparativeAssessmentQuery: {comparativeAssessmentIds : $comparativeAssessmentId}) {
            info {
              id
              normsCategory
              label
              dateCreated
              dateModified
            }
            tests {
              ${queryBodiesSingleTest}
            }
          }
        }`;

        const variables = {
            comparativeAssessmentId: this.comparativeAssessmentId,
        };

        const body: any = {
            query,
            variables,
        };

        return JSON.stringify(body);
    }
}
