


































































import UserApi from '@/api/user.api';
import Vue from 'vue';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    components: {
        GreenButton,
    },
    data() {
        return {
            userName: this.$store.getters.currentUser?.name,
            phone: '',
            message: '',
            rules: {
                required: (value: any) => !!value || 'Required.',
            },
            showConfirmation: false,
        };
    },
    computed: {
        validSupportMessage(): boolean {
            return this.message !== '';
        },
    },
    methods: {
        async sendSupportEmail() {
            try {
                if (this.validSupportMessage) {
                    await UserApi.sendSupportEmail(this.phone, this.message);
                }
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
            } finally {
                this.hide();
                this.showConfirmation = true;
            }
        },
        hide() {
            this.$emit('hide');
        },
    },
    watch: {
        show() {
            this.phone = '';
            this.message = '';
        },
    },
});
