




import Vue, { PropType } from 'vue';

import * as Constants from '@/common/constants/visualizations.constants';
import { Position2d } from '@/common/types/reports/position';
import { Visualization } from '@/common/utils/reports/visualization/visualization';
import * as ScoreUtils from '@/common/utils/reports/visualization/score';

export interface ScoreConfig {
    score: number;
    scoreLabel: string;
    percentile: number;
    percentileLabel: string;
    functionalWindowLowerBound: number;
    functionalWindowUpperBound: number;
    accuracy: number;
    reportVersion: number;
}

export default Vue.extend({
    props: {
        config: {
            type: Object as PropType<ScoreConfig>,
        },
    },
    methods: {
        calculateScoreColor(score: number): string {
            if (score >= 75) {
                return Constants.GREEN;
            } else if (score >= 50) {
                return Constants.YELLOW;
            } else if (score >= 25) {
                return Constants.ORANGE;
            } else {
                return Constants.RED;
            }
        },
        calculateScorePercentageInRadians(score: number): number {
            return (Math.PI * 2 * score) / 100;
        },
    },
    mounted() {
        const visualization = Visualization.build();

        // Positions
        const POSITION = new Position2d(0.23, 0.5);
        const TEXT_OFFSET = this.config.reportVersion === 1 ? new Position2d(0.15, -0.1) : new Position2d(0.19, 0.0);

        // Text
        const TEXT_FONT_FAMILY = 'ProximaNova';
        const NUMBER_FONT_SIZE_SMALL = '.0065em';
        const PURSUITS_TEXT_FONT_SIZE = '.0055em';
        const ACCURACY_TEXT_FONT_SIZE = '0.002em';

        const SMALL_CIRCLE_INNER_RADIUS = 0.075;
        const SMALL_CIRCLE_OUTER_RADIUS = 0.11;

        const PERCENTILE_TEXT_OFFSET = new Position2d(0.16, 0.17);
        const PERCENTILE_TEXT_FONT_SIZE = '.003em';

        const ASPECT_RATIO = 8 / 2;

        const individualScore = this.config.reportVersion === 1 ? this.config.score : this.config.accuracy;

        const individualLabels = [
            {
                text: this.config.scoreLabel,
                positionOffset: TEXT_OFFSET,
                fontSize: PURSUITS_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                anchor: 'start',
                color: Constants.BLACK,
            },
            {
                text: individualScore.toString(),
                positionOffset: new Position2d(0, 0),
                fontSize: NUMBER_FONT_SIZE_SMALL,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            },
        ];

        if (this.config.reportVersion === 1) {
            individualLabels.push({
                text: 'PERCENTILE: ' + this.config.percentile.toString(),
                positionOffset: PERCENTILE_TEXT_OFFSET,
                fontSize: PERCENTILE_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                anchor: 'start',
                color: Constants.BLACK,
            });
        } else {
            individualLabels.push({
                text: 'Accuracy Score',
                positionOffset: new Position2d(0.35, 0.22),
                fontSize: ACCURACY_TEXT_FONT_SIZE,
                fontFamily: TEXT_FONT_FAMILY,
                color: Constants.BLACK,
            });
        }

        ScoreUtils.generateScoreCircle(visualization, {
            position: POSITION,
            aspectRatio: ASPECT_RATIO,
            radius: SMALL_CIRCLE_OUTER_RADIUS,
            thickness: SMALL_CIRCLE_OUTER_RADIUS - SMALL_CIRCLE_INNER_RADIUS,
            labels: individualLabels,
            color: this.calculateScoreColor(individualScore),
            fillPercent: individualScore,
        });

        // Actually build the visualization according to the space avaliable
        visualization.drawNormalized(this.svg, ASPECT_RATIO);
    },
    computed: {
        svg(): SVGSVGElement {
            return this.$refs.svg as SVGSVGElement;
        },
    },
});
