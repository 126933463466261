import ProgramDetails from './programDetails';
import { ParticipantSummaryResponse, EqtPrescriptionResponse } from '@/common/types/api/responses/trainerResponses';

import AssessmentModel from './assessmentModel';
import PrescriptionModel from './prescriptionModel';
import GeneralAssignmentModel from './generalAssignmentModel';
import { EXERCISES } from '@/common/types/trainer/exerciseModels';
import { ExerciseModel } from '@/common/types/trainer/program';
import EqtAssignmentModel from './eqtAssignmentModel';

export default class EqtParticipantDetails {
    public readonly program: ProgramDetails;
    public readonly assessments: AssessmentModel[];
    public readonly prescriptions: PrescriptionModel[];
    public readonly generalAssignments: GeneralAssignmentModel[];
    public readonly generalExercises: ExerciseModel[][];
    public readonly assignments: EqtAssignmentModel[];

    public constructor(programSummary: ParticipantSummaryResponse) {
        if (programSummary.eqtProgram) {
            this.program = new ProgramDetails(programSummary.eqtProgram);
        }
        this.prescriptions = createPrescriptions(programSummary);
        this.assessments = createAssessments(this.prescriptions);
        this.generalAssignments = createGeneralAssignments(this.prescriptions);
        this.generalExercises = createGeneralExercises(programSummary.generalExerciseSets);
        this.assignments = createAssignments(this.generalAssignments, this.assessments);
    }
}

function createPrescriptions(participantSummary: ParticipantSummaryResponse): PrescriptionModel[] {
    const result: PrescriptionModel[] = [];
    let selectedPrescription: string | null = null;

    // Find the next prescription id for this participant
    if (participantSummary.participant.eqtParticipantSettings) {
        const nextPrescriptionId = participantSummary.participant.eqtParticipantSettings.nextPrescriptionId;
        if (nextPrescriptionId) {
            selectedPrescription = participantSummary.participant.eqtParticipantSettings.nextPrescriptionId;
        }
    }

    // Find the matching prescription and mark it with next, remembering the prescription group id
    participantSummary.prescriptions.forEach((prescriptionResponse: EqtPrescriptionResponse) => {
        const prescription = new PrescriptionModel(prescriptionResponse);
        prescription.IsNext = prescription.Id === selectedPrescription;

        result.push(prescription);
    });

    // Sort by their ordering
    result.sort((a, b) => (a.Ordering > b.Ordering ? 1 : -1));

    return result;
}

function createAssessments(prescriptions: PrescriptionModel[]): AssessmentModel[] {
    const result: AssessmentModel[] = [];

    prescriptions.forEach((prescription: PrescriptionModel) => {
        if (prescription.Assessment != null) {
            let assessment = result.find((a) => {
                return a.Id === prescription.Assessment?.Id;
            });
            if (!assessment) {
                result.push(prescription.Assessment);
                assessment = prescription.Assessment;
            }
            assessment.AddPrescription(prescription);
        }
    });

    return result;
}

function createGeneralAssignments(prescriptions: PrescriptionModel[]): GeneralAssignmentModel[] {
    const generalAssignments = {} as any;

    // General assignments are defined by groups of prescriptions with no assessment and a group id
    // They are unordered and variable so initalize the general assignments, add to them, and then return
    prescriptions.forEach((prescription: PrescriptionModel) => {
        const prescriptionGroupId = prescription.PrescriptionGroupId;
        if (!prescription.Assessment && prescriptionGroupId) {
            if (!generalAssignments[prescriptionGroupId]) {
                generalAssignments[prescriptionGroupId] = new GeneralAssignmentModel(
                    prescriptionGroupId,
                    prescription.DateCreated,
                );
            }
            generalAssignments[prescriptionGroupId].AddPrescription(prescription);
        }
    });

    return Object.values(generalAssignments);
}

function createGeneralExercises(exerciseSets: string[][]): ExerciseModel[][] {
    const result: ExerciseModel[][] = [];

    exerciseSets.forEach((exerciseSet) => {
        const set: ExerciseModel[] = [];
        exerciseSet.forEach((id) => {
            const exercise = EXERCISES.get(id);
            if (exercise) {
                set.push(exercise);
            }
        });
        result.push(set);
    });

    return result;
}

function createAssignments(
    generalAssignments: GeneralAssignmentModel[],
    assessments: AssessmentModel[],
): EqtAssignmentModel[] {
    const result = [] as EqtAssignmentModel[];

    generalAssignments.forEach((generalAssignment) => {
        result.push(new EqtAssignmentModel(generalAssignment));
    });

    assessments.forEach((assessment) => {
        result.push(new EqtAssignmentModel(assessment));
    });

    result.sort((rhs, lhs) => (rhs.DateCreated < lhs.DateCreated ? 1 : -1));
    return result;
}
