export interface DataApiVersionResponse {
    apiVersion: string;
}

export interface DataApiResult {
    [key: string]: any;
}

export interface GraphQlError {
    message?: string;
    locations?: GraphQlErrorLocation[];
}

export interface GraphQlErrorLocation {
    line?: number;
    column?: number;
}

export class GraphQlErrorResponse extends Error {
    public readonly errors: GraphQlError[];

    public constructor(errors: GraphQlError[]) {
        super();
        this.errors = errors;
        super.message = this.toStringPretty();
    }

    public toString(): string {
        const errorMessages: string[] = this.errors
            .filter((error) => error.message !== undefined)
            .map((error) => error.message!);

        return `Invalid GraphQL Query (${this.errors.length} errors): ${errorMessages.join(', ')}`;
    }

    public toStringPretty(): string {
        const errorMessages: string[] = this.errors.map((error) => {
            const errorLocation = error.locations?.[0];
            const lineNumber = errorLocation?.line?.toString() ?? '?';
            const columnNumber = errorLocation?.column?.toString() ?? '?';
            const message = error.message ?? '?';

            return `[${lineNumber}, ${columnNumber}] ${message}`;
        });

        return `Invalid GraphQL Query (${this.errors.length} errors):\n${errorMessages.join('\n')}`;
    }
}
