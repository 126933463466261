






import Vue from 'vue';

import * as Nav from '@/store/navigation/mutations';

import { LeftNavLink } from '@/common/types/general';
import { ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';

export default Vue.extend({
    data() {
        return {};
    },
    computed: {
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
    },
    methods: {
        setNavigationLinks() {
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, []);
            this.$store.commit(Nav.SHOW_LEFT_NAV);

            const titleNavText = this.$t('routes.admin');
            this.$store.commit(Nav.SET_LEFT_NAV_TITLE_TEXT, titleNavText);

            const links = [
                {
                    text: this.$t('routes.companyManagement'),
                    to: { name: 'CompanyManagement' },
                    icon: 'business',
                    enabled: true,
                },
                {
                    text: this.$t('routes.users'),
                    to: { name: 'UserManagement' },
                    icon: 'contacts',
                    enabled: true,
                },
                {
                    text: this.$t('routes.appVersions'),
                    to: { name: 'AppVersionManagement' },
                    icon: 'fas fa-code-branch',
                    enabled: this.isEngineer,
                },
                {
                    text: this.$t('routes.successCenter'),
                    to: { name: 'successCenter' },
                    icon: 'far fa-question-circle',
                    enabled: true,
                },
            ] as LeftNavLink[];
            this.$store.commit(Nav.SET_LEFT_NAV_LINKS, links);
        },
    },
    async mounted() {
        this.setNavigationLinks();
    },
});
