










import Vue from 'vue';
import PieVisual from '@/views/trainer/exercises/components/stimuli/PieVisual.vue';

export default Vue.extend({
    components: { PieVisual },
    props: {
        x: {
            type: Number,
            required: true,
        },
        y: {
            type: Number,
            required: true,
        },
        r: {
            type: Number,
            required: true,
        },
        duration: {
            type: Number,
            required: true,
        },
        fillColor: {
            type: String,
            required: true,
        },
        activated: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentProgress: 0,
            startTime: undefined as number | undefined,
        };
    },
    methods: {
        step(timestamp: number) {
            if (!this.activated || this.currentProgress >= 0.99) {
                this.startTime = undefined;
                return;
            }

            if (!this.startTime) {
                this.startTime = timestamp;
            }

            const diff = timestamp - this.startTime;
            this.currentProgress = diff / this.duration;

            window.requestAnimationFrame(this.step.bind(this));
        },
        start() {
            this.currentProgress = 0;
            if (this.activated) {
                window.requestAnimationFrame(this.step.bind(this));
            }
        },
    },
    computed: {
        progress(): number {
            return this.currentProgress;
        },
        centerX(): number {
            return this.x;
        },
        centerY(): number {
            return this.y;
        },
        radius(): number {
            return this.r;
        },
    },
    watch: {
        activated(value) {
            this.start();
        },
        x(value) {
            this.start();
        },
        y(value) {
            this.start();
        },
    },
    mounted() {
        if (this.activated) {
            this.start();
        }
    },
});
