




























import Vue, { PropType } from 'vue';

import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    components: {
        GreenButton,
    },
    props: {
        show: Boolean,
        save: Function as PropType<VoidFunction>,
        cancel: Function as PropType<VoidFunction>,
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
    },
});
