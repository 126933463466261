export const SPACE_STORM_NORMS_QUERY = `
spaceStorm {
    eyeType
    level
    score {
      mean
      stdDev
    }
    ships
    distractors
}
`;

export const SPACE_STORM_METRIC_QUERY = `
spaceStorm {
    info {
        attemptNumber
    }
    metrics {
        eyeType
        level
        score
        ships
        distractors
        distanceFromScreenMin
        distanceFromScreenMax
        left {
            distanceFromScreen
        }
        right {
          distanceFromScreen
        }
        both {
          distanceFromScreen
        }
    }
}
`;
