import { render, staticRenderFns } from "./VideoModal.vue?vue&type=template&id=1ba02480&scoped=true&"
import script from "./VideoModal.vue?vue&type=script&lang=ts&"
export * from "./VideoModal.vue?vue&type=script&lang=ts&"
import style0 from "./VideoModal.vue?vue&type=style&index=0&id=1ba02480&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba02480",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardTitle,VDialog})
