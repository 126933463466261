


























import Vue, { PropType } from 'vue';

import SensorimotorScreenerResultVisualization, {
    SensorimotorScreenerResultConfig,
} from '@/views/reports/visualizations/SensorimotorScreenerResultVisualization.vue';
import { ReportData } from '@/common/types/reports/reportData';

import MultiMetricTable from '@/views/reports/metrics/MultiMetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

import * as MetricsUtils from '@/common/utils/reports/metrics';
import SensorimotorApi from '@/api/sensorimotor.api';

export default Vue.extend({
    components: {
        SensorimotorScreenerResultVisualization,
        MultiMetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        hasSensorimotorExam: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            config: undefined as SensorimotorScreenerResultConfig | undefined,
            directions: [
                'midlinePrimary',
                'superiorLeft',
                'superiorRight',
                'inferiorLeft',
                'inferiorRight',
                'midlineLeft',
                'superiorMidline',
                'midlineRight',
                'inferiorMidline',
            ],
        };
    },
    computed: {
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMin');
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreenMax');
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.calibration?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push('Nine Point Motor Function: ' + this.$t('reports.info.sensorimotorGuidance'));
            infoText.push('Near Point Convergence: ' + this.$t('reports.info.nearPointConvergence'));

            return infoText;
        },
    },
    methods: {
        async loadReportData(reportData: ReportData) {
            try {
                if (!reportData.tests.calibration) {
                    this.$emit('error', new Error(this.$t('reports.cards.error').toString()));
                    return;
                }

                const screenerResult = await SensorimotorApi.getScreenerResult(reportData.assessment.id);

                this.config = {} as SensorimotorScreenerResultConfig;
                this.config.calibrationTestData = reportData.tests.calibration;
                this.config.nearPointConvergenceTestData = reportData.tests.nearPointConvergence;
                this.config.systemType = reportData.assessment.systemType;
                this.config.result = screenerResult.result;
                this.config.assessmentId = reportData.assessment.id;
                this.config.overrideResult = screenerResult.overrideResult;
                this.config.participantId = reportData.participant.id;
                this.config.participantDob = reportData.participant.dateOfBirth!;
                this.config.hasSymptoms = screenerResult.hasSymptoms;
            } catch (error) {
                this.$emit('error', error);
                return;
            }
        },
    },
    watch: {
        reportData(newValue) {
            this.loadReportData(newValue);
        },
    },
    async mounted() {
        await this.loadReportData(this.reportData);
    },
});
