import ApiService from '@/api/common/apiService';

import { Module, Company, Country, State, Continent } from '@/common/types/company/company';
import { CompanyBAARequest, CompanyRequest } from '@/common/types/api/requests/companyRequest';
import { PaginatedResponse } from '@/common/types/api/responses/paginatedResponses';
import { HttpMethod } from '@/common/types/api/requests/customRequest';
import { CompanyBAAResponse } from '@/common/types/api/responses/companyBAAResponses';

class CompanyApi {
    public async getCompaniesPaginated(
        page: number,
        size: number,
        searchValue: string,
        sortBy: string,
        sortOrder: string,
    ): Promise<PaginatedResponse<Company>> {
        const uri: string = `/righteye.api/pagingcompanies?page=${page}&per_page=${size}&sort_order=${sortOrder}&sort_by=${sortBy}&search_value=${searchValue}&logo=true`;

        const request = {
            url: uri,
            method: 'GET' as HttpMethod,
        };
        const response = await ApiService.requestWithDataField<any>(request);
        return response;
    }

    public async createNewCompany(company: CompanyRequest) {
        const uri: string = `/righteye.api/companies`;
        const response = await ApiService.post<CompanyRequest>(uri, company);
        return response;
    }

    public async getListOfCountries(): Promise<Country[]> {
        const uri: string = `/righteye.api/countries`;
        const response = await ApiService.get<Country[]>(uri);
        return response;
    }

    public async getListOfStatesByCountry(countryCode: string): Promise<State[]> {
        const uri: string = `/righteye.api/states/${countryCode}`;
        const response = await ApiService.get<State[]>(uri);
        return response;
    }

    public async getListOfContinents(): Promise<Continent[]> {
        const uri: string = `/righteye.api/continents`;
        const response = await ApiService.get<Continent[]>(uri);
        return response;
    }

    public async updateCompanyEyeQTrainerActiveStatus(
        id: string,
        eyeQTrainerActive: boolean,
        populationNormsActive: boolean,
    ) {
        const uri = `/righteye.api/companies/${id}/updateEQTAndPopNorms?isEQTActive=${eyeQTrainerActive}&isPopNormActive=${populationNormsActive}`;
        const response = await ApiService.post<string>(uri);
        return response;
    }

    public async getCompanyModules(companyId: string): Promise<Module[]> {
        const uri: string = `/righteye.api/companies/${companyId}/modules`;
        const response = await ApiService.get<Module[]>(uri);
        return response;
    }

    public async getCompanies(getLogos = false): Promise<Company[]> {
        const uri: string = getLogos ? `/righteye.api/companies?logo=true` : `/righteye.api/companies`;
        const response = await ApiService.get<Company[]>(uri);
        return response;
    }

    public async getCompanyById(companyId: string): Promise<Company> {
        const uri: string = `/righteye.api/companies/${companyId}`;
        const response = await ApiService.get<Company>(uri);
        return response;
    }

    public async getModules(): Promise<Module[]> {
        const uri: string = `/righteye.api/modules`;
        const response = await ApiService.get<Module[]>(uri);
        return response;
    }

    public async updateModulesForCompany(companyId: string, moduleIds: string[]): Promise<string[]> {
        const uri: string = `/righteye.api/companies/${companyId}/modules`;
        const response = await ApiService.put<string[]>(uri, moduleIds);
        return response;
    }

    public async updateCompany(company: Company): Promise<Company> {
        const uri: string = `/righteye.api/companies/`;
        const response = await ApiService.put<Company>(uri, company);
        return response;
    }

    public async updateCompanyActiveState(companyId: string, activeState: boolean) {
        const uri: string = `righteye.api/companies/${companyId}/active=${activeState}`;
        const response = await ApiService.put<Company>(uri);
        return response;
    }

    public async uploadLogoForCompany(companyId: string, logo: File): Promise<string> {
        const uri: string = `righteye.api/companies/${companyId}/logo`;
        const formData = new FormData();
        formData.append('file', logo, logo.name);
        formData.append('name', logo.name);
        const response = await ApiService.put<string>(uri, formData, { 'Content-Type': 'multipart/form-data' });
        return response;
    }

    public async uploadBAAForCompany(companyId: string, baaKey: string, baaSigned: CompanyBAARequest): Promise<string> {
        const uri: string = `righteye.api/companies/${companyId}/baa/${baaKey}`;
        const response = await ApiService.put<string>(uri, baaSigned);
        return response;
    }

    public async getBAAForCompany(companyId: string, baaKey: string): Promise<CompanyBAAResponse> {
        const uri: string = `righteye.api/companies/${companyId}/baa/${baaKey}`;
        const response = await ApiService.get<CompanyBAAResponse>(uri);
        return response;
    }

    public async getBAAForCompanyAuthenticated(companyId: string): Promise<CompanyBAAResponse> {
        const uri: string = `righteye.api/companies/${companyId}/baa`;
        const response = await ApiService.get<CompanyBAAResponse>(uri);
        return response;
    }

    public async uploadBAAForCompanyAuthenticated(companyId: string, baaSigned: CompanyBAARequest): Promise<string> {
        const uri: string = `righteye.api/companies/${companyId}/baa/`;
        const response = await ApiService.put<string>(uri, baaSigned);
        return response;
    }
}

export default new CompanyApi();
