





























































import Vue from 'vue';

import InfoModal from '@/views/common/containers/InfoModal.vue';

export default Vue.extend({
    components: {
        InfoModal,
    },
    data() {
        return {
            pursuitImageSources: [
                { src: require('@/assets/dynamicVision/brain/pursuits_gray.png') },
                { src: require('@/assets/dynamicVision/brain/pursuits_red.png') },
            ],
            saccadeImageSources: [
                { src: require('@/assets/dynamicVision/brain/saccade_gray.png') },
                { src: require('@/assets/dynamicVision/brain/saccade_red.png') },
            ],
            fixationImageSources: [
                { src: require('@/assets/dynamicVision/brain/fixation_gray.png') },
                { src: require('@/assets/dynamicVision/brain/fixation_red.png') },
            ],
            pursuitIndex: 0,
            saccadeIndex: 0,
            fixationIndex: 0,
        };
    },
    computed: {},
    methods: {
        handlePursuitClick() {
            this.pursuitIndex = (this.pursuitIndex + 1) % this.pursuitImageSources.length;
        },
        handleSaccadeClick() {
            this.saccadeIndex = (this.saccadeIndex + 1) % this.saccadeImageSources.length;
        },
        handleFixationClick() {
            this.fixationIndex = (this.fixationIndex + 1) % this.fixationImageSources.length;
        },
    },
});
