import { GraphQlQuery } from '../../common/types/api/requests/graphqlRequests';

export class ParticipantIdQuery implements GraphQlQuery {
    private readonly assessmentId: string;

    public constructor(assessmentId: string) {
        this.assessmentId = assessmentId;
    }

    public requestBody(): string {
        const query = `
        query ParticipantAssessments($assessmentId: String!) {
            report(assessmentId: $assessmentId) {
                participant {
                    id,
                    companyId
                }
            }
        }`;

        const variables = {
            assessmentId: this.assessmentId,
        };

        const body: any = {
            query,
            variables,
        };

        return JSON.stringify(body);
    }
}
