














import Vue from 'vue';
import { WorkoutResponse } from '@/common/types/api/responses/trainerResponses';
import * as DateUtils from '@/common/utils/date';

export default Vue.extend({
    props: {
        workout: {
            type: Object as () => WorkoutResponse,
            required: true,
        },
    },
    data() {
        return {
            completedNum: 0,
            workoutNum: 1,
            workoutDate: undefined as string | undefined,
        };
    },
    mounted() {
        this.workoutNum = this.workout.number;

        if (this.workout.exercises) {
            this.completedNum = this.workout.exercises.filter((exercise: any) => {
                return exercise.finished;
            }).length;
        }

        if (this.workout.started) {
            this.workoutDate = DateUtils.getTrainerProgressFormat(this.workout.started);
        }
    },
});
