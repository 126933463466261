import { ENGINEERING_ROLE, SUPER_ADMIN_ROLE, CLIENT_ADMIN_ROLE } from '../constants/userRoles.constants';
import store from '@/store/index';
import * as CompanyModule from '@/common/types/auth/companyModules';

export const EQT_SETTINGS = 'EqtSettings';
export const COMPANY_MODULES = 'CompanyModules';
export const ALL_SETTINGS = [EQT_SETTINGS, COMPANY_MODULES];

export function isSettingsEnabled(block: string): boolean {
    const settingsPermissions = configureSettingsPermissions();
    return settingsPermissions[block];
}

export function areSettingsEnabled(): boolean {
    const settingsPermissions = configureSettingsPermissions();
    return Object.values(settingsPermissions).filter((s: boolean) => s).length ? true : false;
}

function configureSettingsPermissions(): Record<string, boolean> {
    const userRole = store.getters.userRole;
    const settingsPermissions = {
        EqtSettings:
            store.getters.hasModule(CompanyModule.EYEQ_TRAINER) &&
            (userRole === CLIENT_ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE || userRole === ENGINEERING_ROLE),
        CompanyModules: store.getters.userRole === SUPER_ADMIN_ROLE || store.getters.userRole === ENGINEERING_ROLE,
    } as Record<string, boolean>;
    return settingsPermissions;
}
