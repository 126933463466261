export const SPORTS_VISION = 'Sports Vision EyeQ';
export const THERAPY_GAMES = 'Therapy Games';
export const DYNAMIC_VISION = 'Dynamic Vision';
export const READING = 'Reading';
export const SACCADOMETRY = 'Saccadometry Test';
export const EYEQ_TRAINER = 'EyeQ Trainer';
export const SPORTS_VIDEO = 'Sports Video';
export const INDIVIDUAL_TESTS = 'Individual Tests';
export const NEURO_VISION = 'Neuro Vision';
export const FUNCTIONAL_VISION = 'Functional Vision EyeQ';
export const BRAIN_HEALTH = 'Brain Health EyeQ';
export const CUSTOM_PROTOCOLS = 'Custom Protocols';
export const SENSORIMOTOR = 'Sensorimotor';
export const UNKNOWN = 'Unknown';

export default interface CompanyModule {
    id: string;
    name: string;
}
