
















import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import { ReportData } from '@/common/types/reports/reportData';

import InfoModal from '@/views/common/containers/InfoModal.vue';
import ReactionTime, { ReactionTimeConfig } from '@/views/reports/visualizations/ReactionTime.vue';

export default Vue.extend({
    components: {
        ReactionTime,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
    },
    data() {
        return {
            config: {
                choiceReactionTime: this.reportData.tests.choiceReactionTime.metrics,
                discriminateReactionTime: this.reportData.tests.discriminateReactionTime.metrics,
            } as ReactionTimeConfig,
        };
    },
    computed: {
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];
            infoText.push(this.$t('reports.info.reactionTime.saccadicLatency'));
            infoText.push(this.$t('reports.info.reactionTime.definition'));
            return infoText;
        },
    },
});
