export default {
    rightEye: 'RightEye',
    banner: {
        message: 'Welcome!',
    },
    header: {
        welcome: 'Hello, {user}!',
        needHelpMenu: 'Need Help?',
    },
    version: 'Version',
    confirmation: {
        title: 'Confirm Selection',
        body: 'Are you sure you would like to make this change?',
    },
    buttons: {
        submit: 'SUBMIT',
        login: 'Log In',
        cancel: 'Cancel',
        backToHome: 'Back to Home',
    },
    tooltips: {
        edit: 'Edit',
        save: 'Save',
    },
};
