import ApiService from '@/api/common/apiService';
import { CreateOverrideRequest, DeleteOverrideRequest } from '@/common/types/api/requests/appVersionRequests';
import { AppVersionManifest, AppVersions } from '@/common/types/appVersions/appVersions';
import querystring from 'querystring';

class AppVersionsApi {
    public async getAppVersions(): Promise<AppVersions> {
        const url: string = `/eta/applicationVersions?overrides=true`;
        return await ApiService.requestWithNoAuthorization<AppVersions>({
            url,
            method: 'GET',
        });
    }

    public async getAppManifests(): Promise<AppVersionManifest[]> {
        const url: string = `/eta/allAppManifests`;
        return await ApiService.requestWithNoAuthorization<AppVersionManifest[]>({
            url,
            method: 'GET',
        });
    }

    public async getLauncherManifests(): Promise<AppVersionManifest[]> {
        const url: string = `/eta/allLauncherManifests`;
        return await ApiService.requestWithNoAuthorization<AppVersionManifest[]>({
            url,
            method: 'GET',
        });
    }

    public async getDeltavManifests(): Promise<AppVersionManifest[]> {
        const url: string = `/eta/allDeltavManifests`;
        return await ApiService.requestWithNoAuthorization<AppVersionManifest[]>({
            url,
            method: 'GET',
        });
    }

    public async cleanAppBuilds(versions: string[]): Promise<AppVersionManifest[]> {
        const qs = querystring.stringify({ versions });
        const url: string = `/eta/cleanAppBuilds?${qs}`;
        return await ApiService.requestWithNoAuthorization<AppVersionManifest[]>({
            url,
            method: 'DELETE',
        });
    }

    public async cleanLauncherBuilds(versions: string[]): Promise<AppVersionManifest[]> {
        const qs = querystring.stringify({ versions });
        const url: string = `/eta/cleanLauncherBuilds?${qs}`;
        return await ApiService.requestWithNoAuthorization<AppVersionManifest[]>({
            url,
            method: 'DELETE',
        });
    }

    public async createOverride(createOverrideRequest: CreateOverrideRequest): Promise<void> {
        const url: string = `/eta/addOverride`;
        return await ApiService.requestWithNoAuthorization<void>({
            url,
            method: 'POST',
            data: createOverrideRequest,
        });
    }

    public async deleteOverride(deleteOverrideRequest: DeleteOverrideRequest): Promise<void> {
        const url: string = `/eta/deleteOverride`;
        return await ApiService.requestWithNoAuthorization<void>({
            url,
            method: 'DELETE',
            data: deleteOverrideRequest,
        });
    }
}

export default new AppVersionsApi();
