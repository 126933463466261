import { ProgramDetailsResponse } from '@/common/types/api/responses/trainerResponses';
import CycleDetails from './cycleDetails';
import { WorkoutPlan } from '@/common/types/trainer/program';

import { getTimeStampFormat } from '@/common/utils/date';

export default class ProgramDetails {
    private readonly programId: string;
    private readonly exercisesCompleted: number;
    private readonly startDate: Date;
    private endDate: Date;
    private readonly cycleDetails: CycleDetails;
    private workoutPlan: WorkoutPlan | undefined;

    public constructor(programDetails: ProgramDetailsResponse) {
        this.programId = programDetails.id;
        this.startDate = new Date(programDetails.startDate);
        this.endDate = new Date(programDetails.endDate);
        this.cycleDetails = programDetails.cycles ? new CycleDetails(programDetails.cycles) : new CycleDetails([]);
        this.workoutPlan = this.cycleDetails.CurrentCycle?.WorkoutPlan;

        let exercisesCompleted = 0;
        this.cycleDetails.AllCycles.forEach((c) => (exercisesCompleted += c.WorkoutsCompleted));
        this.exercisesCompleted = exercisesCompleted;
    }

    public get ProgramId(): string {
        return this.programId;
    }

    public get StartDate(): string {
        return getTimeStampFormat(this.startDate);
    }

    public set EndDate(endDate: string) {
        this.endDate = new Date(endDate);
    }

    public get EndDate(): string {
        return getTimeStampFormat(this.endDate);
    }

    public get ExercisesCompleted(): number {
        return this.exercisesCompleted;
    }

    public get CycleDetails(): CycleDetails {
        return this.cycleDetails;
    }

    public get WorkoutPlan(): WorkoutPlan | undefined {
        return this.workoutPlan;
    }

    public set WorkoutPlan(workoutPlan: WorkoutPlan | undefined) {
        this.workoutPlan = workoutPlan;
    }
}
