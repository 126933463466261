import ApiService from '@/api/common/apiService';
import { HttpMethod } from '@/common/types/api/requests/customRequest';

import * as Responses from '@/common/types/api/responses/assessmentResponses';
import { PaginatedResponse } from '@/common/types/api/responses/paginatedResponses';

import {
    AntisaccadeResult,
    ProsaccadeResult,
    Assessment,
    AssessmentReport,
    ComparativeReportScore,
    ReportScore,
} from '@/common/types/reports/assessment';
import { ReportCondition } from '@/common/types/reports/reportCondition';
import { ReportRecommendation } from '@/common/types/reports/reportRecommendation';
import { ReportScoreNameFromTypeId } from '@/common/utils/reports/reports';

class AssessmentApi {
    public async getAssessmentsByCompanyPaginated(
        companyId: string,
        page: number,
        size: number,
        searchValue: string,
        sortBy: string,
        sortOrder: string,
        active?: boolean,
    ): Promise<PaginatedResponse<AssessmentReport>> {
        let uri: string = `/righteye.api/companies/${companyId}/assessments?page=${page}&per_page=${size}&sort_order=${sortOrder}&sort_by=${sortBy}&search_value=${searchValue}`;
        if (active !== undefined) {
            uri = uri + `&active=${active}`;
        }

        const request = {
            url: uri,
            method: 'GET' as HttpMethod,
        };
        const response = await ApiService.requestWithDataField<any>(request);
        return response;
    }

    public async getAssessmentsByUserPaginated(
        userId: string,
        page: number,
        size: number,
        searchValue: string,
        sortBy: string,
        sortOrder: string,
    ): Promise<PaginatedResponse<AssessmentReport>> {
        const uri: string = `/righteye.api/users/${userId}/assessments?page=${page}&per_page=${size}&sort_order=${sortOrder}&sort_by=${sortBy}&search_value=${searchValue}`;

        const request = {
            url: uri,
            method: 'GET' as HttpMethod,
        };
        const response = await ApiService.requestWithDataField<any>(request);
        return response;
    }

    public async getReportScore(assessmentId: string, assessmentType: string): Promise<ReportScore> {
        const reportScoreName = ReportScoreNameFromTypeId(assessmentType);
        const uri: string = `/righteye.api/assessment/${assessmentId}/reportScore/${reportScoreName}`;
        const response = await ApiService.get<ReportScore>(uri);
        return response;
    }

    public async getComparativeScoreData(assessmentId: string): Promise<ComparativeReportScore> {
        const uri: string = `/righteye.api/comparativeAssessment/${assessmentId}/reportScore`;
        const response = await ApiService.get<ComparativeReportScore>(uri);
        return response;
    }

    public async getAssessmentsByCompanyId(companyId: string): Promise<Assessment[]> {
        const uri = `/righteye.api/v2/companies/${companyId}/assessments`;
        const response = await ApiService.get<Assessment[]>(uri);
        return response;
    }

    public async getAssessmentsByUserId(userId: string): Promise<Assessment[]> {
        const uri = `/righteye.api/v2/user/${userId}/assessments`;
        const response = await ApiService.get<Assessment[]>(uri);
        return response;
    }

    public async getAssessmentsByParticipantId(participantId: string): Promise<Assessment[]> {
        const result = [] as Assessment[];
        const uri = `/righteye.api/participants/${participantId}/assessments`;
        const response = await ApiService.get<AssessmentReport[]>(uri);
        response.map((ar: AssessmentReport) => {
            result.push(ar.assessment);
        });
        return result;
    }

    public async getAssessmentById(assessmentId: string): Promise<Assessment> {
        const uri = `/righteye.api/v2/assessments/${assessmentId}`;
        const response = await ApiService.get<AssessmentReport>(uri);
        return response.assessment;
    }

    public async updateAssessmentNotes(
        assessmentId: string,
        notes: string,
    ): Promise<Responses.AssessmentNotesResponse> {
        const uri = `/righteye.api/assessment/submitnotes`;
        const body = {
            assessmentId,
            notes,
        };
        const response = await ApiService.post<Responses.AssessmentNotesResponse>(uri, body);
        return response;
    }

    public async setAssessmentActiveState(assessmentId: string, active: boolean): Promise<Assessment> {
        if (active) {
            const uri = `/righteye.api/assessments/${assessmentId}/active`;
            const response = await ApiService.post<Assessment>(uri);
            return response;
        } else {
            const uri = `/righteye.api/assessments/${assessmentId}/inactive`;
            const response = await ApiService.post<Assessment>(uri);
            return response;
        }
    }

    public async getReportConditions(assessmentId: string): Promise<ReportCondition[]> {
        const uri = `/righteye.api/assessment/${assessmentId}/conditions`;
        const response = await ApiService.get<ReportCondition[]>(uri);
        return response;
    }

    public async setReportConditions(assessmentId: string, conditions: ReportCondition[]): Promise<ReportCondition[]> {
        const uri = `/righteye.api/assessment/${assessmentId}/conditions`;
        const response = await ApiService.put<ReportCondition[]>(uri, conditions);
        return response;
    }

    public async getReportRecommendations(assessmentId: string): Promise<ReportRecommendation[]> {
        const uri = `/righteye.api/assessment/${assessmentId}/recommendations`;
        const response = await ApiService.get<ReportRecommendation[]>(uri);
        return response;
    }

    public async setReportRecommendations(
        assessmentId: string,
        recommendations: ReportCondition[],
    ): Promise<ReportRecommendation[]> {
        const uri = `/righteye.api/assessment/${assessmentId}/recommendations`;
        const response = await ApiService.put<ReportRecommendation[]>(uri, recommendations);
        return response;
    }

    public async getIsAntisaccadeTest(assessmentId: string): Promise<boolean> {
        const uri = `/righteye.api/assessments/${assessmentId}/antisaccade`;
        const response = await ApiService.get<boolean>(uri);
        return response;
    }

    public async getAntisaccadeTestData(assessmentId: string): Promise<AntisaccadeResult> {
        const uri = `/righteye.api/assessments/${assessmentId}/antisaccade/test`;
        const response = await ApiService.get<AntisaccadeResult>(uri);
        return response;
    }

    public async getIsProsaccadeTest(assessmentId: string): Promise<boolean> {
        const uri = `/righteye.api/assessments/${assessmentId}/prosaccade`;
        const response = await ApiService.get<boolean>(uri);
        return response;
    }

    public async getProsaccadeTestData(assessmentId: string): Promise<ProsaccadeResult> {
        const uri = `/righteye.api/assessments/${assessmentId}/prosaccade/test`;
        const response = await ApiService.get<ProsaccadeResult>(uri);
        return response;
    }
}

export default new AssessmentApi();
