






























































import Vue, { PropType } from 'vue';

import { ReportData } from '@/common/types/reports/reportData';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';
import * as MetricsUtils from '@/common/utils/reports/metrics';
import { formatNumber } from '@/common/utils/reports/format';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        const metrics = this.reportData.tests.antisaccades.metrics.antisaccadeTest;

        const fields = [
            'saccadicLatency',
            'visualReactionSpeed',
            'processingSpeed',
            'reactionTime',
            'responseAccuracy',
            'distractibility',
            'impulsivity',
        ];

        return {
            metrics,
            fields,
            onTargetHeaders: [
                {
                    text: 'On Target Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
            contralateralDistractorHeaders: [
                {
                    text: 'Contralateral Distractor Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
            ipsilateralDistractorHeaders: [
                {
                    text: 'Ipsilateral Distractor Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
            targetOnlyHeaders: [
                {
                    text: 'Target Only Metrics',
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('reports.metrics.tests.calibration.myEyes'),
                    align: 'end',
                    value: 'both',
                },
            ],
        };
    },
    computed: {
        onTargetTableData(): any {
            const onTargetMetrics = this.metrics.on_target.block_summary;
            return this.blockMetrics(onTargetMetrics);
        },
        contralateralDistractorTableData(): any {
            const contralateralDistractorMetrics = this.metrics.contralateral_distractor.block_summary;
            return this.blockMetrics(contralateralDistractorMetrics);
        },
        ipsilateralDistractorTableData(): any {
            const ipsilateralDistractorMetrics = this.metrics.ipsilateral_distractor.block_summary;
            return this.blockMetrics(ipsilateralDistractorMetrics);
        },
        targetOnlyTableData(): any {
            const targetOnlyTableMetrics = this.metrics.target_only.block_summary;
            return this.blockMetrics(targetOnlyTableMetrics);
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.choiceReactionTime?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.choiceReactionTime?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.choiceReactionTime?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.antisaccades.definition'));
            infoText.push(this.$t('reports.info.prosaccades.metrics'));
            infoText.push(this.$t('reports.info.prosaccades.saccadicLatency'));
            infoText.push(this.$t('reports.info.prosaccades.saccadicAmplitude'));
            infoText.push(this.$t('reports.info.prosaccades.targetAccuracy'));
            infoText.push(this.$t('reports.info.prosaccades.antisaccadeNumber'));
            infoText.push(this.$t('reports.info.prosaccades.prosaccadeNumber'));
            infoText.push(this.$t('reports.info.prosaccades.saccadicOnset'));

            return infoText;
        },
    },
    methods: {
        blockMetrics(blockSummary: any) {
            return [
                {
                    name: 'Antisaccade Count (#)',
                    both: blockSummary.antisaccade_count,
                },
                {
                    name: 'Antisaccade Mean (#)',
                    both: `${formatNumber(blockSummary.antisaccade_mean)} (+/- ${formatNumber(
                        blockSummary.antisaccade_std_dev,
                    )})`,
                },
                {
                    name: 'Prosaccade Count (#)',
                    both: blockSummary.prosaccade_count,
                },
                {
                    name: 'Prosaccade Mean (#)',
                    both: `${formatNumber(blockSummary.prosaccade_mean)} (+/- ${formatNumber(
                        blockSummary.prosaccade_std_dev,
                    )})`,
                },
                {
                    name: 'Saccadic Amplitude (°)',
                    both: `${formatNumber(blockSummary.saccadic_amplitude_avg)} (+/- ${formatNumber(
                        blockSummary.saccadic_amplitude_std_dev,
                    )})`,
                },
                {
                    name: 'Saccadic Latency (ms)',
                    both: `${formatNumber(blockSummary.saccadic_latency_avg)} (+/- ${formatNumber(
                        blockSummary.saccadic_latency_std_dev,
                    )})`,
                },
                {
                    name: 'Target Accuracy (mm)',
                    both: `${formatNumber(blockSummary.target_accuracy_avg)} (+/- ${formatNumber(
                        blockSummary.target_accuracy_std_dev,
                    )})`,
                },
                {
                    name: 'Saccadic Onset (ms)',
                    both: `${formatNumber(blockSummary.saccadic_onset_mean)} (+/- ${formatNumber(
                        blockSummary.saccadic_onset_std_dev,
                    )})`,
                },
            ];
        },
    },
});
