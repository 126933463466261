
























import Vue from 'vue';

import * as CompanyModule from '@/common/types/auth/companyModules';

import { CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    props: {
        eyeQTrainerAssigned: {
            type: Boolean,
            required: true,
        },
        companyId: {
            type: String,
            required: true,
        },
    },
    computed: {
        isModuleActive(): boolean {
            return this.$store.getters.hasModule(CompanyModule.EYEQ_TRAINER);
        },
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
        tooltip(): TranslateResult {
            return this.eyeQTrainerAssigned
                ? this.$t('trainerAdmin.tooltips.eqtAssigned')
                : this.$t('trainerAdmin.tooltips.eqtNotAssigned');
        },
    },
    methods: {
        handleClick() {
            if (this.eyeQTrainerAssigned) {
                if (this.isClientAdmin || this.isSuperAdmin || this.isEngineer) {
                    this.$router.push({ name: 'eqtparticipants', params: { companyId: this.companyId } });
                } else {
                    this.$router.push({ name: 'landing' });
                }
            }
        },
    },
});
