import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import Auth from '@/store/auth/module';
import Company from '@/store/company/module';
import DataTables from '@/store/datatables/module';
import General from '@/store/general/module';
import Navigation from '@/store/navigation/module';
import Participant from '@/store/participant/module';
import Trainer from '@/store/trainer/module';
import User from '@/store/user/module';
import { CLEAR_ERROR_BANNER } from './general/mutations';
import { CLEAR_EQT_STATE } from './trainer/mutations';
import { CLEAR_COMPANY_STATE } from './company/mutations';
import { CLEAR_USER_STATE } from './user/mutations';
import { CLEAR_PARTICIPANT_STATE } from './participant/mutations';
import { CLEAR_DATATABLE_STATE } from './datatables/mutations';

// tslint:disable-next-line:no-empty-interface
export interface RootState {}

Vue.use(Vuex);

const LOCALSTORAGE_KEY = '280aed42-e8e8-44d7-9d85-f7ebe67dfdfb'; // Make it easy to invalidate cache on deployment by changing this key
const vuexPersist = new VuexPersist({
    key: LOCALSTORAGE_KEY,
    storage: window.localStorage,
    modules: ['Auth', 'Company', 'General', 'Navigation', 'Trainer', 'User', 'Participant'], // Which modules should be persisted between tabs, refreshes, etc
});

const store = new Vuex.Store({
    modules: {
        Auth,
        DataTables,
        Company,
        General,
        Navigation,
        Trainer,
        User,
        Participant,
    },
    plugins: [vuexPersist.plugin],
});

export function resetVuexStoreState() {
    // Clear the persisted VueX store on a module by module basis. Modules should implement and maintain their own CLEAR mutations
    store.commit(CLEAR_ERROR_BANNER);
    store.commit(CLEAR_EQT_STATE);
    store.commit(CLEAR_COMPANY_STATE);
    store.commit(CLEAR_USER_STATE);
    store.commit(CLEAR_PARTICIPANT_STATE);
    store.commit(CLEAR_DATATABLE_STATE);
    window.localStorage.removeItem(LOCALSTORAGE_KEY);
}

export default store;
