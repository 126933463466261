





























import Vue from 'vue';
import Logger from '@/common/utils/logger';
import * as Nav from '@/store/navigation/mutations';
import { ExerciseResponse } from '@/common/types/api/responses/trainerResponses';
import TrainerApi from '@/api/trainer.api';
import { SET_ERROR_BANNER } from '@/store/general/mutations';
import { CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE, ENGINEERING_ROLE } from '@/common/constants/userRoles.constants';

export default Vue.extend({
    props: ['exerciseId'],
    data() {
        return {
            currentExercise: undefined as ExerciseResponse | undefined,
            video: '',
            exerciseType: '',
            exercise: undefined as ExerciseResponse | undefined,
        };
    },
    methods: {
        onStartExerciseClicked() {
            if (this.exercise) {
                this.$router.push({
                    name: 'exercise',
                    params: {
                        // @ts-ignore because params is annoying
                        exercise: this.exercise,
                    },
                });
            } else if (this.currentExercise) {
                // @ts-ignore because params is annoying
                this.$router.push({
                    name: 'exercise',
                    params: {},
                });
            } else {
                this.$router.push({ name: 'progress' });
            }
        },
    },
    computed: {
        buttonText(): string {
            if (this.$store.getters.currentWorkout) {
                return this.$t('trainer.tutorial.link', {
                    exerciseNum: this.$store.getters.currentWorkout.currentExerciseNumber,
                }).toString();
            } else {
                return this.$t('trainer.tutorial.demoButtonText').toString();
            }
        },
        isClientAdmin(): boolean {
            return this.$store.getters.userRole === CLIENT_ADMIN_ROLE;
        },
        isSuperAdmin(): boolean {
            return this.$store.getters.userRole === SUPER_ADMIN_ROLE;
        },
        isEngineer(): boolean {
            return this.$store.getters.userRole === ENGINEERING_ROLE;
        },
    },
    async mounted() {
        this.$store.commit(Nav.HIDE_LEFT_NAV);

        if (this.exerciseId) {
            try {
                this.exercise = await TrainerApi.getExercise(this.exerciseId);
            } catch (error) {
                this.$store.commit(SET_ERROR_BANNER, error.message);
                Logger.error(error);
            }
        }

        if (this.exercise) {
            this.currentExercise = this.exercise;
        } else if (this.$store.getters.currentWorkout) {
            this.currentExercise = this.$store.getters.currentWorkout.currentExercise;
        }

        if (this.currentExercise) {
            this.video = this.currentExercise.tutorialLink;
            this.exerciseType = this.currentExercise.exerciseType.name;
        } else if (this.$store.getters.currentWorkout) {
            // @ts-ignore because params is annoying
            this.$router.push({
                name: 'progress',
                params: {
                    // @ts-ignore because params is annoying
                    showLogoutLink: true,
                },
            });
        } else if (this.isClientAdmin || this.isSuperAdmin || this.isEngineer) {
            this.$router.push({ name: 'exercises' });
        } else {
            Logger.error('Error loading exercise');
            this.$router.push({ name: 'home' });
        }
    },
});
