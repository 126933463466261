export const HORIZONTAL_GAP_SACCADES_NORMS_QUERY = `
horizontalGapSaccades{
    nystagmusDuration0Degrees {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusDuration20DegreesLeft {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusDuration20DegreesRight {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusDuration23DegreesLeft {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusVelocity0Degrees {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusVelocity20DegreesLeft {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusVelocity20DegreesRight {
        fast { mean stdDev }
        slow { mean stdDev }
    }
    nystagmusVelocity23DegreesLeft {
        fast { mean stdDev }
        slow { mean stdDev }
    }
}
`;

export const HORIZONTAL_GAP_SACCADES_METRIC_QUERY = `
horizontalGapSaccades {
    metrics {
        nystagmusDuration0Degrees {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusDuration20DegreesLeft {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusDuration20DegreesRight {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusDuration23DegreesLeft {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusVelocity0Degrees {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusVelocity20DegreesLeft {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusVelocity20DegreesRight {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        nystagmusVelocity23DegreesLeft {
            fast { mean stdDev }
            slow { mean stdDev }
        }
        both {
            distanceFromScreen
            distanceFromScreenMin
            distanceFromScreenMax
        }
    }
}
`;
