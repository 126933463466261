


















import Vue from 'vue';

import GreenButton from '@/views/common/controls/GreenButton.vue';

export default Vue.extend({
    props: {
        show: Boolean,
        workoutPlan: String,
    },
    components: {
        GreenButton,
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
    },
});
