






import Vue from 'vue';

interface MessageBarClass {
    multicolor: boolean;
}

export default Vue.extend({
    props: {
        msg: {
            type: String,
            default: '',
        },
        multicolor: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        gradient(): MessageBarClass {
            return {
                multicolor: this.multicolor,
            };
        },
    },
});
