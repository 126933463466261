






















































































































import Vue, { PropType } from 'vue';

import * as VisualDataUtils from '@/common/utils/reports/visualData';
import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ReportData } from '@/common/types/reports/reportData';

import FixationStabilityVisualization, {
    FixationStabilityConfig,
} from '@/views/reports/visualizations/FixationStabilityVisualization.vue';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import { extractLeftRightBothNorms } from '@/common/utils/reports/norms';
import { ReportNorms } from '@/common/types/reports/norms';
import { extractLeftRightBoth } from '@/common/utils/reports/reports';

import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';
import ReportCardDropdown from '@/views/company/components/ReportCardDropdown.vue';
import GreenButton from '@/views/common/controls/GreenButton.vue';
import { ButtonState } from '@/common/types/general';

export default Vue.extend({
    components: {
        FixationStabilityVisualization,
        MetricTable,
        InfoModal,
        ReportCardDropdown,
        GreenButton,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
        reportType: {
            required: false,
            type: String,
        },
    },
    data() {
        const visualData = VisualDataUtils.extractVisualData(this.reportData, 'fixationStability');
        const metrics = extractLeftRightBoth(this.reportData.tests.fixationStability.metrics);
        let eyeDominance;

        if (metrics.bothLeft && metrics.bothRight) {
            if (metrics.bothLeft.fixationDispersion < metrics.bothRight.fixationDispersion) {
                eyeDominance = 'left';
            } else {
                eyeDominance = 'right';
            }
        }

        const monocularFields = [
            'fixationLocationAggregate',
            'right.fixationStabilityAggregate',
            'left.fixationStabilityAggregate',
            'fixationStabilityAggregate',
            'gazePositionsBand1Aggregate',
            'gazePositionsBand2Aggregate',
            'gazePositionsBand3Aggregate',
            'gazePositionsBand4Aggregate',
            'bceaAggregate',
            'depth',
            'fixationDispersion',
        ];

        const binocularFields = [
            'right.fixationLocationAggregate',
            'left.fixationLocationAggregate',
            'fixationLocationAggregate',
            'right.fixationStabilityAggregate',
            'left.fixationStabilityAggregate',
            'fixationStabilityAggregate',
            'right.gazePositionsBand1Aggregate',
            'left.gazePositionsBand1Aggregate',
            'gazePositionsBand1Aggregate',
            'right.gazePositionsBand2Aggregate',
            'left.gazePositionsBand2Aggregate',
            'gazePositionsBand2Aggregate',
            'right.gazePositionsBand3Aggregate',
            'left.gazePositionsBand3Aggregate',
            'gazePositionsBand3Aggregate',
            'right.gazePositionsBand4Aggregate',
            'left.gazePositionsBand4Aggregate',
            'gazePositionsBand4Aggregate',
            'bceaAggregate',
            'depth',
            'right.fixationDispersion',
            'left.fixationDispersion',
        ];

        return {
            config: {
                testData: visualData,
                systemType: this.reportData.assessment.systemType,
            } as FixationStabilityConfig,
            metrics,
            monocularFields,
            binocularFields,
            showGuidelines: false,
            showEyeDominance: false,
            showAvgHeadTilt: false,
            playButtonState: 'active' as ButtonState,
            playAnimation: false,
            eyeDominance,
        };
    },
    computed: {
        hasLeft(): boolean {
            const metrics = this.reportData.tests.fixationStability.metrics;
            return metrics.eyeType.includes('LEFT');
        },
        hasRight(): boolean {
            const metrics = this.reportData.tests.fixationStability.metrics;
            return metrics.eyeType.includes('RIGHT');
        },
        hasBinocular(): boolean {
            const metrics = this.reportData.tests.fixationStability.metrics;
            return metrics.eyeType.includes('BINOCULAR');
        },
        norms(): any {
            const norms = extractLeftRightBothNorms(this.normsData.fixationStability);
            return norms;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.fixationStability?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.fixationStability?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(this.reportData.tests.fixationStability?.metrics, 'distanceFromScreen');
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.fixations'));
            infoText.push(this.$t('reports.info.fixationStability.fixationLocation'));
            infoText.push(this.$t('reports.info.fixationStability.bcea'));
            infoText.push(this.$t('reports.info.fixationStability.fixationDispersion'));

            return infoText;
        },
        hasEyeCoordinates(): boolean {
            for (const point of this.config.testData.left.points) {
                if (point.eyeX && point.eyeY) {
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        setShowGuidelines(showGuidelines: boolean) {
            this.showGuidelines = showGuidelines;
        },
        setShowEyeDominance(showEyeDominance: boolean) {
            this.showEyeDominance = showEyeDominance;
        },
        setShowAvgHeadTilt(showAvgHeadTilt: boolean) {
            this.showAvgHeadTilt = showAvgHeadTilt;
        },
        playReplayAnimation() {
            this.playButtonState = 'inactive';
            this.playAnimation = true;
        },
        onAnimationFinished() {
            this.playButtonState = 'active';
            this.playAnimation = false;
        },
    },
});
