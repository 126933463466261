var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideTextField),expression:"hideTextField"}],staticClass:"editable-text-field"},[_c('v-hover',{attrs:{"disabled":!_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTextField),expression:"!showTextField"}],staticClass:"text-row ma-0",class:{ 'on-hover': hover },attrs:{"justify":"end","align":"center"}},[_c('v-col',{staticClass:"text-content-col ma-0 pa-0 text-wrap"},[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"md":"auto"}},[_c('IconButton',{staticClass:"edit-icon-button",class:{ 'show-edit-icon-button': hover && _vm.editable },attrs:{"icon":'edit',"tooltip":_vm.$t('components.tooltips.edit'),"size":20},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setShowTextField(true)}}})],1)],1)]}}])}),(_vm.editable && _vm.showTextField)?_c('v-row',{staticClass:"edit-row ma-0 pa-0",attrs:{"justify":"start","align":"center"}},[_c('v-textarea',{staticClass:"edit-text-field text-wrap",attrs:{"autofocus":"","auto-grow":"","dense":"","solo":"","counter":_vm.characterLimit,"rules":[
                function (v) { return !_vm.characterLimit || !v || v.length <= _vm.characterLimit || 'Max ' + _vm.characterLimit + ' characters'; } ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.saveTextField();
                _vm.setShowTextField(false);},"change":function($event){return _vm.change($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('IconButton',{staticClass:"save-icon-button",attrs:{"icon":'far fa-save',"tooltip":_vm.$t('components.tooltips.save'),"disabled":_vm.characterLimit !== undefined &&
                        _vm.textFieldContent !== null &&
                        _vm.textFieldContent.length > _vm.characterLimit},nativeOn:{"click":function($event){$event.stopPropagation();if (
                            _vm.textFieldContent === null ||
                            _vm.characterLimit === undefined ||
                            _vm.textFieldContent.length <= _vm.characterLimit
                        ) {
                            _vm.saveTextField();
                            _vm.setShowTextField(false);
                        }}}})]},proxy:true}],null,false,3852009338),model:{value:(_vm.textFieldContent),callback:function ($$v) {_vm.textFieldContent=$$v},expression:"textFieldContent"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }