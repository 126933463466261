








import Vue from 'vue';
import FancyCheckBox from '@/views/common/controls/FancyCheckBox.vue';
import EyeView from '@/views/trainer/exercises/components/workouts/EyeView.vue';

export default Vue.extend({
    components: { FancyCheckBox, EyeView },
    props: {
        exerciseName: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
    },
});
