
























import Vue, { PropType } from 'vue';

import * as MetricsUtils from '@/common/utils/reports/metrics';

import { ReportData } from '@/common/types/reports/reportData';

import MetricTable from '@/views/reports/metrics/MetricTable.vue';
import { ReportNorms } from '@/common/types/reports/norms';
import InfoModal from '@/views/common/containers/InfoModal.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    components: {
        MetricTable,
        InfoModal,
    },
    props: {
        reportData: Object as PropType<ReportData>,
        normsData: Object as PropType<ReportNorms>,
        loadMetricTable: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        const metrics = this.reportData.tests.dynamicVisualAcuity3.metrics;

        const fields = ['speed', 'reactionTime'];

        return {
            metrics,
            fields,
        };
    },
    computed: {
        norms(): any {
            return this.normsData.dynamicVisualAcuity3;
        },
        minDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.dynamicVisualAcuity3?.metrics,
                'distanceFromScreenMin',
            );
        },
        maxDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.dynamicVisualAcuity3?.metrics,
                'distanceFromScreenMax',
            );
        },
        averageDistance(): number | undefined {
            return MetricsUtils.searchForField(
                this.reportData.tests.dynamicVisualAcuity3?.metrics,
                'distanceFromScreen',
            );
        },
        infoText(): TranslateResult[] {
            const infoText = [] as TranslateResult[];

            infoText.push(this.$t('reports.info.dynamicVisualAcuity.dva3'));
            infoText.push(this.$t('reports.info.reactionTime.definition'));

            return infoText;
        },
    },
});
